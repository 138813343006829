import { Close } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Alert, Button, Divider, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { classNames } from 'utilities/Utils';
import CircularCountDown from '../ProgressBar/CircularCountDown';
import Typography from '../Typography/Typography';

type Type = 'success' | 'info' | 'warning' | 'error';
type Position = {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'right';
};

const colorMap = {
  warning: '#F2A526',
  info: '#3543BF',
  error: '#C62828',
  success: '#2E7D32',
};

const getIconsMapping = {
  success: {
    icon: (
      <CheckCircleIcon fill="#fff" className="text-common-white text-size-22" />
    ),
  },
  info: {
    icon: <InfoIcon fill="#fff" />,
  },
  warning: {
    icon: (
      <WarningIcon fill="#fff" className="text-text-primary text-size-22" />
    ),
  },
  error: {
    icon: <CancelIcon fill="#fff" />,
  },
};

const calculatePosition = (position: Position) => {
  const positionsStyle = {
    top: 'unset',
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
  };
  positionsStyle[position.vertical] = '20px';
  positionsStyle[position.horizontal] = '20px';
  return positionsStyle;
};

const useStyles = makeStyles(() => ({
  snackBarRoot: (props: { type: Type; zIndexValue; position: Position }) => ({
    margin: 'auto',
    zIndex: props.zIndexValue,
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    backgroundColor: colorMap[props.type],
    padding: '12px',
    maxWidth: '420px', //fixed
    '& [class*="MuiSnackbar-root"]': {
      bottom: 'inherit',
      right: 'inherit',
    },
    ...calculatePosition(props.position),
  }),
  // eslint-disable-next-line
  alertRoot: (props: { type: Type; zIndexValue: any; position: Position }) => ({
    padding: '0px',
    backgroundColor: 'inherit',
  }),
}));

type ToastPropTypes = {
  children?;
  open: boolean;
  onClose: (
    e: React.SyntheticEvent<unknown>,
    v: 'timeout' | 'clickaway',
  ) => void;
  type: Type;
  autoHide?: boolean;
  showCross?: boolean;
  showCounter?: boolean;
  title?: string;
  position?: Position;
  message: string;
  positiveText?: string;
  negativeText?: string;
  onPositiveClick: Function;
  onNegativeClick?: Function;
  clickAwayHandler?: Function;
};

function BlockToast(props: ToastPropTypes) {
  const {
    children = null,
    open,
    onClose,
    type = 'error',
    autoHide = true,
    showCounter = false,
    showCross = false,
    title,
    position = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    message,
    positiveText = 'Okay',
    onPositiveClick = null,
    negativeText = null,
    onNegativeClick = null,
    clickAwayHandler = null,
  } = props;

  const textColor = type === 'warning' ? 'text.primary' : 'common.white';

  /* eslint-disable */
  const zIndexValue = true ? '999999999 !important' : '1400'; //default snackbar - 1400

  const classes = useStyles({ type, zIndexValue, position });

  const handleClickAway = event => {
    if (clickAwayHandler) {
      clickAwayHandler(event, 'clickaway');
    }
  };

  const snackBarProps = {
    open,
    key: children,
    ClickAwayListenerProps: {
      onClickAway: handleClickAway,
    },
  };

  const alertProps = {};

  if (autoHide) {
    /* eslint-disable */
    snackBarProps['autoHideDuration'] = 5000;
    snackBarProps['onClose'] = onClose;
    /* eslint-disable */
  }

  if (showCross) {
    alertProps['onClose'] = onClose;
    alertProps['action'] = (
      <Button onClick={e => onClose(e, 'clickaway')} className="p-0">
        <Close className="text-common-white" />
      </Button>
    );
  }

  return (
    <Snackbar {...snackBarProps} className={classes.snackBarRoot}>
      <Alert
        variant="filled"
        severity={type}
        icon={getIconsMapping[type].icon}
        className={classNames(classes.alertRoot)}
        {...alertProps}
      >
        <div className="flex">
          <div id="alert-content">
            <Typography variant="subHeadingRegular" themeColor={textColor} fb>
              {title}
            </Typography>
            <Typography themeColor={textColor} className="my-2">
              {message}
            </Typography>
            <Divider
              className={
                type !== 'warning' ? 'bg-color-white' : 'bg-color-overlay'
              }
            />
            <Button
              variant="text"
              onClick={() => onPositiveClick()}
              className="capitalize mt-2"
            >
              <Typography themeColor={textColor} variant="subHeadingRegular">
                {positiveText}
              </Typography>
            </Button>
            {negativeText ? (
              <Button
                onClick={() => onNegativeClick()}
                variant="text"
                className="capitalize my-2"
              >
                <Typography themeColor={textColor} variant="subHeadingRegular">
                  {negativeText}
                </Typography>
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div>
            {showCounter && (
              <div className="w-[40px] h-[40px]">
                <CircularCountDown />
              </div>
            )}
          </div>
        </div>
      </Alert>
    </Snackbar>
  );
}

export { BlockToast };
