export const ShowButtons = {
  none: 'none',
  both: 'both',
  basic: 'basic',
};

export const Position = {
  start: 'start',
  center: 'center',
};

export type CarouselProps = {
  children: React.ReactNode | React.ReactNode[];
  initialItem?: number;
  multiView?: boolean;
  infiniteScroll?: boolean;
  position?: keyof typeof Position;
  showButtons?: keyof typeof ShowButtons;
  customButtons?: {
    left?: (disabled: boolean) => React.ReactNode;
    right?: (disabled: boolean) => React.ReactNode;
  };
  onLeftClick?: (nextItemId: number) => void;
  onRightClick?: (nextItemId: number) => void;
  shiftBy?: number;
  scrollToActiveItem?: boolean;
  hideIndicator?: boolean;
  customIndicator?: React.ReactNode;
  customClasses?: {
    container?: string;
    slider?: string;
    slide?: string;
    activeSlide?: string;
    indicatorContainer?: string;
    indicator?: string;
    leftButton?: string | ((disabled: boolean) => string);
    rightButton?: string | ((disabled: boolean) => string);
  };
  'data-test-id'?: string;
};

export type StyleProps = {
  multiView: boolean;
};
