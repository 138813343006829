import { HotelLegalEntityType, UserProfileType } from 'app/hotel/constants';
import { SourcePartner } from './app/hotel/interfaces';

export const DEMAND_COLOR_STRING = {
  VERY_HIGH: 'Very High',
  HIGH: 'High',
  LOW: 'Low',
  VERY_LOW: 'Very Low',
  NORMAL: 'Normal',
};

export const PROPERTY_TYPE = {
  HOTEL: 'Hotel',
  HOMESTAY: 'Homestay',
};

export const baseUrl = 'newextranet';
export const appSessionCookieKey = 'connect-session';
export const appDocAccessCookieKey = 'extranet-session';
export const cookieDomain = '.goibibo.com';
export const API_TOKEN_KEY = 'api_token';
export const COOKIE_EXPIRE_TIME = 365;

// Marking Gurgoan Location as default for location related/nearby Search
export const DEFAULT_LOCATION_COORDS = {
  LATITUDE: 28.4595,
  LONGITUDE: 77.0266,
};
export const displayDateFormat = 'Do MMM YYYY';
export const displayDateFormatWithComma = 'Do MMM, YYYY';
export const displayTimeFormat = 'h:mm A';
export const displayDateFormatWithTime = 'Do MMM YYYY, h:mm:ss a';
export const apiDateFormat = 'YYYY-MM-DD';
export const doMonthFormat = 'Do MMM';
export const monthFormat = 'MMM';
export const fullMonthFormat = 'MMMM';
export const displayHourOnlyFormat = 'h a';
export const monthYear = 'MMMM YYYY';
export const noEndDate = new Date('2099-12-31');
export const displayTimeFormatWithSeconds = 'HH:mm:ss';
export const displayDateFullMonthFormat = 'Do MMMM YYYY';
export const doDayFullMonthFormat = 'D MMMM';
export const doDayFullMonthYearFormat = 'D MMMM YYYY';
export const dateMnthYr = 'DD MMM\'YY';
export const apiDateFormatV2 = 'YYYY-MM-DD 00:00:00';
// this is a special case required for analytics APIs where date includes the whole day
export const apiDateFormatV3 = 'YYYY-MM-DD 23:59:59';
export const displayDateMonth = 'DD MMM';
export const displayDate = 'DD';

export const dateMonthYear = 'DD MMM, YYYY';
export const displayDateFormatV2 = 'DD MMM, YYYY';
export const displayDateFormatV3 = 'MMM DD, YYYY';
export const displayDateFormatV4 = 'DD MMMM';
export const abbreviatedDateMnthYr = 'DDMMMYYYY';
export const abbreviatedDate = 'DD MMM YYYY, h:mm A';
export const displayDateFormatMMM_D_YYYY_d = 'MMM D YYYY, ddd';
export const displayISODateTime = 'YYYY-MM-DD hh:mm:ss';
export const longDateTimeFormatWithMeridian = 'MMMM DD, YYYY, hh:mm:ss A';

type SourcePartnerType = { [P in Uppercase<SourcePartner>]: Lowercase<P> };
export const SOURCE_PARTNER: SourcePartnerType = {
  SYNXIS: 'synxis',
  DERBY: 'derby',
  INGO: 'ingo',
};
export const PARTNER_HOTEL = [SOURCE_PARTNER.DERBY, SOURCE_PARTNER.SYNXIS];
export const BRANDS = {
  GOIBIBO: 'goibibo',
  MAKEMYTRIP: 'makemytrip',
};

export const PROPERTY_CHAIN_IDS = {
  975: 'TREEBO',
  976: 'FAB',
  897: 'OYO',
};

export const SCREEN_WIDTH_BREAKPOINT = 1440;

export const GST_ERROR_CODE_MAP = {
  SCRUTINY: 2001,
  INVOICE_PENDING: 2005,
};

export const GST_INVOICE_STATUS_MAP = {
  REJECTED: 'rejected',
  SCRUTINY: 'scrutiny',
  IN_MODERATION: 'in_wip',
}; //TODO: use this at every places where gst status is used

//Copied for getGeneralData API
export const paymentModes = [
  [0, 'Pay Now'],
  [1, 'Pay@hotel'],
];

export const inventoryMaxFutureDays = 450;

export const LMR_CHAIN_ID_DISABLED = 365;

export const hotelLegalEntityTypeMap = {
  0: HotelLegalEntityType.INGO,
  1: HotelLegalEntityType.HOTEL_TRAVEL,
};

export const userProfileTypeMap = {
  0: UserProfileType.INGO,
  1: UserProfileType.BOTH,
  2: UserProfileType.HOTEL_TRAVEL,
};
export const OCCUPANCY_FIELDS = {
  BASE_ADULT_OCCUPANCY: 'base_adult_occupancy',
  MAX_ADULT_OCCUPANCY: 'max_adult_occupancy',
  MAX_CHILD_OCCUPANCY: 'max_child_occupancy',
  MAX_GUEST_OCCUPANCY: 'max_guest_occupancy',
};
export const RESELLER_AGREEMENT_ID = 431;
export const RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY =
  'redirectedFromResellerOnboarding';
export const RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE = 'true';

export const WINDOW_TYPE = {
  BLANK: '_blank',
};

export const ALLOWED_FILE_TYPES = ['.pdf', '.png', '.jpg', '.jpeg'];
