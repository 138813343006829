import { useContext, useMemo } from 'react';
import { doQuery } from 'base/GraphQLClient';
import moment from 'moment';
import {
  checkNodeIfDaysOfTheWeek,
  getFormattedDate,
  getFutureDate,
  getStartOfMonth,
  getStartOfYear,
} from '../../../utilities/DateUtils';
import {
  apiDateFormat,
  apiDateFormatV2,
  displayDateMonth,
} from '../../../constants';
import { HotelContext } from 'utilities/Context';
import {
  OPT_LAST_15D,
  OPT_LAST_1M,
  OPT_LAST_3M,
  OPT_LAST_6M,
  OPT_LAST_7D,
  TAB_ASP,
  TAB_CONVERSION,
  TAB_REVENUE,
  VAL_BRAND_MMT,
  VAL_LAST_15D,
  VAL_LAST_1M,
  VAL_LAST_3M,
  VAL_LAST_6M,
  VAL_LAST_7D,
  VAL_STAYING_DATE,
  analyticsPages,
  tabsData,
} from '../Constants';
import {
  INTLFormat,
  currencyDataFormaterWithFixedDecimals,
  generatePrefixForCurrencies,
  getValue,
} from 'utilities/Utils';

import {
  dTypeBarGraph,
  dTypeHGraph,
  dTypeHShowMoreGraph,
  dTypeTab,
} from '../Constants';
import { AnalyticsPagesSectionsDataKeys } from '../constants/Analytics';

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export const convertArrayToStringArray = arr => {
  return `["${arr.join('","')}"]`;
};

export const formatToYYYY_MM_DD = date => {
  let month = date.getMonth();
  if (month < 10) {
    month = `0${month}`;
  }
  return `${date.getFullYear()}-${month}-${date.getDate()}`;
};

export const formatDateString = (date, currentFormat, expectedFormat) => {
  const momentDate = moment(date, currentFormat).format(expectedFormat);
  return momentDate;
};

export const substractFromToday = (amt, type) => {
  amt = amt - 1;
  const now = moment();
  let pastDate = now.subtract(amt, type);
  if (type === 'months') {
    const date = pastDate.date() - 1;
    pastDate = pastDate.subtract(date, 'days');
  }
  return pastDate.format('YYYY-MM-DD');
};

export const numberPostfix = num => {
  switch (num) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const INRFormat = nStr => {
  if (!nStr) {
    return 0;
  }
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  let rgx = /(\d+)(\d{3})/;
  let z = 0;
  const len = String(x1).length;
  let num = parseInt(len / 2 - 1);

  while (rgx.test(x1)) {
    if (z > 0) {
      x1 = x1.replace(rgx, '$1,$2');
    } else {
      x1 = x1.replace(rgx, '$1,$2');
      rgx = /(\d+)(\d{2})/;
    }
    z++;
    num--;
    if (num === 0) {
      break;
    }
  }
  return x1 + x2;
};

export const getDates = no_of_days => {
  const dateArray = [];
  let currentDate = new Date();
  let num = 0;
  while (num < no_of_days) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
    num++;
  }
  return dateArray;
};

export const getDateSlice = (dateList, start, end) => {
  return dateList.slice(start, end);
};

export const useRoomDetails = () => {
  const hotelContext = useContext(HotelContext);
  const rooms = hotelContext.roomRatePlan?.roomDetails;

  const roomDetails = useMemo(() => {
    const roomDetails = [];
    for (let i = 0; i < rooms?.length; i++) {
      const room = rooms[i];
      const temp = {
        label: room.roomtypename,
        value: room.roomtypecode,
        isActive: room.isactive,
      };

      if (room.isactive) {
        roomDetails.unshift(temp);
      } else {
        roomDetails.push(temp);
      }
    }
    roomDetails.unshift({ label: 'Select All', value: 'All', isActive: true });
    return roomDetails;
  }, [hotelContext.roomRatePlan?.roomDetails]);

  return roomDetails;
};

export const getAPIAndUIDates = ({ selectedValue }) => {
  let { from, to } = selectedValue;

  if (!from || !to) {
    const val = selectedValue.label;
    from = null;
    to = new Date();

    if (val === 'Month till Date') {
      from = getStartOfMonth();
    } else if (val === 'Year till Date') {
      from = getStartOfYear();
    } else {
      return [null, null, null, null];
    }
  }

  return [
    getFormattedDate(from, apiDateFormat),
    getFormattedDate(to, apiDateFormat),
    getFormattedDate(from),
    getFormattedDate(to),
  ];
};

export const getVendorId = brand => {
  let vendorId;
  switch (brand) {
    case 'all':
      vendorId = ['MakeMyTrip', 'Goibibo'];
      break;
    case 'mmt':
      vendorId = 'MakeMyTrip';
      break;
    case 'goibibo':
      vendorId = 'Goibibo';
      break;
    default:
  }
  return vendorId;
};

export const getNewVendorId = brand => {
  let vendorId;
  switch (brand) {
    case 'all':
      vendorId = ['MMT', 'GI'];
      break;
    case 'mmt':
      vendorId = 'MMT';
      break;
    case 'goibibo':
      vendorId = 'GI';
      break;
    default:
  }
  return vendorId;
};

export const getField = intervalField => {
  let field;
  switch (intervalField) {
    case 'booking':
      field = 'bookingDate';
      break;
    case 'searchDate':
      field = 'searchDate';
      break;
    case 'stay':
      field = 'dayWise.date';
      break;
    case 'los':
      field = 'los';
      break;
    case 'ap':
      field = 'ap';
      break;
    default:
  }
  return field;
};

export const getPipeline = pipeline => {
  let pipe;
  switch (pipeline) {
    case 'Transaction':
      pipe = 'transactions';
      break;
    case 'demand_v2':
      pipe = 'demand';
      break;
    default:
  }

  return pipe;
};
export const getPipe2line = pipeline => {
  let pipe;
  switch (pipeline) {
    case 'Transaction':
      pipe = 'transactions';
      break;
    case 'demand_v2':
      pipe = 'demand_v2';
      break;
    default:
  }

  return pipe;
};

export const getPropertyName = (intervalField, property, reverse = false) => {
  const propertyNameMap = {
    booking: {
      room_nights: 'roomNights',
      net_amount: 'netAmount',
      sell_amount: 'avgSellPrice',
      demandHotelVisits: 'demandHotelVisits',
      conversionRate: 'conversionRate',
    },
    stay: {
      room_nights: 'dayWise.roomNights',
      net_amount: 'dayWise.totalNetAmount',
      sell_amount: 'avgSellPrice',
      demandHotelVisits: 'demandHotelVisits',
      conversionRate: 'conversionRate',
    },
    los: {
      room_nights: 'roomNights',
      bookings: 'bookings',
    },
    ap: {
      bookings: 'bookingId',
    },
    meal_plan: {
      room_nights: 'roomNights',
    },
    pax: {
      room_nights: 'roomNights',
    },
  };

  const propertyNameReverseMap = {
    booking: {
      roomNights: 'room_nights',
      netAmount: 'net_amount',
      avgSellPrice: 'sell_amount',
      demandHotelVisits: 'demandHotelVisits',
      conversionRate: 'conversionRate',
    },
    stay: {
      'dayWise.roomNights': 'room_nights',
      'dayWise.totalNetAmount': 'net_amount',
      avgSellPrice: 'sell_amount',
      demandHotelVisits: 'demandHotelVisits',
      conversionRate: 'conversionRate',
    },
    los: {
      roomNights: 'room_nights',
      bookings: 'bookings',
    },
    ap: {
      bookingId: 'bookings',
    },
    meal_plan: {
      roomNights: 'room_nights',
    },
    pax: {
      roomNights: 'room_nights',
    },
  };

  return !reverse
    ? propertyNameMap[intervalField][property]
    : propertyNameReverseMap[intervalField][property];
};

export const getNewPropertyName = (
  intervalField,
  property,
  reverse = false,
) => {
  const propertyNameMap = {
    searchDate: {
      room_nights: 'roomNights',
      net_amount: 'netAmount',
      sell_amount: 'avgSellPrice',
      visits: 'visits',
      conversionRate: 'conversionRate',
    },
    booking: {
      room_nights: 'roomNights',
      net_amount: 'netAmount',
      sell_amount: 'avgSellPrice',
      demandHotelVisits: 'visits',
      conversionRate: 'conversionRate',
    },
    stay: {
      room_nights: 'dayWise.roomNights',
      net_amount: 'dayWise.totalNetAmount',
      sell_amount: 'avgSellPrice',
      demandHotelVisits: 'visits',
      conversionRate: 'conversionRate',
    },
    los: {
      room_nights: 'roomNights',
      bookings: 'bookings',
    },
    ap: {
      bookings: 'bookingId',
    },
    meal_plan: {
      room_nights: 'roomNights',
    },
    pax: {
      room_nights: 'roomNights',
    },
  };

  const propertyNameReverseMap = {
    searchDate: {
      room_nights: 'roomNights',
      net_amount: 'netAmount',
      sell_amount: 'avgSellPrice',
      visits: 'visits',
      conversionRate: 'conversionRate',
    },
    booking: {
      roomNights: 'room_nights',
      netAmount: 'net_amount',
      avgSellPrice: 'sell_amount',
      demandHotelVisits: 'demandHotelVisits',
      conversionRate: 'conversionRate',
    },
    stay: {
      'dayWise.roomNights': 'room_nights',
      'dayWise.totalNetAmount': 'net_amount',
      avgSellPrice: 'sell_amount',
      demandHotelVisits: 'demandHotelVisits',
      conversionRate: 'conversionRate',
    },
    los: {
      roomNights: 'room_nights',
      bookings: 'bookings',
    },
    ap: {
      bookingId: 'bookings',
    },
    meal_plan: {
      roomNights: 'room_nights',
    },
    pax: {
      roomNights: 'room_nights',
    },
  };

  return !reverse
    ? propertyNameMap[intervalField][property]
    : propertyNameReverseMap[intervalField][property];
};

export const getAnalyticsV2DateFormat = (date, field, isEnd = false) => {
  if (field === 'bookingDate') {
    return `${date} ${!isEnd ? '00:00:00' : '23:59:59'}`;
  }
  return date;
};

const makeIntervals = (startDate, endDate, gap, field) => {
  const intervals = [];
  let date = new Date(startDate);

  while (date.getTime() < new Date(endDate).getTime()) {
    const intervalStartDate = date;
    const intervalEndDate = getFutureDate(intervalStartDate, gap + 1);
    const interval = [
      getFormattedDate(date, apiDateFormat),
      getAnalyticsV2DateFormat(
        getFormattedDate(intervalStartDate, apiDateFormat),
        field,
      ),
      getAnalyticsV2DateFormat(
        getFormattedDate(intervalEndDate, apiDateFormat),
        field,
      ),
    ];
    intervals.push(interval);

    date = getFutureDate(date, gap);
  }

  return intervals;
};

export const getIntervals = (period, interval, field, startDate, endDate) => {
  const intervals = [];

  if (field === 'dayWise.date') {
    intervals.push({
      type: 'range',
      field: field,
      range_intervals: [
        [
          'Stay Interval',
          startDate,
          getFormattedDate(getFutureDate(endDate, 1), apiDateFormat),
        ],
      ],
    });
  }

  switch (true) {
    case (period === 7 || period === 15) && interval === 'days': {
      intervals.push({
        field: field,
        type: 'day',
      });
      break;
    }
    case period === 30 && interval === 'days': {
      intervals.push({
        field: field,
        type: 'range',
        range_intervals: makeIntervals(startDate, endDate, 7, field),
      });
      break;
    }
    case (period === 3 || period === 6) && interval === 'months': {
      intervals.push({
        field: field,
        type: 'month',
      });
      break;
    }
    default:
      break;
  }
  return intervals;
};

export const getDateType = type => {
  let dateType;
  switch (type) {
    case 'booking':
      dateType = 'bookingDate';
      break;
    case 'checkin':
      dateType = 'checkin';
      break;
    default:
  }

  return dateType;
};

export const parseAnalyticsResponse = (
  hotelId,
  data,
  request,
  res,
  keyValues,
  isBookingIdCountPresent = false,
  getTotalRoomNights = false,
) => {
  const brand = request.brand;
  let totalRoomNights = 0;
  Object.keys(data).forEach(date => {
    Object.keys(data[date]).forEach(property => {
      const oldProperty = getNewPropertyName(
        request.interval_field,
        property,
        true,
      );
      if (keyValues.comp) {
        keyValues.comp.forEach(({ key, value }) => {
          if (!res.comp.data[brand][oldProperty]) {
            res.comp.data[brand][oldProperty] = {};
          }
          res.comp.data[brand][oldProperty][date] = {
            ...res.comp.data[brand][oldProperty][date],
            [key]: data[date][property][value] ?? 0,
          };
        });
      }

      if (keyValues.hotel) {
        keyValues.hotel.forEach(({ key, value }) => {
          if (!res.hotel[hotelId][brand][oldProperty]) {
            res.hotel[hotelId][brand][oldProperty] = {};
          }
          res.hotel[hotelId][brand][oldProperty][date] = {
            ...res.hotel[hotelId][brand][oldProperty][date],
            [key]: data[date][property][value] ?? 0,
          };
        });
        if (isBookingIdCountPresent) {
          res.hotel[hotelId][brand][oldProperty][date] = {
            ...res.hotel[hotelId][brand][oldProperty][date],
            count: data[date].bookingId.value,
          };
        }
      }

      if (getTotalRoomNights) {
        totalRoomNights += data[date][property].value;
      }
    });
  });

  return totalRoomNights;
};

export const parseCancelledBookingsResponseHelper = (
  hotelId,
  totalData,
  cancelledData,
  request,
  res,
  keyValues,
  getCompetitorBookingsCount = false,
) => {
  const brand = request.brand;

  let totalBookings = 0;
  let totalCancelledBookings = 0;
  let compTotalBookings = 0;
  let compTotalCancelledBookings = 0;

  Object.keys(cancelledData).forEach(date => {
    Object.keys(cancelledData[date]).forEach(property => {
      const oldProperty = getPropertyName(
        request.interval_field,
        property,
        true,
      );
      if (keyValues.comp) {
        keyValues.comp.forEach(({ key, value, dataValAccessor }) => {
          if (!res.comp.data[brand][oldProperty]) {
            res.comp.data[brand][oldProperty] = {};
          }
          res.comp.data[brand][oldProperty][date] = {
            ...res.comp.data[brand][oldProperty][date],
            ...(dataValAccessor === 'total'
              ? { [key]: totalData[date][property][value] }
              : { [key]: cancelledData[date][property][value] }),
          };
        });
      }

      if (keyValues.hotel) {
        keyValues.hotel.forEach(({ key, value, dataValAccessor }) => {
          if (!res.hotel[hotelId][brand][oldProperty]) {
            res.hotel[hotelId][brand][oldProperty] = {};
          }
          res.hotel[hotelId][brand][oldProperty][date] = {
            ...res.hotel[hotelId][brand][oldProperty][date],
            ...(dataValAccessor === 'total'
              ? { [key]: totalData[date][property][value] }
              : { [key]: cancelledData[date][property][value] }),
          };
        });
      }

      totalBookings += totalData[date][property].value;
      totalCancelledBookings += cancelledData[date][property].value;

      if (getCompetitorBookingsCount) {
        compTotalBookings += totalData[date][property].comp_value;
        compTotalCancelledBookings += cancelledData[date][property].comp_value;
      }
    });
  });

  return {
    totalBookings,
    totalCancelledBookings,
    compTotalBookings,
    compTotalCancelledBookings,
  };
};

export const getBrandName = brand => {
  if (brand) {
    return brand === 'all'
      ? ''
      : brand === 'goibibo'
        ? 'GI'
        : brand.toUpperCase();
  }
  return '';
};

export const getMapBandText = (COMMON_STRINGS, data) => {
  let text = '';
  if (data.mmt) {
    text += `${COMMON_STRINGS.LABELS.MAKEMYTRIP} - ${data.mmt}`;
    if (data.goibibo) {
      text += ', ';
    }
  }
  if (data.goibibo) {
    text += `${COMMON_STRINGS.LABELS.GOIBIBO} - ${data.goibibo}`;
  }
  return text;
};

//this is a common method to decide the start and end of the interval,
//Note: Please test all the dependent methods after changing something here
export const startAndEndofIntervalFromToday = period => {
  const endInterval = new Date();
  const startInterval = getFutureDate(endInterval, -period);

  return {
    endInterval,
    startInterval,
  };
};

export const getOverviewInterval = duration => {
  const value = duration.split('-');
  const period = value[0];
  const interval = value[1];
  const { endInterval } = startAndEndofIntervalFromToday(period);

  //end date is end of comparison interval(suppose we are comparing for last 7 days so the end date will be today minus 7th day)
  //start date is again minus 7th day of the end date, in case of month we only want the month name so dates will not matter much
  let endDate = getFutureDate(endInterval, -period);
  let startDate = getFutureDate(getFutureDate(endDate, 1), -period);
  switch (period) {
    //7 and 15 day case
    case '7':
    case '15':
      return `${getFormattedDate(startDate, 'Do MMMM')} - ${getFormattedDate(
        endDate,
        'Do MMMM',
      )} ( ${period} days )`;
    //1 month case(30 days)
    case '30':
      return `${getFormattedDate(startDate, 'Do MMMM')} - ${getFormattedDate(
        endDate,
        'Do MMMM',
      )} ( 1 month )`;
    // months case
    default:
      startDate = substractFromToday(parseInt(period) * 2, interval);
      //for extracting the name of previous month(duration -1month)
      endDate = substractFromToday(parseInt(period) + 1, interval);
      return `${getFormattedDate(startDate, 'MMMM\'YY')} - ${getFormattedDate(
        endDate,
        'MMMM\'YY',
      )} ( ${period} months )`;
  }
};

export const getGraphTitleSuffix = duration => {
  switch (duration) {
    case VAL_LAST_7D:
      return OPT_LAST_7D;
    case VAL_LAST_15D:
      return OPT_LAST_15D;
    case VAL_LAST_1M:
      return OPT_LAST_1M;
    case VAL_LAST_3M:
      return OPT_LAST_3M;
    case VAL_LAST_6M:
      return OPT_LAST_6M;
    default:
      return duration;
  }
};

export const generateGraphIntervalsStayingDate = (
  val,
  fieldData = '',
  isDemand = false,
) => {
  const value = val.split('-');
  const period = value[0];

  switch (val) {
    case VAL_LAST_7D:
    case VAL_LAST_15D:
      return [
        {
          field: isDemand
            ? 'searchDate'
            : fieldData === VAL_STAYING_DATE
              ? 'dayWise.date'
              : 'bookingDate',
          type: 'day',
        },
      ];

    case VAL_LAST_1M: {
      // start from today
      const { endInterval } = startAndEndofIntervalFromToday(period);
      const startDate = getFutureDate(endInterval, 1);
      const lastDate = getFutureDate(startDate, -period);

      const firstEnd = getFutureDate(lastDate, 7);
      // const secondStart = getFutureDate(firstEnd, 1);
      const secondEnd = getFutureDate(firstEnd, 7);
      // const thirdStart = getFutureDate(secondEnd, 1);
      const thirdEnd = getFutureDate(secondEnd, 7);
      // const fourthStart = getFutureDate(thirdEnd, 1);

      const apiDateFmt =
        fieldData === VAL_STAYING_DATE || isDemand
          ? apiDateFormat
          : apiDateFormatV2;

      return [
        {
          field: isDemand
            ? 'searchDate'
            : fieldData === VAL_STAYING_DATE
              ? 'dayWise.date'
              : 'bookingDate',

          type: 'range',
          range_intervals: [
            [
              `1.${getFormattedDate(
                lastDate,
                displayDateMonth,
              )}-${getFormattedDate(
                getFutureDate(firstEnd, -1),
                displayDateMonth,
              )}`,
              getFormattedDate(lastDate, apiDateFmt),
              getFormattedDate(firstEnd, apiDateFmt),
            ],
            [
              `2.${getFormattedDate(
                firstEnd,
                displayDateMonth,
              )}-${getFormattedDate(
                getFutureDate(secondEnd, -1),
                displayDateMonth,
              )}`,
              getFormattedDate(firstEnd, apiDateFmt),
              getFormattedDate(secondEnd, apiDateFmt),
            ],
            [
              `3.${getFormattedDate(
                secondEnd,
                displayDateMonth,
              )}-${getFormattedDate(
                getFutureDate(thirdEnd, -1),
                displayDateMonth,
              )}`,
              getFormattedDate(secondEnd, apiDateFmt),
              getFormattedDate(thirdEnd, apiDateFmt),
            ],
            [
              `4.${getFormattedDate(
                thirdEnd,
                displayDateMonth,
              )}-${getFormattedDate(
                getFutureDate(startDate, -1),
                displayDateMonth,
              )}`,
              getFormattedDate(thirdEnd, apiDateFmt),
              getFormattedDate(startDate, apiDateFmt),
            ],
          ],
        },
      ];
    }

    case VAL_LAST_3M:
    case VAL_LAST_6M:
      return [
        {
          field: isDemand
            ? 'searchDate'
            : fieldData === VAL_STAYING_DATE
              ? 'dayWise.date'
              : 'bookingDate',

          type: 'month',
        },
      ];
    default:
      return {};
  }
};

export const processTabData = (key, val, currency) => {
  const { suffix: curSuffix, value } = currencyDataFormaterWithFixedDecimals(
    Math.round(val),
    currency,
  );
  const intlFormatted = INTLFormat(val, currency);
  const currencyPrefix = generatePrefixForCurrencies(currency);
  switch (key) {
    case TAB_CONVERSION:
      return {
        val: intlFormatted,
        prefix: '',
        suffix: '%',
        toolTip: '',
        toolTipScrolled: `${intlFormatted} %`,
      };

    case TAB_REVENUE:
    case TAB_ASP:
      return {
        val: INTLFormat(value, currency),
        prefix: currencyPrefix,
        suffix: curSuffix,
        toolTip: `${currencyPrefix} ${intlFormatted}`,
        toolTipScrolled: `${currencyPrefix} ${intlFormatted}`,
      };
    //rest tabs without any prefix or suffix
    default:
      return {
        val: intlFormatted,
        prefix: '',
        suffix: '',
        toolTip: '',
        toolTipScrolled: intlFormatted,
      };
  }
};

export const checkStatus = (code = null, status) => {
  //404 is a special case for analytics apis
  return status === 'success' || code === 404;
};

export const extractTabData = (cardData, data, fieldData = '') => {
  const { fieldKey, kpiKey, name, tooltip, to, type, kpiKeySD, fieldKeySD } =
    cardData;
  const fieldKeyLocal = fieldData === VAL_STAYING_DATE ? fieldKeySD : fieldKey;
  const kpiKeyLocal = fieldData === VAL_STAYING_DATE ? kpiKeySD : kpiKey;
  const dataFromNode =
    data?.[fieldKeyLocal]?.['CURRENT INTERVAL STAT']?.[kpiKeyLocal] || {};

  const newData = {
    boost: dataFromNode?.boost_pc_prev > 0,
    trend: Math.round(Math.abs(dataFromNode?.boost_pc_prev || 0)),
    value: dataFromNode?.value || 0,
    tooltip,
    share: dataFromNode?.share || 0,
    name: name,
    to,
    type,
    isError: false,
    isLoading: false, //for testing purpose can be removed in the future if not required,
    compAvg: dataFromNode?.comp_value || 0,
  };

  return { ...newData };
};

export const extractGraphData = (cardData, data, fieldData = '') => {
  const {
    fieldKey,
    kpiKey,
    name,
    tooltip,
    nodes,
    type,
    overviewInfo,
    fieldKeySD,
    kpiKeySD,
  } = cardData;

  const fieldKeyLocal = fieldData === VAL_STAYING_DATE ? fieldKeySD : fieldKey;
  const kpiKeyLocal = fieldData === VAL_STAYING_DATE ? kpiKeySD : kpiKey;
  const dataFromNode = data?.[fieldKeyLocal] || {};

  let newData = {};

  if (nodes?.[0] === '__dynamic__') {
    newData = {
      name,
      tooltip,
      comparisonData: Object.entries(dataFromNode).reduce(
        (res, [key, data]) => {
          return {
            ...res,
            [`${checkNodeIfDaysOfTheWeek(key)}`]: {
              userValue: data?.[kpiKeyLocal]?.interval_share || 0,
              competitorsValue: data?.[kpiKeyLocal]?.comp_interval_share || 0,
            },
          };
        },
        {},
      ),
      isLoading: false, //for testing purpose can be removed in the future if not required
      isError: false,
      type,
      overviewInfo,
    };
  } else {
    const userCompComparisonData = nodes?.reduce(
      (result, currentUsrCompNode) => {
        return {
          ...result,
          [currentUsrCompNode]: {
            userValue:
              dataFromNode?.[currentUsrCompNode]?.[kpiKeyLocal]
                ?.interval_share || 0,
            competitorsValue:
              dataFromNode?.[currentUsrCompNode]?.[kpiKeyLocal]
                ?.comp_interval_share || 0,
          },
        };
      },
      {},
    );
    newData = {
      name,
      tooltip,
      comparisonData: userCompComparisonData,
      isLoading: false, //for testing purpose can be removed in the future if not required
      isError: false,
      type,
      overviewInfo,
    };
  }

  return { ...newData };
};

const formatKey = (key, type) => {
  switch (type) {
    case VAL_LAST_7D:
      return getFormattedDate(key, 'DD MMM');
    case VAL_LAST_15D:
      return getFormattedDate(key, 'Do');
    case VAL_LAST_3M:
    case VAL_LAST_6M:
      return getFormattedDate(key, 'MMMM');

    default:
      return key
        .replace('1.', '')
        .replace('2.', '')
        .replace('3.', '')
        .replace('4.', '');
  }
};

const shouldConsiderDate = (incomingDate, duration) => {
  const value = duration.split('-');
  const period = value[0];
  const interval = value[1];
  const { endInterval, startInterval } = startAndEndofIntervalFromToday(period);

  const intervalPrevStart = substractFromToday(parseInt(period), interval);

  switch (duration) {
    case VAL_LAST_3M:
    case VAL_LAST_6M:
      return (
        moment(incomingDate).diff(intervalPrevStart) >= 0 &&
        moment(incomingDate).diff(endInterval) <= 0
      );

    case VAL_LAST_1M:
      return true;

    default:
      return (
        moment(incomingDate).diff(startInterval) >= 0 &&
        moment(incomingDate).diff(endInterval) <= 0
      );
  }
};

export const extractBarGraphData = (
  cardData,
  data,
  duration,
  fieldData = '',
) => {
  const {
    fieldKey,
    name,
    tooltip,
    kpiKey,
    type: gtype,
    overviewInfo,
    kpiKeySD,
    fieldKeySD,
    graphTitle = '',
  } = cardData;
  const fieldKeyLocal = fieldData === VAL_STAYING_DATE ? fieldKeySD : fieldKey;
  const kpiKeyLocal = fieldData === VAL_STAYING_DATE ? kpiKeySD : kpiKey;
  const dataFromNode = data?.[fieldKeyLocal] || {};
  const userCompComparisonData = Object.entries(dataFromNode).reduce(
    (result, [key, data]) => {
      //filter to restrict extra intervals recieved from backend
      if (!shouldConsiderDate(key, duration)) {
        return result;
      }
      return {
        ...result,
        [`${formatKey(key, duration)}`]: {
          userValue: data?.[kpiKeyLocal]?.value || 0,
          competitorsValue: data?.[kpiKeyLocal]?.comp_value || 0,
        },
      };
    },
    {},
  );

  const newData = {
    name,
    tooltip,
    comparisonData: userCompComparisonData,
    isLoading: false, //for testing purpose can be removed in the future if not required
    isError: false,
    type: gtype,
    overviewInterval: getOverviewInterval(duration),
    overviewInfo,
    graphTitle: graphTitle.replace(
      '__duration__',
      getGraphTitleSuffix(duration),
    ),
  };

  return { ...newData };
};

export const getTabWiseGraphData = payload => {
  const { graphType, tabQuery, variables, pageSection, fieldData, duration } =
    payload;
  return new Promise(async (resolves, rejects) => {
    try {
      const { data, error } = await doQuery(tabQuery, variables, {
        useLoader: false,
      });

      const processedData = Object.keys(graphType).reduce(
        (result, current) => {
          const node = graphType[current];
          const resData =
            getValue(data, `${node.nodeKey}.analyticsHistogram.modelType1`) ||
            {};
          const isSuccessNode = checkStatus(
            resData?.status_code,
            resData?.status,
          );
          switch (node.type) {
            case dTypeTab:
              if (isSuccessNode) {
                const tabData = extractTabData(node, resData?.data, fieldData);
                return {
                  [AnalyticsPagesSectionsDataKeys.TABS]: {
                    ...result[AnalyticsPagesSectionsDataKeys.TABS],
                    [node.name]: { ...tabData },
                  },
                  [pageSection]: {
                    ...result[pageSection],
                  },
                };
              }

              return {
                [AnalyticsPagesSectionsDataKeys.TABS]: {
                  ...result[AnalyticsPagesSectionsDataKeys.TABS],
                  [node.name]: {
                    isError: true,
                    isLoading: false,
                    name: node.name,
                    tooltip: node.tooltip,
                    to: node.to,
                    boost: false,
                    trend: null,
                    value: null,
                    type: node.type,
                  },
                },
                [pageSection]: {
                  ...result[pageSection],
                },
              };

            case dTypeHGraph:
              if (isSuccessNode) {
                const graphData = extractGraphData(
                  node,
                  resData?.data,
                  fieldData,
                );

                return {
                  [AnalyticsPagesSectionsDataKeys.TABS]: {
                    ...result[AnalyticsPagesSectionsDataKeys.TABS],
                  },
                  [pageSection]: {
                    ...result[pageSection],
                    [node.name]: { ...graphData },
                  },
                };
              }

              return {
                [AnalyticsPagesSectionsDataKeys.TABS]: {
                  ...result[AnalyticsPagesSectionsDataKeys.TABS],
                },
                [pageSection]: {
                  ...result[pageSection],
                  [node.name]: {
                    name: node.name,
                    tooltip: node.tooltip,
                    type: node.type,
                    isError: true,
                    isLoading: false,
                    comparisonData: {},
                  },
                },
              };
            case dTypeHShowMoreGraph:
              if (isSuccessNode) {
                return {
                  [AnalyticsPagesSectionsDataKeys.TABS]: {
                    ...result[AnalyticsPagesSectionsDataKeys.TABS],
                  },
                  [pageSection]: {
                    ...result[pageSection],
                    [node.name]: {
                      name: node.name,
                      tooltip: node.tooltip,
                      comparisonData: {
                        ...resData?.data,
                      },
                      isError: false,
                      isLoading: false,
                      type: node.type,
                      overviewInfo: node.overviewInfo,
                    },
                  },
                };
              }

              return {
                [AnalyticsPagesSectionsDataKeys.TABS]: {
                  ...result[AnalyticsPagesSectionsDataKeys.TABS],
                },
                [pageSection]: {
                  ...result[pageSection],
                  [node.name]: {
                    name: node.name,
                    tooltip: node.tooltip,
                    type: node.type,
                    isError: true,
                    isLoading: false,
                    comparisonData: {},
                  },
                },
              };
            case dTypeBarGraph:
              if (isSuccessNode) {
                const graphData = extractBarGraphData(
                  node,
                  resData?.data,
                  duration,
                  fieldData,
                );

                return {
                  [AnalyticsPagesSectionsDataKeys.TABS]: {
                    ...result[AnalyticsPagesSectionsDataKeys.TABS],
                  },
                  [pageSection]: {
                    ...result[pageSection],
                    [node.name]: { ...graphData },
                  },
                };
              }

              return {
                [AnalyticsPagesSectionsDataKeys.TABS]: {
                  ...result[AnalyticsPagesSectionsDataKeys.TABS],
                },
                [pageSection]: {
                  ...result[pageSection],
                  [node.name]: {
                    name: node.name,
                    tooltip: node.tooltip,
                    type: node.type,
                    isError: true,
                    isLoading: false,
                    comparisonData: {},
                  },
                },
              };

            default:
              return { ...result };
          }
        },
        {
          [AnalyticsPagesSectionsDataKeys.TABS]: {},
          [pageSection]: {},
        },
      );

      if (error) {
        rejects(error);
      } else {
        resolves(processedData);
      }
    } catch (error) {
      rejects(error);
    }
  });
};

export const getWishListOrder = tabdata => {
  const listWithTimeStamp = tabdata
    .filter(ele => ele.timeStamp)
    .sort((a, b) => b.timeStamp - a.timeStamp);
  const listWithoutTimeStamp = tabdata.filter(ele => !ele.timeStamp);
  return [...listWithTimeStamp, ...listWithoutTimeStamp];
};

export const generateRangeIntervalsForBookingsDateOrSearchAndStayingDate = (
  duration,
  forSearchDateOrDaywise = false,
) => {
  const PREVIOUS_INTERVAL_STAT = 'PREVIOUS INTERVAL STAT';
  const CURRENT_INTERVAL_STAT = 'CURRENT INTERVAL STAT';
  const value = duration.split('-');
  const period = value[0];
  const interval = value[1];

  let intervalCurrentStart = new Date();
  let intervalCurrentEnd = substractFromToday(parseInt(period) * 1, interval);

  let intervalPerviousEnd = substractFromToday(parseInt(period) * 2, interval);

  //for days
  if (interval === 'days') {
    const { endInterval } = startAndEndofIntervalFromToday(period);
    // the date should start from today
    intervalCurrentStart = getFutureDate(endInterval, 1);
    intervalCurrentEnd = getFutureDate(intervalCurrentStart, -period);
    intervalPerviousEnd = getFutureDate(intervalCurrentEnd, -period);
  }

  if (forSearchDateOrDaywise) {
    return [
      [
        PREVIOUS_INTERVAL_STAT,
        getFormattedDate(intervalPerviousEnd, apiDateFormat),
        getFormattedDate(intervalCurrentEnd, apiDateFormat),
      ],
      [
        CURRENT_INTERVAL_STAT,
        getFormattedDate(intervalCurrentEnd, apiDateFormat),
        getFormattedDate(intervalCurrentStart, apiDateFormat),
      ],
    ];
  }

  return [
    [
      PREVIOUS_INTERVAL_STAT,
      getFormattedDate(intervalPerviousEnd, apiDateFormatV2),
      getFormattedDate(intervalCurrentEnd, apiDateFormatV2),
    ],
    [
      CURRENT_INTERVAL_STAT,
      getFormattedDate(intervalCurrentEnd, apiDateFormatV2),
      getFormattedDate(intervalCurrentStart, apiDateFormatV2),
    ],
  ];
};

export const isInternationalCurrency = currency => currency !== 'INR';

const generateVendorOrBrandId = (brandOrVendor, brand) => {
  return brandOrVendor === 'vendor'
    ? { vendorId: brand }
    : { brand: brand === VAL_BRAND_MMT ? 'MMT' : 'GI' };
};

export const createTabDataVariables = ({
  hotelCode,
  brand = '',
  isStayingDate = false,
  duration,
  activeTab,
  isMinCompetitorsAvailable,
}) => {
  const comparisonData = {};
  const customMetricData = {
    custom_metric: ['boost_pc_prev'],
  };

  if (isMinCompetitorsAvailable) {
    comparisonData.comparison = ['compset'];
    customMetricData.custom_metric.push('share');
  }
  return Object.values(tabsData).reduce((result, current, i) => {
    return {
      ...result,
      [`AnalyticsHistogram${i + 1}RequestData`]: {
        analyticsHistogram: {
          modelType: 'modelType1',
          endPointId: 1,
          body: {
            pipeline: current.pipeline,
            include: {
              ...(brand
                ? generateVendorOrBrandId(current.vendorOrBrand, brand)
                : {}),
              [`${current.hidKey}`]: hotelCode,
              ...(current.shouldIncludeConfirmStatusValue
                ? {
                  confirmStatusValue: [
                    'pending',
                    'confirmed',
                    'amended',
                    'noshow',
                    'aborted',
                  ],
                }
                : {}),
            },
            intervals: [
              {
                field: isStayingDate ? current.fieldKeySD : current.fieldKey,
                type: 'range',
                range_intervals:
                  generateRangeIntervalsForBookingsDateOrSearchAndStayingDate(
                    duration,
                    //if fieldkey is booking date then date format should not be applied of stayingDate or searchDate
                    (isStayingDate && current.fieldKeySD !== 'bookingDate') ||
                      current.fieldKey === 'searchDate',
                  ),
              },
            ],
            KPI: [
              {
                [`${isStayingDate ? current.kpiKeySD : current.kpiKey}`]:
                  current.kpiValue,
              },
            ],
            //active check to load only required data per active tab
            ...(activeTab === current.name ? comparisonData : {}),
            ...(activeTab === current.name
              ? customMetricData
              : { custom_metric: ['boost_pc_prev'] }),
          },
        },
      },
    };
  }, {});
};

export const createPageWiseVariables = ({
  pageName,
  brand = '',
  hotelId,
  durationVal,
  fieldVal,
  isMinCompetitorsAvailable,
}) => {
  const value = durationVal.split('-');
  const period = value[0];
  const interval = value[1];

  const isOneMonthFilter = durationVal === VAL_LAST_1M;
  const isStayingDate = fieldVal === VAL_STAYING_DATE;

  let ltDate = new Date();
  let gteDate = substractFromToday(parseInt(period) * 1, interval);

  //for days it show start from 1 day before the current date
  if (interval === 'days') {
    const { endInterval, startInterval } =
      startAndEndofIntervalFromToday(period);
    //include todays date
    ltDate = endInterval;
    gteDate = startInterval;
  }

  return Object.values(analyticsPages[pageName]).reduce((result, current) => {
    return {
      ...result,
      [`AnalyticsHistogram${current.nodeKey.match(/(\d+)/)[0]}RequestData`]: {
        analyticsHistogram: {
          modelType: 'modelType1',
          endPointId: 1,
          body: {
            pipeline: current.pipeline,
            include: {
              //for some apis brand name is epspected in brand field and in some in vendorId field
              ...(brand
                ? generateVendorOrBrandId(current.brandOrVendorId, brand)
                : {}),
              //for bookingDates, searchDate or Daywise.date
              // for one month filter custom intervals are created for graph, so dates are not required there
              ...(current.datesData.isOptional && isOneMonthFilter
                ? {}
                : {
                  [`${
                    isStayingDate
                      ? current.datesData.nameSD
                      : current.datesData.name
                  }`]: {
                    gte: getFormattedDate(
                      gteDate,
                      isStayingDate
                        ? current.datesData.gteFormatSD
                        : current.datesData.gteFormat,
                    ),
                    [`${
                      current.isLteGraph ||
                        (isStayingDate && current.isLteSDGraph)
                        ? 'lte'
                        : 'lt'
                    }`]: getFormattedDate(
                      ltDate,
                      isStayingDate
                        ? current.datesData.ltFormatSD
                        : current.datesData.ltFormat,
                    ),
                  },
                }),
              ...(current.isHotelCodeKeyDotHotelCode
                ? { 'hotel.hotelCode': hotelId }
                : { hotelId }),
              ...(current?.shouldAvoidConfirmStatusValue
                ? {}
                : {
                  confirmStatusValue: [
                    'pending',
                    'confirmed',
                    'amended',
                    'noshow',
                    'aborted',
                  ],
                }),
            },
            //for intervals
            ...(!current.intervals.isPresent
              ? {}
              : current.intervals.intervalAvailableFor === 'onlymonth'
                ? {
                  intervals: generateGraphIntervalsStayingDate(
                    durationVal,
                    fieldVal,
                    current.pipeline === 'demand_v2',
                  ),
                }
                : {
                  intervals: {
                    type: current.intervals.type,
                    //field is optional
                    ...(current.intervals.field
                      ? {
                        field: isStayingDate
                          ? current.intervals.fieldSD
                          : current.intervals.field,
                      }
                      : {}),
                    //intervals field is optional
                    ...(current.intervals.intervalsName
                      ? {
                        [`${current.intervals.intervalsName}`]:
                            current.intervals.intervalsValues,
                      }
                      : {}),
                  },
                }),

            //for kpi
            KPI: isStayingDate ? current.kpiValueSD : current.kpiValue,
            //for groupby
            ...(current?.groupBy || {}),
            //comparisonData
            ...(current.isComparisonData && isMinCompetitorsAvailable
              ? {
                comparison: ['compset'],
              }
              : {}),
            //customMetric
            ...(current.isCustom_metric
              ? {
                custom_metric: ['interval_share'],
              }
              : {}),
          },
        },
      },
    };
  }, {});
};
