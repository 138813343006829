export const actualDomainNames = {
  MMT: 'MakeMyTrip',
  GI: 'goibibo',
};
export const RTB_EXPIY_TIME = 24;

export const CAMPGAIN_MANAGER_KEY = {
  GOSTAYS: {
    id: 'GO-STAY',
    label: 'goStays',
  },
  GOMMT_ADVANTAGE: {
    id: 'MMT-ADVANTAGE',
    label: 'Go-MMT Select',
  },
  DSD: {
    id: 'DSD',
    label: 'Daily Steal Deal',
  },
} as const;

export const MANDATORY = 'Mandatory';
