import { Checkbox } from '@mui/material';
import { OptionType } from '../Dropdown.types';

type MultiSelectOptionRenderHook = ({
  valueField,
  labelField,
  allSelected,
  classesCheckbox,
  SELECT_ALL_OPTION,
}: {
  valueField: string;
  labelField: string;
  allSelected: boolean | number;
  classesCheckbox: string;
  SELECT_ALL_OPTION: {
    label: string;
    value: string;
  };
}) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multiSelectOptionProps: any,
  option: OptionType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
) => JSX.Element;

const useMultiSelectOptionRender: MultiSelectOptionRenderHook = ({
  valueField,
  labelField,
  allSelected,
  classesCheckbox,
  SELECT_ALL_OPTION,
}) => {
  return (multiSelectOptionProps, option, state) => {
    let selectAllProps = {};
    if (option[valueField] === SELECT_ALL_OPTION.label) {
      selectAllProps = { checked: allSelected };
    }
    return (
      <div {...multiSelectOptionProps}>
        <li>
          <Checkbox
            className={classesCheckbox}
            checked={state.selected}
            {...selectAllProps}
          />
          {option[labelField]}
        </li>
      </div>
    );
  };
};

export default useMultiSelectOptionRender;
