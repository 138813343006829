import { createFilterOptions } from '@mui/material';
import {
  type AutoCompleteProps,
  type GetFilteredResults,
  type OptionLabel,
} from './__LegacyDropdown__/Dropdown.types';

export const getFilteredResults: GetFilteredResults = (
  inputValue: string,
  options: AutoCompleteProps['options'],
  optionLabel: OptionLabel,
) => {
  const filter = createFilterOptions();
  const results = inputValue.length
    ? (filter(options as [], {
      inputValue: inputValue,
      getOptionLabel: optionLabel,
    }) as unknown[])
    : options;
  return results;
};

type CheckIfAllSelectedType = (
  tagOptions: AutoCompleteProps['options'],
  tags: AutoCompleteProps['value'],
  valueField: string,
) => boolean | number;

export const checkIfAllSelected: CheckIfAllSelectedType = (
  tagOptions,
  tags,
  valueField,
) => {
  const tagOptionValues = tagOptions.map(ele => ele[valueField]);
  const tagValues = Array.isArray(tags) && tags?.map(ele => ele[valueField]);
  return (
    tagOptionValues.every(item => tagValues.indexOf(item) !== -1) &&
    tagOptions.length &&
    Array.isArray(tags) &&
    tags.length
  );
};
