import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Typography,
  InlineMsgAlerts,
  Radio,
  Input as InputLatest,
  Tag,
  Button,
  Dropdown,
  Divider,
} from 'components/latest-core';
import { useLangStrings } from 'utilities/CustomHooks';
import { downloadWavierDocument } from '../../BookingsApiClient';
import {
  getWaiverDetails,
  initiateWaiver,
} from 'modules/bookings-revamp/BookingsGraphClient';
import { Waiver } from '../../bookings.types';
import { getDateDifference, getFormattedDate } from 'utilities/DateUtils';

import { fixedFloatValue, showMessage } from 'utilities/Utils';
import { FileDownload } from 'assets/common';
import { pushToOmniture } from 'utilities/gtm';

import './OfferRefund.scss';

const OFFER = 'offer';
const REJECT = 'reject';

type CompDataTypes = {
  vendorBookingId: string;
  bookingName: string;
  bookingDate: string;
  checkin: string;
  checkout: string;
  isMultiRoom: boolean;
  parentVendorBookingId: string;
  bookingId: string;
  currency: string;
};

const getRejectTypeOptions = OFFER_REFUND_POPUP => {
  const { GUEST_CHECKIN, INVALID_REASON, INVALID_DOC, STRICT_POLICY } =
    OFFER_REFUND_POPUP;
  return [STRICT_POLICY, INVALID_REASON, INVALID_DOC, GUEST_CHECKIN];
};

const getStayDatesString = (checkIn, checkOut) => {
  if (checkIn && checkOut) {
    return `${getFormattedDate(
      new Date(checkIn),
      'DD MMM YYYY',
    )} - ${getFormattedDate(new Date(checkOut), 'DD MMM YYYY')}`;
  }
  return 'NA';
};

type CompProps = {
  setFooterOption?: (value: string) => void;
  data: CompDataTypes; //Mahesh to change type according to data passed & add in BookingsListCard for offerRefund State
  setData?: (val) => void;
  onWaiverSuccess?: () => void;
  onWaiverFailure?: (val: string) => void;
  refreshBookingDetails?: () => void;
  isL1?: boolean;
  onClose?: (type?: string | undefined) => void;
};

type RejectReason = {
  label: string;
  value: string;
};

const OfferRefundComp = ({
  STRINGS,
  value,
  setVal,
  bkgAmt: bookingAmt,
  currencyNotation,
}) => {
  const guestRefundValue: string = fixedFloatValue(
    String((value / 100) * bookingAmt),
    2,
  );
  const hotelierRefundValue: string = fixedFloatValue(
    String(bookingAmt - Number(guestRefundValue)),
    2,
  );

  return (
    <div className="bg-color-light p-3 offer-refund-new">
      <Typography variant="body1" className="mb-1">
        {STRINGS.OFFER_REFUND_DETAILS.REFUND_AMOUNT_TEXT}
      </Typography>
      <InputLatest
        value={value}
        min={1}
        max={100}
        onChange={setVal}
        onBlur={e => {
          if (e.target.value === '') {
            setVal(1);
          }
        }}
        type="number"
        className="!w-[111px]"
        rightElement="%"
      />
      <Typography
        variant="subtitle1"
        themeColor="text.secondary"
        className="mt-[10px] mb-1 uppercase"
      >
        {STRINGS.OFFER_REFUND_DETAILS.REFUND_SUMMARY}
      </Typography>
      <table>
        <tr>
          <th></th>
          <th>
            <Typography variant="subtitle1">{'%'}</Typography>
          </th>
          <th>
            <Typography variant="subtitle1">
              {STRINGS.OFFER_REFUND_DETAILS.REFUND_AMOUNT_TEXT2}
            </Typography>
          </th>
        </tr>
        <tr>
          <td>
            <Typography variant="subtitle1">
              {STRINGS.OFFER_REFUND_DETAILS.GUESTS_REFUND}
            </Typography>
          </td>
          <td>
            <Typography variant="subtitle1">{value}%</Typography>
          </td>
          <td>
            <Typography variant="subtitle1">
              {currencyNotation} {guestRefundValue}
            </Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography variant="subtitle1">
              {STRINGS.BOOKING_LIST_CARD_LABELS.CANCELLATION_CHARGES}
            </Typography>
          </td>
          <td>
            <Typography variant="subtitle1">{100 - value}%</Typography>
          </td>
          <td>
            <Typography variant="subtitle1">
              {' '}
              {currencyNotation} {hotelierRefundValue}
            </Typography>
          </td>
        </tr>
      </table>
    </div>
  );
};

const RefundRejection = props => {
  const { STRINGS, rejectOptions, rejectReason, setRejectReason } = props;

  return (
    <div className="mt-2 ml-6">
      <Typography variant="body1" className="mb-1">
        {STRINGS.OFFER_REFUND_DETAILS.REASON_FOR_REJECT_TITLE}{' '}
        <span className="text-error-dark">*</span>
      </Typography>

      <Dropdown
        options={rejectOptions}
        value={rejectReason}
        className="reject-reason-dropdown"
        renderOption={(tempProps, option, { selected }) => {
          return (
            <div
              {...(tempProps as Object)}
              className={`py-2 px-3 cursor-pointer  hover:bg-color-primary ${
                selected ? 'text-primary-default font-medium' : ''
              }`}
            >
              {option.toString()}
            </div>
          );
        }}
        variant="default"
        onChange={(obj: RejectReason) => setRejectReason(obj)}
        disableClearable
      />
    </div>
  );
};

export const OfferRefundNew = (props: CompProps) => {
  const {
    data,
    setData,
    onClose,
    setFooterOption,
    onWaiverSuccess,
    onWaiverFailure,
    refreshBookingDetails,
  } = props;

  const {
    vendorBookingId,
    bookingName,
    bookingDate,
    checkin,
    checkout,
    isMultiRoom,
    parentVendorBookingId,
    bookingId: confirmBookingId,
    currency,
  } = data;

  const [STRINGS, COMMON] = useLangStrings<'BookingsRevamp'>('BookingsRevamp');

  const dropdownOption = getRejectTypeOptions(
    STRINGS.OFFER_REFUND_DETAILS.REJECT_TYPE_OPTIONS,
  );

  const [selectedValue, setSelectedValue] = useState(OFFER);
  const [offerRefund, setOfferRefund] = useState(100);
  const [rejectReason, setRejectReason] = useState(dropdownOption[0]);

  const [waiverData, setWaiverData] = useState<Waiver>();

  const requestDaysDiff =
    getDateDifference(new Date(), waiverData?.waiver_requested) || 0;

  const currencyNotation = currency === 'INR' ? '₹' : currency;

  const subTitle =
    requestDaysDiff > 0
      ? STRINGS.OFFER_REFUND_DETAILS.SUB_TITLE.replace(
        '__DAYS__',
        `${
          requestDaysDiff +
            (requestDaysDiff > 1
              ? ` ${COMMON.LABELS.DAYS}`
              : ` ${COMMON.LABELS.DAY}`)
        }`,
      )
      : COMMON.LABELS.TODAY;

  const options = [
    {
      label: (
        <div className="flex">
          <Typography variant="body1">
            {STRINGS.FOOTER_OPTIONS_LABELS.OFFER_REFUND_1}
          </Typography>
          <Tag
            label={COMMON.LABELS.RECOMMENDED}
            size="small"
            color="success"
            className="ml-1"
          />
        </div>
      ),
      description: selectedValue === OFFER && (
        <OfferRefundComp
          STRINGS={STRINGS}
          value={offerRefund}
          setVal={setOfferRefund}
          bkgAmt={waiverData?.bkgAmt}
          currencyNotation={currencyNotation}
        />
      ),
      value: OFFER,
    },
    {
      label: (
        <div>
          <Typography variant="body1">
            {STRINGS.OFFER_REFUND_DETAILS.REJECT_REFUND_REQ}
          </Typography>
        </div>
      ),
      description: selectedValue === REJECT && (
        <RefundRejection
          STRINGS={STRINGS}
          rejectOptions={dropdownOption}
          rejectReason={rejectReason}
          setRejectReason={setRejectReason}
        />
      ),
      value: REJECT,
    },
  ];

  const handleRefundReject = () => {
    const showRejection = selectedValue === 'reject';

    const tempData = {
      booking_id: isMultiRoom ? parentVendorBookingId : confirmBookingId,
      is_vendor_parent_booking_id: isMultiRoom,
      waiver_status: showRejection ? 0 : 1,
      waiver_message: '',
    };

    pushToOmniture({
      event: 'ctaClick',
      pageName: 'refund_requests',
      cta: {
        name: showRejection
          ? 'reject_refund_requests'
          : 'confirm_refund_request',
        componentName: 'refund_requests',
        type: 'click',
      },
      loadedComponents: vendorBookingId,
    });

    if (!showRejection) {
      tempData.waiver_message =
        STRINGS.OFFER_REFUND_DETAILS.WAIVER_MESSAGE_ON_HOTEL_OFFERING;
      // @ts-ignore
      tempData.waiver_percent = offerRefund;
    } else if (showRejection) {
      tempData.waiver_message = rejectReason;
    }
    initiateWaiver(tempData)
      .then(resp => {
        if (resp.success) {
          showMessage({
            type: 'success',
            message: showRejection
              ? STRINGS.OFFER_REFUND_DETAILS.REJECT_REASON_SUCCESS
              : STRINGS.OFFER_REFUND_DETAILS.CONFIRM_REFUND_SUCCESS,
            show: true,
          });

          if (showRejection) {
            onWaiverFailure?.(rejectReason);
          } else {
            onWaiverSuccess?.();
          }
          refreshBookingDetails?.();
          onClose?.('success');
        } else {
          showMessage({
            type: 'error',
            message: resp.message,
            show: true,
          });
        }
        if (setFooterOption) setFooterOption('');
        if (resp) {
          setData?.(null);
        }
      })
      .catch(error => {
        console.error('Error initiating waiver', error);
      });
  };

  const performDownloadReport = doc => {
    downloadWavierDocument(doc)
      .then(docURL => {
        window.open(docURL as string);
      })
      .catch(() =>
        showMessage({
          show: true,
          message: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
          type: 'error',
        }),
      );
  };

  const handleDownloadDocs = () => {
    pushToOmniture({
      event: 'ctaClick',
      pageName: 'refund_requests',
      cta: {
        name: 'download_document',
        componentName: 'refund_requests',
        type: 'click',
      },
      loadedComponents: vendorBookingId,
    });

    waiverData.wavierDocument.forEach(doc => performDownloadReport(doc));
  };

  useEffect(() => {
    getWaiverDetails(
      isMultiRoom ? parentVendorBookingId : confirmBookingId,
      isMultiRoom,
    )
      .then(resp => {
        setWaiverData(resp);
        // setOfferRefund(100 - data.cancellationPercent);
      })
      .catch(error => {
        console.error('Error fetching waiver details', error);
      })
      .finally(() => {
        pushToOmniture({
          event: 'pageView',
          pageName: 'refund_requests',
          loadedComponents: vendorBookingId,
        });
      });
  }, []);

  const onDrawerClose = () => {
    pushToOmniture({
      event: 'ctaClick',
      pageName: 'refund_requests',
      cta: {
        name: 'cancel_refund_request',
        componentName: 'refund_requests',
        type: 'click',
      },
      loadedComponents: vendorBookingId,
    });

    if (onClose) {
      onClose();
    } else {
      setData?.(null);
    }
  };

  return (
    <Drawer
      isOpen
      header={{
        title: STRINGS.OFFER_REFUND_DETAILS.TITLE,
        subTitle: subTitle,
      }}
      footer={{
        primaryBtn: {
          text:
            selectedValue === 'offer'
              ? STRINGS.OFFER_REFUND_DETAILS.CONFIRM_REFUND
              : STRINGS.OFFER_REFUND_DETAILS.CONFIRM_THIS_REJECTION,
          onClick: handleRefundReject,
        },
        secondaryBtn: {
          text: COMMON.BUTTONS.CANCEL,
          onClick: onDrawerClose,
        },
      }}
      onClose={() => {
        if (setFooterOption) setFooterOption('');
        onDrawerClose();
      }}
      preventBackDropClick
    >
      <div className="offer-refund-new-comp">
        <Typography variant="subHeadingMedium" className="mb-4 !font-bold">
          {STRINGS.OFFER_REFUND_DETAILS.BOOKING_DETAILS}
        </Typography>
        <div className="flex">
          <div className="w-1/2">
            <Typography
              variant="subtitle1"
              className="uppercase"
              themeColor="text.secondary"
            >
              {STRINGS.OFFER_REFUND_DETAILS.BOOKED_BY}
            </Typography>
            <Typography variant="body1" className="mt-[3px]">
              {bookingName}
            </Typography>
          </div>
          <div className="w-1/2">
            <Typography
              variant="subtitle1"
              className="uppercase"
              themeColor="text.secondary"
            >
              {STRINGS.OFFER_REFUND_DETAILS.BOOKING_ID}
            </Typography>
            <Typography variant="body1" className="mt-[3px]">
              {vendorBookingId}
            </Typography>
          </div>
        </div>
        <div className="flex mt-3">
          <div className="w-1/2">
            <Typography
              variant="subtitle1"
              className="uppercase"
              themeColor="text.secondary"
            >
              {STRINGS.OFFER_REFUND_DETAILS.BOOKING_DATE}
            </Typography>
            <Typography variant="body1" className="mt-[3px]">
              {bookingDate
                ? getFormattedDate(new Date(bookingDate), 'DD MMM YYYY')
                : 'NA'}
            </Typography>
          </div>
          <div className="w-1/2">
            <Typography
              variant="subtitle1"
              className="uppercase"
              themeColor="text.secondary"
            >
              {STRINGS.OFFER_REFUND_DETAILS.STAY_DATES}
            </Typography>
            <Typography variant="body1" className="mt-[3px]">
              {getStayDatesString(checkin, checkout)}
            </Typography>
          </div>
        </div>
        <div className="flex mt-3">
          <div className="w-1/2">
            <Typography
              variant="subtitle1"
              className="uppercase"
              themeColor="text.secondary"
            >
              {STRINGS.OTHER_BOOKING_DETAILS.CANCELLATION_POLICY}
            </Typography>
            <Typography variant="body1" className="mt-[3px]">
              {STRINGS.OFFER_REFUND_DETAILS.NON_REFUNDABLE}
            </Typography>
          </div>
          <div className="w-1/2">
            <Typography
              variant="subtitle1"
              className="uppercase"
              themeColor="text.secondary"
            >
              {STRINGS.OFFER_REFUND_DETAILS.NET_AMOUNT}
            </Typography>
            <Typography variant="body1" className="mt-[3px]">
              {waiverData?.bkgAmt}
            </Typography>
          </div>
        </div>
        <div className="flex mt-3">
          <div>
            <Typography
              variant="subtitle1"
              className="uppercase"
              themeColor="text.secondary"
            >
              {STRINGS.OFFER_REFUND_DETAILS.ROOM_BOOKED}
            </Typography>
            <Typography variant="body1" className="mt-[3px]">
              {waiverData?.roomDetails}
            </Typography>
          </div>
        </div>

        <Divider className="my-3" />

        <div>
          <Typography variant="subHeadingMedium" className="mb-4">
            {STRINGS.OFFER_REFUND_DETAILS.GUEST_REFUND_REQ}
          </Typography>
          <div className="flex">
            <div className="w-1/2">
              <Typography
                variant="subtitle1"
                className="uppercase"
                themeColor="text.secondary"
              >
                {STRINGS.OFFER_REFUND_DETAILS.REFUND_REQ_REASON}
              </Typography>
              <Typography variant="body1" className="mt-[3px]">
                {waiverData?.waiverReason}
              </Typography>
            </div>
            <div className="w-1/2">
              <Typography
                variant="subtitle1"
                className="uppercase"
                themeColor="text.secondary"
              >
                {STRINGS.OFFER_REFUND_DETAILS.SHARED_DOC}
              </Typography>
              <div
                onClick={handleDownloadDocs}
                className="w-fit flex items-start mt-[6px]"
              >
                <Button
                  variant="text"
                  className="text-primary-default underline mr-1 !uppercase !font-normal !text-[14px]"
                >
                  {COMMON.BUTTONS.DOWNLOAD}
                </Button>
                <img
                  src={FileDownload}
                  alt="pdf"
                  width="15px"
                  className="cursor-pointer mt-[3px]"
                />
              </div>
            </div>
          </div>
        </div>

        <Divider className="my-3" />
        <div>
          <Typography variant="subHeadingMedium" className="mb-2">
            {STRINGS.OFFER_REFUND_DETAILS.RESP_FOR_REFUND_REQ}
          </Typography>

          <InlineMsgAlerts
            severity="info"
            inerClasses="!items-start"
            message={STRINGS.OFFER_REFUND_DETAILS.LOYALTY_INFO}
          />

          <Typography variant="body1" className="mt-4 mb-2">
            {STRINGS.OFFER_REFUND_DETAILS.MANAGE_REFUND_REQ}
          </Typography>

          <Radio
            options={options}
            value={selectedValue}
            onChange={setSelectedValue}
            customClasses={{
              formRoot: 'offer-refund-radio',
            }}
            // className="offer-refund-radio"
          />
        </div>
      </div>
    </Drawer>
  );
};
