import React, { useMemo } from 'react';
import { classNames } from 'utilities/Utils';
import Button from 'components/latest-core/Button/Button';
import type { DrawerFooterPropTypes } from '../Drawer.types';

function DrawerFooter(props: DrawerFooterPropTypes) {
  const { footer, footerClasses, classes } = props;
  const { root = '', primaryBtn = '', secondaryBtn = '' } = footerClasses || {};

  const buttonComponentList = useMemo(() => {
    const buttonConfig = [
      {
        btn: footer?.primaryBtn,
        className: classNames(classes.footerPrimaryBtnContainer, primaryBtn),
        variant: 'contained',
        defaultText: 'Save',
      },
      {
        btn: footer?.secondaryBtn,
        className: classNames(
          classes.footerSecondaryBtnContainer,
          secondaryBtn,
        ),
        variant: 'text',
        defaultText: 'Cancel',
      },
    ] as const;
    const buttons = buttonConfig
      .filter(({ btn }) => btn)
      .map(({ btn, className, variant, defaultText }) => (
        <Button
          onClick={btn.onClick}
          disabled={btn?.disabled}
          className={className}
          variant={variant}
        >
          {btn.text || defaultText}
        </Button>
      ));
    return footer?.showPrimaryBtnOnFirst ? buttons : buttons.reverse();
  }, [footer, classes, footerClasses]);

  return (
    footer && (
      <div className={classNames(classes.footerContainer, root)}>
        {buttonComponentList.map((button, index) => (
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
      </div>
    )
  );
}

export default React.memo(DrawerFooter);
