import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Button from 'components/latest-core/Button/Button';
import Typography from 'components/latest-core/Typography/Typography';
import { useEffect, useRef } from 'react';
import { classNames } from 'utilities/Utils';
const getHours = is24HrFormat => {
  return new Array(is24HrFormat ? 24 : 12)
    .fill(0)
    .map((_, i) => (i + Number(!is24HrFormat)).toString().padStart(2, '0'));
};

const getMinutes = gap => {
  const minuteInterval = 60 / Math.ceil(60 / gap);

  return new Array(60 / minuteInterval)
    .fill(0)
    .map((_, i) => (i * minuteInterval).toString().padStart(2, '0'));
};
const intervals = ['am', 'pm'];
export const PopOver = ({
  closePopoverOnSelect,
  showNowButton,
  classes,
  setSelected,
  is24HrFormat,
  minuteInterval,
  handleNowClick,
  handleClose,
  selected,
  disabledHours,
}) => {
  const hrRef = useRef(null);
  const minRef = useRef(null);
  const hours = getHours(is24HrFormat);
  const minutes = getMinutes(minuteInterval);
  const textClass =
    'w-10 h-9 flex items-center justify-center cursor-pointer mx-1 flex-shrink-0';
  useEffect(() => {
    // hrScroll
    const hrIndex = hours.findIndex(val => val === selected?.hour);
    const scrollToHr = selected?.hour ? 36 * hrIndex : '72';
    hrRef.current?.scrollTo({ top: scrollToHr, behavior: 'smooth' });
    //min scroll
    const minIndex = minutes.findIndex(val => val === selected?.minute);
    const scrollToMin = selected?.minute ? 36 * minIndex : '72';
    minRef.current?.scrollTo({ top: scrollToMin, behavior: 'smooth' });
  }, [selected]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-start border border-solid border-divider rounded-t">
        <Typography variant="body1" className={textClass}>
          {'hh'}
        </Typography>
        <Divider className="h-9 w-[1px]" />
        <Typography variant="body1" className={textClass}>
          {'mm'}
        </Typography>
      </div>
      <div
        className={`flex border border-solid border-divider border-y-0 ${
          !closePopoverOnSelect || showNowButton
            ? ''
            : 'rounded-b border-b-[1px]'
        }`}
      >
        <Grid className={`${classes.hours}`} alignItems="center" ref={hrRef}>
          {hours.map((time, i) => (
            <Typography
              key={i}
              onClick={() => setSelected(prev => ({ ...prev, hour: time }))}
              variant="body1"
              className={classNames(
                textClass,
                time === selected?.hour && classes.selected,
                i === 0 && 'mt-14',
                i + 1 === hours.length && 'mb-14',
                disabledHours?.includes(time) && 'disabled',
              )}
            >
              {time}
            </Typography>
          ))}
        </Grid>
        <Divider className="h-[140px] mt-1 w-[1px]" />
        <Grid className={classNames(classes.hours)} id="mm-list" ref={minRef}>
          {minutes.map((time, i) => (
            <Typography
              key={i}
              onClick={() => setSelected(prev => ({ ...prev, minute: time }))}
              variant="body1"
              className={classNames(
                textClass,
                time === selected?.minute && classes.selected,
                i === 0 && 'mt-14',
                i + 1 === minutes.length && 'mb-14',
              )}
            >
              {time}
            </Typography>
          ))}
        </Grid>
        {!is24HrFormat && (
          <>
            <Divider
              className={`h-[72px] w-[1px] ${
                selected?.interval
                  ? selected?.interval === 'am'
                    ? 'mt-14'
                    : 'mt-5'
                  : 'mt-[38px]'
              }`}
            />
            <div
              className={`flex flex-col justify-center h-[148px]  ${
                selected?.interval
                  ? selected?.interval === 'am'
                    ? 'pt-9'
                    : 'pb-9'
                  : ''
              }`}
            >
              {intervals.map((interval, i) => (
                <Typography
                  key={i}
                  onClick={() =>
                    setSelected(prev => ({ ...prev, interval: interval }))
                  }
                  variant="body1"
                  className={classNames(
                    textClass,
                    interval === selected?.interval && classes.selected,
                  )}
                >
                  {interval}
                </Typography>
              ))}
            </div>
          </>
        )}
      </div>
      {(!closePopoverOnSelect || showNowButton) && (
        <div
          className={classNames(
            classes.footer,
            'h-10 relative full box-border',
          )}
        >
          {showNowButton && (
            <Button
              variant="text"
              size="small"
              onClick={handleNowClick}
              className="absolute left-2 top-2"
            >
              Now
            </Button>
          )}
          {!closePopoverOnSelect && (
            <Button
              onClick={handleClose}
              size="small"
              className="absolute right-2"
            >
              Done
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
