import ListOptions from '../Components/RevampVersion/ListOptions';
import { classNames } from 'utilities/Utils';
import { ListSubheader } from '@mui/material';

type MultiSelectRenderedOptionsProps = {
  variant: string;
  options: unknown[];
  value: unknown;
  optionLabel: (item: string) => string;
  valueLabel: (item: string) => string;
  valueField?: string;
  isGrouped?: boolean;
  groupByField?: (option: unknown[]) => string;
};

const renderGroupedOptions = ({
  group,
  showNoBorder,
  allGroups,
  optionLabel,
  value,
  selectValLabel,
}) => {
  const items = allGroups[group].map((option, idx) => {
    const isSelected =
      Array.isArray(value) &&
      value.findIndex(opt => optionLabel(opt) === optionLabel(option)) > -1;
    return (
      <ListOptions
        key={`${group}-${idx}`}
        labelField={optionLabel(option)}
        valueField={selectValLabel(option)}
        isSelected={isSelected}
        value={selectValLabel(option)}
      />
    );
  });

  return [
    <ListSubheader
      key={`${group}-header`}
      className={classNames(
        'text-text-primary border-0 border-solid border-t-common-divider mx-1 !pl-2 !text-[12px] !leading-4 !h-4 !pt-2 !pb-4',
        showNoBorder ? 'border-t-0' : 'border-t-[1px]',
      )}
    >
      {group}
    </ListSubheader>,
    items,
  ];
};

/**
 * Custom hook for rendering options in a multi-select dropdown.
 *
 * @param {MultiSelectRenderedOptionsProps} props - The props for the hook.
 * @returns {Object} - An object containing the renderOption function.
 */
const useMultiSelectRenderedOptions = ({
  isGrouped,
  groupByField,
  variant,
  options,
  value,
  optionLabel,
  valueLabel,
  valueField,
}: MultiSelectRenderedOptionsProps) => {
  const optionsWithGroup = displayedOptions => {
    if (variant === 'multiSelectGrouped' && isGrouped) {
      return displayedOptions.reduce((acc, option) => {
        const group = groupByField(option);
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(option);
        return acc;
      }, {});
    }
    return options;
  };

  const renderOption = (displayedOptions, selectValLabel) => {
    if (variant === 'multiSelectGrouped' && isGrouped) {
      const allGroups = optionsWithGroup(displayedOptions);
      return Object.keys(allGroups).map(group =>
        renderGroupedOptions({
          group,
          showNoBorder: group === Object.keys(allGroups)[0],
          allGroups,
          optionLabel,
          value,
          selectValLabel,
        }),
      );
    }
    return displayedOptions.map((option, idx) => {
      const isSelected =
        Array.isArray(value) &&
        value.findIndex(opt => {
          if (valueField) {
            return valueLabel(opt) === valueLabel(option);
          }
          return optionLabel(opt) === optionLabel(option);
        }) > -1;
      return (
        <ListOptions
          key={idx}
          labelField={optionLabel(option)}
          valueField={selectValLabel(option)}
          isSelected={isSelected}
          value={selectValLabel(option)}
        />
      );
    });
  };

  return { renderOption };
};

export default useMultiSelectRenderedOptions;
