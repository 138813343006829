import { doQuery } from 'base/GraphQLClient';
import { getValue } from 'utilities/Utils';
import { CHAT_UNREAD_COUNT } from './ChatDiscoveryQueries';

export function fetchChatUnreadCount(hotelCode, { showLoader = true } = {}) {
  const query = CHAT_UNREAD_COUNT;

  const variables = {
    rawEndpointRequestData: {
      unreadChatDetails: {
        modelType: 'modelType1',
        queryParams: {
          hotelcode: hotelCode,
        },
      },
    },
  };

  return doQuery(query, variables, { useLoader: showLoader }).then(response => {
    const success = getValue(
      response,
      'data.rawEndpoint.unreadChatDetails.modelType1[success]',
    );
    if (success) {
      const lastUnreadChatMsg = getValue(
        response,
        'data.rawEndpoint.unreadChatDetails.modelType1[last_unread_chat]',
      );
      const totalUnreadMsg = getValue(
        response,
        'data.rawEndpoint.unreadChatDetails.modelType1[total_unread_message]',
      );

      return { lastUnreadChatMsg, totalUnreadMsg };
    }
    const errorMessage = getValue(
      response,
      'data.rawEndpoint.unreadChatDetails.modelType1[error]',
    );
    throw new Error(errorMessage);
  });
}
