import React from 'react';
import { Close } from 'assets/common';
import { classNames } from 'utilities/Utils';
import type { DrawerHeaderPropTypes } from '../Drawer.types';

function DrawerHeader(props: DrawerHeaderPropTypes) {
  const { header, onClose, headerClasses, classes } = props;
  const {
    root = '',
    title = '',
    subTitle = '',
    leftElem = '',
    rightCrossElem = '',
    rightElem = '',
  } = headerClasses || {};

  return (
    <div className={classNames(classes.headerContainer, root)}>
      {header.leftElem && <div className={leftElem}>{header.leftElem}</div>}
      <div className={classes.headerChildContainer}>
        {header.title && (
          <div className={classNames(classes.headerTitleContainer, title)}>
            {header.title}
          </div>
        )}
        {header.subTitle && (
          <div
            className={classNames(classes.headerSubTitleContainer, subTitle)}
          >
            {header.subTitle}
          </div>
        )}
      </div>
      {!header.hideCross && (
        <div
          onClick={onClose}
          className={classNames(
            classes.headerCrossIconContainer,
            rightCrossElem,
          )}
        >
          <Close />
        </div>
      )}
      {header.rightElem && <div className={rightElem}>{header.rightElem}</div>}
    </div>
  );
}

export default React.memo(DrawerHeader);
