import { groupBy } from '../../../../utilities/Utils';
import {
  AttributeData,
  CategorizedTemplates,
  LeafCategory,
  ServiceNode,
  ServicesTemplateResponse,
  ServiceValidationInput,
} from '../types/Schema';

export type TemplatesState = {
  templateCategories: Array<CategorizedTemplates>;
  optionalTemplates: Array<LeafCategory>;
  leafCategoryById: Record<number, LeafCategory>;
};

export function mergeInclusionsAndTemplates(
  templates: ServicesTemplateResponse,
): TemplatesState {
  const leafCategoryById = templates.serviceTemplateData.reduce<
  Record<number, LeafCategory>
  >((acc, e) => {
    return {
      ...acc,
      [e.serviceId]: e,
    };
  }, {});
  const { mandatory, optional: optionalTemplates } = groupBy(
    templates.serviceTemplateData,
    item => (item.isMandatoryForPackage ? 'mandatory' : 'optional'),
  );
  const groupedTemplates = groupBy(
    mandatory || [],
    item => item.subCategoryName,
  );
  const templateCategories = templates.categoryOrder
    .map<CategorizedTemplates>(categoryName => ({
    templates: groupedTemplates[categoryName] || [],
    categoryName: categoryName,
  }))
    .filter(value => value.templates.length > 0);
  return {
    templateCategories,
    optionalTemplates: optionalTemplates || [],
    leafCategoryById,
  };
}

const halfWidthFieldTypes = new Set([
  /*'number_select', */ 'date',
  'time_select',
]);
export const isHalfWidthType = (attr: AttributeData): boolean =>
  halfWidthFieldTypes.has(attr.renderType);
function attributesGrid(attrs: AttributeData[]): AttributeData[][] {
  return attrs.reduce<AttributeData[][]>((acc, e) => {
    if (acc.length === 0) {
      return [[e]];
    }
    const isStartField = e.label.toLowerCase().indexOf('start') === 0;
    const currentIsHalfWidth = isHalfWidthType(e);
    const prevGroup = acc.pop()!;
    if (currentIsHalfWidth && !isStartField) {
      const prevIsAlsoHalfWidth = isHalfWidthType(prevGroup[0]);
      const prevGroupNotFilled = prevGroup.length < 2;
      const labelCanFit = e.label.length <= 25;
      if (
        !isStartField &&
        prevIsAlsoHalfWidth &&
        prevGroupNotFilled &&
        labelCanFit
      ) {
        return [...acc, [...prevGroup, e]];
      }
    }
    return [...acc, prevGroup, [e]];
  }, []);
}

export type AttributeGroup = {
  label: string;
  attributesGroup: AttributeData[][];
};

const ignoreCriteria: Record<string, unknown[]> = {
  attributeKey: ['mlos', 'segment', 'offer_code'],
  labelCategory: ['Set-up Dates'],
};
const ignoreCriteriaKeys = Object.keys(ignoreCriteria);
const shouldIncludeAttribute = (attr: AttributeData) => {
  return ignoreCriteriaKeys.every(key => {
    const attrElement = attr[key];
    if (!attrElement) {
      return true;
    }
    return ignoreCriteria[key].indexOf(attrElement) === -1;
  });
};

export function groupAttributesByLabel(
  template: LeafCategory,
): AttributeGroup[] {
  const { serviceTemplateJson, labelOrder } = template;
  const filteredAttributes = serviceTemplateJson.filter(shouldIncludeAttribute);
  const categoryMap = groupBy(filteredAttributes, item => item.labelCategory);
  return labelOrder
    .filter(label => categoryMap[label]?.length)
    .map<AttributeGroup>(value => ({
    label: value,
    attributesGroup: attributesGrid(categoryMap[value]),
  }));
}

export function getAttributesKeyIdMap(
  template: LeafCategory,
): Record<string, number> {
  const { serviceTemplateJson } = template;
  return serviceTemplateJson.reduce((acc, item) => {
    return {
      ...acc,
      [item.settingKey]: item.attributeId,
    };
  }, {} as Record<string, number>);
}

export const createServiceInput = (
  servicesIncl: ServiceNode,
): ServiceValidationInput => ({
  subCategoryId: servicesIncl.leafCategory.subCategoryId,
  leafTemplateId: servicesIncl.leafCategory.serviceId,
  selectedAttributes: servicesIncl.selectedAttributes,
  status: servicesIncl.status === 'ACTIVE' ? 1 : 2,
  priority: servicesIncl.priorityOrder,
  id: servicesIncl.id || undefined,
});
