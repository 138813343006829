import React, { useEffect, useRef, useState } from 'react';

import { classNames } from 'utilities/Utils';
import { useResizeObserver } from 'utilities/CustomHooks';
import * as CommonIcons from 'assets/common';

import { ShowButtons, Position, type CarouselProps } from './Carousel.types';
import { useStyles } from './Carousel.styles';

export const Carousel = (props: CarouselProps) => {
  const {
    children,
    // 0-based index
    initialItem = 0,
    multiView = false,
    infiniteScroll = false,
    position = Position.start,
    showButtons = ShowButtons.both,
    customButtons = {},
    onLeftClick = () => {},
    onRightClick = () => {},
    shiftBy = 1,
    hideIndicator = false,
    customIndicator,
    customClasses = {},
    scrollToActiveItem = false,
    ...rest
  } = props;

  const [activeItem, setActiveItem] = useState(initialItem);
  const [isBtnDisabled, setIsBtnDisabled] = useState({
    left: true,
    right: true,
  });
  const sliderRef = useRef<HTMLDivElement>(null);
  const childrenRefs = useRef<HTMLDivElement[]>([]);

  const { width } = useResizeObserver(sliderRef, true);

  const childrenCount = React.Children.count(children);

  const showLeftButton =
    showButtons === ShowButtons.both ||
    (showButtons === ShowButtons.basic && activeItem > shiftBy - 1) ||
    infiniteScroll;
  const showRightButton =
    showButtons === ShowButtons.both ||
    (showButtons === ShowButtons.basic &&
      activeItem !== childrenCount - shiftBy) ||
    infiniteScroll;

  const styles = useStyles({ multiView });

  const getCenter = rect => {
    return (rect.left + rect.right) / 2;
  };

  const getScrollDistance = (
    parentRect,
    firstChildRect,
    nextChildRect,
    lastChildRect,
    activeSlidePosition,
  ) => {
    let shiftPx = 0;
    if (activeSlidePosition === Position.start)
      shiftPx = nextChildRect.left - parentRect.left;
    else if (activeSlidePosition === Position.center)
      shiftPx = getCenter(nextChildRect) - getCenter(parentRect);
    // button is disabled if infinite scroll is false and
    // active slide is first or last slide or the next slide is already in view
    setIsBtnDisabled({
      left:
        !infiniteScroll &&
        (activeItem < shiftBy ||
          parentRect.left <= firstChildRect.left - shiftPx),
      right:
        !infiniteScroll &&
        (activeItem >= childrenCount - shiftBy ||
          (!customClasses.activeSlide &&
            parentRect.right >= lastChildRect.right - shiftPx)),
    });
    return { shiftPx };
  };

  const scrollToSlide = itemId => {
    if (sliderRef.current) {
      const { shiftPx } = getScrollDistance(
        sliderRef.current.getBoundingClientRect(),
        childrenRefs.current[0].getBoundingClientRect(),
        childrenRefs.current[itemId].getBoundingClientRect(),
        childrenRefs.current[childrenCount - 1].getBoundingClientRect(),
        position,
      );
      sliderRef.current.scrollBy({
        left: shiftPx,
        behavior: infiniteScroll ? 'auto' : 'smooth',
      });
    }
  };

  const calculateNextIndex = (nextItem, totalCount) => {
    return (nextItem + totalCount) % totalCount;
  };

  const onClickLeftButton = () => {
    const nextItem = calculateNextIndex(activeItem - shiftBy, childrenCount);
    if (!isBtnDisabled.left) {
      setActiveItem(nextItem);
      onLeftClick(nextItem);
    }
  };

  const onClickRightButton = () => {
    const nextItem = calculateNextIndex(activeItem + shiftBy, childrenCount);
    if (!isBtnDisabled.right) {
      setActiveItem(nextItem);
      onRightClick(nextItem);
    }
  };

  useEffect(() => {
    if (scrollToActiveItem && initialItem !== activeItem) {
      setActiveItem(initialItem);
    }
  }, [initialItem]);

  useEffect(() => {
    if (childrenCount > 0) {
      scrollToSlide(activeItem);
    }
  }, [activeItem, childrenCount, width]);

  return (
    <div
      className={classNames(styles.container, customClasses.container)}
      {...rest}
    >
      {showLeftButton && (
        <div
          onClick={onClickLeftButton}
          className={classNames(
            styles.leftButton,
            isBtnDisabled.left
              ? 'cursor-not-allowed [&>svg>path]:fill-text-disable'
              : 'cursor-pointer [&>path]:fill-text-link',
            typeof customClasses.leftButton === 'function'
              ? customClasses.leftButton(isBtnDisabled.left)
              : customClasses.leftButton,
          )}
        >
          {customButtons.left ? (
            customButtons.left(isBtnDisabled.left)
          ) : (
            <CommonIcons.ChevronLeft />
          )}
        </div>
      )}
      <div className={classNames(styles.slider, customClasses.slider ?? '')}>
        <div className={classNames(styles.slides)} ref={sliderRef}>
          {React.Children.map(children, (child, idx) => (
            <div
              className={classNames(
                styles.slide,
                customClasses.slide ?? '',
                idx === activeItem ? customClasses.activeSlide ?? '' : '',
              )}
              ref={ref => ref && (childrenRefs.current[idx] = ref)}
            >
              {child}
            </div>
          ))}
        </div>
        {!hideIndicator && childrenCount > 1 && (
          <div
            className={classNames(
              styles.indicatorContainer,
              customClasses.indicatorContainer ?? '',
            )}
          >
            {customIndicator
              ? customIndicator
              : React.Children.map(children, (_, id) => (
                  <span
                    className={classNames(
                      styles.indicator,
                      activeItem === id ? styles.activeIndicator : '',
                      (isBtnDisabled.left && id < activeItem) ||
                        (isBtnDisabled.right && id > activeItem)
                        ? styles.disabledIndicator
                        : '',
                    )}
                    onClick={() => setActiveItem(id)}
                  ></span>
              ))}
          </div>
        )}
      </div>
      {showRightButton && (
        <div
          onClick={onClickRightButton}
          className={classNames(
            styles.rightButton,
            isBtnDisabled.right
              ? 'cursor-not-allowed [&>svg>path]:fill-text-disable'
              : 'cursor-pointer [&>path]:fill-text-link',
            typeof customClasses.rightButton === 'function'
              ? customClasses.rightButton(isBtnDisabled.right)
              : customClasses.rightButton,
          )}
        >
          {customButtons.right ? (
            customButtons.right(isBtnDisabled.right)
          ) : (
            <CommonIcons.ChevronRight />
          )}
        </div>
      )}
    </div>
  );
};
