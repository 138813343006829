import { forwardRef } from 'react';
import { ModalVariant } from './Modal.constant';
import type { ModalPropTypes } from './Modal.types';
import { ModalBase, ModalConfirm, ModalInfo } from './variants';

/**
 * Modal size type:
 * size === 'custom' -> custom width and height can be passed
 * --------------------------------
 * Modal variant type:
 * variant === 'info' ->
 * 1. footer has only 1 button by default, default button text is 'Close' and variant is primary,
 * 2. default onClick is onClose,
 * 3. hideFooterDivider is true
 * 4. hideCross is true
 * 4. footer can be overridden
 * 5. default size is small
 * --------------------------------
 * variant === 'confirm' ->
 * 1. footer has 2 buttons by default, default button text is 'Cancel' and 'Confirm' and variant is secondary and primary respectively,
 * 2. hideFooterDivider is true
 * 3. hideCross is true
 * 4. default size is medium
 * --------------------------------
 * variant === 'default' ->
 * 1. footer can be overridden
 * 2. header can be overridden
 * 3. size can be overridden
 * 4. default size is medium
 * --------------------------------
 **/

function Modal(props: ModalPropTypes, ref?: React.Ref<HTMLDivElement>) {
  const { variant = ModalVariant.DEFAULT } = props;
  switch (variant) {
    case ModalVariant.CONFIRM:
      return <ModalConfirm ref={ref} {...props} />;
    case ModalVariant.INFO:
      return <ModalInfo ref={ref} {...props} />;
    default:
      return <ModalBase ref={ref} variant={variant} {...props} />;
  }
}

export default forwardRef(Modal);
