import { useCallback } from 'react';
import { Paper } from '@mui/material';

type ChildrenProps = {
  children?: React.ReactNode;
};

const usePaperComponent = (
  className: string,
): ((props: ChildrenProps) => JSX.Element) => {
  return useCallback(
    ({ children }: ChildrenProps) => (
      <Paper className={className}>{children}</Paper>
    ),
    [className],
  );
};

export default usePaperComponent;
