import React from 'react';
import { autobind } from '../../../utilities/Autobind';
import {
  CustomComposedChart,
  DashboardLoader as Loader,
  DefaultMessageSection,
} from '../components';
import { Line, Legend } from 'recharts';
import * as AC from '../constants/Dashboard';
import { substractFromToday } from '../utils/utils';
import { wrappedStrings } from '../../../utilities/CustomHooks';
import { Dashboard } from '../../../utilities/TestIds';
import { getRoomNightsSection } from 'modules/analytics/utils/AnalyticsGraphClient';

const DataDisplaySection = ({ context }) => {
  const { width, height } = context;
  const { data } = context.state;

  const legend = (
    <Legend
      width={width}
      height={10}
      align="left"
      verticalAlign="bottom"
      iconType="rect"
      wrapperStyle={{ left: 50 }}
      className="custom-chart-legend"
    />
  );

  const rnLineChart = (
    <Line
      yAxisId="left"
      key="hotelValue"
      name="Go-MMT"
      type="linear"
      dataKey="hotelValue"
      stroke="#0099ff"
      strokeWidth={2}
      width={350}
      height={200}
    />
  );

  const charts = [rnLineChart];
  return (
    <div
      style={{
        position: 'relative',
        left: '40px',
        marginLeft: '-75px',
        marginTop: '-35px',
      }}
    >
      <CustomComposedChart
        charts={charts}
        data={data}
        height={height}
        width={width}
        xAxis="interval"
        legend={legend}
      />
    </div>
  );
};

const SectionBody = ({ context }) => {
  let filler = (
    <DefaultMessageSection message={context.defaultSectionMessage} />
  );
  if (context.state.isLoading) {
    filler = <Loader />;
  } else if (context.state.data.length > 0) {
    filler = <DataDisplaySection context={context} />;
  }
  return filler;
};

class PerformanceSection extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
    const { STRINGS, COMMON_STRINGS } = this.props;
    this.title = this.title = STRINGS.PERFORMANCE_SECTION.HEADING;

    this.width = this.props.width ? this.props.width : 380;
    this.height = this.props.height ? this.props.height : 250;
    this.hotel = this.props.hotel;
    this.property = ['room_nights'];
    this.brand = ['all'];
    this.compType = 'hotel';
    this.interval = 'days';
    this.period = 7;
    this.intervalField = 'booking';
    this.heading = COMMON_STRINGS.LABELS.ROOM_NIGHTS;
    this.defaultSectionMessage = COMMON_STRINGS.MESSAGES.NO_DATA_FOUND;
    this.info = COMMON_STRINGS.LABELS.INFO;
    this.state = {
      data: [],
      hoveredBar: {},
      isLoading: false,
      showSection: true,
    };
  }

  componentWillReceiveProps(props) {
    if (this.props.hotel.hotelId !== props.hotel.hotelId) {
      this.hotel = props.hotel;
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const request = this.buildRequest();
    this.setState({ isLoading: true });
    getRoomNightsSection(request, this.hotel.hotelId, { useLoader: false })
      .then(this.onSuccess)
      .catch(this.onFailure);
  }

  onSuccess(response) {
    if (response.data.status === 'failure') {
      this.defaultSectionMessage = response.data.message;
      this.props.onSectionLoadUpdate(false, this.props.gtmLabel);
      this.setState({ isLoading: false, data: [], showSection: false });
      return;
    }
    this.props.onSectionLoadUpdate(true, this.props.gtmLabel);
    const data = response.data.data;
    const hotelBrandData = data.hotel[this.hotel.hotelId][this.brand[0]];

    const hotelHistogramData = hotelBrandData
      ? hotelBrandData[this.property[0]]
      : [];

    const histogram = Object.keys(hotelHistogramData).map(interval => {
      const trend = {};
      const hotelIntervalData = hotelHistogramData[interval];

      const intervalDate = new Date(interval);
      const day = intervalDate.getDate();
      const month = intervalDate.getMonth();
      const year = intervalDate.getFullYear();
      trend.year = year;
      if (this.interval === 'months') {
        trend.interval = AC.months[month];
      } else if (this.interval === 'days') {
        trend.interval = `${day} ${AC.months[month]}`;
      }
      trend.hotelValue = hotelIntervalData.value;
      return trend;
    });
    this.setState({ data: histogram, isLoading: false, showSection: true });
  }

  onFailure() {
    const { COMMON_STRINGS } = this.props;
    this.props.onSectionLoadUpdate(false, this.props.gtmLabel);
    this.defaultSectionMessage = COMMON_STRINGS.MESSAGES.CONNECTIVITY_ERROR_MSG;
    this.setState({ isLoading: false, data: [], showSection: false });
  }

  buildRequest() {
    const request = {};
    request.property = this.property;
    request.period = this.period;
    request.interval = this.interval;
    request.start_date = substractFromToday(this.period, this.interval);
    request.comp_data = true;
    request.comp_type = this.compType;
    request.brand = this.brand;
    request.interval_field = this.intervalField;
    request.pipeline = AC.TRANSACTION;
    return request;
  }

  render() {
    const { showSection } = this.state;
    return (
      showSection && (
        <div className="sr-panel" data-test-id={Dashboard.roomNight}>
          <div className="sr-panel-heading ">
            <h3 className="font16 margin0">{this.title}</h3>
          </div>
          <div className="sr-panel-body flex items-center justify-center">
            <div className="sr-flex-row">
              <SectionBody context={this} />
            </div>
          </div>
        </div>
      )
    );
  }
}
export default wrappedStrings(PerformanceSection, 'Dashboard');
