import { createTheme } from '@mui/material/styles';
import { colorPalette, getColor } from './colors';
import { getTypographyVariant } from './fonts';
import type { PaletteColorsType } from './theme.types';

const theme = createTheme({
  typography: palette => getTypographyVariant(palette),
  palette: colorPalette,
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1280,
      xl: 1440,
    },
  },
});

export { theme, type PaletteColorsType, getColor };
