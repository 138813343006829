import { useFlagState } from 'utilities/CustomHooks';
import { useSingleCalendar } from './hooks/SingleCalendar.hooks';
import { useMultiSelectCalendar } from './hooks/MultiSelectCalendar.hooks';
import { useRangeCalendar } from './hooks/RangeCalendar.hooks';
import {
  UseCalendarProp,
  UseCalendarReturnProp,
  Variants,
} from './Calendar.types';

export const useCalendar = <T extends keyof typeof Variants>(
  props: UseCalendarProp<T>,
): UseCalendarReturnProp<T> => {
  const [isCalendarOpen, showCalendar, hideCalendar] = useFlagState(false);
  const calendarProps =
    props.variant === 'single'
      ? useSingleCalendar(props)
      : props.variant === 'multiple'
        ? useMultiSelectCalendar(props)
        : props.variant === 'range'
          ? useRangeCalendar(props)
          : null;
  return {
    // @ts-ignore
    calendarProps,
    popoverState: {
      isCalendarOpen,
      showCalendar,
      hideCalendar,
    },
  };
};
