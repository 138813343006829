import React, { forwardRef } from 'react';
import { Box as MuiBox, BoxProps } from '@mui/material';

import { DataAttribute } from '../../../interfaces/types';

type NewProps = {
  gap?: BoxProps['gap'];
  columnGap?: BoxProps['columnGap'];
  rowGap?: BoxProps['rowGap'];
};
type Props = DataAttribute &
NewProps &
Omit<BoxProps, 'gap' | 'columnGap' | 'rowGap'> & {
  children: React.ReactNode[];
};

function Box(props: Partial<Props>, ref) {
  const {
    children,
    // v5 Changes props
    borderRadius = 0,
    // Renamed props changes
    gap = 0,
    columnGap = 0,
    rowGap = 0,
    ...rest
  } = props;

  return (
    <MuiBox
      {...{ ref: ref }}
      // Breaking changes in v5 - https://mui.com/material-ui/migration/v5-component-changes/#box
      borderRadius={borderRadius}
      // Signature changes
      gap={`${gap}px`}
      columnGap={`${columnGap}px`}
      rowGap={`${rowGap}px`}
      {...rest}
    >
      {children}
    </MuiBox>
  );
}

export default forwardRef(Box);
