import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { theme } from './mui-theme';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function ThemeProvider(props: Props) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </StyledEngineProvider>
  );
}
