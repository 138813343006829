import { AttributeFieldProps } from './AttributeFieldProps';
import { useFormContext, ValidateResult } from 'react-hook-form';
import { FieldContainer } from './FieldContainer';
import React, { useCallback } from 'react';
import { ControlledTimePicker } from 'components/latest-core/TimePicker/TimePicker';

function validateStartAndEndTime({
  startTimeValue,
  endTimeValue,
  startTimeId,
  endTimeId,
}: {
  startTimeValue: string | undefined;
  endTimeValue: string | undefined;
  startTimeId: number | undefined;
  endTimeId: number | undefined;
}): { errors: Record<string, ValidateResult> } {
  const bothFieldsAreUnSelected = !startTimeValue && !endTimeValue;
  if (bothFieldsAreUnSelected) {
    return { errors: {} };
  }
  if (startTimeValue && !endTimeValue) {
    return {
      errors: {
        [endTimeId]: 'End Time is empty',
      },
    };
  }
  if (endTimeValue && !startTimeValue) {
    return {
      errors: {
        [startTimeId]: 'Start Time is empty',
      },
    };
  }
  if (endTimeValue < startTimeValue) {
    return {
      errors: {
        [endTimeId]: 'End Time is less than Start Time',
      },
    };
  }
  if (endTimeValue === startTimeValue) {
    return {
      errors: {
        [endTimeId]: 'End Time is same as Start Time',
      },
    };
  }
  return { errors: {} };
}

export function TimeSelectComponent(props: AttributeFieldProps) {
  const { control, getValues } = useFormContext();
  const { attr, defaultValue, attributesKeyIdMap } = props;
  const timeValidator = useCallback((): ValidateResult => {
    const isStartTime = attr.settingKey.indexOf('start_time') > -1;
    let startTimeValue: string | undefined;
    let endTimeValue: string | undefined;
    let startTimeId: number;
    let endTimeId: number;
    if (isStartTime) {
      startTimeId = attr.attributeId;
      endTimeId =
        attributesKeyIdMap[attr.settingKey.replace('start_time', 'end_time')];
      startTimeValue = getValues(`${attr.attributeId}`) as string | undefined;
      endTimeValue = getValues(`${endTimeId}`) as string | undefined;
    }
    const isEndTime = attr.settingKey.indexOf('end_time') > -1;
    if (isEndTime) {
      endTimeId = attr.attributeId;
      startTimeId =
        attributesKeyIdMap[attr.settingKey.replace('end_time', 'start_time')];
      endTimeValue = getValues(`${attr.attributeId}`) as string | undefined;
      startTimeValue = getValues(`${startTimeId}`) as string | undefined;
    }
    const { errors } = validateStartAndEndTime({
      startTimeValue,
      endTimeValue,
      startTimeId,
      endTimeId,
    });
    return errors[attr.attributeId] || null;
  }, [getValues, attr]);
  return (
    <FieldContainer attr={attr}>
      <ControlledTimePicker
        name={`${attr.attributeId}`}
        customValidator={timeValidator}
        control={control}
        minuteInterval={15}
        placeholder="Select Time"
        isRequired={attr.required}
        defaultValue={defaultValue as string}
        removeSeconds
      />
    </FieldContainer>
  );
}
