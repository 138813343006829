import { Box } from '@mui/material';
import * as Dashboard from 'assets/modules/dashboard';

export type QualityScoreDisplayProps = {
  score: number;
  tier: 'silver' | 'gold' | 'platinum';
};

// const collapsedSilver = (
//   <path
//     xmlns="http://www.w3.org/2000/svg"
//     d="M4.38977 46.645C4.39237 40.7075 5.86349 34.8629 8.67208 29.6317C11.4807 24.4005 15.5395 19.9452 20.4871 16.6627L22.4774 19.6625C18.0246 22.6168 14.3716 26.6265 11.8439 31.3346C9.31612 36.0427 7.99211 41.3028 7.98977 46.6466L4.38977 46.645Z"
//     fill="#b8b8b8"
//   />
// );
// const collapsedGold = (
//   <path
//     xmlns="http://www.w3.org/2000/svg"
//     d="M18.0137 4.8152C23.1569 1.84872 28.9541 0.200437 34.8887 0.0171578C40.8234 -0.166122 46.7112 1.1213 52.0277 3.76475L50.425 6.98827C45.6401 4.60917 40.341 3.45049 34.9999 3.61544C29.6587 3.78039 24.4413 5.26386 19.8123 7.93369L18.0137 4.8152Z"
//     fill="#f3ba78"
//   />
// );
// const collapsedPlatinum = (
//   <path
//     xmlns="http://www.w3.org/2000/svg"
//     d="M57.9735 14.8078C63.45 17.9634 67.9992 22.5053 71.1635 27.9767C74.3279 33.4481 75.996 39.6563 76.0001 45.9769L72.4001 45.9792C72.3964 40.2906 70.8951 34.7033 68.0472 29.779C65.1992 24.8548 61.105 20.7671 56.1762 17.927L57.9735 14.8078Z"
//     fill="#7a7ba2"
//   />
// );

// //All 5 expanded (or selected) segments
// const expandedSilver = (
//   <path
//     xmlns="http://www.w3.org/2000/svg"
//     d="M0 36C-1.88731e-07 30.0625 1.46856 24.2173 4.27486 18.9848C7.08116 13.7524 11.1381 9.29536 16.0842 6.01066L21.0631 13.508C17.3535 15.9715 14.3109 19.3143 12.2061 23.2386C10.1014 27.1629 9 31.5469 9 36L0 36Z"
//     fill="#b8b8b8"
//   />
// );
// const expandedGold = (
//   <path
//     xmlns="http://www.w3.org/2000/svg"
//     d="M22.0001 14.9947C27.1421 12.0259 32.9386 10.3751 38.8731 10.1892C44.8077 10.0033 50.696 11.2882 56.0137 13.9293L52.0103 21.9899C48.0221 20.009 43.6058 19.0454 39.1549 19.1848C34.7039 19.3242 30.3566 20.5623 26.5001 22.7889L22.0001 14.9947Z"
//     fill="#f3ba78"
//   />
// );
// const expandedPlatinum = (
//   <path
//     xmlns="http://www.w3.org/2000/svg"
//     d="M59.5126 16.4462C64.4659 19.72 68.5326 24.1681 71.3505 29.3943C74.1683 34.6206 75.6497 40.4626 75.6628 46.4L66.6628 46.4199C66.653 41.9668 65.5419 37.5853 63.4286 33.6656C61.3152 29.7459 58.2652 26.4098 54.5501 23.9545L59.5126 16.4462Z"
//     fill="#7a7ba2"
//   />
// );

const tierImgMap = {
  silver: Dashboard.QSSilverIcon,
  gold: Dashboard.QSGoldIcon,
  platinum: Dashboard.QSPlatinumIcon,
};

export default function QualityScoreDisplay(props: QualityScoreDisplayProps) {
  const { score, tier } = props;

  return (
    <Box width="80px" height="50px" position="relative">
      {/* <svg
            width="80"
            height="50"
            viewBox="0 0 80 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {tier === 'silver' ? expandedSilver : collapsedSilver}
            {tier === 'gold' ? expandedGold : collapsedGold}
            {tier === 'platinum' ? expandedPlatinum : collapsedPlatinum}
          </svg> */}
      <img
        width={80}
        height={50}
        src={tierImgMap[tier]}
        style={{ objectFit: 'cover' }}
        alt=""
      />
      <Box
        component="span"
        position="absolute"
        left="50%"
        bottom={0}
        style={{
          transform: 'translateX(-50%)',
          fontSize: '1.75rem',
          fontWeight: 'bold',
          lineHeight: 1,
        }}
      >
        {score}
      </Box>
    </Box>
  );
}
