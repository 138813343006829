import React, { useLayoutEffect, useRef, useState } from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Legend,
} from 'recharts';
import { legendTypes, toolTipTypes } from '../SponsoredListingConstants';
import { isNullOrUndefined } from 'utilities/Utils';

const CustomLabel = props => {
  const { textAnchor } = props;
  return (
    <g radius={[25, 25, 10, 20]}>
      <rect
        x={props.viewBox.x}
        y={props.viewBox.y}
        fill="#F6C851"
        width={34}
        height={20}
        textAnchor={textAnchor}
      />
      <text
        x={props.viewBox.x}
        y={props.viewBox.y}
        fill="#111"
        dy={13}
        dx={5}
        style={{ fontSize: '11px', color: '#4A4A4A' }}
      >
        Start
      </text>
    </g>
  );
};

const formatPayload = payload =>
  Object.keys(payload).reduce((acc, key) => {
    const value = payload[key];

    if (isNullOrUndefined(value)) {
      return acc;
    }
    if (value !== payload[key]) {
      return acc;
    }

    const camelCaseKey = key.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
    acc[camelCaseKey] = value;
    return acc;
  }, {});

export default function LineGraph(props) {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { graphData, fullWidth, dataTourId, isPageType } = props;

  const data = graphData?.revenue?.revenueList || graphData?.revenueList;
  const lastItem = data[data?.length - 1];
  const newLastVal = lastItem.duringCampaign;
  if (data?.length === 3) {
    data.push({
      duringCampaign: newLastVal,
      estimatedWithoutCampaign: null,
      label: null,
      priorCampaignStart: null,
    });
  }

  const startIndicator =
    graphData?.revenue?.startIndicator || graphData?.startIndicator;
  useLayoutEffect(() => {
    const contentWidth = containerRef.current?.clientWidth;
    setContainerWidth(contentWidth);
  }, []);

  const legend = graphData?.revenue?.legend || graphData?.legend;

  function RenderLegend({ payload }) {
    return Object.entries(payload).map(([key, val]) => {
      return (
        <div
          className={`d-flex marginR10 sr-align-center ${
            val?.trim() === legendTypes.BEFORE
              ? 'order1'
              : val?.trim() === legendTypes.WITHSL
                ? 'order2'
                : val?.trim() === legendTypes.WITHOUTSL
                  ? 'order3'
                  : 'dn'
          }`}
        >
          {val?.trim() === legendTypes.BEFORE && (
            <span
              style={{
                width: '21px',
                display: 'inline-block',
                margin: '0 4px',
                height: '2px',
                borderTop: '1px solid #9B9B9B',
              }}
            ></span>
          )}
          {val?.trim() === legendTypes.WITHSL && (
            <span
              style={{
                width: '21px',
                background: '#F6C851',
                display: 'inline-block',
                margin: '0 4px',
                height: '2px',
              }}
            ></span>
          )}

          {val?.trim() === legendTypes.WITHOUTSL && (
            <span
              style={{
                width: '21px',
                display: 'inline-block',
                margin: '0 4px',
                height: '2px',
                borderTop: '1px dashed #9B9B9B',
              }}
            ></span>
          )}

          {!isNullOrUndefined(key) && key !== '__typename' && (
            <span>{val}</span>
          )}
        </div>
      );
    });
  }

  function findDuplicateValues(input) {
    let duplicateValue = false;
    const values = {};
    let key;

    for (key in input) {
      if (input.hasOwnProperty(key) && input[key] !== '') {
        values[input[key]] = values.hasOwnProperty(input[key])
          ? values[input[key]] + 1
          : 1;
      }
    }

    for (key in values) {
      if (values.hasOwnProperty(key) && values[key] > 1) {
        duplicateValue = true;
        break;
      }
    }
    return duplicateValue;
  }

  function MyCustomTooltip({ payload }) {
    if (!payload) {
      return null;
    }
    return payload?.slice(0, 1).map(data => {
      const newPayload = formatPayload(data?.payload);
      const isDuplicate = findDuplicateValues(newPayload);

      if (isDuplicate) {
        const isDurPriorSame =
          newPayload[toolTipTypes.DURING] === newPayload[toolTipTypes.BEFORE];
        const isDurAfterSame =
          newPayload[toolTipTypes.DURING] === newPayload[toolTipTypes.AFTER];
        const isALLDataSame =
          (newPayload[toolTipTypes.DURING] ===
            newPayload[toolTipTypes.BEFORE]) ===
          newPayload[toolTipTypes.AFTER];
        if (isDurPriorSame || isALLDataSame) {
          return (
            <div>
              <div>During campaign: {newPayload[toolTipTypes.DURING]}</div>
              {!isNullOrUndefined(newPayload[toolTipTypes.AFTER]) && (
                <div>
                  Estimated Without Campaign: {newPayload[toolTipTypes.AFTER]}
                </div>
              )}
            </div>
          );
        } else if (isDurAfterSame || isALLDataSame) {
          return (
            <div>
              {newPayload[toolTipTypes.DURING] !== '' && (
                <div>During campaign: {newPayload[toolTipTypes.DURING]}</div>
              )}
            </div>
          );
        }
      } else {
        return Object.keys(newPayload)?.map(key => {
          return (
            <div style={{ textTransform: 'capitalize' }}>
              {key !== '__typename' && (
                <>
                  {`${
                    key === 'label'
                      ? 'Date'
                      : key === toolTipTypes.BEFORE
                        ? 'Before campaign start'
                        : key
                  }:${newPayload[key]}`}
                </>
              )}
            </div>
          );
        });
      }
      return null;
    });
  }
  return (
    <div ref={containerRef} data-tour-id={dataTourId} className="line-graph">
      <LineChart
        width={fullWidth ? containerWidth : 500}
        height={200}
        data={data}
        data-tour-id={dataTourId}
      >
        <CartesianGrid vertical={false} stroke="#DEDEDE" />
        <XAxis
          dataKey="label"
          axisLine={false}
          tickLine={true}
          dy={5}
          interval={fullWidth && !isPageType ? 0 : null}
          padding={
            isPageType ? { left: 40, right: 20 } : { left: 5, right: 20 }
          }
          style={{
            fontSize: isPageType ? '8.5px' : '9px',
            color: 'rgba(74,74,74,0.6)',
            fontWeight: '500',
          }}
        />
        <YAxis
          width={40}
          axisLine={false}
          dx={-5}
          style={{
            fontSize: isPageType ? '8.5px' : '9px',
            color: 'rgba(74,74,74,0.6)',
            fontWeight: '500',
          }}
        />
        <Tooltip cursor={false} content={<MyCustomTooltip />} />

        <Line
          data-tour-id={dataTourId}
          type="linear"
          dataKey="priorCampaignStart"
          stroke="#9B9B9B"
          activeDot={{ r: 8 }}
        />
        <Legend
          verticalAlign="bottom"
          align="left"
          payload={legend}
          content={<RenderLegend />}
          wrapperStyle={{
            fontSize: '10px',
            left: '0px',
          }}
        />

        <ReferenceLine
          x={startIndicator}
          stroke="#F6C851"
          label={CustomLabel}
        />
        <Line
          type="linear"
          dataKey="duringCampaign"
          stroke="#F6C851"
          activeDot={{ r: 8 }}
        />
        <Line
          type="linear"
          dataKey="estimatedWithoutCampaign"
          stroke="#9B9B9B"
          activeDot={{ r: 8 }}
          strokeDasharray="3 3"
        />
      </LineChart>
    </div>
  );
}
