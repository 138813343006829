import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useSuspenseQueries } from '@tanstack/react-query';
import { Divider } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { routes } from 'app/routes';
import { getValue, navigateTo, showMessage } from 'utilities/Utils';
import { useCurrentHotel, useLangStrings } from 'utilities/CustomHooks';
import { suspenseWrapper } from 'base/reactQueryWrapper';

import {
  Container,
  Typography,
  FooterLayout,
  Modal,
} from 'components/latest-core';
import { FaqCardsLatest } from 'components/common';
import { AdvanceSettings } from '../components/AdvanceSettings';
import PackageName from '../components/PackageName';
import { LOSPersuasion } from '../components/LOSPersuasion';
import { PackagesCart } from '../components/SidePanel';
import PackageLosInclusion from './PackageLosInclusions';

import {
  FetchLosServiceResponse,
  LeafCategory,
  ServiceValidationInput,
} from '../data/types/Schema';
import { PackagesLosContext } from '../PackagesLosContext';
import {
  fetchLosPackageDetailsQueryOptions,
  fetchLosPkgServiceTemplateQueryOptions,
} from '../data/queries/packagesQueries';
import { PACKAGE_TYPE } from '../packageConstants';
import {
  deactivateLosPackage,
  saveLosPackage,
} from '../data/api/packagesApiClient';
import { createFAQ, generateDefaultFormValues, getStayDays } from '../utils';
import { showError } from 'base/APIClient';
import { PerformanceIcon, BadgeIcon, FunnelImg } from 'assets/modules/packages';
import MinStayDuration from '../components/MinStayDuration';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';

const FooterErrors = ({ errors }: { errors: Record<string, string> }) => {
  if (Object.keys(errors).length === 0) return null;
  return (
    <Typography variant="body1" className="!text-[#eb2026] px-4">
      {Object.values(errors).map(error => {
        return <div>{error}</div>;
      })}
    </Typography>
  );
};

const PackageLongStay = suspenseWrapper(() => {
  const [hotelCode, { mmt_id: mmtId }] = useCurrentHotel();
  const [pkgStr, commonStrings] = useLangStrings<'Packages'>('Packages');
  const { search } = useLocation();
  const { packageId } = queryString.parse(search) as { packageId?: string };
  const history = useHistory();
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const clearPackageId = () => {
    history.replace({
      search: '',
    });
  };

  const results = useSuspenseQueries({
    queries: [
      fetchLosPkgServiceTemplateQueryOptions(hotelCode),
      fetchLosPackageDetailsQueryOptions({
        hotelCode,
        mmtId,
        packageId,
        clearPackageId,
      }),
    ],
  });

  useEffect(() => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.PAGE_VIEW,
      pageType: 'los-package',
    });
  }, []);

  const [{ data: inclState }, { data: packageDetails }] = results;
  const { roomRateplanData: roomAndRatePlan, servicesResponse } =
    packageDetails;
  const minLosNode = servicesResponse?.minLosNode;

  const [serviceResponse, updateServiceResponse] =
    useState<FetchLosServiceResponse | null>(servicesResponse);
  const [inclusionForEdit, setInclusionForEdit] =
    useState<ServiceValidationInput | null>(null);
  const [inclusionForDelete, setInclusionForDelete] = useState(null);
  const [inclusionLookupByIdMap, setInclusionLookupByIdMap] = useState<Record<
  number,
  LeafCategory
  > | null>(null);
  const [validationErrors, setValidationErrors] = useState<
  Record<string, string>
  >({});
  const minStayDurationRef = useRef<HTMLElement>();

  const customValueOptions = useMemo(() => {
    const map = new Map();
    minLosNode.customValues.forEach(item => {
      map.set(getStayDays(item), item);
    });
    return map;
  }, [packageDetails]);

  const formMethods = useForm({
    defaultValues: generateDefaultFormValues(
      servicesResponse,
      customValueOptions,
    ),
    shouldUnregister: false,
  });
  const formState = formMethods.formState;

  const minStay = formMethods.watch('minStayDuration');
  const customStayDuration = formMethods.watch('customStayDuration');
  const saveText = servicesResponse?.packageId
    ? commonStrings.BUTTONS.EDIT
    : commonStrings.BUTTONS.CREATE;

  const deactivatePackage = async () => {
    await deactivateLosPackage({
      id: packageId,
      hotelId: mmtId,
    })
      .then(res => {
        const response = getValue(res, 'data.rawEndpoint.savePackageInclusion');
        const { message, error } = response;
        if (message) {
          showMessage({ message });
          navigateTo(routes.property.allActiveInclusions);
        } else if (error) {
          throw error;
        }
      })
      .catch(err => {
        const { message } = err;
        setShowDeactivateModal(false);
        showMessage({ message, type: 'error' });
      });
  };

  const handleSave = async data => {
    saveLosPackage({
      ...data,
      hotelId: mmtId,
      serviceResponse: serviceResponse,
    })
      .then(res => {
        const response = getValue(res, 'data.rawEndpoint.savePackageInclusion');
        const { message, validationError } = response;
        if (message) {
          showMessage({ message });
          navigateTo(routes.property.allActiveInclusions);
        } else if (validationError) {
          throw validationError;
        }
      })
      .catch(err => {
        const { fieldError, message } = err;
        if (fieldError) {
          setValidationErrors(
            fieldError.reduce(
              (acc, error) => ({ ...acc, [error.fieldName]: error.message }),
              {},
            ),
          );
        } else if (message) showError(message);
      });
  };

  useEffect(() => {
    if ('minLengthOfStay' in validationErrors) {
      formMethods.setError('customStayDuration', {
        type: 'custom',
        message: validationErrors.minLengthOfStay,
      });
      formMethods.setError('minStayDuration', {
        type: 'custom',
        message: validationErrors.minLengthOfStay,
      });
      minStayDurationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else formMethods.clearErrors(['customStayDuration', 'minStayDuration']);
  }, [validationErrors]);

  useEffect(() => {
    if (
      validationErrors.minLengthOfStay &&
      (!formState.errors.customStayDuration ||
        !formState.errors.minStayDuration)
    ) {
      setValidationErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors.minLengthOfStay;
        return newErrors;
      });
    }
  }, [formState]);

  return (
    <PackagesLosContext.Provider
      value={{
        minStay: +minStay || customStayDuration.value,
        inclusionForDelete,
        setInclusionForDelete,
        inclusionForEdit,
        setInclusionForEdit,
        serviceResponse,
        updateServiceResponse,
        inclusionLookupByIdMap,
        setInclusionLookupByIdMap,
      }}
    >
      <FormProvider {...formMethods}>
        <Container className="flex justify-center mt-6" isNewContent>
          <div>
            <Typography variant="h1">
              {pkgStr.LOS.CREATE_LOS_PACKAGE.replace(
                '__placeholder__',
                packageId ? pkgStr.LABELS.EDIT : commonStrings.BUTTONS.CREATE,
              )}
            </Typography>
            <LOSPersuasion strings={pkgStr} />
            <div className="shadow-[0_0_10px_5px_rgba(0,0,0,0.1)] bg-color-white rounded-lg p-4 ">
              <MinStayDuration
                ref={minStayDurationRef}
                minLosNode={minLosNode}
                customValueOptions={customValueOptions}
              />
              <div className="border-[1px] border-solid  border-divider rounded-lg p-4 mt-4">
                <PackageLosInclusion inclState={inclState} />
              </div>
              <div className="border-[1px] border-solid  border-divider rounded-lg px-4 pb-5 pt-3 mt-4">
                <PackageName strings={pkgStr} />
              </div>
              <div className="border-[1px] border-solid  border-divider rounded-lg p-4 mt-4">
                <AdvanceSettings
                  roomAndRatePlan={roomAndRatePlan}
                  selectedRatePlan={servicesResponse?.rateplanIds ?? []}
                  isEdit={!!serviceResponse.packageId}
                  strings={pkgStr}
                />
              </div>
            </div>
            {packageId && (
              <>
                <Divider className="mt-4" />
                <Typography
                  variant="h3"
                  isLink
                  onClick={() => setShowDeactivateModal(true)}
                  className="mt-3"
                  themeColor="primary.main"
                >
                  {pkgStr.LOS.DEACTIVATE_PACKAGE}
                </Typography>
              </>
            )}
          </div>
          <div>
            <PackagesCart pkgStr={pkgStr} contextName={PACKAGE_TYPE.LOS} />
            <div className="flex flex-col border-[1px] border-solid border-divider bg-color-white mb-4">
              <Typography variant="body2" className="mx-3 my-2">
                {pkgStr.LOS.HOW_CUSTOMERS_SEE_DEAL}
              </Typography>
              <Divider />
              <img
                src={FunnelImg}
                alt="funnel-package-img"
                className="p-2 w-full"
              />
            </div>
            <FaqCardsLatest
              title={commonStrings.LABELS.FREQ_ASKED_QUESTIONS}
              faqs={createFAQ({
                faqData: pkgStr.LOS.FAQ,
                PerformanceIcon,
                BadgeIcon,
              })}
              itemClassName="!items-start"
              showHide={true}
              openFirst
            />
          </div>
        </Container>
        <div className="mt-[200px]" />
        <FooterLayout
          positiveText={pkgStr.LOS.CREATE_LONG_STAY_PACKAGE.replace(
            '__placeholder__',
            saveText,
          )}
          negativeText={commonStrings.BUTTONS.CANCEL}
          twoColLayout
          disabledFlag={
            serviceResponse
              ? serviceResponse.validatePackageResponse.matchingInclusions <
                serviceResponse.validatePackageResponse.minimumInclusions
              : true
          }
          negativeButtonClick={() => navigateTo(routes.property.inclusionsBase)}
          positiveButtonClick={formMethods.handleSubmit(handleSave)}
        >
          {<FooterErrors errors={validationErrors} />}
        </FooterLayout>
        <Modal
          isOpen={showDeactivateModal}
          header={{
            title: pkgStr.LOS.DEACTIVATE_MODAL_TITLE,
            hideCross: true,
          }}
          footer={{
            primaryBtn: {
              text: commonStrings.BUTTONS.DEACTIVATE,
              onClick: deactivatePackage,
            },
            secondaryBtn: {
              text: commonStrings.BUTTONS.CANCEL,
              onClick: () => setShowDeactivateModal(false),
            },
          }}
          size="small"
        >
          <Typography variant="subHeadingRegular">
            {pkgStr.LOS.DEACTIVATE_MODAL_BODY}
          </Typography>
        </Modal>
      </FormProvider>
    </PackagesLosContext.Provider>
  );
});
export default PackageLongStay;
