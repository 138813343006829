import React, { useContext, useEffect } from 'react';
import { AppContext, AuthContext } from '../../../utilities/Context';
import { routes } from '../../routes';
import { navigateTo } from 'utilities/Utils';
import { useFlagState, useLangStrings } from '../../../utilities/CustomHooks';
import { Tooltip } from '../../../components/new-core';

import { PlusIcon, UserImg } from '../../../assets/old_shared';
import { ChevronDown, ChevronRight } from '../../../assets/common';
import { Dashboard } from '../../../utilities/TestIds';
import { Avatar } from '@mui/material';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';
import { Typography } from 'components/latest-core';
import { pushToCtaOmnitureCommon } from 'app/login/utils';

import './Users.scss';
interface UserProps {
  style?: Object;
  inOnboarding?: boolean;
}

export default function User({ style = {}, inOnboarding = false }: UserProps) {
  const appState = useContext(AppContext);
  const { user, isStaff, showGroup, hotelList } = appState;
  const userDisplay =
    `${user.first_name} ${user.profile?.last_name}` || user.name || user.email;
  const [STRINGS] = useLangStrings<'Dashboard'>('Dashboard');
  const [showMenu, openMenu, closeMenu] = useFlagState(false);
  const { logOutUser } = useContext(AuthContext);

  useEffect(() => {
    window.addEventListener('click', closeMenu);
    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, []);

  const getUserRole = () => {
    if (user && user.profile && user.profile.role === 0) {
      return 'admin';
    }
    return 'user';
  };
  const openUserMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    openMenu();
  };

  const addNewHotel = () => {
    navigateTo(routes.onboarding.myProperty);
  };

  const backToDashboard = () => {
    navigateTo(routes.dashboard.base);
  };

  const viewProfile = () => {
    //  sessionStorage.setItem('VIEW_USER_PROFILE', 'true');
    navigateTo(routes.userManagement.profile, { isCalledViewProfile: true });
  };

  const manageTeam = () => {
    //  sessionStorage.removeItem('VIEW_USER_PROFILE');
    navigateTo(routes.userManagement.team);
  };

  const changePassword = () => {
    pushToGTM(GTM_CATEGORIES.HEADER, 'change_password');
    navigateTo(
      `${routes.login.resetPasswordConfirm}?userName=${
        user?.name || ''
      }&isStaff=${appState.isStaff}`,
    );
  };
  const clearResellerSessionStorageData = () => {
    hotelList?.forEach(hotel => {
      // eslint-disable-next-line
      hotel?.hotelcode &&
        sessionStorage.removeItem(`hide-reseller-popup-${hotel.hotelcode}`);
    });
  };
  const logoutHandler = () => {
    pushToCtaOmnitureCommon({
      ctaName: 'log_out',
      componentName: '',
      pageName: 'dashboard',
    });
    logOutUser(true, () => {
      document.cookie = 'closed=;expires=Thu, 01 Jan 1970 00:00:00 UTC';
      pushToGTM(GTM_CATEGORIES.HEADER, 'signout');
      clearResellerSessionStorageData();
    });
  };
  return (
    <div
      data-test-id={Dashboard.userMenu}
      className={`sr-dropdown${
        showMenu ? ' open' : ''
      } hover:bg-color-primary hover:rounded-md h-[52px] py-1 px-2 xmd:w-[190px] `}
      style={style}
    >
      <div
        className="flex items-center justify-start lg:gap-4 xmd:gap-2 cursor-pointer w-100"
        onClick={openUserMenu}
      >
        <Avatar className="bg-color-primary">
          <span className="user-name fw500 font21">
            {(user.profile?.first_name ?? user?.first_name)
              ?.slice(0, 1)
              ?.toUpperCase()}
            {user?.profile?.last_name?.slice(0, 1)?.toUpperCase()}
          </span>
        </Avatar>
        <div className="flex justify-between w-[120px]">
          <Typography
            className="!text-size-12 whitespace-nowrap overflow-hidden text-ellipsis"
            fb
          >
            Hi, {userDisplay}
          </Typography>
          <ChevronDown />
        </div>
      </div>
      <div
        className="pad0 sr-dropdown-menu"
        style={{
          minWidth: '280px',
          marginTop: '20px',
          left: '-90px',
        }}
      >
        <div className="sr-flex-row">
          <div className="sr-flex1 fb">
            <div
              className="padT19 padB15 hover sr-cursor borBtm"
              onClick={viewProfile}
            >
              <div className="d-flex marginLR19">
                <div>
                  <img src={UserImg} alt="User symbol" className="sr-profilE" />
                </div>
                <div className="marginL10" style={{ width: '100%' }}>
                  <div className="d-flex sr-justify-space">
                    <span className="users">{userDisplay}</span>
                    <ChevronRight />
                  </div>
                  <div className="marginT3 font11 opacity6">
                    {user.profile.user_designation}
                  </div>
                </div>
              </div>
            </div>

            {!showGroup && (
              <div className="sr-whiteSPre padT19 hover sr-curPointer">
                <div
                  data-test-id="onborading-add-hotel"
                  className="borBtm d-flex sr-justify-space padB19 marginLR19"
                  onClick={inOnboarding ? backToDashboard : addNewHotel}
                >
                  <span className="users d-flex">
                    {inOnboarding ? (
                      <>{STRINGS.USER.BACK_TO_DASHBOARD}</>
                    ) : (
                      <>
                        <img
                          className="marginR6"
                          style={{ width: '10px', height: '20px' }}
                          alt=""
                          src={PlusIcon}
                        />
                        {isStaff ? STRINGS.USER.FCL : STRINGS.USER.ADD_HOTELS}
                      </>
                    )}
                  </span>
                  <span>
                    <ChevronRight />
                  </span>
                </div>
              </div>
            )}

            {!showGroup && getUserRole() === 'admin' && (
              <div className="padT19 hover sr-curPointer">
                <dt
                  className="borBtm d-flex sr-justify-space padB19 marginLR19"
                  onClick={manageTeam}
                >
                  <Tooltip
                    placement="left"
                    content={STRINGS.USER.MANAGE_TEAM_TOOLTIP}
                  >
                    <span className="users">
                      {STRINGS.USER.MANAGE_YOUR} {STRINGS.USER.TEAM}
                    </span>
                  </Tooltip>
                  <span>
                    <ChevronRight />
                  </span>
                </dt>
              </div>
            )}

            <div className="padT19 hover sr-curPointer">
              <dt
                className="borBtm d-flex sr-justify-space padB19 marginLR19"
                onClick={changePassword}
              >
                <div className="users">{STRINGS.USER.CHANGE_PASSWORD}</div>
                <span>
                  <ChevronRight />
                </span>
              </dt>
            </div>
            <div className="pad19 hover sr-curPointer" onClick={logoutHandler}>
              <span className="users blue-lt fmed font12 width100 dib">
                {STRINGS.USER.LOGOUT}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
