import { useCallback } from 'react';
import HierarchicalOption from '../../Components/HierarchicalOption';
import IconVariantOption from '../Components/IconVariantOption';
import CheckboxVariantOption from '../Components/CheckboxVariantOption';
import { AutoCompleteProps, type RenderOption } from '../Dropdown.types';

type Options = AutoCompleteProps['options'];

type UseOptionRendererParams = {
  options: Options;
  isHierarchicalVariant: boolean;
  isIconVariant: boolean;
  isCheckboxVariant: boolean;
  isMultiSelectVariant: boolean;
  isCustom: boolean;
  optionLabel: (option: unknown) => string;
  hierarchicalLabelField: string;
  renderOption: RenderOption;
  labelField: string;
};

function useOptionRenderer({
  options,
  isHierarchicalVariant,
  isIconVariant,
  isCheckboxVariant,
  isMultiSelectVariant,
  isCustom,
  optionLabel,
  hierarchicalLabelField,
  renderOption,
  labelField,
}: UseOptionRendererParams) {
  return useCallback(
    (incomingProps, option, state) => {
      if (isHierarchicalVariant) {
        return (
          <HierarchicalOption
            incomingProps={incomingProps}
            optionLabel={optionLabel(option)}
            optionHierarchicalSubLabel={option[hierarchicalLabelField]}
          />
        );
      } else if (isIconVariant) {
        return (
          <IconVariantOption
            optionLabel={optionLabel(option)}
            incomingProps={incomingProps}
          />
        );
      } else if (isCheckboxVariant || isMultiSelectVariant) {
        return (
          <CheckboxVariantOption
            option={optionLabel(option)}
            incomingProps={incomingProps}
            selected={state.selected}
          />
        );
      } else if (isCustom) {
        return renderOption(incomingProps, option, {
          selected: state.selected,
          inputValue: state.inputValue,
          labelField,
        });
      }
      return <div {...incomingProps}>{optionLabel(option)}</div>;
    },
    [options],
  );
}

export default useOptionRenderer;
