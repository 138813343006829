import { APP_MAIN } from '../base/Url';
import axios, { setCommonHeaders, showError } from '../base/APIClient';
import { InitialDataAPI, ParsedInitData } from 'interfaces/AppContextInterface';

import * as Hashes from 'jshashes';

function doWebengageSetup() {
  const webengage = window.webengage;
  if (webengage) {
    webengage.user.login(window.webengage_uid);
    webengage.onReady(function () {
      webengage.screen(
        webengage.util.copy(
          {
            'User ID': webengage.state.getForever().cuid || '',
            htlcode: window.hotelGA.hotelCode,
          },
          webengage.state.getForever().uattr || {},
        ),
      );
    });
    return true;
  }
  return false;
}

function doSandeshLogin() {
  axios
    .get(APP_MAIN.SANDESH_LOGIN)
    .then(res => {
      const data = res.data;
      if (data.results && data.results.length) {
        window.webengage_uid = data.results[0].cns_uid;
        const done = doWebengageSetup();
        if (!done) {
          window.addEventListener('load', doWebengageSetup);
        }
      }
    })
    .catch(error => {
      console.error('Sandesh Login', error);
    });
}

export function getInitialData(): Promise<ParsedInitData> {
  return new Promise((resolve, reject) => {
    axios
      .get<InitialDataAPI>(APP_MAIN.INITIAL_DATA)
      .then(res => {
        const {
          csrf_token: csrfToken,
          helpcenter_url: helpcenterUrl,
          chain_ids: chainIds,
          dayuse_price_percentages: dayusePricePercentages,
          hotel,
          is_user_chain: isUserChain,
          staff,
          user,
          is_business_development_manager: isBusinessDevelopmentManager,
        } = res.data;
        //Setting global window stuff: Can remove later
        window.csrf_token = csrfToken;
        window.helpCenterUrl = helpcenterUrl;
        window.INGOIBIBO_GOOGLE_ANALYTICS_USERNAME = new Hashes.SHA256().hex(
          user.name,
        );
        window.userRole = user.profile.role === 0 ? 'Admin' : 'Staff';

        window.userGA = {
          isStaff: staff ? 1 : 0,
        };
        window.adobeUserGA = {
          userID: user.profile.id,
          loginStatus: 'logged_in',
          userType: staff ? 'staff' : 'non-staff',
        };

        window.hotelGA = {
          hotelCode: '',
        };
        //Initialize API headers
        setCommonHeaders(csrfToken);
        //Start loading UI
        const dataToPass = {
          hotelList: hotel,
          user: user,
          isStaff: staff,
          chainData: {
            chainId: chainIds ? chainIds?.[0] : null,
            isChainUser: isUserChain || false,
          },
          dayUsePricePercentages: dayusePricePercentages,
          isBdManager: isBusinessDevelopmentManager,
        };
        resolve(dataToPass);

        doSandeshLogin();
      })
      .catch(err => {
        const msg = err?.response?.data || null;
        if (msg) {
          showError(msg);
        }
        reject(msg);
      });
  });
}
