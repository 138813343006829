import { RTBIcons } from 'assets/common';
import { Button, Modal } from 'components/latest-core';
import { Dispatch, SetStateAction } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';

type KnowMorePopupProps = {
  setKnowMorePopup: Dispatch<SetStateAction<boolean>>;
};

const KnowMorePopup = (props: KnowMorePopupProps) => {
  const { setKnowMorePopup } = props;

  const [STRINGS, COMMON] = useLangStrings<'Reseller'>('Reseller');
  return (
    <Modal isOpen>
      <div className="flex flex-col items-center justify-center">
        <RTBIcons.GreenTickIconComp height={108} width={108} />
        <div className="font-[500] text-[18px] text-[#2E7D32]">
          {STRINGS.KNOW_MORE_POPUP.WE_HAVE_ACCEPTED_YOUR_REQUEST}
        </div>
        <div className="mt-2 flex flex-col items-center">
          <div className="text-[16px] font-[500]">
            {STRINGS.KNOW_MORE_POPUP.WANT_TO_KNOW_MORE}
          </div>
          <div className="text-[14px] text-center">
            {STRINGS.KNOW_MORE_POPUP.PLEASE_CONNECT_WITH_YOUR_BDM_TO_KNOW_MORE}
          </div>
        </div>
        <Button
          variant={'outlined'}
          onClick={() => setKnowMorePopup(false)}
          className="mt-6"
        >
          {COMMON.BUTTONS.CLOSE_TXT}
        </Button>
      </div>
    </Modal>
  );
};

export default KnowMorePopup;
