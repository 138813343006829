import React from 'react';
import { browsers } from '../../assets/modules/app';
import { useLangStrings } from '../../utilities/CustomHooks';
import { ingoLogo } from 'assets/common';
import browserVersions from '../../browsers.json';
import './UnsupportedBrowser.scss';

interface UnsupportedBrowserProps {
  acknowledge: () => void;
}

interface Browser {
  name: string;
  lastSupportedVersion: number;
  logo: string;
  url: string;
}

const AllSupportedBrowsers: Browser[] = [
  {
    name: 'Chrome',
    lastSupportedVersion: browserVersions.chrome,
    logo: browsers.chrome,
    url: 'https://www.google.com/chrome/',
  },
  {
    name: 'Edge',
    lastSupportedVersion: browserVersions.edge,
    logo: browsers.edge,
    url: 'https://www.microsoft.com/edge',
  },
  {
    name: 'Firefox',
    lastSupportedVersion: browserVersions.firefox,
    logo: browsers.firefox,
    url: 'https://www.mozilla.org/firefox/new/',
  },
  {
    name: 'Safari',
    lastSupportedVersion: browserVersions.safari,
    logo: browsers.safari,
    url: 'https://www.apple.com/in/safari/',
  },
];

export default function UnsupportedBrowser(props: UnsupportedBrowserProps) {
  const { acknowledge } = props;

  const [{ UNSUPPORTED_BROWSER: STRINGS }, COMMON_STRINGS] =
    useLangStrings<'App'>('App');

  return (
    <div className="unsupport-browser-wrapper">
      <div className="header-wrapper">
        <img src={ingoLogo} width="35px" alt="ingo_logo" />
        <span className="black-lt opacity9 ico22 fb padL5">
          {COMMON_STRINGS.LABELS.GO_MMT}
        </span>
      </div>
      <img
        className="unsupported-imagery"
        src="https://gos3.ibcdn.com/unsupportbrowser-1581445105.png"
        alt="unsupport browser"
      />
      <h3>{STRINGS.TITLE}</h3>
      <p>{STRINGS.DETAIL}</p>
      <button onClick={acknowledge}>{STRINGS.BUTTON_TEXT}</button>
      <div className="supported-browser-container">
        <label>{STRINGS.SUPPORTED_LABEL}: </label>
        {AllSupportedBrowsers.map(item => (
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <img src={item.logo} alt="browser logo" />
            <span className="browser-name">{item.name}</span>
            <span className="browser-version">
              {STRINGS.VERSION} {item.lastSupportedVersion}+
            </span>
          </a>
        ))}
      </div>
    </div>
  );
}
