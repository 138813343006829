import { useState, useMemo, useEffect, Ref, forwardRef } from 'react';
import { Box, BoxProps, Popover, TextField } from '@mui/material';
import Typography from '../Typography/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Clock } from 'assets/latest_core';
import {
  getDataPropsFromRest,
  isEmpty,
  isNullOrUndefined,
} from 'utilities/Utils';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  PathValue,
  RegisterOptions,
} from 'react-hook-form';
import { PopOver } from './Components/PopOver';
type CommonProps = {
  placeholder?: string;
  closePopoverOnSelect?: boolean;
  BoxProps?: BoxProps;
  is24HrFormat?: boolean;
  minuteInterval?: number;
  showNowButton?: boolean;
  disabled?: boolean;
  disabledHours?: string[];
  removeSeconds?: boolean;
};
type Props = CommonProps & {
  time?: string;
  callback: (time: string) => void;
  autoFocus?: boolean;
};
type RangeFromToProps = {
  label: string;
  placeholder?: string;
};
type RangeProps = {
  from?: string;
  to?: string;
  fromProps: RangeFromToProps;
  toProps: RangeFromToProps;
  callback: (time: Pick<RangeProps, 'from' | 'to'>) => void;
  disabled?: boolean;
  is24HrFormat?: boolean;
  closePopoverOnSelect?: boolean;
  BoxProps?: BoxProps;
  minuteInterval?: number;
  autoFocus?: boolean;
};
const useStyles = makeStyles(theme => ({
  popover: {
    marginTop: '4px',
    padding: '20px',
    overflow: 'none',
    zIndex: '100000002 !important',
    '& [class*="MuiPopover-paper-"]': {
      width: 'inherit',
    },
  },
  textField: (props: { isPopOverOpen }) => ({
    width: '100%',
    height: '41px',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    '& fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
      border: props.isPopOverOpen && '2px solid #3543BF',
    },
    '& > div:first-child': {
      cursor: 'pointer',
      paddingRight: '8px',
      height: '40px !important',
      background: theme.palette.common.white,
      '& input': {
        cursor: 'pointer',
        padding: '10px 12px 9px !important',
        caretColor: 'transparent',
      },
    },
  }),
  hours: {
    height: '148px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  selected: {
    background: theme.palette.backgrounds.primary,
    fontWeight: 500,
  },
  footer: {
    border: `1px solid ${theme.palette.backgrounds.divider}`,
    padding: '8px 10px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  nowButton: {
    color: '#3543BF',
  },
  okButton: {
    backgroundColor: '#3543BF',
    color: theme.palette.common.white,
    borderRadius: '4px',
    padding: '2px 8px',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginLeft: 'auto',
  },
}));
//Input format will be hh:mm:ss
const parseTime = (time: string, is24HrFormat: boolean) => {
  if (isNullOrUndefined(time)) return { hour: '', minute: '' };
  let [hour, minute] = time.split(':').map(Number); //eslint-disable-line prefer-const
  const interval = !is24HrFormat ? (hour < 12 ? 'am' : 'pm') : null;
  if (!is24HrFormat) {
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour -= 12;
    }
  }
  return { hour, minute, interval };
};
const TimePickerComp = (props: Props, ref?: Ref<HTMLDivElement>) => {
  const {
    placeholder = 'hh:mm',
    time = null, // send this in 24-hr format
    callback,
    closePopoverOnSelect = true,
    BoxProps: BoxParams,
    is24HrFormat = true,
    minuteInterval = 30,
    showNowButton = false,
    disabled = false,
    autoFocus = true,
    disabledHours = [],
    removeSeconds = false,
    ...rest
  } = props;
  const { hour, minute, interval = undefined } = parseTime(time, is24HrFormat);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState({
    hour: time ? `${hour}`.padStart(2, '0') : null,
    minute: time ? `${minute}`.padStart(2, '0') : null,
    ...(!is24HrFormat && { interval: time ? interval : null }),
  });
  const open = Boolean(anchorEl);
  const classes = useStyles({ isPopOverOpen: open });
  const formatSelectedTime = () => {
    if (is24HrFormat)
      return `${selected.hour}:${selected.minute}${removeSeconds ? '' : ':00'}`;
    const formattedHour = (
      (parseInt(selected.hour) === 12 ? 0 : parseInt(selected.hour)) +
      (selected.interval === 'am' ? 0 : 12)
    )
      .toString()
      .padStart(2, '0');
    return `${formattedHour}:${selected.minute}${removeSeconds ? '' : ':00'}`;
  };
  const selectedValues = useMemo(() => {
    if (!selected.hour || !selected.minute) return null;
    let formattedTime = `${selected.hour} : ${selected.minute}`;
    if (!is24HrFormat)
      formattedTime = `${formattedTime} ${selected.interval ?? ''}`;
    if (formattedTime === '12 : 00 pm') formattedTime += ' (noon)';
    return formattedTime;
  }, [selected]);
  const dataTestIdProps = getDataPropsFromRest(rest);
  useEffect(() => {
    if (selected.hour && selected.minute && closePopoverOnSelect) {
      callback(formatSelectedTime());
      setAnchorEl(null);
    }
  }, [selected]);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // allow only close if both are selected or none are selected
    if (
      (selected.hour && selected.minute && selected.interval) ||
      (!selected.hour && !selected.minute && !selected.interval) ||
      (is24HrFormat && selected.hour && selected.minute)
    ) {
      setAnchorEl(null);
      const timeSel = formatSelectedTime();
      if (selected.hour) {
        callback(timeSel);
      }
    }
  };
  const handleNowClick = () => {
    const date = new Date();
    const parsedHour = date.getHours();
    const parsedParsedHour = is24HrFormat
      ? parsedHour
      : parsedHour > 12
        ? parsedHour - 12
        : parsedHour;
    const minutes = date.getMinutes();
    setSelected({
      hour: parsedParsedHour.toString().padStart(2, '0'),
      minute: minutes.toString().padStart(2, '0'),
      ...(!is24HrFormat && { interval: parsedHour > 12 ? 'pm' : 'am' }),
    });
  };
  return (
    <Box bgcolor="common.white" {...BoxParams} {...dataTestIdProps}>
      <TextField
        autoFocus={autoFocus}
        placeholder={placeholder}
        variant="outlined"
        onClick={handleClick}
        className={classes.textField}
        value={selectedValues}
        InputProps={{
          endAdornment: <Clock />,
        }}
        disabled={disabled}
        inputRef={ref}
      />
      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PopOver
          closePopoverOnSelect={closePopoverOnSelect}
          showNowButton={showNowButton}
          classes={classes}
          setSelected={setSelected}
          is24HrFormat={is24HrFormat}
          minuteInterval={minuteInterval}
          handleNowClick={handleNowClick}
          handleClose={handleClose}
          selected={selected}
          disabledHours={disabledHours}
        />
      </Popover>
    </Box>
  );
};

export const TimePicker = forwardRef(TimePickerComp);

export const TimePickerRange = (props: RangeProps) => {
  const {
    from = null,
    to = null,
    fromProps,
    toProps,
    callback,
    ...rest
  } = props;
  const [time, setTime] = useState({
    from,
    to,
  });
  const getStartValue = newTime => {
    setTime(prev => ({ ...prev, from: newTime }));
  };
  const getEndValue = newEndTime => {
    setTime(prev => ({ ...prev, to: newEndTime }));
  };
  useEffect(() => {
    if (!isEmpty(time.from) && !isEmpty(time.to)) {
      callback(time);
    }
  }, [time]);
  return (
    <div className="flex gap-4 w-full flex-1">
      <div className="w-full">
        <Box my={1}>
          <Typography themeColor="gray.main">{fromProps.label}</Typography>
        </Box>
        <TimePicker
          {...{
            time: time.from,
            placeholder: fromProps?.placeholder,
            callback: getStartValue,
            closePopoverOnSelect: true,
            ...rest,
          }}
        />
      </div>
      <div className="w-full">
        <Box my={1}>
          <Typography themeColor="gray.main">{toProps.label}</Typography>
        </Box>
        <TimePicker
          {...{
            time: time.to,
            placeholder: toProps?.placeholder,
            callback: getEndValue,
            closePopoverOnSelect: true,
            ...rest,
          }}
        />
      </div>
    </div>
  );
};
type ControlledTimePickerProps<
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = CommonProps & {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: string;
  isRequired?: boolean;
  customValidator?: RegisterOptions<T, TName>['validate'];
};
export const ControlledTimePicker = <
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
    props: ControlledTimePickerProps<T, TName>,
  ) => {
  const {
    name,
    control,
    defaultValue = null,
    customValidator,
    isRequired = false,
    ...additional
  } = props;
  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };
  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const {
    field: { onChange, value, ref },
  } = useController<T, TName>({
    name,
    defaultValue: defaultValue as PathValue<T, TName>,
    control,
    rules: validations,
  });
  return (
    <TimePicker
      time={value}
      callback={newValue => onChange(newValue)}
      ref={ref}
      {...additional}
    />
  );
};
