import moment, { MomentInput, Moment, unitOfTime } from 'moment';
import {
  displayDateFormat,
  displayTimeFormat,
  inventoryMaxFutureDays,
} from '../constants';

export const MonthNames = [
  { short: 'Jan', long: 'January' },
  { short: 'Feb', long: 'February' },
  { short: 'Mar', long: 'March' },
  { short: 'Apr', long: 'April' },
  { short: 'May', long: 'May' },
  { short: 'Jun', long: 'June' },
  { short: 'Jul', long: 'July' },
  { short: 'Aug', long: 'August' },
  { short: 'Sep', long: 'September' },
  { short: 'Oct', long: 'October' },
  { short: 'Nov', long: 'November' },
  { short: 'Dec', long: 'December' },
];

export const DaysName = [
  { short: 'Sun', long: 'Sunday' },
  { short: 'Mon', long: 'Monday' },
  { short: 'Tue', long: 'Tuesday' },
  { short: 'Wed', long: 'Wednesday' },
  { short: 'Thu', long: 'Thusday' },
  { short: 'Fri', long: 'Friday' },
  { short: 'Sat', long: 'Saturday' },
];

export const DaysNameShort = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
] as const;

export function addTime(timeIn24: MomentInput, diff: number) {
  return moment(timeIn24, 'HH:mm').add(diff, 'hours');
}

export function getFormattedTime(
  timeIn24: MomentInput,
  format: string = displayTimeFormat,
) {
  return moment(timeIn24, 'HH:mm').format(format);
}

export const getDiffBetweenTimes = (start: MomentInput, end: MomentInput) => {
  const duration = moment.duration(
    moment(end, 'HH:mm').diff(moment(start, 'HH:mm')),
  );
  return duration.asHours();
};

export const getTimeDiff = (start: MomentInput, end: MomentInput) => {
  const duration = moment.duration(
    moment(end, 'HH:mm:ss').diff(moment(start, 'HH:mm:ss')),
  );
  const inHour = parseInt(`${duration.asHours()}`);
  const inMinute = parseInt(`${duration.asMinutes() % 60}`);
  const inSecond = parseInt(`${duration.asSeconds() % 60}`);
  return `${inHour}h:${inMinute}m:${inSecond}s`;
};

export function getTimeFromNow(date: MomentInput) {
  return moment(date).fromNow();
}

export function getStartOfDay(momentDate: MomentInput) {
  return moment(momentDate).startOf('day');
}

export function getEndOfDay(momentDate: MomentInput) {
  return moment(momentDate).endOf('day');
}

export function getStartOfMonth(date: Date = new Date()) {
  return moment(date).startOf('month');
}
export function getEndOfMonth(date: Date = new Date()) {
  return moment(date).endOf('month');
}
export function getStartOfLastMonth() {
  return moment().subtract(1, 'months').startOf('month');
}
export function getPreviourDay(val) {
  return moment().subtract(val, 'days');
}
export function getStartOfNextMonth() {
  return moment().add(1, 'months').startOf('month');
}
export function getEndOfNextMonth() {
  return moment().add(1, 'months').endOf('month');
}
export function getEndOfLastMonth() {
  return moment().subtract(1, 'months').endOf('month');
}
export function getStartOfQuarter() {
  return moment().startOf('quarter');
}
export function getEndOfQuarter() {
  return moment().endOf('quarter');
}
export function getStartOfLastQuarter() {
  return moment().subtract(1, 'quarters').startOf('quarter');
}
export function getEndOfLastQuarter() {
  return moment().subtract(1, 'quarters').endOf('quarter');
}
export function getStartOfFinancialYear() {
  return moment(`${moment().subtract(1, 'years').year()}`, 'YYYY')
    .quarter(2)
    .startOf('quarter');
}
export function getEndOfFinancialYear() {
  return moment(`${moment().year()}`, 'YYYY').quarter(1).endOf('quarter');
}

export function getStartOfYear() {
  return moment().startOf('year');
}

export function noOfDaysInMonth(date: Date = new Date()): number {
  return moment(date).daysInMonth();
}

export const getFutureDate = (
  currentDate: Date | string,
  futureDays: number,
  unit = 'days',
) => {
  const date = new Date(currentDate);
  if (unit === 'days') {
    date.setDate(date.getDate() + futureDays);
  } else if (unit === 'months') {
    date.setMonth(date.getMonth() + futureDays);
  }
  return date;
};

//Expects date objects
export function onlyDateComparison(date1: Date, date2: Date) {
  const d1 = date1.setHours(0, 0, 0, 0);
  const d2 = date2.setHours(0, 0, 0, 0);
  if (d1 === d2) {
    return 0;
  } else if (d1 < d2) {
    return -1;
  }
  //if (d1 > d2)
  return 1;
}

//Ignoring the time of the day
export function isToday(date: Date) {
  return onlyDateComparison(new Date(), date) === 0;
}

export function isTomorrow(date: Date) {
  return onlyDateComparison(getFutureDate(new Date(), 1), date) === 0;
}

//Ignoring the time of the day
export function isDateSame(date1: Date, date2: Date) {
  return onlyDateComparison(date1, date2) === 0;
}

export function isDatesSameInMinutes(date1: Date, date2: Date) {
  return moment(date1).isSame(moment(date2), 'minute');
}

// Converting 24 hour format to 12 hour format
export function convertTo12HourFormat(time24: string) {
  // eslint-disable-next-line prefer-const
  let [hours, minutes, seconds] = time24.split(':');
  const period = +hours >= 12 ? 'PM' : 'AM';
  if (+hours === 0 || +hours === 12) {
    hours = '12';
  } else if (+hours > 12) {
    hours = String(+hours - 12).padStart(2, '0');
  }
  let time12 = `${hours}:${minutes}`;
  if (seconds && seconds !== '00') {
    time12 += `:${seconds}`;
  }
  return `${time12} ${period}`;
}

export function getDay(date: Date) {
  return date.toDateString().split(' ')[0];
}

export const getDates = (noOfDays: number, initialDate = new Date()) => {
  const dateArray: Array<string> = [];
  let currentDate = initialDate;
  let num = 0;
  while (num < noOfDays) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days').toDate();

    num++;
  }
  return dateArray;
};

export const getFormattedDate = (
  date: MomentInput,
  dateFormat = displayDateFormat,
): string => {
  return moment(date).format(dateFormat);
};

export const readCustomDateFormat = (date: MomentInput, format: string) => {
  if (date && format) {
    return moment(date, format);
  }
  return date;
};

//This function disables the ARI changes higher than inventoryMaxFutureDays days as our system doesn't support it
export const disableFutureDate = (daysOffest: number, current: Moment) => {
  if (!current) {
    // allow empty select
    return false;
  }
  // can not select higher than inventoryMaxFutureDays days in future
  const date = moment().add(inventoryMaxFutureDays - daysOffest, 'days');
  return current.toDate() > date.toDate();
};

export const getFullMonthName = (date: Date) => {
  return moment(date).format('MMMM');
};

export const getDateDifference = (
  date1: MomentInput,
  date2: MomentInput,
  unit: unitOfTime.Diff = 'days',
  precise?: boolean,
) => {
  return moment(date1).diff(date2, unit, precise);
};

export const getDiffInMinutes = (date1: MomentInput, date2: MomentInput) => {
  const diff = Math.abs(moment(date1).valueOf() - moment(date2).valueOf());
  const minutes = Math.floor(diff / 1000 / 60);
  return minutes;
};

export const isDateInRange = (
  startDate: Date | string,
  endDate: Date | string,
  inputDate: Date | string,
) => {
  const sDate = new Date(startDate).getTime();
  const eDate = new Date(endDate).getTime();
  const iDate = new Date(inputDate).getTime();

  return iDate >= sDate && iDate < eDate;
};

export const getUTCFormattedDate = (date: MomentInput, format: string) => {
  if (date) {
    return moment.utc(date).local().format(format);
  }
  return date;
};

/**
 * @description
 * This method will accept month whose offset has to be taken and value has to be predicted.
 *
 * @param {number} month offset month
 *
 * @returns {string} number of days from current day to next month
 */
export const mapMonthsToDays = (month: number) => {
  const now = new Date().getTime();
  const futureDate = new Date().setMonth(new Date().getMonth() + month);
  const diffTime = Math.abs(futureDate - now);
  const diffDate = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDate.toString();
};

export const getMomentObject = (date: MomentInput, format: string) => {
  return moment(date, format);
};
export const convertStrToMomentObj = (
  date: MomentInput,
  format?: string,
): Date => {
  return moment(date, format) as unknown as Date;
};

export const getMomentObjectByTime = (
  hours: number = 0,
  minutes: number = 0,
  seconds: number = 0,
) => {
  return moment().hour(hours).minute(minutes).second(seconds);
};
export const getCurrentMonth = () => new Date().getMonth();
export const getCurrentYear = () => new Date().getFullYear();
export const getPreviousYear = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getFullYear();

export const getShortMonthsList = () => [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function millisToMinutesAndSeconds(
  millis: number,
  unit: 'minutes' | 'days' = 'minutes',
) {
  if (unit === 'days') {
    //Get days from milliseconds
    const days = millis / 86400000; //86400000 = 24 * 60 * 60 * 1000
    const absoluteDays = Math.floor(days);
    const d = String(absoluteDays);

    //Get remainder from days and convert it into hours
    const hours = (days - absoluteDays) * 24;
    const absoluteHours = Math.floor(hours);
    const h = absoluteHours > 9 ? absoluteHours : `0${absoluteHours}`;

    //Get remainder from hours and convert to minutes
    const minutes = (hours - absoluteHours) * 60;
    const absoluteMinutes = Math.floor(minutes);
    const m = absoluteMinutes > 9 ? absoluteMinutes : `0${absoluteMinutes}`;

    //Get remainder from minutes and convert to seconds
    const seconds = (minutes - absoluteMinutes) * 60;
    const absoluteSeconds = Math.floor(seconds);
    const s = absoluteSeconds > 9 ? absoluteSeconds : `0${absoluteSeconds}`;

    return { minutes: m, seconds: s, hours: h, days: d };
  }

  const minutes = `0${Math.floor(millis / 60000)}`.slice(-2);
  const seconds = `0${((millis % 60000) / 1000).toFixed(0)}`.slice(-2);
  return { minutes, seconds };
}

/**
 * @description
 * This method will accept 24 hour time and return 12 hour meridian string
 *
 * @param {string} 24/12 hour time in (hh:mm:ss) format e.g:- 15:30:20
 *
 * @returns {string} 12 hour string e.g:- 03:30 pm
 */
export function convert24hrsToAMPM(time: string) {
  if (!time) {
    return '';
  }
  const [hrs, mins, secs] = time.split(':');
  let finalTimeArr = [];
  let newhrs: string | number = +hrs % 12;
  let timeStr = ' AM';
  if (+hrs >= 12) {
    timeStr = ' PM';
  }
  if (newhrs === 0) {
    newhrs = 12;
  }
  if (newhrs < 10) {
    newhrs = `0${newhrs}`;
  }

  finalTimeArr = [newhrs, mins, secs].filter(item => item);

  return finalTimeArr.join(':') + timeStr;
}

export const groupDates = (
  selectedDays: Date[],
  shouldGroupTwoValues: boolean = false,
) => {
  const dates = selectedDays;
  const sortedDates = dates.sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime(),
  );

  let groupedDates = sortedDates.reduce(function (acc, val) {
    const date = moment(val);
    const present = acc.some(datesObj => {
      const previousDay = date.clone().subtract(1, 'day');
      const nextDay = date.clone().add(1, 'day');
      if (
        datesObj[previousDay.toDate().toString()] ||
        datesObj[nextDay.toDate().toString()]
      ) {
        datesObj[val.toString()] = true;
        return true;
      }
      return false;
    });
    if (!present) {
      acc.push({ [val.toString()]: true });
    }
    return acc;
  }, []);

  groupedDates = groupedDates.map(datesObj => {
    const keys = Object.keys(datesObj);
    if (shouldGroupTwoValues && keys.length === 1) {
      keys.push(keys[0]);
    }
    return keys;
  });
  return groupedDates;
};

export const unGroupDates = (dates: [MomentInput, MomentInput][]) => {
  const flatDateList = [];
  for (let index = 0; index < dates.length; index++) {
    const [from, to] = dates[index];
    const fromDate = moment(from);
    const toDate = moment(to);

    const dateDiff = toDate.diff(fromDate, 'days');
    flatDateList.push(fromDate.toDate());
    if (dateDiff !== 0) {
      let nextDate = fromDate.clone().add(1, 'days');
      for (let i = 1; i <= dateDiff; i++) {
        flatDateList.push(nextDate.toDate());
        nextDate = nextDate.clone().add(1, 'days');
      }
    }
  }

  const sortedDates = flatDateList.sort((a, b) => a - b);
  return sortedDates;
};

// use this for API calls of blackout
export const formattedGroupDates = (blackOutList: Date[], format: string) => {
  const groupedList = groupDates(blackOutList, false);

  return groupedList.map(day => {
    const firstDay = getFormattedDate(day[0], format);
    const lastDay = getFormattedDate(day[day.length - 1], format);
    return [firstDay, lastDay];
  });
};

export const dateInit = (
  date: string | number | Date,
  fallBackDate: Date = null,
) => {
  return date ? new Date(date) : fallBackDate;
};

export const getFirstDate = (year: number, month: number) => {
  return new Date(new Date().setFullYear(year, month - 1, 1));
};

export const getLastDate = (year: number, month: number) => {
  return new Date(year, month, 0);
};

export function addToDate(
  totalNumber: number,
  unit: unitOfTime.Base | unitOfTime._quarter = 'days',
  date: Date = new Date(),
) {
  const newDate = moment(date).add(totalNumber, unit).toDate();
  return new Date(newDate);
}

const diffDateFormatList = [
  'year',
  'months',
  'days',
  'hours',
  'minutes',
  'seconds',
];
export function dateDifference(
  date1: Date,
  date2: Date,
  formatType = diffDateFormatList,
): {
    [x: string]: number;
  } {
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);
  return formatType.reduce((acc, format: unitOfTime.Diff) => {
    acc[format] = momentDate1.diff(momentDate2, format);
    return acc;
  }, {});
}

export const clockTick = {
  days: 86400000,
  hours: 3600000,
  minutes: 60000,
  seconds: 1000,
};

// Input in secs Output in x secs or x mins y secs or x hrs y mins z secs
export function convertSecondsToHoursMinutes(seconds: number) {
  if (seconds < 60) {
    return [
      {
        value: seconds,
        unit: 'secs',
      },
    ];
  }
  const minutes = parseInt((seconds / 60).toString());
  const remainingSeconds = parseInt((seconds / 60).toString());
  if (minutes < 60) {
    if (remainingSeconds) {
      return [
        {
          value: minutes,
          unit: 'mins',
        },
        {
          value: remainingSeconds,
          unit: 'secs',
        },
      ];
    }
    return [
      {
        value: minutes,
        unit: 'mins',
      },
    ];
  }
  const hours = parseInt((minutes / 60).toString());
  const remainingMinutes = parseInt((minutes % 60).toString());
  if (remainingMinutes) {
    return [
      {
        value: hours,
        unit: 'hrs',
      },
      {
        value: remainingMinutes,
        unit: 'mins',
      },
    ];
  }
  return [
    {
      value: hours,
      unit: 'hrs',
    },
  ];
}

// Input e.g: ['2021-05-30', '2021-05-31', '2021-07-01'].
// Output e.g {'April 2021': ['2021-05-30', '2021-05-31'], 'July 2021': ['2021-07-01'] }
// Note: The order of same month dates in the input array is maintained in the output object array
export function groupDatesByMonthYear(datesArr: string[] | Date[]) {
  const toReturnObj = {};
  datesArr.forEach(date => {
    const monthKey = getFormattedDate(date, 'MMMM YYYY');

    if (!toReturnObj[monthKey.toString()]) {
      toReturnObj[monthKey.toString()] = [date];
    } else {
      toReturnObj[monthKey.toString()].push(date);
    }
  });
  return toReturnObj;
}

export const returnTime = timeVal => {
  if (timeVal) {
    const valuesArr = timeVal.split(':');
    timeVal = getMomentObjectByTime(valuesArr[0], valuesArr[1]);
    return timeVal;
  }
  return null;
};

export const getDateObject = date => {
  if (moment.isMoment(date)) {
    return moment(date).toDate();
  }
  return date;
};

export const isTimeGreater = (time1, time2) => {
  time1 = time1.split(':');
  time2 = time2.split(':');
  if (Number(time1[0]) > Number(time2[0])) {
    return true;
  } else if (
    Number(time1[0]) === Number(time2[0]) &&
    Number(time1[1]) > Number(time2[1])
  ) {
    return true;
  }
  return false;
};

export const getDateList = (numberOfDate: number) => {
  const dateList = [];

  const today = new Date();
  for (let i = 0; i < numberOfDate; i++) {
    const date = moment(getFutureDate(today, i)).format('YYYY-MM-DD');
    dateList.push(date);
  }

  return dateList;
};

export const getHour = (time: string): number =>
  Number(time.split(':').shift()) ?? 0;

const formatValue = value => String(value).padStart(2, '0');

export const hourToString = (hour: number) => `${formatValue(hour)}:00:00`;

const minutesToHour = (minutes: number) => minutes / 60;

export const addHour = (time: string, minutes: number): number =>
  getHour(time) + minutesToHour(minutes);

// get the latest object from array of objects on the basis of the date key
export const getLatestDateObject = (arr, key) => {
  return arr.reduce(
    (a, b) => (new Date(a[key]) > new Date(b[key]) ? a : b),
    {},
  );
};

export const sortDatesObj = (obj): string[] => {
  const objKeys = Object.keys(obj ?? {});
  const sortedDateKeys: string[] = objKeys.sort((a: string, b: string) => {
    return (
      +moment(a, 'DD-MM-YYYY').toDate() - +moment(b, 'DD-MM-YYYY').toDate()
    );
  });
  return sortedDateKeys;
};

export const checkCurrentWeek = (from: Date, to: Date) => {
  return (
    isToday(from) && getDateDifference(getFutureDate(new Date(), 7), to) === 0
  );
};

export const getPrevDays = x => {
  const today = new Date();
  const prevDays = [];
  for (let i = 0; i < x; i++) {
    const nextDay = getFormattedDate(
      getFutureDate(today, i * -1),
      'YYYY-MM-DD',
    );
    prevDays.push(nextDay);
  }
  return prevDays;
};
export const checkNodeIfDaysOfTheWeek = nodeName => {
  switch (nodeName) {
    case '1.0':
      return 'Monday';
    case '2.0':
      return 'Tuesday';
    case '3.0':
      return 'Wednesday';
    case '4.0':
      return 'Thursday';
    case '5.0':
      return 'Friday';
    case '6.0':
      return 'Saturday';
    case '7.0':
      return 'Sunday';

    default:
      return nodeName;
  }
};

type EnumOfDays = typeof DaysNameShort[number];
export const getDateByWeekDay = (
  dayOfTheWeek: EnumOfDays,
  dateFormat: string = displayDateFormat,
) => {
  //returns date on a specefic day. For example date on (coming) Tuesday.
  const weekDay = DaysNameShort.indexOf(dayOfTheWeek) + 1;
  const nextDate = new Date();
  nextDate.setDate(
    nextDate.getDate() + ((weekDay + 7 - nextDate.getDay()) % 7 || 7),
  );
  return getFormattedDate(nextDate, dateFormat);
};

type AddUnitOfTimeType = {
  date: MomentInput;
  noOfUnit: number;
  unit?: unitOfTime.Diff;
  isEndDate?: boolean;
};

export const addUnitOfTime = ({
  date,
  noOfUnit,
  unit = 'days',
  isEndDate = false,
}: AddUnitOfTimeType): MomentInput => {
  const newDate = moment(date).add(noOfUnit, unit);
  if (isEndDate) {
    return newDate.endOf('day');
  }
  return newDate;
};
