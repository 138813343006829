import React from 'react';
import { RadioLabelPropsType } from '../Radio.types';
import { classNames } from 'utilities/Utils';

const RadioLabel = (props: RadioLabelPropsType) => {
  const { option, radioClasses, classes } = props;
  const { root = '', label = '', description = '' } = radioClasses || {};

  return (
    <span className={classNames(classes.radioContentContainer, root)}>
      <span className={classNames(classes.radioLabelContainer, label)}>
        {option.label}
      </span>
      {option.description && (
        <span
          className={classNames(classes.radioDescriptionContainer, description)}
        >
          {option.description}
        </span>
      )}
    </span>
  );
};

export default React.memo(RadioLabel);
