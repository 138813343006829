import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'utilities/Context';
import { useLocation } from 'react-router-dom';
import {
  clearQueryParams,
  getParsedErrorMessageFromAPI,
} from 'utilities/Utils';
import { NewUserPasswordResetHeader, ResetResp } from './NewUserPassworReset';
import { Typography } from 'components/latest-core';
import { verifyExistingUser } from 'group-property/modules/user-management/UserManagementAPIClient';

const VerifyExistingUser = () => {
  const {
    langStringsData: { langStrings },
  } = useContext(AuthContext);
  const { pathname, search } = useLocation();

  const [{ username, token }] = useState(() =>
    clearQueryParams(pathname, search, ['username', 'token']),
  );
  const [respMess, setRespMess] = useState(null);
  const email = username && window.atob(username);

  const { Common: COMMON } = langStrings;

  useEffect(() => {
    const payload = {
      userName: username,
      token: token,
    };

    verifyExistingUser(payload)
      .then((res: ResetResp) => {
        const { response } = res;
        let message = '';

        if (response?.errorDetail) {
          message = getParsedErrorMessageFromAPI(response);
        } else if (response?.success) {
          message = response?.message;
        }
        if (message) {
          setRespMess(message);
        }
      })
      .catch(err =>
        setRespMess(
          err?.response?.data?.message || COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        ),
      );
  }, []);

  if (!respMess) return null;

  return (
    <NewUserPasswordResetHeader>
      <Typography
        variant="subtitle1"
        className="my-1 !text-[#090909] opacity-50"
      >
        {COMMON.LABELS.EMAIL}: {email}
      </Typography>
      <Typography
        variant="buttonText"
        className="pt-[10px] !text-[#090909] mb-[10px] !normal-case"
      >
        {respMess}
      </Typography>
    </NewUserPasswordResetHeader>
  );
};

export default VerifyExistingUser;
