import { createContext, SetStateAction } from 'react';
import {
  FetchServiceResponse,
  LeafCategory,
  ServiceNode,
  ServiceValidationInput,
} from './data/types/Schema';

export type DetailsDraftState = {
  selectedRatePlanCode: string;
  selectedRatePlanName: string;
  selectedRoomName: string;
  name: string;
  createMode: 'duplicate' | 'same';
};

export type PackagesContextType = {
  type: 'create' | 'update';
  detailsDraftState: DetailsDraftState | null;
  updateDetailsState: (action: SetStateAction<DetailsDraftState>) => void;
  inclusionLookupByIdMap: Record<number, LeafCategory> | null;
  setInclusionLookupByIdMap: (
    action: SetStateAction<Record<number, LeafCategory>>,
  ) => void;
  inclusionForDelete: ServiceNode | null;
  setInclusionForDelete(arg: ServiceNode | null): void;
  inclusionForEdit: ServiceValidationInput | null;
  setInclusionForEdit(arg: ServiceValidationInput | null): void;
  serviceResponse: FetchServiceResponse | null;
  updateServiceResponse: (action: SetStateAction<FetchServiceResponse>) => void;
};

export const PackagesContext = createContext<PackagesContextType>({
  type: 'create',
  detailsDraftState: null,
  updateDetailsState: () => {},
  inclusionLookupByIdMap: null,
  setInclusionLookupByIdMap: () => {},
  serviceResponse: null,
  inclusionForEdit: null,
  setInclusionForEdit() {},
  inclusionForDelete: null,
  setInclusionForDelete() {},
  updateServiceResponse: () => {},
});
