export const nameRegex = /^[a-zA-Z ]*$/;
export const nameWithNumberRegex = /^[_a-zA-Z0-9 ]*$/;
export const propertyNameRegex =
  /^(?!\s|[:|/\\()'`.,& -])(?!.*\s{2,})[0-9a-zA-Z|:/\\()'`.,&@ -]*$/;
export const nameWithNumberAndBracketsRegex = /^[_a-zA-Z0-9 ()]*$/;
export const nameWithNumberBracketsAndHyphenRegex = /^[_a-zA-Z0-9 ()-]*$/;

export const phoneRegex = /^\+?[-\d# ]{6,20}$/;
export const domPhoneRegex = /^[0-9]{10}$/;
export const telephoneRegex = /^[0-9-]+$/;
export const digitsRegex = /^\d+$/;

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
export const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const gstRegex =
  /^([0][1-9]|[1-2][0-9]|[3][0-8])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$/;
export const tanRegex = /([A-Za-z]{4}[0-9]{5}[A-Za-z]{1})$/;
export const ifscRegex = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
export const integerRegex = /^[0-9]*$/;
export const vatRegex = /^[0-9a-zA-Z]{1,20}$/;
export const alphaNumericRegex = /^[0-9a-zA-Z]+$/;
export const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
export const roomNameRegex =
  /^(?!\s|[:|/\\()'`.,&+ -])(?!.*\s{2,})[0-9a-zA-Z|:/\\()'`.,&@+ -]*$/;
export const spaceNameRegex = /^[_0-9a-zA-Z|:\-/\\()'`., &@+]*$/;

export const otherNameRegex = /^(?=\s*\S).{0,30}$/;
export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const spaceCheckRegex = /^\s*$/;

export const registerUserFullNameRegex = /^[A-Za-z'0-9, _-]*$/;
export const pwdWithAtleastOneAlpaNumericAndSplCharRegex =
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[&^%$#@]).*$/;
export const sequenceOfChars =
  /(?:123)|(?:qwe)|(?:asd)|(?:zxc)|(?:abc)|(?:qaz)|(?:qazwsx)/;
