import { Checkbox } from '@mui/material';
import { OptionType } from '../Dropdown.types';

type CheckboxVariantOptionProps = {
  option: OptionType;
  selected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incomingProps: any;
};

const CheckboxVariantOption = ({
  option,
  incomingProps,
  selected,
}: CheckboxVariantOptionProps) => {
  return (
    <div {...incomingProps}>
      <Checkbox
        className={'h-[14px] w-[14px] p-0 m-0 mr-2 [&>span>svg]:h-[18px]'}
        checked={selected}
      />
      {option}
    </div>
  );
};

export default CheckboxVariantOption;
