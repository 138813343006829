//Folders that are okay to be used anywhere and should end up in
//main chunk: this one, old_shared, modules/app, modules/blocked-on-web
//modules/hotelHeader, modules/navigation, modules/goStay
import { ReactComponent as Retry } from './retry.svg';
import { ReactComponent as ChevronDown } from './chevron_down.svg';
import { ReactComponent as ChevronDownXL } from './chevron_down_xl.svg';
import { ReactComponent as ChevronUp } from './chevron_up.svg';
import { ReactComponent as ChevronRight } from './chevron_right.svg';
import { ReactComponent as ChevronLeft } from './chevron_left.svg';
import { ReactComponent as ChevronWhiteDisabledLeft } from './chevron_disabled_left.svg';
import { ReactComponent as ChevronWhiteDisabledRight } from './chevron_disabled_right.svg';
import { ReactComponent as ChevronLeftTwins } from './chevron_left_twins.svg';
import { ReactComponent as ChevronRightTwins } from './chevron_right_twins.svg';
import { ReactComponent as ChevronDisabledLeft } from './left_disabled_chevron.svg';
import { ReactComponent as ChevronDisabledRight } from './right_disabled_chevron.svg';
import { ReactComponent as ChevronWhiteLeft } from './LeftWhiteArrow.svg';
import { ReactComponent as ChevronWhiteRight } from './RightWhiteArrow.svg';
import { ReactComponent as RightYellowArrow } from './RightYellowArrow.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as SearchNotFound } from './search_not_found.svg';
import { ReactComponent as ResultsNotFound } from './results_not_found.svg';
import { ReactComponent as NewSearch } from './new_search_icon.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as Addition } from './addition.svg';
import { ReactComponent as Substract } from './substract.svg';
import { ReactComponent as Date } from './date.svg';
import { ReactComponent as Caution } from './caution.svg';
import { ReactComponent as ErrorCaution } from './error_caution.svg';
import { ReactComponent as Bulb } from './bulb.svg';
import { ReactComponent as ErrorMsgIcon } from './error_msg_icon.svg';
import { ReactComponent as InfoBulb } from './idea.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as InfoDark } from './info-dark.svg';
import { ReactComponent as InfoOutline } from './info_outline.svg';
import { ReactComponent as FilterIcon } from './filter.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as DeleteUnfilled } from './delete_unfilled.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Unlock } from './unlock.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as CheckedUnfilled } from './checked_unfilled.svg';
import { ReactComponent as EmptyRadio } from './emptyRadio.svg';
import { ReactComponent as CheckedRadio } from './checkedRadio.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as SuccessInvoice } from './successInvoice.svg';
import { ReactComponent as ErrorInvoice } from './errorInvoice.svg';
import { default as demandHighInventory } from './ic-demand-high.svg';
import { ReactComponent as PartnerIcon } from './synxis.svg';
import { ReactComponent as PopupIcon } from './popup.svg';
import { ReactComponent as HourlyLogo } from './hourly-logo.svg';
import { ReactComponent as ChipCancel } from './cancelChip.svg';
import { ReactComponent as RedirectIcon } from './redirectIcon.svg';
import { ReactComponent as LinkAttachIcon } from './link-attach-icon.svg';
import { ReactComponent as ContactUsIcon } from './contact_us.svg';
import { ReactComponent as HostSupportIcon } from './host_support.svg';
import { ReactComponent as EmailsIcon } from './emails.svg';
import { ReactComponent as ContactIcon } from './Contact.svg';
import { ReactComponent as CloseSmall } from './close_small.svg';
import { ReactComponent as LInfoIcon } from './latest-info-icon.svg';
import { ReactComponent as LInfoClose } from './latest-info-close.svg';
import { ReactComponent as LErrorIcon } from './latest-error-icon.svg';
import { ReactComponent as LErrorClose } from './latest-error-close.svg';
import { ReactComponent as LSuccessIcon } from './latest-success-icon.svg';
import { ReactComponent as LSuccessClose } from './latest-success-close.svg';
import { ReactComponent as LWarningClose } from './latest-warning-close.svg';
import { ReactComponent as LWarningIcon } from './latest-warning-icon.svg';
import { ReactComponent as LNotificationIcon } from './latest-notification-icon.svg';
import { ReactComponent as LSAErrorIcon } from './latest-sa-info-icon.svg';
import { ReactComponent as LSAInfoIcon } from './latest-sa-error-icon.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as EditOutlined } from './edit-outlined.svg';
import { ReactComponent as AlertUnfilled } from './alert-unfilled.svg';
import { ReactComponent as ChevronDownUnfilled } from './chevron-down-unfilled.svg';
import { ReactComponent as UndrawIcon } from './undraw.svg';
import { ReactComponent as FilterSelectedIcon } from './filter_selected.svg';
import { ReactComponent as LegendIcon } from './rectangle-legend.svg';
import { ReactComponent as LocationPin } from './location-pin.svg';
import { ReactComponent as CloseUnfilled } from './close-unfilled.svg';
import { ReactComponent as TickUnfilled } from './tick-unfilled.svg';
import { ReactComponent as LockGrey } from './lock-grey.svg';
import { ReactComponent as CloseBlack } from './close-black.svg';
import { ReactComponent as ChevRightWhite } from './chev-right-white.svg';
import { ReactComponent as ChevLeftWhite } from './chev-left-white.svg';
import { ReactComponent as CalendarLight } from './calendar-light.svg';

import { ReactComponent as LinkUnlink } from './link-unlink.svg';
import { ReactComponent as DragIndicator } from './drag-indicator-icon.svg';
import { ReactComponent as GreyTick } from './grey_tick.svg';
import { ReactComponent as BdParity } from './bd-parity.svg';
import { ReactComponent as BdInventory } from './bd-inventory.svg';
import { ReactComponent as TableSortAscIcon } from './table-sort-asc.svg';
import { ReactComponent as TableSortDescIcon } from './table-sort-desc.svg';
import { ReactComponent as SortIcon } from './sort-icon.svg';
import { ReactComponent as FilterNewIcon } from './filter-icon.svg';
import { ReactComponent as FilterNewSelectIcon } from './filter-selected-icon.svg';
import { ReactComponent as CrawlLoad } from './recrawl-icon.svg';
import { ReactComponent as MMTBlackNewIcon } from './mmt-black-new.svg';
import { ReactComponent as CalendarBlueIcon } from './calendar_blue_icon.svg';
import { ReactComponent as LinkDelink } from './link_delink.svg';
import { ReactComponent as SearchIconSuffix } from './suffix_icon_search.svg';
import InfoSvg from './info_blue.svg';
import ingoLogo from './ingoLogo.png';
import makeMyTripIcon from './mmtBig.svg';
import goibiboIcon from './goBig.svg';
import CrossSVG from './cross.svg';
import Cross78SVG from './cross-78.svg';
import InfoFilledSvg, {
  ReactComponent as InfoFilledSvgComp,
} from './info-filled.svg';
import unlinkRateplan from './unlink-rp.svg';
import CalendarIcon from './Calendar.svg';

import CalendarWhiteIcon from './calendar_white_icon.svg';
import PropertyMarker from './propertyMarker.svg';
import MMTBlackIcon from './logo-mmt-black.svg';
import SLLogo from './sponsored-listing-logo.svg';
import GroupBookingSvg from './group-booking.svg';
import PropertyMarkerNew from './property-marker-new.svg';
import InfoBlueFilled from './info-blue-filled.svg';
import NavHelpDefault from './nav-help-default.svg';
import WarningAlert, {
  ReactComponent as WarningAlertComp,
} from './warning-alert.svg';
import DecreaseArrow from './decrease_arrow.svg';
import IncreaseArrow from './increase_arrow.svg';
import InfoQuestionIcon from './info_question_outline.svg';
import { ReactComponent as BlueArrowLeft } from './arrowLeft.svg';
import NoDataCard from './no-data-card.png';
import LockFilled from './lock-filled.svg';
import FileDownload from './file-download.svg';

//RTB persuasion
import GreenTickIcon, {
  ReactComponent as GreenTickIconComp,
} from './green_tick.svg';
import RTBIcon from './RTBIcon.svg';
import Step1RTBOnboarding from './step1_RTB_onboarding.svg';
import Step2RTBOnboarding from './step2_RTB_onboarding.svg';
import Step3RTBOnboarding from './step3_RTB_onboarding.svg';
import YouTube from './youtube.svg';
import MMTblack from './mmt-black.svg';
import MMTgulf from './mmt-gulf.svg';
import MMTholiday from './mmt-holiday.svg';
import MMTMember from './mmt-member.svg';
import MMTIndiaPos from './mmt-IndiaPos.svg';
import MMTmobile from './mmt-mobile.svg';
import Mybiz from './mmt-mybiz.svg';
import Mypartner from './mmt-mypartner.svg';
import MMTselect from './mmt-select.svg';
import { ReactComponent as Plus } from './plus.svg';
import RightChevronThin from './right-thin.png';
import { ReactComponent as ActiveMixCheckbox } from './active-mix-checkbox.svg';

const RTBIcons = {
  RTBIcon,
  GreenTickIcon,
  Step1RTBOnboarding,
  Step2RTBOnboarding,
  Step3RTBOnboarding,
  GreenTickIconComp,
};
export {
  Retry,
  ChevronDown,
  ChevronDownXL,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  ChevronLeftTwins,
  ChevronRightTwins,
  ChevronDisabledLeft,
  ChevronDisabledRight,
  ChevronWhiteLeft,
  ChevronWhiteRight,
  RightYellowArrow,
  Search,
  NewSearch,
  Close,
  Error,
  Addition,
  Substract,
  Date,
  Caution,
  ErrorCaution,
  ingoLogo,
  Bulb,
  InfoBulb,
  Info,
  InfoDark,
  InfoSvg,
  InfoOutline,
  FilterIcon,
  ArrowLeft,
  Edit,
  Add,
  Delete,
  DeleteUnfilled,
  Lock,
  Unlock,
  Tick,
  Upload,
  SuccessInvoice,
  ErrorInvoice,
  Checked,
  CheckedUnfilled,
  EmptyRadio,
  CheckedRadio,
  demandHighInventory,
  makeMyTripIcon,
  goibiboIcon,
  PartnerIcon,
  PopupIcon,
  CrossSVG,
  Cross78SVG,
  InfoFilledSvg,
  unlinkRateplan,
  CalendarIcon,
  CalendarBlueIcon,
  CalendarLight,
  ChevronWhiteDisabledLeft,
  ChevronWhiteDisabledRight,
  CalendarWhiteIcon,
  PropertyMarker,
  RTBIcons,
  MMTBlackIcon,
  SLLogo,
  GroupBookingSvg,
  HourlyLogo,
  ChipCancel,
  RedirectIcon,
  LinkAttachIcon,
  InfoFilledSvgComp,
  ContactUsIcon,
  HostSupportIcon,
  EmailsIcon,
  ContactIcon,
  YouTube,
  CloseSmall,
  LInfoIcon,
  LInfoClose,
  LErrorIcon,
  LErrorClose,
  LSuccessIcon,
  LSuccessClose,
  LWarningClose,
  LWarningIcon,
  LNotificationIcon,
  LSAErrorIcon,
  LSAInfoIcon,
  Check,
  PropertyMarkerNew,
  InfoBlueFilled,
  WarningAlert,
  WarningAlertComp,
  LegendIcon,
  LocationPin,
  DecreaseArrow,
  IncreaseArrow,
  EditOutlined,
  AlertUnfilled,
  ChevronDownUnfilled,
  CloseUnfilled,
  TickUnfilled,
  NavHelpDefault,
  LinkUnlink,
  DragIndicator,
  GreyTick,
  BdParity,
  BdInventory,
  FilterSelectedIcon,
  TableSortAscIcon,
  TableSortDescIcon,
  SortIcon,
  UndrawIcon,
  InfoQuestionIcon,
  ErrorMsgIcon,
  LockGrey,
  MMTblack,
  MMTgulf,
  MMTholiday,
  MMTMember,
  MMTIndiaPos,
  MMTmobile,
  Mybiz,
  Mypartner,
  MMTselect,
  NoDataCard,
  BlueArrowLeft,
  FilterNewIcon,
  FilterNewSelectIcon,
  CrawlLoad,
  MMTBlackNewIcon,
  LockFilled,
  CloseBlack,
  ChevRightWhite,
  ChevLeftWhite,
  Plus,
  SearchNotFound,
  ResultsNotFound,
  FileDownload,
  SearchIconSuffix,
  RightChevronThin,
  LinkDelink,
  ActiveMixCheckbox,
};
