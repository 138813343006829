import { IngoLogo } from 'assets/modules/user-management';
import {
  Button,
  ControlledInput,
  FormElement,
  Typography,
} from 'components/latest-core';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PASSWORD_MIN_CHAR_LENGTH } from '../login.constant';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { AuthContext } from 'utilities/Context';
import {
  clearQueryParams,
  getParsedErrorMessageFromAPI,
  showMessage,
} from 'utilities/Utils';
import {
  pwdWithAtleastOneAlpaNumericAndSplCharRegex,
  sequenceOfChars,
} from 'utilities/allRegex';
import { verifyUserAndCreatePwd } from 'group-property/modules/user-management/UserManagementAPIClient';
import { useLocation } from 'react-router-dom';
import { ToastProps } from 'interfaces/AppContextInterface';
import NewUserPasswordResetSuccess from './NewUserPasswordResetSuccess';

export type ResetResp = {
  response?: {
    errorDetail?: {
      displayMessage: string;
    };
    success?: boolean;
    message?: string;
  };
};

const NewUserPasswordResetHeader = ({ children }) => {
  return (
    <div className="bg-[url('assets/modules/user-management/city-bg.webp')] bg-cover bg-bottom flex justify-center bg-color-dark min-h-screen">
      <div className="w-[300px] p-[15px] bg-color-white my-[30px] h-fit rounded-md">
        <img src={IngoLogo} alt="ingo-logo" className="mb-[10px]" />
        {children}
      </div>
    </div>
  );
};

const NewUserPassworReset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isPwdResetSuccess, setIsPwdResetSuccess] = useState(false);

  const { pathname, search } = useLocation();
  const {
    langStringsData: { langStrings },
  } = useContext(AuthContext);

  const [{ username, token }] = useState(() =>
    clearQueryParams(pathname, search, ['username', 'token']),
  );

  const email = username && window.atob(username);
  const { UserManagement: STRINGS, Common: COMMON } = langStrings;

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const checkCommonValidation = (value: string) => {
    if (value?.length < PASSWORD_MIN_CHAR_LENGTH)
      return COMMON.MESSAGES.INVALID_PWD_LEN;

    if (!pwdWithAtleastOneAlpaNumericAndSplCharRegex.test(value)) {
      return COMMON.MESSAGES.PASSWORD_VALIDATION;
    }
    if (sequenceOfChars.test(value)) {
      return COMMON.MESSAGES.COMMON_SEQ_LETTERS;
    }

    return null;
  };

  const validatePassowrd = () => {
    const password = getValues('password');
    const err = checkCommonValidation(password);

    if (err) {
      return err;
    }
    return null;
  };

  const validateConfirmPassowrd = () => {
    const [password, confirmPassword] = getValues([
      'password',
      'confirmPassword',
    ]);
    const err = checkCommonValidation(password);

    if (err) {
      return err;
    }

    if (password !== confirmPassword) {
      return COMMON.MESSAGES.PWD_DOESNT_MATCH;
    }
    return null;
  };

  const handleFormSubmit = data => {
    const { password } = data;

    const payload = {
      userName: username,
      token,
      password,
    };

    void verifyUserAndCreatePwd(payload).then((resp: ResetResp) => {
      const { response } = resp;
      const errObj: ToastProps = {
        show: true,
        type: 'success',
        message: '',
      };

      if (response?.errorDetail) {
        errObj.message = getParsedErrorMessageFromAPI(response);
        errObj.type = 'error';
      } else if (response?.success) {
        errObj.message = response?.message;
        setIsPwdResetSuccess(true);
      }
      if (errObj.message) {
        showMessage(errObj);
      }
    });
  };

  if (isPwdResetSuccess) {
    return <NewUserPasswordResetSuccess />;
  }

  return (
    <NewUserPasswordResetHeader>
      <div>
        <Typography
          variant="subtitle1"
          className="my-[10px] !text-[#090909] opacity-50"
        >
          {COMMON.LABELS.EMAIL}: {email}
        </Typography>
        <Typography
          variant="buttonText"
          className="pt-[25px] !text-[#090909] mb-[10px] !normal-case"
        >
          {STRINGS.USER_LISING.CONTINUE_ACCOUNT}
        </Typography>
        <Typography variant="subtitle1" className="!text-[#090909] opacity-50	">
          {STRINGS.USER_LISING.EMAIL_VERIFIED}
        </Typography>

        <div className="mt-[30px]">
          <FormElement
            title={
              <span className="font-bold text-[#333]">
                {COMMON.PLACEHOLDER.NEW_PASSWORD}
              </span>
            }
            errorMsg={errors.password?.message}
            className="mb-[15px]"
          >
            <ControlledInput
              control={control}
              isRequired
              name="password"
              placeholder="Enter Password"
              customValidator={validatePassowrd}
              type={showPassword ? 'password' : 'text'}
              rightElement={
                <div
                  className=" p-[10px] relative left-[11px] top-[3px] hover:cursor-pointer hover:bg-color-dark border-solid  border-0 border-l-[1px] border-[#0000003b]"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <RemoveRedEyeOutlinedIcon />
                </div>
              }
            />
          </FormElement>

          <FormElement
            title={
              <span className="font-bold text-[#333]">
                {COMMON.PLACEHOLDER.CONFIRM_PASSWORD}
              </span>
            }
            errorMsg={errors.confirmPassword?.message}
            className="mb-[15px]"
          >
            <ControlledInput
              control={control}
              isRequired
              name="confirmPassword"
              placeholder="Confirm Password"
              customValidator={validateConfirmPassowrd}
              type="password"
            />
          </FormElement>
        </div>
        <div className="flex justify-end">
          <Button
            className="mt-[10px]"
            onClick={handleSubmit(handleFormSubmit)}
          >
            {COMMON.BUTTONS.CONTINUE_TEXT}
          </Button>
        </div>
      </div>
    </NewUserPasswordResetHeader>
  );
};

export default NewUserPassworReset;
export { NewUserPasswordResetHeader };
