import * as Dashboard from '../../assets/modules/dashboard';
import { Typography, Divider } from 'components/latest-core';
import { useContext, useEffect, useState } from 'react';
import { getPriorityPersuasions } from './OpportunityCenterGraphqlClient';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { navigateTo } from 'utilities/Utils';
import { routes } from 'app/routes';
import { useLangStrings } from 'utilities/CustomHooks';
import { HotelContext } from 'utilities/Context';
import {
  opportunityCategoryDataMapping,
  routeMapping,
} from './OpportunityCenter.constants';
import { getDateDifference } from 'utilities/DateUtils';
import PersuasionActionButton from './components/PersuasionActionButton';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';
import GreatWorkPopup from './components/GreatWorkPopup';
import { SOURCE_PARTNER } from '../../constants';
import OpportunityCenterShimmer from './OpportunityCenterShimmer';

import './OpportunityCentre.scss';

type Persuasions = {
  doc_count: number;
  docs: object[];
};

type OpportunityCTA = {
  text?: string;
  type: string;
  meta?: {
    redirect_link?: string;
  };
};

type OpportunityDashboard = {
  ctas: OpportunityCTA[];
  display_type: string;
  display_variant: string;
  is_active: boolean;
  message: string;
  persuasion_title: string;
};

export type PersuasionObj = {
  title: string;
  consumers: {
    opportunity_dashboard?: OpportunityDashboard;
    opportunity_listing?: {
      persuasion_title: string;
      message: string;
      ctas: OpportunityCTA[];
    };
  };
  type: string;
  sub_type: string;
  category: string;
  priority?: number;
  expiry_date: string;
  id: string;
  data?: {
    promo_code?: string;
    identifier?: string;
    offer_type?: string[];
  };
};

export type PersuasionCompProps = {
  persuasion: PersuasionObj;
  refreshData: () => void;
  hotelcode?: string;
  rank: number;
};

export const HighPriority = () => {
  return (
    <div className="high-priority">
      <NotificationsNoneOutlinedIcon className="notification" />
      HIGH PRIORITY
      <div className="shimmer" />
      <div className="shimmer1" />
    </div>
  );
};

export const GetPersuasionTag = persuasion => {
  const [STRINGS] = useLangStrings<'OpportunityCenter'>('OpportunityCenter');

  if (persuasion.priority >= 0 && persuasion.priority <= 20) {
    return <HighPriority />;
  }
  if (persuasion.priority > 20 && !persuasion?.data?.oc_hide_expiry_date) {
    const days = getDateDifference(
      new Date(persuasion?.expiry_date),
      new Date(),
    );
    if (days >= 0) {
      return (
        <div className="expire-warning">
          {STRINGS.EXPIRES_IN.replace('_DAYS_', days.toString())}
        </div>
      );
    }
  }

  return null;
};

const Persuasion = (props: PersuasionCompProps) => {
  const { persuasion, refreshData, rank } = props;
  const data = persuasion.consumers?.opportunity_dashboard;

  const persuasionTitle = data?.persuasion_title;

  const {
    currentHotel: { source_partner: sourcePartner, hotelcode, showListings },
    showNewContentScore,
  } = useContext(HotelContext);
  const isSynxisOrDerby =
    sourcePartner === SOURCE_PARTNER.SYNXIS ||
    sourcePartner === SOURCE_PARTNER.DERBY;

  const route = routeMapping({
    isDerbyOrSynxis: isSynxisOrDerby,
    hotelcode,
    showListings,
    showNewContentScore,
  })[persuasion.type]?.[persuasion.sub_type];
  if (!persuasionTitle) return null;

  const tag = GetPersuasionTag(persuasion);

  return (
    <div
      className="persuasion-card"
      key={persuasion.id}
      data-test-id={`oc-persuasions-card-${persuasion.id}`}
    >
      {tag}
      <div className="oc-content">
        <img
          src={opportunityCategoryDataMapping[persuasion?.category]?.img}
          alt={persuasion.category}
          width="20px"
        />
        <Typography variant="body1" themeColor="gray.main">
          {persuasionTitle}
        </Typography>
      </div>
      <div className="flex justify-end items-center gap-3">
        {data?.ctas?.map(btnItem => (
          <PersuasionActionButton
            persuasion={persuasion}
            route={route}
            btn={{
              text: btnItem.type,
              type: btnItem.type,
              redirectionLink: btnItem?.meta?.redirect_link,
            }}
            refreshData={refreshData}
            dashboard
            rank={rank}
          />
        ))}
      </div>
    </div>
  );
};

const OpportunityCentreDashboard = () => {
  const {
    currentHotel: { hotelcode },
  } = useContext(HotelContext);
  const [STRINGS] = useLangStrings<'OpportunityCenter'>('OpportunityCenter');
  const [persuasions, setPersuasions] = useState<Persuasions | null>(null);
  const [successModal, showSuccessModal] = useState(false);
  const [showShimmer, setShowShimmer] = useState(true);

  const getData = () => {
    getPriorityPersuasions(hotelcode, 'dashboard', null, {
      showLoader: false,
    })
      .then(resp => {
        let data = null;

        const response =
          resp?.data?.rawEndpoint?.analyticsV1Persuasions?.modelType1;
        if (response?.status === 'success' && response?.data) {
          data = response.data;

          const gtmLabel = (data?.docs || []).reduce(
            (result, persuasion, index) => {
              result +=
                (index === 0 ? '' : '|') +
                (persuasion.title ? `${persuasion.title}_${index + 1}` : '');
              return result;
            },
            '',
          );

          pushToGTM(
            GTM_CATEGORIES.DASHBOARD,
            'action_center',
            `load_${data.doc_count}|${gtmLabel}`,
          );
        }
        setPersuasions(data);
      })
      .catch(() => setPersuasions(null))
      .finally(() => setShowShimmer(false));
  };

  const onViewAllClick = () => {
    pushToGTM(
      GTM_CATEGORIES.DASHBOARD,
      'action_center',
      'click_view_all_tasks',
    );
    navigateTo(routes.opportunityCenter.actionDetail);
  };

  const refreshData = () => {
    getData();
    showSuccessModal(true);
  };

  useEffect(getData, [hotelcode]);

  if (showShimmer) return <OpportunityCenterShimmer />;
  if (!persuasions || !(persuasions?.docs || []).length) return null;

  return (
    <div className="oportunity-centre-main" data-test-id="oc_main">
      <div className="opportunity-card">
        <div className="header">
          <div className="subheader">
            <div className="d-flex align-center">
              <img src={Dashboard.StarIcon} alt="star-icon" />
              <Typography
                variant="body1"
                fb
                themeColor="backgrounds.warning"
                className="action-centre"
              >
                {STRINGS.ACTION_CENTER}
              </Typography>
              <span className="sr-label sr-label-success marginL10">
                {STRINGS.NEW}
              </span>
            </div>
            <Typography
              variant="body1"
              themeColor="common.white"
              className="line-height-18"
            >
              {STRINGS.ACTION_TITLE}
            </Typography>
          </div>
        </div>
        <div className="persuasions" data-test-id="oc-persuasions">
          {persuasions.docs.map((persuasion: PersuasionObj, index) => (
            <Persuasion
              rank={index + 1}
              persuasion={persuasion}
              refreshData={refreshData}
            />
          ))}
        </div>
        <Divider className="separator" />
        <div className="all-tasks">
          <Typography
            variant="subtitle1"
            themeColor="common.white"
            fb
            onClick={onViewAllClick}
            data-test-id="oc_view_all_tasks"
          >
            {STRINGS.ALL_TASKS} ({persuasions.doc_count})
          </Typography>
        </div>

        {successModal && (
          <GreatWorkPopup
            STRINGS={STRINGS}
            onCloseClick={() => showSuccessModal(false)}
            dashboard
          />
        )}
      </div>
    </div>
  );
};

export default OpportunityCentreDashboard;
