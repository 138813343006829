import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  autocompleteRoot: {
    width: '100%',
  },
  notFaded: {
    '& .Mui-disabled': {
      color: `${theme.palette.text.primary}`, // Keep the text color
      '-webkit-text-fill-color': `${theme.palette.text.primary}`, // For some browsers
      opacity: 1, // Prevent fading
    },
  },
  root: (props: {
    isInlineBorder: boolean;
    zIndexValue: string;
    noBorder: boolean;
    isInline: boolean;
    isCheckboxVariant: boolean;
    isChipVariant: boolean;
    disabled: boolean;
  }) => ({
    width: '100%',
    height:
      (!props.isInline && props.isCheckboxVariant) || props.isChipVariant
        ? 'full'
        : props.isInlineBorder
          ? 'auto'
          : '40px',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    backgroundColor: props.disabled
      ? `${theme.palette.backgrounds.light}`
      : `${theme.palette.common.white}`,
    '& > div:first-child': {
      minHeight: '40px !important',
      padding:
        props.isCheckboxVariant || props.isChipVariant
          ? '9px 34px 9px 8px !important'
          : '1px',
      backgroundColor: 'inherit',
      gap: props.isCheckboxVariant || props.isChipVariant ? '4px' : '',
      '& input': {
        color: `${theme.palette.text.primary} !important`,
        minWidth: (props.isCheckboxVariant || props.isChipVariant) && '0',
        height: (props.isCheckboxVariant || props.isChipVariant) && '0',
        '&:focus': {
          minWidth: (props.isCheckboxVariant || props.isChipVariant) && '100%',
          display: (props.isCheckboxVariant || props.isChipVariant) && 'block',
          height: (props.isCheckboxVariant || props.isChipVariant) && '100%',
        },
        padding: props.noBorder
          ? '4px 0px 4px !important'
          : props.isInline
            ? '0px !important'
            : props.isCheckboxVariant || props.isChipVariant
              ? '0 35px 0 0 !important'
              : props.isInlineBorder
                ? '4px 12px 2px !important'
                : '9px 12px 8px !important',
        fontSize: '16px',
      },
      '& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26) !important',
        fontSize: props.isInlineBorder ? '14px' : '16px',
      },
      '& .Mui-disabled:disabled': {
        color: 'rgba(0, 0, 0, 0.26) !important',
        cursor: 'not-allowed',
      },
    },
  }),
  noBorder: {
    '& fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
      border: 'none',
    },
    '& .Mui-disabled': {
      color: `${theme.palette.primary.main}`, // Keep the text color
      '-webkit-text-fill-color': `${theme.palette.primary.main}`, // For some browsers
      opacity: 1, // Prevent fading
    },
    '& input': {
      color: `${theme.palette.primary.main}`,
      fontWeight: '500',
    },
  },
  leftBorderRoundedNone: {
    '& [class*="MuiOutlinedInput-root"]': {
      borderTopLeftRadius: '0px !important',
      borderBottomLeftRadius: '0px !important',
    },
  },
  isInline: {
    '& input': {
      color: `${theme.palette.text.primary}`,
      fontWeight: '400',
      '&::placeholder': {
        color: `${theme.palette.text.secondary}`,
        opacity: 1,
      },
    },
  },
  popper: (props: { zIndexValue }) => ({
    zIndex: props.zIndexValue,
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchable: (props: any) => ({
    paddingLeft:
      props.isCheckboxVariant || props.isChipVariant
        ? '4px !important'
        : '15px !important',
    width: 'auto',
  }),
  paper: {
    backgroundColor: `${theme.palette.common.white}`,
    padding: '0px 4px 4px 4px',
    boxShadow: '0px 1px 5px rgba(1, 16, 34, 0.1)',
    borderRadius: '4px',
    border: '1px solid #D2D1D1',
    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: `${theme.palette.backgrounds.primary}`,
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: `${theme.palette.backgrounds.dark}`,
      color: `${theme.palette.primary.main}`,
    },
    '& li[class^="MuiAutocomplete-option"]': {
      fontSize: '14px',
      fontFamily: 'Roboto',
      lineHeight: '20px',
      padding: '8px 12px',
      marginBottom: '2px',
      backgroundColor: `${theme.palette.common.white}`,
    },
    '& div[class*="MuiAutocomplete-groupLabel"]': {
      fontSize: '12px !important',
    },
    '& span[class*="MuiCheckbox-root"]': () => ({
      backgroundColor: `${theme.palette.common.white}`,
    }),
    '& li[data-focus="true"]': {
      backgroundColor: `${theme.palette.backgrounds.primary}`,
      '& *': {
        color: `${theme.palette.primary.main}`,
      },
      '& svg': {
        backgroundColor: 'transparent',
      },
      '& span[class*="MuiCheckbox-root"]': {
        backgroundColor: (props: { isMultiSelectVariant: boolean }) =>
          props.isMultiSelectVariant
            ? `${theme.palette.backgrounds.primary}`
            : `${theme.palette.backgrounds.light}`,
        '&.Mui-checked': {
          backgroundColor: (props: { isMultiSelectVariant: boolean }) =>
            props.isMultiSelectVariant
              ? `${theme.palette.backgrounds.primary}`
              : `${theme.palette.backgrounds.primary}`,
        },
      },
    },
    '& li[aria-selected="true"]': {
      fontWeight: 500,
      color: `${theme.palette.primary.main}`,
      '& *': {
        color: `${theme.palette.primary.main}`,
      },
    },
  },
  chip: {
    borderRadius: '60px',
    border: `1px solid ${theme.palette.divider}`,
    background: '#EFF1FF',
    maxWidth: '180px',
    height: '34px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: (props: { isMultiSelectVariant: boolean }) =>
      props.isMultiSelectVariant ? '0px' : '8px',
    marginBottom: (props: { isMultiSelectVariant: boolean }) =>
      props.isMultiSelectVariant ? '0px' : '12px',
    marginTop: (props: { isMultiSelectVariant: boolean }) =>
      props.isMultiSelectVariant ? '4px' : '0px',
    '& span[class^="MuiChip-label"]': {
      fontSize: '14px !important',
      lineHeight: '22px',
      maxWidth: (props: { isMultiSelectVariant: boolean }) =>
        props.isMultiSelectVariant ? '106px' : '126px',
      padding: (props: { isMultiSelectVariant: boolean }) =>
        props.isMultiSelectVariant && '0px 8px',
    },
    '& svg[class^="MuiChip-deleteIcon-"]': {
      marginRight: (props: { isMultiSelectVariant: boolean }) =>
        props.isMultiSelectVariant ? '8px' : '12px',
    },
    '&:focus': {
      background: `${theme.palette.common.white}`,
    },
    borderColor: '#3543BF',
  },
  small: {
    width: '182px',
  },
  endAdornment: {
    top: 'auto',
  },
  checkbox: {
    marginRight: '12.5px',
    backgroundColor: `${theme.palette.backgrounds.light}`,
    color: `${theme.palette.primary.main}`,
    padding: (props: { isMultiSelectVariant: boolean }) =>
      props.isMultiSelectVariant && '0px',
  },
  buttonVariant: {
    color: `${theme.palette.backgrounds.white} !important`,
    '& .MuiInputBase-root': {
      width: '100% !important',
      background: '#3543BF !important',
      '& .MuiInputBase-input': {
        color: `${theme.palette.backgrounds.white} !important`,
        '-webkit-text-fill-color': `${theme.palette.backgrounds.white} !important`,
        fontWeight: '600 !important',
        fontSize: '16px !important',
        borderRight: `1px solid ${theme.palette.backgrounds.white}`,
        marginRight: '8px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
}));

export default useStyles;
