import { MinimumStayDuration } from 'modules/promotions-and-coupons/Promotions/components/revamp-components';
import { classNames } from 'utilities/Utils';
import { generateUpfrontValueOptions } from '../utils';
import { useFormContext } from 'react-hook-form';
import { Typography } from 'components/latest-core';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { MinLosNode } from '../data/types/Schema';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';

type MinStayDurationProps = {
  minLosNode: MinLosNode;
  customValueOptions: Map<string, string>;
};

export const MinStayDuration = (props: MinStayDurationProps, ref) => {
  const { minLosNode, customValueOptions } = props;
  const myRef = useRef(null);

  const {
    formState: { errors },
    watch,
  } = useFormContext();
  const error = errors.minStayDuration || errors.customStayDuration;
  const minValue = watch('minStayDuration');

  useEffect(() => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'min_stay_clicked',
        type: 'click',
        componentName: minValue,
      },
      pageName: 'los-package',
    });
  }, [minValue]);

  useImperativeHandle(ref, () => ({
    scrollIntoView: () =>
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      }),
  }));

  return (
    <div ref={myRef}>
      <div
        className={classNames(
          'border-[1px] border-solid rounded-lg p-4',
          error ? 'border-[#eb2026]' : 'border-divider',
        )}
      >
        <MinimumStayDuration
          CUSTOM_OPTIONS={customValueOptions}
          recommendedId={minLosNode.recommendedValueId}
          minStayDurationOptions={generateUpfrontValueOptions(minLosNode)}
          isLosPackageFlow
        />
      </div>
      {error && (
        <Typography variant="body1" className="!text-[#eb2026] pt-1">
          {error.message.toString()}
        </Typography>
      )}
    </div>
  );
};

export default forwardRef(MinStayDuration);
