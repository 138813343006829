import { CalendarBase } from './CalendarBase';
import { useEffect, useMemo, useRef } from 'react';
import { getFormattedDate } from 'utilities/DateUtils';
import { CalendarIcon } from 'assets/common';
import { Input, Typography } from 'components/latest-core';
import { classNames } from 'utilities/Utils';
import { CalendarInputProps, InputProps } from '../Calendar.types';

const DefaultInput = ({ calendarInputProps, anchorEl }: InputProps) => {
  const {
    calendarProps,
    popoverState,
    disabled = false,
    error = false,
    leftElement,
    rightElement = <img src={CalendarIcon} alt="calendar-icon" />,
    placeholder,
    inputDateFormat = 'DD MMM YYYY, ddd',
    inputClasses = '',
  } = calendarInputProps;
  const { showCalendar } = popoverState;
  const calendarInputText = useMemo(() => {
    const { variant, selectedDays } = calendarProps;
    switch (variant) {
      case 'single':
        return selectedDays
          ? getFormattedDate(selectedDays, inputDateFormat)
          : '';
      case 'multiple':
        return selectedDays.length > 0
          ? `${selectedDays.length} day${
            selectedDays.length > 1 ? 's' : ''
          } selected`
          : '';
      case 'range': {
        const { from, to } = selectedDays;
        return `${from ? `${getFormattedDate(from, inputDateFormat)} - ` : ''}${
          to ? getFormattedDate(to, inputDateFormat) : ''
        }`;
      }
    }
  }, [calendarProps.selectedDays]);
  const defaultPlaceholder =
    calendarProps.variant === 'single'
      ? 'Enter Date'
      : calendarProps.variant === 'multiple'
        ? 'Select Dates'
        : 'Select a range';
  return (
    <Input
      className={classNames(
        inputClasses,
        '[&>input]:cursor-pointer cursor-pointer',
      )}
      onClick={_ => showCalendar()}
      value={calendarInputText}
      ref={anchorEl}
      disabled={disabled}
      error={error}
      leftElement={leftElement}
      rightElement={rightElement}
      placeholder={placeholder ?? defaultPlaceholder}
      readOnly
    />
  );
};

const CustomTwoInputs = ({ calendarInputProps, anchorEl }) => {
  const {
    calendarProps,
    popoverState,
    disabled = false,
    error = false,
    rightElement = <img src={CalendarIcon} alt="calendar-icon" />,
    inputDateFormat = 'DD MMM YYYY, ddd',
    startDateText = 'Start Date',
    endDateText = 'End Date',
    hasNoEndDate = false,
    inputClasses = '',
  } = calendarInputProps;
  const { showCalendar } = popoverState;
  const {
    selectedDays: { from, to },
  } = calendarProps;
  const fromRef = useRef();
  const toRef = useRef();
  const handleInputClick = ref => {
    anchorEl.current = ref.current;
    showCalendar();
  };
  const fromValue = from ? getFormattedDate(from, inputDateFormat) : '';
  const toValue = to ? getFormattedDate(to, inputDateFormat) : '';

  return (
    <div className="flex gap-2 w-full">
      <div className="flex flex-col gap-1 flex-1">
        <Typography variant="body1" fb>
          {startDateText}
        </Typography>
        <Input
          className={classNames(
            inputClasses,
            '[&>input]:cursor-pointer cursor-pointer',
          )}
          onClick={_ => handleInputClick(fromRef)}
          value={fromValue}
          ref={fromRef}
          disabled={disabled}
          error={error}
          rightElement={rightElement}
          placeholder="Start Date"
          readOnly={true}
        />
      </div>
      {!hasNoEndDate && (
        <div className="flex flex-col gap-1 flex-1">
          <Typography variant="body1" fb>
            {endDateText}
          </Typography>
          <Input
            className={classNames(
              inputClasses,
              '[&>input]:cursor-pointer cursor-pointer',
            )}
            onClick={_ => handleInputClick(toRef)}
            value={toValue}
            ref={toRef}
            disabled={disabled}
            error={error}
            rightElement={rightElement}
            placeholder="End Date"
            readOnly={true}
          />
        </div>
      )}
    </div>
  );
};

export const CalendarInput = (props: CalendarInputProps) => {
  const {
    calendarProps,
    popoverState,
    hasTwoInputs = false,
    customInput: CustomInput = hasTwoInputs && calendarProps.variant === 'range'
      ? CustomTwoInputs
      : DefaultInput,
    ...rest
  } = props;
  const { isCalendarOpen } = popoverState;
  const anchorEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    calendarProps.setMonth(calendarProps.initialMonth);
  }, [isCalendarOpen]);

  return (
    <>
      <CustomInput calendarInputProps={props} anchorEl={anchorEl} />
      <CalendarBase
        calendarProps={calendarProps}
        popoverState={popoverState}
        anchorEl={anchorEl}
        {...rest}
      />
    </>
  );
};
