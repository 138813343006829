import React, { forwardRef } from 'react';
import { RadioGroup, Radio as MuiRadio, FormControlLabel } from '@mui/material';
import { classNames, getDataPropsFromRest } from 'utilities/Utils';
import type {
  CustomRadioIconPropsType,
  OptionProps,
  RadioVariantsPropsType,
} from '../Radio.types';
import { getStringValue, resolveClasses } from '../Radio.utils';
import SingleRadio from '../components/SingleRadio';

function RadioBase(
  props: RadioVariantsPropsType,
  ref?: React.Ref<HTMLElement>,
) {
  const {
    name = '',
    inline = false,
    disabled = false,
    enableRipple = false,
    value,
    options,
    onChange,
    selectedOptionSubComp,
    customRadioIcon = () => {},
    customClasses = null,
    classes,
    ...rest
  } = props;

  const { radioRoot, formRoot, radioGroupRoot } = classes;

  const {
    groupRoot: groupRootClass = '',
    formRoot: formRootClass = '',
    radioRoot: radioRootClass = '',
  } = customClasses || {};

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    onChange(event.target.value, event.target.name);
  };

  const dataTestIdProps = getDataPropsFromRest(rest);

  const extraRadioProps = (
    option: OptionProps,
    isDisabled: boolean,
    isSelected: boolean,
  ): CustomRadioIconPropsType => {
    const IconComp = () => {
      return (
        <SingleRadio
          option={option}
          customClasses={customClasses}
          classes={classes}
          isDisabled={isDisabled}
          isSelected={isSelected}
        />
      );
    };
    return {
      icon: <IconComp />,
      checkedIcon: <IconComp />,
    };
  };

  return (
    <RadioGroup
      ref={ref}
      name={name}
      row={inline}
      value={getStringValue(value)}
      onChange={handleChange}
      className={classNames(radioGroupRoot, groupRootClass)}
      {...dataTestIdProps}
    >
      {options.map((option, index) => {
        const isSelected =
          getStringValue(value) === getStringValue(option.value);
        const isDisabled = disabled || option.disabled;
        const customRadioProps =
          customRadioIcon(option, isDisabled) ||
          extraRadioProps(option, isDisabled, isSelected);
        return (
          <React.Fragment key={index}>
            <FormControlLabel
              label={null}
              value={getStringValue(option.value)}
              className={classNames(
                formRoot,
                resolveClasses(formRootClass, isSelected),
              )}
              control={
                <MuiRadio
                  disabled={isDisabled}
                  disableRipple={!enableRipple}
                  className={classNames(
                    radioRoot,
                    resolveClasses(radioRootClass, isSelected),
                  )}
                  {...customRadioProps}
                />
              }
            />
            {isSelected && selectedOptionSubComp
              ? selectedOptionSubComp(value)
              : null}
          </React.Fragment>
        );
      })}
    </RadioGroup>
  );
}

export default forwardRef(RadioBase);
