import { isNullOrUndefined } from 'utilities/Utils';
import type { OptionValueType } from './Radio.types';

export const getStringValue = (value: OptionValueType) => {
  if (isNullOrUndefined(value)) {
    return value;
  }
  return value.toString();
};

export const getSelectedClassName = (
  isSelected: boolean,
  className: string,
  selectedClassName: string,
) => {
  return isSelected ? `${className} ${selectedClassName}` : className;
};

export function resolveClasses<T>(
  className: T | ((isSelected: boolean) => T),
  isSelected: boolean,
): T {
  return className instanceof Function ? className(isSelected) : className;
}
