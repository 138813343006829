import React, { useState, useEffect, useContext } from 'react';
import { Button } from '../../../components/new-core';
import '../style/InstayGuestIssuesSection.scss';
import InstayIssue from './InstayIssue';
import {
  getInstayIssues,
  updateInstayIssues,
} from '../utils/DashboardAPIClient';
import { navigateTo } from 'utilities/Utils';
import { useLangStrings } from '../../../utilities/CustomHooks';
import { onlyDateComparison } from '../../../utilities/DateUtils';
import { AppContext } from '../../../utilities/Context';
import { routes } from '../../../app/routes';
import { Dashboard } from '../../../utilities/TestIds';

import { legacyPushToGTM } from 'utilities/gtm';

export default function InstayGuestIssuesSection(props) {
  const { hotel, gtmLabel, onSectionLoadUpdate } = props;
  const [issues, setIssues] = useState([]);
  const [issuesCount, setIssuesCount] = useState();

  const appState = useContext(AppContext);
  const isStaff = appState.isStaff;
  const [STRINGS] = useLangStrings('InstayIssues');

  const getFilteredIssues = allIssues => {
    const filteredIssues = [];
    const today = new Date();
    today.setDate(today.getDate() + 2);
    allIssues.forEach((issue, id) => {
      if (
        !issue.overDue &&
        onlyDateComparison(today, new Date(issue.checkOut)) !== 1
      ) {
        filteredIssues.push({ issue: issue, id: id });
      }
    });
    if (filteredIssues.length > 2) {
      return [filteredIssues[0], filteredIssues[1]];
    }
    return filteredIssues;
  };

  useEffect(() => {
    fetchData();
  }, [hotel.hotelId]);

  const fetchData = () => {
    getInstayIssues(hotel.hotelId, [0, 10], { showLoader: false })
      .then(res => {
        setIssuesCount(res.count);
        setIssues(getFilteredIssues(res.results));
        onSectionLoadUpdate(res.results.length > 0, gtmLabel);
      })
      .catch(() => {
        onSectionLoadUpdate(false, gtmLabel);
      });
    legacyPushToGTM({
      eventCategory: 'web_dashboard',
      eventAction: 'instay_issues',
      omnitureData: { skip: true },
      label: 'load',
    });
  };

  const goToInstayIssues = () => {
    legacyPushToGTM({
      eventCategory: 'web_instay_issues',
      eventAction: 'instay_issues_clicked',
      omnitureData: { event: 'ctaClick', name: STRINGS.VIEW_ALL_ISSUES },
    });
    navigateTo(routes.instay.base);
  };
  if (issues.length === 0) {
    return null;
  }
  return (
    <div className="sr-panel panel-default" data-test-id={Dashboard.instay}>
      <div className="sr-panel-heading instay-header">
        <span>
          {STRINGS.INSTAY_GUEST_ISSUES}{' '}
          <span className="count">({issuesCount})</span>
        </span>
        <Button
          variant="text"
          size="small"
          className="view-all"
          onClick={goToInstayIssues}
        >
          {STRINGS.VIEW_ALL_ISSUES}
        </Button>
      </div>

      <div className="sr-panel-body instay-body">
        <div className="persuasion">
          <span className="please-note">
            {`${STRINGS.DASHBOARD_TEXT[0]}: `}
          </span>
          {STRINGS.DASHBOARD_TEXT[1]}
        </div>
        {issues.map(issueObj => {
          return (
            <InstayIssue
              issue={issueObj.issue}
              updateInstayIssues={updateInstayIssues}
              hotelCode={hotel.hotelId}
              fetchData={fetchData}
              id={issueObj.id}
              isStaff={isStaff}
            />
          );
        })}
      </div>
    </div>
  );
}
