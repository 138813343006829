type Props = {
  /**
   * Drag ID.
   */
  id: string;

  /**
   * Dragged Children.
   */
  children: React.ReactNode;

  /**
   * Dragging item.
   */
  item?: object;

  /**
   * On drag start for the dragging element.
   */
  onDragStart?: (event, item) => void;

  /**
   * On drop dragging element.
   */
  onDrop?: (event, item) => void;
};

export default function Draggable({
  children,
  id,
  item = {},
  onDragStart = () => {},
  onDrop = () => {},
}: Props) {
  const handleDrag = ev => {
    onDragStart(ev, item);
  };

  const handleDrop = ev => {
    onDrop(ev, item);
  };

  return (
    <div
      draggable={true}
      id={id}
      onDragOver={ev => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      className="animation-move"
    >
      {children}
    </div>
  );
}
