export const DEFAULT_COUNTRY_ID = 103;

export const PERMISSION_TAB_NAME_TO_ID_MAPPING = {
  Bookings: 1,
  'Rates and Inventory': 2,
  Offers: 3,
  Content: 4,
  Analytics: 5,
  Messages: 6,
  Dashboard: 7,
  'Finance & Bank Details': 8,
};

export const WINDOW_SIZE = 4;

export const TABS = [
  'Bookings',
  'Rates & Inventory',
  'Offers',
  'Content',
  'Analytics',
  'Messages',
  'Dashboard',
  'Finance & Bank Details',
];

export const PERMISSION_TABS = [
  {
    key: 'booking',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING.Bookings,
    name: 'Bookings',
    className: 'rates_inventory2',
    desc: 'Access bookings and Tax Invoices.',
    disabled: false,
  },
  {
    key: 'r_n_i',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING['Rates and Inventory'],
    name: 'Rates and Inventory',
    className: 'rates_inventory2',
    desc: 'Access Rates, Inventory and Add-Ons',
    disabled: false,
  },
  {
    key: 'offer',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING.Offers,
    name: 'Offers',
    className: 'rates_inventory2',
    desc: 'Access Promotion, Performance Boosters and CUG offers.',
    disabled: false,
  },
  {
    key: 'content',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING.Content,
    name: 'Content',
    className: 'rates_inventory2',
    desc: 'Access Property content, Rating & Riviews.',
    disabled: false,
  },
  {
    key: 'analytics',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING.Analytics,
    name: 'Analytics',
    className: 'rates_inventory2',
    desc: 'Access Analytics and Reports.',
    disabled: false,
  },
  {
    key: 'message',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING.Messages,
    name: 'Messages',
    className: 'rates_inventory2',
    desc: 'Access guest chat, Instay Guest Issues & Ingo-Box.',
    disabled: false,
  },
  {
    key: 'dashboard',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING.Dashboard,
    name: 'Dashboard',
    className: 'rates_inventory2',
    desc: 'Property overview and Performance.',
    disabled: true,
  },
  {
    key: 'fnb',
    id: PERMISSION_TAB_NAME_TO_ID_MAPPING['Finance & Bank Details'],
    name: 'Finance & Bank Details',
    className: 'rates_inventory2',
    desc: 'Finance and bank details update module',
    disabled: false,
  },
];

export const viewOnlyOptions = [
  { label: '', value: 'View-Only' },
  { label: '', value: 'Editing' },
];

export const userTypeOptions = [
  { label: 'Hotel User', value: 1 },
  // { label: 'Revenue manager', value: 2 },
  // { label: 'Property manager', value: 3 },
  // { label: 'Channel manager', value: 4 }
];

export const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Others', value: 'Transgender' },
];

export const userDesignationOptions = [
  { label: 'Owner', value: 'Owner' },
  { label: 'Hotel Manager', value: 'Hotel Manager' },
  { label: 'Front Desk Manager', value: 'Front Desk Manager' },
  {
    label: 'Central Reservation Manager',
    value: 'Central Reservation Manager',
  },
  { label: 'Hotel Content Manager', value: 'Hotel Content Manager' },
  { label: 'Rates & Inventory Manager', value: 'Rates & Inventory Manager' },
  { label: 'Account & Payments Manager', value: 'Account & Payments Manager' },
];

export const roleData = [
  {
    value: 1,
    content: 'Staff',
  },
  {
    value: 0,
    content: 'Admin',
  },
];

export const getKeysForPermissionTabs = (): {
  [key: string]: {
    radioState: { name: string; defaultValue: string };
    switchState: { name: string; defaultValue: string };
  };
} => {
  const permissionKeys = PERMISSION_TABS.reduce((acc, tab) => {
    return {
      ...acc,
      [tab.name]: {
        radioState: {
          name: `step2.${tab.key}.radio`,
          defaultValue: tab.name === 'Dashboard' ? 'View-Only' : 'Editing',
        },
        switchState: {
          name: `step2.${tab.key}.switch`,
          defaultValue: true,
        },
      },
    };
  }, {});

  return permissionKeys;
};

const permissionTabKeys = getKeysForPermissionTabs();

export const ADD_USER_FORM_FIELDS = {
  ADD_USER_STEP_1: {
    EMAIL: {
      name: 'step1.email',
      label: 'Email Address',
      isRequired: true,
    },
    FIRST_NAME: {
      name: 'step1.firstName',
      label: 'First Name',
      isRequired: true,
    },
    LAST_NAME: {
      name: 'step1.lastName',
      label: 'Last Name',
      isRequired: true,
    },
    MOBILE: {
      name: 'step1.mobile',
      label: 'Mobile',
      isRequired: true,
      defaultValue: {
        phone: '',
        country: {
          label: '🇮🇳 India (+91)',
          selectedLabel: '🇮🇳 (+91)',
          value: '+91',
          id: 103,
        },
      },
    },
    USER_TYPE: {
      name: 'step1.userType',
      label: 'Select Type',
      defaultValue: userTypeOptions[0],
    },
    USER_DESIGNATION: {
      name: 'step1.userDesignation',
      label: 'Select Designation',
      defaultValue: userDesignationOptions[0],
    },
    USER_ROLE: {
      name: 'step1.userRole',
      defaultValue: roleData[1].value,
    },
  },
  ADD_USER_STEP_2: permissionTabKeys,
} as const;

export const TABS_CONFIG = isSelf => {
  return [
    {
      name: 'Profile',
      step: 1,
      componentToLoad: 'BASICS',
    },
    {
      name: 'Permissions',
      step: 2,
      componentToLoad: 'PERMISSIONS',
      selfEdit: isSelf,
    },
    {
      name: 'Assigned Properties',
      step: 3,
      componentToLoad: 'ASSIGNED_PROPERTIES',
      selfEdit: isSelf,
    },
  ];
};

export const EDITABLE_BASIC_DETAILS_FORM_FIELDS = isUserSelf => {
  return {
    PERSONAL_DETAILS: {
      FIRST_NAME: {
        name: 'personal.firstName',
        label: 'First Name',
        isRequired: true,
        maxLength: 20,
        apiKey: 'first_name',
      },
      LAST_NAME: {
        name: 'personal.lastName',
        label: 'Last Name',
        isRequired: true,
        maxLength: 20,
        apiKey: 'last_name',
      },
      EMAIL: {
        name: 'personal.email',
        label: 'Email Address',
        isRequired: true,
        apiKey: 'email',
      },
      MOBILE: {
        name: 'personal.mobile',
        label: 'Mobile',
        isRequired: isUserSelf,
        maxLength: 10,
        apiKey: 'mobile',
        type: 'mobile',
        defaultValue: {
          phone: '',
          country: {
            label: '🇮🇳 India (+91)',
            selectedLabel: '🇮🇳 (+91)',
            value: '+91',
            id: 103,
          },
        },
      },
      WHATSAPP_NUMBER: {
        name: 'personal.whatsappNumber',
        label: 'Whatsapp Number',
        type: 'mobile',
        defaultValue: {
          phone: '',
          country: {
            label: '🇮🇳 India (+91)',
            selectedLabel: '🇮🇳 (+91)',
            value: '+91',
            id: 103,
          },
        },
        apiKey: 'whatsapp_number',
      },
      WHATSAPP_OPT_IN: {
        name: 'personal.whatsappOptIn',
      },
      USER_TYPE: {
        name: 'personal.userType',
        label: 'Select Type',
        isRequired: true,
        defaultValue: userTypeOptions[0],
      },
      USER_DESIGNATION: {
        name: 'personal.userDesignation',
        label: 'Select Designation',
        defaultValue: userDesignationOptions[0],
        isRequired: !isUserSelf,
        apiKey: 'user_designation',
      },
      USER_ROLE: {
        name: 'personal.userRole',
        label: 'Role',
        isRequired: !isUserSelf,
        apiKey: 'role',
      },
    },
  } as const;
};

export const EDITABLE_HOSTING_DETAILS_FORM_FIELDS = (
  isMultiAdminHotelUser,
  isUserSelf,
) => {
  return {
    DATE_OF_BIRTH: {
      name: 'hostInfo.dob',
      label: 'Date of Birth',
      apiKey: 'dob',
      isRequired: isUserSelf,
    },
    GENDER: {
      label: 'Gender of the Host',
      isRequired: !isMultiAdminHotelUser,
      name: 'hostInfo.gender',
      apiKey: 'gender',
      type: 'gender',
    },
    PROFESSION: {
      name: 'hostInfo.profession',
      label: 'Profession',
      apiKey: 'profession',
      isRequired: !isMultiAdminHotelUser,
      type: 'profession',
    },
    HOSTING_SINCE: {
      name: 'hostInfo.hostingSince',
      isRequired: !isMultiAdminHotelUser,
      label: 'Hosting Since',
      apiKey: 'hosting_since',
    },
    LANGUAGES_SPOKEN: {
      name: 'hostInfo.languages',
      apiKey: 'languages',
      label: 'Languages Spoken',
      isRequired: !isMultiAdminHotelUser,
      type: 'languages',
      defaultValue: [],
    },
    INTERESTS: {
      name: 'hostInfo.interests',
      apiKey: 'interests',
      label: 'Interests',
      isRequired: !isMultiAdminHotelUser,
      type: 'interests',
      defaultValue: [],
    },
  } as const;
};

//can add more fields in future
export const TYPES = {
  EDIT: 'EDIT',
  PROFILE_VIEW: 'PROFILE_VIEW',
} as const;

export const ACTIONS = {
  DELETE: 'DELETE',
} as const;
