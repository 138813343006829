/* eslint-disable @typescript-eslint/no-explicit-any */ // Omniture data is dynamic and can have any key

export const GTM_CATEGORIES = {
  LOGIN: 'web_login',
  QUALITY_SCORE: 'web_quality_score',
  DASHBOARD: 'web_dashboard',
  DASHBOARD_OPPORTUNITIES: 'web_dashboard_opportunities',
  BOOKINGS: 'web_bookings',
  RATES_AND_INVENTORY: 'web_rates_inventory',
  INVENTORY_OPTIMIZATION: 'inventory_optimization_load',
  PROMOTIONS: 'web_promotions',
  PLB_AND_MARGIN_OFFERS: 'web_plb_margin_offers',
  PROPERTY: 'web_property',
  LISTING: 'host$web_property',
  LISTING_IMAGE_UPLOAD: 'host$web_property|web_property_information',
  RATINGS_AND_REVIEWS: 'web_ratings_reviews',
  ANALYTICS: 'web_analytics',
  ADDONS: 'web_add_ons',
  REPORTS: 'web_reports',
  HEADER: 'web_header',
  PAGE_LOAD: 'web_page_load',
  SPONSORED_LISTING: 'web_sponsored_listing',
  BOOKINGS_NEW_BOOKINGS: 'web_bookings_new_bookings',
  BOOKINGS_REFUND_REQUESTS: 'web_bookings_refund_requests',
  BOOKINGS_UPCOMING: 'web_bookings_upcoming',
  BOOKINGS_PAST: 'web_bookings_past',
  BOOKINGS_CANCELLED: 'web_bookings_cancelled',
  BOOKINGS_ALL_BOOKINGS: 'web_bookings_all_bookings',
  BOOKINGS_PAY_AT_HOTEL: 'web_bookings_pay_at_hotel',
  BOOKINGS_CORPORATE: 'web_bookings_corporate',
  BOOKINGS_ADJUSTMENTS: 'web_bookings_adjustments',
  BOOKINGS_ALL_TRANSACTIONS: 'web_bookings_all_transactions',
  BOOKINGS_OPPORTUNITY: 'web_booking_opportunities',
  RATINGS_AND_REVIEWS_ALL_REVIEWS: 'web_ratings_reviews_all_reviews',
  RATINGS_AND_REVIEWS_NOT_REPLIED: 'web_ratings_reviews_not_replied',
  RATINGS_AND_REVIEWS_REPLIED: 'web_ratings_reviews_replied',
  RATINGS_AND_REVIEWS_BOOKING_REVIEWS: 'web_ratings_reviews_booking_reviews',
  RATINGS_AND_REVIEWS_NON_BOOKING_REVIEWS:
    'web_ratings_reviews_non_booking_reviews',
  RATINGS_AND_REVIEWS_PHOTO_REVIEWS: 'web_ratings_reviews_photo_reviews',
  RATINGS_AND_REVIEWS_ALL_QUESTIONS: 'web_ratings_reviews_all_questions',
  RATINGS_AND_REVIEWS_HOTEL_QUESTIONS: 'web_ratings_reviews_hotel_questions',
  RATINGS_AND_REVIEWS_CITY_LOCALITY_QUESTIONS:
    'web_ratings_reviews_city_locality_questions',
  RATINGS_AND_REVIEWS_UNANSWERED_QUESTIONS:
    'web_ratings_reviews_unanswered_hotel_questions',
  RATINGS_AND_REVIEWS_NEW_IN_STAY_REVIEWS:
    'web_ratings_reviews_new_in-stay_reviews',
  RATINGS_AND_REVIEWS_RESOLVED_IN_STAY_REVIEWS:
    'web_ratings_reviews_resolved_in-stay_reviews',
  RATINGS_AND_REVIEWS_UNRESOLVED_IN_STAY_REVIEWS:
    'web_ratings_reviews_unresolved_in-stay_reviews',
  RATINGS_AND_REVIEWS_NEW_MONEY_BACK_REVIEWS:
    'web_ratings_reviews_new_money_back_reviews',
  RATINGS_AND_REVIEWS_ALL_MONEY_BACK_REVIEWS:
    'web_ratings_reviews_all_money_back_reviews',
  TAX_INVOICING: 'web_tax_invoicing',
  GO_STAYS_POPUP: 'gostays_popup',
  BOOST_PERFORMANCE: 'web_boostperformance',
  INGOBOX: 'web_inbox',
  GUESTCHAT: 'web_ingochat',
  MANAGE_PROFILE: 'manage_profile',
  HELP_CENTER: 'helpcenter',
  WEB_LEFTDRAWER: 'web_leftdrawer',
  WEB_MYLISTING: 'web_mylisting',
  REVIEWS: 'web_reviews',
  REVIEWS_ALL_REVIEWS: 'web_reviews_all_reviews',
  REVIEWS_NOT_REPLIED: 'web_reviews_not_replied',
  REVIEWS_REPLIED: 'web_reviews_replied',
  REVIEWS_BOOKING_REVIEWS: 'web_reviews_booking_reviews',
  REVIEWS_NON_BOOKING_REVIEWS: 'web_reviews_non_booking_reviews',
  REVIEWS_PHOTO_REVIEWS: 'web_reviews_photo_reviews',
  REVIEWS_ALL_QUESTIONS: 'web_reviews_all_questions',
  REVIEWS_HOTEL_QUESTIONS: 'web_reviews_hotel_questions',
  REVIEWS_CITY_LOCALITY_QUESTIONS: 'web_reviews_city_locality_questions',
  REVIEWS_UNANSWERED_QUESTIONS: 'web_reviews_unanswered_hotel_questions',
  GO_STAYS: 'web_gostays',
  MMT_SELECT: 'web_mmt_select',
  ACTION_DETAIL: 'web_action_center_l2',
  WEB_BOOKINGS_CALENDER: 'web_bookings_calendar',
  ONBOARDING: 'web_onboarding',
  HOST_WEB_ONBOARDING: 'hostweb_onboarding',
  BOOKINGS_INSIGHTS: 'web_bookings_insights',
  BOOKINGS_CALENDAR: 'web_bookings_calendar',
  ONBOARDING_FINANCE: 'onboarding_finance',
};

export const OMNITURE_CATEGORIES = {
  CTA_CLICK: 'ctaClick',
  PAGE_VIEW: 'pageView',
  CTA_LOAD: 'ctaLoad',
  FILTER: 'filter',
};

export const GTM_ACTIONS = {
  PURSUASIONS_POPUP_ACTION: 'persuasions_:title_popup',
  MANAGE_PROFILE: 'manage_profile',
  ADD_MEMBER: 'add_member',
  VIEW_PROFILE: 'view_profile',
  ASSIGN_HOST_PERSUASION: 'assign_host_persuasion',
  ASSIGN_HOST_PERSUASION_POPUP: 'assign_host_persuasion_popup',
  HOST_DETAILS_MISSING: 'host_details_missing_persuasion',
  EDIT_PROFILE: 'edit_profile',
  QUALITY_SCORE_ACTIVE: 'quality_score_active',
  SAVE_CONTINUE: 'save_continue_click',
  SUBMIT_FOR_REVIEW: 'submit_for_review',
  ONBOARDING_AMENITIES: 'onboarding_amenities',
  ONBOARDING_BASICINFO: 'onboarding_basicInfo',
  ONBOARDING_FINANCE: 'onboarding_finance',
  LOAD: 'load',
  PROPERTY_ONBOARDING: 'property_onboarding',
  SELECT_PROPERTY_TYPE_META: 'select_property_type_meta',
  SELECT_PROPERTY_CATEGORY: 'select_property_category',
  SELECT_PROPERTY_TYPE: 'select_property_type',
};
export type omniObjectProps = {
  event: string;
  loadedComponents?: string;
  pageName?: string;
  pageType?: string;
  cta?: Record<string, any>;
  error?: Record<string, any>;
  name?: string;
  [key: string]: any;
};

export function getOmniEvent(params: GAObject) {
  if (params.eventCategory === GTM_CATEGORIES.PAGE_LOAD) {
    return 'pageView';
  }
  return params.eventLabel ? 'ctaClick' : 'pageView';
}

export const getCurrentPageName = pageName => {
  const isResellerView = window.adobeHotelGA?.isResellerView;

  if (pageName) {
    return isResellerView ? `hotelcloud_${pageName}` : pageName;
  }

  const path = window.location.pathname.split('?')[0].split('/').slice(2);

  return isResellerView ? `hotelcloud_${path.join(':')}` : path.join(':');
};

export function pushToOmniture(props: omniObjectProps) {
  const {
    pageType,
    loadedComponents = '',
    datasource = '',
    ...extraData
  } = props;

  const pageName = getCurrentPageName(props.pageName);

  const findSource = window?.adobeHotelGA?.source || datasource || 'NA';

  const omniObject: any = {
    page: {
      pageInfo: {
        pageName: `dweb:${findSource}${pageName ? `:${pageName}` : ''}`,
        pageType: pageType ? pageType : 'Main page',
        siteName: 'extranet',
        platform: 'Dweb',
        language: 'english',
        loadedComponents: loadedComponents,
        numberOfLoadedComponents: loadedComponents
          .split('|')
          ?.filter(x => x.length).length,
      },
    },
    account: {
      accountInfo: {
        ...window.adobeUserGA,
      },
    },
    hotel: {
      hotelInfo: {
        ...window.adobeHotelGA,
      },
    },
    ...extraData,
  };
  window.adobeDataLayer.push(omniObject);
}

export function convertToOmni(params) {
  const isPageView = params.event === 'pageView';
  const isClickView =
    params?.event === 'ctaClick' ||
    params?.event === 'toggle' ||
    (!isPageView && !!params.eventLabel);
  const loadedComponents =
    params?.loadedComponents ||
    (params.eventCategory === GTM_CATEGORIES.PAGE_LOAD
      ? params.eventLabel
      : params.eventCategory +
        (params.eventAction ? `|${params.eventAction}` : ''));
  const pageName = getCurrentPageName(params.pageName);
  const pageType = `${
    params?.type === 'popup'
      ? `Popup_${
        params.name ? params.name : params.eventLabel || params.eventAction
      }`
      : 'Main page'
  }`;

  const omniObject: any = {
    event: params.event,
    loadedComponents,
    pageName,
    pageType: params?.pageType || pageType,
    cta: {},
  };
  if (params.cta) {
    omniObject.cta = { componentName: params.eventAction, ...params.cta };
  }
  if (params.window) {
    omniObject.window = params.window;
  }
  if (isClickView) {
    omniObject.cta = {
      name: params.name || params.eventLabel,
      componentName: params.eventAction,
      type: 'click',
      ...omniObject.cta,
    };
  }
  if (params.error) {
    omniObject.error = { ...params.error };
  }
  if (params.hotelInfo) {
    omniObject.hotel = { hotelInfo: { ...params.hotelInfo } };
  }
  return omniObject;
}

export default function pushToGTM(
  category: string,
  action: string,
  label = '',
  customData: any = {},
) {
  try {
    const { omnitureData = {}, ...custom } = customData || {};
    const params: any = {
      event: 'customEvent',
      username: window.INGOIBIBO_GOOGLE_ANALYTICS_USERNAME,
      eventCategory: category,
      eventAction: action,
      eventLabel: label ?? '',
      ...window.userGA,
      ...window.hotelGA,
      ...custom,
    };
    if (omnitureData?.skip) {
      return;
    }
    params.event = getOmniEvent(params);
    const omniObject = convertToOmni({ ...params, ...omnitureData });
    pushToOmniture(omniObject);
  } catch (error) {
    console.error('Error in pushing GA event', error); // should not happen
  }
}

export type GAObject = {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  [x: string]: any;
};

export function legacyPushToGTM(gaObj: GAObject) {
  const { eventCategory, eventAction, eventLabel, ...rest } = gaObj;
  pushToGTM(eventCategory, eventAction, eventLabel, rest);
}
