import { makeStyles } from '@mui/styles';
import { getColor, type PaletteColorsType } from 'components/mui-theme';
import type { Theme } from '@mui/material';

type TypographyStylesProps = {
  themeColor: PaletteColorsType;
  themeBg: PaletteColorsType;
  fb: boolean;
};
export const useTypographyStyles = makeStyles<Theme, TypographyStylesProps>(
  () => ({
    bold: {
      fontWeight: ({ fb }) => (fb ? 500 : 0),
    },
    overflowWrap: {
      overflowWrap: 'anywhere',
    },
    addColor: {
      color: ({ themeColor }) => (themeColor ? getColor(themeColor) : null),
      backgroundColor: ({ themeBg }) => (themeBg ? getColor(themeBg) : null),
    },
    inline: {
      display: 'inline',
    },
    link: {
      cursor: 'pointer',
    },
  }),
);
