import { Button, Radio, Typography } from 'components/latest-core';
import { useContext, useState } from 'react';
import { AppContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';
import { showMessage } from 'utilities/Utils';
import { updatPersuasioninIngo } from '../OpportunityCenterGraphqlClient';

const PersuasionModal = ({
  persuasion,
  onModalClose,
  dashboard,
  hotelcode,
  refreshData,
}) => {
  const { data } = persuasion;
  const promoType = data?.promo_type;
  const description = data?.description;
  const expiringType = data?.offer_type?.[0] || 'promotion/coupon';

  const [OPP_CTR_STRINGS] =
    useLangStrings<'OpportunityCenter'>('OpportunityCenter');
  const [STRINGS] = useLangStrings<'Dashboard'>('Dashboard');
  const { user } = useContext(AppContext);
  const options = [
    {
      label: STRINGS.PERSUASIONS.EXTEND_FOR_ONE_MONTH.replace(
        '__messages__',
        expiringType,
      ),
      value: '1',
    },
    {
      label: STRINGS.PERSUASIONS.EXTEND_FOR_TWO_MONTH.replace(
        '__messages__',
        expiringType,
      ),
      value: '2',
    },
    {
      label: STRINGS.PERSUASIONS.EXTEND_FOR_THREE_MONTH.replace(
        '__messages__',
        expiringType,
      ),
      value: '3',
    },
  ];
  const [period, setPeriod] = useState(options[2].value);

  const onClose = () => {
    if (dashboard) {
      pushToGTM(
        GTM_CATEGORIES.DASHBOARD,
        `action_center_${persuasion.title}_popup`,
        `click_cancel_${period}`,
      );
    } else {
      pushToGTM(
        GTM_CATEGORIES.ACTION_DETAIL,
        `${persuasion.category}_cta`,
        `click_cancel_${period}`,
      );
    }
    onModalClose();
  };

  const updatePersuasion = () => {
    if (dashboard) {
      pushToGTM(
        GTM_CATEGORIES.DASHBOARD,
        `action_center_${persuasion.title}_popup`,
        `click_save_${period}`,
      );
    } else {
      pushToGTM(
        GTM_CATEGORIES.ACTION_DETAIL,
        `${persuasion.category}_cta`,
        `click_save_${period}`,
      );
    }

    return updatPersuasioninIngo(persuasion, period, hotelcode, user)
      .then(resp => {
        if (resp?.error?.message) {
          const errMessage = resp?.error?.message;

          showMessage({
            show: true,
            message: errMessage || 'Something went wrong',
            type: 'error',
          });
          return;
        }

        const updatePersuasionData = resp?.data?.updatePersuasion;

        if (updatePersuasionData?.status === 'failure') {
          showMessage({
            show: true,
            message: updatePersuasionData.message || 'Something went wrong',
            type: 'error',
          });
        } else {
          refreshData();
        }

        onModalClose();
      })
      .catch(err => {
        const errMessage = err?.errors?.[0]?.message;

        if (errMessage) {
          showMessage({
            show: true,
            message: errMessage || 'Something went wrong',
            type: 'error',
          });
        }
        onModalClose();
      });
  };

  return (
    <div className="opportunitycenter-persuasion-modal">
      {promoType && (
        <div className="mb-3">
          <Typography variant="body1" themeColor="gray.main" inline>
            {OPP_CTR_STRINGS.OFFER_TYPE}
            {': '}
          </Typography>
          <Typography variant="body1" themeColor="gray.main" inline fb>
            {promoType}
          </Typography>
        </div>
      )}
      {description && (
        <div className="mb-3">
          <Typography variant="body1" themeColor="gray.main" inline>
            {OPP_CTR_STRINGS.OFFER_DESC}
            {': '}
          </Typography>
          <Typography variant="body1" themeColor="gray.main" inline fb>
            {description}
          </Typography>
        </div>
      )}
      {
        <Radio
          name="promotion"
          value={period}
          onChange={val => setPeriod(val)}
          options={options}
        />
      }
      <div className="flex-center space-around">
        <Button fullWidth variant="outlined" className="mr-5" onClick={onClose}>
          Cancel
        </Button>
        <Button fullWidth variant="contained" onClick={updatePersuasion}>
          SAVE CHANGES
        </Button>
      </div>
    </div>
  );
};

export default PersuasionModal;
