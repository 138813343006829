import { Typography } from 'components/latest-core';
import { Calendar } from 'assets/modules/packages';
import { Packages } from 'interfaces/Strings';

export const LOSPersuasion = (props: { strings: Packages }) => {
  const {
    strings: { LOS: str },
  } = props;
  return (
    <div className="flex flex-row rounded-lg gap-[10px] bg-gradient-to-r from-[#FCDEB9] to-[#FDCB8D] my-4 h-[100px] items-center">
      <div className="content-center">
        <Calendar className="mx-3" width={82} height={82} />
      </div>
      <div className="flex flex-col mr-3">
        <Typography variant="h3">{str.OFFER_MORE_PERKS}</Typography>
        <Typography variant="subtitle1">
          {str.ADD_INCLUSIONS}
          <span className="font-medium">{str.TRANSFERS_LAUNDRY_SPA}</span>
          {str.FOR_CUSTOMERS_BOOKING}
        </Typography>
      </div>
    </div>
  );
};
