import { makeStyles } from '@mui/styles';

type RadioStylesType = {
  inline: boolean;
};

export const useRadioStyles = makeStyles(theme => ({
  radioGroupRoot: (props: RadioStylesType) => ({
    gap: props.inline ? '16px' : '8px',
  }),
  formRoot: {
    margin: 0,
  },
  radioRoot: {
    padding: '0px',
    width: '100%',
  },
  radioContainer: {
    display: 'flex',
    gap: '8px',
    width: '100%',
  },
  icon: {
    width: 14,
    height: 14,
    margin: '3px',
    display: 'flex',
    flexShrink: 0,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.common.light}`,
    backgroundColor: theme.palette.common.white,
    '&.radio-disabled': {
      backgroundColor: theme.palette.backgrounds.light,
      border: `1px solid ${theme.palette.backgrounds.divider}`,
    },
  },
  checkedIcon: {
    border: `4px solid ${theme.palette.primary.main}`,
    '&.radio-disabled': {
      backgroundColor: theme.palette.backgrounds.dark,
      border: `4px solid ${theme.palette.backgrounds.divider}`,
    },
  },
  radioContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    userSelect: 'none',
  },
  radioLabelContainer: {
    ...theme.typography.body1,
  },
  radioDescriptionContainer: {
    ...theme.typography.subtitle1,
  },

  // chip variant
  chip: {
    borderRadius: '60px',
    padding: '6px 8px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.backgrounds.divider}`,
    gap: '4px',
    'input:disabled ~ &': {
      backgroundColor: theme.palette.backgrounds.light,
    },
  },
  checkedChip: {
    backgroundColor: theme.palette.backgrounds.primary,
    border: `1px solid ${theme.palette.primary.main}`,
    'input:disabled ~ &': {
      border: `1px solid ${theme.palette.backgrounds.divider}`,
    },
  },

  // card variant
  card: {
    borderRadius: '8px',
    padding: '12px',
    border: `2px solid ${theme.palette.divider}`,
    gap: '4px',
    'input:disabled ~ &': {
      backgroundColor: theme.palette.backgrounds.light,
    },
  },
  checkedCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    'input:disabled ~ &': {
      border: `2px solid ${theme.palette.divider}`,
    },
  },

  // legacy box variant
  legacyBoxedLabel: {
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    fontSize: '12px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '85px',
    padding: '0px 15px',
    'input:hover ~ &': {
      backgroundColor: 'rgba(183, 183, 183, 0.15)',
      color: theme.palette.text.primary,
    },
    'input:disabled ~ &': {
      backgroundColor: 'rgba(206, 217, 224, 0.15)',
      color: theme.palette.text.secondary,
      borderColor: 'rgb(118,118,118,0.3)',
    },
  },
  legacyCheckedBoxedLabel: {
    border: `1px solid ${theme.palette.common.legacySecondary}`,
    color: theme.palette.common.legacySecondary,
    'input:hover ~ &': {
      color: theme.palette.common.legacyPrimary,
      border: `1px solid ${theme.palette.common.legacyPrimary}`,
      backgroundColor: 'rgba(254, 125, 50, 0.15)',
    },
    'input:disabled ~ &': {
      border: `1px solid ${theme.palette.common.legacySecondary}`,
      color: theme.palette.common.legacySecondary,
      backgroundColor: 'rgba(206, 217, 224, 0.15)',
    },
  },
}));
