import { useCalendar } from 'components/latest-core';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  dateInit,
  formattedGroupDates,
  getFormattedDate,
  isDateSame,
  onlyDateComparison,
  unGroupDates,
} from 'utilities/DateUtils';
import { confirmBoosterType } from './ConfirmBoosterModal/ConfirmBoosterModal';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';

export const CALENDAR_TYPE = {
  BOOKING_START: 'bookingdatestart',
  BOOKING_END: 'bookingdateend',
  STAY_START: 'checkindatestart',
  STAY_END: 'checkoutdateend',
  BLACKOUT: 'checkinblackoutdates',
} as const;

const { API_DATE_FORMAT: dateFormat } = CONSTANTS;
const dateFormatter = (type, date) => {
  if (type === CALENDAR_TYPE.BLACKOUT) {
    return formattedGroupDates(date, dateFormat);
  }
  return getFormattedDate(date, dateFormat);
};

/**
 * @description
 * Custom hook created to add calendar component data and it's payload.
 *
 * @interface ConfigInterface {
 *  hasBookingEndSync: Boolean,
 *  blackOutOptions: Object,
 *  showMore: CALENDAR_TYPE[],
 *  createOnly: CALENDAR_TYPE[]
 * }
 *
 * @interface useBoosterCalendarResultInterface {
 *  calendarProps: useCalendarInput[],
 *  calendarDates: Date[],
 *  getModifiedDate(modificationMap: Object, isEdit: Boolean, showMore: Boolean): void,
 *  appendDatePayload(payload: Object, isEdit: Boolean, showMore: Boolean): void
 * }
 *
 * @param { CALENDAR_TYPE } initialDate calendar which is required.
 * @param { ConfigInterface } config configuration
 *
 * @returns { useBoosterCalendarResultInterface }
 */
export default function useBoosterCalendar(
  initialDate,
  config = {
    hasBookingEndSync: false,
    blackOutOptions: {},
    showMore: [],
    createOnly: [],
  },
) {
  const [STRINGS] = useLangStrings('PerformanceBooster');
  const { DRAWER } = STRINGS;

  const {
    [CALENDAR_TYPE.BLACKOUT]: blackOutInit,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartInit,
    [CALENDAR_TYPE.BOOKING_END]: bookingEndInit,
    [CALENDAR_TYPE.STAY_START]: stayStartInit,
    [CALENDAR_TYPE.STAY_END]: stayEndInit,
  } = initialDate;

  const bookingStartCalendarProps = useCalendar({
    variant: 'single',
    initialDate: dateInit(bookingStartInit),
  });
  const bookingEndCalendarProps = useCalendar({
    variant: 'single',
    initialDate: dateInit(bookingEndInit),
  });

  const stayStartCalendarProps = useCalendar({
    variant: 'single',
    initialDate: dateInit(stayStartInit),
  });
  const stayEndCalendarProps = useCalendar({
    variant: 'single',
    initialDate: dateInit(stayEndInit),
    callbackAfterDateSelection: newStayEnd => {
      if (config.hasBookingEndSync) {
        const {
          calendarProps: {
            selectedDays: updatedBookingEnd,
            setSelectedDays: setBookingEnd,
          },
        } = bookingEndCalendarProps;
        const isBookingEndInvalid =
          onlyDateComparison(updatedBookingEnd as Date, newStayEnd as Date) !==
          -1;

        if (isBookingEndInvalid) {
          // @ts-ignore
          setBookingEnd(newStayEnd);
        }
      }
    },
  });
  const blackOutList = unGroupDates(blackOutInit);
  const blackoutCalendarProps = useCalendar({
    variant: 'multiple',
    initialDate: blackOutList,
    multiSelectRestriction: config.blackOutOptions,
  });

  const calendarDisplayLabel = {
    [CALENDAR_TYPE.BOOKING_START]: DRAWER.BOOKING_START,
    [CALENDAR_TYPE.BOOKING_END]: DRAWER.BOOKING_END,
    [CALENDAR_TYPE.STAY_START]: DRAWER.STAY_START,
    [CALENDAR_TYPE.STAY_END]: DRAWER.STAY_END,
    [CALENDAR_TYPE.BLACKOUT]: DRAWER.BLACKOUT,
  };

  // Calendar props
  const calendarProps = {
    [CALENDAR_TYPE.STAY_START]: stayStartCalendarProps,
    [CALENDAR_TYPE.STAY_END]: stayEndCalendarProps,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartCalendarProps,
    [CALENDAR_TYPE.BOOKING_END]: bookingEndCalendarProps,
    [CALENDAR_TYPE.BLACKOUT]: blackoutCalendarProps,
  };

  // calendar dates
  const calendarDates = {
    [CALENDAR_TYPE.STAY_START]:
      stayStartCalendarProps.calendarProps.selectedDays,
    [CALENDAR_TYPE.STAY_END]: stayEndCalendarProps.calendarProps.selectedDays,
    [CALENDAR_TYPE.BOOKING_START]:
      bookingStartCalendarProps.calendarProps.selectedDays,
    [CALENDAR_TYPE.BOOKING_END]:
      bookingEndCalendarProps.calendarProps.selectedDays,
    [CALENDAR_TYPE.BLACKOUT]: blackoutCalendarProps.calendarProps.selectedDays,
  };

  const generateModifiedDates = (modificationMap, isEdit, showMore) => {
    Object.keys(calendarDates).forEach(type => {
      const isBlackOut = type === CALENDAR_TYPE.BLACKOUT;
      const initialValue = isBlackOut ? blackOutList : initialDate[type];
      const updatedValue = calendarDates[type];

      if (initialValue !== undefined) {
        const key = calendarDisplayLabel[type];
        const checkShowMore = !(config.showMore?.includes(type) && !showMore);
        const skipOnEdit = isEdit && config.createOnly?.includes(type);

        if (isBlackOut) {
          const hasUpdated = initialValue.join(',') !== updatedValue.join(',');
          if (hasUpdated && checkShowMore && !skipOnEdit) {
            modificationMap[key] = {
              apiKey: type,
              type: confirmBoosterType.DATE_LIST,
              initialValue: blackOutList,
              updatedValue: dateFormatter(type, updatedValue),
            };
          }
        } else {
          const hasUpdated =
            (!isEdit && updatedValue) ||
            !isDateSame(initialValue, updatedValue);

          if (hasUpdated && checkShowMore && !skipOnEdit) {
            modificationMap[key] = {
              apiKey: type,
              type: confirmBoosterType.DATE,
              initialValue,
              updatedValue: dateFormatter(type, updatedValue),
            };
          }
        }
      }
    });
  };

  const appendDatePayload = (payload, showMore) => {
    Object.keys(calendarDates).forEach(type => {
      const date = calendarDates[type];
      // Show more add when active only.
      const addOnShowMore = !(config.showMore?.includes(type) && !showMore);
      if (initialDate[type] !== undefined && addOnShowMore) {
        payload[type] = dateFormatter(type, date);
      }
    });
  };

  return {
    calendarProps,
    calendarDates,
    generateModifiedDates,
    appendDatePayload,
  };
}

// Edit flow skip adding it as payload.
