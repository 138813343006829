import type { PaletteColorsType } from './theme.types';
import { getValue } from 'utilities/Utils';

// If you want to use theme colors in scss import variables.scss file in your scss file and use the variables
// variables.scss palette and theme palette both are linked to the same file.
// So if you are adding any changes to variables.scss file, you need to update the theme palette as well.
// So if you are adding any changes to theme palette, you need to update the variables.scss file as well.

export const colorPalette = {
  common: {
    dark: '#000000',
    black: '#0F0F0F',
    white: '#FFFFFF',
    transparent: 'transparent',
    lightSuccess: '#A9B466',
    light: '#404040',
    lightError: '#EB6920',
    legacyPrimary: '#fe7d32',
    legacySecondary: '#fd7e32',
    leagecyNav: '#FE975B',
  },
  backgrounds: {
    primary: '#EFF1FF',
    success: '#E8FFF0',
    lightSuccess: '#F5FDEE',
    warning: '#FFF8EE',
    lightError: '#FEF8ED',
    error: '#FFE9E9',
    dark: '#EFEFEF',
    light: '#F8F8F8',
    white: '#FFFFFF',
    dividerDark: '#EBEBEB',
    divider: '#D2D1D1',
    info: '#E2F5FE',
    overlay: '#31313180',
  },
  divider: '#DEDEDE', // alternative divider -> #EBEBEB
  primary: {
    main: '#3543BF',
    light: '#C2C7EC',
    dark: '#1F2A8D',
    lighter: '#EFF1FF',
  },
  error: {
    main: '#C62828',
    light: '#FFEBEE',
    lighter: '#FFE9E9',
    dark: '#C62828',
  },
  success: {
    main: '#37BC8E',
    light: '#7CE3C0',
    lighter: '#E8F5E9',
    medium: '#81C784',
    dark: '#2E7D32',
    infoDefault: '#D78411',
  },
  warning: {
    main: '#F2A526',
    light: '#FADCAC',
    lighter: '#FFF8EE',
  },
  gray: {
    main: '#4A4A4A',
    light: '#8B8B8B',
  },
  text: {
    primary: '#404040', // dark
    secondary: '#787878', // light
    link: '#0B61D9',
    dark: '#343434',
    disable: '#0006',
    lightSecondary: '#858585',
  },
  info: {
    main: '#3543BF',
  },
  graph: {
    accent1: '#0F8EDB',
    accent2: '#EB9071',
    accent3: '#F1CB6A',
    accent4: '#D565BB',
  },
  secondary: {
    main: '#008CFF',
  },
};

export const getColor = (colorPath: PaletteColorsType) => {
  return getValue(colorPalette, colorPath, 'inherit');
};
