import { Skeleton } from '@mui/material';

const PerformanceCardShimmer = () => {
  return (
    <>
      <div className="flex mb-2 items-center">
        <Skeleton
          variant="text"
          animation="pulse"
          className="w-[calc(30%)] mr-2"
        />
      </div>
      <div className="flex items-center mb-1">
        <Skeleton
          variant="text"
          animation="pulse"
          className="w-[calc(20%)] mr-1"
        />
        <Skeleton variant="text" animation="pulse" className="w-[calc(30%)]" />
      </div>
      <Skeleton variant="text" className="text-[#404040] mb-[10px] w-[60%]" />
      <Skeleton variant="text" className="text-text-link mb-[10px] w-[20%]" />
    </>
  );
};

export default PerformanceCardShimmer;
