import Loader from 'components/Loader';
import { HotelContext } from 'utilities/Context';

const withLoaderWrapper = Component => props => {
  return (
    <HotelContext.Consumer>
      {({ isLegacyParityEnsured }) => {
        return !isLegacyParityEnsured ? (
          <Loader className="global-loader-overlay" {...props} />
        ) : (
          <Component {...props} />
        );
      }}
    </HotelContext.Consumer>
  );
};

export default withLoaderWrapper;
