export const RoomNightsMonthWise = `
  query RoomNightsMonthWise($rawEndpointRequestData: RawEndpointsRequestData) {
      rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData,){
        analyticsGraph{
          modelType1{
            status
            message
            data
          }
        }
      }
    }
`;

export const BookingCancelled = `
  query BookingCancelled($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData,){
      analyticsGraph{
        modelType1{
          status
          message
          data
        }
      }
    }
  }
`;

export const LOSDistribution = `
  query IosDistribution($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData,){
      analyticsGraph{
        modelType1{
          status
          message
          data
        }
      }
    }
  }
`;

export const MealPlan = `
  query MealPlan($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData,){
      analyticsGraph{
        modelType1{
          status
          message
          data
        }
      }
    }
  }
`;

export const PAXCount = `
  query RoomNightPax($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData,){
      analyticsGraph{
        modelType1{
          status
          message
          data
        }
      }
    }
  }
`;

export const analyticsQuery = `query AnalyticsHistogram($rawEndpointRequestData: RawEndpointsRequestData){
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData){
      analyticsHistogram{
        modelType1 {
          data
          message
          status
          status_code
        }
      }
    }
  }`;
