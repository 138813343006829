import { TextField, createFilterOptions } from '@mui/material';
import { SearchIconSuffix } from 'assets/common';
import { useMemo, useState } from 'react';

interface InputProps {
  placeholder?: string;
  startAdornment?: React.ReactNode;
  size?: 'small' | 'medium';
  rootStyles?: string;
}

const useOptions = (
  options: unknown[],
  optionLabel: (option: unknown) => string,
) => {
  const [searchingText, setSearchingText] = useState('');

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        matchFrom: 'any',
        stringify: option => optionLabel(option),
      }),
    [optionLabel],
  );

  const filteredOptions = filterOptions(options, {
    inputValue: searchingText,
    getOptionLabel: optionLabel,
  });

  const onClearText = useMemo(() => {
    return () => {
      setSearchingText('');
    };
  }, []);

  const Component = (inputProps: InputProps) => {
    const {
      placeholder = 'Search...',
      startAdornment,
      size = 'small',
      rootStyles = '',
    } = inputProps;

    return (
      <TextField
        placeholder={placeholder}
        InputProps={{
          startAdornment: !searchingText
            ? startAdornment || (
                <SearchIconSuffix width={24} height={24} className="mr-2" />
            )
            : null,
        }}
        onChange={e => {
          e.stopPropagation();
          setSearchingText(e.target.value);
        }}
        fullWidth
        autoComplete="off"
        onKeyDown={e => e.stopPropagation()}
        onKeyUp={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        onBlur={e => e.stopPropagation()}
        size={size}
        value={searchingText}
        classes={{
          root: rootStyles,
        }}
        autoFocus
      />
    );
  };

  return [filteredOptions, onClearText, Component] as const;
};

export default useOptions;
