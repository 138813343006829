import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '../Typography/Typography';
import { isNullOrUndefined } from 'utilities/Utils';
// Type defination for Tabs.tsx
type TabsProps = {
  tabs: {
    label: React.ReactNode;
    value: string;
    count?: number;
    dataTourId?: string;
    rightIcon?: React.ReactNode | null;
    description?: string;
    disabled?: boolean;
  }[];
  activeTab?: string;
  onChange: ((value: unknown) => void) | undefined;
  className?: string;
  orientation?: boolean;
  borderBtmLine?: boolean;
  activeClassName?: string;
  inactiveClassName?: string;
};
//end of type definition
const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    padding: 0,
    listStyleType: 'none',
    margin: 0,
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    listStyleType: 'none',
    margin: 0,
    '&:last-child': {
      marginRight: 24,
    },
  },
  borderBtm: { borderBottom: '1px solid #D2D1D1' },
  noBorderBtm: { borderBottom: 'none' },
  tab: {
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    padding: '8px 12px 2px', //added padding to extend the border bottom line
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '18px',
    marginRight: 0, //removed margin right for only last child, as padding has been added on both the sides of the tab so margin needs to removed
  },
  tabVertical: {
    cursor: 'pointer',
    marginRight: '12px !important', //reduced 12px as padding added of 12px on right to extend the underline
    borderBottom: '2px solid transparent',
    padding: '10px 12px 6px', //added padding to extend the border bottom line
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
    fontWeight: 700,
  },
  inactive: {
    color: '#404040',
  },
  count: {
    color: '#fff',
    borderRadius: '24px',
    padding: '2px 4px',
    fontSize: '12px',
    backgroundColor: '#3543BF',
    marginLeft: '4px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-1px',
    lineHeight: '12px',
    minWidth: '14px',
  },
  disabled: {
    color: '#787878',
  },
  icon: {
    marginLeft: '4px',
  },
}));
export default function Tabs(props: TabsProps) {
  const {
    tabs,
    activeTab,
    onChange,
    className = '',
    orientation = false,
    borderBtmLine = false,
    activeClassName = '',
    inactiveClassName = '',
  } = props;
  const classes = useStyles();
  const rootClass = `${className} ${
    borderBtmLine ? classes.borderBtm : classes.noBorderBtm
  }`;
  const description = useMemo(
    () => tabs.find(tab => tab.value === activeTab)?.description,
    [activeTab],
  );
  return (
    <Box component="nav" className={rootClass}>
      <Box
        component="ul"
        className={orientation ? classes.vertical : classes.list}
      >
        {tabs.map((tab, index) => {
          const {
            value,
            label,
            count,
            dataTourId,
            rightIcon = null,
            disabled,
          } = tab;
          const tabClass =
            value === activeTab
              ? `${classes.tab} ${classes.active} ${activeClassName}`
              : disabled
                ? `${classes.tab} ${classes.disabled}`
                : `${classes.tab} ${classes.inactive} ${inactiveClassName}`;
          const tabVerticalClass =
            value === activeTab
              ? `${classes.tabVertical} ${classes.active}`
              : `${classes.tabVertical} ${classes.inactive}`;
          return (
            <Box display="flex" alignItems="center" key={value}>
              <Box
                component="li"
                className={orientation ? tabVerticalClass : tabClass}
                key={index}
                role="button"
                data-tour-id={dataTourId}
                onClick={() => !disabled && onChange(value)}
              >
                {label}{' '}
                {!isNullOrUndefined(count) && (
                  <Box component="span" className={classes.count}>
                    {count}
                  </Box>
                )}
              </Box>
              {rightIcon && (
                <Box component="li" className={classes.icon}>
                  {rightIcon}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      {description && (
        <Typography variant="body1" className="mt-2" themeColor="text.primary">
          {description}
        </Typography>
      )}
    </Box>
  );
}
