import { Button, Typography } from 'components/latest-core';
import InfoWrapper from '../components/InfoWrapper';
import { useLangStrings } from 'utilities/CustomHooks';
import React from 'react';
import { navigateTo } from 'utilities/Utils';
import { routes } from 'app/routes';
import { Dashboard } from 'utilities/TestIds';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';

const AnalyticsRedirectCard = () => {
  const [STRINGS] = useLangStrings<'Dashboard'>('Dashboard');
  const [COMMON_STRINGS] = useLangStrings<'Common'>('Common');

  const handleViewAllClick = () => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'view_all',
        type: 'click',
        componentName: 'performance_overview|property_analytics',
      },
    });
    navigateTo(routes.analytics.base);
  };

  return (
    <InfoWrapper
      className="bg-[#EFF1FF] mb-6 border-solid border-[#C2C7EC] border-[1px]"
      testId={Dashboard.propertyAnalytics}
    >
      <div className="w-3/4 ">
        <Typography variant="h3" className="mb-1">
          {STRINGS.YOUR_PROPERTY_ANALYTICS.HEADER}
        </Typography>
        <Typography variant="body1">
          {STRINGS.YOUR_PROPERTY_ANALYTICS.ANALYTICS_INFO}
        </Typography>
      </div>
      <div className="w-1/4 flex justify-end items-center">
        <Button variant="outlined" color="primary" onClick={handleViewAllClick}>
          {COMMON_STRINGS.BUTTONS.VIEW_ALL}
        </Button>
      </div>
    </InfoWrapper>
  );
};

export default React.memo(AnalyticsRedirectCard);
