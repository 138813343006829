import { useContext } from 'react';

import {
  DRAWER_MAPPING,
  SEGMENT_MAPPING_CUG,
  mmtBlackList,
  mmtSelectList,
} from 'modules/promotions-and-coupons/PnC.constants';
import { GAFormatting, showMessage } from 'utilities/Utils';
import { CorporateDrawer, CouponDrawer, MMTBlackDrawer } from './drawers';

import {
  createUpdateCoupon,
  createUpdateCug,
} from 'modules/promotions-and-coupons/PnCGraphClient';
import { DealsContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import { legacyPushToGTM } from 'utilities/gtm';
import { getProcessedData, transformPayload } from '../utils';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';
import GenericCugDrawer from './drawers/GenericCugDrawer/GenericCugDrawer';

export const DRAWER_TYPE = {
  COUPON: 'coupon',
  MMT_BLACK: 'MMTBlack',
  MMT_SELECT: 'MMTSelect',
  CORPORATE: 'corporate',
  HOLIDAYS: 'Holidays',
  GENERIC: 'generic',
};

const handleError = (err, STRINGS) => {
  showMessage({
    show: true,
    message: err,
    commonMessage: STRINGS.ALERT_MESSAGE.SOMETHING_WENT_WRONG,
    type: 'error',
  });
};

export default function BoosterDrawerController(props) {
  const {
    type,
    data,
    isOfferAgain = false,
    cugAffiliates,
    hotelCode,
    onClose,
    mmtId,
  } = props;
  const [STRINGS] = useLangStrings('PerformanceBooster');
  const { navigateToActive, refreshAllPncPage, scrollToTop } =
    useContext(DealsContext);

  const createCoupon = (payload, isOfferAgain) => {
    payload.hotelcode = hotelCode;

    const eventType = GAFormatting(CONSTANTS.TYPE.COUPONS);
    const formattedPayload = transformPayload(STRINGS, payload);

    createUpdateCoupon(formattedPayload, mmtId)
      .then(coupon => {
        showMessage({
          show: true,
          message: STRINGS.ALERT_MESSAGE.COUPON_CREATE_SUCCESS,
          type: 'success',
        });

        legacyPushToGTM({
          eventCategory: 'web_boostperformance',
          eventAction: isOfferAgain
            ? `expired_booster_${eventType}`
            : eventType,
          eventLabel: isOfferAgain
            ? 'offer_again_success'
            : `${eventType}_success`,
          misc_id: coupon.offercode,
        });
        onClose();
        navigateToActive();
      })
      .catch(err => {
        const error = err?.data?.[0] || err;
        handleError(error, STRINGS);
        legacyPushToGTM({
          eventCategory: 'web_boostperformance',
          eventAction: isOfferAgain
            ? `expired_booster_${eventType}`
            : eventType,
          eventLabel: isOfferAgain
            ? 'offer_again_failure'
            : `${eventType}_failure`,
        });
      });
  };

  const updateCoupon = (payload, offerCode) => {
    const eventAction = `active_booster_${GAFormatting(
      CONSTANTS.TYPE.COUPONS,
    )}`;
    const formattedPayload = transformPayload(STRINGS, payload);
    formattedPayload.offerCode = {
      value: offerCode,
    };
    if (!(formattedPayload[STRINGS.CHECKINBLACKOUTDATES] || []).length) {
      formattedPayload[STRINGS.CHECKINBLACKOUTDATES] = [''];
    }
    createUpdateCoupon(formattedPayload, mmtId)
      .then(response => {
        showMessage({
          show: true,
          message: response.message,
          type: 'success',
        });

        legacyPushToGTM({
          eventCategory: 'web_boostperformance',
          eventAction,
          eventLabel: 'modify_success',
          misc_id: offerCode,
        });
        onClose();
        scrollToTop();
        refreshAllPncPage();
      })
      .catch(err => {
        const error = err?.data?.[0] || err;
        handleError(error, STRINGS);
        legacyPushToGTM({
          eventCategory: 'web_boostperformance',
          eventAction,
          eventLabel: 'modify_failure',
          misc_id: offerCode,
        });
      });
  };

  const updateCUG = (payload, segment, offerCode = '') => {
    const blackOutDatesModified = [];
    const modifiedOfferValue = [];
    const data = {};
    const cugType = Object.keys(DRAWER_MAPPING).find(
      key => DRAWER_MAPPING[key] === segment,
    );
    const eventAction = `active_booster_${GAFormatting(segment)}`;

    const {
      checkindatestart,
      checkoutdateend,
      bookingdatestart,
      bookingdateend,
      checkinblackoutdates,
      offer_value_list,
      offer_value,
    } = payload;

    checkinblackoutdates &&
      checkinblackoutdates.forEach(eachDate => {
        let temp = '';
        temp += `${eachDate[0]},`;
        temp += eachDate[1];
        blackOutDatesModified.push(temp);
      });

    if (offer_value_list && offer_value_list.length > 0) {
      offer_value_list.forEach(eachOffer => {
        const temp = {
          isActive: {
            value: true,
          },
          offerValue: {
            value: 0,
          },
          segment: '',
        };
        let tierIndex = 0;
        segment === CONSTANTS.TYPE.MMT_BLACK
          ? (tierIndex = mmtBlackList.indexOf(eachOffer.segment))
          : (tierIndex = mmtSelectList.indexOf(eachOffer.segment));

        temp.offerValue.value = eachOffer.offer_value;
        temp.segment =
          segment === CONSTANTS.TYPE.MMT_BLACK ||
          segment === CONSTANTS.TYPE.MMT_SELECT
            ? SEGMENT_MAPPING_CUG[cugType][tierIndex]
            : SEGMENT_MAPPING_CUG[cugType];

        modifiedOfferValue.push(temp);
      });
    } else {
      const temp = {
        segment: '',
        offerValue: {
          value: 0,
        },
      };
      temp.segment =
        segment === CONSTANTS.TYPE.MMT_BLACK ||
        segment === CONSTANTS.TYPE.MMT_SELECT
          ? SEGMENT_MAPPING_CUG[cugType][0]
          : SEGMENT_MAPPING_CUG[cugType];
      temp.offerValue.value = offer_value;
      modifiedOfferValue.push(temp);
    }

    if (checkindatestart) {
      data.checkinDateStart = {
        value: checkindatestart,
      };
    }
    if (checkoutdateend) {
      data.checkoutDateEnd = { value: checkoutdateend };
    }
    if (bookingdatestart) {
      data.bookingDateStart = { value: bookingdatestart };
    }
    if (bookingdateend) {
      data.bookingDateEnd = { value: bookingdateend };
    }
    if (checkinblackoutdates) {
      data.checkinBlackoutDates = blackOutDatesModified.length
        ? blackOutDatesModified
        : [''];
    }
    data.offerValueList = modifiedOfferValue;
    data.offerCode = { value: offerCode };

    createUpdateCug([data], mmtId)
      .then(() => {
        let message = isOfferAgain
          ? STRINGS.ALERT_MESSAGE.CUG_CREATE_SUCCESS
          : STRINGS.ALERT_MESSAGE.CUG_UPDATED_SUCCESS;
        if (segment === CONSTANTS.TYPE.CORPORATE) {
          message = `${STRINGS.MY_BIZ} ${message}`;
        } else if (segment === CONSTANTS.TYPE.MMT_BLACK) {
          message = `${STRINGS.MMT_BLACK} ${message}`;
        } else if (segment === CONSTANTS.TYPE.MMT_SELECT) {
          message = `${STRINGS.MMT_SELECT} ${message}`;
        } else if (segment === CONSTANTS.TYPE.HOLIDAYS) {
          message = `${STRINGS.HOLIDAYS} ${message}`;
        } else if (segment === CONSTANTS.TYPE.MEMBER) {
          message = `${STRINGS.MEMBER} ${message}`;
        }
        showMessage({
          show: true,
          message,
          type: 'success',
        });

        legacyPushToGTM({
          eventCategory: 'web_boostperformance',
          eventAction,
          eventLabel: 'modify_success',
          misc_id: offerCode,
        });
        onClose();
        // Creation to listing page
        if (isOfferAgain) {
          navigateToActive();
        } else {
          scrollToTop();
          refreshAllPncPage();
        }
      })
      .catch(err => {
        const error = isOfferAgain ? err?.data?.[0] : err;
        handleError(error, STRINGS);
        legacyPushToGTM({
          eventCategory: 'web_boostperformance',
          eventAction,
          eventLabel: 'modify_failure',
          misc_id: offerCode,
        });
      });
  };

  const drawerData = getProcessedData(STRINGS, type, data);

  switch (type) {
    case DRAWER_TYPE.COUPON:
      return (
        <CouponDrawer
          data={drawerData}
          onDrawerClose={onClose}
          createCoupon={createCoupon}
          updateCoupon={updateCoupon}
        />
      );
    case DRAWER_TYPE.CORPORATE:
      return (
        <CorporateDrawer
          data={drawerData}
          onDrawerClose={onClose}
          createCUG={updateCUG}
          updateCUG={updateCUG}
          isOfferAgain={isOfferAgain}
        />
      );
    case DRAWER_TYPE.MMT_BLACK:
    case DRAWER_TYPE.MMT_SELECT:
      return (
        <MMTBlackDrawer
          type={type}
          data={drawerData}
          cugAffiliates={cugAffiliates}
          onDrawerClose={onClose}
          createCUG={updateCUG}
          updateCUG={updateCUG}
          isOfferAgain={isOfferAgain}
        />
      );
    default:
      if (drawerData) {
        return (
          <GenericCugDrawer
            data={drawerData}
            onDrawerClose={onClose}
            createCUG={updateCUG}
            updateCUG={updateCUG}
            isOfferAgain={isOfferAgain}
          />
        );
      }
      return null;
  }
}
