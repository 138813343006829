import {
  AvailabilityTracker,
  Brandings,
  ChannelManager,
  CreativeDynamicOffer,
  GoMMTLogo,
  IntelligentReports,
  Mobile,
  MultiHotelSupport,
  RealTimePayment,
  SalesChannels,
  SignupFlow,
  SmartDashboard,
  MonitorComp,
  RealTimeNotification,
  UserActivity,
  CompetitionRate,
} from 'assets/modules/login';
import { useContext, useEffect, useState } from 'react';
import { Button, Input, Typography } from 'components/latest-core';

import Registration from './Registration';
import {
  broadcastLoginInfoToOtherTabs,
  getParsedErrorMessageFromAPI,
  navigateTo,
  scrollToElement,
  setCookie,
  showMessage,
} from 'utilities/Utils';
import { routes } from 'app/routes';
import { pushToOmniture } from 'utilities/gtm';
import AppDownloadButtons from './AppDownloadButtons';
import { loginUser } from '../LoginAPIClient';
import { AuthContext } from 'utilities/Context';
import { SSO_ONE_LOGIN_URL } from '../login.constant';
import { COOKIE_EXPIRE_TIME, appDocAccessCookieKey } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { pushToCtaOmnitureCommon } from '../utils';

import './login.scss';

const iconComponents = {
  'Smart dashboard': <SmartDashboard />,
  'New Sales Channels': <SalesChannels />,
  'Multi hotel support': <MultiHotelSupport />,
  'Monitor your competitors': <MonitorComp />,
  'Competition Rate': <CompetitionRate />,
  'Availability Tracker': <AvailabilityTracker />,
  'Create Dynamic offers': <CreativeDynamicOffer />,
  'Real time payment': <RealTimePayment />,
  'Intelligent reports': <IntelligentReports />,
  'Email Notifications': <RealTimeNotification />,
  'User activity': <UserActivity />,
  '40+ channel managers': <ChannelManager />,
};

const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const {
    location: { state },
  } = useHistory();

  const { from = '/' } = state || {};

  const moveToRegistrationBlock = () => {
    pushToCtaOmnitureCommon({
      ctaName: 'Get_Started_For_Free',
      componentName: 'Landing_Page|Get_Started_For_Free',
      pageName: 'landing_page',
    });
    scrollToElement('#registration');
  };

  const {
    setApiToken,
    langStringsData: { langStrings },
  } = useContext(AuthContext);

  const {
    Login: { LOGIN: STRINGS },
    Common: COMMON,
  } = langStrings;

  const signInOmniEventObj = {
    ctaName: 'Sign_In',
    componentName: 'Landing_Page|Sign_In',
    pageName: 'landing_page',
  };
  const onLogin = event => {
    event.preventDefault();

    if (!userName) {
      moveToRegistrationBlock();
      return;
    }

    const payload = {
      userName,
      password,
    };

    loginUser(payload)
      .then(resp => {
        // @ts-ignore
        const data = resp?.response;

        const isStaff = data?.internalRequest;

        if (isStaff) {
          window.location.href = SSO_ONE_LOGIN_URL;
          return;
        }

        if (data?.success) {
          const apiToken = data?.apiToken;

          setApiToken(apiToken);
          setCookie(appDocAccessCookieKey, apiToken, COOKIE_EXPIRE_TIME);
          broadcastLoginInfoToOtherTabs('login');

          if (apiToken) {
            //redirecting back to previously accessed url after login
            navigateTo(from);
          }
          pushToCtaOmnitureCommon(signInOmniEventObj);
        } else {
          pushToCtaOmnitureCommon({
            ...signInOmniEventObj,
            errMsg:
              getParsedErrorMessageFromAPI(data) ??
              COMMON.MESSAGES.SOMETHING_WENT_WRONG,
          });
          showMessage({
            type: 'error',
            show: true,
            commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
            message: data,
          });
        }
      })
      .catch(err => {
        pushToCtaOmnitureCommon({
          ...signInOmniEventObj,
          errMsg:
            getParsedErrorMessageFromAPI(err) ??
            COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        });
        showMessage({
          type: 'error',
          show: true,
          commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
          message: err,
        });
      });
  };

  const onForgotPasswordClick = () => {
    pushToCtaOmnitureCommon({
      ctaName: 'Forgot_Password',
      componentName: 'Landing_Page|Forgot_Password',
      pageName: 'landing_page',
    });

    navigateTo(routes.login.resetPassword);
  };

  useEffect(() => {
    localStorage.removeItem('last_hotelcode');
  });

  useEffect(() => {
    pushToOmniture({
      event: 'pageView',
      pageName: 'landing_page',
    });
  }, []);

  return (
    <div className="bg-color-white overflow-x-auto login">
      <div className="mx-auto text-center">
        <div className="bg-[url(/src/assets/modules/login/bg.jpg)] bg-no-repeat bg-top h-[700px]">
          <div className="item-width mx-auto pt-[24px]">
            <div className="flex space-between items-center">
              <img src={GoMMTLogo} alt="GoMMTLogo" />
              <div className="relative">
                <form
                  className="flex justify-end ip-container"
                  onSubmit={onLogin}
                >
                  <Input
                    placeholder="Username"
                    value={userName}
                    onChange={setUserName}
                    className="!w-[225px] mr-2"
                    data-testid="username"
                  />
                  <Input
                    placeholder="Password"
                    value={password}
                    onChange={setPassword}
                    className="!w-[225px] mr-2"
                    type="password"
                  />
                  <Button
                    className="!bg-[#f39c12] !px-4 !hover:bg-[#c87f0a]"
                    type="submit"
                  >
                    {STRINGS.SIGN_IN}
                  </Button>
                </form>
                <div className="text-end mt-1 mr-20 absolute left-[330px]">
                  <Button
                    variant="text"
                    className="!text-[15px] !text-common-white hover:!text-common-white !hover:underline"
                    onClick={onForgotPasswordClick}
                  >
                    {STRINGS.FORGOT_PASSWORD}
                  </Button>
                </div>
              </div>
            </div>
            <div className="text-center text-common-white font-bold mx-[10px] pt-[80px]">
              <div className="text-[40px] pb-3">{STRINGS.LIST_PROPERTY}</div>
              <div className="text-size-24 pb-14">{STRINGS.PROPERTY_TYPES}</div>
              <div className="flex items-center justify-center">
                <div className="mr-3 text-[#798698]">
                  .......................................................
                </div>
                <Typography variant="h3" themeColor="common.white" fb>
                  {STRINGS.FREE_LISTING}
                </Typography>
                <div className="ml-3 text-[#798698]">
                  .......................................................
                </div>
              </div>
              <div className="text-center mt-5">
                <img
                  src={Brandings}
                  alt="Brandings"
                  className="py-4 mb-5 px-16 align-center w-fit bg-color-white border rounded-[30px]"
                />
              </div>
              <Button
                // size="large"
                className="!px-7 !py-4 !text-size-18 !h-auto !bg-[#4b932a] !leading-[30px] hover:bg-[#4b932a]"
                onClick={moveToRegistrationBlock}
              >
                {STRINGS.GET_STARTED}
              </Button>
            </div>
          </div>
        </div>
        <img
          src={SignupFlow}
          alt="SignupFlow"
          className="w-[944px] my-[50px]"
        />
        <Typography variant="h2" className="py-5 !text-[32px]">
          {STRINGS.FEATURES}
        </Typography>
        <div className="item-width mx-auto text-center mt-[30px]">
          <div className="grid grid-cols-4 gap-y-[50px]	 auto-cols-auto">
            {STRINGS.ALL_FEATURES.map(feature => {
              return (
                <div className="text-center px-[15px]" key={feature.TITLE}>
                  <div>
                    {iconComponents[feature.TITLE]}
                    <div className="text-[#2c3e50] text-[19px] my-[10px]">
                      {feature.TITLE}
                    </div>
                    <p className="text-[#2c3e50] mb-[10px] text-[15px]">
                      {feature.DESC}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <Typography
            variant="h2"
            className="py-5 !text-[32px] !mt-[50px] text-[#2c3e50] !font-normal"
            themeColor="common.black"
          >
            {STRINGS.MOBILE_FIRST_LABEL}
          </Typography>
          <div className="mb-[10px] text-[#2c3e50] text-[23px]">
            {STRINGS.MOBILE_FIRST_DESC}
          </div>
          <AppDownloadButtons userName={userName} />
          <img src={Mobile} width={'100%'} alt="Mobile" className="mt-[50px]" />
        </div>
      </div>
      <div>
        <div
          className="border border-solid	 border-t border-b-0 border-[#cdcdcd] mt-[6px]"
          id="registration"
        />
        <Registration userName={userName} />
      </div>
    </div>
  );
};

export default Login;
