import { useState, Dispatch, SetStateAction } from 'react';
import { arrayMove } from './array-move';
import Draggable from './Draggable';

export interface DraggableItem {
  id: string | number;
}

export interface DraggableItems {
  items: Array<DraggableItem | any>;
  setItems: Dispatch<SetStateAction<Array<DraggableItem | any>>>;
  children?: React.ReactNode;
  component: (item: any) => JSX.Element;
  id?: string;
  callback?: (item: any, item1: any) => void;
  parentKey: string;
}

const SortableList = ({
  items = [],
  setItems,
  component,
  id = 'id',
  callback,
  parentKey,
}: DraggableItems) => {
  const [dragId, setDragId] = useState('');
  const onDrop = (ev, item) => {
    const ondragId = localStorage.getItem('ondragId');
    localStorage.removeItem('ondragId');
    if (ondragId && ondragId !== `${item[parentKey]}`) return;
    let currentPos = 0,
      droppedPos = 0;
    for (let i = 0; i < items.length; i++) {
      if (dragId === items[i][id]) {
        currentPos = i;
      }
      if (ev.currentTarget.id === `${items[i][id]}`) {
        droppedPos = i;
      }
    }
    //check if reorder can hapen
    const newItems = arrayMove([...items], currentPos, droppedPos);
    callback ? callback(newItems, items) : setItems(newItems);
  };

  const onDragStart = (ev, item) => {
    localStorage.setItem('ondragId', item[parentKey]);
    setDragId(item[id]);
  };

  const renderComponent = (componentJsx, item, index) => {
    const Component = componentJsx;
    return <Component item={item} index={index} />;
  };

  return (
    <>
      {items.map((item, index) => (
        <Draggable
          key={`${item[id]}-${index}`}
          id={item[id]}
          onDrop={onDrop}
          onDragStart={onDragStart}
          item={item}
        >
          {renderComponent(component, item, index)}
        </Draggable>
      ))}
    </>
  );
};

export default SortableList;
