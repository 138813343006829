/**
 * This file will contain all the individual event buses
 * initialized for different modules
 */

import mitt from 'mitt';

export const AddonEvents = mitt();
export const AppEvents = mitt();
export const InventoryEvents = mitt();
export const LogoutConfirmEvent = mitt();

export const EventNames = {
  CLEAR_INV_CHANGES: 'clearInvChanges',
  UPDATE_INV_MOD: 'updateInvMod',
  UPLOAD_MEDIA: 'uploadMedia',
  UPDATE_MEDIADATA: 'updateMediaData',
};

export const BookingEvent = mitt();
export const BOOKING_EVENT_NAME = {
  EMIT_DATE: 'emit_clicked_scrolled_date',
  DYNAMIC_FILTER_EVENT: 'emit_dynamic_filter_map',
  UPDATE_CALENDAR: 'update_calendar_date',
};

export const InvoicesUploadEvent = mitt();
export const INVOICES_UPLOAD_EVENT_NAME = {
  show_files_uploader_drawer: 'show_files_uploader_drawer',
  all_upload_complete: 'all_upload_complete',
};

export const PendingTasksEvent = mitt();
export const PENDING_TASKS_EVENT_NAME = {
  decrement_count: 'decrement_count',
};

export const BookingFilterEvent = mitt();
export const FILTER_EVENTS_NAME = {
  disable_search_btn_at_load: 'disable_search_btn_at_load',
  clear_input_search: 'clear_input_search',
};

//todo: add object to differentiate between events
export const GuestChatCountEvent = mitt();
export const GUEST_CHAT_COUNT_EVENTS_NAME = {
  group_chat_count: 'group_chat_count',
  property_chat_count: 'property_chat_count',
};

export const AnalyticsEvents = mitt();
export const ANALYTICS_EVENTS_NAME = {
  download_pdf: 'download_pdf',
  change_to_scroll_view: 'change_to_scroll_view',
  change_to_normal_view: 'change_to_normal_view',
};

export const DropdownInputEvents = mitt();
export const DROPDOWN_INPUT_EVENTS_NAME = {
  searchTextChange: 'searchTextChange',
};
