import React from 'react';
import { Tooltip as MuiTooltip } from '@mui/material';
import './Tooltip.scss';

type TooltipProps = Pick<
React.ComponentProps<typeof MuiTooltip>,
'placement'
> & {
  content: React.ReactNode;
  children: React.ReactElement<any, any>;
  className?: string;
  bgBlack?: boolean;
  open?: boolean;
};

export default function Tooltip(props: TooltipProps) {
  const {
    content,
    children,
    placement = 'right',
    className = '',
    bgBlack,
    open,
  } = props;

  const tooltipClass = {
    tooltip: `${className}`,
  };
  if (bgBlack) {
    tooltipClass.tooltip = `black-tooltip ${className} text-[10px] px-2 !py-1 `;
    //@ts-ignore
    tooltipClass.arrow = 'black-arrow-tooltip';
  }

  const extraProps = { ...(typeof open === 'boolean' && { open }) };

  return (
    <MuiTooltip
      title={content ?? ''}
      placement={placement}
      classes={tooltipClass}
      arrow
      {...extraProps}
    >
      {children}
    </MuiTooltip>
  );
}
