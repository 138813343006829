export const GET_DEALS_LIST_QUERY = `  query getPromotionsAndBenefits($endpointRequestData: EndpointRequestData, $rawEndpointRequestData: RawEndpointsRequestData) {
  fetchServices(endpointRequestData: $endpointRequestData) {
      success
      message
      errorCode
      requestId
      data
  }
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      rpcGetOffer {
        success
        message
        errorCode
        correlationKey
        data {
          offerDataList {
            offerCode
            dealName
            dealType
            offerPerformanceData {
              offerRoomNights
              offerRevenue
            }
            expiringIn
            offerValueDataList {
              offerValue
              offerType
              segment
              id
              isActive
              discountErrorMessage
            }
            isActive
            status
            modifiedOn
            createdOn
            checkoutdateend
            checkinblackoutdates
            bookingdateend
            checkindatestart
            bookingdatestart
            showModification
            campaignDetails {
              imageUrl
              campaignId
            }
            minNights
            night
            offerCategory
            parentGroupId
          }
          hotelPerformanceData {
            hotelRoomNights
            hotelRevenue
          }
          statusCountData {
            active
            expired
            inActive
          }
        }
      }
    }
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      rpcGetHotelDetailforMMTIdV2 {
        request_id
        hotel_details {
            blackEnrolledOn
        } 
      }
    }
}`;

export const GET_DEALS_META_DATA_QUERY = `query RpcGetOfferMetadata($rawEndpointRequestData: RawEndpointsRequestData,
  $NetAvailableAndBookedInventoryRequestData: RawEndpointsRequestData,
  $BookedInventoryShareRequestData: RawEndpointsRequestData,
  $CompsetBookingsOnCugRequestData: RawEndpointsRequestData
)
{
  grpc_rpc: rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
  rpcGetOfferMetadata {
      success
      message
      errorCode
      correlationKey
      data
      __typename
    }
    __typename
  }
  netAvailableAndBookedInventory: rawEndpoint(rawEndpointRequestData: $NetAvailableAndBookedInventoryRequestData){
    analyticsHistogram {
      modelType1 {       
          data
          message
          status
          status_code       
          __typename
        }
      __typename
      }   
    __typename
  }

  bookedInventoryShare: rawEndpoint(rawEndpointRequestData: $BookedInventoryShareRequestData){
    analyticsHistogram {
        modelType1 {       
        data
        message
        status
        status_code       
        __typename
      }
      __typename
    }   
    __typename
  }

  compsetBookingsOnCug: rawEndpoint(rawEndpointRequestData: $CompsetBookingsOnCugRequestData){
    analyticsHistogram {
        modelType1 {       
                    data
                    message
                    status
                    status_code       
                    __typename
                    }
                     __typename
                     }   
                __typename
                }
}
`;

export const CREATE_UPDATE_CUG_MUTATION = `mutation  RpcUpdateCug($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    rpcUpdateCug {
      success
      mmtId
      message
      errorCode
      correlationKey
      data  {
        success
        message
        errorCode
        offerData
      }
    }
  }
}`;

export const CREATE_CUG_MUTATION = `mutation RpcUpdateCug($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    rpcUpdateCug {
      success
      mmtId
      message
      errorCode
      correlationKey
       data {
        success
        message
        errorCode
        offerData {
          offerCode
          offerName
          bookingDateStart
          bookingDateEnd
          checkinDateStart
          checkoutDateEnd
          checkinBlackoutDates
          offerValueList {
            isActive
            offerValue
            segment
          }
        }
      }
    }
  }
}`;

export const CREATE_UPDATE_COUPON = `mutation RpcUpdateCoupon($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
      rpcUpdateCoupon {
          success
          mmtId
          message
          errorCode
          correlationKey
          data {
            success
            message
            errorCode
            offerData {
              offerCode
              offerName
              bookingDateStart
              bookingDateEnd
              checkinDateStart
              checkoutDateEnd
              checkinBlackoutDates
              offerValue{ 
                value
              }              
            }
          }
        }
      }
    }`;
