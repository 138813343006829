import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { Typography } from '../index';
import { Tooltip } from 'components/new-core';
import {
  classNames,
  getDataPropsFromRest,
  isNullOrUndefined,
} from 'utilities/Utils';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import type { PaletteColorsType } from 'components/mui-theme';

type FormElementProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  errorMsg?: string | FieldError | Merge<FieldError, FieldErrorsImpl<unknown>>;
  hintText?: string;
  className?: string;
  isRequired?: boolean;
  BoxProps?: BoxProps;
  showEmptyErrorBox?: boolean;
  helpText?: string;
  leftElem?: React.ReactNode;
  fb?: boolean;
  titleFb?: boolean;
  subTitleFb?: boolean;
  titleColor?: PaletteColorsType;
  titleClass?: string;
  subTitleClass?: string;
  errorClass?: string;
  isOptional?: boolean;
};

export default function FormElement(props: FormElementProps) {
  const {
    title,
    subTitle,
    children,
    errorMsg,
    className,
    BoxProps: BoxParams,
    isRequired = false,
    showEmptyErrorBox = false,
    helpText = '',
    hintText,
    leftElem = '',
    fb = true,
    titleFb = false,
    subTitleFb = false,
    titleColor = 'text.primary',
    titleClass,
    subTitleClass = '',
    errorClass = '',
    isOptional = false,
    ...rest
  } = props;

  const dataTestIdProps = getDataPropsFromRest(rest);

  return (
    <Box
      {...BoxParams}
      display="flex"
      flexDirection="column"
      className={className}
      {...dataTestIdProps}
    >
      {title && (
        <Box className={`mb-1 ${titleClass || ''}`}>
          <Typography
            variant={titleFb ? 'subHeadingMedium' : 'body1'}
            inline
            themeColor={fb ? titleColor : 'text.secondary'}
          >
            {title}
            {isOptional && !isRequired && (
              <Typography variant="subtitle1" inline>
                {' (Optional)'}
              </Typography>
            )}
            {isRequired && (
              <Typography themeColor="error.main" inline>
                {' '}
                *
              </Typography>
            )}
            {helpText.length > 0 && (
              <Tooltip
                content={helpText}
                className="text-common-white bg-color-lightDefault"
              >
                <span className="ml-1">
                  <HelpOutlineIcon className="w-3 h-3 text-text-secondary" />
                </span>
              </Tooltip>
            )}
          </Typography>
          {subTitle && (
            <Typography
              variant="sectionHeading"
              themeColor={subTitleFb ? 'text.secondary' : 'text.primary'}
              className={`capitalize ${subTitleClass || ''}`}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
      )}
      <Box display="flex">{children}</Box>
      {hintText && (
        <Typography
          variant="subtitle1"
          themeColor="text.primary"
          className="mt-1"
        >
          {hintText}
        </Typography>
      )}
      {!isNullOrUndefined(errorMsg) && (
        <Box
          color="error.main"
          mt={1}
          display="flex"
          className={classNames('form-error', errorClass)}
        >
          {!!leftElem && <div>{leftElem}</div>}
          <Typography
            variant="subtitle1"
            color="inherit"
            themeColor="error.main"
          >
            {errorMsg as string}
          </Typography>
        </Box>
      )}
      {!errorMsg && showEmptyErrorBox && (
        <Box mt={1} display="flex" height={16} />
      )}
    </Box>
  );
}
