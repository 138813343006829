import { forwardRef } from 'react';
import { DropdownProps } from './DropdownMain.types';
import DropdownHierarchical from './Variants/DropdownHierarchical';
import DropdownInline from './Variants/DropdownInline';
import DropdownMultiSelect from './Variants/DropdownMultiSelect';
import DropdownSearchBase from './Variants/DropdownSearchBase';
import { DropdownBase } from './Variants/DropdownBase';

const DropdownMain = forwardRef((props: DropdownProps, ref) => {
  const { variant } = props;

  switch (variant) {
    case 'selectHierarchical':
      return <DropdownHierarchical {...props} ref={ref} />;
    case 'inlineDropdown':
      return <DropdownInline {...props} ref={ref} />;
    case 'searchableSingleSelect':
      return <DropdownSearchBase {...props} ref={ref} />;
    case 'multiSelect':
      return <DropdownMultiSelect menuHeight="49vh" {...props} ref={ref} />;
    case 'multiSelectGrouped':
      return <DropdownMultiSelect {...props} ref={ref} />;
    default:
      return <DropdownBase {...props} ref={ref} />;
  }
});

export default DropdownMain;
