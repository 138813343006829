import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Divider } from '@mui/material';
import { HotelContext } from '../../utilities/Context';
import { getActiveRoomRateplan } from '../../modules/inventory/utils';
import { Checkbox, Typography } from 'components/latest-core';

const RatePlanCheckbox = props => {
  const {
    rateplan,
    roomRpList,
    onChange,
    disabled,
    isNewUI = false,
    setIsRoomChecked = () => {},
  } = props;
  const { rateplancode, roomCode } = rateplan;
  const isRatePlanChecked = roomRpList[roomCode]?.[rateplancode] ?? false;
  const handleRpClick = val => {
    if (val === false) {
      setIsRoomChecked(false);
    }
    onChange({
      ...roomRpList,
      [roomCode]: {
        ...roomRpList[roomCode],
        [rateplancode]: val,
      },
    });
  };

  if (rateplan.parent_id) {
    return null;
  }
  return (
    <>
      {isNewUI ? (
        <Checkbox
          variant="thin"
          checked={isRatePlanChecked}
          disabled={disabled}
          onChange={handleRpClick}
        >
          <Typography>{rateplan.rateplanname}</Typography>
        </Checkbox>
      ) : (
        <span>
          <Checkbox
            checked={isRatePlanChecked}
            disabled={disabled}
            onChange={handleRpClick}
          />
          <span>{rateplan.rateplanname}</span>
        </span>
      )}
    </>
  );
};

const RoomCheckbox = props => {
  const { room, activeRateplans, roomRpList, onChange, disabled, isNewUI } =
    props;
  const rateplans =
    activeRateplans[room.roomtypecode]?.filter(rp => !rp.isHotelTravelRP) ?? [];
  const currentRoom = roomRpList[room.roomtypecode];

  const selectedRpLength = useMemo(() => {
    return Object.keys(roomRpList[room.roomtypecode]).filter(
      rpCode =>
        roomRpList[room.roomtypecode][rpCode] &&
        rpCode !== 'roomLevel' &&
        rpCode !== 'isRoomChecked',
    ).length;
  }, [roomRpList]);

  const [isRoomChecked, setIsRoomChecked] = useState(false);

  useEffect(() => {
    const roomRatePlans = Object.keys(roomRpList[room.roomtypecode]).filter(
      rpCode => rpCode !== 'roomLevel' && rpCode !== 'isRoomChecked',
    );
    const isRoomAllChecked = selectedRpLength === roomRatePlans.length;
    setIsRoomChecked(isRoomAllChecked);
  }, [selectedRpLength, roomRpList]);

  const handleRoomClick = val => {
    const updatedRoomObj = {
      roomLevel: true,
    };
    currentRoom.isRoomChecked = val;
    Object.keys(currentRoom).forEach(rpCode => {
      updatedRoomObj[rpCode] = val;
    });
    onChange({
      ...roomRpList,
      [room.roomtypecode]: updatedRoomObj,
    });
    setIsRoomChecked(val);
  };

  return (
    <>
      {isNewUI ? (
        <>
          {rateplans.length > 0 && (
            <>
              <Checkbox
                variant="thin"
                checked={isRoomChecked}
                disabled={disabled}
                onChange={handleRoomClick}
              >
                <Typography>
                  {room.roomtypename}{' '}
                  <Typography
                    themeColor="text.secondary"
                    variant="subtitle1"
                    inline
                  >{`(${selectedRpLength}/${rateplans.length})`}</Typography>
                </Typography>
              </Checkbox>
              <div className="ml-7 grid grid-cols-3">
                {rateplans?.map(rateplan => (
                  <RatePlanCheckbox
                    key={rateplan.rateplancode}
                    rateplan={rateplan}
                    disabled={disabled}
                    roomRpList={roomRpList}
                    onChange={onChange}
                    isNewUI
                    setIsRoomChecked={setIsRoomChecked}
                  />
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="sr-width33 marginB20">
          <div>
            <Checkbox
              checked={isRoomChecked}
              disabled={disabled}
              onChange={handleRoomClick}
            />
            <span>{room.roomtypename}</span>
          </div>
          <div className="sr-flex-col padL25">
            {rateplans?.map(rateplan => (
              <RatePlanCheckbox
                rateplan={rateplan}
                disabled={disabled}
                roomRpList={roomRpList}
                onChange={onChange}
                setIsRoomChecked={setIsRoomChecked}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const RoomRateplanSelectable = props => {
  const {
    roomRpsList,
    setRoomRpsList,
    activeRooms,
    activeRateplans,
    disabled = false,
    isNewUI = false,
  } = props;

  return (
    <div
      className={
        isNewUI ? '' : 'flex marginTB15 pad15 flex-wrap bg-color-light'
      }
    >
      {activeRooms.map((room, index) => {
        const rateplans = activeRateplans[room.roomtypecode] ?? [];
        if (rateplans.length === 0) return null;
        return (
          <>
            <RoomCheckbox
              key={room.roomtypecode}
              room={room}
              activeRateplans={activeRateplans}
              roomRpList={roomRpsList}
              disabled={disabled}
              onChange={setRoomRpsList}
              isNewUI={isNewUI}
            />
            {activeRooms.length - 1 !== index && <Divider className="my-4" />}
          </>
        );
      })}
    </div>
  );
};

export function useRoomRpSelectable(selectedRoomRpList) {
  const { roomRatePlan } = useContext(HotelContext);
  const { activeRooms, activeRateplans } = useMemo(
    () => getActiveRoomRateplan(roomRatePlan, true),
    [roomRatePlan],
  );

  const [roomRpsList, setRoomRpsList] = useState(() => {
    const toReturn = {};
    activeRooms.forEach(room => {
      const roomCode = room.roomtypecode;
      const rateplans =
        activeRateplans[roomCode]?.filter(rp => !rp.isHotelTravelRP) ?? [];

      if (selectedRoomRpList.rooms[roomCode]) {
        const roomObj = {};
        rateplans.forEach(rp => {
          roomObj[rp.rateplancode] = true;
        });
        toReturn[roomCode] = roomObj;
      } else {
        toReturn[roomCode] = {};
        rateplans.forEach(rp => {
          const rpCode = rp.rateplancode;
          toReturn[roomCode][rpCode] =
            selectedRoomRpList.rateplans[rpCode] ?? false;
        });
      }
    });
    return toReturn;
  });

  return {
    roomRpsList,
    setRoomRpsList,
    activeRooms,
    activeRateplans,
  };
}

export function usePackagesRoomRpSelectable(
  roomAndRatePlan,
  selectedRpList,
  isEdit,
) {
  const selectedRoomRpList = roomAndRatePlan.reduce((roomAcc, room) => {
    const rateplans = room.ratePlans.reduce((acc, rp) => {
      return {
        ...acc,
        [rp.ratePlanCode]: isEdit
          ? !!selectedRpList.includes(rp.ratePlanCode)
          : true,
      };
    }, {});
    return { ...roomAcc, [room.ingoRoomId]: rateplans };
  }, {});
  const activeRooms = roomAndRatePlan.map(room => ({
    roomtypecode: room.ingoRoomId,
    roomtypename: room.roomName,
  }));
  const activeRateplans = roomAndRatePlan.reduce((roomAcc, room) => {
    const rateplan = room.ratePlans.map(rp => ({
      roomCode: room.ingoRoomId,
      roomName: room.roomName,
      rateplancode: rp.ratePlanCode,
      rateplanname: rp.ratePlanName,
    }));
    return {
      ...roomAcc,
      [room.ingoRoomId]: rateplan,
    };
  }, {});

  const [roomRpsList, setRoomRpsList] = useState(selectedRoomRpList);

  return {
    roomRpsList,
    setRoomRpsList,
    activeRooms,
    activeRateplans,
  };
}

export default RoomRateplanSelectable;
