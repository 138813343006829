import makeStyles from '@mui/styles/makeStyles';

export const useCardStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    background: theme.palette.common.white,
  },
  persuasionCard: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    background: theme.palette.common.white,
  },
}));

export const useThemeCorrection = makeStyles({
  wrapper: {
    '& [class*=" MuiOutlinedInput-root-"]': {
      '&.small': {
        fontSize: '0.75rem',
        '& input': {
          padding: '4px 10px',
          height: '22px',
        },
      },
      '&.medium': {
        fontSize: '0.875rem',
        '& input': {
          padding: '6px 10px',
          height: '22px',
        },
      },
      '&.large': {
        fontSize: '1rem',
        '& input': {
          padding: '8px 10px',
          height: '22px',
        },
      },
    },
    '& [class*=" MuiButton-root-"]': {
      padding: '6px 16px',
      fontSize: '0.75rem',
      borderRadius: '4px',
      '&.zero-padding': {
        margin: '0 -8px',
        padding: '2px 8px',
      },
      '& .MuiButton-label': {
        height: '22px',
      },
      '&.MuiButton-sizeSmall': {
        padding: '4px 12px',
        fontSize: '0.75rem',
        '&.zero-padding': {
          marginLeft: '-6px',
          padding: '2px 6px',
        },
      },
      '&.MuiButton-sizeLarge': {
        padding: '8px 20px',
        fontSize: '0.875rem',
        '&.zero-padding': {
          marginLeft: '-10px',
          padding: '2px 10px',
        },
      },
    },
    '& [class*="MuiFormGroup-root"]': {
      '&.boxed-radio': {
        flexDirection: 'row',
        '& .MuiButtonBase-root': {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
        '& .MuiRadio-root input': {
          '&:hover': {
            '~ .boxed-label': {
              background: 'rgba(183, 183, 183, 0.15)',
              color: '#212121',
            },
          },
          '&:disabled': {
            '~ .boxed-label': {
              background: 'rgba(206, 217, 224, 0.15)',
            },
          },
        },
        '& .Mui-checked input': {
          '&:hover': {
            '~ .checked-boxed-label': {
              color: '#fe7d32',
              border: '1px solid #fe7d32',
              background: 'rgba(254, 125, 50, 0.15)',
            },
          },
        },
        '& .boxed-label': {
          borderRadius: '4px',
          backgroundColor: '#fff',
          border: 'solid 1px #b7b7b7',
          color: '#b7b7b7',
          fontSize: '12px',
          height: '38px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '85px',
          padding: '0 15px',
        },
        '& .checked-boxed-label': {
          border: 'solid 1px#fd7e32',
          color: '#fd7e32',
        },
      },
    },
  },
});

export const useDividerStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    padding: theme.spacing(0),
    margin: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dividerWithMargin: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const useNewAlertStyles = makeStyles(theme => ({
  primary: {
    width: 'inherit',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    background: theme.palette.backgrounds.primary,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  secondary: {
    width: 'inherit',
    background: theme.palette.backgrounds.dark,
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },
  warning: {
    width: 'inherit',
    background: theme.palette.warning.main,
    borderRadius: '0px 0px 8px 8px',
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  info: {
    width: 'inherit',
    background: theme.palette.primary.lighter,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '4px',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  warningLight: {
    width: 'inherit',
    background: theme.palette.warning.lighter,
    border: `1px solid ${theme.palette.warning.light}`,
    '& path': {
      fill: theme.palette.warning.main,
    },
  },
}));

export const useAlertStyles = makeStyles(theme => ({
  alertContainer: {
    padding: '8px 12px',
    borderRadius: '4px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& *': {
      display: 'inline',
    },
    '& > .alertIcon': {
      minWidth: '20px',
      minHeight: '20px',
    },
  },
  primary: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    background: theme.palette.backgrounds.primary,
    color: theme.palette.primary.main,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  warning: {
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: '4px',
    background: theme.palette.backgrounds.warning,
    color: theme.palette.gray.main,
    '& path': {
      fill: theme.palette.warning.main,
    },
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '4px',
    background: theme.palette.backgrounds.error,
    color: theme.palette.common.black,
    '& path': {
      fill: theme.palette.error.main,
    },
  },
  info: {
    background: 'rgba(194,199,236,.2)',
    borderRadius: '4px',
    padding: '6px 12px',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}));

export const useTagStyles = makeStyles(theme => ({
  tagContainer: {
    height: '19px',
    background: theme.palette.common.white,
    borderRadius: '4px',
    padding: '4px 6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '11px',
  },
  primary: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
  warning: {
    border: `1px solid ${theme.palette.warning.main}`,
    color: theme.palette.warning.main,
  },
  success: {
    border: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
  },
  white: {
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.common.black,
  },
  black: {
    border: `1px solid ${theme.palette.common.black}`,
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  blue: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
}));

export const useMaxWidth = makeStyles(() => ({
  fullWidth: {
    maxWidth: '100%',
  },
  width300: {
    maxWidth: '300px',
  },
  width400: {
    maxWidth: '400px',
  },
  width500: {
    maxWidth: '500px',
  },
  // Added only which are needed
}));
