import { makeStyles } from '@mui/styles';

export const useTextAreaStyles = makeStyles(theme => ({
  smallPadding: {
    '& > div': {
      padding: '10px 12px',
    },
    '& > div > textarea': {
      lineHeight: '22px',
    },
  },
  helperTextCharCount: {
    margin: '3px 0 0',
    textAlign: 'right',
  },
  helperTextError: {
    margin: '3px 0 0',
    textAlign: 'left',
  },
  errContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    marginRight: theme.spacing(1),
  },
}));
