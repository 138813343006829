import { useRef } from 'react';
import { DayProps, useActiveModifiers, useDayRender } from 'react-day-picker';
import { classNames } from 'utilities/Utils';

const modifiersStyles = {
  day: {
    backgroundColor: 'transparent',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    color: '#404040',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    letterSpacing: '0.02em',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  today: {
    border: '1px solid #73767C',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  selected: {
    color: 'white',
    backgroundColor: '#3543BF',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  todaySelected: {
    border: '1px solid white',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    color: 'white',
    backgroundColor: '#3543BF',
  },
  rangeMiddle: {
    backgroundColor: '#EFF1FF',
  },
  weekStart: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  weekEnd: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
};

const dayBoxStyles = {
  rangeStart: {
    backgroundClip: 'content-box',
    borderTopLeftRadius: '100%',
    borderBottomLeftRadius: '100%',
    backgroundColor: '#EFF1FF',
  },
  rangeEnd: {
    backgroundClip: 'content-box',
    borderTopRightRadius: '100%',
    borderBottomRightRadius: '100%',
    backgroundColor: '#EFF1FF',
  },
  rangeMiddle: {
    backgroundClip: 'content-box',
    borderRadius: '0%',
    backgroundColor: '#EFF1FF',
  },
};

export function CustomDay(props: DayProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);
  const modifiers = useActiveModifiers(props.date);

  const customDayStyles = {
    ...(dayRender.buttonProps.style ?? {}),
    ...modifiersStyles.day,
    ...(modifiers.rangeMiddle ? modifiersStyles.rangeMiddle : {}),
    ...(modifiers.weekStart && modifiers.rangeMiddle
      ? modifiersStyles.weekStart
      : {}),
    ...(modifiers.weekEnd && modifiers.rangeMiddle
      ? modifiersStyles.weekEnd
      : {}),
    ...(modifiers.today ? modifiersStyles.today : {}),
    ...(modifiers.selected || modifiers.rangeStart || modifiers.rangeEnd
      ? modifiersStyles.selected
      : {}),
    ...(modifiers.today &&
    (modifiers.rangeStart || modifiers.rangeEnd || modifiers.selected)
      ? modifiersStyles.todaySelected
      : {}),
  };

  const customDayBoxStyles = {
    ...(modifiers.rangeMiddle && modifiers.today
      ? dayBoxStyles.rangeMiddle
      : {}),
    ...(modifiers.rangeStart || (modifiers.weekStart && modifiers.rangeMiddle)
      ? dayBoxStyles.rangeStart
      : {}),
    ...(modifiers.rangeEnd || (modifiers.weekEnd && modifiers.rangeMiddle)
      ? dayBoxStyles.rangeEnd
      : {}),
  };

  if (dayRender.isHidden) {
    return <div role="gridcell"></div>;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }
  return (
    <div
      className={
        !modifiers.disabled &&
        !modifiers.outside &&
        'hover:bg-[#EFF1FF] hover:rounded-full'
      }
      data-test-id={modifiers.disabled ? 'disabled-day' : 'active-day'}
    >
      <div style={customDayBoxStyles}>
        <button
          {...dayRender.buttonProps}
          style={customDayStyles}
          className={classNames(dayRender.buttonProps.className, 'relative')}
        >
          {modifiers.blocked && (
            <div
              className="absolute bg-[#73767C] left-[18px] bottom-0 top-2 h-[22px] w-[1px]"
              style={{
                transform: 'rotate(45deg)',
              }}
            />
          )}
          {props.date.getDate()}
          {modifiers.today && (
            <div
              className={classNames(
                modifiers.selected || modifiers.rangeStart || modifiers.rangeEnd
                  ? 'bg-[#fff]'
                  : 'bg-[#222EA7]',
                'w-1 h-1 rounded',
              )}
            />
          )}
        </button>
      </div>
    </div>
  );
}
