export const POINT_OF_INTEREST = 'point_of_interest';
export const NEARBY_PLACES_CONSTANTS = {
  ESTABLISHMENT: 'establishment',
  TRAIN_STATION: 'train_station',
  TRANSIT_STATION: 'transit_station',
  BUS_STATION: 'bus_station',
  AIRPORT: 'airport',
  RAIL: 'rail',
};

export const MAP_DEFAULT_RADIUS = 50000;
