import {
  FormElement,
  ControlledCheckbox,
  ControlledCalendar,
} from 'components/latest-core';
import { StayDateProps } from '../data/types/Schema';

export const StayDate = ({
  bookingStartDate,
  checkinStartDate,
  checkinNoEndDate,
  LOS_STRINGS,
  control,
}: StayDateProps) => {
  return (
    <div className="rounded bg-color-light my-4 border-primary">
      <FormElement title={LOS_STRINGS.ADVANCE_SETTINGS.CHECK_IN_START_DATE}>
        <ControlledCalendar
          name="checkinStartDate"
          control={control}
          disabled={!bookingStartDate}
          disabledBefore={bookingStartDate}
          inputClasses="!w-[250px]"
          variant="single"
          type="input"
        />
      </FormElement>
      <div className="mt-4">
        <ControlledCheckbox
          name="checkinNoEndDate"
          variant="thin"
          control={control}
          disabled={!checkinStartDate}
          className="!mr-1"
        >
          {LOS_STRINGS.ADVANCE_SETTINGS.HAS_CHECKIN_END_DATE}
        </ControlledCheckbox>
      </div>
      {checkinNoEndDate && (
        <FormElement
          title={LOS_STRINGS.ADVANCE_SETTINGS.CHECK_IN_END_DATE}
          className="mt-2"
        >
          <ControlledCalendar
            name="checkinEndDate"
            control={control}
            disabledBefore={checkinStartDate}
            variant="single"
            type="input"
            inputClasses="!w-[250px]"
          />
        </FormElement>
      )}
    </div>
  );
};
