import { Checkbox, ListItemText, MenuItem } from '@mui/material';

const ListOptions = props => {
  const { labelField, valueField, isSelected, ...rest } = props;
  return (
    <MenuItem
      {...rest}
      key={labelField}
      value={valueField}
      autoFocus={false}
      classes={{
        root: 'pl-2 ml-2 mr-1',
      }}
      disableRipple
      disableTouchRipple
    >
      <Checkbox checked={isSelected} className="p-0" />
      <ListItemText
        primary={labelField}
        className="pl-2 text-text-primary"
        {...(isSelected ? { 'aria-selected': 'true' } : {})}
      />
    </MenuItem>
  );
};

export default ListOptions;
