import { DayPickerDefaultProps } from 'react-day-picker';
import { isDateSame } from 'utilities/DateUtils';
import { CalendarBasic } from './CalendarBasic';
import { Popover } from '@mui/material';
import { useEffect } from 'react';
import { RangePickerMenu } from '../components/RangePickerMenu';
import { Footer } from '../components/Footer';
import { CalendarBaseProps, InvalidDates } from '../Calendar.types';

const selectAndNavigateDate = ({ selectedDays, calendarProps }) => {
  const { variant, setSelectedDays, setMonth } = calendarProps;
  if (variant === 'single') {
    setSelectedDays(selectedDays);
    setMonth(selectedDays ?? new Date());
  } else if (variant === 'multiple') {
    setSelectedDays(selectedDays);
    setMonth(selectedDays[0] ?? new Date());
  } else if (variant === 'range') {
    calendarProps.setRange(selectedDays);
    setMonth(selectedDays.from ?? new Date());
  }
};

export const CalendarBase = (props: CalendarBaseProps) => {
  const {
    modifiers = {},
    blockedDates = [],
    anchorEl,
    calendarProps,
    popoverState,
    numberOfMonths = 1,
    showDatePickerMenu = false,
    primaryCta,
    secondaryCta,
    fromYear,
    toYear,
    skipHideOnSelect = false,
    className = '',
    captionLabel,
  } = props;

  const today = new Date();
  const { variant, handleDayClick, initialMonth, month, setMonth } =
    calendarProps;
  const { isCalendarOpen, hideCalendar } = popoverState;
  const extraProps: Pick<
  DayPickerDefaultProps,
  'modifiers' | 'disabled' | 'selected'
  > = {};

  if (variant === 'range') {
    const { selectedDays, disabledAfter, disabledBefore } = calendarProps;
    const { from, to } = selectedDays;
    extraProps.modifiers = {
      ...modifiers,
      blocked: blockedDates,
      rangeStart: from,
      rangeEnd: to,
      rangeMiddle:
        from && to && !isDateSame(from, to)
          ? {
            before: to,
            after: from,
          }
          : [],
    };
    extraProps.disabled = [
      { after: disabledAfter, before: disabledBefore },
      props.disabledBefore && { before: props.disabledBefore },
      props.disabledAfter && { after: props.disabledAfter },
      ...blockedDates,
    ];
  } else {
    extraProps.modifiers = {
      blocked: blockedDates,
    };
    extraProps.selected = calendarProps.selectedDays;
    extraProps.disabled = [
      {
        after: props.disabledAfter,
        before: props.disabledBefore ?? today,
      },
      ...blockedDates,
    ];
  }
  useEffect(() => {
    setMonth(initialMonth);
  }, [isCalendarOpen]);

  useEffect(() => {
    const hasSelectedDays =
      (variant === 'single' && calendarProps.selectedDays) ||
      (variant === 'multiple' && calendarProps.selectedDays.length > 0) ||
      (variant === 'range' &&
        calendarProps.selectedDays.from &&
        calendarProps.selectedDays.to);
    if (!skipHideOnSelect && hasSelectedDays) {
      hideCalendar();
    }
  }, [calendarProps.selectedDays]);

  return (
    <Popover
      open={isCalendarOpen}
      anchorEl={anchorEl.current}
      onClose={_ => hideCalendar()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transitionDuration={'auto'}
      classes={{
        paper: 'rounded-xl border-solid border-[1px] border-divider',
      }}
    >
      <div className="flex">
        {showDatePickerMenu && variant === 'range' && (
          <RangePickerMenu
            calendarProps={calendarProps}
            optionsToShow={props.datePickerMenuOptions}
            invalidDates={extraProps.disabled as InvalidDates}
            selectAndNavigateDate={selectAndNavigateDate}
          />
        )}
        <div className="flex flex-col">
          <CalendarBasic
            className={className}
            handleDayClick={handleDayClick}
            initialMonth={initialMonth}
            modifiers={modifiers}
            numberOfMonths={numberOfMonths}
            month={month}
            setMonth={setMonth}
            fromYear={fromYear}
            toYear={toYear}
            captionLabel={captionLabel}
            data-test-id={props['data-test-id']}
            {...extraProps}
          />
          {(primaryCta || secondaryCta) && (
            <Footer
              primaryCta={primaryCta}
              secondaryCta={secondaryCta}
              hideCalendar={hideCalendar}
              calendarProps={calendarProps}
              selectAndNavigateDate={selectAndNavigateDate}
              isMultiCalendar={numberOfMonths > 1}
            />
          )}
        </div>
      </div>
    </Popover>
  );
};
