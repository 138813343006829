export const FetchRoomRatePlans = /* GraphQL */ `
  query FetchRoomRatePlans($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      roomRatePlansResponse {
        ingoHotelId
        rooms {
          ingoRoomId
          roomName
          ratePlans {
            ratePlanCode
            ratePlanName
          }
        }
        correlationId
        success
        error {
          errors {
            errorCode
            field
            message
          }
        }
      }
      __typename
    }
  }
`;

export const FetchTemplates = /* GraphQL */ `
  query RpcFetchServiceTemplates(
    $rawEndpointRequestData: RawEndpointsRequestData
  ) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
      fetchServiceTemplates {
        templateList {
          serviceTemplateData {
            serviceId
            leafCategoryName
            priorityOrder
            subCategoryId
            subCategoryName
            isMandatoryForPackage
            customCategoryTags
            labelOrder
            serviceTemplateJson {
              attributeKey
              settingKey
              required
              label
              labelCategory
              attributeId
              renderType
              maxValue
              minValue
              attributeList {
                id
                label
              }
              isSetting
            }
          }
        }
        pagination {
          totalItems
          count
          currentPage
          hasNext
          nextPage
        }
        error {
          errors {
            errorCode
            field
            message
          }
        }
        ignoreFields
        correlationId
        success
      }
    }
  }
`;

const FetchServiceFragment = `
    fetchServices {
            success
            ratePlanName
            roomName
            correlationId
            servicesResponse {
                services {
                    id
                    objectId
                    condition {
                        minLengthOfStay
                        checkinDate
                        checkoutDate
                        bookingStartDate
                        bookingEndDate
                        blackOutDates
                        segment
                    }
                    serviceValue
                    selectedAttributes
                    offerType
                    status
                    metadata {
                        leafCategory
                        description
                        currency
                        priority
                        details
                        name
                        category
                    }
                    channelManagerId
                    contentType
                    leafCategory {
                        serviceId
                        subCategoryId
                    }
                    chargeType
                    subjectToAvailability
                    isEligibleForPackage
                    priorityOrder
                }
            }
            pagination {
                totalItems
                count
                currentPage
                hasNext
                nextPage
            }
            validatePackageResponse {
                minimumInclusions
                minimumCategories
                matchingInclusions
                matchingCategories
                isValid
                errorMappings
            }
            ignoreFields
            error {
                errors {
                    errorCode
                    field
                    message
                }
            }
        }
`;

export const FetchServices = /* GraphQL */ `
  query RpcFetchServices($rawEndpointRequestData: RawEndpointsRequestData) {
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
        ${FetchServiceFragment}
    }
  }
`;

export const ValidationMutation = /* GraphQL */ `
  mutation RPCValidatePackageRequest(
    $rawEndpointsRequestData: RawEndpointsRequestData
  ) {
    rawEndpoint(endpointRequestData: $rawEndpointsRequestData) {
        ${FetchServiceFragment}
    }
  }
`;

export const SAVE_PACKAGE = /* GraphQL */ `
  mutation SavePackage($endpointRequestData: EndpointRequestData) {
    rpcSavePackage(endpointRequestData: $endpointRequestData) {
      packageCode
      success
      correlationId
      error {
        errors {
          field
          message
        }
      }
      errorMappings
      __typename
    }
  }
`;

export const UPDATE_ACTIVITY_TRACKER_QUERY = `mutation ActivityTrackerUpdate($endpointRequestData: RawEndpointsRequestData) {  
  rawEndpoint(endpointRequestData: $endpointRequestData) {
    activityTrackerUpdate {
       modelType1 {
       message
       success
        }
      }
    }
  }`;

export const FETCH_LOS_PACKAGE_DETAILS = `query FetchPackageMainQuery($getPackageRequestData: RawEndpointsRequestData, $roomRateplanRequestData: RawEndpointsRequestData, $fetchServiceRequest: RawEndpointsRequestData) {
  roomRateplanData: rawEndpoint(rawEndpointRequestData: $roomRateplanRequestData) {
  roomRatePlansResponse {
      ingoHotelId
      rooms {
      ingoRoomId
      roomName
      ratePlans {
          ratePlanCode
          ratePlanName
      }
      }
      correlationId
      success
      error {
      errors {
          errorCode
          field
          message
      }
      }
  }
  }
  rpcGetPackageRequest: rawEndpoint(rawEndpointRequestData: $getPackageRequestData) {
      rpcGetPackageResponse {
      data {
          minLosNode
          packageId
          hotelId
          rateplanIds
          name
          type
          packageValidations {
              minMandatoryInclusionCount
              eligibleInclusionCount
          }
          applicabilityDetails {
                  bookingStartDate
          bookingEndDate
          checkinDate
          checkoutDate
          blackoutDates
          }
          benefits {
              id
          }
          packageAttributes {
              minLengthOfStay
          }
      }
      error {
          errorCode
          message
      }
      }
  }
  fetchServices: rawEndpoint(rawEndpointRequestData: $fetchServiceRequest) {
      fetchServices {
      success
    ratePlanName
    roomName
    correlationId
    servicesResponse {
      services {
        id
        objectId
        condition {
          minLengthOfStay
          checkinDate
          checkoutDate
          bookingStartDate
          bookingEndDate
          blackOutDates
          segment
        }
        serviceValue
        selectedAttributes
        offerType
        status
        metadata {
          leafCategory
          description
          currency
          priority
          details
          name
          category
        }
        channelManagerId
        contentType
        leafCategory {
          serviceId
          subCategoryId
        }
        chargeType
        subjectToAvailability
        isEligibleForPackage
        priorityOrder
      }
    }
    pagination {
      totalItems
      count
      currentPage
      hasNext
      nextPage
    }
    validatePackageResponse {
      minimumInclusions
      minimumCategories
      matchingInclusions
      matchingCategories
      isValid
      errorMappings
    }
    ignoreFields
    error {
      errors {
        errorCode
        field
        message
      }
    }    
}}
}`;

export const LOS_PACKAGE_SERVICE_TEMPLATE_QUERY = `query RpcFetchServiceTemplates($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    fetchServiceTemplatesInclusion {
      templateList {
        serviceTemplateData {
          serviceId
          leafCategoryName
          priorityOrder
          subCategoryId
          subCategoryName
          isMandatoryForPackage
          customCategoryTags
          labelOrder
          serviceTemplateJson {
            attributeKey
            settingKey
            required
            label
            labelCategory
            attributeId
            renderType
            maxValue
            minValue
            attributeList {
              id
              label
            }
            isSetting
          }
        }
      }
      error {
        errors {
          errorCode
          field
          message
        }
      }
      ignoreFields
      correlationId
      success
    }
  }
}
`;
export const VERIFY_LOS_PACKAGE = `
  mutation VerifyPackage($rawEndpointRequestData: RawEndpointsRequestData) {
    rpcVerifyPackageRequest: rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
      rpcVerifyPackageResponse {
        data {
          minLosNode
          packageId
          hotelId
          rateplanIds
          name
          type
          packageValidations {
            minMandatoryInclusionCount
            eligibleInclusionCount
            errorMappings
          }
          applicabilityDetails {
            bookingStartDate
            bookingEndDate
            checkinDate
            checkoutDate
            blackoutDates
          }
          benefits {
            id
            selectedAttributes
            status
            offerType
            chargeType
            channelManagerId
            priorityOrder : priority
            leafCategory {
              serviceId
              subCategoryId
            }
            metadata{
              name
              description
            }
          }
          packageAttributes {
            minLengthOfStay
          }
        }
        error {
          errorCode
          message
        }
      }
    }
  }
`;

export const SAVE_LOS_PACKAGE = `mutation SavePackageInclusion($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointsRequestData) {
    savePackageInclusion {
      id
      validationError {
        errorCode
        message
        fieldError {
          fieldName
          message
        }
      }
      error {
        errorCode
        field
        message
      }
      message
    }
  }
}`;
