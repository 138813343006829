import {
  type FieldPath,
  type FieldValues,
  type RegisterOptions,
  useController,
} from 'react-hook-form';
import Dropdown from './DropdownMain';
import { ControlledDropdownProps } from './DropdownMain.types';

const ControlledDropdown = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
    props: ControlledDropdownProps<T, TName>,
  ) => {
  const {
    name,
    control,
    defaultValue = null,
    isRequired = false,
    customValidator,
    ...rest
  } = props;
  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
    validate: customValidator,
  };

  const {
    field: { ref, value, onChange, onBlur },
  } = useController<T, TName>({
    name,
    control,
    defaultValue,
    rules: validations,
  });

  return (
    <Dropdown
      ref={ref}
      value={value?.value === 'null' ? '' : value}
      onChange={onChange}
      onBlur={onBlur}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
    />
  );
};

export default ControlledDropdown;
