import { routes } from 'app/routes';

/**
 * @param {Object} args of function
 * @param {CurrentHotel} args.currentHotel
 * @param {Common} args.commonStr
 * @param {Boolean} args.showNewContentScore
 */
export const getPropertyTabsArray = ({
  currentHotel,
  commonStr,
  showNewContentScore,
}) => {
  const eligibleForPackage = currentHotel.isEligibleForPackage;
  const { PROPERTY_TABS } = commonStr;
  return [
    {
      label: PROPERTY_TABS.BASIC_INFO,
      gtmAction: 'property-basic_info',
      route: routes.property.basicInfoBase,
    },
    {
      label: PROPERTY_TABS.ROOMS,
      gtmAction: 'property-rooms',
      route: routes.property.rooms,
    },
    {
      label: PROPERTY_TABS.MY_IMPACT,
      gtmAction: 'MyImpact',
      route: routes.myImpactHotel.base,
      showNew: true,
    },
    {
      label: PROPERTY_TABS.AMENITIES_SERVICES,
      gtmAction: 'property-amenities',
      route: routes.property.amenities,
    },
    {
      label: eligibleForPackage
        ? PROPERTY_TABS.INCLUSIONS_PACKAGES
        : PROPERTY_TABS.INCLUSIONS,
      gtmAction: 'property-inclusions',
      route: routes.property.inclusionsBase,
    },
    {
      label: PROPERTY_TABS.PHOTOS_VIDEOS,
      gtmAction: 'property-media',
      route: routes.property.photosAndVideos.base,
      showNew: true,
    },
    {
      label: PROPERTY_TABS.PROPERTY_VIBE,
      gtmAction: 'property-property_vibe',
      route: routes.property.vibe,
      showNew: true,
    },
    {
      label: PROPERTY_TABS.POLICIES,
      route: routes.property.policiesBase,
      gtmAction: 'property-rules_and_policies',
    },
    // Conditionally include NEW_CONTENT_SCORE tab only if showNewContentScore
    showNewContentScore && {
      label: PROPERTY_TABS.NEW_CONTENT_SCORE,
      gtmAction: 'property-content-score',
      route: routes.property.newContentScore,
      showNew: true,
    },
    {
      label: PROPERTY_TABS.FINANCIAL_BANK_DETAILS,
      gtmAction: 'property-financial_detail',
      route: routes.property.finance,
    },
    // Check if propertyCategory is homestay and include CONTENT_SCORE tab
    !showNewContentScore && {
      label: PROPERTY_TABS.CONTENT_SCORE,
      gtmAction: 'property-content-score',
      route: routes.property.contentScore,
    },
    {
      label: PROPERTY_TABS.SETTINGS,
      gtmAction: 'property-settings',
      route: routes.property.settingsBase,
    },
  ].filter(Boolean); // Filter out falsy values (null, undefined, false)
};
