import { useState } from 'react';
import { ActiveModifiers } from 'react-day-picker';
import { isDateSame, onlyDateComparison } from 'utilities/DateUtils';
import {
  UseRangeCalendarProps,
  UseRangeCalendarReturnProps,
} from '../Calendar.types';

const addToRange = (range, date, resetOnFromClick, emptyRangeNotAllowed) => {
  const { from, to } = range;
  if (from && to) {
    if (
      isDateSame(to, date) &&
      isDateSame(from, date) &&
      !emptyRangeNotAllowed
    ) {
      return { from: null, to: null };
    }
    if (isDateSame(to, date)) {
      return { from: to, to: null };
    }
    if (isDateSame(from, date) && !emptyRangeNotAllowed) {
      return { from: null, to: null };
    }
    if (onlyDateComparison(from, date) > 0) {
      return resetOnFromClick ? { from: date, to: null } : { from: date, to };
    }
    return { from, to: date };
  }
  if (to) {
    if (onlyDateComparison(date, to) > 0) {
      return { from: to, to: date };
    }
    return { from: date, to };
  }
  if (from) {
    if (onlyDateComparison(date, from) < 0) {
      return { from: date, to: from };
    }
    return { from, to: date };
  }
  return { from: date, to: null };
};

export const useRangeCalendar = (
  props: UseRangeCalendarProps,
): UseRangeCalendarReturnProps => {
  const today = new Date();
  const {
    variant,
    initialDate,
    disabledBefore: defaultDisabledBefore = new Date(),
    disabledAfter: defaultDisabledAfter,
    callbackAfterDateSelection,
    defaultNull = true,
    resetOnFromClick = false,
    emptyRangeNotAllowed = false,
  } = props;
  const [selectedDays, setSelectedDays] = useState<{
    from: Date | null;
    to: Date | null;
  }>(
    initialDate
      ? initialDate
      : defaultNull
        ? { from: null, to: null }
        : { from: today, to: null },
  );
  const [disabledBefore, setDisabledBefore] = useState(defaultDisabledBefore);
  const [disabledAfter, setDisabledAfter] = useState(defaultDisabledAfter);
  const [month, setMonth] = useState<Date>();
  const handleDayClick = (date, { disabled }: ActiveModifiers, _) => {
    if (disabled) return;
    const { from, to } = addToRange(
      selectedDays,
      date,
      resetOnFromClick,
      emptyRangeNotAllowed,
    );
    if (from && !to) {
      setDisabledBefore(date);
      setDisabledAfter(defaultDisabledAfter);
    } else {
      setDisabledBefore(defaultDisabledBefore);
      setDisabledAfter(defaultDisabledAfter);
    }
    setSelectedDays({ from, to });
    callbackAfterDateSelection?.({ from, to });
  };
  const setRange = ({ from, to }: { from: Date; to: Date }) => {
    const updatedSelectedDays = { from, to };
    const isFromDisabled = from ? from > defaultDisabledAfter : false;
    const isToDisabled = to ? to < defaultDisabledBefore : false;
    if (from && from < defaultDisabledBefore)
      updatedSelectedDays.from = defaultDisabledBefore;
    else if (to && to > defaultDisabledAfter) {
      updatedSelectedDays.to = defaultDisabledAfter;
    }
    if (!isFromDisabled && !isToDisabled) {
      setSelectedDays(updatedSelectedDays);
      setDisabledAfter(defaultDisabledAfter);
      setDisabledBefore(defaultDisabledBefore);
      callbackAfterDateSelection?.(updatedSelectedDays);
    }
  };

  return {
    variant,
    selectedDays,
    setSelectedDays,
    disabledAfter,
    setDisabledAfter,
    disabledBefore,
    setDisabledBefore,
    handleDayClick,
    setRange,
    month,
    setMonth,
    initialMonth:
      selectedDays?.from &&
      (!defaultDisabledBefore ||
        onlyDateComparison(selectedDays.from, defaultDisabledBefore) >= 0) &&
      (!defaultDisabledAfter ||
        onlyDateComparison(selectedDays.from, defaultDisabledAfter) <= 0)
        ? selectedDays.from
        : today,
  };
};
