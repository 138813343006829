import { Drawer, Modal, Typography } from 'components/latest-core';
import { TemplatesTable } from '../components/TemplatesTable';
import { usePackageActions } from '../components/usePackageActions';
import { FormActionResult, InclusionsForm } from '../components/InclusionsForm';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { PackagesLosContext } from '../PackagesLosContext';
import {
  AttributeErrors,
  PackageLosInclusionProps,
} from '../data/types/Schema';
import { groupBy, isNullOrUndefined } from 'utilities/Utils';
import { useLangStrings } from 'utilities/CustomHooks';
import { PACKAGE_TYPE } from '../packageConstants';

const PackageLosInclusion = (props: PackageLosInclusionProps) => {
  const { inclState } = props;
  const { templateCategories, optionalTemplates, leafCategoryById } = inclState;
  const [pkgStr, commonStr] = useLangStrings<'Packages'>('Packages');

  const {
    performValidation,
    confirmDelete,
    cancelDelete,
    inclusionForDelete,
    onNewInclusionClick,
    onInclusionDeleteClick,
  } = usePackageActions(PACKAGE_TYPE.LOS);

  const {
    serviceResponse,
    inclusionForEdit,
    setInclusionForEdit,
    setInclusionLookupByIdMap,
  } = useContext(PackagesLosContext);

  const saveTriggerRef = useRef<() => Promise<FormActionResult>>();
  const setErrorRef = useRef<(errors: AttributeErrors) => void>();
  const onFormSaveClicked = useCallback(async () => {
    const formResult = await saveTriggerRef.current();
    if (formResult.type === 'valid') {
      const _result = await performValidation(
        formResult.formState,
        formResult.template,
      );
      if (_result.success === false) {
        setErrorRef.current(_result.errors);
      }
    }
  }, [performValidation]);

  const selectedLeafCategory =
    inclusionForEdit && leafCategoryById[inclusionForEdit.leafTemplateId];

  useEffect(() => {
    setInclusionLookupByIdMap(leafCategoryById);
  }, [leafCategoryById]);

  const selectedInclusionsById = useMemo(
    () =>
      groupBy(
        serviceResponse?.servicesResponse?.services || [],
        item => item?.leafCategory?.serviceId,
      ),
    [serviceResponse],
  );

  return (
    <div className={'flex flex-col gap-3'}>
      <TemplatesTable
        onInclusionClick={onNewInclusionClick}
        templateCategories={templateCategories}
        optionalTemplates={optionalTemplates}
        onInclusionDeleteClick={onInclusionDeleteClick}
        selectedInclusionsMap={selectedInclusionsById}
        isLosPackageFlow
      />
      <Drawer
        footer={{
          primaryBtn: {
            text: commonStr.BUTTONS.SAVE,
            onClick: onFormSaveClicked,
          },
          secondaryBtn: {
            text: commonStr.BUTTONS.CANCEL,
            onClick: () => setInclusionForEdit(null),
          },
        }}
        preventBackDropClick
        header={{
          title: selectedLeafCategory?.leafCategoryName || '',
        }}
        isOpen={!isNullOrUndefined(inclusionForEdit)}
        onClose={() => setInclusionForEdit(null)}
      >
        {inclusionForEdit && (
          <InclusionsForm
            saveTriggerRef={instance => (saveTriggerRef.current = instance)}
            setErrorRef={instance => (setErrorRef.current = instance)}
            template={selectedLeafCategory}
            selectedAttributes={inclusionForEdit.selectedAttributes}
          />
        )}
      </Drawer>

      <Modal
        isOpen={!!inclusionForDelete}
        variant="confirm"
        size="small"
        footer={{
          primaryBtn: {
            text: commonStr.BUTTONS.DELETE,
            onClick: confirmDelete,
          },
          secondaryBtn: {
            text: commonStr.BUTTONS.CANCEL,
            onClick: cancelDelete,
          },
        }}
        header={{ title: pkgStr.LABELS.INCLUSION_DELETE_TITLE }}
      >
        <div className={'flex flex-col'}>
          <Typography>{pkgStr.LABELS.INCLUSION_DELETE_CONFIRMATION}</Typography>
          <Typography fb>{pkgStr.LABELS.INCLUSION_DELETE_CONTINUE}</Typography>
        </div>
      </Modal>
    </div>
  );
};

export default PackageLosInclusion;
