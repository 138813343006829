import { forwardRef, useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { Typography } from 'components/latest-core';
import {
  DropdownMultiSelectGroupedProps,
  DropdownMultiSelectProps,
} from '../DropdownMain.types';
import { isNullOrUndefined } from 'utilities/Utils';
import SelectAll from '../Components/RevampVersion/SelectAll';
import DropdownSearchBase from './DropdownSearchBase';
import useMultiSelectRenderedOptions from '../Hooks/useMultiSelectRenderedOptions';

/**
 * Dropdown component for multi-select functionality.
 *
 * @param props - The props for the Dropdown component.
 * @param ref - The ref for the Dropdown component.
 * @returns The rendered Dropdown component.
 */
const Dropdown = (
  props: DropdownMultiSelectProps | DropdownMultiSelectGroupedProps,
  ref,
) => {
  /**
   * Destructure the props and set default values.
   */
  const {
    options,
    labelField = 'label',
    value = [],
    valueField = '',
    onChange,
    placeholder,
    searchPlaceholder,
    disabled,
    error,
    variant,
    maxTags = Infinity,
    maxTagsText = `You can select upto ${maxTags} items`,
    startAdornment,
    limitTags = 2,
    ...rest
  } = props;

  /**
   * Callback function to get the label of an option.
   *
   * @param item - The option item.
   * @returns The label of the option.
   */
  const optionLabel: (item) => string = useCallback(
    item => (typeof item === 'string' ? item : item?.[labelField]),
    [labelField],
  );
  const valueLabel: (item) => string = useCallback(
    item => (typeof item === 'string' ? item : item?.[valueField]),
    [valueField],
  );
  /**
   * Check if the maximum number of items are selected.
   */
  const maxItemsSelected = useMemo(
    () => maxTags && Array.isArray(value) && value.length > maxTags,
    [maxTags, value],
  );

  /**
   * Callback function to handle the onChange event.
   *
   * @param selectedValue - The selected value.
   */
  const handleOnChange = useCallback(
    selectedValue => {
      if (
        !maxItemsSelected ||
        //for maxTag limits, we should allow to remove tags
        selectedValue.length === 0 ||
        selectedValue.length <= value.length
      ) {
        onChange(selectedValue);
      }
    },
    [maxItemsSelected, onChange],
  );

  /**
   * Get the rendered options for the Dropdown component.
   */
  const { renderOption } = useMultiSelectRenderedOptions({
    optionLabel,
    value,
    options,
    variant,
    isGrouped: (variant === 'multiSelectGrouped' && props.isGrouped) || false,
    groupByField:
      (variant === 'multiSelectGrouped' && props.groupByField) || undefined,
    valueLabel,
    valueField,
  });

  return (
    <DropdownSearchBase
      {...rest}
      options={options}
      labelField={labelField}
      value={value}
      valueField={valueField}
      onChange={handleOnChange}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      renderOptions={renderOption}
      renderValue={(
        selected: unknown,
        allOptionsMap?: {
          [key: string]: unknown;
        },
      ) => {
        if (Array.isArray(selected)) {
          if (selected.length > limitTags) {
            return `${selected.length} items selected`;
          }
          return selected
            .map(item => {
              // Ensure allOptionsMap is defined before attempting to use it
              return (
                !isNullOrUndefined(item) &&
                allOptionsMap &&
                optionLabel(allOptionsMap[item])
              );
            })
            .join(', ');
        }
        return null;
      }}
      renderSelectAll={
        <SelectAll
          onSelectAll={handleOnChange}
          values={value as unknown[]}
          options={options}
        />
      }
      isMultiple
      ref={ref}
      disabled={disabled}
      error={error || maxItemsSelected}
      startAdornment={startAdornment}
      {...(maxItemsSelected
        ? {
          renderMaxTagError: (
              <Grid xs={12}>
                <Box my={1}>
                  <Typography themeColor="error.main">{maxTagsText}</Typography>
                </Box>
              </Grid>
          ),
        }
        : {})}
    />
  );
};

const DropdownMultiSelect = forwardRef(Dropdown);
export default DropdownMultiSelect;
