import { makeStyles } from '@mui/styles';
import { InputStylesProps } from '../types/input.type';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '40px',
    overflow: 'hidden',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    boxSizing: 'border-box',
    fontSize: '14px !important',
    backgroundColor: theme.palette.common.white,
    '& input': {
      boxSizing: 'border-box',
      height: '40px',
      paddingTop: '14px',
      paddingBottom: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.divider}`,
      top: '0px',
      boxSizing: 'border-box',
      height: '40px',
      '& legend': {
        display: 'none',
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.text.primary}`,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        appearance: 'none',
        margin: 0,
      },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.backgrounds.dark,
      color: '#404040 !important',
    },
    '&.Mui-disabled ,&.Mui-disabled > input ': {
      color: '#404040 !important',
      cursor: 'not-allowed',
    },
  },
  endCloseIcon: {
    marginRight: '12px',
    width: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.backgrounds.dark,
      borderRadius: '12px',
      padding: '3px',
      transition: 'all 0.2s',
    },
  },
  small: {
    width: '182px',
  },
  prefixSuffix: {
    backgroundColor: '#EFEFEF',
    padding: '10px',
    color: '#787878',
    fontSize: '14px !important',
  },
  adornedStart: {
    paddingLeft: '0px',
  },
  adornedEnd: { paddingRight: '0px' },
  input: (props: InputStylesProps) => ({
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
    textAlign: props.orientation,
  }),
}));
