import { isNullOrUndefined, showMessage } from 'utilities/Utils';
import { triggerSandesh } from './SponsoredListingGraphClient';

import { legacyPushToGTM, GTM_CATEGORIES, pushToOmniture } from 'utilities/gtm';
export const SLHelpcenter =
  'https://partners.go-mmt.com/support/solutions/folders/81000287932';

export const SLV2HelpCenter =
  'https://partners.go-mmt.com/support/solutions/folders/81000298628';

//Local storage keys
export const tourGuideKey = hotelCode => `sl_tourguide_${hotelCode}`;
export const trophyKey = (adId, currentFilter) =>
  `sl_trophy_${adId}_${currentFilter}`;

export const brandData = [
  {
    value: 'MMT',
    content: 'MakeMyTrip',
  },
  {
    value: 'GI',
    content: 'Goibibo',
  },
];

//Listing view filter selection
export const legendTypes = {
  BEFORE: 'Before campaign start',
  WITHSL: 'With Sponsored listing campaign',
  WITHOUTSL: 'Without Sponsored listing (Organic)',
};

export const toolTipTypes = {
  DURING: 'during campaign',
  BEFORE: 'prior campaign start',
  AFTER: 'estimated without campaign',
};

export const filterTypes = {
  LIVE_FILTER: 'LIVE',
  SCHEDULED_FILTER: 'SCHEDULED',
  FULFILLED_FILTER: 'FULFILLED',
  REFUNDABLE_FILTER: 'REFUNDABLE',
  PARTALLY_FULFILLED: 'PARTIAL_FULFILLED',
  PAUSED: 'PAUSED',
};

export const filterTabName = {
  [filterTypes.LIVE_FILTER]: 'Live',
  [filterTypes.SCHEDULED_FILTER]: 'Scheduled',
  [filterTypes.FULFILLED_FILTER]: 'Fulfilled',
  [filterTypes.REFUNDABLE_FILTER]: 'Partially Fulfilled',
  [filterTypes.PARTALLY_FULFILLED]: 'Partial Fulfilled',
  [filterTypes.PAUSED]: 'Paused',
};

export const filterDataKeyMap = {
  [filterTypes.LIVE_FILTER]: 'liveCampaign',
  [filterTypes.FULFILLED_FILTER]: 'fulfilledCampaign',
  [filterTypes.SCHEDULED_FILTER]: 'scheduledCampaign',
  [filterTypes.REFUNDABLE_FILTER]: 'refundedCampaign',
};

export const renderTypes = {
  PRICE: 'PRICE',
  RATIO: 'RATIO',
  TEXT: 'TEXT',
};

//Payment Options
export const paymentTypes = {
  future: 'FUTURE_ADJUSTMENT',
  advance: 'ADVANCE_PAYMENT',
};

export const paymentTypeLabel = {
  [paymentTypes.future]: 'Future Adjustments',
  [paymentTypes.advance]: 'Pay Now',
};

export const reportingDataTypes = {
  CAMPAIGN_FILTER: 'Campaign Details',
  ADJUSTMENT_FILTER: 'Adjustment Details',
};

export const persMap = {
  DISC: {
    color: '#fe7d32',
  },
  STATS: {
    color: '#30a960',
  },
  IMP: {
    color: '#30a960',
  },
};

export const FORM_FIELDS = {
  CAMPAIGN_NAME: {
    name: 'campaign_name',
  },
};

export const ERROR_MESSAGES_FOR_CREATE_FIELDS = {
  campaign_name: 'Campaign Name Mandatory',
};

export const generateSponsoredListData = (data, headerOrder) => {
  if (isNullOrUndefined(data) || isNullOrUndefined(headerOrder)) {
    return [];
  }
  return data.map(obj => {
    const result = [];

    headerOrder.forEach(key => {
      result.push(key[obj]);
    });

    return result;
  });
};

const { PAGE_LOAD, SPONSORED_LISTING } = GTM_CATEGORIES;
export function pushSLEventToGTM(action, label, config = {}) {
  const {
    isPersuasion = false,
    pageType = 'HOME',
    isPageLoad = false,
    ...customData
  } = config;
  const defaultGAObject = {
    eventCategory: isPersuasion
      ? pageType === 'HOME'
        ? 'web_dashboard'
        : 'web_boostperformance'
      : isPageLoad
        ? PAGE_LOAD
        : SPONSORED_LISTING,
    eventAction: action,
    eventLabel: label,
  };

  const gaObject = { ...defaultGAObject, ...customData };
  legacyPushToGTM(gaObject);
}

export function createDropOfMailerEvent(
  eventId,
  hotelCode,
  selectedReason,
  reasonFreeText,
  hotelname,
  city,
  campaignName,
  brand,
  COMMON_STRINGS,
) {
  triggerSandesh(
    eventId,
    hotelCode,
    selectedReason,
    reasonFreeText,
    hotelname,
    city,
    campaignName,
    brand,
  )
    .then(res => {
      const { pushToSandesh } = res.data;
      if (pushToSandesh.success) {
        console.log('event trigger for create page drop of mailer');
      }
    })
    .catch(err => {
      showMessage({
        show: true,
        message: COMMON_STRINGS.MESSAGES.SOMETHING_WENT_WRONG,
        type: 'error',
      });
      pushToOmniture({
        event: 'ctaResponse',
        cta: {
          name: 'create campaign',
          type: 'click',
          componentName: 'Create Campaign',
          apiResponseCode: 'failure',
        },

        errorInfo: {
          errorCode: err?.response?.status,
          errorType: err?.response?.statusText,
          errorDescription:
            err?.response?.message ||
            COMMON_STRINGS.MESSAGES.SOMETHING_WENT_WRONG,
        },
      });
    });
}

export const PAYMENT_DUMMY_MOBILE_NUMBER = '1234567891';

export const MIN_ROAS_TO_RENDER = 2;

export const CAMPAIGN_REPORT_FILE_NAME = 'CampaignReport';

export const SL_CTA = {
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  VIEW_PERFORMANCE: 'VIEW_PERFORMANCE',
  REFUND_DETAILS: 'REFUND_DETAILS',
};
