import { useMemo, useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import { Dropdown, Modal, Typography } from 'components/latest-core';
import { pushEventToGtm } from 'modules/dashboard/utils/utils';
import {
  type RejectReasonModalProps,
  type ReasonOption,
} from 'modules/dashboard/Dashboard.types';

const getReasonOptions = STRINGS => [
  { value: 'payment_issue', label: STRINGS.REJECT_REASON_MODAL.PAYMENT_ISSUE },
  {
    value: 'roomtype_sold_out',
    label: STRINGS.REJECT_REASON_MODAL.ROOM_TYPE_SOLD_OUT,
  },
  { value: 'rate_issue', label: STRINGS.REJECT_REASON_MODAL.RATE_ISSUE },
  {
    value: 'hotel_sold_out',
    label: STRINGS.REJECT_REASON_MODAL.HOTEL_SOLD_OUT,
  },
  {
    value: 'hotel_not_exist',
    label: STRINGS.REJECT_REASON_MODAL.HOTEL_DO_NOT_EXIST,
  },
  {
    value: 'hotel_renovation',
    label: STRINGS.REJECT_REASON_MODAL.HOTEL_UNDER_RENOVATION,
  },
  {
    value: 'unmarried_couple',
    label: STRINGS.REJECT_REASON_MODAL.UNMARRIED_COUPLE,
  },
  { value: 'service_issue', label: STRINGS.REJECT_REASON_MODAL.SERVICE_ISSUE },
  { value: 'local_id', label: STRINGS.REJECT_REASON_MODAL.LOCAL_ID },
];

export const RejectReasonModal = (props: RejectReasonModalProps) => {
  const { onReject, close } = props;
  const [selectedReason, setSelectedReason] = useState<ReasonOption>({
    value: '',
    label: '',
  });
  const [STRINGS, COMMON_STRINGS] = useLangStrings<'Dashboard'>('Dashboard');
  const reasonOptions = useMemo(() => getReasonOptions(STRINGS), [STRINGS]);

  const GTM_ACTION = 'new_bookings_reject';

  const rejectBooking = () => {
    pushEventToGtm(GTM_ACTION, 'reject');
    onReject(selectedReason.value ? selectedReason.value : 'payment_issue');
  };

  const closeHandler = () => {
    pushEventToGtm(GTM_ACTION, 'close');
    close();
  };

  return (
    <Modal
      isOpen
      header={{
        title: STRINGS.REJECT_REASON_MODAL.REJECT_BOOKING,
      }}
      footer={{
        primaryBtn: {
          text: COMMON_STRINGS.BUTTONS.REJECT,
          onClick: rejectBooking,
          disabled: !selectedReason.value,
        },
        secondaryBtn: {
          text: COMMON_STRINGS.BUTTONS.CLOSE,
          onClick: closeHandler,
        },
        hideFooterDivider: true,
      }}
      onClose={closeHandler}
    >
      <div>
        <div>
          <Typography className="pb-2">
            {STRINGS.REJECT_REASON_MODAL.REJECT_A_BOOK_TEXT}
            <b>{STRINGS.REJECT_REASON_MODAL.IRREVERSIBLE_ACTION}</b>
            {STRINGS.REJECT_REASON_MODAL.REJECT_A_BOOK_TEXT1}
          </Typography>
          <Typography className="pb-2">
            {STRINGS.REJECT_REASON_MODAL.REVENUE_AND_RANKING}
          </Typography>
          <Typography className="pb-2">
            {STRINGS.REJECT_REASON_MODAL.FREQUENT_REJECTIONS_TEXT}
          </Typography>
        </div>
        <Typography className="pb-2">
          {STRINGS.REJECT_REASON_MODAL.SELECT_REASON_FOR_REJECTION}
        </Typography>
        <Dropdown
          value={selectedReason}
          options={reasonOptions}
          onChange={(reason: ReasonOption) => setSelectedReason(reason)}
          disableClearable
          variant="default"
        />
      </div>
    </Modal>
  );
};
