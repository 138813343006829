export const PACKAGE_OMN_PAGE_NAME = 'package';
export const PACKAGE_SUFFIX = ' - Package';

export const PACKAGE_TYPE = {
  LOS: 'los',
  INCLUSIONS: 'inclusions',
} as const;

export const LOS_PACKAGE_LENGTH_LIMIT = 45;

export const MIN_STAY_VAL = '3';
