import { useEffect, useState } from 'react';
import { Toast, Typography } from 'components/latest-core';
import { AppEvents } from '../utilities/EventBus';
import { Box } from '@mui/material';

type State = {
  show: boolean;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
  persistUntilClear: boolean;
  title?: string;
};

const initialState: State = {
  show: false,
  message: '',
  type: 'success',
  persistUntilClear: false,
  title: '',
};

export default function FloatingMessage() {
  const [{ show, message, type, persistUntilClear, title }, showMessage] =
    useState(initialState);

  useEffect(() => {
    const showMessageHandler = data => {
      showMessage(oldData => {
        if (oldData.persistUntilClear) {
          return oldData;
        }
        return data;
      });
    };

    AppEvents.on('showToast', showMessageHandler);
    return () => {
      AppEvents.off('showToast', showMessageHandler);
    };
  }, []);

  const closeHandler = () => showMessage(initialState);

  if (!show) {
    return null;
  }
  const textColor = type === 'warning' ? 'text.primary' : 'common.white';

  return (
    <Toast
      type={type}
      onClose={closeHandler}
      open
      autoHide={!persistUntilClear}
      title={title}
    >
      <Box color="common.white" className="flex justify-center w-fit">
        {Array.isArray(message) ? (
          message.map((m, i) => (
            <Typography
              key={`message_${i}`}
              variant="subtitle1"
              color="inherit"
              fb
            >
              {m}
            </Typography>
          ))
        ) : (
          <Typography variant="subtitle1" themeColor={textColor} fb>
            {message}
          </Typography>
        )}
      </Box>
    </Toast>
  );
}
