import React from 'react';
import { Add, Remove } from '@mui/icons-material';
import { Input } from 'components/latest-core';
import { classNames } from 'utilities/Utils';
import { Divider } from '@mui/material';

type NumberPctIncrInputProps = Partial<
Pick<
React.ComponentProps<typeof Input>,
| 'name'
| 'size'
| 'min'
| 'max'
| 'decimalPoints'
| 'error'
| 'disabled'
| 'className'
>
> & {
  value: number;
  onChange: (value: number, name: string) => void;
  variant?: 'small' | 'large' | 'minus-num-plus';
  incrMin?: number;
  showButtons?: boolean;
  btnSize?: 'small' | 'medium' | 'large';
};

export const NumberPctIncrInput = (props: NumberPctIncrInputProps) => {
  const {
    value,
    onChange,
    name = '',
    min = 0,
    max = 100,
    incrMin = 0,
    className = '',
    error = false,
    variant = 'num-minus-plus',
    showButtons = true,
    decimalPoints = 0,
    disabled = false,
  } = props;

  const increment = () => {
    onChange(value + 1, name);
  };

  const decrement = () => {
    onChange(value - 1, name);
  };

  const minBtnDisabled = value <= (incrMin || min);
  const maxBtnDisabled = value >= max;

  const removeButton = (
    <div
      onClick={() => {
        if (!minBtnDisabled) decrement();
      }}
      className={classNames(
        'h-[38px] w-8 bg-[#e9ebff] text-primary-default select-none flex items-center justify-center rounded-l',
        minBtnDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        variant === 'minus-num-plus' ? '-ml-3' : '',
      )}
      role="button"
    >
      <Remove className="w-4 h-4" />
    </div>
  );

  const addButton = (
    <div
      onClick={() => {
        if (!maxBtnDisabled) increment();
      }}
      className={classNames(
        'h-[38px] w-8 bg-[#e9ebff] text-text-primary select-none flex items-center justify-center rounded-r',
        maxBtnDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        variant === 'minus-num-plus' ? '-mr-3' : '',
      )}
      role="button"
    >
      <Add className="w-4 h-4" />
    </div>
  );

  const minusElem = (
    <div className="number-pct-both-elem">
      {showButtons && (
        <div className="number-pct-controller left-elem">{removeButton}</div>
      )}
    </div>
  );

  const plusElem = (
    <div className="number-pct-both-elem">
      {showButtons && <div className="number-pct-controller">{addButton}</div>}
    </div>
  );

  const rightElem = (
    <div className="number-pct-right-elem">
      <span className="pad0 font12">%</span>
      {showButtons && (
        <div className="flex -mr-3 ml-3">
          {removeButton}
          <Divider
            orientation="vertical"
            className="h-[38px] "
            color="#efefef"
          />
          {addButton}
        </div>
      )}
    </div>
  );

  return (
    <Input
      type="number"
      onChange={val => onChange(val as unknown as number, name)}
      value={value}
      min={min}
      max={max}
      error={error}
      className={className}
      decimalPoints={decimalPoints}
      rightElement={variant === 'minus-num-plus' ? plusElem : rightElem}
      leftElement={variant === 'minus-num-plus' ? minusElem : null}
      disabled={disabled}
      orientation={variant === 'minus-num-plus' ? 'center' : 'left'}
    />
  );
};
