import { type ReactElement } from 'react';
import { Box, BoxProps } from '@mui/material';
import { classNames } from 'utilities/Utils';
import { FlexContainer } from '../../common';

type ContentProps = {
  children: React.ReactNode;
};
const MainContent = ({ children }: ContentProps) => (
  <Box id="main-content" width={756}>
    {children}
  </Box>
);
const SideContent = ({ children }: ContentProps) => (
  <Box
    id="side-content"
    width={316}
    top={20}
    position="sticky"
    height="max-content"
  >
    {children}
  </Box>
);
type ShowEmptySideView = {
  children: React.ReactElement;
  twoColLayout?: true;
  isNewContent?: boolean;
};
type ShowSideView = {
  children: ReactElement | [ReactElement, ReactElement];
  twoColLayout?: false;
  isNewContent?: boolean;
};
type Props = (ShowEmptySideView | ShowSideView) & BoxProps;
export const Container = (props: Props) => {
  const {
    className,
    twoColLayout = false,
    children,
    isNewContent = false,
    bgcolor,
    ...rest
  } = props;
  return (
    <FlexContainer
      className={classNames(
        className,
        'core-container',
        isNewContent ? 'new-core-container' : '',
      )}
      paddingX={6}
      width={1152}
      gap="20px"
      flexDirection="row"
      boxSizing={'content-box'}
      {...rest}
    >
      {Array.isArray(children) || twoColLayout ? (
        <>
          <MainContent>{twoColLayout ? children : children[0]}</MainContent>
          <SideContent>{twoColLayout ? '' : children[1]}</SideContent>
        </>
      ) : (
        <MainContent>{children}</MainContent>
      )}
    </FlexContainer>
  );
};
