import { RESELLER_AGREEMENT_ID } from '../../constants';

export const checkExistingEmailorMobileVariables = data => {
  return {
    rawEndpointRequestData: {
      existingIngoEmailMobileCheck: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          data: data,
        },
      },
    },
  };
};

export const checkExistingEmailOrMobileQuery = `mutation ExistingCheck($rawEndpointRequestData: RawEndpointsRequestData) { 
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    existingIngoEmailMobileCheck {
      modelType1 {
        success 
        message 
        data 
        response_code
      }
    }  
  }
}`;

export const createResellerOnlyUserVariables = (hotelCode, payload) => {
  const { firstName, lastName, email, mobile, password } = payload;

  return {
    rawEndpointRequestData: {
      umCreateUser: {
        endPointId: 1,
        modelType: 'modelType1',
        body: {
          email: email,
          first_name: firstName,
          last_name: lastName,
          country_id: 103,
          countrycode: +91,
          mobile: mobile.phone,
          role: 0,
          user_legal_entity_type: 2,
          password: password,
          user_designation: 'Owner',
          rights: {},
          hotels: [hotelCode],
          hotels_list: [
            {
              code: hotelCode,
              is_host: false,
            },
          ],
        },
      },
    },
  };
};

export const createResellerOnlyUserQuery = `query UMCreateUser($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    umCreateUser {
      modelType1 {
        success
        message
        data
      }
    }
  }
}`;

export const getExistingAccountsVariables = hotelCode => {
  return {
    rawEndpointRequestData: {
      adminContactDetails: {
        endPointId: 1,
        modelType: 'modelType1',
        queryParams: {
          hotelcode: hotelCode,
        },
      },
    },
  };
};

export const getExistingAccountsQuery = `query AdminContactDetails($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    adminContactDetails {
      modelType1 {
        success
        message
        data  {
          first_name
          last_name
          countrycode
          mobile
          country_id
          email
          id
        }
      }
    }
  }
}`;

export const markAdminUsersAsResellerVariables = hotelCode => {
  return {
    rawEndpointRequestData: {
      updateReseller: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          hotelcode: hotelCode,
          user_legal_entity_type: 1,
        },
      },
    },
  };
};

export const markAdminUsersAsResellerQuery = `mutation UpdateReseller($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    updateReseller {
      modelType1 {
        success
        message
        data
      }
    }
  }
}`;

export const activateLegalEntityVariables = hotelCode => {
  return {
    rawEndpointRequestData: {
      activateLegalEntity: {
        endPointId: 99,
        modelType: 'modelType1',
        urlParams: {
          hotelCode,
        },
      },
    },
  };
};

export const activateLegalEntityQuery = `mutation ActivateLegalEntity($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    activateLegalEntity {
      modelType1 {
        success
        message
      }
    }
  }
}`;

export const acceptResellerAgreementVariables = (hotelCode, user) => {
  return {
    rawEndpointRequestData: {
      acceptAgreementList: {
        endPointId: 99,
        modelType: 'modelType1',
        urlParams: {
          hotelcode: hotelCode,
        },
        body: {
          name: user.name,
          designation: user.profile.user_designation,
          mobile_number: user.profile.mobile,
          email: user.email,
          agreement_list: [RESELLER_AGREEMENT_ID],
        },
      },
    },
  };
};

export const acceptResellerAgreementQuery = `mutation AcceptAgreementList($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    acceptAgreementList {
      modelType1 {
        success
        message

      }
    }
  }
}`;
