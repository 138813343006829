import { Input, Checkbox, NumberPctIncrInput } from 'components/latest-core';
import { FormElement } from 'components/new-core';
import { useLangStrings } from 'utilities/CustomHooks';

import './DiscountInput.scss';

const DiscountInput = props => {
  const {
    cFS,
    offer,
    currency,
    isOfferTypePercent,
    showDefaulValCheckBox = false,
    isDefaultValChecked,
    onDefaultValChange,
    index,
    isBundled,
  } = props;
  const [STRINGS] = useLangStrings<'Promotions'>('Promotions');
  const NSTRINGS = STRINGS.CREATE_SECTIONS.CONFIGURE_OFFER;

  const {
    setAllPercentDiscount,
    setAllFixedDiscount,
    setLoggedInPercentDiscount,
    setLoggedInFixedDiscount,
  } = cFS;

  const {
    allPercentDiscount,
    allFixedDiscount,
    loggedInPercentDiscount,
    loggedInFixedDiscount,
  } = offer;

  const getPercentInput = (isSmallInput = false) => (
    <Input
      type="number"
      value={allPercentDiscount}
      rightElement={'%'}
      onChange={value => setAllPercentDiscount(value, index)}
      {...props}
      min={0}
      max={90 - loggedInPercentDiscount}
      disabled={isSmallInput}
      className={`${isSmallInput ? 'percent-dis' : ''}`}
    />
  );
  const getFixedInput = (isDisabled = false) => (
    <Input
      type="number"
      value={allFixedDiscount}
      leftElement={currency || 'INR'}
      onChange={value => setAllFixedDiscount(value, index)}
      {...props}
      disabled={isDisabled}
    />
  );

  return (
    <div className="discount-input-wrapper">
      <FormElement
        label={NSTRINGS.DISCOUNT_ALL}
        className={'marginR20 max-w-[200px]'}
        isRequired
      >
        {isOfferTypePercent ? getPercentInput() : getFixedInput()}
      </FormElement>
      <div style={{ width: '250px', marginRight: '20px' }}>
        <FormElement
          label={NSTRINGS.DISCOUNT_LOGGEDIN}
          className={'marginR0'}
          style={{ width: 'auto' }}
          isRequired
        >
          {isOfferTypePercent ? getPercentInput(true) : getFixedInput(true)}
          <span className="padLR8 padT10">+</span>
          {isOfferTypePercent ? (
            <NumberPctIncrInput
              value={loggedInPercentDiscount}
              onChange={value =>
                setLoggedInPercentDiscount(Number(value), index)
              }
              min={0}
              max={90 - allPercentDiscount}
              disabled={isBundled}
              className="!w-[140px] flex-shrink-0"
            />
          ) : (
            <Input
              type="number"
              leftElement={currency || 'INR'}
              value={loggedInFixedDiscount}
              onChange={value => setLoggedInFixedDiscount(value, index)}
              disabled={isBundled}
            />
          )}
        </FormElement>
        {isOfferTypePercent && showDefaulValCheckBox && (
          <Checkbox
            checked={isDefaultValChecked}
            size="small"
            onChange={() => onDefaultValChange(loggedInPercentDiscount, index)}
          >
            {NSTRINGS.DEFAULT_VAL_DESC}
          </Checkbox>
        )}
      </div>
    </div>
  );
};

export default DiscountInput;
