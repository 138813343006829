import { Autocomplete, TextField } from '@mui/material';
import { forwardRef, useCallback, useRef } from 'react';
import { OptionLabel } from '../__LegacyDropdown__/Dropdown.types';
import { ChevronDownUnfilled, CloseSmall } from 'assets/common';
import { DropdownMainProps } from '../DropdownMain.types';
import {
  classNames,
  getDataPropsFromRest,
  isNullOrUndefined,
} from 'utilities/Utils';
import usePaperComponent from '../__LegacyDropdown__/Hooks/usePaperComponent';
import CustomPopper from '../Components/CustomPopper';
import { useDropdownBaseStyles } from '../Dropdown.styles';

/**
 * DropdownMain component.
 *
 * @component
 * @example
 * ```tsx
 * <DropdownMain
 *   placeholder="Select"
 *   labelField="label"
 *   valueField=""
 *   name=""
 *   options={[]}
 *   onChange={(newValue, name, reason) => {}}
 *   value=""
 *   onBlur={(e) => {}}
 *   popupIcon={<ChevronDownUnfilled color="#404040" />}
 *   clearIcon={<CloseSmall color="#404040" />}
 *   getOptionLabel={(item) => {}}
 *   classes={{}}
 *   PaperComponent={null}
 *   className=""
 *   inputDisabled={true}
 *   rightIcon={null}
 *   leftIcon={null}
 *   popupAnchor={null}
 *   popperWidth={null}
 *   paperClasses=""
 *   disableClearable={true}
 *   increaseZIndex={true}
 *   autoFocus={false}
 *   isButtonVariant={false}
 *   renderOption={(incomingProps, option, state) => {}}
 *   displayRightEl={null}
 *   noBorder={false}
 *   size=""
 * />
 * ```
 */
const DropdownMain = (props: DropdownMainProps, ref: React.Ref<unknown>) => {
  const {
    placeholder = 'Select',
    labelField = 'label',
    valueField = '',
    name = '',
    options,
    onChange,
    value,
    onBlur,
    popupIcon,
    clearIcon,
    getOptionLabel,
    classes,
    PaperComponent,
    className = '',
    inputDisabled = true,
    rightIcon = null,
    leftIcon = null,
    popupAnchor = null,
    popperWidth = null,
    paperClasses = '',
    disableClearable = false,
    increaseZIndex = false,
    autoFocus = false,
    isButtonVariant = false,
    renderOption,
    displayRightEl = null,
    noBorder = false,
    size,
    ...rest
  } = props;
  const classesInternal = useDropdownBaseStyles(props);
  const autoCompleteRef = useRef<HTMLElement | null>(null);
  const optionLabel: OptionLabel = useCallback(
    item => (typeof item === 'string' ? item : item?.[labelField]),
    [labelField],
  );

  const handleChange = (newValue, nametemp, reason) => {
    onChange(newValue, nametemp, reason);
  };

  const optionRenderer = useCallback(
    (incomingProps, option, state) => {
      if (typeof renderOption === 'function') {
        return renderOption(incomingProps, option, {
          selected: state.selected,
          inputValue: state.inputValue,
          labelField,
        });
      }
      return <div {...incomingProps}>{optionLabel(option)}</div>;
    },
    [labelField, optionLabel, renderOption],
  );

  const paperComponent = usePaperComponent(
    `${classesInternal.paper} ${paperClasses}`,
  );

  const dataTestIdProps = getDataPropsFromRest(rest);
  const autoCompleteProps: { inputValue?: string } = {};

  return (
    <Autocomplete
      {...rest}
      selectOnFocus={false}
      onChange={(_, newValue, reason) => handleChange(newValue, name, reason)}
      popupIcon={
        popupIcon || (
          <ChevronDownUnfilled color={isButtonVariant ? '#fff' : '#404040'} />
        )
      }
      clearIcon={clearIcon || <CloseSmall color="#404040" />}
      getOptionLabel={getOptionLabel ?? optionLabel}
      classes={
        classes || {
          root: classesInternal.autoCompleteRoot,
          endAdornment: classesInternal.endAdornment,
        }
      }
      ref={autoCompleteRef}
      renderInput={params => {
        const inputProps = {
          ...params.InputProps,
          ...(leftIcon && { startAdornment: leftIcon }),
          ...(rightIcon && { endAdornment: rightIcon }),
          readOnly: true,
        };

        if (!isNullOrUndefined(displayRightEl)) {
          inputProps.endAdornment = (
            <>
              {displayRightEl(value)}
              {inputProps.endAdornment}
            </>
          );
        }

        return (
          <TextField
            placeholder={placeholder}
            className={classNames(
              classesInternal.root,
              size === 'small' && classesInternal.small,
              className,
              isButtonVariant && classesInternal.buttonVariant,
              noBorder && classesInternal.noBorder,
            )}
            inputRef={ref}
            //do not change the order, this is to override the default inputProps
            {...params}
            InputProps={inputProps}
            disabled={inputDisabled}
            autoFocus={autoFocus}
          />
        );
      }}
      options={options}
      value={value}
      onBlur={(e: React.FocusEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (typeof onBlur === 'function') {
          onBlur(e);
        }
      }}
      PaperComponent={PaperComponent || paperComponent}
      isOptionEqualToValue={(option, incValue) => {
        return typeof incValue === 'string'
          ? option === incValue
          : valueField
            ? option[valueField] === incValue?.[valueField]
            : option[labelField] === incValue?.[labelField];
      }}
      PopperComponent={popperProps => (
        <CustomPopper
          {...popperProps}
          popupAnchor={popupAnchor}
          autoCompleteRef={autoCompleteRef}
          popperWidth={popperWidth}
          increaseZIndex={increaseZIndex}
        />
      )}
      disableClearable={disableClearable}
      renderOption={optionRenderer}
      filterOptions={(_, state) => {
        state.inputValue = '';
        return options;
      }}
      {...autoCompleteProps}
      {...dataTestIdProps}
    />
  );
};

const DropdownBase = forwardRef(DropdownMain);
export { DropdownBase };
