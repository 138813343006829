import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  reactQueryProviderHOC,
  suspenseWrapper,
} from './base/reactQueryWrapper';
import { getLangStrings } from './app/hotel/HotelAPIClient';
import { ISTRINGS } from './interfaces/Strings';
import { Button } from './components/latest-core';
import { errorIllustration } from './assets/old_shared';
import { GTM_CATEGORIES, legacyPushToGTM } from './utilities/gtm';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { routes } from 'app/routes';
import Login from 'app/login/components/Login';
import {
  broadcastLoginInfoToOtherTabs,
  navigateTo,
  removeAPIToken,
  routeHistoryRef,
  setCookie,
  // showMessage,
} from 'utilities/Utils';
import Header from 'app/login/components/Header';
import FloatingMessage from 'app/FloatingMessage';
import { AuthContext } from 'utilities/Context';
import { useStateWithLocalStorage } from 'utilities/CustomHooks';
import { v4 as generateUuidKey } from 'uuid';
// import { logoutUser } from 'app/login/LoginAPIClient';
import {
  baseUrl,
  appSessionCookieKey,
  cookieDomain,
  appDocAccessCookieKey,
  API_TOKEN_KEY,
  COOKIE_EXPIRE_TIME,
  RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
} from './constants';

import { useSuspenseQuery } from '@tanstack/react-query';

import MainApp from './MainApp';
import ResetPassowrdWrapper, {
  ActivateAccount,
  MessageInfo,
} from 'app/login/components/ResetPassowrdWrapper';
import NewUserPassworReset from 'app/login/components/NewUserPassworReset';
import NewUserPasswordResetSuccess from 'app/login/components/NewUserPasswordResetSuccess';
import VerifyExistingUser from 'app/login/components/VerifyExistingUser';

import GlobalShimmer from 'app/shimmers/GlobalShimmer';
import queryString from 'query-string';
import InstagramOauthMain from 'shared_logic/property-vibe/InstagramOauthMain';
import LogoutConfirmPopup from 'app/LogoutConfirmPopup';
import { setCommonHeaders } from 'base/APIClient';
import { ThemeProvider } from 'components';

import './style/new/base.scss';
import './style/base.scss';

// interface LogoutResponse {
//   response: {
//     success: boolean;
//     message: string;
//   };
// }

function useStringsFetcher() {
  const [refetchStringsCount, setRefetchStringsCount] = useState<number>(0);
  useEffect(() => {
    // This code will be stripped off in prod build by Rollup
    if (import.meta.hot) {
      import.meta.hot.on('update-strings', () => {
        setRefetchStringsCount(prevCount => prevCount + 1);
      });
    }
  }, []);
  const [lang, setLang] = useState(() => {
    const ingoUserLang = localStorage.getItem('ingo_user_lang');
    return ingoUserLang || 'En';
  });
  return {
    setLang,
    lang,
    fetcher: () =>
      getLangStrings(lang, { showLoader: false }).then(res => {
        setCommonHeaders();
        return {
          langStrings: res.data as ISTRINGS,
          lang,
          setLang,
        };
      }),
    fetcherDeps: [lang, refetchStringsCount],
  };
}

const ProtectedRoute = ({ children, ...rest }) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ history }) => {
        routeHistoryRef.curr = history;

        if (isLoggedIn) {
          return children;
        }
        const { href, origin } = window.location;
        const isUrlContainsBaseURL = href.includes(baseUrl);

        const pathName = href.replace(
          origin + (isUrlContainsBaseURL ? `/${baseUrl}` : ''),
          '',
        );

        navigateTo(routes.login.base, {
          from: pathName || '/',
        });
      }}
    />
  );
};

const MainWrapper = () => {
  const connectCookie = document.cookie
    .split(';')
    .find(cookie => cookie.trim().startsWith(appSessionCookieKey));

  const cookieValue = connectCookie?.split('=').map(part => part.trim())[1];

  // when user access extranet through OneLogin when he is already loggedin with someother account
  if (cookieValue) {
    removeAPIToken(false);
  }

  const [apiToken, setApiToken, deleteToken] = useStateWithLocalStorage<
  boolean | string
  >(API_TOKEN_KEY, false);

  const { fetcher: stringsFetcher, fetcherDeps: stringsFetcherDeps } =
    useStringsFetcher();

  const { data: stringsData } = useSuspenseQuery({
    queryFn: stringsFetcher,
    queryKey: ['app', 'strings', ...stringsFetcherDeps],
    staleTime: Infinity,
  });

  const onLogoutAPISuccess = redirectToLogin => {
    deleteToken();
    setCookie(appDocAccessCookieKey, '', 0);

    broadcastLoginInfoToOtherTabs('logout');
    localStorage.removeItem('last_hotelcode');
    sessionStorage.removeItem(RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY);

    if (redirectToLogin) {
      navigateTo(routes.login.base);
    }
  };

  const logOutUser = (redirectToLogin = true, cb?: Function) => {
    cb?.();
    onLogoutAPISuccess(redirectToLogin);

    // logoutUser()
    //   .then((resp: LogoutResponse) => {
    //     if (resp?.response?.success) {
    //       cb?.();
    //       onLogoutAPISuccess(redirectToLogin);
    //     }
    //   })
    //   .catch(err => {
    //     const errMsg = err?.response?.data ?? 'Something Went Wrong';
    //     if (errMsg) {
    //       showMessage({
    //         message: errMsg,
    //         type: 'error',
    //         show: true,
    //       });
    //     }
    //   });
  };

  useEffect(() => {
    //specifying a unique code for a tab.
    window.TAB = generateUuidKey();

    const extranetCookie = document.cookie
      .split(';')
      .find(cookie => cookie.trim().startsWith(appDocAccessCookieKey));

    if (apiToken && !extranetCookie) {
      setCookie(appDocAccessCookieKey, apiToken.toString(), COOKIE_EXPIRE_TIME);
    }

    if (cookieValue) {
      setApiToken(cookieValue);
      setCookie(appDocAccessCookieKey, cookieValue, COOKIE_EXPIRE_TIME);
      document.cookie = `${appSessionCookieKey}=''; domain=${cookieDomain}; expires=${new Date(
        0,
      ).toUTCString()}; path=/`;
      broadcastLoginInfoToOtherTabs('login');
      localStorage.removeItem('last_hotelcode');
    }

    const onTokenValueChnage = event => {
      const { currentTab, action } = event.data;

      // Reload other tabs other than current tab
      if (window.TAB !== currentTab) {
        if (action === 'logout') {
          navigateTo(routes.login.base);
        }
        window.location.reload();
      }
    };

    const channel = new BroadcastChannel('tokenChange');
    channel.addEventListener('message', onTokenValueChnage);

    return () => {
      channel.removeEventListener('message', onTokenValueChnage);
    };
  }, []);

  // to prevent showing user login page for a sec from logging in from oneLogin
  if (cookieValue) return null;

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!apiToken,
        setApiToken,
        logOutUser,
        onLogoutAPISuccess,
        langStringsData: stringsData,
      }}
    >
      <ThemeProvider>
        <FloatingMessage />
        <LogoutConfirmPopup />
        <Router basename={`/${baseUrl}`}>
          <Switch>
            <Route
              key="activateComplete"
              path={routes.login.activateComplete}
              render={({ history }) => {
                routeHistoryRef.curr = history;

                return (
                  <Header>
                    <ActivateAccount />
                  </Header>
                );
              }}
            />
            <Route
              key="login"
              path={routes.login.base}
              render={({ history }) => {
                routeHistoryRef.curr = history;

                if (apiToken) {
                  return navigateTo('/');
                }
                return <Login />;
              }}
            />
            <Route
              key="forgotPassword"
              path={[
                routes.login.resetPassword,
                routes.login.resetPasswordConfirm,
              ]}
              render={({ history }) => {
                routeHistoryRef.curr = history;

                return <ResetPassowrdWrapper />;
              }}
            />
            <Route
              key="register"
              path={[routes.login.registerConfirm]}
              render={({ history }) => {
                routeHistoryRef.curr = history;

                return (
                  <Header>
                    <MessageInfo />
                  </Header>
                );
              }}
            />
            <Route
              exact
              key="newUserPasswordReset"
              path={routes.userManagement.newUserPasswordReset}
              render={({ history }) => {
                routeHistoryRef.curr = history;

                return <NewUserPassworReset />;
              }}
            />
            <Route
              exact
              key="newUserPasswordReset"
              path={routes.userManagement.verifyExistingUser}
              render={({ history }) => {
                routeHistoryRef.curr = history;

                return <VerifyExistingUser />;
              }}
            />
            <Route
              key="newUserPasswordResetSucces"
              path={routes.userManagement.newUserPasswordResetSucces}
              render={({ history }) => {
                routeHistoryRef.curr = history;

                return <NewUserPasswordResetSuccess />;
              }}
            />
            <ProtectedRoute path="/">
              <MainApp langStringsData={stringsData} />
            </ProtectedRoute>
          </Switch>
        </Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export function MainErrorScreen(props: { onClick: () => void }) {
  const helpCenterUrl = window.helpCenterUrl;
  const openHelpdesk = useCallback(() => {
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.PAGE_LOAD,
      eventAction: 'helpdesk',
    });
  }, []);
  return (
    <div
      className="sr-card sr-flex-col flex-centralized"
      style={{
        fontSize: '12px',
        lineHeight: '1.67',
        color: '#212121',
        height: '100%',
      }}
    >
      <img
        alt="There was an error"
        src={errorIllustration}
        className="marginB16"
      />
      <h1>There was an error!</h1>
      <Button onClick={props.onClick}>Try again</Button>
      <h3>
        <a
          rel="noreferrer noopener"
          target="extranet_help_center"
          href={helpCenterUrl}
          className={'text-blue'}
          onClick={openHelpdesk}
        >
          visit our FAQs
        </a>
        &nbsp;for more Help
      </h3>
    </div>
  );
}

const MainWrapperContainer = () => {
  const { pathname, hash, origin, search } = window.location;

  const params = queryString.parse(search);

  let activationId = null;
  let resetToken = null,
    userId = null;

  if (params?.code) {
    return <InstagramOauthMain code={params?.code} />;
  }

  if (pathname.includes('accounts/activate')) {
    const paths = pathname.split('/');
    activationId = paths[paths.length - 1];
  }
  if (pathname.includes('accounts/password/reset/confirm')) {
    const paths = pathname.split('/');
    resetToken = paths[paths.length - 1];
    userId = paths[paths.length - 2];
  }

  let redirectURL = '';

  if (activationId) {
    redirectURL = `/${
      baseUrl + routes.login.activateComplete
    }?activationId=${activationId}`;
  }

  if (userId && resetToken) {
    redirectURL = `/${
      baseUrl + routes.login.resetPasswordConfirm
    }?userId=${userId}&resetToken=${resetToken}`;
  }
  if (redirectURL) {
    window.location.replace(origin + redirectURL);
    return null;
  }

  if (hash) {
    const URL = origin + pathname + hash.replace('#', '') + search;

    window.location.replace(URL);
    return null;
  }

  return (
    <>
      <MainWrapper />
    </>
  );
};

const MainWithSuspense = suspenseWrapper(MainWrapperContainer, {
  renderLoader: () => <GlobalShimmer />,
  renderError: ({ reset }) => <MainErrorScreen onClick={reset} />,
});
export default reactQueryProviderHOC(MainWithSuspense);
