import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { Typography } from '../../../components/latest-core';
import { useLangStrings } from '../../../utilities/CustomHooks';
import CONSTANTS from '../HostWebMigration.constants';
import {
  HostMigrationSpaceIntro,
  HostMigrationPhoto,
  HostMigrationHostProfile,
  HostMigrationSnooze,
  SpacePreview,
  PhotoPreview,
  RecommendationPreview,
  SnoozePreview,
} from 'assets/modules/host-migrations';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    marginTop: 0,
  },
  info: {
    paddingRight: 95,
  },
  preview: {
    height: '100%',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  card: {
    boxShadow: '0px 5px 16px rgba(1, 16, 34, 0.1)',
    transition: 'all 0.2s ease-in',
  },
  section: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
  },
  content: {
    marginTop: 12,
  },
  activeIcon: {
    '& > path': {
      stroke: theme.palette.primary.main,
    },
  },
}));

const detailsIconList = [
  HostMigrationSpaceIntro,
  HostMigrationPhoto,
  HostMigrationHostProfile,
  HostMigrationSnooze,
];

const detailsPreviewImages = [
  SpacePreview,
  PhotoPreview,
  RecommendationPreview,
  SnoozePreview,
];

export default function WhatNewForHost() {
  const [activeCard, setActiveCard] = useState(0);
  const classes = useStyles();

  const [STRINGS] = useLangStrings<'HostWebMigration'>('HostWebMigration');

  useEffect(() => {
    const interval = setTimeout(() => {
      setActiveCard(prev => {
        return (prev + 1) % detailsIconList.length;
      });
    }, CONSTANTS.ACCORDION_INTERVAL);

    return () => {
      clearTimeout(interval);
    };
  }, [activeCard]);

  return (
    <Grid
      className={classes.root}
      container
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Grid xs={6} className={classes.info}>
        <Box marginY={5}>
          <Typography variant="h1">{STRINGS.INTRO.TITLE}</Typography>
          <Typography themeColor="gray.main">
            {STRINGS.INTRO.CONTENT}
          </Typography>
        </Box>
        <Box>
          {detailsIconList.map((Icon, index) => {
            const isActive = activeCard === index;
            const content = STRINGS.INTRO.DETAILS[index];

            return (
              <Box
                key={index}
                padding={4}
                className={isActive ? classes.card : classes.section}
                onClick={() => setActiveCard(index)}
              >
                <Grid container>
                  <Grid xs={2}>
                    <Icon className={isActive ? classes.activeIcon : ''} />
                  </Grid>
                  <Grid xs={10}>
                    <Typography
                      themeColor={isActive ? 'primary.main' : null}
                      fb
                    >
                      {content.TITLE}
                    </Typography>
                    {isActive && (
                      <Typography className={classes.content}>
                        {content.CONTENT}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid xs={6} className={classes.preview}>
        <img src={detailsPreviewImages[activeCard]} alt="preview" />
      </Grid>
    </Grid>
  );
}
