import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  CSSProperties,
} from 'react';
import {
  Paper,
  TextField,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as CommonIcons from 'assets/common';
import { debounce, isNullOrUndefined } from 'utilities/Utils';
import { Search } from '@mui/icons-material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Box from '../Box/Box';

export function autoSuggestParse(label, inputVal) {
  const matches = match(label, inputVal);
  const parts = parse(label, matches);
  return parts;
}

// Type defination for SearchInput
type AutoCompleteProps = Pick<
React.ComponentProps<typeof Autocomplete>,
| 'ref'
| 'options'
| 'onBlur'
| 'renderOption'
| 'placeholder'
| 'disabled'
| 'noOptionsText'
| 'value'
>;

type FixedOptions = {
  fixedOptions?: AutoCompleteProps['options'];
};

type FetchOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchOptions?: (value: any) => Promise<AutoCompleteProps['options']>;
};

type SearchInputProps = Omit<AutoCompleteProps, 'options'> &
(FixedOptions & FetchOptions) & {
  onChange: (value: unknown, name: string) => void;
  labelField?: string;
  disableClear?: boolean;
  name?: string;
  addTextAsOption?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  disableCloseOnSelect?: boolean;
  fromAutoSuggestMultiSelect?: boolean;
  getOptionDisabled?: (option) => boolean;
  // this is to prevent the default filtering of MuiAutocomplete
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterOptions?: (options: unknown[], state: any) => unknown[];
  debounceWaitTime?: number;
  className?: string;
  style?: CSSProperties;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '36px',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    '& > div:first-child': {
      padding: '0',
      background: theme.palette.common.white,
      '& input': {
        padding: '8px 12px 7px !important',
      },
    },
  },
  searchable: {
    paddingLeft: '12px !important',
    width: 'auto',
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: '4px',
    boxShadow: '0px 1px 5px rgba(1, 16, 34, 0.1)',
    borderRadius: '4px',
    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: theme.palette.backgrounds.primary,
    },
    '& li[class^="MuiAutocomplete-option"]': {
      fontSize: '14px',
      fontFamily: 'Roboto',
      lineHeight: '22px',
      borderRadius: '4px',
      padding: '8px 12px',
      marginBottom: '2px',
    },
    '& div[class*="MuiAutocomplete-groupLabel"]': {
      fontSize: '12px !important',
    },
    '& li[data-focus="true"]': {
      backgroundColor: theme.palette.backgrounds.primary,
      fontweight: '500',
      '& *': {
        fontweight: '500',
        color: theme.palette.text.primary,
      },
    },
    '& li[aria-selected="true"]': {
      backgroundColor: theme.palette.backgrounds.dark,
      fontweight: '500',
      color: theme.palette.primary.main,
      '& *': {
        fontweight: '500',
        color: theme.palette.primary.main,
      },
    },
    '& li:has(> span.Highlight)': {
      background: theme.palette.backgrounds.primary,
    },
  },
}));

const AutocompleteStyles = makeStyles(() => ({
  popper: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zIndex: '100000002 !important' as any,
  },
  popupIndicator: {
    display: 'none',
  },
}));

const SearchInputComp = (
  props: SearchInputProps,
  ref: React.Ref<HTMLInputElement>,
) => {
  const {
    fetchOptions,
    fixedOptions,
    value,
    onChange,
    disableClear,
    labelField = 'label',
    disabled = false,
    noOptionsText = 'No options',
    placeholder = '',
    renderOption,
    name = '',
    addTextAsOption = false,
    disableCloseOnSelect = false,
    fromAutoSuggestMultiSelect = false,
    getOptionDisabled,
    debounceWaitTime = 300,
    className,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(fixedOptions ?? []);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const autoCompleteRef = useRef<any>(null);

  const optionLabel = useCallback(
    item => (typeof item === 'string' ? item : item?.[labelField]),
    [labelField],
  );

  const PaperComponent = useCallback(
    ({ children }) => <Paper className={classes.paper}>{children}</Paper>,
    [],
  );

  const debouncedFetchOptions = useMemo(
    () =>
      debounce(
        debiuncedValue =>
          fetchOptions(debiuncedValue)
            .then(data => setOptions(data))
            .catch(() => setOptions([]))
            .finally(() => setLoading(false)),
        debounceWaitTime,
      ),
    [],
  );

  useEffect(() => {
    if (addTextAsOption) {
      setOptions(prevOptions => [
        ...prevOptions,
        {
          label: `Add ${inputValue}`,
          value: inputValue,
        },
      ]);
    }
    if (fixedOptions?.length > 0) {
      if (options.length !== fixedOptions.length) {
        setOptions(fixedOptions);
      }
      return () => {};
    }
    if (inputValue === '') {
      setLoading(false);
      setOptions([]);
      return () => {};
    }
    setLoading(true);
    if (fetchOptions) {
      debouncedFetchOptions(inputValue);
    }

    return () => {};
  }, [inputValue, fetchOptions, fixedOptions]);

  const optionRenderer = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (props, option, state, ownerState) => {
      const { inputValue: inputVal } = state;
      if (isNullOrUndefined(renderOption)) {
        const label = optionLabel(option);
        const parts = autoSuggestParse(label, inputVal);
        return (
          <Box
            {...props}
            sx={{
              fontWeight: 'inherit',
              fontSize: '14px',
              whiteSpace: 'pre',
              lineHeight: '20px',
            }}
          >
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 500 : 400,
                }}
                className={part.highlight ? 'Highlight' : ''}
              >
                {part.text}
              </span>
            ))}
          </Box>
        );
      }
      return renderOption(props, option, state, ownerState);
    },
    [optionLabel, renderOption],
  );

  const getInputProps = params => {
    return {
      ...params.InputProps,
      startAdornment: (
        <>
          <Search color="action" className={classes.searchable} />
          {params.InputProps.startAdornment}
        </>
      ),
      endAdornment: (
        <>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps.endAdornment}
        </>
      ),
    };
  };
  return (
    <Autocomplete
      classes={{
        ...AutocompleteStyles(),
        root: className,
      }}
      value={value}
      options={options}
      onInputChange={(_, inoutChangeVal, reason) => {
        if (fromAutoSuggestMultiSelect) {
          if (reason !== 'reset') {
            setInputValue(inoutChangeVal);
          }
        } else {
          setInputValue(inoutChangeVal);
        }
      }}
      onChange={(_, newValue) => {
        onChange(newValue, name);
      }}
      ref={autoCompleteRef}
      loading={loading}
      loadingText={'Loading...'}
      getOptionLabel={optionLabel}
      popupIcon={<CommonIcons.ChevronDown />}
      disableClearable={disableClear}
      PaperComponent={PaperComponent}
      disabled={disabled}
      noOptionsText={noOptionsText}
      selectOnFocus={addTextAsOption}
      clearOnBlur={addTextAsOption}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionDisabled={getOptionDisabled}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={ref}
          InputProps={getInputProps(params)}
          placeholder={placeholder}
          variant="outlined"
          className={classes.root}
        />
      )}
      renderOption={optionRenderer}
      {...rest}
    />
  );
};
const SearchInput = forwardRef(SearchInputComp);
export { SearchInput };
