import { forwardRef } from 'react';
import ModalBase from './ModalBase';
import type { ModalPropTypes } from '../Modal.types';
import { ModalSize } from '../Modal.constant';

function ModalInfo(props: ModalPropTypes, ref?: React.Ref<HTMLDivElement>) {
  const {
    onClose = () => {},
    footer = null,
    header = null,
    size = ModalSize.SMALL,
  } = props;

  const cutomHeader = header
    ? {
      ...header,
      hideCross: true,
    }
    : header;

  const customFooter = footer
    ? {
      ...footer,
      primaryBtn: {
        text: footer?.primaryBtn?.text || 'Close',
        onClick: footer?.primaryBtn?.onClick || onClose,
      },
      hideFooterDivider: true,
    }
    : footer;

  return (
    <ModalBase
      ref={ref}
      {...props}
      footer={customFooter}
      header={cutomHeader}
      size={size}
    />
  );
}

export default forwardRef(ModalInfo);
