import CloseIcon from '@mui/icons-material/Close';
import * as Dashboard from '../../../assets/modules/dashboard';
import { routes } from 'app/routes';
import { navigateTo } from 'utilities/Utils';
import { Button } from 'components/latest-core';

const GreatWorkPopup = ({ STRINGS, onCloseClick, dashboard = false }) => {
  return (
    <div className="great-work">
      <CloseIcon className="close-icon" onClick={onCloseClick} />
      <div className="top-content">
        <img src={Dashboard.ThumbsUp} alt="ThumbsUp" />
      </div>
      <div className="main-content">
        <div className="title">{STRINGS.GREAT_WORK}</div>
        {dashboard && (
          <Button
            variant="outlined"
            onClick={() => navigateTo(routes.opportunityCenter.actionDetail)}
          >
            {STRINGS.SEE_ALL_TASKS}
          </Button>
        )}
      </div>
    </div>
  );
};

export default GreatWorkPopup;
