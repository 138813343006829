import { useState } from 'react';
import { ActiveModifiers } from 'react-day-picker';
import { onlyDateComparison } from 'utilities/DateUtils';
import {
  UseMultiSelectCalendarProps,
  UseMultiSelectCalendarReturnProps,
} from '../Calendar.types';

const removeDate = (selectedDateMeta, day) => {
  const monthMap = { ...selectedDateMeta.month };
  const yearMap = { ...selectedDateMeta.year };
  const selectedDayMonth = day.getMonth();
  const selectedDayYear = day.getFullYear();
  const yearDateKey = `${selectedDayYear}-${selectedDayMonth}`;
  monthMap[yearDateKey] -= 1;
  yearMap[selectedDayYear] -= 1;
  return {
    month: monthMap,
    year: yearMap,
    count: selectedDateMeta.count - 1,
  };
};

const addDate = (selectedDateMeta, day) => {
  const monthMap = { ...selectedDateMeta.month };
  const yearMap = { ...selectedDateMeta.year };
  const selectedDayMonth = day.getMonth();
  const selectedDayYear = day.getFullYear();
  const yearDateKey = `${selectedDayYear}-${selectedDayMonth}`;
  if (monthMap[yearDateKey]) monthMap[yearDateKey] += 1;
  else monthMap[yearDateKey] = 1;
  if (yearMap[selectedDayYear]) yearMap[selectedDayYear] += 1;
  else yearMap[selectedDayYear] = 1;
  return {
    month: monthMap,
    year: yearMap,
    count: selectedDateMeta.count + 1,
  };
};

const shouldRestrict = (multiSelectRestriction, selectedDateMeta, day) => {
  const {
    month: monthLimit,
    year: yearLimit,
    total: maxDates,
  } = multiSelectRestriction;
  if (maxDates && maxDates < selectedDateMeta.count) {
    return true;
  }
  const selectedDayMonth = day.getMonth();
  const selectedDayYear = day.getFullYear();
  const yearDateKey = `${selectedDayYear}-${selectedDayMonth}`;
  // No restriction, then no need to compute.
  if (yearLimit === undefined && monthLimit === undefined) {
    return false;
  }
  const { month: monthMap, year: yearCounterMap } = selectedDateMeta;
  // Month check
  if (monthMap?.[yearDateKey] >= monthLimit) {
    return true;
  }
  // Year check
  if (yearCounterMap?.[selectedDayYear] >= yearLimit) {
    return true;
  }
  return false;
};

const removeMultipleDates = (
  index: number,
  selectedDateMeta,
  groupedDateArray: Array<Date[]>,
): [
  Date[],
  {
    month: { [key: string]: number };
    year: { [key: string]: number };
    count: number;
  },
] => {
  const _groupedDates = [...groupedDateArray];
  const { month, year, count } = selectedDateMeta;
  const newCount = count - groupedDateArray[index].length;
  groupedDateArray[index].forEach(date => {
    const dateToRemove = new Date(date);
    const selectedDayMonth = dateToRemove.getMonth();
    const selectedDayYear = dateToRemove.getFullYear();
    const yearDateKey = `${selectedDayYear}-${selectedDayMonth}`;
    month[yearDateKey] = month[yearDateKey] - 1;
    year[selectedDayYear] = year[selectedDayYear] - 1;
  });
  _groupedDates.splice(index, 1);
  const newSelectedDays = _groupedDates.reduce((acc, dateArr) => {
    return [...acc, ...dateArr.map(date => new Date(date))];
  }, []);
  return [newSelectedDays, { month, year, count: newCount }];
};

const getInitialSelectedDateMeta = selectedDays => {
  const initialSelectedDateMeta = {
    month: {},
    year: {},
    count: 0,
  };
  const newinitialSelectedDateMeta = selectedDays.reduce(
    (acc, day) => addDate(acc, day),
    initialSelectedDateMeta,
  );
  return newinitialSelectedDateMeta;
};

export const useMultiSelectCalendar = (
  props: UseMultiSelectCalendarProps,
): UseMultiSelectCalendarReturnProps => {
  const today = new Date();
  const {
    variant,
    initialDate,
    callbackAfterDateSelection,
    defaultNull = true,
    multiSelectRestriction = {},
  } = props;
  const [selectedDays, setSelectedDays] = useState<Date[]>(
    initialDate ? initialDate : defaultNull ? [] : [today],
  );
  const [month, setMonth] = useState<Date>();
  const [selectedDateMeta, setSelectedDateMeta] = useState(() =>
    getInitialSelectedDateMeta(selectedDays),
  );
  const handleDayClick = (
    day: Date,
    { disabled, selected }: ActiveModifiers,
    _,
  ) => {
    if (disabled) return;
    const currentSelectedDays = [...selectedDays];
    if (selected) {
      const selectedIndex = currentSelectedDays.findIndex(
        selectedDay => onlyDateComparison(selectedDay, day) === 0,
      );
      currentSelectedDays.splice(selectedIndex, 1);
      setSelectedDateMeta(removeDate(selectedDateMeta, day));
    } else if (!shouldRestrict(multiSelectRestriction, selectedDateMeta, day)) {
      currentSelectedDays.push(day);
      setSelectedDateMeta(addDate(selectedDateMeta, day));
    }
    setSelectedDays(currentSelectedDays);
    callbackAfterDateSelection?.(currentSelectedDays);
  };

  const handleRemoveMultipleDates = (
    index: number,
    groupedDateArray: Array<Date[]>,
  ) => {
    const [updatedSelectedDays, updatedSelectedDatesMeta] = removeMultipleDates(
      index,
      selectedDateMeta,
      groupedDateArray,
    );
    setSelectedDateMeta(updatedSelectedDatesMeta);
    setSelectedDays(updatedSelectedDays);
    callbackAfterDateSelection?.(updatedSelectedDays);
  };

  return {
    variant,
    selectedDays,
    setSelectedDays,
    handleDayClick,
    month,
    setMonth,
    initialMonth: selectedDays[0] ?? today,
    selectedDateMeta,
    setSelectedDateMeta,
    handleRemoveMultipleDates,
  };
};
