import * as CommonIcons from 'assets/common';

import { classNames } from 'utilities/Utils';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from 'components/latest-core';
import { useNavigation, CaptionProps } from 'react-day-picker';
import { Menu } from './Menu';
import { MonthNames } from 'utilities/DateUtils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cta: {
    cursor: 'pointer',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}));
type CustomCaptionProps = {
  month: Date;
  setMonth: React.Dispatch<React.SetStateAction<Date>>;
  fromYear: Date;
  toYear: Date;
  captionLabel: React.ReactNode;
} & CaptionProps;

export const CustomCaption = (props: CustomCaptionProps) => {
  const {
    setMonth,
    fromYear,
    toYear,
    displayMonth: date,
    captionLabel,
  } = props;
  const months = MonthNames.map(_month => _month.long);
  const { nextMonth, previousMonth } = useNavigation();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const handleChange = (_year: number, _month: number) => {
    setMonth(new Date(_year, _month));
  };
  const monthOptions = MonthNames.map(_month => ({ label: _month.long }));

  const end = toYear.getFullYear();
  const start = fromYear.getFullYear();

  const yearOptions = Array.from({ length: end - start + 1 }, (_, i) => ({
    label: String(start + i),
  }));

  const classes = useStyles();

  return (
    <div className="w-[280px]">
      <div className="flex space-between flex-row items-center pb-3">
        <CommonIcons.ChevronLeft
          className={classNames(
            classes.cta,
            'p-[7px] rounded-[4px] border-solid border-[1px] border-divider !w-10 !h-10',
          )}
          onClick={() => {
            if (previousMonth) setMonth(previousMonth);
          }}
          data-test-id="previous-month"
        />
        <div className="flex flex-1 justify-center p-1 gap-2">
          <Menu
            selectedEl={option => (
              <Typography variant="subHeadingMedium">{option.label}</Typography>
            )}
            options={monthOptions}
            value={{ label: month }}
            callback={option => {
              handleChange(year, months.indexOf(option.label));
            }}
            data-test-id="month-selector"
          />
          <Menu
            selectedEl={option => (
              <Typography variant="subHeadingMedium">{option.label}</Typography>
            )}
            options={yearOptions}
            value={{ label: year.toString() }}
            callback={option => {
              handleChange(parseInt(option.label), months.indexOf(month));
            }}
            data-test-id="year-selector"
          />
        </div>
        <CommonIcons.ChevronRight
          className={classNames(
            classes.cta,
            'p-[7px] rounded-[4px] border-solid border-[1px] border-divider !w-10 !h-10',
          )}
          onClick={() => nextMonth && setMonth(nextMonth)}
          data-test-id="next-month"
        />
      </div>
      {captionLabel && (
        <Typography variant="subtitle1" className="pb-3">
          {captionLabel}
        </Typography>
      )}
    </div>
  );
};
