import React, { forwardRef, useCallback, useState } from 'react';
import { Drawer as MuiDrawer } from '@mui/material';
import {
  classNames,
  getDataPropsFromRest,
  isNullOrUndefined,
} from 'utilities/Utils';
import { DrawerFooter, DrawerHeader } from './components';
import { useDrawerStyles } from './Drawer.styles';
import type { DrawerPropTypes } from './Drawer.types';

function Drawer(props: DrawerPropTypes, ref?: React.Ref<HTMLDivElement>) {
  const {
    id = '',
    isOpen,
    customClasses = null,
    header = null,
    children,
    footer = null,
    leftChildren,
    preventBackDropClick = false,
    onClose = () => {}, // onClose and onCrossClick are same
    onBackDropClick, // pass onBackDropClick to overide onClose on backdrop click
    ...rest
  } = props;

  const {
    root: rootContainerClass = '',
    backdrop: backdropClass = '',
    paper: paperClass = '',
    container: containerClass = '',
    leftChild: leftChildClass = '',
    header: headerClasses = {},
    children: childrenClass = '',
    footer: footerClasses = {},
  } = customClasses || {};

  const [showLeftChildren, setShowLeftChildren] = useState(
    !isNullOrUndefined(leftChildren),
  );

  const classes = useDrawerStyles({
    hasLeftChildren: !isNullOrUndefined(leftChildren),
  });

  const dataTestIdProps = getDataPropsFromRest(rest);

  const backDropClickHandler = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      reason: string,
    ) => {
      if (
        (preventBackDropClick || !isNullOrUndefined(leftChildren)) &&
        reason === 'backdropClick'
      ) {
        return;
      }
      if (onBackDropClick) {
        return onBackDropClick(event);
      }
      return onClose(event);
    },
    [leftChildren, onBackDropClick, onClose, preventBackDropClick],
  );

  const renderLeftChild = useCallback(
    (drawerHasEntered: boolean) => {
      if (leftChildren) {
        setShowLeftChildren(drawerHasEntered);
      }
    },
    [leftChildren],
  );

  return (
    <MuiDrawer
      id={id}
      anchor="right"
      open={isOpen}
      onClose={backDropClickHandler}
      classes={{
        paper: paperClass,
        root: classNames(classes.root, rootContainerClass),
      }}
      ModalProps={{
        BackdropProps: {
          className: classNames(classes.backdrop, backdropClass),
        },
      }}
      SlideProps={{
        onEntering: () => renderLeftChild(false),
        onEntered: () => renderLeftChild(true),
      }}
      {...dataTestIdProps}
    >
      {isOpen && showLeftChildren && (
        <div className={classNames(classes.leftChildContainer, leftChildClass)}>
          {leftChildren}
        </div>
      )}
      <div className={classNames(classes.drawerContainer, containerClass)}>
        {header && (
          <DrawerHeader
            header={header}
            onClose={onClose}
            headerClasses={headerClasses}
            classes={classes}
          />
        )}
        <div
          className={classNames(classes.drawerChidrenContainer, childrenClass)}
          ref={ref}
        >
          {children}
        </div>
        {footer && (
          <DrawerFooter
            footer={footer}
            footerClasses={footerClasses}
            classes={classes}
          />
        )}
      </div>
    </MuiDrawer>
  );
}

export default forwardRef(Drawer);
