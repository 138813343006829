import React from 'react';

type PerformanceCardWrapperProps = {
  children: React.ReactNode;
  className?: string;
  testId: string;
};

const PerformanceCardWrapper = ({
  children,
  className,
  testId,
}: PerformanceCardWrapperProps) => {
  const wrapperClasses = [
    'min-h-[134px]',
    'max-h-[134px]',
    'relative',
    'flex',
    'flex-col',
    'p-3',
    'rounded-lg',
    'bg-color-white',
    'shadow-[0px_0px_10px_5px_rgba(0,0,0,0.05)]',
    'mb-6',
    'w-1/2',
  ];

  return (
    <div
      className={`${wrapperClasses.join(' ')} ${className}`}
      data-test-id={testId}
    >
      {children}
    </div>
  );
};

export default PerformanceCardWrapper;
