import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type DividerProps = MuiDividerProps & { isSection?: boolean };

type StyleProps = {
  isSection: boolean;
};

const useStyles = makeStyles(theme => ({
  root: (props: StyleProps) => ({
    backgroundColor: theme.palette.backgrounds.divider,
    borderColor: theme.palette.backgrounds.divider,
    borderWidth: props.isSection ? '8px' : '',
  }),
}));

export const Divider = (props: DividerProps) => {
  const { isSection = false, ...rest } = props;
  const classes = useStyles({ isSection });
  return <MuiDivider classes={classes} {...rest} />;
};
