import { forwardRef } from 'react';
import type { RadioVariantsPropsType } from '../Radio.types';
import RadioBase from './RadioBase';
import { getSelectedClassName, resolveClasses } from '../Radio.utils';
import { classNames } from 'utilities/Utils';

function RadioCard(
  props: RadioVariantsPropsType,
  ref?: React.Ref<HTMLDivElement>,
) {
  const { classes, customClasses = {} } = props;
  const { card, checkedCard } = classes;
  const { radio = '' } = customClasses;
  return (
    <RadioBase
      ref={ref}
      {...props}
      customClasses={{
        ...customClasses,
        radio: isSelected =>
          classNames(
            getSelectedClassName(isSelected, card, checkedCard),
            resolveClasses(radio, isSelected),
          ),
      }}
    />
  );
}

export default forwardRef(RadioCard);
