import { apiDateFormat } from '../../../constants';
import {
  getFormattedDate,
  getFutureDate,
  getMomentObject,
} from 'utilities/DateUtils';
import {
  getVendorId,
  getField,
  getPipeline,
  getPipe2line,
  getNewVendorId,
  getPropertyName,
  getIntervals,
  getDateType,
  getAnalyticsV2DateFormat,
  parseAnalyticsResponse,
  parseCancelledBookingsResponseHelper,
  getNewPropertyName,
} from './utils';
import * as AC from '../constants/Analytics';

export const parseTransactionOverviewResponse = (
  roomNightsAndNetAmountResponse,
  averageSellPriceResponse,
  roomNightsAndNetAmountRequest,
  averageSellPriceRequest,
  hotelId,
) => {
  const field = getField(roomNightsAndNetAmountRequest.interval_field);
  const brand = roomNightsAndNetAmountRequest.brand;

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: {
      no_of_competitors:
        roomNightsAndNetAmountResponse.meta_info.compset_hotel_count + 1,
    },
  };

  const parseResponse = (request, obj) => {
    request.property.forEach(property => {
      const startDate = request.start_date;
      const newProperty = getPropertyName(request.interval_field, property);

      res.comp.data[brand][property] = {
        [startDate]: {
          value: obj[field][AC.CURRENT_INTERVAL_STAT][newProperty].comp_value,
        },
      };

      res.hotel[hotelId][brand][property] = {
        [startDate]: {
          value: obj[field][AC.CURRENT_INTERVAL_STAT][newProperty].value,
          rank: obj[field][AC.CURRENT_INTERVAL_STAT][newProperty].rank,
          share: obj[field][AC.CURRENT_INTERVAL_STAT][newProperty].share,
          boost:
            obj[field][AC.CURRENT_INTERVAL_STAT][newProperty].boost_pc_prev > 0,
          boost_pc:
            obj[field][AC.CURRENT_INTERVAL_STAT][newProperty].boost_pc_prev,
        },
      };
    });
  };

  parseResponse(roomNightsAndNetAmountRequest, roomNightsAndNetAmountResponse);
  parseResponse(averageSellPriceRequest, averageSellPriceResponse);

  return res;
};

export const getTransactionOverviewVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipeline(request.pipeline);

  const currentIntervalEndDate = getFormattedDate(
    getFutureDate(new Date(), 1),
    apiDateFormat,
  );

  const currentIntervalStartDate = getFormattedDate(
    getFutureDate(
      currentIntervalEndDate,
      request.period * -1,
      request.interval,
    ),
    apiDateFormat,
  );

  const previousIntervalEndDate = currentIntervalStartDate;

  const previousIntervalStartDate = getFormattedDate(
    getFutureDate(
      previousIntervalEndDate,
      request.period * -1,
      request.interval,
    ),
    apiDateFormat,
  );

  const previousIntervalRange = [
    AC.PREVIOUS_INTERVAL_STAT,
    getAnalyticsV2DateFormat(previousIntervalStartDate, field),
    getAnalyticsV2DateFormat(previousIntervalEndDate, field),
  ];
  const currentIntervalRange = [
    AC.CURRENT_INTERVAL_STAT,
    getAnalyticsV2DateFormat(currentIntervalStartDate, field),
    getAnalyticsV2DateFormat(currentIntervalEndDate, field),
  ];

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
      confirmStatus: [1, 2, 10, 11, 14],
    },
    intervals: [
      {
        field: field,
        type: 'range',
        range_intervals: [previousIntervalRange, currentIntervalRange],
      },
    ],
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['boost_pc_prev', 'share', 'rank'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const getRoomNightsSectionVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(new Date(), apiDateFormat);

  const startDate = request.start_date;

  const intervals = getIntervals(
    request.period,
    request.interval,
    field,
    startDate,
    endDate,
  );

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
    },
    intervals: intervals,
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['share', 'rank'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseRoomNightsSectionResponse = (response, request, hotelId) => {
  const field = getField(request.interval_field);
  const brand = request.brand;

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: { no_of_competitors: response.meta_info.compset_hotel_count + 1 },
  };

  const data =
    request.interval_field === 'stay'
      ? response[field][AC.STAY_INTERVAL]
      : response;

  const keyValuesObject = {
    comp: [{ key: 'value', value: 'comp_value' }],
    hotel: [
      { key: 'value', value: 'value' },
      { key: 'share', value: 'share' },
      { key: 'rank', value: 'rank' },
    ],
  };

  parseAnalyticsResponse(hotelId, data[field], request, res, keyValuesObject);

  return res;
};

export const getAvgSellingPriceVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(new Date(), apiDateFormat);

  const startDate = request.start_date;

  const intervals = getIntervals(
    request.period,
    request.interval,
    field,
    startDate,
    endDate,
  );

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
    },
    intervals: intervals,
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['share', 'rank'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseAvgSellingPriceResponse = (data, request, hotelId) => {
  const field = getField(request.interval_field);
  const brand = request.brand;

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: { no_of_competitors: data.meta_info.compset_hotel_count + 1 },
  };

  const keyValuesObject = {
    comp: [{ key: 'value', value: 'comp_value' }],
    hotel: [
      { key: 'value', value: 'value' },
      { key: 'share', value: 'share' },
      { key: 'rank', value: 'rank' },
    ],
  };

  parseAnalyticsResponse(hotelId, data[field], request, res, keyValuesObject);

  return res;
};

export const getHotelVisitVariables = (request, hotelId) => {
  const pipeline = getPipe2line(request.pipeline);
  const vendorId = getNewVendorId(request.brand[0]);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);
  const endDate = getFormattedDate(new Date(), apiDateFormat);

  const field =
    request.interval_field === 'booking' ? 'searchDate' : 'searchCheckin';

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      'hotel.hotelCode': hotelId,
      brand: vendorId,
    },
    KPI: [{ visits: 'sum' }],
    comparison: ['compset'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const getConversionRateVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);
  const endDate = getFormattedDate(new Date(), apiDateFormat);

  const field =
    request.interval_field === 'booking' ? 'bookingDate' : 'checkinDate';

  const payload = {
    pipeline: 'transactions',
    include: {
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
    },
    KPI: [{ conversionRate: 'custom' }],
    comparison: ['compset'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseDemandOverviewRes = (
  hotelVisitsData,
  conversionRatesData,
  request,
  hotelId,
) => {
  const brand = request.brand;
  const startDate = request.start_date;

  const res = {
    comp: {
      data: {
        [brand]: {
          demandHotelVisits: {
            [startDate]: {
              value: hotelVisitsData.visits
                ? hotelVisitsData.visits.comp_value
                : 0,
            },
          },
          demandRoomNights: {
            [startDate]: {
              value: conversionRatesData.conversionRate
                ? conversionRatesData.conversionRate.comp_value
                : 0,
            },
          },
        },
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {
          demandHotelVisits: {
            [startDate]: {
              value: hotelVisitsData.visits ? hotelVisitsData.visits.value : 0,
            },
          },
          demandRoomNights: {
            [startDate]: {
              value: conversionRatesData.conversionRate
                ? conversionRatesData.conversionRate.value
                : 0,
            },
          },
        },
      },
    },
    meta: {
      no_of_competitors: hotelVisitsData.meta_info
        ? hotelVisitsData.meta_info.compset_hotel_count + 1
        : 0,
    },
  };

  return res;
};

export const getVisibilitySectionVariables = (request, hotelId) => {
  const vendorId = getNewVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipe2line(request.pipeline);

  const endDate = getFormattedDate(new Date(), apiDateFormat);

  const startDate = getFormattedDate(
    getFutureDate(endDate, request.period * -1, request.interval),
    apiDateFormat,
  );
  const KPI = request.property.map(property => {
    return {
      [getNewPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const intervals = getIntervals(
    request.period,
    request.interval,
    field,
    startDate,
    endDate,
  );

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: `${startDate}`,
        lte: `${endDate}`,
      },
      'hotel.hotelCode': hotelId,
      brand: vendorId,
    },
    intervals: intervals,
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['share', 'rank'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseVisibilitySectionResponse = (data, request, hotelId) => {
  const field = getField(request.interval_field);
  const brand = request.brand;
  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: { no_of_competitors: data.meta_info.compset_hotel_count + 1 },
  };

  const keyValuesObject = {
    comp: [{ key: 'value', value: 'comp_value' }],
    hotel: [
      { key: 'value', value: 'value' },
      { key: 'share', value: 'share' },
      { key: 'rank', value: 'rank' },
    ],
  };

  parseAnalyticsResponse(hotelId, data[field], request, res, keyValuesObject);

  return res;
};

export const getConversionRateTrendVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(new Date(), apiDateFormat);

  const startDate = getFormattedDate(
    getFutureDate(endDate, request.period * -1, request.interval),
    apiDateFormat,
  );

  const intervals = getIntervals(
    request.period,
    request.interval,
    field,
    startDate,
    endDate,
  );
  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
    },
    intervals: intervals,
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['share'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const getRoomNightsMonthWiseTrendVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);

  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  KPI.push({ bookingId: 'value_count' });

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
    },
    intervals: [
      ...(request.interval_field === 'stay'
        ? [
          {
            type: 'range',
            field: field,
            range_intervals: [
              [
                AC.STAY_INTERVAL,
                startDate,
                getFormattedDate(
                  getFutureDate(request.end_date, 1),
                  apiDateFormat,
                ),
              ],
            ],
          },
        ]
        : []),
      {
        field: field,
        type: request.view,
      },
    ],
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['share', 'rank'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const getRoomNightsMonthWiseTotalVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);

  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
    },
    ...(request.interval_field === 'stay' && {
      intervals: [
        {
          type: 'range',
          field: field,
          range_intervals: [
            [
              AC.STAY_INTERVAL,
              startDate,
              getFormattedDate(
                getFutureDate(request.end_date, 1),
                apiDateFormat,
              ),
            ],
          ],
        },
      ],
    }),
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['share', 'rank'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseRoomNightsMonthWiseResponse = (
  trendResponse,
  totalResponse,
  request,
  hotelId,
) => {
  const field = getField(request.interval_field);
  const brand = request.brand;

  const trendData =
    request.interval_field === 'stay'
      ? trendResponse[field][AC.STAY_INTERVAL]
      : trendResponse;

  const totalData =
    request.interval_field === 'stay'
      ? totalResponse[field][AC.STAY_INTERVAL]
      : totalResponse;

  const totalDataValAccessor =
    request.interval_field === 'stay' ? 'dayWise.roomNights' : 'roomNights';

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: {
      no_of_competitors: trendResponse.meta_info.compset_hotel_count + 1,
      hotel: {
        room_nights_rank: totalData[totalDataValAccessor].rank,
        room_nights_share: totalData[totalDataValAccessor].share,
        room_nights_total: totalData[totalDataValAccessor].value,
      },
      comp: {
        room_nights_total: totalData[totalDataValAccessor].comp_value,
      },
    },
  };

  const keyValuesObject = {
    comp: [{ key: 'value', value: 'comp_value' }],
    hotel: [
      { key: 'value', value: 'value' },
      { key: 'share', value: 'share' },
      { key: 'rank', value: 'rank' },
    ],
  };

  parseAnalyticsResponse(
    hotelId,
    trendData[field],
    request,
    res,
    keyValuesObject,
    true,
  );

  return res;
};

export const getRoomNightsLsYrDataVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [field]: {
        gte: getAnalyticsV2DateFormat(startDate, field),
        lte: getAnalyticsV2DateFormat(endDate, field, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
    },
    intervals: [
      ...(request.interval_field === 'stay'
        ? [
          {
            type: 'range',
            field: field,
            range_intervals: [
              [
                AC.STAY_INTERVAL,
                startDate,
                getFormattedDate(
                  getFutureDate(request.end_date, 1),
                  apiDateFormat,
                ),
              ],
            ],
          },
        ]
        : []),
      {
        field: field,
        type: 'month',
      },
    ],
    KPI: KPI,
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

const parseRoomNightsLsYrHelper = (res, request, hotelId) => {
  const field = getField(request.interval_field);
  const brand = request.brand;

  const {
    data: response,
    status,
    message,
  } = res.data.rawEndpoint.analyticsHistogram.modelType1;

  const finalRes = {
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: {
      no_of_competitors: response?.meta_info?.compset_hotel_count + 1,
      hotel: {},
    },
  };

  const data =
    request.interval_field === 'stay'
      ? response[field][AC.STAY_INTERVAL]
      : response;

  const keyValuesObject = {
    hotel: [{ key: 'value', value: 'value' }],
  };

  const totalRoomNights = parseAnalyticsResponse(
    hotelId,
    data[field],
    request,
    finalRes,
    keyValuesObject,
    false,
    true,
  );

  finalRes.meta = {
    ...finalRes.meta,
    hotel: {
      room_nights_total: totalRoomNights,
    },
  };

  return { data: finalRes, status, message };
};

export const parseRoomNightsLsYrDataResponse = (
  data,
  request,
  hotelId,
  dataType,
) => {
  const valAccessor = dataType === 'hotelData' ? 'room_nights' : dataType;
  const [lastYearRes, currYearRes] = data;
  const brand = request.brand;

  const lastYearData = parseRoomNightsLsYrHelper(lastYearRes, request, hotelId);
  const currYearData = parseRoomNightsLsYrHelper(currYearRes, request, hotelId);

  const res = [lastYearData, currYearData];

  let errMessage = null;

  const modifiedData = res.reduce(
    (finalData, currentItem, index) => {
      const temp = currentItem;
      if (temp && temp.status === 'success' && temp.data) {
        const hotelBookingsData = temp.data;
        const data =
          hotelBookingsData.hotel[hotelId][brand]?.[valAccessor] || {};
        const type = index === 0 ? 'curYrData' : 'prevYrData';

        finalData[type][dataType] = data;
        finalData[type].meta = hotelBookingsData.meta;
      } else {
        errMessage = temp.message;
      }
      return finalData;
    },
    {
      prevYrData: { [dataType]: {}, meta: {} },
      curYrData: { [dataType]: {}, meta: {} },
    },
  );

  return { errMessage, modifiedData };
};

export const getRoomNightsLOSTrendVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const field = getField(request.interval_field);
  const dateType = getDateType(request.date_type);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [dateType]: {
        gte: getAnalyticsV2DateFormat(startDate, dateType),
        lte: getAnalyticsV2DateFormat(endDate, dateType, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
    },
    intervals: {
      field: field,
      type: 'custom',
      custom_intervals: AC.LOS_TREND_CUSTOM_INTERVALS,
    },
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['interval_share', 'rank'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const getRoomNightsLOSAverageVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const dateType = getDateType(request.date_type);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const payload = {
    pipeline: pipeline,
    include: {
      [dateType]: {
        gte: getAnalyticsV2DateFormat(startDate, dateType),
        lte: getAnalyticsV2DateFormat(endDate, dateType, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
      los: {
        gte: 1,
        lte: 1000,
      },
    },
    KPI: [
      {
        roomNights: 'avg',
      },
    ],
    comparison: ['compset'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseRoomNightsLOSResponse = (
  trendData,
  averageData,
  request,
  hotelId,
) => {
  const field = getField(request.interval_field);
  const brand = request.brand;

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: {
      no_of_competitors: trendData.meta_info.compset_hotel_count + 1,
      comp: {
        average_los: averageData.roomNights.comp_value,
      },
      hotel: {
        average_los: averageData.roomNights.value,
      },
    },
  };

  const keyValuesObject = {
    comp: [
      { key: 'value', value: 'comp_value' },
      { key: 'portion', value: 'comp_interval_share' },
    ],
    hotel: [
      { key: 'value', value: 'value' },
      { key: 'portion', value: 'interval_share' },
      { key: 'rank', value: 'rank' },
    ],
  };

  parseAnalyticsResponse(
    hotelId,
    trendData[field],
    request,
    res,
    keyValuesObject,
  );

  return res;
};

export const getRoomNightsMealPlanVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const dateType = getDateType(request.date_type);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [dateType]: {
        gte: getAnalyticsV2DateFormat(startDate, dateType),
        lte: getAnalyticsV2DateFormat(endDate, dateType, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
      ...(request.filter &&
        request.filter.room_id && { roomId: request.filter.room_id }),
    },
    intervals: AC.MEAL_PLAN_INTERVALS,
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['interval_share'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseRoomNightsMealPlanResponse = (data, request, hotelId) => {
  const field = 'filter_aggs';
  const brand = request.brand;

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: {
      no_of_competitors: data.meta_info.compset_hotel_count + 1,
      hotel: {},
    },
  };

  const keyValuesObject = {
    comp: [
      { key: 'value', value: 'comp_value' },
      { key: 'portion', value: 'comp_interval_share' },
    ],
    hotel: [
      { key: 'value', value: 'value' },
      { key: 'portion', value: 'interval_share' },
    ],
  };

  const totalRoomNights = parseAnalyticsResponse(
    hotelId,
    data[field],
    request,
    res,
    keyValuesObject,
    false,
    true,
  );

  res.meta = {
    ...res.meta,
    hotel: {
      room_nights_total: totalRoomNights,
    },
  };

  return res;
};

export const getRoomNightsPaxVariables = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const dateType = getDateType(request.date_type);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const KPI = request.property.map(property => {
    return {
      [getPropertyName(request.interval_field, property)]:
        AC.propertyKPIMap[property],
    };
  });

  const payload = {
    pipeline: pipeline,
    include: {
      [dateType]: {
        gte: getAnalyticsV2DateFormat(startDate, dateType),
        lte: getAnalyticsV2DateFormat(endDate, dateType, true),
      },
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: AC.CONFIRM_STATUS_VALUE,
      ...(request.filter &&
        request.filter.room_id && { roomId: request.filter.room_id }),
    },
    intervals: AC.ROOM_NIGHTS_PAX_INTERVALS,
    KPI: KPI,
    comparison: ['compset'],
    custom_metric: ['interval_share'],
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseRoomNightsPaxResponse = (data, request, hotelId) => {
  const field = 'filter_aggs';
  const brand = request.brand;

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: {
      no_of_competitors: data.meta_info.compset_hotel_count + 1,
      hotel: {},
    },
  };

  const keyValuesObject = {
    comp: [
      { key: 'value', value: 'comp_value' },
      { key: 'portion', value: 'comp_interval_share' },
    ],
    hotel: [
      { key: 'value', value: 'value' },
      { key: 'portion', value: 'interval_share' },
    ],
  };

  const totalRoomNights = parseAnalyticsResponse(
    hotelId,
    data[field],
    request,
    res,
    keyValuesObject,
    false,
    true,
  );

  res.meta = {
    ...res.meta,
    hotel: {
      room_nights_total: totalRoomNights,
    },
  };

  return res;
};

export const getCancelledBookingsVariable = (request, hotelId) => {
  const vendorId = getVendorId(request.brand[0]);
  const pipeline = getPipeline(request.pipeline);

  const endDate = getFormattedDate(request.end_date, apiDateFormat);
  const startDate = getFormattedDate(request.start_date, apiDateFormat);

  const lastYRStartDate = getFormattedDate(
    getMomentObject(request.start_date, apiDateFormat).subtract(1, 'year'),
    apiDateFormat,
  );
  const lastYREndDate = getFormattedDate(
    getMomentObject(request.end_date, apiDateFormat).subtract(1, 'year'),
    apiDateFormat,
  );

  const payload = {
    pipeline: pipeline,
    include: {
      ...(request.comp_data && {
        bookingDate: {
          gte: `${startDate} 00:00:00`,
          lt: `${endDate} 00:00:00`,
        },
      }),
      hotelId: hotelId,
      vendorId: vendorId,
      confirmStatusValue: [...AC.CONFIRM_STATUS_VALUE, 'cancelled'],
    },
    intervals: [
      ...(!request.comp_data
        ? [
          {
            field: 'bookingDate',
            type: 'range',
            range_intervals: [
              [
                'PREVIOUS_YEAR',
                `${lastYRStartDate} 00:00:00`,
                `${lastYREndDate} 00:00:00`,
              ],
              [
                'CURRENT_YEAR',
                `${startDate} 00:00:00`,
                `${endDate} 00:00:00`,
              ],
            ],
          },
        ]
        : []),
      {
        type: 'filter',
        filter_intervals: {
          'Cancelled Bookings': {
            include: {
              confirmStatusValue: 'cancelled',
            },
          },
          'Total Bookings': {},
        },
      },
      {
        field: 'ap',
        type: 'range',
        range_intervals: [
          ['D0', 0, 1],
          ['D1', 1, 2],
          ['D-2-3', 2, 4],
          ['D-4-5', 4, 6],
          ['D-6-10', 6, 11],
          ['D-10+', 11, 500],
        ],
      },
    ],
    KPI: [
      {
        bookingId: 'value_count',
      },
    ],
    custom_metric: ['interval_share'],
    ...(request.comp_data && {
      comparison: request.comparison === 'city' ? ['city'] : ['compset'],
    }),
  };

  return {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: payload,
      },
    },
  };
};

export const parseCancelledBookingsLastYrResponse = (
  res,
  request,
  hotelId,
  yearType,
) => {
  const cancelledData =
    res.data.bookingDate[yearType].filter_aggs['Cancelled Bookings'];
  const totalData =
    res.data.bookingDate[yearType].filter_aggs['Total Bookings'];

  const field = getField(request.interval_field);
  const brand = request.brand;

  const { status, message } = res;

  const finalRes = {
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: { hotel: {} },
  };

  const keyValuesObject = {
    hotel: [
      { key: 'cancelled', value: 'value', dataValAccessor: 'cancelled' },
      { key: 'total', value: 'value', dataValAccessor: 'total' },
      { key: 'share', value: 'nterval_share', dataValAccessor: 'cancelled' },
    ],
  };

  const { totalBookings, totalCancelledBookings } =
    parseCancelledBookingsResponseHelper(
      hotelId,
      totalData[field],
      cancelledData[field],
      request,
      finalRes,
      keyValuesObject,
    );

  finalRes.meta = {
    ...finalRes.meta,
    hotel: {
      bookings_cancelled: totalCancelledBookings,
      bookings_total: totalBookings,
    },
  };

  return { data: finalRes, status, message };
};

export const parseCancelledBookingsResponse = (data, request, hotelId) => {
  const field = getField(request.interval_field);
  const brand = request.brand;

  const cancelledData = data.filter_aggs['Cancelled Bookings'];
  const totalData = data.filter_aggs['Total Bookings'];

  const res = {
    comp: {
      data: {
        [brand]: {},
      },
    },
    hotel: {
      [hotelId]: {
        [brand]: {},
      },
    },
    meta: {
      no_of_competitors: data.meta_info.compset_hotel_count + 1,
      hotel: {},
      comp: {},
    },
  };

  const keyValuesObject = {
    comp: [
      { key: 'cancelled', value: 'comp_value', dataValAccessor: 'cancelled' },
      { key: 'total', value: 'comp_value', dataValAccessor: 'total' },
      {
        key: 'portion',
        value: 'comp_interval_share',
        dataValAccessor: 'cancelled',
      },
    ],
    hotel: [
      { key: 'cancelled', value: 'value', dataValAccessor: 'cancelled' },
      { key: 'total', value: 'value', dataValAccessor: 'total' },
      { key: 'portion', value: 'nterval_share', dataValAccessor: 'cancelled' },
    ],
  };

  const {
    totalBookings,
    totalCancelledBookings,
    compTotalBookings,
    compTotalCancelledBookings,
  } = parseCancelledBookingsResponseHelper(
    hotelId,
    totalData[field],
    cancelledData[field],
    request,
    res,
    keyValuesObject,
    true,
  );

  res.meta = {
    ...res.meta,
    hotel: {
      bookings_total: totalBookings,
      bookings_cancelled: totalCancelledBookings,
    },
    comp: {
      bookings_total: compTotalBookings,
      bookings_cancelled: compTotalCancelledBookings,
    },
  };

  return res;
};
