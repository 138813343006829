import { NavHelpDefault } from 'assets/common';
import { Tooltip } from 'components/latest-core';
import React from 'react';

interface IQuestionTooltip {
  content: React.ReactNode;
  header?: string;
  className?: string;
  bgBlack?: boolean;
  titleWithContent?: boolean;
  size?: number;
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
}

const QuestionTooltip = (props: IQuestionTooltip) => {
  const {
    content,
    className,
    placement = 'bottom',
    bgBlack = true,
    titleWithContent = false,
    header,
    size = 20,
  } = props;
  return (
    <Tooltip
      bgBlack={bgBlack}
      content={content}
      titleWithContent={titleWithContent}
      header={header}
      placement={placement}
    >
      <img
        src={NavHelpDefault}
        alt={'help-tooltip'}
        width={size}
        height={size}
        className={className}
      />
    </Tooltip>
  );
};

export default QuestionTooltip;
