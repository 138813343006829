import React from 'react';
import { Close } from 'assets/common';
import { classNames } from 'utilities/Utils';
import type { ModalHeaderPropTypes } from '../Modal.types';

function ModalHeader(props: ModalHeaderPropTypes) {
  const { header, onClose, headerClasses, classes } = props;
  const {
    root = '',
    title = '',
    subTitle = '',
    rightCrossElem = '',
  } = headerClasses || {};

  return (
    <div className={classNames(classes.headerContainer, root)}>
      {header.leftElem}
      <div className={classes.headerChildContainer}>
        {header.title && (
          <div className={classNames(classes.headerTitleContainer, title)}>
            {header.title}
          </div>
        )}
        {header.subTitle && (
          <div
            className={classNames(classes.headerSubTitleContainer, subTitle)}
          >
            {header.subTitle}
          </div>
        )}
      </div>
      {!header.hideCross && (
        <div
          onClick={onClose}
          className={classNames(
            classes.headerCrossIconContainer,
            rightCrossElem,
          )}
        >
          <Close />
        </div>
      )}
      {header.rightElem}
    </div>
  );
}

export default React.memo(ModalHeader);
