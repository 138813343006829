import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import * as CommonIcons from 'assets/common';
import { __LegacyDropdown__ as Dropdown } from '../Dropdown/__LegacyDropdown__/__LegacyDropdown__';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography/Typography';
import { useLangStrings } from 'utilities/CustomHooks';
import { pushToOmniture } from 'utilities/gtm';

export const DEFAULT_PAGE_SIZE_OPTIONS = [
  {
    title: '5 per page',
    value: 5,
  },
  {
    title: '10 per page',
    value: 10,
  },
  {
    title: '15 per page',
    value: 15,
  },
  {
    title: '20 per page',
    value: 20,
  },
  {
    title: '25 per page',
    value: 25,
  },
];
const useStyles = makeStyles(() => ({
  paginate: {
    background: 'transparent',
    position: 'absolute',
    bottom: '0px',
    width: 'auto',
  },
  pageNums: {
    overflow: 'auto',
    marginLeft: 'none !important',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
  },
  pageBtn: {
    padding: '6px 10px',
    width: 'auto',
    height: '30px',
    fontSize: '14px',
    fontWeight: 500,
    background: '#fff',
    borderRadius: '4px',
    marginRight: '8px',
    color: '#3543bf',
    cursor: 'pointer',
  },
  activeBtn: {
    color: '#404040',
    background: '#eff1ff',
  },
  nextprev: {
    position: 'relative',
    left: '12px',
    marginTop: '4px',
    height: '30px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: '#3543bf',
  },
  disableBtn: {
    color: '#c2c7ec !important',
    cursor: 'not-allowed',
  },
  dropdownEl: {
    width: '125px',
  },
}));
export type PaginationPropType = {
  onPageChange: (page: number, count?: number) => void;
  setDataPerPage: (count: number) => void;
  totalPages: number;
  currentPage: number;
  dataPerPage: number;
  options?: {
    title: string;
    value: number;
  }[];
  onPrevClick?: () => void;
  onNextClick?: () => void;
};
export function usePagination(maxDataLength: number, defaultPageSize = 5) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataPerPage, setDataPerPage] = useState<number>(defaultPageSize);
  const totalPages = Math.round(Math.ceil(maxDataLength / dataPerPage));
  const [startRange, endRange] = [
    dataPerPage * (currentPage - 1),
    dataPerPage * currentPage,
  ];
  const onPageChange: PaginationPropType['onPageChange'] = page => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: `page :${currentPage + 1}`,
        type: 'click',
        componentName: 'page_selection',
      },
    });
    setCurrentPage(page);
  };
  return {
    currentPage,
    dataPerPage,
    totalPages,
    startRange,
    endRange,
    onPageChange,
    setDataPerPage,
  };
}
export default function Pagination(props: PaginationPropType) {
  const {
    onPageChange,
    setDataPerPage,
    totalPages,
    currentPage,
    dataPerPage,
    options = DEFAULT_PAGE_SIZE_OPTIONS,
    onPrevClick,
    onNextClick,
  } = props;
  const [COMMON_STRINGS] = useLangStrings<'Common'>('Common');
  const pageNumsRef: React.RefObject<HTMLElement> = useRef();
  const classes = useStyles();

  const getBtnsStateBasedOnPageInfo = () => ({
    disableLeftBtn: currentPage === 1,
    disableRightBtn: currentPage === totalPages,
  });

  const [disableBtn, setDisableBtn] = useState(getBtnsStateBasedOnPageInfo());
  const { disableLeftBtn, disableRightBtn } = disableBtn;

  const handleScroll = direction => {
    if (direction === 'left') {
      pageNumsRef.current.scrollLeft -= 150;
    } else {
      pageNumsRef.current.scrollLeft += 150;
    }
    setDisableBtn(prevState => ({
      ...prevState,
      disableLeftBtn: pageNumsRef.current.scrollLeft === 0,
    }));
  };
  const pageRefs = useMemo(() => {
    return [...Array(totalPages)].map(() => {
      return React.createRef<HTMLDivElement>();
    });
  }, [totalPages]);

  useEffect(() => {
    // Scroll to the active page button
    if (pageNumsRef.current && pageRefs[currentPage - 1]?.current) {
      const container = pageNumsRef.current;
      const targetTab = pageRefs[currentPage - 1].current;

      const targetTabOffset = targetTab.offsetLeft;
      const containerWidth = container.offsetWidth;
      const targetTabWidth = targetTab.offsetWidth;

      const targetScrollLeft =
        targetTabOffset - (containerWidth - targetTabWidth * 2);

      container.scrollTo({
        left: targetScrollLeft,
        behavior: 'smooth',
      });
    }
    setDisableBtn(getBtnsStateBasedOnPageInfo());
  }, [currentPage]);

  if (totalPages === 0) return null;

  return (
    <Box
      className={`${classes.paginate} ${totalPages <= 5 ? 'mb-2' : ''}`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" alignItems="center">
        {totalPages > 5 && (
          <Box
            className={`${classes.nextprev} ${
              disableLeftBtn ? classes.disableBtn : ''
            }`}
            onClick={() => {
              if (!disableLeftBtn) {
                handleScroll('left');
                onPrevClick?.();
              }
            }}
          >
            {disableLeftBtn ? (
              <CommonIcons.ChevronDisabledLeft />
            ) : (
              <CommonIcons.ChevronLeft />
            )}
            <Typography
              variant="body1"
              fb
              themeColor={`${
                disableLeftBtn ? 'primary.light' : 'primary.main'
              }`}
            >
              {COMMON_STRINGS.BUTTONS.PREV_CAPITAL_CASE}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          ml={3}
          {...{ ref: pageNumsRef }}
          className={`${classes.pageNums} ${
            totalPages > 5 ? 'max-w-[165px]' : 'max-w-[180px]'
          }`}
          mt={totalPages > 5 ? 2 : 0}
        >
          {[...Array(totalPages)].map((_, index) => {
            return (
              <div ref={pageRefs[index]} key={index}>
                <Box
                  className={`${classes.pageBtn} ${
                    index === currentPage - 1 ? classes.activeBtn : ''
                  }`}
                  onClick={() => onPageChange(index + 1)}
                >
                  {index + 1}
                </Box>
              </div>
            );
          })}
        </Box>
        {totalPages > 5 && (
          <Box
            className={`${classes.nextprev} ${
              disableRightBtn ? classes.disableBtn : ''
            }`}
            onClick={() => {
              if (!disableRightBtn) {
                handleScroll('right');
                onNextClick?.();
              }
            }}
          >
            <Typography
              variant="body1"
              fb
              themeColor={`${
                disableRightBtn ? 'primary.light' : 'primary.main'
              }`}
            >
              {COMMON_STRINGS.BUTTONS.NEXT_CAPITAL_CASE}
            </Typography>
            {disableRightBtn ? (
              <CommonIcons.ChevronDisabledRight />
            ) : (
              <CommonIcons.ChevronRight />
            )}
          </Box>
        )}
      </Box>
      <Box marginLeft={4} className={classes.dropdownEl}>
        <Dropdown
          name="pagination-ddown"
          options={options}
          labelField="title"
          value={options.find(x => x.value === dataPerPage)}
          onChange={(v: { title: string; value: number }) => {
            setDataPerPage(v.value);
            onPageChange(1, v.value);
          }}
          disableClear
          noBorder
        />
      </Box>
    </Box>
  );
}
