import { useEffect, useState } from 'react';
import { Button, Chip } from 'components/latest-core';
import { getFormattedDate } from 'utilities/DateUtils';

type BlackoutChipsProps = {
  groupedDates: Array<Date[]>;
  handleRemoveDates: (index: number, groupedDates: Array<Date[]>) => void;
  dateFormat?: string;
  maxChips?: number;
};

export const BlackoutChips = (props: BlackoutChipsProps) => {
  const {
    groupedDates,
    handleRemoveDates,
    dateFormat = 'MMM DD YYYY, ddd',
    maxChips = 5,
  } = props;
  const [showMore, setShowMore] = useState(false);

  const chipLabel = (dates: Date[]) => {
    const firstDay = getFormattedDate(dates[0], dateFormat);
    const lastDay = getFormattedDate(dates[dates.length - 1], dateFormat);
    return dates.length === 1 ? firstDay : `${firstDay} - ${lastDay}`;
  };

  useEffect(() => {
    if (showMore && groupedDates.length <= maxChips) {
      setShowMore(false);
    }
  }, [groupedDates, showMore, maxChips]);

  return (
    <div className="flex flex-wrap gap-1 pt-1">
      {(showMore ? groupedDates : groupedDates.slice(0, maxChips)).map(
        (dates, index) => (
          <Chip
            label={chipLabel(dates)}
            onDelete={() => handleRemoveDates(index, groupedDates)}
          />
        ),
      )}
      {showMore ? (
        <Button variant="text" onClick={() => setShowMore(false)}>
          Show Less
        </Button>
      ) : groupedDates.length > maxChips ? (
        <Button variant="text" onClick={() => setShowMore(true)}>
          {`+ ${groupedDates.length - maxChips} More`}
        </Button>
      ) : null}
    </div>
  );
};
