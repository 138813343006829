import { Typography } from 'components/latest-core';
import { Cross } from 'assets/modules/daily-steal-deal';

type CheckboxChipProps = {
  incomingChipValue: unknown[];
  option: unknown;
  disabled: boolean;
  optionLabel: (tag: unknown) => string;
  onChange: (tags: unknown[], name: string, value: string) => void;
  name: string;
  isMultiple: boolean;
};

const CheckboxChip = ({
  incomingChipValue,
  option,
  disabled,
  optionLabel,
  onChange,
  name,
  isMultiple,
}: CheckboxChipProps) => {
  const wprClasses = [
    'flex items-center gap-1 rounded-full border border-solid border-[1px] px-[6px]',
    disabled
      ? 'bg-color-light border-color-divider pointer-events-none'
      : 'border-primary-light bg-color-primary',
    isMultiple ? 'ml-2' : '',
  ];

  return (
    <div className={wprClasses.join(' ')}>
      <Typography
        variant="body1"
        className="max-w-[100px] text-ellipsis overflow-hidden whitespace-nowrap"
      >
        {optionLabel(option)}
      </Typography>
      <Cross
        className="cursor-pointer"
        height={10}
        width={10}
        onClick={() => {
          const newTags = incomingChipValue.filter(
            tag => optionLabel(tag) !== optionLabel(option),
          );
          onChange(newTags, name, '');
        }}
        aria-disabled={disabled}
        stroke={disabled ? '#D2D1D1' : '#3543BF'}
      />
    </div>
  );
};

export default CheckboxChip;
