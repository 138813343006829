import { useMemo } from 'react';
import {
  ControlledInput,
  Modal,
  ControlledCheckbox,
} from 'components/latest-core';
import { FormElement, Button } from '../../components/new-core';

import { acceptTnC } from './HotelAPIClient';
import { useForm, useWatch } from 'react-hook-form';
import {
  useLangStrings,
  useOpenDocumentLink,
} from '../../utilities/CustomHooks';
import { showMessage } from 'utilities/Utils';
import { IAgreement, ITnC, TnCModalFormType } from './interfaces';
import {
  domPhoneRegex,
  emailRegex,
  nameRegex,
  phoneRegex,
} from 'utilities/allRegex';

type CompProps = {
  data: ITnC;
  isDomHotel: boolean;
  hotelCode: string;
  onClose: () => void;
};

function agreementString(agreement_list, _STRINGS) {
  let isHSAgreement = false;
  let STRINGS = _STRINGS.NORMAL;

  for (let i = 0; i < agreement_list.length; i++) {
    if (agreement_list[i].agreement_id === 289) {
      STRINGS = _STRINGS.IMPACT_COVID;
    } else if (agreement_list[i].agreement_id === 275) {
      isHSAgreement = true;
      STRINGS = _STRINGS.HS_AGREEMENT;
    } else if (
      agreement_list[i].agreement_id === 285 ||
      agreement_list[i].agreement_id === 287
    ) {
      STRINGS = _STRINGS.CLEANSTAY_OR_CORPBOOKING;
    } else if (
      agreement_list[i].agreement_id === 283 ||
      agreement_list[i].agreement_id === 284 ||
      agreement_list[i].agreement_id === 288
    ) {
      STRINGS = _STRINGS.CLEANSTAY_OR_CORPBOOKING;
    }
  }

  return { STRINGS, isHSAgreement };
}

export default function TermsAndConditionsModal(props: CompProps) {
  const {
    data: { agreement_list: agreementList, insurmountable },
    isDomHotel,
    onClose,
    hotelCode,
  } = props;
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<TnCModalFormType>({ shouldUnregister: true });
  const [{ TERMS_AND_CONDITION: STRINGS }, COMMON] =
    useLangStrings<'Dashboard'>('Dashboard');
  const [openDocument] = useOpenDocumentLink();

  const checkbox = useWatch({
    control,
    name: 'ignore',
  });

  const showError = () =>
    showMessage({
      show: true,
      message: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
      type: 'error',
    });

  const onSubmit = postdata => {
    postdata.hotelcode = hotelCode;
    acceptTnC(hotelCode, postdata).then(onClose).catch(showError);
  };

  const renderAgreementList = (_agreementList: IAgreement[]) => {
    return (
      <div className="pad16" style={{ border: '1px solid #d3d3d3' }}>
        {_agreementList.map(agreement => (
          <div className="marginTB4">
            {agreement.agreement_display_name}
            <Button
              className="marginL8 capitalize"
              variant="text"
              onClick={() => openDocument(agreement.sample_url)}
            >
              {COMMON.BUTTONS.VIEW_DETAILS}
            </Button>
          </div>
        ))}
      </div>
    );
  };

  const {
    STRINGS: { HEADER, HOTELIER_MESSAGE, CHECKBOX },
    isHSAgreement,
  } = useMemo(
    () => agreementString(agreementList, STRINGS),
    [agreementList, STRINGS],
  );

  return (
    <Modal
      isOpen
      size="custom"
      header={{
        title: HEADER,
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: 'I accept',
          onClick: handleSubmit(onSubmit),
          disabled: !checkbox,
        },
        secondaryBtn: insurmountable
          ? null
          : {
            text: 'Close',
            onClick: onClose,
          },
      }}
      onClose={onClose}
      customClasses={{
        paper: 'w-[600px]',
      }}
    >
      <div>
        <p>
          {STRINGS.GREET},<p className="marginT15">{HOTELIER_MESSAGE}</p>
        </p>

        {agreementList.length > 0 && renderAgreementList(agreementList)}

        <div className="sr-flex-row marginT15">
          <FormElement
            label="Name"
            errorMsg={errors?.name?.message?.toString()}
            isRequired
          >
            <ControlledInput
              name="name"
              type="text"
              control={control}
              placeholder="Full Name"
              pattern={nameRegex}
              isRequired
            />
          </FormElement>
          <FormElement
            label="Designation"
            isRequired
            errorMsg={errors?.designation?.message?.toString()}
          >
            <ControlledInput
              name="designation"
              type="text"
              control={control}
              placeholder="Designation"
              pattern={nameRegex}
              isRequired
            />
          </FormElement>
        </div>
        <div className="sr-flex-row">
          <FormElement
            label="Mobile No."
            isRequired
            errorMsg={errors?.mobile_number?.message?.toString()}
          >
            <ControlledInput
              name="mobile_number"
              type="text"
              control={control}
              placeholder="9999988888"
              pattern={isDomHotel ? domPhoneRegex : phoneRegex}
              isRequired
            />
          </FormElement>
          {isHSAgreement && (
            <FormElement
              label="Email"
              errorMsg={errors?.email?.message?.toString()}
              isRequired
            >
              <ControlledInput
                name="email"
                type="text"
                control={control}
                placeholder="user@example.com"
                pattern={emailRegex}
                isRequired
              />
            </FormElement>
          )}
        </div>

        <div className="marginTB15">{STRINGS.TC_AGREE_MESSAGE}</div>

        <ControlledCheckbox name="ignore" control={control} isRequired>
          {CHECKBOX}
        </ControlledCheckbox>
      </div>
    </Modal>
  );
}
