import moment, { MomentInput } from 'moment';
import { apiDateFormat, noEndDate } from '../../constants';
import { formattedGroupDates, getFormattedDate } from 'utilities/DateUtils';
import {
  AnswerType,
  ApplicabilityDetailsProps,
  CreateFaqProps,
  FetchLosServiceResponse,
  GenerateUpfrontValueOptionsProps,
  GetMinStayDurationProps,
  PackageApiCalVariableProps,
} from './data/types/Schema';

export const LOS_PACKAGE_SERVICE_TEMPLATE_VARIABLE = (hotelId: string) => ({
  rawEndpointRequestData: {
    fetchServiceTemplatesInclusion: {
      modelType: 'modelType1',
      body: {
        category: 'inclusion',
        searchRequest: {
          filters: [
            {
              field: 'type',
              value: 'package',
            },
            {
              field: 'packageType',
              value: 'los',
            },
          ],
        },
        hotelId: hotelId,
      },
    },
  },
});

export const PACKAGE_API_CAL_VARIABLE = ({
  hotelCode,
  mmtId,
  packageId,
}: PackageApiCalVariableProps) => ({
  roomRateplanRequestData: {
    rpcRatePlanRequest: {
      endPointId: 99,
      modelType: 'modelType1',
      body: {
        ingoHotelId: hotelCode,
        searchRequest: {
          filters: [
            {
              field: 'isPackagesFlow',
              value: 'true',
            },
            {
              field: 'packageType',
              value: 'los',
            },
          ],
        },
      },
    },
  },
  getPackageRequestData: {
    rpcGetPackageRequest: {
      modelType: 'modelType1',
      body: {
        hotelId: mmtId,
        type: 'los',
        id: packageId,
      },
    },
  },
  fetchServiceRequest: {
    rpcFetchServices: {
      modelType: 'modelType1',
      body: {
        ingoHotelId: hotelCode,
        hotelId: mmtId,
        serviceType: 'inclusion',
        packageId: packageId,
        segment: [],
        channelManager: '',
        status: ['ACTIVE'],
        searchRequest: {
          filters: [
            {
              field: 'QueryType',
              value: 'package',
            },
            {
              field: 'packageType',
              value: 'los',
            },
          ],
        },
      },
    },
  },
});

export const formatBlackoutDates = (blackoutDate: Date[]) => {
  const groupedDates = formattedGroupDates(blackoutDate, apiDateFormat);
  return groupedDates.map(([startDate, endDate]) => ({
    startDate,
    endDate,
  }));
};

export const getRatePlanIds = (
  selectedRoomAndRp: Record<string, Record<string, boolean>>,
): string[] => {
  return Object.values(selectedRoomAndRp)
    .flatMap(rp => Object.keys(rp).filter(key => rp[key]))
    .filter(key => key !== 'roomLevel' && key !== 'isRoomChecked');
};

export const getApplicabilityDetails = (data: ApplicabilityDetailsProps) => {
  const {
    checkinStartDate,
    checkinEndDate,
    bookingStartDate,
    bookingEndDate,
    blackoutDate,
  } = data;

  let bookingEnd = '';
  if (bookingEndDate) {
    bookingEnd = getFormattedDate(bookingEndDate, apiDateFormat);
  } else if (bookingStartDate) {
    bookingEnd = getFormattedDate(noEndDate, apiDateFormat);
  }

  let checkOut = '';
  if (checkinEndDate) {
    checkOut = getFormattedDate(checkinEndDate, apiDateFormat);
  } else if (checkinStartDate) {
    checkOut = getFormattedDate(noEndDate, apiDateFormat);
  }

  return {
    bookingStartDate: bookingStartDate
      ? getFormattedDate(bookingStartDate, apiDateFormat)
      : '',
    bookingEndDate: bookingEnd,
    checkinDate: checkinStartDate
      ? getFormattedDate(checkinStartDate, apiDateFormat)
      : '',
    checkoutDate: checkOut,
    blackoutDates: formatBlackoutDates(blackoutDate),
  };
};

export const unGroupBlackoutDates = (
  dates: Array<{
    startDate: MomentInput;
    endDate: MomentInput;
  }>,
) => {
  const flatDateList = [];
  for (let index = 0; index < dates.length; index++) {
    const { endDate: to, startDate: from } = dates[index];
    const fromDate = moment(from);
    const toDate = moment(to);

    const dateDiff = toDate.diff(fromDate, 'days');
    flatDateList.push(fromDate.toDate());
    if (dateDiff !== 0) {
      let nextDate = fromDate.clone().add(1, 'days');
      for (let i = 1; i <= dateDiff; i++) {
        flatDateList.push(nextDate.toDate());
        nextDate = nextDate.clone().add(1, 'days');
      }
    }
  }
  const sortedDates = flatDateList.sort((a, b) => a - b);
  return sortedDates;
};

const DAYS_REGEX = /\d+ nights/;
export const getStayDays = (text: string, checkType = DAYS_REGEX) => {
  const regex = checkType;
  const match = regex.exec(text);
  const days = match?.[0]?.split(' ')[0];
  return days;
};

export const generateUpfrontValueOptions = (
  minLosNode: GenerateUpfrontValueOptionsProps,
) => {
  return [
    ...Object.values(minLosNode.upfrontValues).map((day: string) => ({
      value: getStayDays(day),
      label: day,
    })),
    {
      value: '0',
      label: 'Set custom duration',
    },
  ];
};

export const getMinStayDuration = ({
  minLengthOfStay,
  minLosNode,
  customValueOptions,
}: GetMinStayDurationProps) => {
  const minStay =
    minLengthOfStay ||
    getStayDays(minLosNode.upfrontValues[minLosNode.recommendedValueId]);
  const customValue = customValueOptions.get(minStay.toString());
  return {
    minStayDuration: customValue ? 0 : minStay,
    customStayDuration: customValue
      ? {
        label: customValue,
        value: minStay,
      }
      : undefined,
  };
};

const getValidDate = date => {
  return date ? new Date(date) : null;
};

export const generateDefaultFormValues = (
  servicesResponse: FetchLosServiceResponse,
  customValueOptions: Map<string, string>,
) => {
  const isNoBookingEndDate =
    servicesResponse.applicabilityDetails.bookingEndDate ===
    getFormattedDate(noEndDate, apiDateFormat);

  const isNoCheckinEndDate =
    servicesResponse.applicabilityDetails.checkoutDate ===
    getFormattedDate(noEndDate, apiDateFormat);

  return {
    ...getMinStayDuration({
      minLengthOfStay: servicesResponse.packageAttributes.minLengthOfStay,
      minLosNode: servicesResponse.minLosNode,
      customValueOptions,
    }),
    promotionBasedOn: servicesResponse.applicabilityDetails.checkinDate
      ? 'booking&stay'
      : 'booking',
    checkinNoEndDate: isNoCheckinEndDate
      ? null
      : !!servicesResponse.applicabilityDetails.checkoutDate,
    bookingNoEndDate: isNoBookingEndDate
      ? null
      : !!servicesResponse.applicabilityDetails.bookingEndDate,
    bookingStartDate:
      getValidDate(servicesResponse.applicabilityDetails.bookingStartDate) ||
      new Date(),
    checkinStartDate: getValidDate(
      servicesResponse.applicabilityDetails.checkinDate,
    ),
    checkinEndDate: isNoCheckinEndDate
      ? null
      : getValidDate(servicesResponse.applicabilityDetails.checkoutDate),
    bookingEndDate: isNoBookingEndDate
      ? null
      : getValidDate(servicesResponse.applicabilityDetails.bookingEndDate),
    blackoutDate: unGroupBlackoutDates(
      servicesResponse.applicabilityDetails.blackoutDates || [],
    ),
    packageName: servicesResponse?.name || 'Long Stay Benefit',
  };
};

export const createFAQ = ({
  faqData,
  PerformanceIcon,
  BadgeIcon,
}: CreateFaqProps) => {
  const questionCount = 6;
  const faqs = Array.from({ length: questionCount }, (_, i) => {
    const questionNumber = i + 1;
    const questionKey = `QUESTION_${questionNumber}`;
    const answerKeyBase = `ANSWER_${questionNumber}`;

    const answerKeys = Object.keys(faqData).filter(key =>
      key.startsWith(answerKeyBase),
    );
    const answers: AnswerType[] = answerKeys.map(answerKey => ({
      TEXT: faqData[answerKey],
    }));

    if (questionNumber === 1) {
      answers[0] = {
        ICON: BadgeIcon,
        TEXT: `<b>${faqData.BADGE_TITLE}</b> <br/> ${faqData.BADGE_TEXT}`,
      };
      answers[1] = {
        ICON: PerformanceIcon,
        TEXT: `<b>${faqData.VISIBILITY_TITLE}</b> <br/> ${faqData.VISIBILITY_TEXT}`,
      };
    }

    return {
      QUESTION: faqData[questionKey],
      ANSWERS: answers,
    };
  });

  return faqs;
};
