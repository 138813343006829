import { useState, useEffect, forwardRef, Ref } from 'react';

import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { TimePicker } from './TimePicker';

type TimeRange = {
  from: string;
  to: string;
};

type CommonProps = {
  use12Hours?: boolean;
  showLabel?: boolean;
  disabled?: boolean;
};

type TimeRangePickerProps = CommonProps & {
  defaultValue: TimeRange;
  onChange: (time: TimeRange) => void;
};

function TimeRangePickerComp(
  props: TimeRangePickerProps,
  ref?: Ref<HTMLInputElement>,
) {
  const {
    defaultValue,
    onChange,
    use12Hours,
    showLabel = true,
    disabled = false,
  } = props;
  const [startTime, setStartTime] = useState(defaultValue.from);
  const [endTime, setEndTime] = useState(defaultValue.to);

  useEffect(() => {
    onChange({ from: startTime, to: endTime });
  }, [startTime, endTime]);

  return (
    <Grid container xs={12} spacing={1}>
      <Grid xs={4} md={6}>
        {showLabel && <Box mb={1}>Start Time</Box>}
        <TimePicker
          minuteInterval={15}
          time={startTime && startTime}
          callback={setStartTime}
          is24HrFormat={use12Hours}
          disabled={disabled}
          autoFocus={false}
          ref={ref}
        />
      </Grid>
      <Grid xs={4} md={6}>
        {showLabel && <Box mb={1}>End Time</Box>}
        <TimePicker
          minuteInterval={15}
          time={endTime && endTime}
          callback={setEndTime}
          is24HrFormat={use12Hours}
          disabled={disabled}
          autoFocus={false}
        />
      </Grid>
    </Grid>
  );
}

const TimeRangePicker = forwardRef(TimeRangePickerComp);

type ControlledTimePickerProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = CommonProps & {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: string;
  isRequired?: boolean;
  customValidator?: RegisterOptions<T, TName>['validate'];
};

export function ControlledTimeRangePicker<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: ControlledTimePickerProps<T, TName>) {
  const { name, control, defaultValue, isRequired = false, ...rest } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {};
  if (isRequired) {
    validations.validate = function (value) {
      const { from: fromTime, to: toTime } = value;
      if (fromTime && toTime) {
        return true;
      }
      return 'This field is required';
    };
  }

  const {
    field: { ref, value, onChange },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });

  return (
    <TimeRangePicker
      ref={ref}
      defaultValue={value}
      onChange={onChange}
      {...rest}
    />
  );
}

export default TimeRangePicker;
