import React, { useMemo } from 'react';
import { classNames } from 'utilities/Utils';
import Button from 'components/latest-core/Button/Button';
import type { ModalFooterPropTypes } from '../Modal.types';

function ModalFooter(props: ModalFooterPropTypes) {
  const { footer, footerClasses, classes } = props;
  const {
    root = '',
    primaryBtn = '',
    secondaryBtn = '',
    errorMsg = '',
  } = footerClasses || {};

  const buttonComponentList = useMemo(() => {
    const buttonConfig = [
      {
        btn: footer?.primaryBtn,
        className: classNames(classes.footerPrimaryBtnContainer, primaryBtn),
        variant: 'contained',
        defaultText: 'Confirm',
        btnWrapper: footer?.primaryBtn?.wrapper,
      },
      {
        btn: footer?.secondaryBtn,
        className: classNames(
          classes.footerSecondaryBtnContainer,
          secondaryBtn,
        ),
        variant: 'text',
        defaultText: 'Cancel',
        btnWrapper: footer?.secondaryBtn?.wrapper,
      },
    ] as const;
    const buttons = buttonConfig
      .filter(({ btn }) => btn)
      .map(
        ({
          btn,
          className,
          variant,
          defaultText,
          btnWrapper = (footerBtn: JSX.Element) => footerBtn,
        }) =>
          btnWrapper(
            <Button
              onClick={btn.onClick}
              disabled={btn?.disabled}
              className={className}
              variant={variant}
              data-test-id={btn['data-test-id'] ? btn['data-test-id'] : ''}
            >
              {btn.text || defaultText}
            </Button>,
          ),
      );
    return footer?.showPrimaryBtnOnFirst ? buttons : buttons.reverse();
  }, [footer, classes]);

  return (
    footer && (
      <div className={classNames(classes.footerContainer, root)}>
        {footer.errorMsg && (
          <div
            className={classNames(classes.footerErrorMsgContainer, errorMsg)}
          >
            {footer.errorMsg}
          </div>
        )}
        {buttonComponentList.map((button, index) => (
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
      </div>
    )
  );
}

export default React.memo(ModalFooter);
