import ResellerOnboarding from './ResellerOnboarding';
import { useContext } from 'react';
import { HotelContext } from 'utilities/Context';

const ResellerMain = () => {
  const {
    currentHotel: { hotelcode: hotelCode },
  } = useContext(HotelContext);

  return <ResellerOnboarding key={hotelCode} />;
};

export default ResellerMain;
