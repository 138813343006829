import React, { forwardRef } from 'react';
import { classNames } from 'utilities/Utils';
import { useTypographyStyles } from './Typography.styles';
import type { TypographyProps, VariantType } from './Typography.types';
import { Typography as MuiTypography } from '@mui/material';

const Typography = (
  props: TypographyProps,
  ref?: React.Ref<HTMLDivElement> | React.RefObject<HTMLDivElement>,
) => {
  const {
    variant = 'body1',
    className = '',
    themeColor,
    themeBg,
    fb = false,
    onClick,
    inline,
    isLink,
    overflowWrap,
    ...rest
  } = props;

  const classes = useTypographyStyles({ themeColor, themeBg, fb });

  return (
    <MuiTypography
      ref={ref}
      className={classNames(
        classes.addColor,
        fb && classes.bold,
        inline && classes.inline,
        isLink && classes.link,
        overflowWrap && classes.overflowWrap,
        className,
      )}
      variant={variant}
      onClick={onClick}
      display={inline ? 'inline' : 'block'}
      {...rest}
    />
  );
};

export default forwardRef(Typography);
export { VariantType };
