import { Box, Chip, Grid } from '@mui/material';
import * as CommonIcons from 'assets/common';
import { Typography } from 'components/latest-core';
import { type AutoCompleteProps } from '../Dropdown.types';

type TagsProps = {
  tags: unknown[];
  isMultiSelectVariant: boolean;
  disabled: boolean;
  chipClasses: string;
  optionLabel: (tag: unknown) => string;
  valueField: string;
  setTags: (tags: unknown[]) => void;
  onChange: (tags: unknown[], name: string, value: string) => void;
  setTagOptions: (options: unknown[]) => void;
  options?: AutoCompleteProps['options'];
  displayWarning: boolean;
  maxTagsText: string;
  maxTags: number;
  value?: AutoCompleteProps['value'];
  name: string;
};

const DropdownTags = ({
  tags,
  isMultiSelectVariant,
  disabled,
  chipClasses,
  optionLabel,
  valueField,
  setTags,
  onChange,
  setTagOptions,
  options,
  displayWarning,
  maxTagsText,
  maxTags,
  value,
  name,
}: TagsProps) => {
  if (!tags || (tags && tags.length === 0)) {
    return null;
  }
  return (
    <Grid
      container
      direction={'row'}
      className={`${isMultiSelectVariant && 'gap-1'}`}
    >
      {tags?.map((tagsValue, index) => (
        <Grid key={`chip_${index}`}>
          <Chip
            disabled={disabled}
            clickable={false}
            className={chipClasses}
            label={optionLabel(tagsValue)}
            onDelete={() => {
              const newTags = tags.filter(tag => {
                if (valueField) {
                  return tag[valueField] !== tagsValue[valueField];
                }
                return optionLabel(tag) !== optionLabel(tagsValue);
              });
              setTags(newTags as []);
              onChange(newTags, name, '');
              setTagOptions(
                options.filter(option =>
                  (newTags as unknown[]).every(
                    tag => JSON.stringify(tag) !== JSON.stringify(option),
                  ),
                ),
              );
            }}
            deleteIcon={
              <CommonIcons.Close
                style={{ width: '16px', height: '16px' }}
                data-test-id={`${optionLabel(tagsValue)}_chip_cross`}
              />
            }
          />
        </Grid>
      ))}
      {displayWarning &&
        maxTagsText &&
        maxTags !== Infinity &&
        (value as unknown[])?.length >= maxTags && (
          <Grid xs={12}>
            <Box my={1}>
              <Typography themeColor="error.main">{maxTagsText}</Typography>
            </Box>
          </Grid>
      )}
    </Grid>
  );
};

export default DropdownTags;
