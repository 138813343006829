import {
  createRef,
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
} from '@mui/material';
import { Container, Typography } from '../index';
import { Error, GreenCheck } from 'assets/latest_core';
import { Button, Tooltip } from 'components/latest-core';
import { useResizeObserver } from 'utilities/CustomHooks';

const useStyles = makeStyles(theme => ({
  root: {
    '& div[class*="MuiPaper-root"]': {
      background: 'transparent',
      padding: 0,
      position: 'relative',
      boxShadow: 'none',

      '& span[class*="MuiStepLabel-root"]': {
        alignItems: 'flex-start',
        padding: 0,
      },

      '& div[class*="MuiStepContent-root"]': {
        marginTop: theme.spacing(0),
        paddingRight: theme.spacing(0),
        borderWidth: '2px',
        borderColor: theme.palette.backgrounds.divider,
      },

      '& div[class*="MuiStepConnector-root"]': {
        height: theme.spacing(2),
        ' & > span': {
          minHeight: theme.spacing(5),
          borderColor: 'transparent',
        },
      },
      '& span[class*="MuiStepLabel-labelContainer"]': {
        overflow: 'auto',
      },
    },
  },
  stepLabel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    marginLeft: '9px',
    zIndex: 1,
    flexDirection: 'column',
  },
  stepContent: (props: { isLast: boolean }) => ({
    position: 'relative',
    paddingTop: '75px',
    marginTop: '-75px',
    background: theme.palette.common.white,
    borderRadius: '4px',
    marginLeft: props.isLast ? '12px' : '10px',
  }),
  stepContentAnimate: {
    animation: `$animation 1000ms ${theme.transitions.easing.easeIn}`,
    animationFillMode: 'forwards',
  },
  '@keyframes animation': {
    '0%': {
      boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0)',
    },
    '100%': {
      boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.25)',
    },
  },
  _step: {
    padding: theme.spacing(2),
    borderTop: '1px solid #D2D1D1',
  },
  stepFooter: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #D2D1D1',
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  disableIcon: {
    backgroundColor: theme.palette.backgrounds.divider,
    height: '28px',
    width: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  activeIcon: {
    backgroundColor: theme.palette.backgrounds.primary,
    height: '28px',
    width: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #3543BF',
    borderRadius: '50%',
  },
  headerConnector: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginLeft: '-1px',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '72px',
    background: '#FFFFFF',
    boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 12,
  },
}));

export type Steps = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  content: React.ReactNode;
  summary?: React.ReactNode;
  sideContent?: React.ReactNode;
  enableNext?: () => boolean;
  makeApiCall?: boolean;
  secondaryCTA?: React.ReactNode;
  buttonName?: string;
  iconLink?: string;
  sideLineColor?: string;
  disableSkip?: boolean;
};

export type StepperProps = {
  title?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  steps: Steps[];
  onSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  completedStep?: number;
  negativeText?: React.ReactNode;
  positiveText?: string | React.ReactNode;
  onCancel?: () => void;
  onNextClick?: (n: number) => boolean | Promise<boolean>;
  enableSave?: boolean;
  guidePopup?: React.ReactNode;
  NextButtonText?: React.ReactNode;
  isEditMode?: boolean;
  errorMap?: number[];
  onClickEdit?: (index: number) => void;
  children?: React.ReactNode | React.ReactNode[];
  editButtonText?: string;
  errorMessage?: string | null;
  className?: string;
  isLayoutDynamic?: boolean;
  disableCancel?: boolean;
  showSkipCta?: boolean;
  handleSkipClick?: (number) => boolean;
  lastCompletedStep?: number;
  enableFooterBtn?: boolean;
  preEdit?: (index: number) => void;
  hideErrorMsg?: boolean;
  positiveTextToolTip?: string;
};

const StepperComponent = (
  props: StepperProps,
  ref?: React.Ref<HTMLDivElement>,
) => {
  const {
    title,
    subtitle,
    icon,
    steps,
    onSubmit,
    activeStep,
    setActiveStep,
    negativeText = 'cancel',
    positiveText = 'Save',
    onCancel,
    onNextClick,
    NextButtonText = 'Next',
    enableSave,
    guidePopup,
    errorMap = [],
    children,
    isEditMode = false,
    editButtonText = 'Edit',
    errorMessage = null,
    onClickEdit,
    className,
    isLayoutDynamic = false,
    disableCancel = false,
    showSkipCta = false,
    handleSkipClick = () => true,
    lastCompletedStep = 0,
    enableFooterBtn = null,
    preEdit = null,
    hideErrorMsg = false,
    positiveTextToolTip = '',
  } = props;

  const [contentHeight, setContentHeight] = useState(steps.map(() => 0));
  const [completedStep, setCompletedStep] = useState(activeStep + 1);

  const isLast = activeStep === steps.length - 1;

  const borderColor = useRef({
    top: steps.map(() => '#D2D1D1'),
    bottom: steps.map(() => '#D2D1D1'),
  });

  const classes = useStyles({ isLast });

  const stepperRefs = useMemo(() => {
    return steps.map(() => {
      return {
        label: createRef<HTMLDivElement>(),
        content: createRef<HTMLDivElement>(),
      };
    });
  }, []);

  const StepIcon = _props => {
    const { active, error, icon: _icon, completed } = _props;
    const _classes = useStyles({ isLast });

    let iconType = 4;
    if (active) {
      iconType = 1;
    } else if (completed) {
      iconType = 2;
    }
    if (error) {
      iconType = 3;
    }
    if (steps[_icon - 1]?.iconLink) {
      iconType = 5;
    }
    const stepIcon = () => {
      switch (iconType) {
        case 1:
          return (
            <div className={_classes.activeIcon}>
              <Typography
                variant="subHeadingMedium"
                themeColor="primary.main"
                fb
              >
                {_icon}
              </Typography>
            </div>
          );
        case 2:
          return <GreenCheck height="28px" width="28px" />;
        case 3:
          return <Error height="28px" width="28px" />;
        case 4:
          return (
            <div className={_classes.disableIcon}>
              <Typography variant="subHeadingMedium" themeColor="gray.main" fb>
                {_icon}
              </Typography>
            </div>
          );
        case 5:
          return (
            <img
              src={steps[_icon - 1].iconLink}
              alt={_icon}
              className="h-7 w-7 rounded-full select-none z-[1] bg-color-white"
            />
          );
        default:
          return null;
      }
    };

    return (
      <div className={_classes.headerConnector}>
        {_icon !== 1 && (
          <div
            style={{
              position: 'absolute',
              height: '32px',
              borderLeft: `2px solid ${borderColor.current.top[_icon - 1]}`,
              top: '-32px',
            }}
          ></div>
        )}
        {stepIcon()}
        {steps.length !== _icon && (
          <div
            style={{
              position: 'absolute',
              height: `${contentHeight[_icon - 1]}px`,
              borderLeft: `2px solid ${borderColor.current.bottom[_icon - 1]}`,
              zIndex: -1,
              top: '28px',
            }}
          ></div>
        )}
      </div>
    );
  };

  const handleNext = e => {
    e.stopPropagation();
    const nextStep = activeStep + 1;
    if (onNextClick) {
      const res = onNextClick(nextStep);
      Promise.resolve(res)
        .then(returnVal => {
          if (returnVal) {
            setActiveStep(nextStep);
            stepperRefs[nextStep]?.label.current?.scrollIntoView(false);
            if (completedStep < nextStep) setCompletedStep(nextStep);
          }
        })
        .catch(error => {
          console.error('Error in onNextClick', error);
        });
    } else {
      setActiveStep(nextStep);
    }
  };

  const onEditClick = (isError, index) => {
    if (preEdit) {
      preEdit(index);
    } else {
      if (!isError) setActiveStep(index);
      onClickEdit?.(index);
    }
  };

  const { height = 0 } = useResizeObserver(
    stepperRefs[activeStep]?.content,
    true,
  );

  useLayoutEffect(() => {
    setContentHeight(
      stepperRefs.map(
        stepperRef => stepperRef.label.current?.clientHeight ?? 0,
      ),
    );
  }, [activeStep, height]);

  const getStepperErrorMessage = (errorCount: number) => {
    const errorText = `${errorCount} __placeholder__ found. Please correct it to proceed further.`;
    if (errorCount > 1) {
      return errorText.replace('__placeholder__', 'errors');
    }
    return errorText.replace('__placeholder__', 'error');
  };
  const toMainReturn = (
    <div>
      {!!title && (
        <div className={`flex items-center mb-4 ${icon && 'ml-1'}`}>
          {icon}
          <Typography variant="h1" className={icon && 'ml-4'}>
            {title}
            {subtitle && <Typography variant="h3">{subtitle}</Typography>}
          </Typography>
        </div>
      )}
      <Paper>
        <MuiStepper activeStep={activeStep} orientation="vertical" ref={ref}>
          {steps.map((step, index) => {
            const isActive = activeStep === index;
            const isDisabled = step?.enableNext ? !step.enableNext() : false;
            if (step.sideLineColor) {
              borderColor.current.bottom[index] = step.sideLineColor;
            } else if (errorMap?.[index] > 0) {
              borderColor.current.bottom[index] = '#EB2026';
            } else if (!isActive && index < completedStep) {
              borderColor.current.bottom[index] = '#2E7D32';
            } else if (isActive) {
              borderColor.current.bottom[index] = '#3543BF';
            } else {
              borderColor.current.bottom[index] = '#D2D1D1';
            }
            borderColor.current.top[index + 1] =
              borderColor.current.bottom[index];

            const isError = !!errorMap.reduce((acc, cur) => acc + cur, 0);

            return (
              <Step key={step.title.toString()}>
                <StepLabel
                  StepIconComponent={StepIcon}
                  StepIconProps={{
                    error: errorMap?.[index] > 0,
                    completed: index < completedStep,
                  }}
                  ref={stepperRefs[index]?.label}
                >
                  <div className={classes.stepLabel}>
                    <div className="flex justify-between w-full">
                      <Typography
                        variant={isActive ? 'h3' : 'subHeadingMedium'}
                      >
                        {step.title}
                      </Typography>
                      {!isActive &&
                        (index < lastCompletedStep ||
                          index < completedStep) && (
                          <Button
                            variant="text"
                            onClick={() => onEditClick(isError, index)}
                          >
                            {editButtonText}
                          </Button>
                      )}
                    </div>
                    {isActive && (
                      <Typography
                        variant="body1"
                        themeColor="text.secondary"
                        className={`${step.subtitle ? 'h-auto' : 'h-[14px]'}`}
                      >
                        {step.subtitle}
                      </Typography>
                    )}

                    {!isActive &&
                      (index < lastCompletedStep || index < completedStep) && (
                        <div className="w-full">{step.summary}</div>
                    )}
                  </div>
                </StepLabel>
                <StepContent
                  style={{
                    borderColor: borderColor.current.bottom[index],
                  }}
                  ref={stepperRefs[index]?.content}
                >
                  <div
                    className={`${classes.stepContent} ${classes.stepContentAnimate}`}
                  >
                    <div className={classes._step}>{step.content}</div>
                    {(!isLast || isEditMode) && (
                      <div className={classes.stepFooter}>
                        <div className="flex flex-row">
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            disabled={isDisabled}
                            data-test-id="next-cta"
                          >
                            {step.buttonName ?? NextButtonText}
                          </Button>
                          {step.secondaryCTA}
                          {showSkipCta && (
                            <Button
                              variant="text"
                              className="ml-8"
                              data-test-id="skip-cta"
                              disabled={!!step.disableSkip}
                              onClick={() => {
                                const moveToNextStep = handleSkipClick(
                                  index + 1,
                                );
                                if (moveToNextStep) {
                                  setActiveStep(index + 1);
                                  stepperRefs[
                                    index + 1
                                  ]?.label.current?.scrollIntoView(false);
                                  setCompletedStep(index + 1);
                                }
                              }}
                            >
                              Skip
                            </Button>
                          )}
                        </div>
                        {!hideErrorMsg && errorMap?.[index] > 0 && (
                          <Typography themeColor="error.main" className="ml-2">
                            {getStepperErrorMessage(errorMap?.[index])}
                          </Typography>
                        )}
                      </div>
                    )}
                  </div>
                  {isLast && children}
                </StepContent>
              </Step>
            );
          })}
        </MuiStepper>
      </Paper>
    </div>
  );
  let toSaidReturn;
  if (steps[activeStep]?.sideContent || !isLayoutDynamic) {
    toSaidReturn = (
      <div>
        {guidePopup}
        <div key={activeStep}>{steps[activeStep]?.sideContent}</div>
      </div>
    );
  }

  return (
    <>
      {toSaidReturn ? (
        <Container
          paddingBottom={12}
          className={`${classes.root} ${className}`}
          key={activeStep}
        >
          {toMainReturn}
          {toSaidReturn}
        </Container>
      ) : (
        <Container
          paddingBottom={12}
          className={`${classes.root} ${className}`}
          key={activeStep}
        >
          {toMainReturn}
        </Container>
      )}
      <div className={classes.footer}>
        <Container twoColLayout isNewContent>
          <div className="h-[72px] flex justify-between items-center">
            <Button
              variant="text"
              onClick={onCancel}
              disabled={disableCancel}
              data-test-id="stepper-footer-negative-button"
            >
              {negativeText}
            </Button>
            <div className="flex justify-between items-center">
              {errorMessage ? (
                <Typography themeColor="error.main" className="mr-2">
                  {errorMessage}
                </Typography>
              ) : (
                errorMap?.[steps.length - 1] > 0 && (
                  <Typography themeColor="error.main" className="mr-2">
                    {getStepperErrorMessage(errorMap?.[steps.length - 1])}
                  </Typography>
                )
              )}
              <Tooltip content={positiveTextToolTip} bgBlack>
                <span>
                  <Button
                    variant="contained"
                    disabled={
                      enableFooterBtn !== null
                        ? !enableFooterBtn
                        : activeStep !== steps.length - 1 || enableSave
                    }
                    onClick={onSubmit}
                    data-test-id="stepper-footer-submit"
                  >
                    {positiveText}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const Stepper = forwardRef(StepperComponent);

export default Stepper;
