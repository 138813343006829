export const PLACEHOLDER = '__placeholder__';

export const SETUP_ACCOUNT_FORM_FIELDS = {
  ACCOUNT_TYPE: {
    name: 'accountType',
    defaultValue: 'existing',
  },
  FIRST_NAME: {
    name: 'firstName',
    isRequired: true,
    label: 'First Name',
    defaultValue: '',
  },
  LAST_NAME: {
    name: 'lastName',
    isRequired: true,
    label: 'Last Name',
    defaultValue: '',
  },
  MOBILE_NUMBER: {
    name: 'mobile',
    isRequired: true,
    label: 'Mobile Number',
    defaultValue: {
      phone: '',
      country: '+91',
    },
  },
  EMAIL_ADDRESS: {
    name: 'email',
    isRequired: true,
    label: 'Email Address',
    defaultValue: '',
  },
  PASSWORD: {
    name: 'password',
    isRequired: true,
    label: 'Password',
    defaultValue: '',
  },
};

export const LOGOUT_COOKIES = 'closed=;expires=Thu, 01 Jan 1970 00:00:00 UTC';
