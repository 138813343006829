import {
  useController,
  type FieldPath,
  type FieldValues,
  type RegisterOptions,
} from 'react-hook-form';
import Radio from './Radio';
import { getStringValue } from './Radio.utils';
import type { ControlledRadioProps } from './Radio.types';

function ControlledRadio<
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: ControlledRadioProps<T, TName>) {
  const {
    name,
    control,
    defaultValue = null,
    isRequired = false,
    customValidator,
    customChangeHandler,
    ...rest
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };

  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const {
    field: { onChange, value, ref },
  } = useController<T, TName>({
    name,
    rules: validations,
    defaultValue,
    control,
  });

  const onCustomChange = (radioValue: string) => {
    if (customChangeHandler) {
      customChangeHandler(radioValue, name);
    }
    onChange(radioValue);
  };

  return (
    <Radio
      ref={ref}
      name={name}
      value={getStringValue(value)}
      onChange={onCustomChange}
      {...rest}
    />
  );
}

export default ControlledRadio;
