import { getFormattedDate } from 'utilities/DateUtils';
import axios, {
  apiWrapper,
  showError,
  wrappedAxiosCall,
} from '../../../base/APIClient';
import { USERMANAGEMENT } from '../../../base/Url';
import {
  PERMISSION_TABS,
  PERMISSION_TAB_NAME_TO_ID_MAPPING,
  TABS,
} from './userManagement.constants';
import {
  IHost,
  IPreferences,
  IUploadProfilePicResponse,
  IUser,
  IUserTabWisePermissions,
} from './userManagement.types';
import { apiDateFormat } from './../../../constants';

const computeUserPermissionsTabWise = data => {
  const userTabWisePermissionsLocal = {};
  (data || []).forEach(user => {
    const userTabMap = {};
    TABS.forEach(tab => {
      let switchedOn = true;
      let selectedImage = 0;
      let displayText = 'e';
      if (tab in user.rights.blocked) {
        if (user.rights.blocked[tab] === 'Read-Write') {
          switchedOn = false;
          displayText = 'b';
        } else {
          selectedImage = 1;
          displayText = 'r';
        }
      }
      userTabMap[tab] = {
        email_verified: true,
        isSwitchOn: switchedOn,
        selectedImage: selectedImage,
        displayText: displayText,
      };
    });
    userTabWisePermissionsLocal[user.id] = userTabMap;
  });
  return userTabWisePermissionsLocal;
};

const parseUsersData = (
  data,
): {
  data?: IUser[];
  userPermission?: { [key: string]: IUserTabWisePermissions };
} => {
  const parsedData = {};
  // @ts-ignore
  parsedData.data = data;
  // @ts-ignore
  parsedData.userPermission = computeUserPermissionsTabWise(data);
  return parsedData;
};

export const fetchAssociatedUsers = (
  hotel: string,
): Promise<{
  data?: IUser[];
  userPermission?: { [key: string]: IUserTabWisePermissions };
}> => {
  const { UM_FETCH_USERS } = USERMANAGEMENT;
  const url = `${UM_FETCH_USERS}?hotelcode=${hotel}`;
  return new Promise((resolve, reject) => {
    wrappedAxiosCall({ method: 'get', url })
      .then(res => {
        resolve(parseUsersData(res));
      })
      .catch(reject);
  });
};

export const getHostsInfoList = (): Promise<{
  [key: string]: IHost;
}> => {
  const { HOSTS_LIST } = USERMANAGEMENT;
  return new Promise<{ [key: string]: IHost }>((resolve, reject) => {
    return apiWrapper(axios.get(HOSTS_LIST))
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          showError(data);
          reject();
        }
      })
      .catch(err => {
        showError(err.data ? err.data : err);
        reject(err);
      });
  });
};

const constructAssignedPropertiesPayload = (
  state,
  addBothAddAndRemoveList = false,
) => {
  const { selectionMap } = state;

  const toAddHotelsList = [];
  const toRemoveHotelsList = [];

  Object.keys(selectionMap).forEach(key => {
    if (selectionMap[key].isSelected) {
      toAddHotelsList.push({ code: key, is_host: selectionMap[key].isHost });
    } else if (addBothAddAndRemoveList) {
      toRemoveHotelsList.push({
        code: key,
      });
    }
  });

  if (addBothAddAndRemoveList) {
    return { add: toAddHotelsList, remove: toRemoveHotelsList };
  }

  return toAddHotelsList;
};

const getPermissionStatesPayload = data => {
  const permissionsPayload = [];

  const step2KeysAndValues: [string, { radio: string; switch: boolean }][] =
    Object.entries(data);

  step2KeysAndValues.forEach(([tabKey, tabState]) => {
    const { radio, switch: swtichValue } = tabState;
    const tabName = PERMISSION_TABS.find(tab => tab.key === tabKey).name;
    const tabId = PERMISSION_TAB_NAME_TO_ID_MAPPING[tabName];

    if (!swtichValue) {
      permissionsPayload.push({
        tab_id: tabId,
        permission_type: 0,
      });
    } else if (radio === 'View-Only') {
      permissionsPayload.push({
        tab_id: tabId,
        permission_type: 1,
      });
    }
  });

  return permissionsPayload;
};

export const getCreatNewUserPayload = (formData, newUserStep3State) => {
  const { step1, step2 } = formData;

  const {
    email,
    firstName,
    lastName,
    userRole,
    userDesignation,
    userType,
    mobile,
  } = step1;
  const { phone, country } = mobile;

  const blocked = getPermissionStatesPayload(step2);

  const payload = {
    username: email,
    first_name: firstName,
    last_name: lastName,
    email: email,
    countrycode: country,
    mobile: phone,
    role: userRole,
    user_designation: userDesignation.value,
    rights: { blocked },
    user_type: userType.value,
    hotels_list: constructAssignedPropertiesPayload(newUserStep3State),
  };

  return payload;
};

export const addNewUser = payload => {
  const { UM_ADD_USER } = USERMANAGEMENT;
  return apiWrapper(axios.post(UM_ADD_USER, payload, { useLoader: true }));
};

export const getUpdateUserPayload = (data, isUserSelf) => {
  const {
    personal,
    hostInfo,
    preferences,
    step2: permissionsState,
    newUserStep3State: assignedPropertiesState,
  } = data;

  let finalPayload = {};

  if (personal) {
    const {
      firstName,
      lastName,
      email,
      mobile,
      whatsappNumber,
      userType,
      userDesignation,
      userRole,
    } = personal;
    const profileStep1Payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      ...(mobile?.phone && { mobile: mobile.phone }),
      ...(mobile?.country && { countrycode: mobile.country.toString }),
      ...(whatsappNumber?.phone && { whatsapp_number: whatsappNumber.phone }),
      user_type: userType.value,
      user_designation: userDesignation,
      role: userRole,
    };
    finalPayload = { ...finalPayload, ...profileStep1Payload };
  }

  if (hostInfo) {
    const { dob, gender, profession, hostingSince, languages, interests } =
      hostInfo;

    let hostInfoStep1Payload = {};

    hostInfoStep1Payload = {
      ...(dob && { dob: getFormattedDate(dob, apiDateFormat) }),
      ...(gender && { gender: gender.value }),
      ...(profession && { profession: profession.value }),
      ...(interests?.length && { interests: interests.map(val => val.value) }),
      ...(languages?.length && { languages: languages.map(val => val.value) }),
      ...(hostingSince && {
        hosting_since: getFormattedDate(hostingSince, apiDateFormat),
      }),
    };

    if (preferences) {
      const preferencesPayloadValue = Object.values(preferences)
        .filter(preference => preference)
        .map((preference: { label: string; value: string }) => {
          return preference.value;
        });

      const preferencesPayload = {
        preferences: preferencesPayloadValue,
      };

      if (preferencesPayloadValue.length) {
        hostInfoStep1Payload = {
          ...hostInfoStep1Payload,
          about_answers: preferencesPayload.preferences,
        };

        finalPayload = { ...finalPayload, ...preferencesPayload };
      }

      if (Object.keys(hostInfoStep1Payload).length) {
        finalPayload = {
          ...finalPayload,
          host_info: hostInfoStep1Payload,
        };
      }
    }
  }
  if (!isUserSelf) {
    if (permissionsState) {
      const permissionsPaylaod = getPermissionStatesPayload(permissionsState);

      finalPayload = {
        ...finalPayload,
        rights: { blocked: permissionsPaylaod },
      };
    }

    if (assignedPropertiesState) {
      const assignedPropertiesPayload = constructAssignedPropertiesPayload(
        assignedPropertiesState,
        true,
      );

      finalPayload = {
        ...finalPayload,
        hotels_list: assignedPropertiesPayload,
      };
    }
  }
  return finalPayload;
};

export const updateUser = (payload, userid) => {
  const { UM_UPDATE_USER } = USERMANAGEMENT;
  const url = UM_UPDATE_USER(userid);
  return apiWrapper(axios.put(url, payload, { useLoader: true }));
};

export const uploadProfilePic = (
  payload,
  userid,
): Promise<IUploadProfilePicResponse> => {
  const { UPLOAD_PROFILE_PIC } = USERMANAGEMENT;
  const url = UPLOAD_PROFILE_PIC(userid);
  return new Promise((resolve, reject) => {
    wrappedAxiosCall({ method: 'put', url, data: payload })
      .then((res: IUploadProfilePicResponse) => resolve(res))
      .catch(reject);
  });
};

const modifyPreferences = (res): IPreferences => {
  const preferences: IPreferences = {
    languages: [],
    professions: [],
    interests: [],
    about_template: [],
  };
  const keysToRead = [
    'languages',
    'professions',
    'interests',
    'about_template',
  ];
  keysToRead.map(key => {
    const data = [];
    res[key].forEach(val => {
      if (key === 'languages') {
        data.push({ label: val.label, value: val.id });
      } else if (key === 'about_template') {
        const arr = [];
        val.answers.forEach(ans => {
          arr.push({ label: ans.answer, value: ans.name });
        });
        data.push({ name: val.name, question: val.question, answers: arr });
      } else {
        data.push({ label: val.name, value: val.id });
      }
    });
    return (preferences[key] = data);
  });
  return preferences;
};

export const getHostInfoFieldsPreferences = (): Promise<IPreferences> => {
  const { PREFERENCES } = USERMANAGEMENT;
  return new Promise((resolve, reject) => {
    return axios
      .get(PREFERENCES)
      .then(({ data }) => {
        if (data && data.data) {
          resolve(modifyPreferences(data.data));
        } else {
          showError(data);
          reject();
        }
      })
      .catch(err => {
        showError(err.data ? err.data : err);
        reject(err);
      });
  });
};

export const resendEmailVerification = payload => {
  const { UM_RESEND_EMAIL_VERIFICATION: url } = USERMANAGEMENT;
  return apiWrapper(axios.post(url, payload));
};

export const verifyExistingUser = payload => {
  const { VERIFY_EXISTING_USER: url } = USERMANAGEMENT;

  return apiWrapper(axios.post(url, payload));
};

export const verifyUserAndCreatePwd = payload => {
  const { VERIFY_USER_AND_CREATE_PWD: url } = USERMANAGEMENT;

  return apiWrapper(axios.post(url, payload));
};

export const deActivateUser = userId => {
  const { UM_DEACTIVATE_USER } = USERMANAGEMENT;
  const url = UM_DEACTIVATE_USER(userId);
  return wrappedAxiosCall({ method: 'post', url });
};

export const checkIfExistingUmWrapper = email => {
  const { UM_EXISTING_USER } = USERMANAGEMENT;
  const data = {
    data: email,
  };
  return apiWrapper(axios.post(UM_EXISTING_USER, data, { useLoader: true }));
};

export const updateWhatsappSubscribed = value => {
  const { IM_WHATSAPP_SUBSCRIBE } = USERMANAGEMENT;
  const data = {
    action: value ? 'subscribed' : 'unsubscribed',
  };
  return wrappedAxiosCall({ method: 'put', url: IM_WHATSAPP_SUBSCRIBE, data });
};

export const resetPassword = payload => {
  const { RESET_PASSWORD } = USERMANAGEMENT;
  return apiWrapper(axios.post(RESET_PASSWORD, payload, { useLoader: true }));
};
