import { useEffect, useState } from 'react';
import { fetchPendingRefundRequest } from '../utils/DashboardAPIClient';
import { Button, Typography, Divider } from 'components/latest-core';
import { useLangStrings } from 'utilities/CustomHooks';
import { makeMyTripIcon } from 'assets/common';
import { getDateDifference, getFormattedDate } from 'utilities/DateUtils';
import { navigateTo, scrollToElement } from 'utilities/Utils';
import { routes } from 'app/routes';
import { OfferRefundNew } from 'modules/bookings-revamp/components/offer-refund/OfferRefund';
import { pushToOmniture } from 'utilities/gtm';

type MiscType = {
  waiver_requested_date: string;
  waiver_message: string;
};

type Misc = MiscType | {};

const RefundRequestDrawerButton = ({ data, onSuccess }) => {
  const [{ REFUND_REQUEST: STRINGS }] =
    useLangStrings<'Dashboard'>('Dashboard');

  const [open, setOpen] = useState(false);

  const offerRefundData = {
    bookingName: data.bookingname,
    bookingId: data.confirmbookingid,
    parentVendorBookingId: data.parent_vendor_booking_id,
    isMultiRoom: data.is_multi_room,
    checkin: data.checkin,
    checkout: data.checkout,
    bookingDate: data.createdon,
    vendorBookingId: data.vendorbookingid,
    currency: data.base_currency,
    misc: data.misc,
  };

  const onClick = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'view_refund_requests',
        componentName: 'refund_requests',
        type: 'click',
      },
      loadedComponents: data.vendorbookingid,
    });

    setOpen(true);
  };

  return (
    <div>
      {open && (
        <OfferRefundNew
          data={offerRefundData}
          onClose={type => {
            setOpen(false);
            if (type === 'success') {
              onSuccess?.();
            }
          }}
        />
      )}
      <Button size="small" onClick={onClick}>
        {STRINGS.VIEW_REQUEST}
      </Button>
    </div>
  );
};

const RefundRequest = ({ data, getData }) => {
  const {
    bookingname,
    confirmbookingid,
    misc,
    checkin,
    checkout,
    noofrooms: roomBooked,
    bookingamount: amount,
    base_currency: currency,
    vendorbookingid,
  } = data;

  const [{ REFUND_REQUEST: STRINGS }, COMMON] =
    useLangStrings<'Dashboard'>('Dashboard');

  let parsedMisc: Misc = {};
  let requestDaysDiff = 0;

  try {
    parsedMisc = JSON.parse(misc);
  } catch (e) {
    parsedMisc = {
      waiver_message: '',
      waiver_requested_date: '',
    };
  }

  if ('waiver_requested_date' in parsedMisc) {
    requestDaysDiff = getDateDifference(
      new Date(),
      parsedMisc.waiver_requested_date,
    );
  }

  return (
    <div
      key={confirmbookingid}
      className="rounded-[4px] border border-solid border-common-divider mt-4"
    >
      <div className="pt-3 pb-4 px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={makeMyTripIcon}
              width={20}
              alt="mmt-icon"
              className="mr-1"
            />
            <Typography variant="body2" className="mr-1">
              {bookingname}
            </Typography>
            <Typography variant="legends" className="!text-text-secondary">
              {vendorbookingid}
            </Typography>
          </div>
          <Typography variant="legends" className="!text-error-dark" fb>
            {requestDaysDiff === 0
              ? COMMON.LABELS.TODAY
              : STRINGS.SUB_TITLE.replace(
                '__DAYS__',
                `${
                  requestDaysDiff +
                    (requestDaysDiff > 1
                      ? ` ${COMMON.LABELS.DAYS}`
                      : ` ${COMMON.LABELS.DAY}`)
                }`,
              )}
          </Typography>
        </div>
      </div>
      <Divider />
      <div className="pt-4 pb-3 px-6">
        <div className="flex justify-between">
          <div>
            <Typography variant="subtitle1" themeColor="text.secondary">
              {COMMON.LABELS.STAY_DATES_TEXT}
            </Typography>
            <Typography variant="subtitle1" className="pt-1">
              {getFormattedDate(checkin)} - {getFormattedDate(checkout)}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" themeColor="text.secondary">
              {STRINGS.ROOMS_BOOKED}
            </Typography>
            <Typography variant="subtitle1" className="pt-1">
              {roomBooked}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" themeColor="text.secondary">
              {STRINGS.AMOUNT}
            </Typography>
            <Typography variant="subtitle1" className="pt-1">
              {currency} {amount}
            </Typography>
          </div>
          <div>
            <Typography variant="subtitle1" themeColor="text.secondary">
              {STRINGS.REASON}
            </Typography>
            <Typography variant="subtitle1" className="pt-1">
              {'waiver_message' in parsedMisc ? parsedMisc.waiver_message : ''}
            </Typography>
          </div>
          <div>
            <RefundRequestDrawerButton data={data} onSuccess={getData} />
          </div>
        </div>
      </div>
    </div>
  );
};

const PendingRefundRequestsNew = props => {
  const { hotel, gtmLabel, onSectionLoadUpdate } = props;
  const [data, setData] = useState([]);
  const [highlightCard, setHighlightCard] = useState(false);

  const [{ REFUND_REQUEST: STRINGS }] =
    useLangStrings<'Dashboard'>('Dashboard');

  const handleHighlightingCard = (hotelId, newCount, result) => {
    const tempData =
      sessionStorage
        .getItem(`isUserAlreadyVisitedRefundRequestCard-${hotelId}`)
        ?.split('-') || [];

    const isUserAlreadyVisitedRefundRequestCard = tempData[0] || false;
    const curCount = +tempData?.[1] || 0;
    let wannaHighLightCard = false;

    if (
      isUserAlreadyVisitedRefundRequestCard !== 'true' ||
      (isUserAlreadyVisitedRefundRequestCard === 'true' && newCount > curCount)
    ) {
      wannaHighLightCard = true;
    }

    if (wannaHighLightCard) {
      setHighlightCard(true);

      setTimeout(() => {
        setHighlightCard(false);
      }, 5000);
    }

    if (curCount) {
      pushToOmniture({
        event: 'ctaLoad',
        cta: {
          name: 'refund_requests',
          componentName: 'refund_requests',
          type: 'load',
        },
        loadedComponents: result?.[0]?.vendorbookingid,
      });
    }

    sessionStorage.setItem(
      `isUserAlreadyVisitedRefundRequestCard-${hotelId}`,
      `true-${newCount}`,
    );
  };

  const getData = () => {
    fetchPendingRefundRequest(hotel.hotelId, { showLoader: false })
      .then((resp: { count: number; results: object[] }) => {
        const { count, results } = resp;
        const hasData = count !== 0;
        setData(results);
        onSectionLoadUpdate(hasData, gtmLabel);
        handleHighlightingCard(hotel.hotelId, count, results);
      })
      .catch(() => onSectionLoadUpdate(false, gtmLabel));
  };

  useEffect(getData, [hotel.hotelId]);

  useEffect(() => {
    if (data.length && highlightCard) {
      scrollToElement('#pending-refund-requests');
    }
  }, [data.length]);

  if (data.length === 0) return null;

  return (
    <div
      id="pending-refund-requests"
      className={`rounded-[4px] mb-5 shadow-dropShadow ${
        highlightCard ? 'bg-color-warning' : 'bg-color-white'
      }`}
    >
      <div className="flex justify-between p-4">
        <div className="flex">
          <Typography variant="body1" className="!capitalize" fb>
            {STRINGS.TITLE}
          </Typography>
          <Typography variant="legends" className="ml-[2px] flex items-center">
            ({data.length})
          </Typography>
        </div>
        {data.length > 2 && (
          <div
            onClick={() =>
              navigateTo(routes.bookings.persuasions.refundRequests)
            }
          >
            <Typography
              variant="body2"
              className="!text-primary-default cursor-pointer !font-semibold"
            >
              {STRINGS.VIEW_ALL_REQUESTS1}
            </Typography>
          </div>
        )}
      </div>
      <Divider />
      <div className="p-4">
        <div className="p-1 bg-[#E8F5E9] border border-solid border-success-medium ">
          <Typography variant="subtitle1">{STRINGS.INFO_MESSAGE_1}</Typography>
        </div>
        <div>
          {data.slice(0, 2).map(item => (
            <RefundRequest data={item} getData={getData} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PendingRefundRequestsNew;
