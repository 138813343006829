import { Divider } from '@mui/material';
import { usePackagesRoomRpSelectable } from 'components/common';
import {
  Button,
  ControlledCalendar,
  ControlledRadio,
  FormElement,
  Typography,
} from 'components/latest-core';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLangStrings } from 'utilities/CustomHooks';

import { StayDate } from './StayDate';
import { BookingDate } from './BookingDate';
import { SelectedDatesInfo } from './SelectedDatesInfo';
import { RoomRatePlanSelectable } from './RoomRatePlanSelectable';
import { isAllRateplanSelected } from 'utilities/Utils';
import { AdvanceSettingsProps } from '../data/types/Schema';

export const AdvanceSettings = ({
  roomAndRatePlan,
  selectedRatePlan,
  isEdit,
  strings: { LOS: LOS_STRINGS },
}: AdvanceSettingsProps) => {
  const [
    {
      PROMOTION_REVAMP: { COMMON: STRINGS },
    },
    COMMON_STRINGS,
  ] = useLangStrings<'Promotions'>('Promotions');
  const [PERFORMANCE_STRINGS] =
    useLangStrings<'PerformanceBooster'>('PerformanceBooster');

  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
  const roomRpSelect = usePackagesRoomRpSelectable(
    roomAndRatePlan,
    selectedRatePlan,
    isEdit,
  );
  const [selectAll, setSelectAll] = useState(() =>
    isAllRateplanSelected(roomRpSelect.roomRpsList),
  );

  const { control, setValue } = useFormContext();
  const [
    promotionBasedOn,
    bookingStartDate,
    checkinNoEndDate,
    checkinStartDate,
    checkinEndDate,
    bookingNoEndDate,
    bookingEndDate,
  ] = useWatch({
    name: [
      'promotionBasedOn',
      'bookingStartDate',
      'checkinNoEndDate',
      'checkinStartDate',
      'checkinEndDate',
      'bookingNoEndDate',
      'bookingEndDate',
    ],
    control,
  });

  const options = [
    {
      label: <Typography>{LOS_STRINGS.ADVANCE_SETTINGS.BOOKING}</Typography>,
      value: 'booking',
    },
    {
      label: (
        <Typography>
          {LOS_STRINGS.ADVANCE_SETTINGS.BOOKING_AND_CHECKIN}
        </Typography>
      ),
      value: 'booking&stay',
    },
  ];

  const onAddClick = () => {
    setShowAdvanceSettings(!showAdvanceSettings);
  };

  const clickOnSelectAll = () => {
    const updatedObject = { ...roomRpSelect.roomRpsList };
    const rooms = Object.keys(roomRpSelect.roomRpsList);
    rooms.forEach(roomCode => {
      const rateplans = Object.keys(roomRpSelect.roomRpsList[roomCode]);
      rateplans.forEach(rpCode => {
        updatedObject[roomCode][rpCode] = !selectAll.isChecked;
      });
    });
    roomRpSelect.setRoomRpsList(updatedObject);
  };

  useEffect(() => {
    if (promotionBasedOn === 'booking') {
      setValue('checkinStartDate', null);
      setValue('checkinEndDate', null);
      setValue('checkinNoEndDate', null);
    }
    if (!bookingNoEndDate) setValue('bookingEndDate', null);
    if (!checkinNoEndDate) setValue('checkinEndDate', null);
  }, [promotionBasedOn, bookingNoEndDate, checkinNoEndDate]);

  useEffect(() => {
    setValue('selectedRoomAndRp', roomRpSelect.roomRpsList);
    const isAllSelected = isAllRateplanSelected(roomRpSelect.roomRpsList);
    setSelectAll(isAllSelected);
  }, [roomRpSelect.roomRpsList]);

  const propsForBookingDate = {
    bookingStartDate,
    bookingNoEndDate,
    LOS_STRINGS,
    control,
  };
  const propsForStayDate = {
    bookingStartDate,
    checkinStartDate,
    checkinNoEndDate,
    LOS_STRINGS,
    control,
  };
  const propsForSelectedDatesInfo = {
    showAdvanceSettings,
    bookingStartDate,
    PERFORMANCE_STRINGS,
    LOS_STRINGS,
    promotionBasedOn,
    bookingEndDate,
    checkinStartDate,
    checkinEndDate,
  };
  const propsForRoomRatePlanSelectable = {
    selectAll,
    clickOnSelectAll,
    roomRpSelect,
    LOS_STRINGS,
  };

  return (
    <div>
      <div className="flex flex-col gap-[10px]">
        <div className="flex justify-between">
          <Typography variant="h3">
            {LOS_STRINGS.ADVANCE_SETTINGS.TITLE}
          </Typography>
          <Button onClick={onAddClick} variant="text" size="small">
            {showAdvanceSettings
              ? COMMON_STRINGS.BUTTONS.HIDE
              : COMMON_STRINGS.BUTTONS.SHOW}
          </Button>
        </div>
        {!showAdvanceSettings && (
          <Typography variant="subtitle1" themeColor="text.secondary">
            {LOS_STRINGS.ADVANCE_SETTINGS.DESCRIPTION}
          </Typography>
        )}
      </div>
      {showAdvanceSettings && (
        <div>
          <div className="border-[1px] border-solid bg-color-light border-divider rounded-lg p-4 mt-4">
            <Typography variant="h3">
              {LOS_STRINGS.SPECIFY_BENEFIT_VALIDITY}
            </Typography>
            <ControlledRadio
              name="promotionBasedOn"
              control={control}
              options={options}
              inline
              customClasses={{
                groupRoot: '!mt-3',
              }}
            />
            <div className="border-[1px] border-solid bg-color-light border-divider rounded px-4 mt-2">
              <FormElement>
                <div className="w-full">
                  <BookingDate {...propsForBookingDate} />
                  {promotionBasedOn === 'booking&stay' && (
                    <>
                      <Divider className="my-4" />
                      <StayDate {...propsForStayDate} />
                    </>
                  )}
                </div>
              </FormElement>
            </div>
            <SelectedDatesInfo {...propsForSelectedDatesInfo} />
          </div>
          <div className="border-[1px] border-solid bg-color-light border-divider rounded p-4 mt-4">
            <Typography variant="subHeadingMedium">
              {STRINGS.BLACKOUT_DATES}
            </Typography>
            <Typography variant="body1" className="my-4">
              {LOS_STRINGS.CHOOSE_CHECKIN_DATES}
            </Typography>
            <FormElement>
              <ControlledCalendar
                name="blackoutDate"
                control={control}
                type="blackout"
                variant="multiple"
                inputClasses="!w-[250px]"
              />
            </FormElement>
          </div>
          <RoomRatePlanSelectable {...propsForRoomRatePlanSelectable} />
        </div>
      )}
    </div>
  );
};
