import { useEffect, useState } from 'react';
import { errorMessages } from 'modules/bookings-revamp/bookings.constants';
import { SuccessInvoice, ErrorInvoice } from 'assets/common';
import { InvoiceScanner, UnderModerationIcon } from 'assets/modules/bookings';
import { Typography } from 'components/latest-core';
import { LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { GST_INVOICE_STATUS_MAP } from '../../constants';
import { useLangStrings } from 'utilities/CustomHooks';

const statusColors = {
  success: 'green',
  pending: 'blue',
  error: 'red',
  warning: '#F2A526',
  [GST_INVOICE_STATUS_MAP.IN_MODERATION]: '#F2A526',
};

type RejectCompProps = {
  message: string[];
  statusColor: string;
};

const RejectReasonsMessage = (props: RejectCompProps) => {
  const { message, statusColor } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const data = isExpanded ? message : message.slice(0, 2);
  const [COMMON] = useLangStrings<'Common'>('Common');

  return (
    <>
      {data.map((msg, idx) => (
        <Typography variant="body1" className={`mt-1 text-[${statusColor}]`}>
          {msg}
          <span
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-2 cursor-pointer text-primary-default"
          >
            {idx === data.length - 1
              ? isExpanded
                ? COMMON.LABELS.SHOW_LESS
                : COMMON.LABELS.SHOW_MORE
              : null}
          </span>
        </Typography>
      ))}
    </>
  );
};

type StyleProps = {
  status: string;
  isExternalError: boolean;
};

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 3,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: 'gray',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props: StyleProps) =>
      props.isExternalError ? 'red' : statusColors[props.status],
  },
}))(LinearProgress);

type FileProps = {
  fileObj: {
    file: File;
    name: string;
    status: string;
    progress: number;
    externalError: string[];
    message: string | string[];
  };
};

export default function FileUploadStatusComp(props: FileProps) {
  const { fileObj } = props;
  const { file, status, externalError, message = null } = fileObj;
  const isExternalError = externalError?.length > 0;
  const [customProgress, setCustomProgress] = useState(
    isExternalError ? 100 : 0,
  );
  const showRejectReason =
    status === 'error' ||
    status === 'warning' ||
    status === GST_INVOICE_STATUS_MAP.IN_MODERATION;
  const isPending = status === 'pending';

  useEffect(() => {
    let interId;
    if (isPending && !isExternalError) {
      interId = setInterval(() => {
        if (customProgress < 90) {
          setCustomProgress(prevProgress => prevProgress + 10);
        } else {
          setCustomProgress(90);
          clearInterval(interId);
        }
      }, 1000);
    } else {
      setCustomProgress(100);
    }
    return () => {
      clearInterval(interId);
    };
  }, [customProgress, status]);

  return (
    <div>
      <div className="flex justify-between">
        <Typography variant="body1" className="text-common-dark uppercase">
          {file.name}
        </Typography>
        {status === 'success' && (
          <div>
            <SuccessInvoice />
          </div>
        )}
        {(status === 'error' || isExternalError) && (
          <div>
            <ErrorInvoice />
          </div>
        )}
        {status === 'in_wip' && <img src={UnderModerationIcon} alt="" />}
        {status === 'pending' && !isExternalError && (
          <img src={InvoiceScanner} alt="scanner" height={20} width={20} />
        )}
      </div>
      <div className="mt-2">
        <BorderLinearProgress
          variant="determinate"
          value={customProgress}
          status={status}
          isExternalError={isExternalError}
        />
        {showRejectReason && (
          <div style={{ color: statusColors[status] }}>
            {Array.isArray(message) ? (
              <RejectReasonsMessage
                message={message}
                statusColor={statusColors[status]}
              />
            ) : (
              <div className="mt-1">{message ?? 'Failed while uploading'}</div>
            )}
          </div>
        )}
      </div>
      {externalError.map(error => (
        <div className="mt-1 text-red-500">{errorMessages[error]}</div>
      ))}
    </div>
  );
}
