import { groupDates } from 'utilities/DateUtils';
import { BlackoutChips } from '../components/BlackoutChips';
import { CalendarInput } from './CalendarInput';
import { BlackoutCalendarInputProps } from '../Calendar.types';

export const BlackoutCalendarInput = (props: BlackoutCalendarInputProps) => {
  if (props.calendarProps.variant !== 'multiple') {
    throw new Error('BlackoutCalendarInput only supports multiple variant');
  }

  const {
    calendarProps: { selectedDays, handleRemoveMultipleDates },
  } = props;
  const groupedDates = groupDates(selectedDays, false);

  const { skipHideOnSelect = true, secondaryCta = 'apply', ...rest } = props;

  return (
    <div className="flex flex-col">
      <CalendarInput
        skipHideOnSelect={skipHideOnSelect}
        secondaryCta={secondaryCta}
        {...rest}
      />
      <BlackoutChips
        groupedDates={groupedDates}
        handleRemoveDates={handleRemoveMultipleDates}
      />
    </div>
  );
};
