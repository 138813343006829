import { useState } from 'react';
import { Dropdown, Radio, ToggleButton } from 'components/latest-core';
import { FormElement } from 'components/new-core/';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  dayOptions,
  DefaultCheckInWeekDay,
  defaultEarlyBirdMax,
  defaultEarlyBirdMin,
  defaultMaxLosValue,
  defaultMinLosValue,
  newPromotionTypes,
  optionsTypeMap,
  yesNoOpts,
} from '../Promotions.constants';
import { parseDate } from './index';

const CustomOfferRestrictions = props => {
  const { customOfferRestrictionState: cRS, isFreeNightCustomOffer } = props;
  const [STRINGS] = useLangStrings('Promotions');
  const NSTRINGS = STRINGS.CREATE_SECTIONS.OFFER_RESTRICTIONS;

  const getValue = value => {
    return value ? 'yes' : 'no';
  };

  return (
    <tr>
      <td>{NSTRINGS.NAME}</td>
      <td>
        <div className="restriction-sub-heading">{NSTRINGS.LABEL1}</div>
        <div className="restriction-comp-wrapper">
          <FormElement label={NSTRINGS.DESC1}>
            <Radio
              variant="legacy-boxed"
              value={getValue(cRS.hasMaxLOS)}
              options={yesNoOpts}
              onChange={val => cRS.setHasMaxLOS(val === 'yes')}
              inline
            />
          </FormElement>
          {cRS.hasMaxLOS && (
            <FormElement label={NSTRINGS.DESC2}>
              <Dropdown
                name="maxLos"
                className="marginT5"
                value={cRS.maxLos}
                options={optionsTypeMap[newPromotionTypes.LOS]}
                onChange={cRS.setMaxLos}
                variant="default"
              />
            </FormElement>
          )}
        </div>
        <div className="restriction-comp-wrapper">
          <FormElement label={NSTRINGS.DESC3}>
            <Radio
              variant="legacy-boxed"
              value={getValue(cRS.hasMinLOS)}
              name="hasMinLOS"
              options={yesNoOpts}
              onChange={val => cRS.setHasMinLOS(val === 'yes')}
              disabled={isFreeNightCustomOffer}
              inline
            />
          </FormElement>
          {cRS.hasMinLOS && (
            <FormElement label={NSTRINGS.DESC4}>
              <Dropdown
                name="minLos"
                className="marginT5"
                value={cRS.minLos}
                options={optionsTypeMap[newPromotionTypes.LOS]}
                onChange={cRS.setMinLos}
                disabled={isFreeNightCustomOffer}
                variant="default"
              />
            </FormElement>
          )}
        </div>
        <div className="restriction-sub-heading">{NSTRINGS.LABEL2}</div>
        <div className="restriction-comp-wrapper">
          <FormElement label={NSTRINGS.DESC5}>
            <Radio
              variant="legacy-boxed"
              value={getValue(cRS.hasEBMin)}
              name="hasEBMin"
              options={yesNoOpts}
              onChange={val => cRS.updateHasEBMin(val === 'yes')}
              inline
            />
          </FormElement>
          {cRS.hasEBMin && (
            <FormElement label={NSTRINGS.DESC6}>
              <Dropdown
                name="EBMin"
                className="marginT5"
                value={cRS.EBMin}
                options={optionsTypeMap[newPromotionTypes.EBD]}
                onChange={cRS.setEBMin}
                variant="default"
              />
            </FormElement>
          )}
        </div>
        <div className="restriction-comp-wrapper">
          <FormElement label={NSTRINGS.DESC7}>
            <Radio
              variant="legacy-boxed"
              value={getValue(cRS.hasEBMax)}
              name="hasEBMax"
              options={yesNoOpts}
              onChange={val => cRS.updateHasEBMax(val === 'yes')}
              inline
            />
          </FormElement>
          {cRS.hasEBMax && (
            <FormElement label={NSTRINGS.DESC8}>
              <Dropdown
                name="EBMax"
                className="marginT5"
                value={cRS.EBMax}
                options={optionsTypeMap[newPromotionTypes.LMD]}
                onChange={cRS.setEBMax}
                variant="default"
              />
            </FormElement>
          )}
        </div>
        <div className="restriction-sub-heading">{NSTRINGS.LABEL3}</div>
        <div className="restriction-comp-wrapper">
          <FormElement label={NSTRINGS.DESC9}>
            <Radio
              variant="legacy-boxed"
              value={getValue(cRS.applicableOnSelectedDays)}
              name="applicableOnSelectedDays"
              options={yesNoOpts}
              onChange={val => cRS.setApplicableOnSelectedDays(val === 'yes')}
              inline
            />
          </FormElement>
          {cRS.applicableOnSelectedDays && (
            <FormElement label={' '} className="marginT22">
              <ToggleButton
                data={dayOptions}
                value={cRS.selectedDays}
                onChange={cRS.setSelectedDays}
                isMultiple
                disableRipple
              />
            </FormElement>
          )}
        </div>
      </td>
    </tr>
  );
};

export function useOfferRestrictionState(promotionData) {
  const [hasMaxLOS, setHasMaxLOS] = useState(promotionData?.hasMaxLOS ?? false);
  const [maxLos, setMaxLos] = useState(promotionData?.maxLos ?? null);
  const [hasMinLOS, setHasMinLOS] = useState(promotionData?.hasMinLOS ?? false);
  const [minLos, setMinLos] = useState(promotionData?.minLos ?? null);
  const [hasEBMin, setHasEBMin] = useState(promotionData?.hasEBMin ?? false);
  const [EBMin, setEBMin] = useState(promotionData?.EBMin ?? null);
  const [hasEBMax, setHasEBMax] = useState(promotionData?.hasEBMax ?? false);
  const [EBMax, setEBMax] = useState(promotionData?.EBMax ?? null);
  const [applicableOnSelectedDays, setApplicableOnSelectedDays] = useState(
    promotionData?.applicableOnSelectedDays ?? true,
  );
  const [selectedDays, setSelectedDays] = useState(
    promotionData?.selectedDays ?? [0, 1, 2, 3, 4, 5, 6],
  );
  const updateHasEBMin = val => {
    setHasEBMax(false);
    setHasEBMin(val);
  };
  const updateHasEBMax = val => {
    setHasEBMin(false);
    setHasEBMax(val);
  };
  return {
    hasMaxLOS,
    setHasMaxLOS,
    maxLos,
    setMaxLos,
    hasMinLOS,
    setHasMinLOS,
    minLos,
    setMinLos,
    hasEBMin,
    updateHasEBMin,
    EBMin,
    setEBMin,
    hasEBMax,
    updateHasEBMax,
    EBMax,
    setEBMax,
    applicableOnSelectedDays,
    setApplicableOnSelectedDays,
    selectedDays,
    setSelectedDays,
  };
}

export function getPayloadFrRestrictions(offer) {
  let toReturn: {
    minLos?: number;
    maxLos?: number;
    earlyBirdMin?: number;
    earlyBirdMax?: number;
    checkinWeekDay?: string[];
    night?: number;
    startDate?: string;
    endDate?: string;
  } = {};

  toReturn.minLos = offer.hasMinLOS
    ? offer.minLos?.value ?? defaultMinLosValue
    : defaultMinLosValue;
  toReturn.maxLos = offer.hasMaxLOS
    ? offer.maxLos?.value ?? defaultMaxLosValue
    : defaultMaxLosValue;
  toReturn.earlyBirdMin = offer.hasEBMin
    ? offer.EBMin?.value ?? defaultEarlyBirdMin
    : defaultEarlyBirdMin;

  if (toReturn.earlyBirdMin === defaultEarlyBirdMin) {
    toReturn.earlyBirdMax = offer.hasEBMax
      ? offer.EBMax?.value ?? defaultEarlyBirdMax
      : defaultEarlyBirdMax;
  }

  toReturn.checkinWeekDay = offer.applicableOnSelectedDays
    ? offer.selectedDays.map(item => String(item))
    : DefaultCheckInWeekDay;

  if (offer.toOffer === 'freeNights') {
    toReturn.night = offer.freeNights;
    toReturn.minLos = offer.freeNights + offer.bookNights;
  } else {
    toReturn = {
      ...toReturn,
      ...parseDate(offer),
    };
  }

  return toReturn;
}

export default CustomOfferRestrictions;
