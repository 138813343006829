import { Room } from '../types/Rooms';
import {
  DeactivatePackage,
  FetchLosServiceResponse,
  FetchServiceResponse,
  LeafCategory,
  SavePackageRequestPayload,
  SavePackageResponsePayload,
  ServicesTemplateResponse,
  ValidationRequestPayload,
} from '../types/Schema';
import {
  getValue,
  groupBy,
  isNullOrUndefined,
} from '../../../../utilities/Utils';
import * as Queries from './packageGraphQueries';
import { doMutation, doQuery } from '../../../../base/GraphQLClient';
import { QueryClient } from '@tanstack/react-query';
import { fetchServicesQueryKeys } from '../queries/packagesQueries';
import {
  createServiceInput,
  mergeInclusionsAndTemplates,
  TemplatesState,
} from '../selectors/templateResponseSelector';
import { showError } from 'base/APIClient';
import {
  LOS_PACKAGE_SERVICE_TEMPLATE_VARIABLE,
  PACKAGE_API_CAL_VARIABLE,
  getApplicabilityDetails,
  getRatePlanIds,
} from 'modules/package-inclusions/utils';

export async function fetchRoomsAndRatePlans({
  hotelId,
  editMode,
}: {
  hotelId: string;
  editMode: boolean;
}): Promise<Array<Room>> {
  const result = await doQuery(
    Queries.FetchRoomRatePlans,
    {
      operationName: 'FetchRoomRatePlans',
      rawEndpointRequestData: {
        rpcRatePlanRequest: {
          endPointId: 99,
          modelType: 'modelType1',
          body: {
            ingoHotelId: hotelId,
            searchRequest: {
              filters: [
                {
                  field: 'isPackagesFlow',
                  value: editMode ? 'false' : 'true',
                },
              ],
            },
          },
        },
      },
    },
    { useLoader: false },
  );
  const {
    data: {
      rawEndpoint: {
        roomRatePlansResponse: { rooms },
      },
    },
  } = result;
  return rooms;
}

export type FetchInclusionsOptions = {
  hotelId: string;
  ratePlanId: string;
  source: 'summary' | 'inclusions';
  cachedResponse?: FetchServiceResponse | undefined;
};

export async function fetchTemplates(): Promise<TemplatesState> {
  const result = await doQuery(
    Queries.FetchTemplates,
    {
      rawEndpointRequestData: {
        rpcFetchServiceTemplates: {
          modelType: 'modelType1',
          body: {
            serviceType: 'inclusion',
            searchRequest: {
              filters: [
                {
                  field: 'QueryType',
                  value: 'package',
                },
              ],
            },
          },
        },
      },
    },
    { useLoader: false },
  );

  const templateList = result.data.rawEndpoint.fetchServiceTemplates
    .templateList as ServicesTemplateResponse;

  const group = groupBy(
    templateList.serviceTemplateData,
    item => item.subCategoryName,
  );
  return mergeInclusionsAndTemplates({
    categoryOrder: Object.keys(group),
    serviceTemplateData: templateList.serviceTemplateData,
  });
}

export async function fetchInclusions(
  params: FetchInclusionsOptions,
): Promise<FetchServiceResponse> {
  if (params.cachedResponse) {
    return params.cachedResponse;
  }
  const variables = {
    rawEndpointRequestData: {
      rpcFetchServices: {
        modelType: 'modelType1',
        body: {
          ingoHotelId: params.hotelId,
          rateplancode: params.ratePlanId,
          serviceType: 'inclusion',
          segment: [],
          channelManager: '',
          status: ['ACTIVE'],
          searchRequest: {
            filters: [
              {
                field: 'QueryType',
                value: 'package',
              },
              {
                field: 'EntityType',
                value: 'rateplan',
              },
            ],
          },
        },
      },
    },
  };

  const gqlResponse = await doQuery(Queries.FetchServices, variables, {
    useLoader: false,
  });
  const { fetchServices } = gqlResponse.data.rawEndpoint;

  const result = fetchServices as FetchServiceResponse;
  if (isNullOrUndefined(result.servicesResponse?.services ?? null)) {
    result.servicesResponse = {
      services: [],
    };
  }
  result.servicesResponse.services = result.servicesResponse.services
    .filter(value => value.status === 'ACTIVE')
    .sort((a, b) => a.priorityOrder - b.priorityOrder);
  result.ratePlanId = params.ratePlanId;
  return result;
}

export async function validateInclusions(
  reqBody: ValidationRequestPayload,
): Promise<FetchServiceResponse> {
  const inclusions = reqBody.inclusions.map(incl => ({
    id: incl.id,
    leafTemplateId: incl.leafTemplateId,
    subCategoryId: incl.subCategoryId,
    selectedAttributes: JSON.stringify(incl.selectedAttributes),
    status: 1,
    priority: incl.priority,
  }));
  const variables = {
    rawEndpointsRequestData: {
      rpcValidatePackageRequest: {
        modelType: 'modelType1',
        body: {
          ...reqBody,
          inclusions: inclusions,
        },
      },
    },
  };
  const gqlResponse = await doMutation(Queries.ValidationMutation, variables);
  const { fetchServices } = gqlResponse.data.rawEndpoint;
  if (fetchServices.servicesResponse?.services) {
    fetchServices.servicesResponse?.services?.sort(
      (a, b) => a.priorityOrder - b.priorityOrder,
    );
  }
  return fetchServices as FetchServiceResponse;
}

export async function savePackage(
  reqBody: SavePackageRequestPayload,
  queryClient: QueryClient,
): Promise<SavePackageResponsePayload> {
  const modifiedReqBody = JSON.parse(JSON.stringify(reqBody));
  modifiedReqBody.inclusions = reqBody.inclusions.map(value => ({
    ...value,
    selectedAttributes: JSON.stringify(value.selectedAttributes),
  }));

  const variables = {
    endpointRequestData: {
      savePackageRequests: {
        rpcSavePackage: {
          modelType: 'modelType1',
          body: modifiedReqBody,
        },
      },
    },
  };
  const gqlResponse = await doMutation(Queries.SAVE_PACKAGE, variables, {
    useLoader: false,
  });
  if (reqBody.packageCode) {
    const summaryPageKey = fetchServicesQueryKeys.summaryPageKey({
      hotelId: reqBody.ingoHotelId,
      ratePlanId: reqBody.packageCode,
    });
    const inclusionsPageKey = fetchServicesQueryKeys.inclusionsPageKey({
      hotelId: reqBody.ingoHotelId,
      ratePlanId: reqBody.packageCode,
    });
    queryClient.removeQueries({
      queryKey: summaryPageKey,
      type: 'all',
      exact: true,
    });
    queryClient.removeQueries({
      queryKey: inclusionsPageKey,
      type: 'all',
      exact: true,
    });
  }

  return gqlResponse.data.rpcSavePackage;
}

export async function deactivate(reqBody: DeactivatePackage): Promise<unknown> {
  const variables = {
    endpointRequestData: {
      savePackageRequests: {
        rpcSavePackage: {
          modelType: 'modelType1',
          body: reqBody,
        },
      },
    },
  };
  const gqlResponse = await doMutation(Queries.SAVE_PACKAGE, variables);
  return gqlResponse.data.rpcSavePackage;
}

export const fetchLosPkgServiceTemplate = async (hotelId: string) => {
  try {
    const res = await doQuery(
      Queries.LOS_PACKAGE_SERVICE_TEMPLATE_QUERY,
      LOS_PACKAGE_SERVICE_TEMPLATE_VARIABLE(hotelId),
      { showError: false, useLoader: false },
    );
    const { templateList, error } = getValue(
      res,
      'data.rawEndpoint.fetchServiceTemplatesInclusion',
      {},
    );
    if (error) {
      throw error;
    }
    const serviceTemplateData =
      templateList.serviceTemplateData as LeafCategory[];
    const group = groupBy(serviceTemplateData, item => item.subCategoryName);
    const data = mergeInclusionsAndTemplates({
      categoryOrder: Object.keys(group),
      serviceTemplateData,
    });
    return data;
  } catch (e) {
    showError(e);
    throw e;
  }
};

// @ts-ignore
export const fetchLosPackageDetails = async ({
  hotelCode,
  mmtId,
  packageId,
  clearPackageId,
}) => {
  try {
    const res = await doQuery(
      Queries.FETCH_LOS_PACKAGE_DETAILS,
      PACKAGE_API_CAL_VARIABLE({ hotelCode, mmtId, packageId }),
      { useLoader: false, showError: false },
    );
    const { rooms: roomRateplanData, error: roomRateplanError } = getValue(
      res,
      'data.roomRateplanData.roomRatePlansResponse',
      {},
    );
    const { data: packageData, error: packageDataError } = getValue(
      res,
      'data.rpcGetPackageRequest.rpcGetPackageResponse',
      {},
    );
    const { servicesResponse: packageServices, error: packageServicesError } =
      getValue(res, 'data.fetchServices.fetchServices', {});

    if (roomRateplanError || packageDataError || packageServicesError)
      throw roomRateplanError || packageDataError || packageServicesError;

    const servicesResponse = {
      minLosNode: packageData.minLosNode,
      packageId: packageData.packageId,
      hotelId: packageData.hotelId,
      rateplanIds: packageData.rateplanIds,
      name: packageData.name,
      type: packageData.type,
      applicabilityDetails: {
        bookingStartDate: packageData.applicabilityDetails?.bookingStartDate,
        bookingEndDate: packageData.applicabilityDetails?.bookingEndDate,
        checkinDate: packageData.applicabilityDetails?.checkinDate,
        checkoutDate: packageData.applicabilityDetails?.checkoutDate,
        blackoutDates: packageData.applicabilityDetails?.blackoutDates,
      },
      validatePackageResponse: {
        minimumInclusions:
          packageData.packageValidations.minMandatoryInclusionCount,
        matchingInclusions:
          packageData.packageValidations.eligibleInclusionCount ?? 0,
      },
      servicesResponse: packageServices,
      packageAttributes: {
        minLengthOfStay: packageData.packageAttributes?.minLengthOfStay,
      },
    } as FetchLosServiceResponse;
    return { roomRateplanData, servicesResponse };
  } catch (e) {
    showError(e);
    if (e.errorCode === 'EIE_0030') clearPackageId();
    else throw e;
  }
};

export async function verifyLosInclusions(reqBody) {
  const inclusions = reqBody.benefits.map(incl => ({
    id: incl.id,
    leafCategoryId: incl.leafTemplateId,
    subCategoryId: incl.subCategoryId,
    selectedAttributes: JSON.stringify(incl.selectedAttributes),
    status: 'active',
    priority: incl.priority,
  }));
  const variables = {
    rawEndpointRequestData: {
      rpcVerifyPackageRequest: {
        modelType: 'modelType1',
        body: {
          ...reqBody,
          benefits: inclusions,
        },
      },
    },
  };
  const verifyResponse = await doMutation(
    Queries.VERIFY_LOS_PACKAGE,
    variables,
    {},
    null,
  );
  const res =
    verifyResponse.data.rpcVerifyPackageRequest.rpcVerifyPackageResponse;

  if (res.error) {
    return res.error;
  }
  const { benefits, packageValidations, ...restOfPayload } = res.data;
  if (benefits) {
    benefits.sort((a, b) => a.priority - b.priority);
  }
  const errorMappings = packageValidations.errorMappings;
  const mappedErrorMappings = Object.keys(errorMappings || {}).reduce(
    (acc, key) => {
      return {
        ...acc,
        key: {
          ...errorMappings[key],
          LongDescription: errorMappings[key].longDescription,
        },
      };
    },
    {},
  );
  const transformedResponse = {
    ...restOfPayload,
    success: true,
    servicesResponse: {
      services:
        benefits?.map(benefit => ({
          ...benefit,
          selectedAttributes: benefit.selectedAttributes,
          leafCategory: benefit.leafCategory,
        })) || [],
    },
    validatePackageResponse: {
      minimumInclusions: packageValidations.minMandatoryInclusionCount,
      matchingInclusions: packageValidations.eligibleInclusionCount ?? 0,
      errorMappings: mappedErrorMappings,
    },
  };
  return transformedResponse;
}

export async function saveLosPackage(reqBody) {
  const {
    minStayDuration,
    customStayDuration,
    packageName,
    hotelId,
    serviceResponse,
    selectedRoomAndRp,
  } = reqBody;

  const inclusionRequest =
    serviceResponse.servicesResponse.services.map(createServiceInput);
  const stringifiedInclusionVals = inclusionRequest.map(value => ({
    ...value,
    status: 'active',
    selectedAttributes: JSON.stringify(value.selectedAttributes),
  }));

  const variables = {
    rawEndpointsRequestData: {
      savePackageInclusion: {
        modelType: 'modelType1',
        body: {
          id: serviceResponse.packageId
            ? +serviceResponse.packageId
            : undefined,
          hotelId: hotelId,
          status: 'active',
          rateplanIds: getRatePlanIds(selectedRoomAndRp),
          name: packageName,
          type: 'los',
          packageAttributes: {
            minLengthOfStay:
              Number(minStayDuration) === 0
                ? Number(customStayDuration.value)
                : Number(minStayDuration),
          },
          applicabilityDetails: getApplicabilityDetails(reqBody),
          inclusions: stringifiedInclusionVals,
        },
      },
    },
  };

  return doMutation(Queries.SAVE_LOS_PACKAGE, variables, {}, null);
}

export async function deactivateLosPackage(reqBody) {
  const { id, hotelId } = reqBody;
  const variables = {
    rawEndpointsRequestData: {
      savePackageInclusion: {
        modelType: 'modelType1',
        body: {
          id: +id,
          hotelId: hotelId,
          status: 'inactive',
        },
      },
    },
  };

  return doMutation(Queries.SAVE_LOS_PACKAGE, variables, {}, null);
}
