import { Divider, Drawer } from 'components/latest-core';
import { useLangStrings } from 'utilities/CustomHooks';
import { ExistingAccountsType } from '../ResellerOnboarding';

type ExistingAccountsSideDrawerProps = {
  existingAccounts: ExistingAccountsType[];
  isOpen: boolean;
  onClose: () => void;
};

const ExistingAccountsSideDrawer = (props: ExistingAccountsSideDrawerProps) => {
  const { isOpen, onClose, existingAccounts } = props;

  const [COMMON] = useLangStrings<'Common'>('Common');

  return (
    <Drawer
      isOpen={isOpen}
      header={{
        title: 'Existing Accounts',
      }}
      footer={{
        primaryBtn: {
          text: 'Close',
          onClick: onClose,
        },
      }}
      onClose={onClose}
    >
      {existingAccounts.length > 0 && (
        <div className="border border-solid border-[#D2D1D1] rounded-[4px]">
          <div className="px-3 py-2 flex flex-row items-center justify-between text-xs bg-[#F8F8F8]">
            <div className="uppercase">{COMMON.LABELS.EMAIL_ID}</div>
            <div className="uppercase">{COMMON.LABELS.PHONE_NUMBER}</div>
          </div>
          <Divider />
          <div className="p-3">
            {existingAccounts.map((account, idx) => {
              return (
                <div
                  className={`flex flex-row justify-between items-center ${
                    idx !== existingAccounts.length - 1 ? 'mb-4' : ''
                  }`}
                  key={idx}
                >
                  <div>{account.email}</div>
                  <div>
                    {account.countrycode} {account.mobile}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default ExistingAccountsSideDrawer;
