import React, { useState, useEffect } from 'react';

import Loader from '../components/Loader';
import { AppEvents } from '../utilities/EventBus';
import { callCounterRef } from 'utilities/Utils';

export default function WrappedLoader() {
  const [isLoading, setLoaderVisibility] = useState(false);

  useEffect(() => {
    const showGlobalLoader = value => {
      if (value) {
        if (!isLoading) {
          setLoaderVisibility(true);
        }
      } else if (callCounterRef.curr === 0) {
        setLoaderVisibility(false);
      }
    };
    AppEvents.on('showGlobalLoader', showGlobalLoader);
    return () => {
      AppEvents.off('showGlobalLoader', showGlobalLoader);
    };
  }, []);

  if (isLoading) {
    return <Loader className="global-loader-overlay" />;
  }
  return null;
}
