import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import ErrorBoundary from '../ErrorBoundary';
import NotFound from '../../modules/404page/notfound';
import QualityScoreBanner from './QualityScoreBanner';
import ContentScoreBanner from './ContentScoreBanner';
import LeftNav, { EVENT_LABEL_OPTIONS } from './../navigation/LeftNav';
import VerificationBlockerMain from '../../modules/VerificationBlocker/VerificationBlockerMain';

import { noRedirectRoutes, routes } from '../routes';
import {
  BookingsRevampMain,
  decideTabs,
  InventoryMain,
  reportsRoute,
  resellerOnlyTabs,
} from './HotelTabs';
import HelpCentre from '../../modules/help-centre/HelpCentreMain';
import { ensureLegacyParity, independentAPICalls } from './legacyParity';
import { AppContext, HotelContext } from '../../utilities/Context';
import { getCurrentHotel, getMiscDetails } from './HotelAPIClient';

import {
  useLangStrings,
  useScrollAndFocusToCurrentElement,
} from '../../utilities/CustomHooks';
import TermsAndConditionsModal from './TermAndConditionsModal';
import HotelTanModal from './HotelTanModal';
import {
  CurrentHotel,
  IPlatform,
  QualityScoreState,
  RegisterationDetails,
  RoomRateplan,
  TabsInfo,
} from '../../interfaces/HotelContextInterface';
import {
  CalSyncData,
  IChainData,
  IHotel,
  ITanPopup,
  ITnC,
  IndependentAPICallsResponse,
} from './interfaces';
import NavigationContainer from '../navigation/NavigationContainer';
import { HelpCentreIcon, RaiseATicketIcon } from '../../assets/modules/app';
import HotelHeader from './HotelHeader/HotelHeader';
import BlockedForExtranet from './BlockedForExtranet';

import {
  clearQueryParams,
  createCommonParentRoute,
  isNullOrUndefined,
  navigateTo,
  showMessage,
} from 'utilities/Utils';
import { combineHotelDetail } from './Hotel.utils';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';
import { CampaignEntityAgreement } from 'modules/campaign-manager/Campaigns.types';
import ResellerMain from 'modules/reseller/ResellerMain';
import useApiRequest from './Reducer/useApiRequest';
import {
  APIS,
  HotelLegalEntityType,
  UserProfileType,
  UserProfileViewType,
} from './constants';
import queryString from 'query-string';
import {
  syncEnabledRoomsAPI,
  decideShowDashboardTermsAndConditionsBanner,
  getRoomRateplans,
} from './HotelGraphClient';
import {
  RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
  RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE,
  hotelLegalEntityTypeMap,
} from '../../constants';
import GlobalShimmer from '../shimmers/GlobalShimmer';
import ContainerShimmer from '../shimmers/ContainerShimmer';
import useResellerOnboardingEffect from './hooks/useResellerOnboardingEffect';
import useRedirectToResellerView from './hooks/useRedirectToResellerView';
import PackageLongStay from 'modules/package-inclusions/pages/PackageLongStay';

import './HotelMain.scss';

export interface HotelMainProps {
  chainData: IChainData;
  hotelList: Array<IHotel>;
}

//Off case
const QualityScoreMain = React.lazy(
  () => import('../../modules/quality-score/QualityScoreMain'),
);

const DemandHeatMap = React.lazy(
  () => import('../../modules/demand-heat-map/DemandHeatMap'),
);

const CompsetMain = React.lazy(
  () => import('../../modules/compset/CompsetMain'),
);

const MyImpactMain = React.lazy(
  () => import('../../modules/my-impact/MyImpactMain'),
);

export const SaleHandlingMain = React.lazy(
  () => import('../../modules/sale-handling/SaleHandlingMain'),
);

const PackagesMain = React.lazy(
  () => import('../../modules/package-inclusions/PackagesMain'),
);

function HotelMain(props: HotelMainProps) {
  const { hotelList, chainData } = props;

  const [STRINGS, COMMON] = useLangStrings<'Dashboard'>('Dashboard');
  const { search, pathname } = useLocation();
  const appState = useContext(AppContext);
  const { user, isStaff, userProfileType } = appState;
  const [state, makeRequest, resetApiData, updateApiData] = useApiRequest();
  const [currentHotel, setCurrentHotel] = useState<CurrentHotel>(null);
  const [roomRatePlan, setRoomRatePlan] = useState<RoomRateplan>(null);
  const [qualityScoreState, setQSState] = useState<Partial<QualityScoreState>>(
    {},
  );
  const [tabsInfo, setTabsInfo] = useState<TabsInfo[]>([]);
  const [extraCallsSettled, setExtraCallsSettled] = useState<boolean>(false);
  const [guestChatUnReadCount, setGuestChatUnReadCount] = useState(null);
  const [instayIssuesCount, setInstayIssuesCount] = useState(null);
  const [reviewsCount, setReviewsCount] = useState(null);
  const [showHelpdeskDrawer, setShowHelpdeskDrawer] = useState<boolean>(false);
  const [termsAndConditionData, setTermsAndConditionData] =
    useState<ITnC>(null);
  const [tanPopupData, setTanPopupData] = useState<ITanPopup>(null);
  const [propertyGstStatus, setPropertyGstStatus] = useState<boolean>(false);
  const [campaignEntity, setCampaignEntity] = useState<CampaignEntityAgreement>(
    {},
  );
  const [userProfileView, setUserProfileView] = useState<UserProfileViewType>(
    UserProfileViewType.INGO,
  );
  const [platforms, setPlatforms] = useState<IPlatform[]>([]);
  const [pendingPrebuyOrdersCount, setPendingPrebuyOrdersCount] =
    useState<number>(0);
  const [isFetchingHotelMetadata, setIsFetchingHotelMetadata] = useState(false);
  const [externalCalSyncData, setExternalCalSyncData] = useState({
    calSyncEnabledRoomList: [],
    calSyncNotEnabledRoomList: [],
    calSyncMultiRoomList: [],
  });

  const [isFirstCall, setIsFirstCall] = useState(true);
  const topLevelCallInProgress = useRef(false);
  const isHotelCodeSearch = useRef(false);
  const navBodyRef = useRef<HTMLDivElement | null>(null);
  const isResellerView = useMemo(
    () =>
      userProfileType === UserProfileType.HOTEL_TRAVEL ||
      userProfileView === UserProfileViewType.HOTEL_TRAVEL,
    [userProfileView],
  );

  const [crtHotel, setCrtHotel] = useState<IHotel>();
  const lastHotel = useRef(null);
  const [registerationDetails, setRegisterationDetails] =
    useState<RegisterationDetails>(null);

  //pass the navbody ref so window will scroll and focus to navBody when startScrollAndFocus is called
  const [startScrollAndFocus] = useScrollAndFocusToCurrentElement(navBodyRef);
  const { apiStatus, response, error } = state;
  const {
    legacyParityOne,
    legacyParityTwo,
    calSyncData,
    [APIS.RESELLER_AGREEMENT]: resellerAgreement,
    featureFlags: { newContentScoreFlag },
  } = response;

  const updateUserProfileView = (newProfileView: UserProfileViewType) => {
    setUserProfileView(newProfileView);

    // Redirect to dashboard if user is on a reseller only page and tries to switch to INGO view
    if (
      newProfileView === UserProfileViewType.INGO &&
      resellerOnlyTabs.includes(pathname)
    ) {
      navigateTo(routes.dashboard.base);
    }
  };

  const refreshCompset = () => {
    makeRequest(APIS.COMPSET_DATA, currentHotel.hotelcode).catch(err => {
      console.error(APIS.COMPSET_DATA, err);
    });
  };

  const fallbackUpdate = () => {
    setCurrentHotel(null);
    setIsFirstCall(false);
    isHotelCodeSearch.current = false;
  };

  const hostWebMigrationComplete = () => {
    const updatedCurrentHotel: CurrentHotel = {
      ...currentHotel,
      showListings: true,
      blockedForExtranet: false,
      hostweb_migration: 'done',
    };

    const allTabs = decideTabs({
      currHotel: updatedCurrentHotel,
      clearTaxInfo: legacyParityOne?.data?.clearTaxDetail || {},
      userRights: user.profile?.rights,
      campaignEntity,
      externalCalSyncData,
      isResellerView,
      commonStr: COMMON,
      showNewContentScore: newContentScoreFlag,
    });

    setCurrentHotel(updatedCurrentHotel);
    setTabsInfo(allTabs);
    navigateTo(routes.listing.base);
  };
  const updateActivityTrack = val => {
    const updatedCurrentHotel: CurrentHotel = {
      ...currentHotel,
      activity_events: val,
    };
    setCurrentHotel(updatedCurrentHotel);
  };

  const decideTnCBanner = (hotelCode: string, source = '') => {
    if (source === 'cta' && isStaff) {
      showMessage({
        show: true,
        message: COMMON.MESSAGES.PERMISSION_DENIED_SU,
        type: 'info',
      });
      return;
    }

    decideShowDashboardTermsAndConditionsBanner(hotelCode)
      .then(setTermsAndConditionData)
      .catch(err => {
        console.error('decideShowDashboardTermsAndConditionsBanner', err);
      });
  };

  const updateHotelDetail = () => {
    setIsFetchingHotelMetadata(true);
    return getCurrentHotel(currentHotel.hotelcode, currentHotel.datasource, {
      channelManagerName: currentHotel.channel_manager_name,
      channelManagerId: currentHotel.channel_manager_id,
    })
      .then(newHotelDetail => {
        updateApiData(APIS.LEGACY_PARITY_PART_1, {
          ...legacyParityOne,
          data: {
            ...legacyParityOne?.data,
            hotelDetail: {
              ...legacyParityOne?.data?.hotelDetail,
              ...newHotelDetail,
            },
          },
        });
        const finalCurrentHotel = combineHotelDetail(
          currentHotel,
          newHotelDetail,
          isStaff,
        );
        setCurrentHotel(finalCurrentHotel);
      })
      .catch(err => {
        console.error('Error while fetching hotel metadata', err);
      })
      .finally(() => {
        setIsFetchingHotelMetadata(false);
      });
  };

  const refreshRoomRateplans = (
    options: { useLoader: boolean } = { useLoader: true },
  ): Promise<RoomRateplan> => {
    return getRoomRateplans(currentHotel.hotelcode, { ...options }).then(
      updatedRoomRateplan => {
        setRoomRatePlan(updatedRoomRateplan);
        return updatedRoomRateplan;
      },
    );
  };

  const refreshCalSync = (): Promise<CalSyncData> => {
    return syncEnabledRoomsAPI(currentHotel.hotelcode, {
      useLoader: true,
    }).then(data => {
      setExternalCalSyncData(data);
      return data;
    });
  };

  const getHotelCodeSearchTabs = allTabs => {
    const tabList = allTabs.filter(
      item =>
        item.label === 'Property' || item.label === 'Property Information',
    );
    tabList.push(reportsRoute);
    return tabList;
  };

  const updateCurrentHotel = (currHotel: IHotel) => {
    if (topLevelCallInProgress.current) {
      return;
    }
    topLevelCallInProgress.current = true;
    if (!isNullOrUndefined(currHotel)) {
      //Reset all counts so UI doesn't show the old count values
      setGuestChatUnReadCount(null);
      setInstayIssuesCount(null);
      setTermsAndConditionData(null);
      setTanPopupData(null);
      setPropertyGstStatus(null);
      //To reset InternalCalSyncData for homestay on hotel change
      resetApiData(APIS.ICS_COMBINED_DATA);
      ensureLegacyParity(currHotel, makeRequest);
      //for triggering the effects for checking the status of the APIs and setting the data and flags appropriately
      setCrtHotel(currHotel);
    } else {
      topLevelCallInProgress.current = false;
      fallbackUpdate();
    }
  };

  const handleHotelCodeSearch = (hotelCode: string) => {
    const hotel = hotelList.find(item => item.hotelcode === hotelCode);
    if (hotel) {
      updateCurrentHotel(hotel);
    } else {
      getMiscDetails(hotelCode)
        .then((res: IHotel & { success: boolean }) => {
          if (res.success) {
            isHotelCodeSearch.current = true;
            //Show only property tab
            updateCurrentHotel(res);
          } else {
            setIsFirstCall(false);
            setCurrentHotel(null);
            setTabsInfo([]);
          }
        })
        .catch(err => {
          console.error('getMiscDetails', err);
        });
    }
  };

  const updateTaskCount = (newCount: string) => {
    const newQsPopup = { ...qualityScoreState.qsPopup };
    newQsPopup.header = newQsPopup.header.replace(/\d/, newCount);
    setQSState({ ...qualityScoreState, qsPopup: newQsPopup });
  };
  const isTabsDataLoaded = [
    apiStatus[APIS.LEGACY_PARITY_PART_1].success,
    apiStatus[APIS.CALSYNC_DATA].success,
    apiStatus[APIS.FEATURE_FLAGS].success,
  ].every(Boolean);

  const isTabsDataLoadingFailed = [
    error[APIS.LEGACY_PARITY_PART_1],
    error[APIS.CALSYNC_DATA],
  ].some(el => !!el);

  const leagcyParityEnsured = [
    isTabsDataLoaded,
    apiStatus[APIS.LEGACY_PARITY_PART_2].success,
  ].every(Boolean);

  const isLegacyParityFailed = [
    isTabsDataLoadingFailed,
    error[APIS.LEGACY_PARITY_PART_2],
  ].some(el => !!el);

  const updateSaleCampaigns = () => {
    makeRequest(APIS.SALE_CAMPAIGNS, {
      mmt_id: currentHotel.mmt_id,
      country: currentHotel.country,
    }).catch(err => {
      console.error(APIS.SALE_CAMPAIGNS, err);
    });
  };

  const updateInternalCalSyncCombinedData = () => {
    makeRequest(APIS.ICS_COMBINED_DATA, currentHotel.hotelcode).catch(err => {
      console.error(APIS.ICS_COMBINED_DATA, err);
    });
  };

  const resetTabs = () => {
    if (isTabsDataLoaded && !isTabsDataLoadingFailed && legacyParityOne) {
      const {
        data: {
          hotelDetail = {},
          clearTaxDetail = {},
          campaignEntityAgreement = {},
        },
      } = legacyParityOne;
      const finalCurrentHotel = combineHotelDetail(
        crtHotel,
        hotelDetail,
        isStaff,
      );
      //Resetting from hotelcode search
      const allTabs = decideTabs({
        currHotel: finalCurrentHotel,
        clearTaxInfo: clearTaxDetail,
        userRights: user.profile?.rights,
        campaignEntity: campaignEntityAgreement,
        externalCalSyncData: calSyncData,
        isResellerView,
        commonStr: COMMON,
        showNewContentScore: newContentScoreFlag,
      });

      if (isHotelCodeSearch.current) {
        setTabsInfo(getHotelCodeSearchTabs(allTabs));
        if (!noRedirectRoutes.test(pathname)) {
          navigateTo(routes.property.propertyDetails);
        }
      } else {
        localStorage.setItem('last_hotelcode', crtHotel.hotelcode);
        setTabsInfo(allTabs);
      }
      setExternalCalSyncData(calSyncData);
      setCampaignEntity(campaignEntityAgreement);
      setCurrentHotel(finalCurrentHotel);
      makeRequest(APIS.COMPSET_DATA, finalCurrentHotel.hotelcode).catch(err => {
        console.error(APIS.COMPSET_DATA, err);
      });
    }
  };

  useEffect(() => {
    // If redirected from reseller onboarding, don't reset tabs in this effect
    if (
      sessionStorage.getItem(
        RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
      ) === RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE
    ) {
      return;
    }

    resetTabs();
  }, [isTabsDataLoaded, crtHotel, isResellerView]);

  // This effect is used to reset the tabs when redirected from reseller onboarding
  useResellerOnboardingEffect({
    tabsInfo,
    resetTabs,
    isFetchingHotelMetadata,
  });

  useRedirectToResellerView({
    userProfileType,
    legalEntityType: currentHotel?.legalEntityType,
    updateUserProfileView,
  });

  useEffect(() => {
    if (isLegacyParityFailed) {
      fallbackUpdate();
      // eslint-disable-next-line
    } //below case is when all the basic APIs are loaded
    else if (leagcyParityEnsured && legacyParityOne && legacyParityTwo) {
      const finalCurrentHotel = combineHotelDetail(
        crtHotel,
        legacyParityOne?.data?.hotelDetail || {},
        isStaff,
      );
      setIsFirstCall(false);
      isHotelCodeSearch.current = false;

      //where to set this data, with tabs data(after tabs data loaded) or after all the basic apis are loaded
      sessionStorage.removeItem('gstnClosedOnce');

      setRoomRatePlan(legacyParityTwo?.data?.roomRatePlans || null);
      setQSState(legacyParityTwo?.data?.qssState || {});

      //Reset the flag here, so that once main calls are done and UI
      //loaded users are able to change hotel immediately if required
      topLevelCallInProgress.current = false;

      //Trigger async calls
      setExtraCallsSettled(false);

      if (!!finalCurrentHotel && !finalCurrentHotel.blockedForExtranet) {
        decideTnCBanner(crtHotel.hotelcode);
        independentAPICalls(
          crtHotel.hotelcode,
          crtHotel.country,
          crtHotel.mmt_id,
        )
          .then((res: IndependentAPICallsResponse) => {
            const [
              guestChatCountData,
              instayIssueCountData,
              propertyGstStatusRes,
              tanPopupDataRes,
              reviewHeaderCount,
              instagramMetaData,
              pendingPrebuyCount,
            ] = res;
            setGuestChatUnReadCount(guestChatCountData);
            setInstayIssuesCount(instayIssueCountData);
            setPropertyGstStatus(propertyGstStatusRes);
            setTanPopupData(tanPopupDataRes);
            setReviewsCount(reviewHeaderCount?.results ?? 0);
            setPlatforms(instagramMetaData);
            setPendingPrebuyOrdersCount(pendingPrebuyCount);
          })
          .catch(() => {
            fallbackUpdate();
          })
          .finally(() => {
            //This will now take care of failure case
            topLevelCallInProgress.current = false;
            setExtraCallsSettled(true);
          });
      } else {
        //This will now take care of failure case
        topLevelCallInProgress.current = false;
        setExtraCallsSettled(true);
      }
    }
  }, [leagcyParityEnsured, crtHotel, isLegacyParityFailed]);

  //scroll and focus on the navbody whenever the hotel, page or content of the navbody changes(includes first page load )
  useEffect(() => {
    startScrollAndFocus();
  }, [pathname, navBodyRef.current, currentHotel]);

  //dashboard does not need data apart from current hotel, so loading the routes once that data is available
  useEffect(() => {
    if (
      pathname === routes.dashboard.base &&
      !!currentHotel &&
      isTabsDataLoaded
    ) {
      setIsFirstCall(false);
    }
  }, [pathname, currentHotel, topLevelCallInProgress.current]);

  // to reset the userProfileView when hotel is changed, and handling case if the new hotel is not on reseller mode
  useEffect(() => {
    if (
      userProfileType === UserProfileType.BOTH &&
      currentHotel &&
      hotelLegalEntityTypeMap[currentHotel.legalEntityType] !==
        HotelLegalEntityType.HOTEL_TRAVEL
    ) {
      updateUserProfileView(UserProfileViewType.INGO);
    }

    if (!isResellerView && currentHotel?.mmt_id) {
      updateSaleCampaigns();
    }

    if (currentHotel?.isHomeStay) {
      updateInternalCalSyncCombinedData();
    }

    if (currentHotel && lastHotel.current !== currentHotel) {
      if (lastHotel.current) {
        const params = queryString.parse(search);
        const paramsToClear = Object.keys(params);
        if (pathname.indexOf('/inventory') !== -1 && paramsToClear?.length) {
          clearQueryParams(pathname, search, paramsToClear);
        }
      }
      lastHotel.current = currentHotel;
    }
  }, [currentHotel]);

  useEffect(() => {
    if (
      userProfileType === UserProfileType.HOTEL_TRAVEL ||
      userProfileView === UserProfileViewType.HOTEL_TRAVEL
    ) {
      window.adobeHotelGA = { ...window.adobeHotelGA, isResellerView: true };
    } else {
      window.adobeHotelGA = { ...window.adobeHotelGA, isResellerView: false };
    }
  }, [userProfileView]);

  useEffect(() => {
    const lastHotelCode = localStorage.getItem('last_hotelcode');
    if (isStaff && !isNullOrUndefined(lastHotelCode)) {
      handleHotelCodeSearch(lastHotelCode);
    }
  }, []);

  if (isFirstCall) {
    //Don't render anything until you have data for the first hotel.
    let hotelObj = null;
    const lastHotelCode = localStorage.getItem('last_hotelcode');
    if (isStaff && !isNullOrUndefined(lastHotelCode)) {
      return <GlobalShimmer />;
    } else if (hotelList.length) {
      hotelObj = hotelList.find(item => item.hotelcode === lastHotelCode);
      if (!hotelObj) {
        hotelObj = hotelList[0];
      }
    }
    updateCurrentHotel(hotelObj);
    return <GlobalShimmer />;
  }

  const counts = {};
  counts[routes.guestchat.base] = guestChatUnReadCount?.unreadCount;
  counts[routes.guestchat.unread] = guestChatUnReadCount?.unreadCount;
  counts[routes.guestchat.unreplied] = guestChatUnReadCount?.unrepliedCount;
  counts[routes.instay.base] = instayIssuesCount;
  counts[routes.reviews.ratings] = reviewsCount?.replynow_count;
  counts[routes.reviews.qna] = reviewsCount?.['not-answered_count'];
  counts[routes.prebuyLedger.base] = pendingPrebuyOrdersCount;

  const shouldBlockUser =
    !isStaff && !chainData.isChainUser && currentHotel?.is_user_hard_blocked;

  const isResellerEligible = Boolean(
    hotelLegalEntityTypeMap[currentHotel?.legalEntityType] ===
      HotelLegalEntityType.INGO && resellerAgreement,
  );

  const tabLevelCheck = tab => {
    return (shouldBlockUser && !tab.verificationBlocker) || !shouldBlockUser;
  };

  const flatTabsArray = tabsInfo.reduce((acc, current) => {
    if (current.children) {
      const filteredChildren = current.children.filter(tabLevelCheck);
      if (filteredChildren.length === 0) {
        return acc;
      }
      if (current.commonParentRoute) {
        const parentNode = createCommonParentRoute(current, filteredChildren);
        acc.push(parentNode);
      } else {
        acc.push(...filteredChildren);
      }
    } else if (tabLevelCheck(current)) {
      acc.push(current);
    }
    return acc;
  }, []);

  const headerLeftComponent = (
    <HotelHeader
      counts={counts}
      hotelList={hotelList}
      currentHotel={currentHotel}
      setCurrentHotel={updateCurrentHotel}
      handleHotelCodeSearch={handleHotelCodeSearch}
      STRINGS={STRINGS.HOTEL_HEADER}
    />
  );

  const showHelpCenter = () => {
    pushToGTM(
      GTM_CATEGORIES.WEB_LEFTDRAWER,
      'help_faq_helpdesk',
      `0_${EVENT_LABEL_OPTIONS.PAGE_OPEN}`,
    );
    pushToGTM(GTM_CATEGORIES.HELP_CENTER, 'sidebar', 'load');
    setShowHelpdeskDrawer(true);
  };

  const helpDeskHandler = () => {
    pushToGTM(
      GTM_CATEGORIES.WEB_LEFTDRAWER,
      'help_faq_support',
      `0_${EVENT_LABEL_OPTIONS.PAGE_OPEN}`,
    );
    window.open(window.helpCenterUrl, '_blank');
  };

  const helpCenter = (
    <div className="help-center sticky-bottom">
      <div>
        {currentHotel?.is_call_center_enabled && (
          <div
            className={`nav-entry ${showHelpdeskDrawer ? 'active' : ''}`}
            onClick={() => showHelpCenter()}
          >
            <div className="flex-centralized">
              <RaiseATicketIcon className="icon" />
              <span className="marginL16">{STRINGS.RAISE_A_TICKET}</span>
            </div>
          </div>
        )}
        <div className="nav-entry" onClick={() => helpDeskHandler()}>
          <div className="flex justify-start items-center gap-2">
            <img alt="help-centre-icon" src={HelpCentreIcon} />
            <span>{STRINGS.HELP_CENTER}</span>
          </div>
        </div>
      </div>
    </div>
  );

  if (isFirstCall || topLevelCallInProgress.current) {
    return <GlobalShimmer />;
  }

  return (
    <HotelContext.Provider
      value={{
        currentHotel,
        updateHotelDetail,
        roomRatePlan,
        clearTaxInfo: legacyParityOne?.data?.clearTaxDetail || {},
        demandData: legacyParityTwo?.data?.demandData || {},
        tabsInfo,
        extraCallsSettled,
        refreshRoomRateplans,
        refreshCalSync,
        showMOPayment: legacyParityTwo?.data?.hotelMOFlag || false,
        qualityScoreState,
        updateTaskCount,
        setCurrentHotel,
        counts,
        setShowHelpdeskDrawer,
        propertyGstStatus,
        decideTnCBanner,
        hostWebMigrationComplete,
        campaignEntity,
        setCampaignEntity,
        externalCalSyncData,
        mainBodyContainer: navBodyRef,
        isLegacyParityEnsured: leagcyParityEnsured || false,
        resellerAgreement,
        compsetData: {
          error: error.compsetData,
          apiStatus: apiStatus.compsetData,
          data: response.compsetData,
        },
        refreshCompset,
        isResellerView,
        platforms,
        setUserProfileView: updateUserProfileView,
        showNewContentScore: newContentScoreFlag,
        updateActivityTrack,
        registerationDetails,
        setRegisterationDetails,
        saleCampaigns: state.response[APIS.SALE_CAMPAIGNS],
        updateSaleCampaigns,
        handleHotelCodeSearch,
        isSaleCampaignsLoading: state.apiStatus[APIS.SALE_CAMPAIGNS].fetching,
        internalCalSyncCombinedData: state.response[APIS.ICS_COMBINED_DATA],
        pendingPrebuyOrdersCount,
        setPendingPrebuyOrdersCount,
      }}
    >
      <div className="w-full h-full overflow-hidden">
        <NavigationContainer
          headerLeftComponent={headerLeftComponent}
          leftNav={
            <LeftNav
              isResellerView={isResellerView}
              tabs={tabsInfo}
              counts={counts}
              isUserBlocked={currentHotel.is_user_hard_blocked}
              disabledControls={currentHotel.blockedForExtranet}
              additionalChildren={helpCenter}
              isNewLinkageEnabled={currentHotel?.is_new_linkage_enabled}
              showSwitchToGoMMTOption={
                userProfileView === UserProfileViewType.HOTEL_TRAVEL
              }
              setUserProfileView={updateUserProfileView}
            />
          }
          showTopLevelUserProfileSwitcher={
            hotelLegalEntityTypeMap[currentHotel.legalEntityType] ===
            HotelLegalEntityType.HOTEL_TRAVEL
          }
          userProfileView={userProfileView}
          setUserProfileView={updateUserProfileView}
          hotelCode={currentHotel.hotelcode}
          isChatEnabled={currentHotel?.is_chat_enabled}
          pendingPrebuyOrdersCount={pendingPrebuyOrdersCount}
        >
          <div className="nav-container">
            <div
              className="nav-body no-focus-border"
              tabIndex={1}
              ref={navBodyRef}
            >
              <ErrorBoundary handleModule hotelCode={currentHotel?.hotelcode}>
                {currentHotel?.blockedForExtranet ? (
                  <BlockedForExtranet currentHotel={currentHotel} />
                ) : (
                  <>
                    <QualityScoreBanner />
                    <ContentScoreBanner />
                    {!isFirstCall && isNullOrUndefined(currentHotel) ? (
                      <p
                        className="full-size-centered"
                        style={{
                          fontSize: '32px',
                          color: 'grey',
                        }}
                      >
                        No valid hotel present to show!
                      </p>
                    ) : (
                      <Suspense fallback={<ContainerShimmer />}>
                        <Switch>
                          {!currentHotel.showListings &&
                            currentHotel.is_sustainability_enabled &&
                            !isResellerView && (
                              <Route
                                path={routes.myImpactHotel.base}
                                component={MyImpactMain}
                              />
                          )}
                          {!currentHotel.showListings &&
                            currentHotel.is_sustainability_enabled &&
                            !isResellerView && (
                              <Redirect
                                from={routes.myImpactHomeStay.base}
                                to={routes.myImpactHotel.base}
                              />
                          )}

                          {currentHotel.showListings &&
                            currentHotel.is_sustainability_enabled &&
                            !isResellerView && (
                              <Redirect
                                from={routes.myImpactHotel.base}
                                to={routes.myImpactHomeStay.base}
                              />
                          )}
                          {flatTabsArray.map(item => (
                            <Route
                              key={item.route}
                              path={item.route}
                              component={item.component}
                            />
                          ))}
                          {!shouldBlockUser &&
                            qualityScoreState.is_qs_active && (
                              <Route
                                path={routes.qualityScore.base}
                                component={QualityScoreMain}
                              />
                          )}

                          {!shouldBlockUser && (
                            <Route
                              path={routes.demandHeatMap.base}
                              component={DemandHeatMap}
                            />
                          )}
                          {!isStaff && isResellerEligible && (
                            <Route
                              path={routes.reseller.base}
                              component={ResellerMain}
                            />
                          )}
                          <Route
                            path={`${routes.inventory.base}/*`}
                            component={InventoryMain}
                          />

                          <Route
                            path={`${routes.bookings.base}/*`}
                            component={BookingsRevampMain}
                          />
                          <Route
                            path={routes.compset.base}
                            component={CompsetMain}
                          />
                          <Route
                            path={routes.packages.base}
                            component={PackagesMain}
                          />
                          {!currentHotel.showListings && (
                            <Route
                              path={routes.packages.losPackage}
                              component={PackageLongStay}
                            />
                          )}

                          <Route
                            path={`${routes.sale_handling.base}`}
                            component={SaleHandlingMain}
                          />
                          {currentHotel.showListings && (
                            <Redirect
                              from={`${routes.property.base}/*`}
                              to={routes.listing.base}
                            />
                          )}
                          {!currentHotel.showListings && (
                            <Redirect
                              from={`${routes.listing.base}/*`}
                              to={routes.property.base}
                            />
                          )}
                          {!currentHotel.showListings && (
                            <Redirect
                              from={`${routes.listing.base}`}
                              to={routes.property.base}
                            />
                          )}
                          {shouldBlockUser && (
                            <Route component={VerificationBlockerMain} />
                          )}
                          {!currentHotel.is_gostay && (
                            <Redirect
                              from={`${routes.businessBooster.campaign.goStay}`}
                              to={routes.dashboard.base}
                            />
                          )}
                          {!currentHotel?.is_advantage && (
                            <Redirect
                              from={`${routes.businessBooster.campaign.selectProgramme}`}
                              to={routes.dashboard.base}
                            />
                          )}

                          <Route
                            component={() => (
                              <NotFound userProfileView={userProfileView} />
                            )}
                          />
                        </Switch>
                      </Suspense>
                    )}
                  </>
                )}
              </ErrorBoundary>
            </div>
            {showHelpdeskDrawer && (
              <HelpCentre
                currentHotel={currentHotel}
                showHelpdeskDrawer={showHelpdeskDrawer}
                setShowHelpdeskDrawer={setShowHelpdeskDrawer}
              />
            )}
            {termsAndConditionData?.showpopup && (
              <TermsAndConditionsModal
                data={termsAndConditionData}
                isDomHotel={currentHotel.isDomHotel}
                hotelCode={currentHotel.hotelcode}
                onClose={() => setTermsAndConditionData(null)}
              />
            )}

            {tanPopupData?.showpopup && (
              <HotelTanModal
                hotelCode={currentHotel.hotelcode}
                onClose={() => setTanPopupData(null)}
              />
            )}
          </div>
        </NavigationContainer>
      </div>
    </HotelContext.Provider>
  );
}

export default React.memo(HotelMain);
