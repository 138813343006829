import { doMutation, doQuery } from 'base/GraphQLClient';
import {
  acceptResellerAgreementQuery,
  acceptResellerAgreementVariables,
  activateLegalEntityQuery,
  activateLegalEntityVariables,
  checkExistingEmailOrMobileQuery,
  checkExistingEmailorMobileVariables,
  createResellerOnlyUserQuery,
  createResellerOnlyUserVariables,
  getExistingAccountsQuery,
  getExistingAccountsVariables,
  markAdminUsersAsResellerQuery,
  markAdminUsersAsResellerVariables,
} from './ResellerGraphQueries';
import { getValue, showMessage } from 'utilities/Utils';
import { User } from 'interfaces/AppContextInterface';

export const checkIfEmailOrMobileExists = data => {
  return new Promise((resolve, reject) => {
    return doMutation(
      checkExistingEmailOrMobileQuery,
      checkExistingEmailorMobileVariables(data),
    )
      .then(res => {
        const success = getValue(
          res,
          'data.rawEndpoint.existingIngoEmailMobileCheck.modelType1.success',
        );

        const message = getValue(
          res,
          'data.rawEndpoint.existingIngoEmailMobileCheck.modelType1.message',
        );
        const responseCode = getValue(
          res,
          'data.rawEndpoint.existingIngoEmailMobileCheck.modelType1.response_code',
        );
        if (!success) reject(message);

        resolve({ responseCode, message });
      })
      .catch(reject);
  });
};

export const createResellerOnlyUser = (hotelCode, payload) => {
  return new Promise((resolve, reject) => {
    doQuery(
      createResellerOnlyUserQuery,
      createResellerOnlyUserVariables(hotelCode, payload),
    )
      .then(res => {
        const { message, success } = getValue(
          res,
          'data.rawEndpoint.umCreateUser.modelType1',
        );

        if (!success) {
          showMessage({ show: true, message, type: 'error' });
          reject(new Error(message));
        }
        resolve(res);
      })
      .catch(reject);
  });
};

export const getExistingAccounts = hotelCode => {
  return new Promise((resolve, reject) => {
    doQuery(getExistingAccountsQuery, getExistingAccountsVariables(hotelCode))
      .then(res => {
        const data =
          getValue(
            res,
            'data.rawEndpoint.adminContactDetails.modelType1.data',
          ) || [];
        resolve(data);
      })
      .catch(reject);
  });
};

export const markAdminUsersAsReseller = hotelCode => {
  return new Promise((resolve, reject) => {
    doMutation(
      markAdminUsersAsResellerQuery,
      markAdminUsersAsResellerVariables(hotelCode),
    )
      .then(res => {
        const success = getValue(
          res,
          'data.rawEndpoint.updateReseller.modelType1.success',
        );
        const message = getValue(
          res,
          'data.rawEndpoint.updateReseller.modelType1.message',
        );
        if (!success) {
          showMessage({ show: true, message, type: 'error' });
          reject(new Error(message));
        }
        resolve(success);
      })
      .catch(reject);
  });
};

export const activateLegalEntity = hotelCode => {
  return new Promise((resolve, reject) => {
    doMutation(
      activateLegalEntityQuery,
      activateLegalEntityVariables(hotelCode),
    )
      .then(res => {
        const success = getValue(
          res,
          'data.rawEndpoint.activateLegalEntity.modelType1.success',
        );

        const message = getValue(
          res,
          'data.rawEndpoint.activateLegalEntity.modelType1.message',
        );
        if (!success) {
          showMessage({ show: true, message, type: 'error' });
          reject(new Error(message));
        }
        resolve(success);
      })
      .catch(reject);
  });
};

export const acceptResellerAgreement = (hotelCode: string, user: User) => {
  return new Promise((resolve, reject) => {
    doMutation(
      acceptResellerAgreementQuery,
      acceptResellerAgreementVariables(hotelCode, user),
    )
      .then(res => resolve(res.data))
      .catch(reject);
  });
};
