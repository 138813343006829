import { Typography } from 'components/latest-core';
import { linkageMaskGroup, linkageStar } from 'assets/modules/inventory';

export type DetailsCardProps = {
  title?: string;
  subTitle?: string;
  content?: string;
  variant?: 'default' | 'without-star';
  children?: React.ReactNode;
  'data-test-id'?: string;
  isFontSizeChange?: boolean;
};
export default function DetailsCard(props: DetailsCardProps) {
  const {
    title = '',
    subTitle = '',
    content = '',
    variant = 'default',
    children,
    'data-test-id': dataTestId,
    isFontSizeChange = false,
  } = props;
  return (
    <div className="mb-4" data-test-id={dataTestId}>
      <div
        className={`${
          variant === 'default' ? 'min-h-[75px]' : 'min-h-[50px]'
        } px-3 py-3 bg-[#0F8EDB] justify-between flex rounded-t-lg items-center`}
      >
        <Typography
          variant={isFontSizeChange ? 'h2' : 'h3'}
          themeColor="common.white"
          className="flex items-center"
          fb
        >
          {title}
        </Typography>
        <div className="relative">
          {variant === 'default' && (
            <img src={linkageStar} alt="rate plan linkage" />
          )}
          <img
            src={linkageMaskGroup}
            alt="rate plan linkage"
            className="absolute right-[-90px] top-[-60px] h-[200px] pointer-events-none "
          />
        </div>
      </div>
      <div
        className={`${
          isFontSizeChange ? 'py-4 px-3' : 'p-3'
        } border border-t-0 border-solid border-divider bg-color-white justify-between relative z-10`}
      >
        <Typography variant="body1" fb>
          {subTitle}
        </Typography>
        <Typography variant="body1" className="my-1">
          <p dangerouslySetInnerHTML={{ __html: content }} className="mb-0" />
        </Typography>
        {children}
      </div>
    </div>
  );
}
