import axios, { apiWrapper } from 'base/APIClient';
import { USERMANAGEMENT } from 'base/Url';

//User Management
export const generateUmOTPWrapper = (
  contactAddress,
  contactType,
  isMultiAdminCreateFlow = false,
  countryCode = '+91',
  updateUser = true,
) => {
  const { UM_WRAPPER_FOR_OTP } = USERMANAGEMENT;
  const data = {
    contact_type: contactType,
    contact_address: contactAddress,
    multi_admin_add_flow: isMultiAdminCreateFlow,
    country_code: countryCode,
    update_user: updateUser,
  };
  return apiWrapper(axios.post(UM_WRAPPER_FOR_OTP, data, { useLoader: true }));
};

export const generateImOTPWrapper = (country_id, contact) => {
  const { IM_SEND_OTP } = USERMANAGEMENT;
  const data = {
    country_id: country_id,
    contact: contact,
    contact_type: 'whatsapp',
  };
  return apiWrapper(axios.post(IM_SEND_OTP, data, { useLoader: true }));
};

export const validateUmOTPWrapper = (otp, data) => {
  const { UM_WRAPPER_FOR_OTP } = USERMANAGEMENT;
  const url = `${UM_WRAPPER_FOR_OTP}${otp}/`;
  if (data) return apiWrapper(axios.put(url, data, { useLoader: true }));
  return apiWrapper(axios.put(url, { useLoader: true }));
};

export const validateImOTPWrapper = (otp, otpData = {}) => {
  const { IM_VERIFY_OTP } = USERMANAGEMENT;
  const data = {
    otp,
    ...otpData,
  };
  return apiWrapper(axios.post(IM_VERIFY_OTP, data, { useLoader: true }));
};
