import { Skeleton } from '@mui/material';
import React from 'react';

export function LeftSectionShimmer() {
  return (
    <div className="bg-color-white w-[220px] p-2 mt-1 md:hidden sm:hidden lg:block">
      {[...new Array(10).keys()].map((_, index) => (
        <div className="flex items-center my-4 py-2" key={index}>
          <Skeleton
            height={28}
            width={28}
            variant="rectangular"
            className="rounded"
          />
          <Skeleton
            height={16}
            width={150}
            variant="rectangular"
            className="rounded ml-2"
          />
          <Skeleton
            height={10}
            width={10}
            variant="rectangular"
            className="rounded ml-2"
          />
        </div>
      ))}
    </div>
  );
}
