import { Button } from 'components/new-core';
import { useContext, useState } from 'react';
import { DealsContext, HotelContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import { showMessage } from 'utilities/Utils';
import {
  newPromotionTypes,
  offerTypes,
  optionsTypeMap,
} from '../../Promotions.constants';
import { updateLoggedInConfigValue } from '../../PromotionsAPIClient';
import { PromotionWindow } from '../index';

const PromotionWindowWrapper = props => {
  const {
    promotionType,
    defaultDiscount,
    currency,
    isEditFlowOnly,
    configureOfferState: cFS,
    suggestionsTypeMap,
    isBundled,
  } = props;
  const { currentHotel } = useContext(HotelContext);
  const { hotelcode: hotelCode } = currentHotel;
  const { actionTypes, dispatch } = useContext(DealsContext);
  const [STRINGS] = useLangStrings('Promotions');
  const NSTRINGS = STRINGS.CREATE_SECTIONS.CONFIGURE_OFFER;

  const [defaultValFrWindow, setDefaultValFrWindow] = useState(null);
  const isOfferTypePercent = cFS.offerType === offerTypes.percentage;

  const updateLoggedInVal = (val, windowNum) => {
    updateLoggedInConfigValue(hotelCode, {
      loggedin_promo_amount: val,
    })
      .then(() => {
        showMessage({
          type: 'success',
          show: true,
          message: STRINGS.MSGS.DEFAULT_VALUE_SUCCESS.replace(
            '__percent__',
            val,
          ),
        });
        dispatch({
          type: actionTypes.UPDATE,
          data: {
            defaultDiscount: val,
          },
        });
      })
      .catch(error => {
        console.error('updateLoggedInConfigValue', error);
      });
    setDefaultValFrWindow(windowNum);
  };

  const handleAddNewWindow = (suggestionObj = null) => {
    cFS.addNewWindow(promotionType, defaultDiscount, suggestionObj);
  };

  const handleRemoveWindow = index => {
    const updatedOfferList = [...cFS.offerList];
    if (updatedOfferList.length === 1) {
      showMessage({
        type: 'error',
        show: true,
        message: STRINGS.MSGS.DELETE_WINDOW_WARNING,
      });
      return;
    }
    updatedOfferList.splice(index, 1);
    cFS.setOfferList(updatedOfferList);

    // Update Checkbox in Promotion Window.
    const windowNum = index + 1;
    if (defaultValFrWindow) {
      if (defaultValFrWindow === windowNum) {
        setDefaultValFrWindow(null);
      } else if (defaultValFrWindow > windowNum) {
        setDefaultValFrWindow(pre => pre - 1);
      }
    }
  };

  const titleObj = {
    [newPromotionTypes.LOS]: NSTRINGS.LOS_BOOK_WITHIN_TITLE,
    [newPromotionTypes.EBD]: NSTRINGS.ADV_BOOK_TITLE,
    [newPromotionTypes.LMD]: NSTRINGS.ADV_BOOK_TITLE,
  };
  const condition = {
    title: titleObj[promotionType],
    options: optionsTypeMap[promotionType],
  };
  const isDayDeal = promotionType === newPromotionTypes.DAY;

  return (
    <>
      {!isEditFlowOnly && !isDayDeal && isOfferTypePercent && (
        <>
          <div className="marginB5 font12 fw600">{NSTRINGS.LABEL2}</div>
          <div className="marginB20 font12">{NSTRINGS.LABEL3}</div>
          <div className="marginTB20">
            {suggestionsTypeMap[promotionType]?.map(item => (
              <Button
                variant={'outlined'}
                onClick={() => handleAddNewWindow(item)}
                disabled={cFS.offerList.length === 3}
                className="marginR20"
              >
                {item.label}
              </Button>
            ))}
          </div>
        </>
      )}
      {cFS?.offerList?.map((offer, i) => {
        return (
          <PromotionWindow
            key={i + 1}
            isOfferTypePercent={isOfferTypePercent}
            defaultValFrWindow={defaultValFrWindow}
            updateLoggedInVal={updateLoggedInVal}
            windowNum={i + 1}
            offer={offer}
            handleRemoveWindow={handleRemoveWindow}
            currency={currency}
            condition={condition}
            isDayDeal={isDayDeal}
            isEditFlowOnly={isEditFlowOnly}
            index={i}
            cFS={cFS}
            isBundled={isBundled}
          />
        );
      })}
      {!isEditFlowOnly && (
        <>
          <Button
            variant="outlined"
            onClick={() => handleAddNewWindow()}
            disabled={cFS.offerList.length === 3 || isEditFlowOnly}
          >
            + {STRINGS.BTNS.ADD_WINDOW}
          </Button>
          <div className="font12 marginTB6">{NSTRINGS.LABEL4}</div>
        </>
      )}
    </>
  );
};

export default PromotionWindowWrapper;
