import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as CommonIcons from 'assets/common';
import {
  classNames,
  getDataPropsFromRest,
  isEmpty,
  isNullOrUndefined,
} from 'utilities/Utils';
import { DropdownProps, OptionLabel } from './Dropdown.types';
import useStyles from './Dropdown.styles';
import { checkIfAllSelected, getFilteredResults } from '../DropdownUtils';
import useOptionRenderer from './Hooks/useOptionRenderer';
import useHandleSelectAll from './Hooks/useHandleSelectAll';
import usePaperComponent from './Hooks/usePaperComponent';
import useHandleClearOptions from './Hooks/useHandleClear';
import CustomPopper from '../Components/CustomPopper';
import useMultiSelectOptionRender from './Hooks/useMultiSelectOptionRenderer';
import DropdownTags from './Components/DropdownTags';
import CheckboxChip from './Components/CheckboxChip';

function DropdownComp(props: DropdownProps, ref: React.Ref<HTMLInputElement>) {
  const {
    value,
    options,
    onChange,
    onBlur,
    placeholder = 'Select',
    limitTags,
    labelField = 'label',
    valueField = '',
    searchable = false,
    disableClear = false,
    disabled = false,
    noOptionsText = 'No options',
    optionVariant = 'text',
    hierarchicalLabelField,
    isMultiple = false,
    groupByField = null,
    size = 'medium',
    name = '',
    autoFocus = false,
    increaseZIndex = true,
    displayRightEl = null,
    maxTags = Infinity,
    maxTagsText = `You can select upto ${maxTags} items`,
    extraDeps = [],
    className = '',
    popupAnchor,
    noBorder = false,
    leftBorderRoundedNone = false,
    style,
    renderOption,
    popperWidth = null,
    menuContainer,
    leftIcon = null,
    showSearchIcon = true,
    error = false,
    isInline = false,
    isInlineBorder = false,
    rigthIconColor = 'text-text-primary',
    defaultValues = [],
    onClick = () => {},
    isButtonVariant = false,
    rightIcon = null,
    filterOptions = null,
    ...rest
  } = props;
  const [tags, setTags] = useState<unknown[]>([]);
  const [tagOptions, setTagOptions] = useState(options);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const autoCompleteRef = useRef<HTMLElement | null>(null);
  const isHierarchicalVariant = optionVariant === 'hierarchical';
  const isIconVariant = optionVariant === 'withIcon';
  const isCheckboxVariant = optionVariant === 'checkbox';
  const isMultiSelectVariant = optionVariant === 'multiSelect';
  const isCustom = optionVariant === 'custom';
  const isTagVariant = optionVariant === 'tags';
  const isChipVariant = optionVariant === 'chips';
  const zIndexValue = increaseZIndex ? '100000002 !important' : 'auto';
  const dataTestIdProps = getDataPropsFromRest(rest);
  const isMultipleTagVariant = isMultiple && optionVariant === 'tags';
  const SELECT_ALL_OPTION = { label: 'Select All', value: 'Select All' };
  const filter = createFilterOptions();
  const allSelected = checkIfAllSelected(tagOptions, tags, valueField);

  const optionLabel: OptionLabel = useCallback(
    item => (typeof item === 'string' ? item : item?.[labelField]),
    [labelField],
  );

  const classes = useStyles({
    isInlineBorder,
    zIndexValue,
    noBorder,
    isInline,
    isCheckboxVariant,
    disabled,
    isMultiSelectVariant,
    isChipVariant,
  });

  const optionRenderer = useOptionRenderer({
    options,
    isHierarchicalVariant,
    isIconVariant,
    isCheckboxVariant,
    isMultiSelectVariant,
    isCustom,
    optionLabel,
    hierarchicalLabelField,
    renderOption,
    labelField,
  });

  const multiSelectOptionRender = useMultiSelectOptionRender({
    valueField,
    labelField,
    allSelected,
    classesCheckbox: classes.checkbox,
    SELECT_ALL_OPTION,
  });

  const PaperComponent = usePaperComponent(classes.paper);

  const handleClearOptions = useHandleClearOptions(
    inputValue,
    tags,
    setTags,
    tagOptions,
    valueField,
  );

  const handleSelectAll = useHandleSelectAll(
    getFilteredResults,
    handleClearOptions,
    inputValue,
    options,
    optionLabel,
    valueField,
    tags,
    setTags,
  );

  const handleToggleSelectAll = onChangeCallbck => {
    if (typeof handleSelectAll === 'function') {
      handleSelectAll(!allSelected, onChangeCallbck);
    }
  };

  const onToggleOption = selectedOptions => setTags(selectedOptions);
  const handleOnChange = (
    newValue: unknown[],
    incomingName: string,
    reason: string = '',
  ) => {
    if (
      (reason === 'selectOption' || reason === 'removeOption') &&
      isMultiSelectVariant
    ) {
      if (
        newValue.find(option => option[valueField] === SELECT_ALL_OPTION.label)
      ) {
        handleToggleSelectAll(onChange);
      } else {
        onChange(
          newValue.filter(
            option => option[valueField] !== SELECT_ALL_OPTION.label,
          ),
          incomingName,
          reason,
        );
        if (typeof onToggleOption === 'function') {
          onToggleOption(newValue);
        }
      }
      setDisplayWarning(false);
      return;
    }
    if (optionVariant === 'tags' && (value as unknown[])?.length >= maxTags) {
      setDisplayWarning(true);
    } else {
      setDisplayWarning(false);
      onChange(newValue, incomingName, reason);
    }
  };

  const filterOptionsInternal = (_, state) => {
    if (!searchable) {
      state.inputValue = '';
    }

    const results = filter(options as [], state) as unknown[];
    return isMultiSelectVariant ? [SELECT_ALL_OPTION, ...results] : results;
  };
  const handleInputChange = ({ target: { value: val } }) => {
    setInputValue(val);
    if (isMultiSelectVariant) {
      const results = val.length
        ? (filter(options as [], {
          inputValue: val,
          getOptionLabel: optionLabel,
        }) as unknown[])
        : options;
      setTagOptions([...results]);
    }
  };

  const disableCloseOnSelect =
    (optionVariant === 'tags' &&
      (value as string | unknown[])?.length < maxTags) ||
    optionVariant === 'checkbox' ||
    optionVariant === 'multiSelect' ||
    (optionVariant === 'chips' && isMultiple);

  const autoCompleteProps: { inputValue?: string } = {};
  if (isMultiSelectVariant) {
    autoCompleteProps.inputValue = inputValue;
  }

  useEffect(() => {
    if (isMultipleTagVariant) {
      setTags(value as unknown[]);
    }
  }, [value]);
  useEffect(() => {
    if (isMultiSelectVariant) {
      setTags(defaultValues);
    }
  }, [defaultValues]);
  useEffect(() => {
    // remove the selected options from the options list
    if (isMultiple && (optionVariant === 'tags' || optionVariant === 'chips'))
      setTagOptions(
        options.filter(option =>
          (value as unknown[])?.every(
            tag => JSON.stringify(tag) !== JSON.stringify(option),
          ),
        ),
      );
  }, [...extraDeps, options]);
  return (
    <>
      {isTagVariant ? (
        <DropdownTags
          tags={tags}
          isMultiSelectVariant={isMultiSelectVariant}
          disabled={disabled}
          chipClasses={classes.chip}
          optionLabel={optionLabel}
          valueField={valueField}
          setTags={setTags}
          onChange={onChange}
          setTagOptions={setTagOptions}
          options={options}
          displayWarning={displayWarning}
          maxTagsText={maxTagsText}
          maxTags={maxTags}
          value={value}
          name={name}
        />
      ) : null}
      <Autocomplete
        onClick={onClick}
        classes={{
          root: classes.autocompleteRoot,
          popper: classes.popper,
          endAdornment: classes.endAdornment,
        }}
        openOnFocus
        ref={autoCompleteRef}
        value={
          isMultiSelectVariant
            ? tags // @ts-ignore
            : value?.value === null || isEmpty(value)
              ? isMultiple
                ? []
                : ''
              : value
        }
        style={style}
        limitTags={limitTags}
        options={
          optionVariant === 'tags' || optionVariant === 'multiSelect'
            ? tagOptions
            : options
        }
        onChange={(_, newValue: unknown[], reason) =>
          handleOnChange(newValue, name, reason)
        }
        onBlur={e => {
          if (isMultiSelectVariant) {
            setTagOptions(getFilteredResults(inputValue, options, optionLabel));
          } else {
            setTagOptions(
              options.filter(option =>
                (tags as unknown[]).every(
                  tag => JSON.stringify(tag) !== JSON.stringify(option),
                ),
              ),
            );
          }
          if (typeof onBlur === 'function') {
            onBlur(e);
          }
        }}
        getOptionLabel={optionLabel}
        popupIcon={
          rightIcon || (
            <CommonIcons.ChevronDownUnfilled
              className={classNames(
                disabled
                  ? ''
                  : isInline || isCheckboxVariant || isInlineBorder
                    ? 'text-text-primary'
                    : 'text-primary-default',
                disabled ? '' : rigthIconColor,
              )}
            />
          )
        }
        clearIcon={<CommonIcons.CloseSmall />}
        disableClearable={isMultiple ? true : disableClear}
        PaperComponent={PaperComponent}
        ListboxComponent={menuContainer}
        PopperComponent={popperProps => (
          <CustomPopper
            {...popperProps}
            popupAnchor={popupAnchor}
            autoCompleteRef={autoCompleteRef}
            popperWidth={popperWidth}
          />
        )}
        disabled={disabled}
        selectOnFocus={false}
        noOptionsText={noOptionsText}
        renderInput={params => {
          const InputProps = {
            ...params.InputProps,
            readOnly: !searchable,
            ...(leftIcon && { startAdornment: leftIcon }),
          };
          if (
            showSearchIcon &&
            ((searchable && !isMultiple) ||
              optionVariant === 'tags' ||
              optionVariant === 'multiSelect')
          ) {
            // Hack to not display chips when optionVariant is tags or multiSelect
            InputProps.startAdornment = (
              <CommonIcons.NewSearch className={classes.searchable} />
            );
          }
          if (!isNullOrUndefined(displayRightEl)) {
            InputProps.endAdornment = (
              <>
                {displayRightEl(value)}
                {InputProps.endAdornment}
              </>
            );
          }

          return (
            <TextField
              {...params}
              autoFocus={autoFocus}
              inputRef={ref}
              InputProps={InputProps}
              placeholder={isChipVariant && isMultiple ? '' : placeholder}
              variant="outlined"
              error={error}
              disabled={!searchable || disabled}
              style={{ caretColor: !searchable ? 'transparent' : 'unset' }}
              onChange={handleInputChange}
              className={classNames(
                classes.root,
                classes.notFaded,
                size === 'small' && classes.small,
                className,
                (noBorder || isInline) && classes.noBorder,
                leftBorderRoundedNone && classes.leftBorderRoundedNone,
                isInline && classes.isInline,
                isButtonVariant && classes.buttonVariant,
              )}
            />
          );
        }}
        groupBy={groupByField}
        renderOption={
          optionVariant !== 'multiSelect'
            ? optionRenderer
            : multiSelectOptionRender
        }
        multiple={isMultiple}
        disableCloseOnSelect={disableCloseOnSelect}
        isOptionEqualToValue={(option, incValue) => {
          return typeof incValue === 'string'
            ? option === incValue
            : valueField
              ? option[valueField] === incValue?.[valueField]
              : option[labelField] === incValue?.[labelField];
        }}
        renderTags={tagValue =>
          tagValue.map(option => (
            <CheckboxChip
              incomingChipValue={tagValue}
              option={option}
              disabled={disabled}
              optionLabel={optionLabel}
              onChange={handleOnChange}
              name={name}
              isMultiple={isMultiple}
            />
          ))
        }
        filterOptions={
          typeof filterOptions === 'function'
            ? filterOptions
            : filterOptionsInternal
        }
        {...autoCompleteProps}
        {...dataTestIdProps}
      />
      {isMultiSelectVariant ? (
        <DropdownTags
          tags={tags}
          isMultiSelectVariant={isMultiSelectVariant}
          disabled={disabled}
          chipClasses={classes.chip}
          optionLabel={optionLabel}
          valueField={valueField}
          setTags={setTags}
          onChange={onChange}
          setTagOptions={setTagOptions}
          options={options}
          displayWarning={displayWarning}
          maxTagsText={maxTagsText}
          maxTags={maxTags}
          value={value}
          name={name}
        />
      ) : null}
    </>
  );
}
const __LegacyDropdown__ = forwardRef(DropdownComp);

export { __LegacyDropdown__ };
