import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '../Typography/Typography';

type AccordionProps = {
  children: React.ReactNode;
  title: string;
  isOpen?: boolean;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  subTitle?: React.ReactNode;
  rightElem?: React.ReactNode;
  noPadding?: boolean;
  renderExpanded?: boolean;
  defaultOpen?: boolean;
  className?: string;
  headerCls?: string;
  contentCls?: string;
  showChip?: React.ReactNode;
  ctaElem?: React.ReactNode;
};

const useStyles = makeStyles(theme => ({
  accRootCls: {
    transition: 'none',
    padding: '0px !important',
    border: '0.5px solid #DEDEDE',
    overflow: 'hidden',
    boxShadow: 'none',
    backgroundColor: '#fff',
    borderRadius: '0px !important',
    '&.Mui-expanded': {
      boxShadow: 'none',
      backgroundColor: '#EFF1FF',
    },
    '&:before': {
      display: 'none',
    },
    '& [class*="MuiSvgIcon-root"]': {
      color: '#3543BF',
      fontSize: '24px',
    },
    '& [class*="MuiAccordionSummary-root"]': {
      padding: '14px 12px',
      minHeight: 'unset',
    },

    '& [class*="MuiAccordionDetails-root"]': {
      display: 'flex',
      padding: '12px',
    },
    '& [class*="MuiAccordionSummary-content"]': {
      margin: '0px',
    },
  },
  headerRootCls: {
    minHeight: 'unset',
    '& > .MuiAccordionSummary-content': {
      alignItems: 'center',
      height: '20px',
    },
  },
  contentRootCls: {
    backgroundColor: theme.palette.backgrounds.white,
  },
}));

function Accordion(props: AccordionProps) {
  const {
    children,
    title,
    isOpen,
    onChange,
    subTitle,
    rightElem,
    //This is required to render only headers when not expanded
    //By default all accordion contents will not be rendered
    renderExpanded = false,
    defaultOpen = false,
    className = '',
    headerCls = '',
    contentCls = '',
    showChip,
    ctaElem,
  } = props;
  const { accRootCls, headerRootCls, contentRootCls } = useStyles();

  return (
    <MuiAccordion
      classes={{ root: `${accRootCls} ${className}` }}
      expanded={isOpen}
      defaultExpanded={defaultOpen}
      onChange={onChange}
      TransitionProps={{ unmountOnExit: !renderExpanded }}
    >
      <AccordionSummary
        classes={{
          root: `${headerRootCls} ${headerCls}`,
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid>
            <Box className="flex flex-1 sr-align-center">
              <Typography variant="body1" themeColor="text.link">
                {title}
              </Typography>
              <Typography className="subtitle marginL8">{subTitle}</Typography>
              <Box className="marginL8">{showChip}</Box>
            </Box>
            <Box>{ctaElem}</Box>
          </Grid>
          <Grid>{rightElem}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails classes={{ root: `${contentRootCls} ${contentCls}` }}>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
}

export default Accordion;
