import React from 'react';
import MuiPopover, { PopoverOrigin } from '@mui/material/Popover';

type PopoverProps = Pick<
React.ComponentProps<typeof MuiPopover>,
'open' | 'anchorEl' | 'onClose' | 'children' | 'style' | 'keepMounted'
> & {
  className?: string;
  disableTransition?: boolean;
  anchorVertical?: PopoverOrigin['vertical'];
  anchorHorizontal?: PopoverOrigin['horizontal'];
  transformVertical?: PopoverOrigin['vertical'];
  transformHorizontal?: PopoverOrigin['horizontal'];
};

function Popover(props: PopoverProps) {
  const {
    open,
    anchorEl,
    onClose,
    children,
    className = '',
    style,
    disableTransition = false,
    anchorVertical = 'bottom',
    anchorHorizontal = 'center',
    transformVertical = 'top',
    transformHorizontal = 'center',
    keepMounted = false,
  } = props;

  return (
    <MuiPopover
      open={open}
      keepMounted={keepMounted}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{ paper: className }}
      style={style}
      anchorOrigin={{
        vertical: anchorVertical,
        horizontal: anchorHorizontal,
      }}
      transformOrigin={{
        vertical: transformVertical,
        horizontal: transformHorizontal,
      }}
      transitionDuration={disableTransition ? 0 : 'auto'}
    >
      {children}
    </MuiPopover>
  );
}
export default Popover;
