import { Skeleton } from '@mui/material';
import React, { Fragment } from 'react';

export const MainSectionShimmer = () => (
  <div className="bg-color-white shadow-dropShadow rounded-lg p-4 border border-solid border-common-dividerLight flex-col flex flex-1 max-w-screen-sm">
    {[...new Array(3)].map((_, index) => (
      <Fragment key={index}>
        <Skeleton
          height={'20px'}
          width={'200px'}
          variant="rectangular"
          className="mt-4 rounded"
        />
        <Skeleton
          height={'36px'}
          variant="rectangular"
          className="mt-2 rounded"
        />
        <Skeleton
          height={'36px'}
          variant="rectangular"
          className="mt-2 rounded"
        />
        <Skeleton
          height={'90px'}
          variant="rectangular"
          className="mt-2 rounded"
        />
      </Fragment>
    ))}
  </div>
);
