import { Snackbar, Box, AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularCountDown from '../ProgressBar/CircularCountDown';
import Typography from '../Typography/Typography';

type Type = 'success' | 'info' | 'warning' | 'error';
const colorMap = {
  warning: '#F2A526',
  info: '#3543BF',
  error: '#C62828',
  success: '#2E7D32',
};

const getIconsMapping = {
  success: {
    icon: (
      <CheckCircleIcon fill="#fff" className="text-common-white text-size-16" />
    ),
  },
  info: {
    icon: <InfoIcon fill="#fff" />,
  },
  warning: {
    icon: (
      <WarningIcon fill="#fff" className="text-text-primary text-size-16" />
    ),
  },
  error: {
    icon: <CancelIcon fill="#fff" />,
  },
};
const useStyles = makeStyles(() => ({
  alertRoot: (props: { type: Type; zIndexValue: number | string }) => ({
    display: 'flex',
    position: 'relative',
    padding: '0px',
    backgroundColor: colorMap[props.type],
    '& [class*="MuiAlert-icon"]': {
      display: 'flex',
      alignItems: 'center',
      padding: '0',
    },
    '& [class*="MuiAlert-message"]': {
      // width: '450px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0',
      flex: '1',
    },
    '& [class*="MuiTypography-gutterBottom"]': {
      marginBottom: '8px',
    },
  }),
  snackBarRoot: (props: { type: Type; zIndexValue: number | string }) => ({
    // width: '550px',
    margin: 'auto',
    zIndex: props.zIndexValue,
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '2px',
    backgroundColor: colorMap[props.type],
    padding: '12px',
    paddingRight: '52px',
    paddingLeft: '20px',
    justifyContent: 'center',
    width: 'auto ',
    left: 'auto',
    right: '35%',
  }),
  isTitle: {
    position: 'relative',
    top: '-10px',
  },
}));

type ToastPropTypes = {
  children?: React.ReactNode;
  open: boolean;
  onClose: (e: React.SyntheticEvent, v: 'timeout' | 'clickaway') => void;
  type: Type;
  autoHide?: boolean;
  noAction?: boolean;
  title?: string;
};

function Toast(props: ToastPropTypes) {
  const {
    children,
    open = true,
    onClose,
    type = 'error',
    autoHide,
    noAction,
    title,
  } = props;

  // eslint-disable-next-line
  const zIndexValue = true ? '999999999 !important' : '1400'; //default snackbar - 1400

  const classes = useStyles({ type, zIndexValue });

  const handleClickAway = event => {
    if (onClose) {
      onClose(event, 'clickaway');
    }
  };

  const snackBarProps = {
    open,
    key: String(children),
    ClickAwayListenerProps: {
      onClickAway: handleClickAway,
    },
  };

  const alertProps = {};
  const isSuccessOrInfo = ['info', 'success', 'warning'].includes(type);

  const autoHideToast = autoHide ?? isSuccessOrInfo;
  const showAction = !(noAction ?? isSuccessOrInfo);

  if (autoHideToast) {
    //@ts-ignore
    snackBarProps.autoHideDuration = 5000;
    //@ts-ignore
    snackBarProps.onClose = onClose;
  }

  if (showAction) {
    //@ts-ignore
    alertProps.onClose = onClose;
  }
  return (
    <Snackbar
      className={`${classes.snackBarRoot} !w-[500px] `}
      {...snackBarProps}
    >
      <div
        className={'flex !justify-center items-center gap-2 !transform-none'}
      >
        <Alert
          className={classes.alertRoot}
          variant="filled"
          severity={type}
          {...alertProps}
          icon={getIconsMapping[type].icon}
        >
          <div>
            {title && (
              <div>
                <AlertTitle>
                  <Typography variant="body2" themeColor="common.white">
                    {title}
                  </Typography>
                </AlertTitle>
              </div>
            )}
            <div className="text-common-white">{children}</div>
          </div>
        </Alert>
        {!showAction && (
          <Box className={`sr-flex1 ${title ? classes.isTitle : ''}`}>
            <CircularCountDown />
          </Box>
        )}
      </div>
    </Snackbar>
  );
}

export { Toast };
