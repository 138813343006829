import React from 'react';
import MuiSlider from '@mui/material/Slider';
import makeStyles from '@mui/styles/makeStyles';
import { Add, Remove } from '@mui/icons-material';
import { IconButton } from 'components/latest-core';
import { isNullOrUndefined } from 'utilities/Utils';

type SliderProps = (
  | { value: number; onChange: (newVal: number) => void; showButtons?: boolean }
  | {
    value: number[];
    onChange: (newVal: number[]) => void;
    showButtons?: never;
  }
) & {
  min?: number;
  max?: number;
  step?: number;
  marksWithLabel?: {
    value: number;
    label?: React.ReactNode;
  }[];
  labelDisplay?: 'auto' | 'on' | 'off';
  showMarks?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& > .MuiSlider-root': {
      margin: '0 4px',
    },
  },
  customFont: {
    fontSize: '12px',
    fontWeight: 500,
  },
}));

function Slider(props: SliderProps) {
  const {
    value,
    onChange,
    min = 0,
    max = 100,
    step = 1,
    marksWithLabel,
    labelDisplay = 'auto',
    showMarks = false,
    showButtons = false,
    disabled = false,
  } = props;

  const { container, customFont } = useStyles();

  const handleChange = (evt, num) => {
    onChange(num);
  };

  const CustomMuiSlider = (
    <MuiSlider
      value={value}
      classes={{ markLabel: customFont, valueLabel: customFont }}
      onChange={handleChange}
      min={min}
      max={max}
      step={step}
      marks={!isNullOrUndefined(marksWithLabel) ? marksWithLabel : showMarks}
      valueLabelDisplay={labelDisplay}
      disabled={disabled}
      sx={{
        '& .MuiSlider-thumb': {
          height: '12px',
          width: '12px',
        },
        '& .MuiSlider-rail': {
          height: '2px',
        },
        '& .MuiSlider-track ': {
          height: '2px',
        },
        '& .MuiSlider-valueLabel': {
          lineHeight: 1.2,
          fontSize: 12,
          background: 'unset',
          padding: 0,
          width: 32,
          height: 32,
          borderRadius: '50% 50% 50% 0',
          backgroundColor: '#3543bf',
          transformOrigin: 'bottom left',
          transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
          '&:before': { display: 'none' },
          '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
          },
          '& > *': {
            transform: 'rotate(45deg)',
          },
        },
      }}
    />
  );

  if (!showButtons) {
    return CustomMuiSlider;
  }

  const increment = () => {
    const newVal = typeof value === 'number' && value + step;
    if (newVal <= max) {
      (onChange as (newVal: number) => void)(newVal);
    }
  };

  const decrement = () => {
    const newVal = typeof value === 'number' && value - step;
    if (newVal >= min) {
      (onChange as (newVal: number) => void)(newVal);
    }
  };

  return (
    <div className={container}>
      <IconButton
        IconComp={Remove}
        onClick={decrement}
        disabled={disabled}
        size="large"
      />
      {CustomMuiSlider}
      <IconButton
        IconComp={Add}
        onClick={increment}
        disabled={disabled}
        size="large"
      />
    </div>
  );
}

export default Slider;
