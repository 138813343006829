import { GST_INVOICE_STATUS_MAP } from '../../constants';
import { showMessage, pushEventToGtm } from 'utilities/Utils';
import { uploadInvoice } from './APIClient';

export const uploadGSTNInvoiceFiles = (
  file: File,
  hotelCode: string,
  bookingId: string, // confirmBookingId in singleroomcase, parentVendorId in multiroomcase
  isMultiRoom: boolean,
  STRINGS: any,
  requestConfig: any = {},
  isL1: boolean = true,
  gtmLabel = '',
) => {
  pushEventToGtm(
    'bookings_upload_invoice',
    gtmLabel || `upload_invoice_${isL1 ? 'l1' : 'l2'}`,
    {
      omnitureData: {
        cta: {
          bookingId,
        },
      },
    },
    'web_bookings_calendar',
  );
  return new Promise((resolve, reject) => {
    // bookingid can be confirmId or parentVendorId
    const formdata = new FormData();
    formdata.append('hotelcode', hotelCode);
    formdata.append('invoice_file', file);
    formdata.append('booking_id', bookingId);
    if (isMultiRoom) {
      formdata.append('is_multiroom_booking', 'True');
    }
    uploadInvoice(formdata, requestConfig)
      .then((data: any) => {
        if (data.success) {
          const { gst_invoice_status, qc_reject_reason, invoice_image } =
            data.data?.cbi_detail ?? {};
          if (gst_invoice_status === GST_INVOICE_STATUS_MAP.REJECTED) {
            resolve({
              invoiceStatus: GST_INVOICE_STATUS_MAP.REJECTED,
              rejectReasons: qc_reject_reason,
              invoiceUrl: invoice_image,
              errorMsg: STRINGS.INVOICE_DETAILS.INVOICE_REJECTED,
            });
          } else if (gst_invoice_status === GST_INVOICE_STATUS_MAP.SCRUTINY) {
            //use GST_INVOICE_STATUS_MAP.scrutiny from constants.ts
            resolve({
              invoiceStatus: GST_INVOICE_STATUS_MAP.SCRUTINY,
              rejectReasons: [],
              invoiceUrl: invoice_image,
              errorMsg: STRINGS.INVOICE_DETAILS.SCRUTINY_TEXT,
            });
          } else if (
            gst_invoice_status === GST_INVOICE_STATUS_MAP.IN_MODERATION
          ) {
            resolve({
              invoiceStatus: GST_INVOICE_STATUS_MAP.IN_MODERATION,
              rejectReasons: [STRINGS.INVOICE_DETAILS.UNDER_VERIFICATION],
              invoiceUrl: invoice_image,
              errorMsg: STRINGS.INVOICE_DETAILS.SCRUTINY_TEXT,
            });
          } else {
            resolve({
              invoiceStatus: 'verified',
              rejectReasons: [],
              invoiceUrl: invoice_image,
              errorMsg: STRINGS.INVOICE_DETAILS.INVOICE_UPLOAD_SUCCESS,
            });
          }
        } else {
          showMessage({
            type: 'error',
            show: true,
            message: STRINGS.INVOICE_DETAILS.UPLOAD_FAILED,
          });
          reject({
            invoiceStatus: '',
            rejectReasons: [],
            invoiceUrl: '',
            errorMsg: STRINGS.INVOICE_DETAILS.UPLOAD_FAILED,
          });
        }
      })
      .catch(() => {
        showMessage({
          type: 'error',
          show: true,
          message: STRINGS.INVOICE_DETAILS.UPLOAD_FAILED,
        });
        reject({
          invoiceStatus: '',
          rejectReasons: [],
          invoiceUrl: '',
          errorMsg: STRINGS.INVOICE_DETAILS.UPLOAD_FAILED,
        });
      });
  });
};
