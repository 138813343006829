import React, { forwardRef, useCallback } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { classNames, getDataPropsFromRest } from 'utilities/Utils';
import { ModalFooter, ModalHeader } from '../components';
import { useModalStyles } from '../Modal.styles';
import type { ModalPropTypes } from '../Modal.types';
import { ModalSize } from '../Modal.constant';

function ModalBase(props: ModalPropTypes, ref?: React.Ref<HTMLDivElement>) {
  const {
    id = '',
    isOpen = false,
    size = ModalSize.MEDIUM,
    header = null,
    children,
    footer = null,
    onClose = () => {},
    onBackDropClick,
    allowBackDropClick = false,
    customClasses = null,
    showScrollbar = false,
    ...rest
  } = props;

  const {
    root: rootContainerClass = '',
    backdrop: backdropClass = '',
    paper: paperClass = '',
    container: containerClass = '',
    header: headerClasses = {},
    children: childrenClass = '',
    footer: footerClasses = {},
  } = customClasses || {};

  const classes = useModalStyles({
    size,
    hideFooterDivider: !!footer?.hideFooterDivider,
    hideHeaderDivider: !!header?.hideHeaderDivider,
    showScrollbar,
  });

  const dataTestIdProps = getDataPropsFromRest(rest);

  const backDropClickHandler = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      reason: string,
    ) => {
      if (!allowBackDropClick && reason === 'backdropClick') {
        return;
      }
      if (onBackDropClick) {
        return onBackDropClick(event);
      }
      return onClose(event);
    },
    [onBackDropClick, onClose, allowBackDropClick],
  );

  return (
    <Dialog
      id={id}
      open={isOpen}
      onClose={backDropClickHandler}
      classes={{
        root: classNames(classes.root, rootContainerClass),
        paper: classNames(classes.paper, paperClass),
      }}
      slotProps={{
        backdrop: {
          className: classNames(classes.backdrop, backdropClass),
        },
      }}
      {...dataTestIdProps}
    >
      <div className={classNames(classes.modalContainer, containerClass)}>
        {header && (
          <ModalHeader
            header={header}
            onClose={onClose}
            headerClasses={headerClasses}
            classes={classes}
          />
        )}
        <DialogContent
          className={classNames(classes.modalChidrenContainer, childrenClass)}
          ref={ref}
        >
          {children}
        </DialogContent>
        {footer && (
          <ModalFooter
            footer={footer}
            footerClasses={footerClasses}
            classes={classes}
          />
        )}
      </div>
    </Dialog>
  );
}

export default forwardRef(ModalBase);
