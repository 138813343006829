import { getDisplayText } from './popups/components/DateSelection';
import {
  getFormattedDate,
  MonthNames,
  getDay,
  addTime,
  getStartOfDay,
  getFutureDate,
} from '../../utilities/DateUtils';
import { SOURCE_PARTNER } from '../../constants';
import { inventoryDaysCount, invDateFormat, GRP, B2C } from './constants';
import { DemandData, RoomRateplan } from 'interfaces/HotelContextInterface';
import {
  ActiveRateplan,
  ActiveRooms,
  PricingModelMap,
  UpdateInventoryDataType,
} from 'interfaces/InventoryContextInterface';
import {
  DataObjType,
  DateObject,
  LevelType,
  ParseModificationsFormDatatype,
  ParseModificationsModificationsType,
  RatePayloadType,
  Rates,
  UpdatedDataArrayType,
  UseDateStateTypeReturnType,
} from './Inventory.type';
import { ISTRINGS } from 'interfaces/Strings';
import {
  IInventory,
  InventoryData,
  OriginalInventory,
  OriginalRates,
} from 'interfaces/InventoryTableContextInterface';
import { isNullOrUndefined, processInternalCalSyncData } from 'utilities/Utils';

//With the assumption that all error elements will have .error class
export function scrollErrorIntoView() {
  const el = document.querySelector('.error');
  if (el) {
    el.parentElement.scrollIntoView({ behavior: 'smooth' });
  }
}

//-------------- Key generator methods ----------------
export function getInvCellKey(
  roomCode: string,
  date: string,
  key: 'available' | 'fixed_available',
) {
  //key=available/fixed_available
  return `inventory.${roomCode}.${date}.${key}`;
}

export function getRateCellKey(
  rpCode: string,
  date: string,
  level: LevelType,
  key: string | number,
) {
  //level=rates/extraRates/inventory/restrictions
  //key: refer http://confluence.mmt.com/display/FS/ARI+Data+Structuring+for+calendar+view
  return `rates.rp_${rpCode}.${date}.${level}_${key}`;
}

export function getOccupancyKey(occupancy: number, ...losList: Array<number>) {
  if (losList.length) {
    return `${losList.reduce((acc, los) => {
      return los ? `${acc}_${los}` : acc;
    }, occupancy)}`;
  }
  return `${occupancy}`;
}

export function getPartnerChildKey(
  childRange: string,
  childOccupancy: number,
  los?: number,
) {
  if (los) {
    return `partner_child_${childRange}_${childOccupancy}_${los}`;
  }
  return `partner_child_${childRange}_${childOccupancy}`;
}

export function getPartnerRangeOccupancyLos(key: string) {
  const [, , range, occupancy, los] = key.split('_');
  return [range, occupancy, los];
}

export function getExtraChildMetaData(
  rateplancode: string,
  date: string,
  range: string,
) {
  return `${rateplancode}_${date}_partner_child_${range}`;
}

//-----------------------------------------------------------------

export function getActiveRoomRateplan(
  allData: RoomRateplan,
  skipAllLinkedRp: boolean = false,
) {
  const { roomDetails, rateplanDetails, parentRateplans } = allData;
  const flatActiveRateplans: ActiveRateplan[] = [];
  const activeRateplans: Record<string, ActiveRateplan[]> = {};
  const parentChildMapping: Record<string, string[]> = {};
  const pricingModelMap: Record<string, PricingModelMap> = {};

  const activeRooms: ActiveRooms[] = (roomDetails ?? []).reduce((acc, room) => {
    if (room.isactive) {
      const isSynXis =
        room.source_config === SOURCE_PARTNER.SYNXIS &&
        !!room.source_roomtypecode;

      const isDerby =
        room.source_config === SOURCE_PARTNER.DERBY &&
        !!room.source_roomtypecode;

      const isPartner = isSynXis || isDerby;
      const isDayUse = room.is_slot_room;
      const data = {
        ...room,
        isSynXis,
        isDerby,
        isPartner,
        isDayUse,
      };
      acc.push(data);
    }
    return acc;
  }, []);

  activeRooms.forEach(room => {
    const rateplans = rateplanDetails[room.roomtypecode];
    if (rateplans) {
      rateplans.forEach(rp => {
        const rpCode = rp.rateplancode;
        const isLinked = rp.parent_id > 0;
        const parentRPCode = rp.parent_rateplancode;
        let displayRP = rp.isactive;
        if (skipAllLinkedRp) {
          displayRP = rp.isactive && !isLinked;
        }
        if (displayRP) {
          rp.isParentRP = !isNullOrUndefined(parentRateplans[rpCode]);
          if (isNullOrUndefined(activeRateplans[room.roomtypecode])) {
            activeRateplans[room.roomtypecode] = [];
          }

          if (isLinked) {
            if (parentChildMapping[parentRPCode] === undefined) {
              parentChildMapping[parentRPCode] = [];
            }
            parentChildMapping[parentRPCode].push(rpCode);
          }

          const isSynXis =
            rp.source_config === SOURCE_PARTNER.SYNXIS &&
            !!rp.source_rateplancode;

          const isDerby =
            rp.source_config === SOURCE_PARTNER.DERBY &&
            !!rp.source_rateplancode;

          const isPartner = isSynXis || isDerby;
          const isDayUse = !!rp.slot_duration;

          const data = {
            roomName: room.roomtypename,
            roomCode: room.roomtypecode,
            roomBaseOccupancy: room.base_adult_occupancy ?? 2,
            roomMaxOccupancy: room.max_adult_occupancy,
            roomChildMaxOccupancy: room.max_child_occupancy,
            maxGuestOccupancy: room.max_guest_occupancy,
            isSynXis,
            isDerby,
            isPartner,
            isDayUse,
            ...rp,
          };
          const isDayRateModel = rp.pricing_model === 3;
          const isOldModel = rp.pricing_model === 0;
          const isSynxisModel = rp.pricing_model === 7;
          const isSynxisLOSModel = rp.pricing_model === 8;
          const isDerbyModel = rp.pricing_model === 9;
          const isDerbyLOSModel = rp.pricing_model === 10;
          const isGroupRp = rp.valid_contract_types?.includes(GRP);
          pricingModelMap[rpCode] = {
            isDayRateModel,
            isOldModel,
            isSynxisModel,
            isSynxisLOSModel,
            isDerbyModel,
            isDerbyLOSModel,
            isGroupRp,
          };
          activeRateplans[room.roomtypecode].push(data);
          flatActiveRateplans.push(data);
        }
      });
    }
  });
  return {
    activeRooms,
    activeRateplans,
    flatActiveRateplans,
    parentChildMapping,
    pricingModelMap,
  };
}

export function addChildRP(
  rpList: string[],
  parentChildMapping: Record<string, string[]>,
) {
  const finalList: string[] = [];
  rpList.forEach(rpCode => {
    finalList.push(rpCode);
    if (!isNullOrUndefined(parentChildMapping[rpCode])) {
      finalList.push(...parentChildMapping[rpCode]);
    }
  });
  return finalList;
}

const demandNormalization = {
  Low: 'Low',
  Normal: 'Normal',
  High: 'High',
  'Very High': 'High',
};

const currYear = new Date().getFullYear();

export function getInventoryDates(
  selectedDate: Date,
  demandData: Record<string, DemandData>,
) {
  const dates: DateObject[] = [];
  for (let i = 0; i < inventoryDaysCount; i++) {
    const currDate = new Date(selectedDate);
    currDate.setDate(currDate.getDate() + i);
    const formattedDate = getFormattedDate(currDate, invDateFormat);
    const year = currDate.getFullYear();
    const dateObj = {
      date: currDate,
      formattedDate,
      dateNum: currDate.getDate(),
      day: getDay(currDate),
      month: MonthNames[currDate.getMonth()].short,
      year: year !== currYear ? year : null,
      demand: demandNormalization[demandData[formattedDate]?.indicator],
    };
    dates.push(dateObj);
  }
  return dates;
}

export function getInvData(
  originalInventory: OriginalInventory,
  roomCode: string,
  formattedDate: string,
  isDerbyLOSModel?: boolean,
  los?: number,
) {
  const invData: InventoryData = originalInventory[roomCode]?.[formattedDate];
  let orig: IInventory = invData?.inventory;
  if (isDerbyLOSModel) {
    const losData = (invData?.los_inventory || []).find(inv => inv.los === los);
    orig = losData?.inventory;
  }
  orig = orig ?? {
    available: 0,
    fixed_available: 0,
  };
  const blocked =
    originalInventory[roomCode]?.[formattedDate]?.restrictions?.block ?? false;

  return { ...orig, blocked };
}

export function getRatesData(
  originalRates: OriginalRates,
  rpCode: string,
  formattedDate: string,
  level: LevelType,
) {
  const orig = originalRates[rpCode]?.[formattedDate]?.[level] ?? {};
  const blocked =
    originalRates[rpCode]?.[formattedDate]?.restrictions?.blocked ?? false;
  return { ...orig, blocked };
}

const inventoryKeys = ['available', 'fixed_available'];

export function inventoryPayload(
  code: string,
  date: string,
  key: string,
  value: boolean,
) {
  const payload: UpdateInventoryDataType = {
    level: 'room',
    code_list: [code],
    date_range_list: [{ from_date: date, to_date: date }],
  };
  if (inventoryKeys.includes(key)) {
    payload.inventory = { [key]: value };
  } else if (key === 'block') {
    payload.restrictions = { block: value };
  }
  return payload;
}

export function ratePayload(
  code: string,
  date: string,
  dataObj: DataObjType,
  contractType: string,
  isDayRateModel: boolean,
) {
  const payload: RatePayloadType = {
    level: 'rate_plan',
    code_list: [code],
    date_range_list: [{ from_date: date, to_date: date }],
    contract_type_list: [contractType],
    rates: {},
    restrictions: {},
  };
  for (const updateKey in dataObj) {
    if (updateKey === 'restrictions_minLos') {
      payload.restrictions.min_los = dataObj[updateKey];
    } else if (updateKey === 'restrictions_block') {
      payload.restrictions.block = dataObj[updateKey];
    } else if (/^extraRates_/.test(updateKey)) {
      const noPrefix = updateKey.replace('extraRates_', '');
      const dataKey = `extra_${noPrefix === 'guest' ? 'adult' : noPrefix}`;
      payload.rates.extra_guest_price = {
        ...payload.rates.extra_guest_price,
        [dataKey]: dataObj[updateKey],
      };
    } else if (/^rates_/.test(updateKey)) {
      if (isDayRateModel) {
        //Note: There is always ever going to be one input on UI
        //So no worries above multiple occ rates coming and overriding
        payload.rates.sell_price_all_occ = dataObj[updateKey];
      } else {
        //Pattern = `rates_occupancy_los`
        const dataKey = updateKey.split('_')[1];
        payload.rates.sell_price = {
          ...payload.rates.sell_price,
          [dataKey]: dataObj[updateKey],
        };
      }
    }
  }
  return payload;
}

export function parseModifications(
  formData: ParseModificationsFormDatatype,
  modifications: ParseModificationsModificationsType,
  contractType: string,
  pricingModelMap: Record<string, PricingModelMap>,
) {
  const inventory: UpdateInventoryDataType[] = [];
  const inventoryModKeys: string[][] = [];
  const rates: RatePayloadType[] = [];
  const roomCodes = new Set<string>();
  const rateModKeys: string[][] = [];
  const rateplanCodes = new Set<string>();
  const dates = new Set<string>();
  const tempRates = {};

  Object.keys(modifications || {}).forEach(modKey => {
    const [type, code, date, key] = modKey.split('.');
    const value = formData?.[type]?.[code]?.[date]?.[key];
    dates.add(date);
    if (type === 'inventory') {
      roomCodes.add(code);
      inventoryModKeys.push([modKey]);
      inventory.push(inventoryPayload(code, date, key, value));
    } else {
      const newCode = code.replace('rp_', '');
      rateplanCodes.add(newCode);
      if (tempRates[`${newCode}__${date}`]) {
        tempRates[`${newCode}__${date}`].dataObj[key] = value;
        tempRates[`${newCode}__${date}`].modKeys.push(modKey);
      } else {
        const newObj = {
          code: newCode,
          date: date,
          dataObj: { [key]: value },
          modKeys: [modKey],
        };
        tempRates[`${newCode}__${date}`] = newObj;
      }
    }
  });

  for (const singleDate in tempRates) {
    rateModKeys.push(tempRates[singleDate].modKeys);
    const rpCode = tempRates[singleDate].code;
    const _contractType =
      pricingModelMap[rpCode]?.isGroupRp && contractType === B2C
        ? GRP
        : contractType;
    rates.push(
      ratePayload(
        rpCode,
        tempRates[singleDate].date,
        tempRates[singleDate].dataObj,
        _contractType,
        pricingModelMap[rpCode]?.isDayRateModel,
      ),
    );
  }
  return {
    inventory,
    rates,
    dates: [...dates],
    roomCodes: [...roomCodes],
    rateplanCodes: [...rateplanCodes],
    inventoryModKeys,
    rateModKeys,
  };
}

export function getMinMaxErrors(
  isOldModel: boolean,
  baseOccupancy: number,
  rates: Rates,
) {
  //Create MinMax array thresholds
  //Begin with null for max
  const minMaxArray = [null];
  for (let occ = baseOccupancy; occ >= 1; occ--) {
    if (isOldModel && occ === baseOccupancy - 1 && occ > 2) {
      occ = 2;
    }
    //adding parseInt(rates[occ] + '', 10) to remove ts error
    if (parseInt(`${rates[occ]}`, 10) > 0) {
      minMaxArray.push(rates[occ]);
    }
  }
  //Add 0 for min
  minMaxArray.push(0);

  //Iterate and post errors if any
  let minPtr = 1;
  const errors = {};
  for (let occ = baseOccupancy; occ > 0; occ--) {
    if (isOldModel && occ === baseOccupancy - 1 && occ > 2) {
      occ = 2;
    }
    const price = rates[occ];
    if (price === '') {
      continue;
    }
    const max = minMaxArray[minPtr - 1];
    let min = minMaxArray[minPtr];
    if (price === min) {
      minPtr++;
      min = minMaxArray[minPtr];
    }
    if (!isNullOrUndefined(max) && price > max) {
      errors[occ] = `This can't be greater than ${max}`;
    }
  }

  return errors;
}

export function flexiParse(diff: number, STRINGS: ISTRINGS['Inventory']) {
  const midnight = getStartOfDay(getFutureDate(new Date(), 1));
  if (diff > 6) {
    diff = 6;
  }
  const final = addTime(midnight, diff);
  const today = getStartOfDay(new Date());
  const dateDiff = getStartOfDay(final).diff(today, 'days');
  let message;
  if (dateDiff === 0) {
    message = `${STRINGS.ON_THIS_DAY}`;
  } else if (dateDiff === -1) {
    message = `${STRINGS.PREVIOUS_DAY}`;
  } else if (dateDiff === 1) {
    message = `${STRINGS.NEXT_DAY}`;
  } else if (dateDiff < -1) {
    message = `${Math.abs(dateDiff)} ${STRINGS.DAYS_EARLIER}`;
  } else {
    message = `${dateDiff} ${STRINGS.DAYS_LATER}`;
  }
  return { time: final.format('hh:mm A'), message };
}

export function calculateNettPrice(
  agreementType: string,
  commission: number,
  sellRate: string,
) {
  let rate = 0;
  if (sellRate) {
    if (agreementType === 'sell_commission') {
      rate = parseFloat(sellRate) - (commission * parseFloat(sellRate)) / 100.0;
    } else if (agreementType === 'nett_markup') {
      rate = (parseFloat(sellRate) * 100.0) / (100.0 + commission);
    }
  }
  return rate.toFixed(2);
}

//The param is the dateState provided by DateSelection.js
export function getDateDisplayText(dateState: UseDateStateTypeReturnType) {
  const {
    calendarProps: {
      selectedDays: { from, to },
    },
    selectedDays,
  } = dateState;
  let dateText = '';
  if (from && to) {
    dateText = `${getFormattedDate(from)} to ${getFormattedDate(to)}`;
  } else {
    dateText = 'No date selected';
  }
  dateText += ` | ${getDisplayText(selectedDays)}`;
  return dateText;
}

// To check if ICS linkage is present or not
export function isLinkagePresent(internalCalSyncData, isActive = false) {
  const parentDetails = processInternalCalSyncData(
    internalCalSyncData?.fetchPropertyListingData?.parentPropertyListing,
  );

  const childDetails = processInternalCalSyncData(
    internalCalSyncData?.fetchPropertyListingData?.childPropertyListing,
  );
  if (isActive) {
    return (
      parentDetails?.[0]?.type === 'active' &&
      childDetails?.[0]?.type === 'active'
    );
  }
  return (
    parentDetails?.[0]?.type === 'active' &&
    (childDetails?.[0]?.type === 'active' ||
      childDetails?.[0]?.type === 'progress')
  );
}

// To check if inventory change is less than inventoryLimit or not
export function checkInventoryLimit(
  updatedDataArray: UpdatedDataArrayType[],
  inventoryLimit = 1,
) {
  return updatedDataArray.every(item =>
    item.inventory ? item.inventory.available <= inventoryLimit : true,
  );
}

export function isInventoryDisabled(internalCalSyncData, currentHotelCode) {
  const childDetails = processInternalCalSyncData(
    internalCalSyncData?.fetchPropertyListingData?.childPropertyListing,
  );
  if (
    childDetails[0]?.type === 'progress' &&
    childDetails[0]?.data[0]?.ingoHotelId === currentHotelCode
  ) {
    return true;
  }
  return false;
}

export function checkIcsLinkage(
  icsAllLinkages,
  childDetailCode,
  currentHotelCode,
) {
  return icsAllLinkages?.data
    ?.filter(
      item =>
        item?.linked_room_hotelcode === childDetailCode ||
        item?.linked_room_hotelcode === currentHotelCode,
    )
    ?.some(item => !!item?.linked_parent_room);
}
