import axios from 'axios';
import { apiWrapper } from 'base/APIClient';
import { FOOTER } from 'base/Url';
import {
  generateUmOTPWrapper,
  validateUmOTPWrapper,
} from 'shared_logic/user-management/APIClient';
import { showMessage } from 'utilities/Utils';

export const sendOTP = (
  contactType,
  contactAddress,
  countryCode = '+91',
  updateUser = false,
) => {
  return generateUmOTPWrapper(
    contactType,
    contactAddress,
    false,
    countryCode,
    updateUser,
  ).then((res: { success: boolean; message: string }) => {
    const { success, message } = res;

    if (success) {
      showMessage({
        show: true,
        message,
        type: 'success',
      });
    } else {
      showMessage({
        show: true,
        message,
        type: 'error',
      });
    }
  });
};

export const verifyOTP = (otp, contactAddress, updateUser = false) => {
  const data = {
    contact_address: contactAddress,
    update_user: updateUser,
  };

  return new Promise((resolve, reject) => {
    validateUmOTPWrapper(otp, data)
      .then((res: { success: boolean; message: string }) => {
        const { success, message } = res;

        if (success) {
          showMessage({
            show: true,
            message: 'OTP Validated successfully',
            type: 'success',
          });
        } else {
          showMessage({
            show: true,
            message,
            type: 'error',
          });
        }
        resolve({ success, message });
      })
      .catch(reject);
  });
};

export const getFooterDetails = (hotelCode, useLoader = false) => {
  const { GET_FOOTER_DETAILS } = FOOTER;
  const url = GET_FOOTER_DETAILS(hotelCode);
  return apiWrapper(axios.get(url, { useLoader }));
};
