import { AttributeFieldProps } from './AttributeFieldProps';
import { useFormContext } from 'react-hook-form';
import { FieldContainer } from './FieldContainer';
import { ControlledToggleButton } from '../../../../components/latest-core';
import React from 'react';

export function BooleanSelect(props: AttributeFieldProps) {
  const { attr, defaultValue: _defaultValue } = props;
  const { control } = useFormContext();
  const options = (attr.attributeList || []).map(value => ({
    value: value.id || 0,
    content: value.label,
    disabled: props.readonly && props.defaultValue !== (value.id || 0),
  }));
  let defaultValue = Number(_defaultValue);
  if (isNaN(defaultValue)) {
    defaultValue = null;
  }
  return (
    <FieldContainer attr={attr} inline>
      <ControlledToggleButton
        control={control}
        btnClass={'disabled'}
        valueAsNumber
        name={`${attr.attributeId}`}
        defaultValue={defaultValue}
        isRequired={attr.required}
        variant={'radio-chip'}
        data={options}
      />
    </FieldContainer>
  );
}
