import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLangStrings } from '../../../utilities/CustomHooks';
import {
  Button,
  Checkbox,
  Tooltip,
  Typography,
} from '../../../components/latest-core';
import { NavHelpDefault } from '../../../assets/common';
import { AppContext, HotelContext } from '../../../utilities/Context';
import { updateActivityTracker } from '../showTooltipConfig';
import Sentry from '../../../utilities/Sentry';

type PackageGuideTooltipProps = {
  title: string;
  body: string;
  content?: ReactNode;
  ctaName?: string;
  onCtaClick: (dontShowAgain: boolean) => void;
  onClose?: (event: React.SyntheticEvent) => void;
};

export function useGuideTooltipConfig(
  key: string,
  options?: { maxShowCount?: number; disabled: boolean },
) {
  const { maxShowCount = 2, disabled = false } = options || {};
  const [showTooltipGuide, setShowTooltipGuide] = useState<boolean>(() => {
    const showCount = parseInt(localStorage.getItem(key), 10) || 0;
    return showCount < maxShowCount;
  });
  useEffect(() => {
    if (disabled) {
      return;
    }
    const showCount = parseInt(localStorage.getItem(key), 10) || 0;
    if (showCount < maxShowCount) {
      localStorage.setItem(key, `${showCount + 1}`);
    }
  }, [disabled]);
  const onDismissClick = useCallback((neverAgain: boolean) => {
    setShowTooltipGuide(false);
    if (neverAgain) {
      localStorage.setItem(key, `${maxShowCount}`);
    }
  }, []);
  return {
    showTooltipGuide: !disabled && showTooltipGuide,
    onDismissClick,
  };
}

export function usePackageGuideConfigForCta(cta: ReactNode) {
  const [pkgStr, commonStr] = useLangStrings<'Packages'>('Packages');
  const { currentHotel, updateActivityTrack } = useContext(HotelContext);
  const eligibleForPackage = currentHotel.isEligibleForPackage;

  const { user } = useContext(AppContext);
  const displayPackagePopup = useMemo(
    () =>
      (currentHotel?.activity_events || []).find(
        item => item.name === commonStr.LABELS.SHOW_PACKAGES_TOOL_TIP,
      ),
    [currentHotel?.activity_events],
  );
  const showPackageTooltipInitialValue =
    eligibleForPackage && Boolean(displayPackagePopup?.is_active);
  const [showPackageTooltip, setShowPackageTooltip] = useState<boolean>(
    showPackageTooltipInitialValue,
  );
  const onPackageTooltipClick = useCallback(() => {
    setShowPackageTooltip(false);
  }, []);
  const updateTrackerCalled = useRef(false);
  useEffect(() => {
    if (!showPackageTooltip || updateTrackerCalled.current) {
      return;
    }
    updateTrackerCalled.current = true;
    updateActivityTracker({
      hotelId: currentHotel.hotelcode,
      userId: `${user.profile.id}`,
    })
      .then(() => {
        const updatedActivityEvents = (currentHotel?.activity_events || []).map(
          item => {
            if (item.name === commonStr.LABELS.SHOW_PACKAGES_TOOL_TIP) {
              return {
                ...item,
                is_active: false,
              };
            }
            return item;
          },
        );
        if (
          Array.isArray(updatedActivityEvents) &&
          updatedActivityEvents.length > 0
        ) {
          // @ts-ignore
          updateActivityTrack(updatedActivityEvents);
        }
      })
      .catch(err => {
        Sentry.logError(err);
      });
  }, [currentHotel, updateActivityTrack, showPackageTooltip]);
  const pkgTooltip = useMemo(
    () => (
      <PackageGuideTooltip
        content={cta}
        onCtaClick={onPackageTooltipClick}
        title={pkgStr.LABELS.BOOST_REVENUE}
        body={pkgStr.LABELS.A_PKG_IS_COMBO_SHORT}
      />
    ),
    [cta, onPackageTooltipClick],
  );
  if (!eligibleForPackage) {
    return null;
  }

  return showPackageTooltip ? pkgTooltip : cta;
}

const defaultContent = (
  <img
    src={NavHelpDefault}
    alt={'help-tooltip'}
    width={20}
    height={20}
    className={''}
  />
);

export function PackageGuideTooltip(props: PackageGuideTooltipProps) {
  const [commonStr] = useLangStrings<'Common'>('Common');
  const {
    onCtaClick,
    ctaName = commonStr.BUTTONS.GOT_IT,
    title,
    body,
    onClose,
    content = defaultContent,
  } = props;
  const [dontShowSelected, setDontShowSelected] = useState<boolean>(false);
  const onCtaClickInternal = useCallback(() => {
    onCtaClick(dontShowSelected);
  }, [onCtaClick, dontShowSelected]);
  return (
    <Tooltip
      open
      onClose={onClose}
      tooltipCls={'!max-w-[400px] !text-common-white rounded-lg'}
      className="!text-common-white"
      content={
        <div className={'flex flex-col gap-2 p-3'}>
          <Typography
            className={'!text-common-white'}
            variant={'subHeadingMedium'}
            fb
          >
            {title}
          </Typography>
          <Typography className={'!text-[#C2C7EC]'}>{body}</Typography>
          <div className={'flex flex-row justify-between pt-2'}>
            <Checkbox
              color={'secondary'}
              size={'medium'}
              checked={dontShowSelected}
              onChange={setDontShowSelected}
              className={'!text-common-white'}
            >
              <Typography className={'!text-common-white'}>
                {commonStr.MESSAGES.DONT_SHOW_AGAIN}
              </Typography>
            </Checkbox>
            <Button
              variant={'contained'}
              className={'bg-color-white !text-primary-dark'}
              color={'inherit'}
              onClick={onCtaClickInternal}
            >
              {ctaName}
            </Button>
          </div>
        </div>
      }
      titleWithContent
      bgBlue
      placement={'top'}
    >
      {/*@ts-ignore*/}
      {content}
    </Tooltip>
  );
}
