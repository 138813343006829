import {
  apiDateFormatV3,
  apiDateFormatV2,
  apiDateFormat,
} from '../../constants';
import { AnalyticsPagesSectionsDataKeys } from './constants/Analytics';

export const [
  VAL_LAST_7D,
  VAL_LAST_15D,
  VAL_LAST_1M,
  VAL_LAST_3M,
  VAL_LAST_6M,
] = ['7-days', '15-days', '30-days-m', '3-months', '6-months'];

export const [
  OPT_LAST_7D,
  OPT_LAST_15D,
  OPT_LAST_1M,
  OPT_LAST_3M,
  OPT_LAST_6M,
] = [
  'Last 7 days',
  'Last 15 days',
  'Last 1 month',
  'Last 3 months',
  'Last 6 months',
];

export const durationOptions = [
  { title: OPT_LAST_7D, value: VAL_LAST_7D, isDisabled: false },
  { title: OPT_LAST_15D, value: VAL_LAST_15D, isDisabled: false },
  { title: OPT_LAST_1M, value: VAL_LAST_1M, isDisabled: false },
  { title: OPT_LAST_3M, value: VAL_LAST_3M, isDisabled: false },
  { title: OPT_LAST_6M, value: VAL_LAST_6M, isDisabled: false },
];

export const [OPT_BRAND_ALL, OPT_BRAND_GI, OPT_BRAND_MMT] = [
  'All Brands',
  'Goibibo',
  'MakeMyTrip',
];

export const [VAL_BRAND_ALL, VAL_BRAND_GI, VAL_BRAND_MMT] = [
  'All',
  'Goibibo',
  'MakeMyTrip',
];

export const brandOptions = [
  { title: OPT_BRAND_ALL, value: VAL_BRAND_ALL },
  { title: OPT_BRAND_GI, value: VAL_BRAND_GI },
  { title: OPT_BRAND_MMT, value: VAL_BRAND_MMT },
];

export const [OPT_BOOKING_DATE, OPT_STAYING_DATE] = [
  'Booking dates',
  'Staying dates',
];

export const [VAL_BOOKING_DATE, VAL_STAYING_DATE] = [
  'bookingDate',
  'staying_date',
];

export const datesFilter = [
  { title: OPT_BOOKING_DATE, value: VAL_BOOKING_DATE },
  { title: OPT_STAYING_DATE, value: VAL_STAYING_DATE },
];

export const competitorComparisonGraphStates = {
  NO_COMPETITOR: 'NO_COMPETITOR',
  USER_GRAPH: 'USER_GRAPH',
  COMPETITOR_GRAPH: 'COMPETITOR_GRAPH',
  VIEW_MORE: 'VIEW_MORE',
  LOADING: 'LOADING',
};

export const [
  TAB_ROOM_NIGHTS,
  TAB_REVENUE,
  TAB_ASP,
  TAB_PROPERTY_VISITS,
  TAB_CONVERSION,
] = ['room-nights', 'revenue', 'asp', 'property-visits', 'conversion'];

export const [dTypeTab, dTypeHGraph, dTypeHShowMoreGraph, dTypeBarGraph] = [
  'tab',
  'horizontal-graph',
  'horizontal-graph-show-more',
  'bar-graph',
];
export const currentTabMapperForOmniture = {
  [TAB_ROOM_NIGHTS]: AnalyticsPagesSectionsDataKeys.ROOM_NIGHTS,
  [TAB_REVENUE]: AnalyticsPagesSectionsDataKeys.REVENUE,
  [TAB_ASP]: 'ASP',
  [TAB_PROPERTY_VISITS]: AnalyticsPagesSectionsDataKeys.PROPERTY_VISITS,
  [TAB_CONVERSION]: AnalyticsPagesSectionsDataKeys.CONVERSION,
};

export const OPT_SECTION_NAME = {
  BC: 'Business Channels',
  RP: 'Room/ Rate Plans',
  DOW: 'Days of the Week',
  MP: 'Meal Plans',
  APW: 'Advance Purchase Window',
  PAX: 'PAX %',
  LOS: 'Length of Stay %',
};

const tabRoomNightAndRevenue = [
  OPT_SECTION_NAME.BC,
  OPT_SECTION_NAME.RP,
  OPT_SECTION_NAME.DOW,
  OPT_SECTION_NAME.MP,
  OPT_SECTION_NAME.APW,
  OPT_SECTION_NAME.PAX,
  OPT_SECTION_NAME.LOS,
].map(name => ({
  name: name,
  timeStamp: 0,
}));
const tabPropertyVisitsAndConversion = [
  OPT_SECTION_NAME.APW,
  OPT_SECTION_NAME.PAX,
  OPT_SECTION_NAME.LOS,
].map(name => ({
  name: name,
  timeStamp: 0,
}));
export const defaultTabsWishListOrder = {
  [TAB_ROOM_NIGHTS]: tabRoomNightAndRevenue,
  [TAB_REVENUE]: tabRoomNightAndRevenue,
  [TAB_ASP]: [],
  [TAB_PROPERTY_VISITS]: tabPropertyVisitsAndConversion,
  [TAB_CONVERSION]: tabPropertyVisitsAndConversion,
};

export const currentAnalyticsSectionForOmniture = {
  [OPT_SECTION_NAME.BC]: 'Business_channels',
  [OPT_SECTION_NAME.RP]: 'Room_rateplans',
  [OPT_SECTION_NAME.DOW]: 'Day_of_the_week',
  [OPT_SECTION_NAME.MP]: 'Mealplans',
  [OPT_SECTION_NAME.APW]: 'AP_window',
  [OPT_SECTION_NAME.PAX]: 'PAX',
  [OPT_SECTION_NAME.LOS]: 'LOS',
};

export const tabsData = {
  TAB_ROOM_NIGHTS: {
    nodeKey: 'analyticsHistogram1',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    type: dTypeTab,
    name: 'Room Nights',
    tooltip:
      'This indicates the total room nights your property was booked according to the duration selected',
    to: TAB_ROOM_NIGHTS,
    nodes: [],
    overviewInfo: '',
    pipeline: 'transactions',
    hidKey: 'hotelId',
    vendorOrBrand: 'vendor',
    kpiValue: 'sum',
    shouldIncludeConfirmStatusValue: true,
  },
  TAB_REVENUE: {
    nodeKey: 'analyticsHistogram2',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    type: dTypeTab,
    name: 'Revenue',
    tooltip:
      'Indicates the net receivable amount by you(property partner), excluding taxes for selected duration',
    to: TAB_REVENUE,
    nodes: [],
    overviewInfo: '',
    pipeline: 'transactions',
    hidKey: 'hotelId',
    vendorOrBrand: 'vendor',
    kpiValue: 'sum',
    shouldIncludeConfirmStatusValue: true,
  },
  TAB_ASP: {
    nodeKey: 'analyticsHistogram3',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    kpiKey: 'avgSellPrice',
    kpiKeySD: 'avgSellPrice',
    type: dTypeTab,
    name: 'ASP',
    tooltip:
      'Indicates average post promotion sell price to the customer of the different rooms/rate plans in your property upon total room nights during that duration',
    to: TAB_ASP,
    nodes: [],
    overviewInfo: '',
    pipeline: 'transactions',
    hidKey: 'hotelId',
    vendorOrBrand: 'vendor',
    kpiValue: 'custom',
    shouldIncludeConfirmStatusValue: true,
  },
  TAB_PROPERTY_VISITS: {
    nodeKey: 'analyticsHistogram4',
    fieldKey: 'searchDate',
    fieldKeySD: 'searchDate',
    kpiKey: 'visits',
    kpiKeySD: 'visits',
    type: dTypeTab,
    name: 'Property Visits',
    tooltip:
      'Property\'s visits include the overall number of visits from various platforms like web, mweb,app and channels like B2C, Holidays,GCC, My Partner, Mybiz. When you select All Brands as filter it will count both MMT and GI visits. (we only show visits data till yesterday\'s date and upto 3 months)',
    to: TAB_PROPERTY_VISITS,
    nodes: [],
    overviewInfo: '',
    pipeline: 'demand_v2',
    hidKey: 'hotel.hotelCode',
    vendorOrBrand: 'brand',
    kpiValue: 'sum',
    shouldIncludeConfirmStatusValue: false,
  },
  TAB_CONVERSION: {
    nodeKey: 'analyticsHistogram5',
    fieldKey: 'bookingDate',
    fieldKeySD: 'bookingDate',
    kpiKey: 'conversionRate',
    kpiKeySD: 'conversionRate',
    type: dTypeTab,
    name: 'Conversion',
    tooltip:
      'Conversion is calculated by dividing the total number of confirmed bookings with total number of visits on your property page. (we only show conversion data till yesterday\'s date and upto 3 months)',
    to: TAB_CONVERSION,
    nodes: [],
    overviewInfo: '',
    pipeline: 'transactions',
    hidKey: 'hotelId',
    vendorOrBrand: 'vendor',
    kpiValue: 'custom',
    shouldIncludeConfirmStatusValue: true,
  },
};
type performanceGraphType = {
  [key: string]: {
    nodeKey: string;
    fieldKey: string;
    fieldKeySD?: string;
    nodes: string[];
    kpiKey: string;
    kpiKeySD?: string;
    tooltip: string;
    type: string;
    name: string;
    overviewInfo: string;
    graphTitle?: string;
    kpiValue: unknown;
    kpiValueSD?: unknown;
    pipeline: string;
    groupBy?: {
      group_by: string[];
    };
    datesData: {
      isOptional?: boolean;
      name: 'bookingDate' | 'searchDate' | 'dayWise.date';
      nameSD?: 'bookingDate' | 'searchDate' | 'dayWise.date';
      ltFormat: string;
      gteFormat: string;
      ltFormatSD?: string;
      gteFormatSD?: string;
    };
    isHotelCodeKeyDotHotelCode?: boolean;
    brandOrVendorId?: 'vendor' | 'brand';
    shouldAvoidConfirmStatusValue?: boolean;
    intervals: {
      isPresent: boolean;
      intervalAvailableFor?: 'all' | 'onlymonth';
      isTypeMonth?: boolean;
      type?: string;
      typeMonth?: string;
      field?: string;
      fieldSD?: string;
      intervalsName?: string;
      intervalsValues?: unknown;
    };
    isComparisonData: boolean;
    isCustom_metric: boolean;
    isLteGraph?: boolean;
    isLteSDGraph?: boolean;
  };
};

export const ANALYTICS_ROOM_NIGHTS_PERFORMANCE_GRAPHS: performanceGraphType = {
  MAIN_BAR_GRAPH: {
    nodeKey: 'analyticsHistogram13',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    nodes: ['__dynamic__'],
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    tooltip:
      'Please note: Your Property\'s value is also included while calculating the share value',
    type: dTypeBarGraph,
    name: 'Your Property\'s Room Nights',
    overviewInfo: 'in your room nights in comparison to room nights during',
    graphTitle: 'Property\'s Room Nights for __duration__',
    kpiValue: [
      {
        roomNights: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.roomNights': 'sum',
      },
    ],
    pipeline: 'transactions',
    datesData: {
      isOptional: true,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'onlymonth',
      isTypeMonth: true,
      type: 'range',
      typeMonth: 'month',
      field: 'los',
      fieldSD: 'dayWise.date',
      intervalsName: 'range_intervals',
      intervalsValues: '__month__',
    },
    isComparisonData: true,
    isCustom_metric: false,
    isLteGraph: false,
    isLteSDGraph: true,
  },
  MAIN_PAGE_BUSINESS_CHANNELS: {
    nodeKey: 'analyticsHistogram10',
    fieldKey: 'filter_aggs',
    fieldKeySD: 'filter_aggs',
    nodes: ['__dynamic__'],
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    name: OPT_SECTION_NAME.BC,
    tooltip:
      'This section indicates the room nights\' contribution from various business channels for the selected duration',
    type: dTypeHGraph,
    overviewInfo: '__topScore__% of your room nights are from __topSource__',
    pipeline: 'transactions',
    kpiValue: [
      {
        roomNights: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.roomNights': 'sum',
      },
    ],
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        Holidays: {
          include: {
            vendorId: 'Holidays',
          },
        },
        'MyBiz/Corporate': {
          include: {
            salesChannelName: ['MyBiz', 'Q2T', 'myBiz', 'Mybiz'],
          },
        },
        MyPartner: {
          include: {
            salesChannelName: 'MyPartner',
          },
        },
        B2C: {
          exclude: {
            salesChannelName: [
              'MyBiz',
              'Q2T',
              'myBiz',
              'Mybiz',
              'MyPartner',
              'Holidays',
            ],
          },
        },
      },
    },
    isComparisonData: true,
    isCustom_metric: true,
  },

  MAIN_PAGE_ROOM_RATE_PLANS: {
    nodeKey: 'analyticsHistogram11',
    fieldKey: 'roomId',
    fieldKeySD: 'roomId',
    nodes: ['__dynamic__'],
    kpiKey: 'ratePlanId',
    kpiKeySD: 'ratePlanId',
    name: OPT_SECTION_NAME.RP,
    tooltip:
      'This section indicates the split of room nights from different rooms and rate plans available at your property',
    type: dTypeHShowMoreGraph,
    overviewInfo: '__topScore__% of your room nights are from __topSource__',
    pipeline: 'transactions',
    groupBy: {
      group_by: ['ratePlanId'],
    },
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: false,
    },
    kpiValue: [
      { netAmount: 'sum' },
      { roomNights: 'sum' },
      { bookingId: 'value_count' },
    ],
    kpiValueSD: [
      { 'dayWise.totalNetAmount': 'sum' },
      { 'dayWise.roomNights': 'sum' },
      { bookingId: 'value_count' },
    ],
    isComparisonData: false,
    isCustom_metric: true,
  },
  MAIN_PAGE_DAYS_OF_THE_WEEK: {
    nodeKey: 'analyticsHistogram8',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    nodes: ['__dynamic__'],
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    name: OPT_SECTION_NAME.DOW,
    tooltip:
      'This section indicates the room night contribution from the bookings for different days of the week',
    overviewInfo:
      '__topScore__% of the room nights on your property are booked on __topSource__',
    type: dTypeHGraph,
    pipeline: 'transactions',
    kpiValue: [
      {
        roomNights: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.roomNights': 'sum',
      },
    ],
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'dayOfWeekNum',
      field: 'bookingDate',
      fieldSD: 'dayWise.date',
    },
    isComparisonData: true,
    isCustom_metric: true,
  },

  MAIN_PAGE_MEAL_PLANS: {
    nodeKey: 'analyticsHistogram9',
    fieldKey: 'filter_aggs',
    fieldKeySD: 'filter_aggs',
    nodes: ['AP', 'CP', 'EP', 'MAP', 'Others'],
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    name: OPT_SECTION_NAME.MP,
    tooltip:
      'This section indicates the room nights from different meal plans available at your property',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your room nights are from __topSource__ meal plan',
    pipeline: 'transactions',
    kpiValue: [
      {
        roomNights: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.roomNights': 'sum',
      },
    ],
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        AP: {
          include: {
            mealPlanValue: 'AP',
          },
        },
        CP: {
          include: {
            mealPlanValue: 'CP',
          },
        },
        EP: {
          include: {
            mealPlanValue: 'EP',
          },
        },
        MAP: {
          include: {
            mealPlanValue: 'MAP',
          },
        },
        Others: {
          exclude: {
            mealPlanValue: ['AP', 'CP', 'EP', 'MAP'],
          },
        },
      },
    },
    isComparisonData: true,
    isCustom_metric: true,
  },
  MAIN_PAGE_ADVANCE_PURCHASE: {
    nodeKey: 'analyticsHistogram7',
    fieldKey: 'ap',
    fieldKeySD: 'ap',
    nodes: ['0-3 Days', '4-7 Days', '8-15 Days', '16-30 Days', '31+ Days'],
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    name: OPT_SECTION_NAME.APW,
    tooltip:
      'This section indicates how much in advance your property is booked and the number of room nights corresponding to that Advance Booking Window',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your room nights are from bookings that have been made __topSource__ in advance',
    pipeline: 'transactions',
    kpiValue: [
      {
        roomNights: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.roomNights': 'sum',
      },
    ],
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'custom',
      intervalsName: 'custom_intervals',
      field: 'ap',
      fieldSD: 'ap',
      intervalsValues: [
        ['0-3 Days', 0, 3],
        ['4-7 Days', 4, 7],
        ['8-15 Days', 8, 15],
        ['16-30 Days', 16, 30],
        ['31+ Days', 31, 1000],
      ],
    },
    isComparisonData: true,
    isCustom_metric: true,
  },

  MAIN_PAGE_PAX: {
    nodeKey: 'analyticsHistogram12',
    fieldKey: 'filter_aggs',
    fieldKeySD: 'filter_aggs',
    nodes: ['__dynamic__'],
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    name: OPT_SECTION_NAME.PAX,
    tooltip:
      'This section indicates the room nights earned from different guest occupancies',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your room nights are from bookings that have PAX as: __topSource__',
    pipeline: 'transactions',
    kpiValue: [
      {
        roomNights: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.roomNights': 'sum',
      },
    ],
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        '1 Adult & 0 Child': {
          include: {
            noOfAdults: 1,
            noOfChildren: 0,
          },
        },
        '1 Adult & 1 Child': {
          include: {
            noOfAdults: 1,
            noOfChildren: 1,
          },
        },
        '2 Adults & 0 Child': {
          include: {
            noOfAdults: 2,
            noOfChildren: 0,
          },
        },
        '2 Adults & 1 Child': {
          include: {
            noOfAdults: 2,
            noOfChildren: 1,
          },
        },
        '2 Adults & 2 Children': {
          include: {
            noOfAdults: 2,
            noOfChildren: 2,
          },
        },
        'More than 2 Adults': {
          include: {
            noOfAdults: {
              gt: 2,
            },
          },
        },
      },
    },
    isComparisonData: true,
    isCustom_metric: true,
  },
  MAIN_PAGE_LENGTH_OF_STAY: {
    nodeKey: 'analyticsHistogram6',
    fieldKey: 'los',
    fieldKeySD: 'los',
    nodes: ['1 Day', '2 Days', '3 Days', '4 Days', '5+ Days'],
    kpiKey: 'roomNights',
    kpiKeySD: 'dayWise.roomNights',
    name: OPT_SECTION_NAME.LOS,
    tooltip:
      'This section indicates the room nights from various bookings for different stay durations',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your room nights are from bookings that have Length of Stay(LOS) as: __topSource__',
    pipeline: 'transactions',
    kpiValue: [
      {
        roomNights: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.roomNights': 'sum',
      },
    ],
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'range',
      field: 'los',
      fieldSD: 'los',
      intervalsName: 'range_intervals',
      intervalsValues: [
        ['1 Day', 1, 2],
        ['2 Days', 2, 3],
        ['3 Days', 3, 4],
        ['4 Days', 4, 5],
        ['5+ Days', 5, 1000],
      ],
    },
    isComparisonData: true,
    isCustom_metric: true,
  },
};

export const ALL_ANALYTICS_ROOM_NIGHTS_PERFORMANCE_GRAPHS = {
  ...tabsData,
  ...ANALYTICS_ROOM_NIGHTS_PERFORMANCE_GRAPHS,
};

export const ANALYTICS_REVENUE_PERFORMANCE_GRAPHS: performanceGraphType = {
  MAIN_BAR_GRAPH: {
    nodeKey: 'analyticsHistogram13',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    nodes: ['__dynamic__'],
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    tooltip:
      'Please note: Your Property\'s value is also included while calculating the share value',
    type: dTypeBarGraph,
    name: 'Your Property\'s Revenue',
    overviewInfo: 'in revenue compared to the revenue between',
    graphTitle: 'Property\'s Revenue for __duration__',
    pipeline: 'transactions',
    datesData: {
      isOptional: true,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'onlymonth',
      isTypeMonth: true,
      type: 'range',
      typeMonth: 'month',
      field: 'los',
      fieldSD: 'dayWise.date',
      intervalsName: 'range_intervals',
      intervalsValues: '__month__',
    },
    kpiValue: [{ netAmount: 'sum' }],
    kpiValueSD: [{ 'dayWise.totalNetAmount': 'sum' }],
    isComparisonData: true,
    isCustom_metric: false,
    isLteGraph: false,
    isLteSDGraph: true,
  },
  MAIN_PAGE_BUSINESS_CHANNELS: {
    nodeKey: 'analyticsHistogram10',
    fieldKey: 'filter_aggs',
    fieldKeySD: 'filter_aggs',
    nodes: ['__dynamic__'],
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    name: OPT_SECTION_NAME.BC,
    tooltip:
      'This section indicates the revenue contribution from various business channels for the selected duration',
    type: dTypeHGraph,
    overviewInfo: '__topScore__% of your revenue is from __topSource__',
    pipeline: 'transactions',
    brandOrVendorId: 'vendor',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        Holidays: { include: { vendorId: 'Holidays' } },
        'MyBiz/Corporate': {
          include: { salesChannelName: ['MyBiz', 'Q2T', 'myBiz', 'Mybiz'] },
        },
        MyPartner: { include: { salesChannelName: 'MyPartner' } },
        B2C: {
          exclude: {
            salesChannelName: [
              'MyBiz',
              'Q2T',
              'myBiz',
              'Mybiz',
              'MyPartner',
              'Holidays',
            ],
          },
        },
      },
    },
    kpiValue: [{ netAmount: 'sum' }],
    kpiValueSD: [{ 'dayWise.totalNetAmount': 'sum' }],
    isComparisonData: true,
    isCustom_metric: true,
  },

  MAIN_PAGE_ROOM_RATE_PLANS: {
    nodeKey: 'analyticsHistogram11',
    fieldKey: 'roomId',
    fieldKeySD: 'roomId',
    nodes: ['__dynamic__'],
    kpiKey: 'ratePlanId',
    kpiKeySD: 'ratePlanId',
    name: OPT_SECTION_NAME.RP,
    tooltip:
      'This section indicates the split of revenue from different rooms and rate plans available at your property',
    type: dTypeHShowMoreGraph,
    overviewInfo: '__topScore__% of your revenue is from __topSource__',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    groupBy: {
      group_by: ['ratePlanId'],
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: false,
    },
    kpiValue: [
      { netAmount: 'sum' },
      { roomNights: 'sum' },
      { bookingId: 'value_count' },
    ],
    kpiValueSD: [
      { 'dayWise.totalNetAmount': 'sum' },
      { 'dayWise.roomNights': 'sum' },
      { bookingId: 'value_count' },
    ],
    isComparisonData: false,
    isCustom_metric: true,
  },
  MAIN_PAGE_DAYS_OF_THE_WEEK: {
    nodeKey: 'analyticsHistogram8',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    nodes: ['__dynamic__'],
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    name: OPT_SECTION_NAME.DOW,
    tooltip:
      'This section indicates the revenue contribution from the bookings for different days of the week',
    overviewInfo:
      '__topScore__% of the revenue is from the bookings on __topSource__',
    type: dTypeHGraph,
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'dayOfWeekNum',
      field: 'bookingDate',
      fieldSD: 'dayWise.date',
    },
    kpiValue: [
      {
        netAmount: 'sum',
      },
    ],
    kpiValueSD: [
      {
        'dayWise.totalNetAmount': 'sum',
      },
    ],
    isComparisonData: true,
    isCustom_metric: true,
  },

  MAIN_PAGE_MEAL_PLANS: {
    nodeKey: 'analyticsHistogram9',
    fieldKey: 'filter_aggs',
    fieldKeySD: 'filter_aggs',
    nodes: ['AP', 'CP', 'EP', 'MAP', 'Others'],
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    name: OPT_SECTION_NAME.MP,
    tooltip:
      'This section indicates the revenue earned from different meal plans available at your property',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your revenue is from __topSource__ meal plan',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        AP: { include: { mealPlanValue: 'AP' } },
        CP: { include: { mealPlanValue: 'CP' } },
        EP: { include: { mealPlanValue: 'EP' } },
        MAP: { include: { mealPlanValue: 'MAP' } },
        Others: { exclude: { mealPlanValue: ['AP', 'CP', 'EP', 'MAP'] } },
      },
    },
    kpiValue: [{ netAmount: 'sum' }],
    kpiValueSD: [{ 'dayWise.totalNetAmount': 'sum' }],
    isComparisonData: true,
    isCustom_metric: true,
  },
  MAIN_PAGE_ADVANCE_PURCHASE: {
    nodeKey: 'analyticsHistogram7',
    fieldKey: 'ap',
    fieldKeySD: 'ap',
    nodes: ['0-3 Days', '4-7 Days', '8-15 Days', '16-30 Days', '31+ Days'],
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    name: OPT_SECTION_NAME.APW,
    tooltip:
      'This section indicates how much in advance your property is booked and the revenue earned from it',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your revenue is from bookings that have made __topSource__ in advance',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'custom',
      intervalsName: 'custom_intervals',
      field: 'ap',
      fieldSD: 'ap',
      intervalsValues: [
        ['0-3 Days', 0, 3],
        ['4-7 Days', 4, 7],
        ['8-15 Days', 8, 15],
        ['16-30 Days', 16, 30],
        ['31+ Days', 31, 1000],
      ],
    },
    kpiValue: [{ netAmount: 'sum' }],
    kpiValueSD: [{ 'dayWise.totalNetAmount': 'sum' }],
    isComparisonData: true,
    isCustom_metric: true,
  },

  MAIN_PAGE_PAX: {
    nodeKey: 'analyticsHistogram12',
    fieldKey: 'filter_aggs',
    fieldKeySD: 'filter_aggs',
    nodes: ['__dynamic__'],
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    name: OPT_SECTION_NAME.PAX,
    tooltip:
      'This section indicates the revenue earned from different guest occupancies',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your revenue is from bookings that have PAS as: __topSource__',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        '1 Adult & 0 Child': { include: { noOfAdults: 1, noOfChildren: 0 } },
        '1 Adult & 1 Child': { include: { noOfAdults: 1, noOfChildren: 1 } },
        '2 Adults & 0 Child': { include: { noOfAdults: 2, noOfChildren: 0 } },
        '2 Adults & 1 Child': { include: { noOfAdults: 2, noOfChildren: 1 } },
        '2 Adults & 2 Children': {
          include: { noOfAdults: 2, noOfChildren: 2 },
        },
        'More than 2 Adults': { include: { noOfAdults: { gt: 2 } } },
      },
    },
    kpiValue: [{ netAmount: 'sum' }],
    kpiValueSD: [{ 'dayWise.totalNetAmount': 'sum' }],
    isComparisonData: true,
    isCustom_metric: true,
  },
  MAIN_PAGE_LENGTH_OF_STAY: {
    nodeKey: 'analyticsHistogram6',
    fieldKey: 'los',
    fieldKeySD: 'los',
    nodes: ['1 Day', '2 Days', '3 Days', '4 Days', '5+ Days'],
    kpiKey: 'netAmount',
    kpiKeySD: 'dayWise.totalNetAmount',
    name: OPT_SECTION_NAME.LOS,
    tooltip:
      'This section indicates the revenue earned from various bookings for different stay durations',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your revenue is from bookings that have Length of Stay(LOS) as: __topSource__',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'range',
      field: 'los',
      fieldSD: 'los',
      intervalsName: 'range_intervals',
      intervalsValues: [
        ['1 Day', 1, 2],
        ['2 Days', 2, 3],
        ['3 Days', 3, 4],
        ['4 Days', 4, 5],
        ['5+ Days', 5, 1000],
      ],
    },
    kpiValue: [{ netAmount: 'sum' }],
    kpiValueSD: [{ 'dayWise.totalNetAmount': 'sum' }],
    isComparisonData: true,
    isCustom_metric: true,
  },
};

export const ALL_ANALYTICS_REVENUE_PERFORMANCE_GRAPHS = {
  ...tabsData,
  ...ANALYTICS_REVENUE_PERFORMANCE_GRAPHS,
};

export const ANALYTICS_ASP_PERFORMANCE_CARDS: performanceGraphType = {
  MAIN_BAR_GRAPH: {
    nodeKey: 'analyticsHistogram6',
    fieldKey: 'bookingDate',
    fieldKeySD: 'dayWise.date',
    nodes: ['__dynamic__'],
    kpiKey: 'avgSellPrice',
    kpiKeySD: 'avgSellPrice',
    tooltip:
      'Competitor value is calculated by taking average of average post promotion sell price to the customer of the different rooms/rate plans in their properties upon total room nights during that duration',
    type: dTypeBarGraph,
    name: 'Your Property\'s Average Selling Price (ASP)',
    overviewInfo: 'in ASP compared to the ASP between',
    graphTitle: 'Property\'s Average Selling Price for __duration__',
    pipeline: 'transactions',
    datesData: {
      isOptional: true,
      name: 'bookingDate',
      nameSD: 'dayWise.date',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
      ltFormatSD: apiDateFormat,
      gteFormatSD: apiDateFormat,
    },
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'onlymonth',
      isTypeMonth: true,
      type: 'range',
      typeMonth: 'month',
      field: 'los',
      fieldSD: 'dayWise.date',
      intervalsName: 'range_intervals',
      intervalsValues: '__month__',
    },
    kpiValue: [{ avgSellPrice: 'custom' }],
    kpiValueSD: [{ avgSellPrice: 'custom' }],
    isComparisonData: true,
    isCustom_metric: false,
    isLteGraph: false,
    isLteSDGraph: false,
  },
};

export const TOP_ANALYTICS_ASP_PERFORMANCE_GRAPHS = {
  ...tabsData,
  ...ANALYTICS_ASP_PERFORMANCE_CARDS,
};

export const ANALYTICS_PROPERTY_VISITS_PERFORMANCE_GRAPHS: performanceGraphType =
  {
    MAIN_BAR_GRAPH: {
      nodeKey: 'analyticsHistogram9',
      fieldKey: 'searchDate',
      nodes: ['__dynamic__'],
      kpiKey: 'visits',
      tooltip:
        'Please note: Your Property\'s value is also included while calculating the share value',
      type: dTypeBarGraph,
      name: 'Your Property\'s Visits',
      overviewInfo:
        'Total number of people visited your property page from __duration__',
      graphTitle: 'Property\'s Visits for __duration__',
      pipeline: 'demand_v2',
      datesData: {
        isOptional: true,
        name: 'searchDate',
        ltFormat: apiDateFormat,
        gteFormat: apiDateFormat,
      },
      brandOrVendorId: 'brand',
      isHotelCodeKeyDotHotelCode: true,
      intervals: {
        isPresent: true,
        intervalAvailableFor: 'onlymonth',
        isTypeMonth: true,
        type: 'range',
        typeMonth: 'month',
        field: 'los',
        intervalsName: 'range_intervals',
        intervalsValues: '__month__',
      },
      kpiValue: [{ visits: 'sum' }],
      isComparisonData: true,
      isCustom_metric: false,
      shouldAvoidConfirmStatusValue: true,
      isLteGraph: true,
      isLteSDGraph: true,
    },
    MAIN_PAGE_ADVANCE_PURCHASE: {
      nodeKey: 'analyticsHistogram8',
      fieldKey: 'ap',
      nodes: ['0-3 Days', '4-7 Days', '8-15 Days', '16-30 Days', '31+ Days'],
      kpiKey: 'visits',
      name: OPT_SECTION_NAME.APW,
      tooltip:
        'This section indicates how much in advance your property is booked and the number of Property visits corresponding to that Advance Booking Window',
      type: dTypeHGraph,
      overviewInfo:
        '__topScore__% of your guests are visiting to book your property __topSource__ in advance',
      pipeline: 'demand_v2',
      datesData: {
        isOptional: false,
        name: 'searchDate',
        ltFormat: apiDateFormat,
        gteFormat: apiDateFormat,
      },
      brandOrVendorId: 'brand',
      isHotelCodeKeyDotHotelCode: true,
      intervals: {
        isPresent: true,
        intervalAvailableFor: 'all',
        isTypeMonth: false,
        type: 'custom',
        intervalsName: 'custom_intervals',
        field: 'ap',
        intervalsValues: [
          ['0-3 Days', 0, 3],
          ['4-7 Days', 4, 7],
          ['8-15 Days', 8, 15],
          ['16-30 Days', 16, 30],
          ['31+ Days', 31, 1000],
        ],
      },
      kpiValue: [{ visits: 'sum' }],
      isComparisonData: true,
      isCustom_metric: true,
      shouldAvoidConfirmStatusValue: true,
    },
    MAIN_PAGE_PAX: {
      nodeKey: 'analyticsHistogram7',
      fieldKey: 'filter_aggs',
      nodes: ['__dynamic__'],
      kpiKey: 'visits',
      name: OPT_SECTION_NAME.PAX,
      tooltip:
        'This section indicates the Property visits earned from different guest occupancies',
      type: dTypeHGraph,
      overviewInfo:
        '__topScore__% of your guests\' searches are for PAX: __topSource__',
      pipeline: 'demand_v2',
      datesData: {
        isOptional: false,
        name: 'searchDate',
        ltFormat: apiDateFormat,
        gteFormat: apiDateFormat,
      },
      brandOrVendorId: 'brand',
      isHotelCodeKeyDotHotelCode: true,
      intervals: {
        isPresent: true,
        intervalAvailableFor: 'all',
        isTypeMonth: false,
        type: 'filter',
        intervalsName: 'filter_intervals',
        intervalsValues: {
          '1 Adult & 0 Child': {
            include: { searchGuestAdult: 1, searchGuestChild: 0 },
          },
          '1 Adult & 1 Child': {
            include: { searchGuestAdult: 1, searchGuestChild: 1 },
          },
          '2 Adults & 0 Child': {
            include: { searchGuestAdult: 2, searchGuestChild: 0 },
          },
          '2 Adults & 1 Child': {
            include: { searchGuestAdult: 2, searchGuestChild: 1 },
          },
          '2 Adults & 2 Children': {
            include: { searchGuestAdult: 2, searchGuestChild: 2 },
          },
          'More than 2 Adults': { include: { noOfAdults: { gt: 2 } } },
        },
      },
      kpiValue: [{ visits: 'sum' }],
      isComparisonData: true,
      isCustom_metric: true,
      shouldAvoidConfirmStatusValue: true,
    },
    MAIN_PAGE_LENGTH_OF_STAY: {
      nodeKey: 'analyticsHistogram6',
      fieldKey: 'filter_aggs',
      nodes: ['1 Day', '2 Days', '3 Days', '4 Days', '5+ Days'],
      kpiKey: 'visits',
      name: OPT_SECTION_NAME.LOS,
      tooltip:
        'This section indicates the Property visits from various bookings for different stay durations',
      type: dTypeHGraph,
      overviewInfo:
        '__topScore__% of the visits on your property are for LOS __topSource__',
      pipeline: 'demand_v2',
      datesData: {
        isOptional: false,
        name: 'searchDate',
        ltFormat: apiDateFormat,
        gteFormat: apiDateFormat,
      },
      brandOrVendorId: 'brand',
      isHotelCodeKeyDotHotelCode: true,
      intervals: {
        isPresent: true,
        intervalAvailableFor: 'all',
        isTypeMonth: false,
        type: 'filter',
        intervalsName: 'filter_intervals',
        intervalsValues: {
          '1 Day': { include: { searchLos: '1' } },
          '2 Days': { include: { searchLos: '2' } },
          '3 Days': { include: { searchLos: '3' } },
          '4 Days': { include: { searchLos: '4' } },
          '5+ Days': { include: { searchLos: ['5', 'others'] } },
        },
      },
      kpiValue: [{ visits: 'sum' }],
      isComparisonData: true,
      isCustom_metric: true,
      shouldAvoidConfirmStatusValue: true,
    },
  };

export const ALL_ANALYTICS_PROPERTY_VISITS_PERFORMANCE_GRAPHS = {
  ...tabsData,
  ...ANALYTICS_PROPERTY_VISITS_PERFORMANCE_GRAPHS,
};

export const ANALYTICS_CONVERSION_PERFORMANCE_GRAPHS: performanceGraphType = {
  MAIN_BAR_GRAPH: {
    nodeKey: 'analyticsHistogram9',
    fieldKey: 'bookingDate',
    nodes: ['__dynamic__'],
    kpiKey: 'conversionRate',
    tooltip:
      'Please note: Your Property\'s value is also included while calculating the share value',
    type: dTypeBarGraph,
    name: 'Your Property\'s Conversion',
    overviewInfo: 'in your conversion in comparison to conversion during',
    graphTitle: 'Property\'s Conversion for __duration__',
    pipeline: 'transactions',
    datesData: {
      isOptional: true,
      name: 'bookingDate',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'onlymonth',
      isTypeMonth: true,
    },
    kpiValue: [{ conversionRate: 'custom' }],
    isComparisonData: true,
    isCustom_metric: false,
    isLteGraph: true,
    isLteSDGraph: true,
  },
  MAIN_PAGE_ADVANCE_PURCHASE: {
    nodeKey: 'analyticsHistogram8',
    fieldKey: 'ap',
    nodes: ['0-3 Days', '4-7 Days', '8-15 Days', '16-30 Days', '31+ Days'],
    kpiKey: 'conversionRate',
    name: OPT_SECTION_NAME.APW,
    tooltip:
      'This section indicates how much in advance your property is booked and the number of Conversion corresponding to that Advance Booking Window',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your guests are booking your property __topSource__ in advance',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'custom',
      intervalsName: 'custom_intervals',
      field: 'ap',
      intervalsValues: [
        ['0-3 Days', 0, 3],
        ['4-7 Days', 4, 7],
        ['8-15 Days', 8, 15],
        ['16-30 Days', 16, 30],
        ['31+ Days', 31, 1000],
      ],
    },
    kpiValue: [{ conversionRate: 'custom' }],
    isComparisonData: true,
    isCustom_metric: true,
  },

  MAIN_PAGE_PAX: {
    nodeKey: 'analyticsHistogram7',
    fieldKey: 'filter_aggs',
    nodes: ['__dynamic__'],
    kpiKey: 'conversionRate',
    name: OPT_SECTION_NAME.PAX,
    tooltip:
      'This section indicates the conversion of different guest occupancies',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your guests are booking your property for __topSource__',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        '1 Adult & 0 Child': { include: { noOfAdults: 1, noOfChildren: 0 } },
        '1 Adult & 1 Child': { include: { noOfAdults: 1, noOfChildren: 1 } },
        '2 Adults & 0 Child': { include: { noOfAdults: 2, noOfChildren: 0 } },
        '2 Adults & 1 Child': { include: { noOfAdults: 2, noOfChildren: 1 } },
        '2 Adults & 2 Children': {
          include: { noOfAdults: 2, noOfChildren: 2 },
        },
        'More than 2 Adults': { include: { noOfAdults: { gt: 2 } } },
      },
    },
    kpiValue: [{ conversionRate: 'custom' }],
    isComparisonData: true,
    isCustom_metric: true,
  },
  MAIN_PAGE_LENGTH_OF_STAY: {
    nodeKey: 'analyticsHistogram6',
    fieldKey: 'filter_aggs',
    nodes: ['1 Day', '2 Days', '3 Days', '4 Days', '5+ Days'],
    kpiKey: 'conversionRate',
    name: OPT_SECTION_NAME.LOS,
    tooltip:
      'This section indicates the Conversion from various bookings for different stay durations',
    type: dTypeHGraph,
    overviewInfo:
      '__topScore__% of your guests are booking your property for __topSource__',
    pipeline: 'transactions',
    datesData: {
      isOptional: false,
      name: 'bookingDate',
      ltFormat: apiDateFormatV3,
      gteFormat: apiDateFormatV2,
    },
    brandOrVendorId: 'vendor',
    intervals: {
      isPresent: true,
      intervalAvailableFor: 'all',
      isTypeMonth: false,
      type: 'filter',
      intervalsName: 'filter_intervals',
      intervalsValues: {
        '1 Day': { include: { los: '1' } },
        '2 Days': { include: { los: '2' } },
        '3 Days': { include: { los: '3' } },
        '4 Days': { include: { los: '4' } },
        '5+ Days': { exclude: { los: ['1', '2', '3', '4'] } },
      },
    },
    kpiValue: [{ conversionRate: 'custom' }],
    isComparisonData: true,
    isCustom_metric: true,
  },
};
export const ALL_ANALYTICS_CONVERSION_PERFORMANCE_GRAPHS = {
  ...tabsData,
  ...ANALYTICS_CONVERSION_PERFORMANCE_GRAPHS,
};

export const QUERIES_NUMBERS = {
  ROOM_NIGHTS: 13,
  REVENUE: 13,
  ASP_TOP: 6,
  PROPERTY_VISITS: 9,
  CONVERSION: 9,
};

export const FILTER_TYPES = {
  DURATION: 'duration',
  BRAND: 'brand',
  DATE: 'fieldData',
};

export const analyticsPages = {
  ANALYTICS_ROOM_NIGHTS_PERFORMANCE_GRAPHS,
  ANALYTICS_REVENUE_PERFORMANCE_GRAPHS,
  ANALYTICS_ASP_PERFORMANCE_CARDS,
  ANALYTICS_PROPERTY_VISITS_PERFORMANCE_GRAPHS,
  ANALYTICS_CONVERSION_PERFORMANCE_GRAPHS,
};
