import * as PromotionsIcons from 'assets/modules/promotions';
import { Button, FormElement } from 'components/new-core';
import { useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import { addToDate, getFormattedDate } from 'utilities/DateUtils';
import {
  dateAPIFormat,
  datesApplicabilityOptions,
  noEndDate,
} from '../Promotions.constants';
import { DatesForm } from '../components';
import { Radio } from 'components/latest-core';

const PromotionDates = props => {
  const { promotionDatesState: promoDS } = props;
  const [STRINGS] = useLangStrings('Promotions');
  const SUB_STRINGS = STRINGS.CREATE_SECTIONS.DATES;

  const handleEditDates = () => {
    promoDS.setShowDatesForm(true);
  };

  const showNoDatesUI = (
    <>
      <div className="end-date-info-wrapper">
        <span className="img-wrapper">
          <img src={PromotionsIcons.CalendarIcon} alt="calendar" />
        </span>
        <span>
          {SUB_STRINGS.DESC_1[0]}
          <b>{SUB_STRINGS.DESC_1[1]}</b>
          {SUB_STRINGS.DESC_1[2]}
          <b>{SUB_STRINGS.DESC_1[3]}</b>
          {SUB_STRINGS.DESC_1[4]}
        </span>
      </div>
      <div className="edit-dates-wrapper">
        <span>{SUB_STRINGS.DESC_2}</span>
        <Button variant="text" onClick={handleEditDates}>
          {STRINGS.BTNS.EDIT_DATES}
        </Button>
      </div>
    </>
  );

  const showDatesUI = (
    <>
      <FormElement label={SUB_STRINGS.APPLICABLE_FOR} style={{ width: 'auto' }}>
        <Radio
          variant="legacy-boxed"
          value={
            promoDS.applicableOnlyForStayDates ? 'stay_date' : 'bkg_stay_date'
          }
          options={datesApplicabilityOptions}
          onChange={val => {
            const valueToUpdate = val === 'stay_date';
            promoDS.setApplicableOnlyForStayDates(valueToUpdate);
          }}
          inline
        ></Radio>
      </FormElement>
      <DatesForm
        title="Stay Date"
        switchVal={promoDS.hasNoStayEndDate}
        onSwitchChange={promoDS.setHasNoStayEndDate}
        startDate={promoDS.stayStartDate}
        endDate={promoDS.stayEndDate}
        onStartDateChange={promoDS.setStayStartDate}
        onEndDateChange={promoDS.setStayEndDate}
      />
      {!promoDS.applicableOnlyForStayDates && (
        <DatesForm
          title="Booking Date"
          startDate={promoDS.bkgStartDate}
          endDate={promoDS.bkgEndDate}
          switchVal={promoDS.hasNoBkgEndDate}
          onSwitchChange={promoDS.setHasNoBkgEndDate}
          onStartDateChange={promoDS.setBkgStartDate}
          onEndDateChange={promoDS.setBkgEndDate}
        />
      )}
    </>
  );

  return (
    <tr>
      <td>{SUB_STRINGS.NAME}</td>
      <td>{promoDS.showDatesForm ? showDatesUI : showNoDatesUI}</td>
    </tr>
  );
};

const initialStayEndDate = addToDate(6, 'months');
const initialBkgEndDate = addToDate(6, 'months');

export function usePromotionDatesState(promotionData, isEditFlow) {
  const [applicableOnlyForStayDates, setApplicableOnlyForStayDates] = useState(
    promotionData?.applicableOnlyForStayDates ?? true,
  );
  const [stayStartDate, setStayStartDate] = useState(
    promotionData?.stayStartDate ?? new Date(),
  );
  const [stayEndDate, setStayEndDate] = useState(
    promotionData?.stayEndDate ?? initialStayEndDate,
  );
  const [hasNoStayEndDate, setHasNoStayEndDate] = useState(
    promotionData?.hasNoStayEndDate ?? true,
  );
  const [bkgStartDate, setBkgStartDate] = useState(
    promotionData?.bkgStartDate ?? new Date(),
  );
  const [bkgEndDate, setBkgEndDate] = useState(
    promotionData?.bkgEndDate ?? initialBkgEndDate,
  );
  const [hasNoBkgEndDate, setHasNoBkgEndDate] = useState(
    promotionData?.hasNoBkgEndDate ?? true,
  );

  const [showDatesForm, setShowDatesForm] = useState(
    isEditFlow || promotionData.stayStartDate,
  );

  return {
    applicableOnlyForStayDates,
    setApplicableOnlyForStayDates,
    stayStartDate,
    setStayStartDate,
    stayEndDate,
    setStayEndDate,
    hasNoStayEndDate,
    setHasNoStayEndDate,
    bkgStartDate,
    setBkgStartDate,
    bkgEndDate,
    setBkgEndDate,
    hasNoBkgEndDate,
    setHasNoBkgEndDate,
    showDatesForm,
    setShowDatesForm,
  };
}

export function getPayloadFrDates(datesState) {
  const payload: {
    checkinDateStart?: string;
    checkoutDateEnd?: string;
    bookingDateStart?: string;
    bookingDateEnd?: string;
  } = {};
  payload.checkinDateStart = getFormattedDate(
    datesState.stayStartDate,
    dateAPIFormat,
  );
  if (datesState.hasNoStayEndDate) {
    payload.checkoutDateEnd = getFormattedDate(noEndDate, dateAPIFormat);
  } else {
    payload.checkoutDateEnd = getFormattedDate(
      datesState.stayEndDate,
      dateAPIFormat,
    );
  }
  payload.bookingDateStart = getFormattedDate(
    datesState.bkgStartDate,
    dateAPIFormat,
  );

  if (datesState.applicableOnlyForStayDates) {
    payload.bookingDateStart = getFormattedDate(new Date(), dateAPIFormat);
    payload.bookingDateEnd = getFormattedDate(noEndDate, dateAPIFormat);
  } else if (datesState.hasNoBkgEndDate) {
    if (datesState.hasNoStayEndDate) {
      payload.bookingDateEnd = getFormattedDate(noEndDate, dateAPIFormat);
    } else {
      payload.bookingDateEnd = getFormattedDate(
        datesState.stayEndDate,
        dateAPIFormat,
      );
    }
  } else {
    payload.bookingDateEnd = getFormattedDate(
      datesState.bkgEndDate,
      dateAPIFormat,
    );
  }
  return payload;
}

export default PromotionDates;
