import { EmailIcon, HostProfileIcon, PhoneIcon } from 'assets/modules/reseller';
import { useLangStrings } from 'utilities/CustomHooks';

const BDMDetailsCard = props => {
  const { contactDetails, className = 'bg-[#EFEFEF]', onClickBdm } = props;
  const [STRINGS] = useLangStrings<'Reseller'>('Reseller');
  const isNotValidData =
    !contactDetails?.name && !contactDetails?.email && !contactDetails?.mobile;
  if (!contactDetails || isNotValidData) return null;

  return (
    <div
      className={`mt-4 rounded-sm px-3 py-[10px] ${className}`}
      data-test-id="bdm-card"
    >
      <div className="font-[500]">
        {STRINGS.BDM_DETAILS_CARD.NEED_MORE_HELP}
      </div>
      <div className="mt-3">
        <div className="flex flex-row mb-2">
          <HostProfileIcon className="mr-2" />
          <div>
            <div>{contactDetails.name} </div>
            <div className="text-[#787878]">
              {STRINGS.BDM_DETAILS_CARD.BUSINESS_DEVELOPMENT_MANAGER}
            </div>
          </div>
        </div>
        {!!contactDetails.mobile && (
          <div className="flex flex-row mb-2 items-center">
            <PhoneIcon className="mr-2" />
            <div className="text-[#3543BF]">
              <a
                href={`tel:${contactDetails.mobile}`}
                onClick={() => onClickBdm && onClickBdm('mobile')}
              >
                {contactDetails.mobile}
              </a>
            </div>
          </div>
        )}
        {!!contactDetails.email && (
          <div className="flex flex-row items-center">
            <EmailIcon className="mr-2" />
            <div className="text-[#3543BF]">
              <a
                href={`mailto:${contactDetails.email}`}
                onClick={() => onClickBdm && onClickBdm('email')}
              >
                {contactDetails.email}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BDMDetailsCard;
