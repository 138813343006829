export const FETCH_PREBUY_ORDERS = `query GetHotelCloudPrebuyOrders($endpointRequestData: EndpointRequestData) {
  rpcGetHotelCloudPrebuyOrders(endpointRequestData: $endpointRequestData) {
    pendingGetPrebuyOrders {
      correlationKey
      error {
        code
        message
      }
      prebuyOrders {
        orderId
        status
        prebuyEntities {
          ingoRoomId
          roomTypeName
          rateplanCode
          mealplan
        }
        priceBreakup {
          costPrice
          unitPrice
          gst
          tds
          gstRate
          tdsRate
        }
        roomNights {
          total
          sold
          pending
          expired
        }
        autoApprovalTime
        expiryTime
        extendedExpiryTime
        approvedBy
        purchasedOn
      }
      roomRateplanFilters {
        ingoRoomId
        roomTypeName
        rateplanCode
        mealplan
      }
      totalOrders
    }
    nonPendingGetPrebuyOrders {
      correlationKey
      error {
        code
        message
      }
      prebuyOrders {
        orderId
        status
        prebuyEntities {
          ingoRoomId
          roomTypeName
          rateplanCode
          mealplan
        }
        priceBreakup {
          costPrice
          unitPrice
          gst
          tds
          gstRate
          tdsRate
        }
        roomNights {
          total
          sold
          pending
          expired
        }
        autoApprovalTime
        expiryTime
        extendedExpiryTime
        approvedBy
        purchasedOn
      }
      roomRateplanFilters {
        ingoRoomId
        roomTypeName
        rateplanCode
        mealplan
      }
      totalOrders
    }
  }
}`;

export const FETCH_PENDING_PREBUY_ORDERS_COUNT = `
query GetHotelCloudPendingPrebuyOrdersCount($endpointRequestData: EndpointRequestData) {
    rpcGetHotelCloudPrebuyOrders(endpointRequestData: $endpointRequestData) {
      pendingGetPrebuyOrders {
        correlationKey
        error {
          code
          message
        }
        totalOrders
      }
    }
  }
`;

export const UPDATE_PREBUY_ORDER_STATUS = `mutation RPCHotelCloudUpsertPrebuyOrder(
  $endpointRequestData: EndpointRequestData
) {
  rpcHotelCloudUpsertPrebuyOrder(endpointRequestData: $endpointRequestData) {
    correlationKey
    success
    error {
      errorCode
      errorMsg
    }
    orderStatus {
      orderNumber
      status
      error {
        errorCode
        errorMsg
      }
      orderStatus
      mojoPushStatus
      rateplanUpdateStatus
    }
  }
}
`;

export const FETCH_AUDIT_HISTORY = `query GetOrderHistory($endpointRequestData: EndpointRequestData) {
  rpcGetResellerOrderHistory(endpointRequestData: $endpointRequestData) {
    correlationKey
    error {
      code
      message
    }
    data {
      orderNumber
      statusCreatedOn
      statusModifiedBy
      currentOrderStatus
      previousOrderStatus
    }
  }
}
`;

export const FETCH_PREBUY_SETTLEMENT = `query PreBuySettlement($rawEndpointRequestData: RawEndpointsRequestData) {
  preBuySettlement: rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
    preBuySettlement {
      responseStatus
      responseMessage
      hotelAdvanceSummary
    }
  }
}`;

export const FETCH_PREBUY_VOUCHER = `mutation Gethoteliervoucher($rawEndpointsRequestData: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $rawEndpointsRequestData) {
    gethoteliervoucher {
      modelType1 {
        status
        message
        pdfContent
        mailSent
        htmlContent
      }
    }
  }
}
`;
