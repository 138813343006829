import {
  hotelConfigQuery,
  roomConfigQuery,
} from 'modules/day-use-rooms/DayUseGraphQueries';

export const BOOKING_VOUCHER_EMAIL_DATA = `query GroupBookingDetails(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    groupedBookingDetails {
      bookingVoucherDetails{
        hotelierEmailList
        voucherEmailList{
          from
          resp
          sentStatus
          sentAt
        }
        cancelled
      }
    
    }
  }
}`;

export const COMBINED_BOOKING_DETAILS = `query GroupBookingDetails(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    groupedBookingDetails {
      customerDetails {
        bookingEmail
        bookingPhone
        cancelled
      }
      noteDetails {
        noteList {
          endDate
          text
          id
          source
          startDate
          isActive
        }
      }
       paymentSettlementDetails{
        message
        hotelCode
        currency
        gstnInvoiceFlag
        paymentStatus
        bookingId
        nettPayable
        isVcc
        gstnInvoiceAmount
        paymentDetailsList{
          finePrint
          timeStamp
          refIdLabel
          refId
          refNo
          currency
          amount
          detailType
          amountLabel
          dateLabel
          paymentType
        }
      }
      repeatCustomerInfo{
        count
        firstValidBookingDate
      }
      bookingVoucherDetails{
        hotelierEmailList
        voucherEmailList{
          from
          resp
          sentStatus
          sentAt
        }
        cancelled
         
      }
    bookingDetails{
        confirmBookingId
        ccRemainingCount
        checkin
        checkout
        checkinTime
        checkoutTime
        pahAmount
        bookingName
        bookingDate
        paymentStatus
        confirmStatus
        bookingStatus
        specialRequests
        hotelCode
        vendorBookingId
        partnerId
        partnerName
        brand
        salesChannel
        customerType
        cancellationPolicy
        paymentMode
        flags
        gstnInfo
        rateBreakup
        rooms
        cancellationInfo
        currency
        parentVendorBookingId
        rpBookingModel
        voyagerId
        uId
        hotelGstn
        frnCode
        prebuyOrderId
        hotelCloudVendorBookingId
        hotelCloudParentVendorBookingId
        oldVendorBookingId
        amendType
      }
    }
  }
}`;

export const BOOKING_VOUCHER_DETAILS = `query GroupBookingDetails(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    groupedBookingDetails {
      bookingVoucherDetails{
        hotelierEmailList
        voucherEmailList{
          from
          resp
          sentStatus
          sentAt
        }
        cancelled
      }
    }
  }
}`;

export const UPLOAD_GSTN = `mutation RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $endpointRequests) {
    uploadGSTNInvoice {
     modelType1{
      success,
        message,
        url
       
    }
    }
  }
}`;

export const VIEW_GI_BOOKING_VOUCHER = `query RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
    viewGIBookingVoucher {
      modelType1 {
      success
        message{
           download_url
        }
      }
    }
  }
}`;

export const VIEW_MMT_BOOKING_VOUCHER = `query RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
    viewMMTBookingVoucher {
      modelType1 {
      success
        message{
           download_url
        }
      }
    }
  }
}`;

export const RESEND_GI_BOOKING_VOUCHER = `mutation RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $endpointRequests) {
    resendGIBookingVoucher {
     modelType1{
      success
      message
    }
    }
  }
}`;

export const RESEND_MMT_BOOKING_VOUCHER = `mutation RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $endpointRequests) {
    resendMMTBookingVoucher {
     modelType1{
      success
       
    }
    }
  }
}`;

export const GET_REJECT_REASONS = `query GetBookingReasons($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests){
    getBookingStatusReasons{
      modelType1{
        reasons
        message
        success
      }
    }
  }
}`;

export const HOTEL_BOOKING_STATUS = `mutation UpdateBookingStatus($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData: $endpointRequests) {
    hotelBookingStatusUpdateResponse{
      modelType1 {
        message
        success
        bookingid
        booking_status
      }
    }
  }
}`;

export const CREATE_NOTE = `mutation RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
  rawEndpoint(
      endpointRequestData: $rawEndpointsRequestData
  ) {
    hostAppCreateNote {
      modelType1 {
        success
        message
        data{
          note_ids
        }
      }
    }
  }
}`;

export const UPDATE_NOTE = `mutation RawResponse($rawEndpointsRequestData : RawEndpointsRequestData) {
rawEndpoint(
endpointRequestData: $rawEndpointsRequestData
) {
    hostAppUpdateNote{
	    modelType1 {
        success
        message
        data{
          note_id
          is_edit_success
          is_delete_success
      }
  }
}
}
}`;

export const GET_PENDING_TASKS_VIEW = `query BookingRevampPendingTasks(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    getPendingTasks {
      unAcknowledged {
        data
      }
      refunds {
        data
      }
      invoices {
        data
      }
      rejectedInvoices{
        data
      }
      checkinOrNoShow{
        data
      }
        
    }
  }
}`;

export const GET_CORPORATE_CHECKIN_VIEW = `query getCorporateCheckinDatewise(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    getPendingTasks {
      corporateCheckinDateWise{
        data
      }
    }
  }
}`;

export const GET_CORPORATE_CHECKOUT_VIEW = `query getCorporateCheckOutDatewise(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    getPendingTasks {
      corporateCheckOutDateWise
      {
        data
      }
    }
  }
}`;

export const GET_CORPORATE_BOOKING_VIEW = `query getCorporateBookingDatewise(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    getPendingTasks {
      corporateBookingDateWise
      {
        data
      }
    }
  }
}`;

export const GET_ALL_BOOKING_CHECKIN_VIEW = `query getAllBookingsCheckinDatewise(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    getPendingTasks {
      allBookingsCheckinDateWise
      {
        data
      }
    }
  }
}`;

export const GET_ALL_BOOKING_CHECKOUT_VIEW = `query getAllBookingsCheckoutDatewise(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    getPendingTasks {
      allBookingsCheckOutDateWise{
        data
      }
    }
  }
}`;

export const GET_ALL_BOOKING_DATE_VIEW = `query getAllBookingsDatewise(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
    getPendingTasks {
      allBookingsDateWise
      {
        data
      }
    }
  }
}`;

export const GET_PENDING_TASK_DETAILS_BY_BOOKING_IDS = `query AnalyticsDocumentV2($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
    documentsAnalyticsV2APIResponse {
      modelType1 {
      status
        status_code
        message
        data{
          doc_count
          group_count
          docs{
            totalOccupancy
            netAmount
            hotelId
            vendorId
            createdOn
            cancelCharges
            roomName
            bookingId
            checkin
            vendorBookingId
            currency
            noOfRoomsx
            checkout
            user{
              signedInUserId
              name
            }
          }
        }
      }
    }
  }
}`;

export const GET_CHANNELWISE_BOOKING_COUNT = `query ChannelWiseBookingCount(
  $endpointRequestData: EndpointRequestData,
  $grpcEndpointRequestData: GRPCEndpointRequestData
) {
  altAccoActivityAndPerformanceDashboard(
    endpointRequestData: $endpointRequestData,
    grpcEndpointRequestData: $grpcEndpointRequestData
  ) {
    groupWiseOccupancyShare{
      status
      message
      data
    }
  }
}`;

export const GET_MEALPLAN_COUNT = `query MealplanCount(
  $endpointRequestData: EndpointRequestData,
  $grpcEndpointRequestData: GRPCEndpointRequestData
) {
  altAccoActivityAndPerformanceDashboard(
    endpointRequestData: $endpointRequestData,
    grpcEndpointRequestData: $grpcEndpointRequestData
  ) {
    groupWiseOccupancyShare{
      status
      message
      data
    }
  }
}`;

export const GET_GSTN_INVOICE_COUNT = `query ChannelWiseBookingCount(
  $endpointRequestData: EndpointRequestData,
  $grpcEndpointRequestData: GRPCEndpointRequestData
) {
  altAccoActivityAndPerformanceDashboard(
    endpointRequestData: $endpointRequestData,
    grpcEndpointRequestData: $grpcEndpointRequestData
  ) {
    groupWiseOccupancyShare{
      status
      message
      data
    }
  }
}`;

export const GET_BOOKING_PERFORMANCE_COUNT = `query BookingRevampDetails(
  $businessLogicData: EndpointBusinessLogicData
  $endpointRequests: EndpointRequestData
) {
  bookingRevamp(
    businessLogicData: $businessLogicData
    endpointRequestData: $endpointRequests
  ) {
   bookingPerformanceData{
    netRevenue
    occupancyShare
    averageRoomRate
  }
  }
}`;

export const GET_PERSUASION_BOOKING_NETAMOUNT = `query AnalyticsHistogramRawResponse($rawEndpointRequestData: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData)
  { 
    analyticsHistogram {
           modelType1 {
                status
                data     
                message
            }
         }
     }
  }
`;

//  Below fields need to be added in below query
//  success_booking_ids;
//  success_vendor_parent_ids;
export const UPDATE_BOOKING_STATUS = `query RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
   BookingHotelBookingStatusV1{
    modelType1{
    booking_status
      message
      bookingid
      error_code
      success
      booking_status
      success_booking_ids
      success_vendor_parent_ids
    }
  }
  }
}`;

export const GET_WAIVER_DETAILS = `query RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
   BookingWaiverDetaisV1{
    modelType1{
    message
      success
      waiver_details{
        bookingid
        waiver_requested
        waiver_message
        waiver_percent
        waiver_status
        cancellation_charges
        roomtypename
        roomtypecode
        bookingamount
        requested_days_ago
        waiver_message_map{
          id
          value
          label
        }
        roomstay
        waiver_document_keys
        
      }
    }
  }
  }
 
}`;

export const INITIATE_WAIVER = `mutation InitiateWaiver($rawEndpointsRequestData:RawEndpointsRequestData) {
  rawEndpoint(endpointRequestData:$rawEndpointsRequestData){
   initiateWaiver {
      modelType1 {
        success
        booking_id
        waiver_percent
        waiver_status
        waiver_message
        message
      }
    }
  }
}`;

export const OPPORTUNITIES_QUERY = `
query RawResponse($endpointRequests: RawEndpointsRequestData) {
  rawEndpoint(rawEndpointRequestData: $endpointRequests) {
    GetOpportunityFlagsBookingV3APIResponses {
      modelType1 {
        success
        error
        data
      }
    }
    analyticsPersuasion {
      modelType1 {
        status
        message
        data {
          persuasions {
            consumers {
              web {
                persuasion_title
                message
              }
            }
          }
        }
      }
    }
   sponsoredList {
      modelType1 {
        requestingLob
        requestId
        pageTypeEnum
        persuasionList {
          persuasion {
            htmlContent
            icon
            headerText
            cta {
              redirectTxt
              action
            }
          }
          revenueGraph {
            text
            toolTipData
            startIndicator
            legend {
              priorCampaignStart
              duringCampaign
              estimatedWithoutCampaign
            }
            revenueList {
              label
              priorCampaignStart
              duringCampaign
              estimatedWithoutCampaign
            }
            revenuePersusation {
              text
            }
          }
        }
      }
    }
  }
 
}
`;

export const HOURLY_ROOM_CONFIG_QUERY = `query Dayuse(
  $roomConfigVariables: RawEndpointsRequestData
  $hotelConfigVariables: RawEndpointsRequestData
) {
  roomConfig: ${roomConfigQuery},
  hotelConfig: ${hotelConfigQuery}
}
`;

export const ANALYTICS_DOCS_QUERY = `
query AnalyticsDocumentsRawResponse(
  $rawEndpointRequestData: RawEndpointsRequestData
) {
  rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) 
    {
      analyticsDocuments 
      { 
        modelType1 
        { 
          status 
          data 
          message 
        } 
      }
    }
}
`;

export const BOOKINGS_LIST_DATA_MUTATION = `
mutation BookingListingGenericRawResponse(
  $rawEndpointRequestData: RawEndpointsRequestData
) {
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) 
    {
      bookingListingGeneric 
      { 
        modelType1 
        { 
          status 
          data 
          message 
        } 
      }
    }
}
`;

export const CONTACT_LIST_DATA_QUERY = `
   query FetchCustomerDetailsResponse(
     $endpointBusinessLogicData: EndpointBusinessLogicData
   ) {
          fetchCustomerDetails(businessLogicData: $endpointBusinessLogicData)
          {    
            fetchCustomerDetails {  
              success  
              data
              {
                 bookingemail
                 bookingphone
                 bookingid
                 cancelled
              }   
           }  
      }
}`;

export const PAYMENT_DETAILS_QUERY = `
      query BookingMoPayment(
          $rawEndpointRequestData: RawEndpointsRequestData
      ) {
           rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) 
           { 
              MOPaymentDetailsV1 {
                modelType1 {
                   message
                   success
                   data{
                    hotelcode 
                    currency
                    gstn_invoice_flag
                    payment_status
                    booking_id
                    nett_payable
                    is_vcc
                    gstn_invoice_amount
                    vendorbookingid
                    details{
                      fine_print
                      timestamp
                      ref_id_label
                      ref_id
                      currency
                      amount
                      detail_type
                      amount_label
                      date_label
                      payment_type
                    }
                  } 
                }
               }
            }
    }`;

export const PAYMENT_DETAILS_QUERY_VCC = `
      query VccGetRealization($rawEndpointsRequestData: RawEndpointsRequestData)
          {
           rawEndpoint(rawEndpointRequestData: $rawEndpointsRequestData) 
            {
              vccGetRealization {
                 modelType1 {
                    responseMessage
                    responseStatus                                  
                    responseData {
                    totalAmount                                   
                    chargedAmount
                    currency
                    transactions {
                      merchantCurrency
                      merchantAmount
                      transactionTime
                      settled
                      merchantName
                      }
                    }                                 
                responseErrors 
                }
              }
            }
        }`;

export const GET_FRN_DETAILS_QUERY = `
      query RpcGetFRNDetail($endpointRequestData: EndpointRequestData) {
        getFRN(endpointRequestData: $endpointRequestData) {
          error {
            code
            message
            }
            frnInfo {
              frnCode
              startDate
              endDate
              maxPerDay
              maxTotal
              roomName
              status
              ratePlanDataList {
                ratePlanCode
                ratePlanName
              }
            }
          }
        }`;
