import { createRoot } from 'react-dom/client';
import { checkAndLogGtmLoginEvent } from 'app/hotel/legacyParity';

import Main from './MainWrapper';

const container = document.getElementById('new-react-shell');
const root = createRoot(container);

checkAndLogGtmLoginEvent();

root.render(<Main />);
