import { ISTRINGS } from 'interfaces/Strings';
import { getFutureDate } from 'utilities/DateUtils';
import { inventoryMaxFutureDays } from '../../constants';
import { ActiveRateplan } from 'interfaces/InventoryContextInterface';

export const synxisAllCTType = 'all';
export const B2C = 'b2c';
export const ALL = 'all';
export const GRP = 'grp';
export const HOURLY = 'hourly';
export const RTB_MODEL = 'RTB_EMAIL';
export const HOTEL_TRAVEL = 'hotel_travel';

export const funnels = [
  { label: 'B2C', key: B2C },
  { label: 'MyBiz', key: 'corporate' },
  { label: 'B2B', key: 'b_2_b' },
  { label: 'Corporate RFP', key: 'corporate_rfp' },
  { label: 'Bundled', key: 'bundled' },
  { label: 'GCC POS', key: 'gcc' },
  { label: 'My Partner', key: 'b2a' },
];

const resellerFunnels = [
  { label: 'Base Rates', key: B2C },
  { label: 'Corporate Special Rates', key: 'corporate' },
];
export const one = '1';
export const getFunnels = (
  isDomestic: boolean,
  isResellerView: boolean,
  flatActiveRateplans: ActiveRateplan[],
) => {
  if (isResellerView) {
    const atLeastOneHotelTravelRPPresent = flatActiveRateplans.some(
      rp => rp.isHotelTravelRP,
    );

    if (atLeastOneHotelTravelRPPresent) {
      return [
        ...resellerFunnels,
        { label: 'HotelCloud Special Rates', key: HOTEL_TRAVEL },
      ];
    }
    return resellerFunnels;
  }
  if (isDomestic) {
    return funnels;
  }
  return [...funnels, { label: 'INDIA POS', key: 'IN-POS' }];
};

export const getFunnelMessage = (STRINGS: ISTRINGS['Inventory']) => ({
  b2c: STRINGS.FUNNEL_MESSAGE.B2C,
  corporate: STRINGS.FUNNEL_MESSAGE.CORPORATE,
  b_2_b: STRINGS.FUNNEL_MESSAGE.B2B,
  corporate_rfp: STRINGS.FUNNEL_MESSAGE.CORPORATE_RFP,
  bundled: STRINGS.FUNNEL_MESSAGE.BUNDLED,
  gcc: STRINGS.FUNNEL_MESSAGE.GCC,
  b2a: STRINGS.FUNNEL_MESSAGE.B2A,
  'IN-POS': STRINGS.FUNNEL_MESSAGE.INDIA_POS,
});

export const invDateFormat = 'YYYY-MM-DD';
export const inventoryDaysCount = 7;
export const editAllowDate = getFutureDate(new Date(), -1);
export const inventoryMinDate = getFutureDate(new Date(), -365);
/**
 * Subtracting the count, since those many days
 * will be shown post selected date
 */
export const inventoryMaxDate = getFutureDate(
  new Date(),
  inventoryMaxFutureDays - inventoryDaysCount,
);

//Restriction thresholds
export const maxCutoffThreshold = inventoryMaxFutureDays * 24;
export const maxLosThreshold = 28;
export const beforeCheckInMax = 2160;
export const afterCheckInMax = 90;

export const restrictionDisplayKey = {
  ctad: 'ctad',
  minLos: 'minLos',
  maxLos: 'maxLos',
  minLosCheckin: 'minLosCheckin',
  maxLosCheckin: 'maxLosCheckin',
  minCutoff: 'minCutoff',
  maxCutoff: 'maxCutoff',
  fpLos: 'fpLos',
};

export const blockRatesOptions = [
  { label: 'Block Rates', value: 'block' },
  { label: 'Unblock Rates', value: 'unblock' },
];

export const defaultRatesLabels = {
  childRates: 'Child Rates',
  guestRates: 'Guest Rates',
};

export const inventoryActionInfo = {
  source: 'host',
  sub_source: 'update',
  reason: '',
  linked_room: '',
  user_id: 0,
};
