import {
  ControlledDropdown,
  ControlledRadio,
  Typography,
} from 'components/latest-core';
import { LOSFormNames } from 'modules/promotions-and-coupons/PnC.constants';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLangStrings } from 'utilities/CustomHooks';

type OptionType = {
  label: string;
  value: string | number;
};

type MinimumStayDurationProps = {
  CUSTOM_OPTIONS: Map<string, string>;
  recommendedId: string;
  minStayDurationOptions: OptionType[];
  isLosPackageFlow?: boolean;
};

const MinimumStayDuration = (props: MinimumStayDurationProps) => {
  //props
  const {
    CUSTOM_OPTIONS,
    recommendedId,
    minStayDurationOptions: radioOptions,
    isLosPackageFlow = false,
  } = props;

  //strings
  const [{ MIN_STAY_DURATION }, { LABELS }] =
    useLangStrings<'Promotions'>('Promotions');

  //rhf
  const { control } = useFormContext();
  const [selectedDays] = useWatch({
    control,
    name: [LOSFormNames.minStayDuration, LOSFormNames.customStayDuration],
  });

  //handlers
  const minStayDurationOptions = useMemo(() => {
    const rightComponent = (
      <div className="p-1 bg-color-graphAccent1/20 rounded-md ml-2 -mb-1">
        <Typography variant="sectionHeading" themeColor="graph.accent1">
          {LABELS.RECOMMENDED}
        </Typography>
      </div>
    );
    const options = radioOptions.map((option, index) => {
      return {
        ...option,
        rightComponent:
          index === Number(recommendedId) - 1 ? rightComponent : null,
      };
    });
    return options;
  }, [radioOptions]);

  const customStayDurationOptions = useMemo(() => {
    const optionsArr = [];
    CUSTOM_OPTIONS.forEach((value, key) => {
      optionsArr.push({
        label: value,
        value: key,
      });
    });

    return optionsArr;
  }, [CUSTOM_OPTIONS]);

  return (
    <div>
      <Typography variant="h3" className="mb-1">
        {MIN_STAY_DURATION.TITLE}
      </Typography>
      {isLosPackageFlow && (
        <Typography
          variant="subtitle1"
          className="-mt-1 mb-4 !text-text-secondary"
        >
          {MIN_STAY_DURATION.LOS_PACKAGE_SUBTITLE}
        </Typography>
      )}

      <ControlledRadio
        options={minStayDurationOptions}
        control={control}
        name={LOSFormNames.minStayDuration}
      />
      <div className="pl-4 ml-6 bg-color-light rounded-md py-2">
        <Typography>{MIN_STAY_DURATION.LABEL}</Typography>
        <div className="w-[280px]">
          <ControlledDropdown
            control={control}
            name={LOSFormNames.customStayDuration}
            options={customStayDurationOptions}
            disabled={Number(selectedDays) !== 0}
            disableClearable
            defaultValue={customStayDurationOptions[0]}
            variant="default"
          />
        </div>
      </div>
    </div>
  );
};

export default MinimumStayDuration;
