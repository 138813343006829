import { routes } from 'app/routes';
import {
  MMTIndiaPos,
  MMTblack,
  MMTgulf,
  MMTholiday,
  MMTMember,
  MMTmobile,
  MMTselect,
  Mybiz,
  Mypartner,
} from 'assets/common';
import {
  CouponIcon,
  CugIcon,
  CustomPromoIcon,
  CustomPromotionIconSvg,
  DatePromoIcon,
  DaybasedPromoIcon,
  EarlybirdPromoIcon,
  FreenightPromoIcon,
  LastminutePromoIcon,
  LongstayPromoIcon,
  PromotionIcon,
} from 'assets/modules/promotions';
import {
  DEAL_TYPE,
  PNC_JUMBO_TAB,
  PNC_TAB,
  PNC_TABLE_KEYS,
  PERFORMANCE_TIME,
  OFFER_VALUE_SEGMENT_TYPE,
} from 'interfaces/PncContextInterface';
import { newPromotionTypes } from './Promotions/Promotions.constants';
import { CreationSubTabsType } from './PnCTypes';

export const apiDateFormat = 'YYYY-MM-DD';
export const minDuration = 30;
export const expiringSoonDuration = 7;
export const millisecondsInADay = 1000 * 60 * 60 * 24;

export const mmtBlackList = ['MMT BLACK', 'MMT BLACK1', 'MMT BLACK2'];
export const mmtSelectList = ['MMT SELCT', 'MMT SELCT1', 'MMT SELCT2'];

export const DRAWER_MAPPING = {
  mobile: 'MOBILE',
  gcc: 'gcc',
  mmtBlack: 'MMT BLACK',
  mmtSelect: 'MMT SELCT',
  corporate: 'corporate',
  geography: 'GEOGRAPHY',
  b2a: 'b2a',
  b_2_b: 'b_2_b',
  b2b: 'b2b',
  holidays: 'Holidays',
  member: 'member',
  vistarafly: 'vistarafly',
};

export const SEGMENT_MAPPING_CUG = {
  gcc: 'GCC',
  mmtBlack: ['MMT_BLACK', 'MMT_BLACK1', 'MMT_BLACK2'],
  mmtSelect: ['MMT_SELECT', 'MMT_SELECT1', 'MMT_SELECT2'],
  mobile: 'MOBILE',
  holidays: 'HOLIDAYS',
  corporate: 'CORPORATE',
  b2a: 'B2A',
  geography: 'GEOGRAPHY',
  member: 'member',
};

export const CUG_ICON_MAPPING = {
  mmtSelect: MMTselect,
  mmtBlack: MMTblack,
  gcc: MMTgulf,
  corporate: Mybiz,
  b2a: Mypartner,
  b2b: Mypartner,
  b_2_b: Mypartner,
  mobile: MMTmobile,
  geography: MMTIndiaPos,
  holidays: MMTholiday,
  member: MMTMember,
  vistarafly: CustomPromotionIconSvg,
};

export const NO_END_DATE = '2099-01-01';

export const CUSTOMER_TYPE_MAP = {
  Preferred: 'Tier 1',
  Elite: 'Tier 2',
  Exclusive: 'Tier 3',
};

export const SELECT_CUSTOMER_TYPE_MAP = {
  Bronze: 'Tier 1',
  Silver: 'Tier 2',
  Gold: 'Tier 3',
};

export const mmtBlackSegment = {
  ELITE: 'elite',
  EXCLUSIVE: 'exclusive',
  PREFERRED: 'preferred',
};

export const mmtSelectSegment = {
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
};

export const noEndDate = new Date(2098, 11, 31, 0, 0, 0);
export const dateAPIFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'T00:00:00.000Z';
// eslint-disable-next-line quotes
export const listingDateFormat = "Do MMM [']YY";

export const DEALS_JUMBO_TABS = [
  {
    label: 'Create New Promotion',
    value: PNC_JUMBO_TAB.create,
    path: routes.businessBooster.pnc.create,
    active: true,
  },
  {
    label: 'My Promotions',
    value: PNC_JUMBO_TAB.all,
    path: routes.businessBooster.pnc.active,
    active: true,
  },
];

export const DEALS_TABS = [
  {
    label: 'Active',
    value: PNC_TAB.active,
    count: 0,
    path: routes.businessBooster.pnc.active,
  },
  {
    label: 'Expired',
    value: PNC_TAB.expired,
    count: 0,
    path: routes.businessBooster.pnc.expired,
  },
];

export const DEALS_TABLE_HEADINGS = [
  {
    title: 'Promotion Name',
    description: '',
    label: PNC_TABLE_KEYS.dealName,
    icon: null,
    width: 'w-[170px]',
  },
  {
    title: 'Promotion Type',
    description: '',
    label: PNC_TABLE_KEYS.dealType,
    icon: true,
    width: 'w-[100px]',
  },
  {
    title: 'Booking Date',
    description: '',
    label: PNC_TABLE_KEYS.bookingDate,
    icon: null,
    width: 'w-[100px]',
  },
  {
    title: 'Stay Date',
    description: '',
    label: PNC_TABLE_KEYS.stayDate,
    icon: null,
    width: 'w-[100px]',
  },
  {
    title: 'Discount',
    description: 'All user (+ logged in)',
    label: PNC_TABLE_KEYS.discount,
    icon: null,
    width: 'w-[108px]',
  },
  {
    title: 'Expiring',
    description: '',
    label: PNC_TABLE_KEYS.expiringIn,
    icon: true,
    width: 'w-[100px]',
  },
  {
    title: 'De-activated On',
    description: '',
    label: PNC_TABLE_KEYS.deactivatedOn,
    icon: false,
    width: 'w-[100px]',
  },
  {
    title: 'Room Nights',
    description: '',
    label: PNC_TABLE_KEYS.roomNights,
    icon: true,
    width: 'w-[80px]',
  },
  {
    title: 'Revenue',
    description: '',
    label: PNC_TABLE_KEYS.revenue,
    icon: true,
    width: 'w-[100px]',
  },

  {
    title: '',
    description: '',
    label: PNC_TABLE_KEYS.cta,
    icon: null,
    width: 'w-[100px]',
  },
];

export const dealTypeDesc = {
  promotion: 'Promotions (Tier 1)',
  cug: 'Special Audience Promotions (Tier 2)',
  coupon: 'Coupons (Tier 3)',
};

export const segmentOrder = {
  [`${OFFER_VALUE_SEGMENT_TYPE.b2a}`]: 0,
  [`${OFFER_VALUE_SEGMENT_TYPE.b2b}`]: 1,
  [`${OFFER_VALUE_SEGMENT_TYPE.b_2_b}`]: 2,
  [`${OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK}`]: 3,
  [`${OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1}`]: 4,
  [`${OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2}`]: 5,
  [`${OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT}`]: 6,
  [`${OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT1}`]: 7,
  [`${OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT2}`]: 8,
  [`${OFFER_VALUE_SEGMENT_TYPE.GEOGRAPHY}`]: 9,
  [`${OFFER_VALUE_SEGMENT_TYPE.corporate}`]: 10,
  [`${OFFER_VALUE_SEGMENT_TYPE.gcc}`]: 11,
  [`${OFFER_VALUE_SEGMENT_TYPE.Holidays}`]: 12,
  [`${OFFER_VALUE_SEGMENT_TYPE.MOBILE}`]: 13,
  [`${OFFER_VALUE_SEGMENT_TYPE.b2c}`]: 14,
  [`${OFFER_VALUE_SEGMENT_TYPE.bundled}`]: 15,
  [`${OFFER_VALUE_SEGMENT_TYPE.loggedin}`]: 16,
  [`${OFFER_VALUE_SEGMENT_TYPE.Affiliate}`]: 17,
};

export const segmentDesc = {
  'MMT BLACK': 'Preferred',
  'MMT BLACK1': 'Gold',
  'MMT BLACK2': 'Platinum',
  Affliate: 'Affliate',
  'MMT SELCT': 'Bronze',
  'MMT SELCT1': 'Silver',
  'MMT SELCT2': 'Gold',
};

export const mmtBlackSegments1_2 = [
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1,
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2,
];

export const onlyMmtBlackSegments = [
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK,
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1,
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2,
];

export const mmtBlackSegments = [
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK,
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1,
  OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2,
  OFFER_VALUE_SEGMENT_TYPE.Affiliate,
];

export const mmtSelectSegments = [
  OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT,
  OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT1,
  OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT2,
];

export enum CTAS {
  EXTEND = 'EXTEND',
  MODIFY = 'MODIFY',
  REACTIVATE = 'REACTIVATE',
  OFFER_AGAIN = 'OFFER_AGAIN',
  DUPLICATE = 'DUPLICATE',
  DEACTIVATE = 'DEACTIVATE',
  WITHDRAW = 'WITHDRAW',
  BLACK_WITHDRAW = 'BLACK_WITHDRAW',
}

export const ctaDesc = {
  EXTEND: 'View/Extend',
  MODIFY: 'View/Modify',
  OFFER_AGAIN: 'Offer Again',
  REACTIVATE: 'Renew',
  DUPLICATE: 'Create Another',
  DEACTIVATE: 'De-Activate Discount',
  WITHDRAW: 'Withdraw from this sale',
  BLACK_WITHDRAW: 'Withdraw',
};

export const createIcon = {
  promotion: PromotionIcon,
  cug: CugIcon,
  coupon: CouponIcon,
  date: DatePromoIcon,
  lastminute: LastminutePromoIcon,
  earlybird: EarlybirdPromoIcon,
  customised: CustomPromoIcon,
  day: DaybasedPromoIcon,
  los: LongstayPromoIcon,
  fnd: FreenightPromoIcon,
};

export const defaultDealsMetaData = {
  isNewUser: false,
  baseOfferValue: 0,
  affiliates: [],
  PROMOTIONS: null,
  CUG: null,
  COUPONS: null,
  fetchingMetaData: true,
  apiFailed: false,
};

export const defaultDealsData = {
  offerDataList: [],
  hotelPerformanceData: {
    hotelRoomNights: 0,
    hotelRevenue: 0,
  },
  statusCountData: {
    active: 0,
    expired: 0,
    inActive: 0,
  },
  fetchingAllPnc: true,
  apiFailed: false,
};

export const defaultFilterData = null;
export const defaultSortingOrder = {
  label: null,
  order: null,
};

export const defaultPagination = {
  pageNo: 1,
  size: 20,
};

export const defaultPageNo = 1;
export const SHOW_PERFORMANCE_DATA = true;

export const cugReverseMap = {
  GEOGRAPHY: 'geography',
  corporate: 'corporate',
  b2a: 'b2a',
  gcc: 'gcc',
  Holidays: 'holidays',
  'MMT BLACK': 'mmtBlack',
  'MMT SELECT': 'mmtSelect',
  MMT_BLACK: 'mmtBlack',
  MMT_SELECT: 'mmtSelect',
  MOBILE: 'mobile',
  member: 'member',
} as const;

export const cugDisplayName = {
  geography: 'India POS',
  'IN-POS': 'India POS',
  GEOGRAPHY: 'India POS',
  corporate: 'MyBiz',
  b2a: 'MyPartner',
  gcc: 'Gulf Market',
  Holidays: 'Holiday Packages',
  holidays: 'Holiday Packages',
  'MMT BLACK': 'MMTBlack/goTribe',
  'MMT SELCT': 'MMT Select(Gulf Market)',
  MOBILE: 'Mobile phones',
  mobile: 'Mobile phones',
};

export const promotionRoutes = [
  { path: routes.businessBooster.pnc.basic, type: newPromotionTypes.DATE },
  {
    path: routes.businessBooster.pnc.custom,
    type: newPromotionTypes.CUSTOM,
  },
  {
    path: routes.businessBooster.pnc.dayBased,
    type: newPromotionTypes.DAY,
  },
  {
    path: routes.businessBooster.pnc.freeNight,
    type: newPromotionTypes.FND,
  },
  {
    path: routes.businessBooster.pnc.longStay,
    type: newPromotionTypes.LOS,
  },
  {
    path: routes.businessBooster.pnc.lastMinute,
    type: newPromotionTypes.LMD,
  },
  {
    path: routes.businessBooster.pnc.earlyBird,
    type: newPromotionTypes.EBD,
  },
];

export const dealRoutes = [
  {
    path: routes.businessBooster.pnc.active,
    activeJumboTab: PNC_JUMBO_TAB.all,
    activeDealsTab: PNC_TAB.active,
  },
  {
    path: routes.businessBooster.pnc.expired,
    activeJumboTab: PNC_JUMBO_TAB.all,
    activeDealsTab: PNC_TAB.expired,
  },
];

export const couponPayloadKeyMap = {
  bookingdatestart: 'bookingDateStart',
  bookingdateend: 'bookingDateEnd',
  checkindatestart: 'checkinDateStart',
  checkoutdateend: 'checkoutDateEnd',
  checkinblackoutdates: 'checkinBlackoutDates',
  offer_value: 'offerValue',
};

export const FilterDealTypeList = [
  {
    type: DEAL_TYPE.PROMOTIONS,
    label: dealTypeDesc.promotion,
  },
  {
    type: DEAL_TYPE.CUG,
    label: dealTypeDesc.cug,
  },
  {
    type: DEAL_TYPE.COUPONS,
    label: dealTypeDesc.coupon,
  },
];

export const FilterTimePeriodList = [
  {
    type: PERFORMANCE_TIME.THIS_WEEK,
    label: 'This week',
  },
  {
    type: PERFORMANCE_TIME.LAST_7,
    label: 'Last 7 days',
  },
  {
    type: PERFORMANCE_TIME.LAST_14,
    label: 'Last 14 days',
  },
  {
    type: PERFORMANCE_TIME.THIS_MONTH,
    label: 'This month',
  },
  {
    type: PERFORMANCE_TIME.LAST_MONTH,
    label: 'Last month',
  },
  {
    type: PERFORMANCE_TIME.LAST_180,
    label: 'Last 180 days',
  },
  {
    type: PERFORMANCE_TIME.LAST_365,
    label: 'Last 365 days',
  },
  {
    type: PERFORMANCE_TIME.ALL_TIME,
    label: 'All Time',
  },
];

export enum ADVANCE_FILTER {
  dealName = 'dealName',
  bookingDateRange = 'bookingDateRange',
  stayDateRange = 'stayDateRange',
}
export const AdvanceFilterList = [
  {
    value: ADVANCE_FILTER.dealName,
    label: 'Promotion Name',
    downComponent: null,
  },
  {
    value: ADVANCE_FILTER.bookingDateRange,
    label: 'Booking Date Range',
    downComponent: null,
  },
  {
    value: ADVANCE_FILTER.stayDateRange,
    label: 'Stay Date Range',
    downComponent: null,
  },
];

export enum FILTER_KEYS {
  DealName = 'dealName',
  BookingDateStart = 'bookingStartDate',
  BookingDateEnd = 'bookingEndDate',
  CheckInDateStart = 'checkinDateStart',
  CheckOutDateEnd = 'checkoutDateEnd',
  DealTypeList = 'dealTypeList',
  PerformanceTimePeriod = 'performanceTimePeriod',
  OfferCodeList = 'offerCodeList',
}

export const dealTypeMap = {
  promotion: 'PROMOTIONS',
  cug: 'CUG',
  coupon: 'COUPONS',
};

export const promotionSegments = {
  mobile: 'Mobile',
  coupon: 'Coupon',
};

//confirmed by Anuj
export const CUG_NAME_MAP = {
  'mmt black2': 'MMT Black/Go Tribe',
  'mmt black1': 'MMT Black/Go Tribe',
  'mmt black': 'MMT Black/Go Tribe',
  mobile: 'Mobile Phone',
  b2a: 'My Partner',
  gcc: 'Gulf Market',
  corporate: 'MyBiz',
  holidays: 'Holidays Package',
  b2c: 'B2C',
  bundled: 'Bundled',
  geography: 'Geography',
  'in-pos': 'India-Pos',
  affiliate: 'Affiliate',
  loggedin: 'Loggedin',
  coupon: 'Coupon',
  'mmt selct': 'Gulf Market', //gulf market
  'mmt selct1': 'Gulf Market',
  'mmt selct2': 'Gulf Market',
  member: 'Member',
};

export const tableRows = {
  yourSellingRate: 'your_selling_rate',
  toBeCreatedPromo: 'to_be_created_promo',
  discount: 'discount',
  counterDiscount: 'counter_discount',
  cug: 'cug',
  coupoun: 'coupon',
  postDealPrices: 'post_deal_prices',
};

export const promotionCreationFormNames = {
  promotionName: 'promotionName',
  extraDiscountForLogin: 'extraDiscountForLogin',
  discountForNonLogin: 'discountForNonLogin',
  bookablePeriod: 'bookablePeriod',
  allRoomAndRp: 'allRoomAndRp',
};

export const postPromotionErrorMap = {
  PPP_1001: 'PPP_1001',
  163: '163',
  102: '102',
};

export const maxAdults = '10';
export const maxChildren = '2';

export const pageNameOmniture = {
  promtionCreationPage: 'promotion_creation_page: ',
  lastminute: 'lastminute',
  earlybird: 'eralybird',
  creationBooster: 'creation_booster',
  postDealPrice: 'post_deal_price',
  allPromotions: 'all_promotions',
};
export const validPaths = [
  routes.businessBooster.pnc.base,
  routes.businessBooster.pnc.active,
  routes.businessBooster.pnc.expired,
];

export const offerNotValidReasons = {
  anotherOfferApplied: 'another_offer_is_alreayd_applied',
  notValidOffer: 'not_valid_offer',
  offerIsGood: 'offer_is_good',
};

export const MIN_STAY_DURATION_COUNT = 25; //5 days or more to 30 days or more

export const LOSFormNames = {
  minStayDuration: 'minStayDuration',
  moreStayDuration: 'moreStayDuration',
  freeNightRule: 'freeNightRule',
  stayNights: 'stayNights',
  freeNights: 'freeNights',
  customStayDuration: 'customStayDuration',
  freeNightRules: 'freeNightRules',
} as const;

export const createLosFrnLink = (type: CreationSubTabsType) => {
  return `${routes.businessBooster.pnc.longStay}?type=${type}`;
};

export const MIN_STAY_NIGHTS = 2;
export const MIN_FREE_NIGHTS = 1;

export const omnitureNameMap = {
  [newPromotionTypes.EBD]: 'earlybirdpromotion',
  [newPromotionTypes.LMD]: 'lastminutedeal',
  [newPromotionTypes.LOS]: 'longstaypromotion',
};

export const newDisplayDateFormat = 'Do MMM’YY';
