export const ModalSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  CUSTOM: 'custom',
} as const;

export const ModalVariant = {
  DEFAULT: 'default',
  CONFIRM: 'confirm',
  INFO: 'info',
} as const;
