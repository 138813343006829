// @ts-nocheck
import { doQuery } from 'base/GraphQLClient';
import { getValue } from 'utilities/Utils';
import axios, { apiWrapper, showError } from '../../base/APIClient';
import { REVIEWS } from '../../base/Url';
import { MergedResponse, ReviewsResponse } from './Revies.types';
import {
  GET_HEADER_COUNTS_QUERY,
  GET_UCG_COUNT_AND_REVIEWS,
} from './ReviewQueryClient';
import { sources, subtabs } from './reviews.constants';

export const getHeaderCountAndReviews = (args: {
  mmtId: string;
  subtab: typeof subtabs[keyof typeof subtabs];
  vendorTab: string;
  limit: number;
  offset: number;
  count: number;
  fromTopLevel: boolean;
  hotelCode: string | number;
}): Promise<MergedResponse> => {
  const {
    mmtId,
    subtab,
    vendorTab,
    limit,
    offset,
    fromTopLevel = false,
    hotelCode,
  } = args;

  const source = vendorTab === 'goibibo' ? sources.GI : sources.MMT;

  const queryParams = {};
  queryParams.source = source;
  queryParams.limit = limit;
  queryParams.offset = offset;

  if (subtab === subtabs.replynow) {
    queryParams.replyPending = 'true';
  } else if (subtab === subtabs.replied) {
    queryParams.replyPending = 'false';
  } else if (subtab === subtabs.booking) {
    queryParams.fromBooking = 'true';
  } else if (subtab === subtabs.nonbooking) {
    queryParams.fromBooking = 'false';
  } else if (subtab === subtabs.photoreviews) {
    queryParams.photoReviews = 'true';
  }

  const variables = {
    getUGCCountRawEndpointsRequestData: {
      urlParams: {
        hotelcode: hotelCode,
      },
    },
    getUGCReviewsRawEndpointsRequestData: {
      getUGCReviews: {
        modelType: 'modelType1',
        urlParams: {
          hotelcode: hotelCode,
        },
        queryParams,
      },
    },
    getShortLinkDataRawEndpointRequestData: {
      getShortLinkData: {
        endPointId: 99,
        modelType: 'modelType1',
        body: {
          hotelId: mmtId,
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    doQuery(GET_UCG_COUNT_AND_REVIEWS(source), variables)
      .then((response: ReviewsResponse) => {
        const totalReplyPending = getValue(
          response,
          'data.ugcCount.results.totalReplyPending',
          null,
        );
        const sourceDependentCounts = getValue(
          response,
          `data.ugcCount.results.${
            source.toLowerCase() as Lowercase<
              typeof sources[keyof typeof sources]
            >
          }`,
          null,
        );

        const mergedResponse = {
          reviews: getValue(
            response,
            'data.ugcReviews.getUGCReviews.modelType1.results',
            null,
          ),
          headerCounts: {
            totalReplyPending,
            ...sourceDependentCounts,
          },
          qrCodePayload: getValue(
            response,
            'data.shortLinkData.getShortLinkData.shortLinkType',
            null,
          ),
        };
        resolve(mergedResponse);
      })
      .catch(err => {
        showError(err?.response?.data || err);
        if (fromTopLevel) {
          resolve(null);
        } else {
          reject();
        }
      });
  });
};

export const downloadPDF = (
  mmt_id: string | number,
  payload?: { shortLinkType: string; channelManager: string },
) => {
  const { shortLinkType, channelManager } = payload ?? {};

  const url = REVIEWS.DOWNLOAD_RATINGS_PDF;
  return new Promise((resolve, reject) => {
    apiWrapper(
      axios //error content is given by product, hence not using wrappedAxios func
        .post(
          url,
          {
            hotelId: mmt_id,
            shortLinkType: shortLinkType ?? 'ratings_reviews', //hardcoded values provided by BE
            channelManager: channelManager ?? 'ingo', //hardcoded values provided by BE
          },
          { useLoader: true },
        ),
    )
      .then(response => {
        const pdfBytes = getValue(
          response,
          'shortLinkPdfDetails.shortLinkPdf',
          null,
        );
        if (pdfBytes) {
          resolve(pdfBytes);
        } else {
          reject();
        }
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getHeaderCount = (
  hotelcode: string | number,
  params: { source: typeof sources[keyof typeof sources] },
  fromTopLevel: boolean = false,
) => {
  const { source } = params;
  return new Promise((resolve, reject) => {
    const variables = {
      getUGCCountRawEndpointsRequestData: {
        urlParams: {
          hotelcode: hotelcode,
        },
      },
    };

    doQuery(GET_HEADER_COUNTS_QUERY(source), variables, {
      useLoader: !fromTopLevel,
    })
      .then(response => {
        const totalReplyPending = getValue(
          response,
          'data.ugcCount.results.totalReplyPending',
          null,
        );
        const sourceDependentCounts = getValue(
          response,
          `data.ugcCount.results.${
            source.toLowerCase() as Lowercase<
              typeof sources[keyof typeof sources]
            >
          }`,
          null,
        );

        resolve({
          totalReplyPending,
          ...sourceDependentCounts,
        });
      })
      .catch(err => {
        showError(err?.response?.data || err);
        if (fromTopLevel) {
          resolve(null);
        } else {
          reject();
        }
      });
  });
};

export const getTableData = (hotelcode, params) => {
  const baseurl = REVIEWS.GET_REVIEWS(hotelcode);
  return new Promise((resolve, reject) => {
    return apiWrapper(axios.get(baseurl, { params, useLoader: true }))
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        showError(err?.response?.data || err);
        reject();
      });
  });
};

export const getReviewsData = (
  hotelcode,
  subtab,
  vendorTab,
  limit,
  offset,
  count,
) => {
  const params = {};
  params.source = vendorTab === 'goibibo' ? 'GI' : 'MMT';
  params.limit = limit;
  params.offset = offset;

  if (subtab === 'replynow') {
    params.replyPending = 'true';
  } else if (subtab === 'replied') {
    params.replyPending = 'false';
  } else if (subtab === 'booking') {
    params.fromBooking = 'true';
  } else if (subtab === 'nonbooking') {
    params.fromBooking = 'false';
  } else if (subtab === 'photoreviews') {
    params.photoReviews = 'true';
  }
  return new Promise(resolve => {
    getTableData(hotelcode, params)
      .then(res => {
        const reviewsData = res.results ?? [];
        resolve({ data: reviewsData, totalCount: count });
      })
      .catch(err => {
        showError(err?.response?.data || err);
        resolve({ data: [], totalCount: 0 });
      });
  });
};

export const postGIReviewReply = (reviewId, data) => {
  const baseurl = REVIEWS.POST_REVIEW_REPLY_GI(reviewId);
  return apiWrapper(axios.post(baseurl, data, { useLoader: true }));
};

export const postMMTReviewReply = (reviewId, params, data) => {
  const baseurl = REVIEWS.POST_REVIEW_REPLY_MMT(reviewId);

  return apiWrapper(
    axios.post(baseurl, data, {
      params,
      useLoader: true,
    }),
  );
};

export const getQnATableData = (voyagerId, params) => {
  const baseurl = REVIEWS.GET_QUESTIONS(voyagerId);
  return new Promise((resolve, reject) => {
    return apiWrapper(axios.get(baseurl, { params, useLoader: true }))
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        showError(err?.response?.data || err);
        reject();
      });
  });
};

export const getQuestionsData = (voyagerId, subtab, limit, offset, count) => {
  const params = {};
  params.limit = limit;
  params.offset = offset;
  if (subtab === 'hotel') {
    params.type = 'hotel';
  } else if (subtab === 'citylocality') {
    params.type = 'city';
  } else if (subtab === 'unanswered') {
    params.type = 'hotel';
    params.answerPending = 'true';
  }
  return new Promise(resolve => {
    getQnATableData(voyagerId, params)
      .then(res => {
        const qnaData = res.results ?? [];
        resolve({ data: qnaData, totalCount: count });
      })
      .catch(err => {
        showError(err?.response?.data || err);
        resolve({ data: [], totalCount: 0 });
      });
  });
};

export const postGIQnAReply = (questionId, data) => {
  const baseurl = REVIEWS.POST_QUESTION_REPLY_GI(questionId);

  return apiWrapper(
    axios.post(baseurl, data, {
      useLoader: true,
    }),
  );
};

export const uploadPhotosToCloud = data => {
  const baseurl = REVIEWS.UPLOAD_PHOTOS();

  return apiWrapper(
    axios.post(baseurl, data, {
      useLoader: true,
    }),
  );
};
