import makeStyles from '@mui/styles/makeStyles';
import { type StyleProps } from './Card.types';

export const useStyles = makeStyles(theme => ({
  root: (props: StyleProps) => ({
    background: props.selected
      ? theme.palette.backgrounds.primary
      : theme.palette.common.white,
    borderRadius: '4px',
    opacity: '0px',
    border: `1px solid ${theme.palette.backgrounds.divider}`,
    boxShadow: props.selected ? '2px 2px 5px 0px #0000001A' : '',
    userSelect: 'none',
    pointerEvents: props.disabled ? 'none' : 'auto',
  }),
  header: {
    width: '100%',
  },
  headingBox: (props: StyleProps) => ({
    width: `calc(100% - ${props.selectable ? '68' : '32'}px)`,
    display: 'flex',
    padding: props.hasSubtitle ? '12px 16px 12px 16px' : '8px 16px 8px 16px',
    gap: '4px',
    opacity: '0px',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  headings: {
    flexDirection: 'column',
    width: '100%',
  },
  title: (props: StyleProps) => {
    return {
      color: theme.palette.text.primary,
      //   is done by mui nowrap prop
      //   overflow: 'hidden',
      //   textOverflow: 'ellipsis',
      //   whiteSpace: 'nowrap',
      fontWeight: 500,
      fontSize:
        props.size === 'small'
          ? '16px'
          : props.size === 'medium'
            ? '18px'
            : '24px',
      lineHeight:
        props.size === 'small'
          ? '20px'
          : props.size === 'medium'
            ? '27px'
            : '32px',
    };
  },
  subTitle: (props: StyleProps) => ({
    color: theme.palette.text.primary,
    fontWeight: 500,
    textAlign: 'left',
    fontSize: props.size === 'small' ? '14px' : '16px',
    lineHeight: '20px',
  }),
  body: {
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    // large, small
    padding: '2px 16px 10px 16px',
    // medium / no subtitle
    // padding:'0px 16px 10px 16px',
    gap: '0px',
    opacity: '0px',
  },
  footer: {
    display: 'flex',
    alignItems: 'end',
    gap: '8px',
    padding: '0px 16px 16px 16px',
    '& .MuiLink-root': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      // @ts-ignore
      color: theme.palette.text.link,
    },
  },
  tag: {
    position: 'relative',
    left: '16px',
    top: '10px',
  },
  icon: {
    flex: 'none',
  },
  selectedCircle: (props: StyleProps) => ({
    fill: props.disabled
      ? theme.palette.backgrounds.divider
      : theme.palette.primary.main,
  }),
}));
