import React, { forwardRef } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import { DataAttribute } from 'interfaces/types';
import { getDataPropsFromRest, isNullOrUndefined } from 'utilities/Utils';

import './../styles/Input.scss';

type CommonProps = DataAttribute &
Pick<
React.ComponentProps<typeof OutlinedInput>,
| 'onKeyDown'
| 'name'
| 'placeholder'
| 'fullWidth'
| 'disabled'
| 'error'
| 'minRows'
| 'maxRows'
> & {
  className?: string;
  rightElem?: React.ReactNode;
};

type MultiLineInputProps = CommonProps & {
  value: string | number;
  onChange?: (v: { name?: string; value?: string | number }) => void;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
};

function MultiLineInputComp(props: MultiLineInputProps, ref) {
  const {
    value,
    onChange,
    //Optional
    minRows = 4,
    maxRows = minRows,
    name,
    placeholder = 'Type here...',
    onBlur,
    onKeyDown,
    fullWidth = true,
    disabled = false,
    error = false,
    className = '',
    rightElem,
    ...rest
  } = props;

  const dataRest = getDataPropsFromRest(rest);

  const inputChangeHandler = evt => {
    const { name: _name } = evt.target;
    const val = evt.target.value;
    return onChange({ name: _name, value: val });
  };

  return (
    <OutlinedInput
      {...dataRest}
      value={value}
      onChange={inputChangeHandler}
      inputRef={ref}
      multiline={true}
      minRows={minRows}
      maxRows={maxRows}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      name={name}
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      classes={{ root: `${className}` }}
      placeholder={placeholder}
      endAdornment={rightElem}
      sx={{
        '& .MuiInputBase-inputMultiline': {
          fontSize: '14px',
          lineHeight: '16px',
        },
      }}
    />
  );
}

const MultiLineInput = forwardRef(MultiLineInputComp);

type ControlledMultiLineInputProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = CommonProps & {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: string | number;
  isRequired?: boolean;
  maxLength?: number;
  minLength?: number;
  customValidator?: RegisterOptions<T, TName>['validate'];
};

function ControlledMultiLineInput<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: ControlledMultiLineInputProps<T, TName>) {
  const {
    name,
    control,
    defaultValue = '',
    isRequired = false,
    maxLength,
    minLength,
    customValidator,
    ...rest
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };
  if (!isNullOrUndefined(maxLength)) {
    validations.maxLength = {
      value: maxLength,
      message: `The value cannot be longer than ${maxLength} characters`,
    };
  }
  if (!isNullOrUndefined(minLength)) {
    validations.minLength = {
      value: minLength,
      message: `The value cannot be shorter than ${minLength} characters`,
    };
  }
  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const {
    field: { ref, value, onChange, onBlur },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });
  const showCount =
    !isNullOrUndefined(maxLength) && !isNullOrUndefined(minLength);
  const count = value?.length ?? 0;
  const onUpdate = ({ value: _value }) => {
    if (showCount && _value.length > maxLength) {
      return;
    }
    onChange(_value);
  };
  return (
    <div className="relative w-full grid ">
      <MultiLineInput
        ref={ref}
        value={value}
        onChange={onUpdate}
        onBlur={onBlur}
        {...rest}
      />
      {showCount && (
        <span className="justify-end grid text-common-light ">
          {`${count}/${maxLength}`}
        </span>
      )}
    </div>
  );
}

export { ControlledMultiLineInput, MultiLineInput };
