import { useContext, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from '../../components/latest-core';
import { useLangStrings } from '../../utilities/CustomHooks';
import { HotelContext } from '../../utilities/Context';
import { getHotelMetaData } from './HostWebMigrationAPIClient';
import CONSTANTS from './HostWebMigration.constants';
import { legacyPushToGTM } from 'utilities/gtm';
import { IHotelMetadataAPI } from 'app/hotel/interfaces';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    marginTop: -72,
  },
  fullHeight: {
    height: '100%',
  },
  content: {
    maxWidth: 540,
    textAlign: 'center',
  },
  info: {
    width: 520,
    margin: 'auto',
  },
  warningIcon: {
    color: 'red',
    width: 60,
    height: 60,
  },
}));

const eventCategory = 'web_host_transition';
function MigrationProgress() {
  const {
    currentHotel: { hotelcode: hotelCode, hostweb_migration: migrationStatus },
    hostWebMigrationComplete,
  } = useContext(HotelContext);

  const [isError, setIsError] = useState<boolean>(() => {
    return migrationStatus === 'failed';
  });
  const intervalRef = useRef(null);
  const classes = useStyles();

  const [STRINGS] = useLangStrings<'HostWebMigration'>('HostWebMigration');

  const setError = () => {
    legacyPushToGTM({
      eventCategory,
      eventAction: 'progress',
      eventLabel: 'transition_stop_error',
    });
    setIsError(true);
    clearInterval(intervalRef.current);
  };

  const ensureMigrationStatus = () => {
    getHotelMetaData(hotelCode)
      .then((response: IHotelMetadataAPI) => {
        const updatedStatus = response.hostweb_migration;
        if (updatedStatus === 'failed') {
          setError();
        }
        if (updatedStatus === 'done') {
          legacyPushToGTM({
            eventCategory,
            eventAction: 'progress',
            eventLabel: 'transition_stop_successful',
          });

          clearInterval(intervalRef.current);
          localStorage.setItem(`host_web_migrating_${hotelCode}`, 'completed');
          hostWebMigrationComplete();
        }
      })
      .catch(error => {
        console.error('getHotelMetaData failed', error);
      });
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      ensureMigrationStatus();
    }, CONSTANTS.METADATA_API_POLLING);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const contentList = isError
    ? STRINGS.PROGRESS.ERROR.CONTENT
    : STRINGS.PROGRESS.MAIN.CONTENT;

  return (
    <Box className={classes.root}>
      <Grid
        container
        className={classes.fullHeight}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {isError ? (
          <WarningIcon className={classes.warningIcon} />
        ) : (
          <Box marginBottom={4}>
            <CircularProgress />
          </Box>
        )}
        <Box className={classes.content}>
          <Typography variant="h1">
            {isError
              ? STRINGS.PROGRESS.ERROR.TITLE
              : STRINGS.PROGRESS.MAIN.TITLE}
          </Typography>
          {contentList.map((content, index) => (
            <Typography
              key={index}
              themeColor="gray.main"
              className={classes.info}
            >
              {content}
            </Typography>
          ))}
        </Box>
      </Grid>
    </Box>
  );
}

export default MigrationProgress;
