import { ListSubheader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { classNames } from 'utilities/Utils';

interface TextFieldComponentProps {
  id?: string;
  name?: string;
  placeholder?: string;
  rootStyles?: string;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  size?: 'small' | 'medium';
  // You can add more props as needed
}

type CustomSearchInputProps = {
  placeholder?: string;
  startAdornment?: React.ReactNode;
  size?: 'small' | 'medium';
  children: React.ReactNode;
  isNoOptions?: boolean;
  isMultiple?: boolean;
  TextFieldComponent: (props: TextFieldComponentProps) => JSX.Element;
};

const useStyles = makeStyles(theme => ({
  searchRoot: {
    '& .MuiInputBase-root': {
      height: '36px',
      margin: '0 4px',
      width: '98%',
    },
    // for active border color of search input
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.text.primary,
        borderWidth: '1px',
      },
    },
  },
}));

const CustomSearchInput = (props: CustomSearchInputProps) => {
  const {
    placeholder,
    startAdornment,
    size,
    children,
    isNoOptions = false,
    isMultiple = false,
    TextFieldComponent,
  } = props;
  const styles = useStyles();

  const shouldShowdivider = !isNoOptions && isMultiple;

  return (
    <ListSubheader
      className={classNames(
        'sticky top-0 left-0 pt-1 !px-0 !bg-color-white z-[189]',
        shouldShowdivider ? 'pb-3' : 'pb-0',
      )}
    >
      {TextFieldComponent({
        placeholder,
        startAdornment,
        rootStyles: styles?.searchRoot,
        size,
      })}

      {children}
      {shouldShowdivider && (
        <div className="h-1 border-0 border-b-[1px] border-solid border-b-divider w-[calc(100%-8px)] ml-1" />
      )}
      {isNoOptions && (
        <span className=" mt-4 ml-2 text-[rgb(120, 120, 120)]">
          No results found
        </span>
      )}
    </ListSubheader>
  );
};

export default CustomSearchInput;
