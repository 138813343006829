import { Add, InfoOutlined, Remove } from '@mui/icons-material';
import { useContext, useState } from 'react';

import { Button, FormElement } from 'components/new-core';
import { Input, Drawer, Tooltip, Calendar } from 'components/latest-core';
import { DealsContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import { GAFormatting } from 'utilities/Utils';
import { ConfirmBoosterModal, confirmBoosterType, DiscardModal } from '..';
import useBoosterCalendar, { CALENDAR_TYPE } from '../useBoosterCalendar';

import { cugReverseMap } from 'modules/promotions-and-coupons/PnC.constants';
import { createOfferValuePayload } from 'modules/promotions-and-coupons/utils';
import { getFormattedDate } from 'utilities/DateUtils';
import { legacyPushToGTM } from 'utilities/gtm';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';

const gtmActionType = GAFormatting(CONSTANTS.TYPE.HOLIDAYS);

const calendarConfig = {
  hasBookingEndSync: true,
  showMore: [
    CALENDAR_TYPE.BOOKING_START,
    CALENDAR_TYPE.BOOKING_END,
    CALENDAR_TYPE.BLACKOUT,
    CALENDAR_TYPE.STAY_START,
    CALENDAR_TYPE.STAY_END,
  ],
  createOnly: [],
};
const getCalendarConfig = blackOutOptions => ({
  ...calendarConfig,
  blackOutOptions,
});

export default function GenericCugDrawer(props) {
  const { data, onDrawerClose, createCUG, updateCUG, isOfferAgain } = props;
  const {
    segment,
    drawerText: {
      TITLE: title,
      SUB_TITLE: subTitle,
      TOOLTIP: specificTooltip,
      BANNER_TEXT: bannerText,
    },
  } = data;

  const {
    pncMetadata: {
      CUG: {
        // @ts-ignore
        offerCategories: { [cugReverseMap[CONSTANTS.TYPE.HOLIDAYS]]: meta },
      },
    },
    cugBeingEdited,
    setAllCugStatus,
  } = useContext(DealsContext);
  const [STRINGS] = useLangStrings('PerformanceBooster');

  const { offerCode, checkIn, checkOut, bookingStart, bookingEnd, blackOut } =
    data;
  const isEdit = !!offerCode;

  const [showMore, setShowMore] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);
  const [offerValue, setOfferValue] = useState(
    data.offerValue || meta.minOfferValue,
  );

  const calendarInitDate = {
    [CALENDAR_TYPE.BLACKOUT]: blackOut,
    [CALENDAR_TYPE.BOOKING_START]: bookingStart,
    [CALENDAR_TYPE.BOOKING_END]: bookingEnd,
    [CALENDAR_TYPE.STAY_START]: checkIn,
    [CALENDAR_TYPE.STAY_END]: checkOut,
  };

  const {
    monthlyBlackoutLimit: monthlyLimit,
    annualBlackoutLimit: annualLimit,
  } = meta;
  const blackOutOptions: {
    month?: number;
    year?: number;
  } = {};
  if (monthlyLimit) {
    blackOutOptions.month = monthlyLimit;
  }
  if (annualLimit) {
    blackOutOptions.year = annualLimit;
  }

  const _calendarConfig = getCalendarConfig(blackOutOptions);

  const {
    calendarProps,
    calendarDates,
    generateModifiedDates,
    appendDatePayload,
  } = useBoosterCalendar(calendarInitDate, _calendarConfig);

  const {
    [CALENDAR_TYPE.BLACKOUT]: blackoutCalendarProps,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartCalendarProps,
    [CALENDAR_TYPE.BOOKING_END]: bookingEndCalendarProps,
    [CALENDAR_TYPE.STAY_START]: stayStartCalendarProps,
    [CALENDAR_TYPE.STAY_END]: stayEndCalendarProps,
  } = calendarProps;

  const {
    [CALENDAR_TYPE.STAY_START]: stayStartDate,
    [CALENDAR_TYPE.STAY_END]: stayEndDate,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartDate,
  } = calendarDates;
  const { today, MAX_CALENDAR_DATE: maxDate } = CONSTANTS;

  const drawerCloseHandler = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'close',
    });
    setShowCancelModal(true);
  };

  const getModificationList = () => {
    const modificationMap = {};

    // Offer value
    if (offerValue !== data.offerValue) {
      modificationMap[STRINGS.DRAWER.OFFER_VALUE] = {
        apiKey: 'offer_value',
        type: confirmBoosterType.NUMBER,
        initialValue: data.offerValue,
        updatedValue: offerValue,
      };
    }

    generateModifiedDates(modificationMap, isEdit, showMore);
    return modificationMap;
  };

  const performanceActions = modificationMap => {
    if (isEdit) {
      const payload = {};
      for (const modification in modificationMap) {
        const { apiKey, updatedValue: apiKeyValue } =
          modificationMap[modification];
        payload[apiKey] = apiKeyValue;
      }
      updateCUG(payload, segment, offerCode);
    } else {
      const payload = { offer_value: offerValue, segment };

      appendDatePayload(payload, showMore);
      createCUG(payload, segment);
    }
  };

  const isOfferValid = offerValue < meta.minOfferValue;
  const tooltip = specificTooltip ?? STRINGS.DRAWER_META.GENERIC.TOOLTIP;
  const invalidOfferMsg =
    STRINGS.DRAWER_META.GENERIC.INVALID_OFFER_VALUE.replace(
      '__segment__',
      STRINGS.HOLIDAYS,
    ).replace('__minValue__', `${meta.minOfferValue}`);

  const confirmBooster = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'activate',
    });
    if (isOfferValid) {
      return;
    }
    const modificationMap = getModificationList();
    if (Object.keys(modificationMap).length !== 0) {
      setConfirmModal(modificationMap);
    } else {
      onDrawerClose();
    }
  };

  const offerValueChange = value => setOfferValue(value);

  const handleShowMore = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    const eventLabel = showMore
      ? 'advance_settings_hide'
      : 'advance_settings_show';
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel,
    });
    setShowMore(c => !c);
  };

  const offerConfirm = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'confirm',
    });
    performanceActions(confirmModal);
  };

  const offerCancel = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'cancel',
    });
    setConfirmModal(null);
  };

  const setValuesWhileCreatingCUG = () => {
    const stayStartData = stayStartCalendarProps?.calendarProps?.selectedDays;
    const stayEndData = stayEndCalendarProps?.calendarProps?.selectedDays;

    const bookingStartData =
      bookingStartCalendarProps?.calendarProps?.selectedDays;
    const bookingEndData = bookingEndCalendarProps?.calendarProps?.selectedDays;

    const offerValueData = createOfferValuePayload(offerValue, cugBeingEdited);
    let blackoutDates = [];
    for (const custValues in confirmModal) {
      const { updatedValue } = confirmModal[custValues];
      blackoutDates = Array.isArray(updatedValue) ? updatedValue : [];
    }
    setAllCugStatus(prev => ({
      ...prev,
      [cugBeingEdited]: {
        ...prev[cugBeingEdited],
        isCustomised: true,
        isActive: true,
        bookingdatestart: showMore
          ? getFormattedDate(
            bookingStartData as Date,
            CONSTANTS.API_DATE_FORMAT,
          )
          : '',
        bookingdateend: showMore
          ? getFormattedDate(bookingEndData as Date, CONSTANTS.API_DATE_FORMAT)
          : '',
        checkindatestart: showMore
          ? getFormattedDate(stayStartData as Date, CONSTANTS.API_DATE_FORMAT)
          : '',
        checkoutdateend: showMore
          ? getFormattedDate(stayEndData as Date, CONSTANTS.API_DATE_FORMAT)
          : '',
        checkinblackout: showMore ? blackoutDates : '',
        offer_value_list: offerValueData,
      },
    }));
    setConfirmModal(null);
    onDrawerClose();
  };

  const onConfirmCallbackFunc =
    isEdit || isOfferAgain ? offerConfirm : setValuesWhileCreatingCUG;

  return (
    <Drawer
      isOpen
      header={{
        title,
        subTitle,
        rightElem: (
          <Tooltip content={tooltip} placement="bottom" className="mr-2">
            <InfoOutlined />
          </Tooltip>
        ),
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: STRINGS.DRAWER_META.GENERIC.POSITIVE_TEXT,
          onClick: confirmBooster,
          disabled: isOfferValid,
        },
        secondaryBtn: {
          text: 'Close',
          onClick: drawerCloseHandler,
        },
      }}
      onClose={drawerCloseHandler}
    >
      <FormElement
        className="margin0 marginT28"
        label={STRINGS.DRAWER.OFFER_VALUE_PLACEHOLDER}
        errorMsg={isOfferValid ? invalidOfferMsg : ''}
      >
        <Input
          type="number"
          value={offerValue}
          onChange={offerValueChange}
          placeholder={STRINGS.DRAWER.OFFER_VALUE_PLACEHOLDER}
          max={meta.maxOfferValue}
          rightElement="%"
          error={isOfferValid}
          decimalPoints={0}
        />
      </FormElement>
      {bannerText && (
        <p className="drawer-persuasion-info-banner">{bannerText}</p>
      )}

      <Button
        onClick={handleShowMore}
        variant="text"
        noPadding
        className="!mt-[10px]"
      >
        {showMore ? (
          <>
            <Remove className="booster-advance-settings-icon" />
            {STRINGS.DRAWER.HIDE_ADVANCE_SETTINGS}
          </>
        ) : (
          <>
            <Add className="booster-advance-settings-icon" />
            {STRINGS.DRAWER.SHOW_ADVANCE_SETTINGS}
          </>
        )}
      </Button>
      {showMore && (
        <>
          <FormElement
            className="marginT28"
            label={STRINGS.DRAWER.APPLICABLE_STAY_START}
          >
            <Calendar
              type="input"
              {...stayStartCalendarProps}
              disabledBefore={(bookingStartDate || today) as Date}
              disabled={isEdit}
            />
          </FormElement>
          <FormElement
            className="marginT28"
            label={STRINGS.DRAWER.APPLICABLE_STAY_END}
          >
            <Calendar type="input" {...stayEndCalendarProps} />
          </FormElement>
          <FormElement
            className="marginT28"
            label={STRINGS.DRAWER.APPLICABLE_BOOKING_START}
          >
            <Calendar
              type="input"
              {...bookingStartCalendarProps}
              disabledAfter={(stayStartDate || maxDate) as Date}
              disabled={isEdit}
            />
          </FormElement>
          <FormElement
            className="marginT28"
            label={STRINGS.DRAWER.APPLICABLE_BOOKING_END}
          >
            <Calendar
              type="input"
              {...bookingEndCalendarProps}
              disabledBefore={(stayStartDate || today) as Date}
              disabledAfter={(stayEndDate || maxDate) as Date}
            />
          </FormElement>
          <FormElement
            className="marginT28"
            label={STRINGS.DRAWER.CHOOSE_BLACKOUT_DATES}
          >
            <Calendar
              type="blackout"
              inputClasses="!w-[250px]"
              {...blackoutCalendarProps}
              disabledBefore={(stayStartDate || today) as Date}
              disabledAfter={stayEndDate as Date}
            />
          </FormElement>
        </>
      )}
      {showCancelModal && (
        <DiscardModal
          gtmActionType={gtmActionType}
          onDrawerClose={onDrawerClose}
          setShowCancelModal={setShowCancelModal}
        />
      )}
      {confirmModal && (
        <ConfirmBoosterModal
          data={confirmModal}
          onConfirm={onConfirmCallbackFunc}
          onClose={offerCancel}
        />
      )}
    </Drawer>
  );
}
