import { ActiveTabType, BookingsTabType, ListViewType } from './bookings.types';
import {
  GET_CORPORATE_CHECKIN_VIEW,
  GET_CORPORATE_CHECKOUT_VIEW,
  GET_CORPORATE_BOOKING_VIEW,
  GET_ALL_BOOKING_CHECKIN_VIEW,
  GET_ALL_BOOKING_CHECKOUT_VIEW,
  GET_ALL_BOOKING_DATE_VIEW,
} from './BookingsGraphQuery';

export const THRESHOLD_DAYS = 7;
export const FILTER_HEADING_HEIGHT = 51;
export const BOOKINGS_HEADING_HEIGHT = 65;
export const SALES_CHANNELS = {
  AMAZON: 'Amazon',
  HDFC_SMART_BUY: 'HDFC Smart Buy',
  GOIBIBO_DMMT: 'Goibibo (DMMT)',
  INTAS: 'Intas',
  FCM: 'FCM',
  TRIPEUR: 'Tripeur',
  ASTRON: 'Astron',
  CTRIP: 'Ctrip',
  INTERMILES: 'Intermiles',
  UPL: 'UPL',
  RETAIL: 'Retail',
  MMTRETAIL: 'MMT Retail',
  GIRETAIL: 'GI Retail',
  HOLIDAYS: 'Holidays',
  MYPARTNER: 'MyPartner',
  MYBIZ: 'MyBiz',
  Q2T: 'Q2T',
  GOIBIBO: 'Goibibo',
};

export const BRAND_MAP = {
  Goibibo: 'GI',
  MakeMyTrip: 'MMT',
  MMTGCC: 'MMT',
  Staff: 'GI',
  HotelTravel: 'HotelTravel',
  HotelCloud: 'HotelCloud',
};

export const CURRENT_SALES_CHANNELS = {
  CORPORATE: 'MyBiz & Q2T',
  ALL: 'All Channels',
};

export const BOOKINGS_CHANNEL = [
  {
    label: CURRENT_SALES_CHANNELS.ALL,
    value: 'all',
  },
  {
    label: CURRENT_SALES_CHANNELS.CORPORATE,
    value: 'Corporate',
  },
];

export const VIEW_TYPES: { [label: string]: ListViewType } = {
  PERSUASION_VIEW: 'persuasionView',
  TIMELINE_VIEW: 'timelineView',
  FILTER_VIEW: 'filterView',
  SEARCH_VIEW: 'searchView',
};

export const PAYMENT_MODE = {
  PAH: 'PAH',
  PAHCC: 'PAH_WITH_CC',
  PAHWITHOUTCC: 'PAH_WITHOUT_CC',
  PAS: 'PAS',
};

export const CONFIRM_STATUS = {
  CANCELLED: 'cancelled',
  CONFIRMED: 'confirmed',
  PENDING: 'pending',
  REJECTED: 'rejected',
  NOSHOW: 'noshow',
  CHECKIN: 'checkedin',
  AMENDED: 'amended',
  UPGRADED: 'alternate',
};

export const dayWiseBookingStatus = {
  pending: 'pending',
  confirmed: 'confirmed',
  inventory: 'inventory',
  rejected: 'rejected',
  cancelled: 'cancelled',
  invoices: 'invoices',
};

export const filterQueryMap = {
  Corporate_checkin: GET_CORPORATE_CHECKIN_VIEW,
  Corporate_checkout: GET_CORPORATE_CHECKOUT_VIEW,
  Corporate_bookingDate: GET_CORPORATE_BOOKING_VIEW,
  all_checkin: GET_ALL_BOOKING_CHECKIN_VIEW,
  all_checkout: GET_ALL_BOOKING_CHECKOUT_VIEW,
  all_bookingDate: GET_ALL_BOOKING_DATE_VIEW,
  hourly_booking: GET_ALL_BOOKING_CHECKIN_VIEW,
};
export const filteredDataNodeMap = {
  Corporate_checkin: 'corporateCheckinDateWise',
  Corporate_checkout: 'corporateCheckOutDateWise',
  Corporate_bookingDate: 'corporateBookingDateWise',
  all_checkin: 'allBookingsCheckinDateWise',
  all_checkout: 'allBookingsCheckOutDateWise',
  all_bookingDate: 'allBookingsDateWise',
  hourly_booking: 'allBookingsCheckinDateWise',
};

export const PersuasionsList = {
  PENDING_BOOKINGS: 'pending_bookings',
  REFUND_REQUESTS: 'refund_requests',
  PENDING_INVOICES: 'pending_invoices',
  REJECTED_INVOICES: 'rejected_invoices',
  CHECKIN_NOSHOW_BOOKINGS: 'checkin_noshow_bookings',
};

export const PersuasionsListReverseMap = {
  pending_bookings: 'PENDING_BOOKINGS',
  refund_requests: 'REFUND_REQUESTS',
  pending_invoices: 'PENDING_INVOICES',
  rejected_invoices: 'REJECTED_INVOICES',
  checkin_noshow_bookings: 'CHECKIN_NOSHOW_BOOKINGS',
};

export const acceptedInvoicesToUpload = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const getBookingTabs = (isResellerView: boolean): BookingsTabType[] => [
  {
    label: 'Bookings',
    value: 'calendar',
  },
  ...(!isResellerView
    ? [
      {
        label: 'Insights',
        value: 'insights' as ActiveTabType,
      },
    ]
    : []),
];

export const PENDING_TASKS_STATUS_KEYS_MAP = {
  confirmed: 'pending_bookings',
  noshow: 'checkin_noshow_bookings',
  checkedin: 'checkin_noshow_bookings',
  refund: 'refund_requests',
  invoices: 'pending_invoices',
};

export const STATUS_KEYS = {
  acknowledge: 'confirmed',
  noshow: 'noshow',
  checkin: 'checkedin',
  refund: 'refund',
  invoices: 'invoices',
} as const;

export const FOOTER_OPTIONS = {
  BOOKING_VOUCHER: 'voucher',
  CANCELLATION_VOUCHER: 'cancelvoucher',
  RAISE_CONCERN: 'concern',
  ACKNOWLEDGE: 'ack',
  OFFER_REFUND: 'offerrefund',
  CHECK_IN: 'checkin',
  NO_SHOW: 'noshow',
  CUSTOMER_INVOICE: 'customerinvoice',
};

export const OpportunitiesKeys = {
  UNSOLD_ROOMS: 'unsold_rooms',
  MMT_BLACK_CUG: 'mmt_black_cug',
  MY_PARTNER_CUG: 'mypartner_cug',
  HOLIDAYS_CUG: 'holidays_cug',
  GSTN_ASSURED: 'gstn_assured',
  MYBIZ_CUG: 'mybiz_cug',
  INCLUSIONS: 'inclusions',
  MYBIZ_RATES: 'mybiz_rates',
} as const;

export const compData = routes => ({
  [BOOKINGS_CHANNEL[0].value]: {
    [OpportunitiesKeys.UNSOLD_ROOMS]: {
      title: 'Today\'s Unsold Rooms',
      desc: 'Sell these rooms at 10% cheaper rates to increase occupancy.',
      ctaName: 'Offer Special Rates Now',
      redirectionUrl: routes.inventory.base,
    },
    [OpportunitiesKeys.MMT_BLACK_CUG]: {
      title: 'Boost your Revenue',
      desc: 'Offer 10% additional discount to Goibibo & MMT loyal customers as these are high spending customers',
      ctaName: 'Configure Loyal Customer offer!',
      redirectionUrl: routes.businessBooster.performance,
    },
    [OpportunitiesKeys.MY_PARTNER_CUG]: {
      title: 'Boost your Revenue',
      desc: 'Offer 10% additional discount to MyPartner customers as these are high spending customers',
      ctaName: 'Configure MyPartner offer!',
      redirectionUrl: routes.businessBooster.performance,
    },
    [OpportunitiesKeys.HOLIDAYS_CUG]: {
      title: 'Boost your Revenue',
      desc: 'Offer 10% additional discount to customers booking Holiday Packages as these are high spending customers',
      ctaName: 'Configure Holiday Customer offer!',
      redirectionUrl: routes.businessBooster.performance,
    },
  },
  [BOOKINGS_CHANNEL[1].value]: {
    [OpportunitiesKeys.GSTN_ASSURED]: {
      title: 'Boost your Room Nights',
      desc: 'Guests prefer to book hotels who are part of Corporate Preffered Program!',
      ctaName: 'Click here to view more details',
      redirectionUrl: routes.businessBooster.performance,
    },
    [OpportunitiesKeys.MYBIZ_CUG]: {
      title: 'Boost your Revenue',
      desc: 'Offer 15%  additional discount as service cost for these customers is less and they give more repeat business.',
      ctaName: 'Create Corporate member deal Now!',
      redirectionUrl: routes.businessBooster.performance,
    },
    [OpportunitiesKeys.INCLUSIONS]: {
      title: 'Get Long Stay Bookings',
      desc: 'Corporate guests book for long stays and  prefer inclusions like Early Check-In, free room upgrade, laundry , etc ',
      ctaName: 'Offer inclusions now!',
      redirectionUrl: routes.property.inclusionsBase,
    },
    [OpportunitiesKeys.MYBIZ_RATES]: {
      title: 'Boost your Room Nights',
      desc: 'Offering lower single occupancy rates increase chances of bookings.',
      ctaName: 'Offer single occupancy rates now!',
      redirectionUrl: routes.inventory.base,
    },
  },
});

export const errorMessages = {
  size_error: 'File Size more than 10MB',
  unrecognised_bookings: 'Booking ID unrecognised',
  wrong_format: 'Uploaded in wrong format',
};

export const SLCampaignTypes = {
  AFTER: 'After Campaign',
  BEFORE: 'Before Campaign',
};

export const getWidths = extendedView => {
  if (!extendedView) {
    return {
      minWidths: {
        GUEST_NAME: 'min-w-[125px]',
        STAY_DURATION: 'min-w-[136px]',
        ROOM_MEAL_PLAN: 'min-w-[116px]',
        BOOKING_ID: 'min-w-[139px]',
        GUEST_CONTACT: 'min-w-[80px]',
        NET_AMOUNT: 'min-w-[67px]',
      },
      maxWidths: {
        GUEST_NAME: 'max-w-[125px]',
        STAY_DURATION: 'max-w-[136px]',
        ROOM_MEAL_PLAN: 'max-w-[116px]',
        BOOKING_ID: 'max-w-[139px]',
        GUEST_CONTACT: 'min-w-[80px]',
        NET_AMOUNT: 'max-w-[90px]',
      },
    };
  }
  return {
    minWidths: {
      GUEST_NAME: 'min-w-[140px]',
      STAY_DURATION: 'min-w-[152px]',
      ROOM_MEAL_PLAN: 'min-w-[175px]',
      BOOKING_ID: 'min-w-[155px]',
      GUEST_CONTACT: 'min-w-[145px]',
      NET_AMOUNT: 'min-w-[100px]',
    },
    maxWidths: {
      GUEST_NAME: 'max-w-[140px]',
      STAY_DURATION: 'max-w-[152px]',
      ROOM_MEAL_PLAN: 'max-w-[175px]',
      BOOKING_ID: 'max-w-[155px]',
      GUEST_CONTACT: 'max-w-[145px]',
      NET_AMOUNT: 'max-w-[100px]',
    },
  };
};

export const invoiceUploadViewTypes = {
  UPLOAD: 'upload',
  STATUSES: 'statuses',
  PROGRESS: 'progress',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getParamsForDeepLinking = (data: Record<string, any>) => {
  let queryString = '';
  for (const key in data) {
    queryString += `${key}=${data[key]}&`;
  }
  return queryString.substring(0, queryString.length - 1);
};

export const DYNAMIC_FILTER_KEYS_MAP = {
  HAS_GROUP_BOOKINGS: 'hasGroupBookings',
  HAS_DAYUSE_BOOKINGS: 'hasDayUseBookings',
  HAS_RTB_BOOKINGS: 'hasRtbBookings',
  HAS_PAH_BOOKINGS: 'hasPahBookings',
  HAS_CORPORATE_BOOKINGS: 'hasCorporateBookings',
  IS_GSTN_ASSURED: 'isGSTNAssured',
};

export const dynamicFiltersMap = {
  hasGroupBookings: 'isGroupBooking',
  hasDayUseBookings: 'isDayUseBooking',
  hasRtbBookings: 'isRTBRequest',
  hasPahBookings: 'payModeValue',
  hasCorporateBookings: 'salesChannelName',
  isGSTNAssured: 'gstnInvoiceReq',
};
export const paymentStatusMap = {
  PENDING: 'Pending',
  PROCESSED: 'Processed',
};
export const upcomingPastMap = {
  UPCOMING: 'upcoming',
  PAST: 'past',
};

export const bookingsFlagsMap = {
  hasGroupBookings: false,
  hasDayUseBookings: false,
  hasRtbBookings: false,
  hasPahBookings: false,
  hasCorporateBookings: false,
  isGSTNAssured: false,
};

export const filterKeysOmniMap = {
  salesCategory: 'salesCategory',
  bookingDateType: 'bookingType',
  confirmStatusValue: 'confirmStatus',
  payModeValue: 'payMode',
  gstInvoiceStatus: 'gstnStatus',
  paymentStatus: 'paymentStatus',
  vendorId: 'brand',
  hourlyOrGroupOrUrlOrRTBbooking: 'hourly_group_url_rtb',
  upcomingOrPast: 'upcoming_past',
  dateRange: 'dateRange',
};

export const CLEAR_FILTER_THRESHOLD = 4;
export const CLEAR_ALL_BUTTON_THRESHOLD = 3;

export enum BOOKING_AMEND_TYPE {
  REBOOKING = 'rebooking',
}

export const amendTypeToBookingIdMap = {
  [BOOKING_AMEND_TYPE.REBOOKING]: 'oldVendorBookingId',
};
