import React, { forwardRef } from 'react';
import { default as MuiButton } from '@mui/material/Button';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getDataPropsFromRest } from 'utilities/Utils';

// Type defination for Button.tsx
export type ButtonProps = {
  id?: string;
  children: React.ReactNode;
  onClick?:
  | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
  | undefined;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'inherit';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'reset' | 'button';
  thin?: boolean;
  className?: string;
  IconComp?: React.ElementType;
  tabIndex?: number;
  IconCompRight?: React.ElementType;
  verticalDivider?: boolean;
  toLowerCase?: boolean;
  isIconStyle?: boolean;
};

// End of type defination for Button.tsx
const useStyles = makeStyles(() => ({
  rootThin: {
    '&.MuiButton-root': {
      fontSize: '14px',
      lineHeight: '16px',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: '14px 28px',
      borderRadius: '4px',
      height: '36px',
    },
  },
  root: {
    '&[class*=" MuiButton-root"]': {
      letterSpacing: 0,
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 600,
      textTransform: 'capitalize',
      textAlign: 'center',
      padding: '8px 16px',
      borderRadius: '4px',
      whiteSpace: 'normal',
      '& [class*=MuiButton-label]': {
        height: 'initial',
      },
      '&[class*=MuiButton-text]': {
        padding: '0',
      },
    },
  },
  outlined: {
    height: '36px',
  },
  containedPrimary: {
    whiteSpace: 'pre',
    '&.Mui-disabled': {
      backgroundColor: '#C2C7EC',
      color: 'white',
      '& hr': {
        opacity: '0.2 !important',
      },
    },
    '&.MuiButton-sizeSmall': {
      fontWeight: '500 !important',
    },
  },
  outlinedPrimary: {
    whiteSpace: 'pre',
    border: '1px solid #3543BF',
    '&[class*=" MuiButton-outlinedPrimary"]:hover': {
      border: '1.5px solid #3543BF',
      color: '#1F2A8D',
    },

    '&.Mui-disabled': {
      border: '1px solid #C2C7EC',
      color: '#C2C7EC',
      '& hr': {
        opacity: '0.2 !important',
      },
    },
    '& hr': {
      borderLeft: '1px solid #3543BF !important',
    },
  },
  textPrimary: {
    minWidth: 'unset',
    padding: 'unset',
    backgroundColor: 'transparent !important',
    '&.MuiButton-textPrimary:hover': {
      backgroundColor: 'transparent',
      color: '#1F2A8D',
      borderRadius: '8px',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent',
      color: '#1F2A8D',
      borderRadius: '8px',
    },
    '&.Mui-disabled': {
      color: '#C2C7EC',
      padding: '0',
      borderRadius: '8px',
      '& hr': {
        opacity: '0.2 !important',
      },
    },
    '& hr': {
      borderLeft: '1px solid #3543BF !important',
      marginLeft: '5px',
    },
  },
  sizeSmall: {
    height: '24px !important',
    fontSize: '14px !important',
    padding: '2px 8px !important',

    '&[class*="MuiButton-sizeSmall"]': {
      fontWeight: '500 !important',
    },
    '&[class*=MuiButton-text]': {
      padding: '0px !important',
    },
  },
  sizeLarge: {
    height: '40px !important',
    padding: '10px 16px !important',
    fontSize: '16px !important',
  },
  divider: (props: { size: string }) => ({
    borderLeft: '1px solid #EFF1FF',
    height: props.size === 'small' ? '14px' : '20px',
    opacity: '0.5',
    marginLeft: props.size === 'small' ? '8px' : '16px',
  }),
}));

function ButtonComp(props: ButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const {
    children,
    onClick,
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    fullWidth = false,
    disabled = false,
    type,
    thin = false,
    IconComp,
    className = '',
    tabIndex,
    IconCompRight,
    isIconStyle = false,
    verticalDivider = false,
    id,
    toLowerCase = true,
    ...rest
  } = props;

  const {
    containedPrimary,
    outlinedPrimary,
    textPrimary,
    root,
    outlined,
    rootThin,
    sizeSmall,
    sizeLarge,
    divider,
  } = useStyles({ size });

  const dataProps = getDataPropsFromRest(rest);

  const iconProps = {
    fontSize: size,
  };
  if (size !== 'medium') {
    iconProps.fontSize = size;
  }

  const startIcon = IconComp && <IconComp {...iconProps} />;
  const endIcon = IconCompRight && (
    <IconCompRight className={isIconStyle ? 'ml-[-6px]' : ''} />
  );

  let rootClass = thin ? rootThin : root;
  rootClass += ` ${className}`;

  return (
    <MuiButton
      variant={variant}
      id={id}
      ref={ref}
      classes={{
        root,
        outlined,
        containedPrimary,
        outlinedPrimary,
        textPrimary,
        sizeSmall,
        sizeLarge,
      }}
      color={color}
      size={size}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      type={type}
      disableElevation
      className={rootClass}
      startIcon={startIcon}
      endIcon={endIcon}
      tabIndex={tabIndex}
      {...dataProps}
    >
      {typeof children === 'string'
        ? toLowerCase
          ? children.toLowerCase()
          : children
        : children}
      {verticalDivider ? <Divider className={divider} /> : null}
    </MuiButton>
  );
}

const Button = forwardRef(ButtonComp);

export default Button;
