import { forwardRef } from 'react';
import RadioBase from './variants/RadioBase';
import RadioLegacyBoxed from './variants/RadioLegacyBoxed';
import RadioCard from './variants/RadioCard';
import RadioChip from './variants/RadioChip';
import { useRadioStyles } from './Radio.styles';
import { RadioVariant } from './Radio.constant';
import type { RadioPropsType, RadioVariantsPropsType } from './Radio.types';

function Radio(props: RadioPropsType, ref?: React.Ref<HTMLDivElement>) {
  const { variant = RadioVariant.DEFAULT, inline = false } = props;
  const classes = useRadioStyles({ inline });
  const radioProps: RadioVariantsPropsType = { ...props, classes };

  switch (variant) {
    case RadioVariant.CARD:
      return <RadioCard ref={ref} {...radioProps} />;
    case RadioVariant.CHIP:
      return <RadioChip ref={ref} {...radioProps} />;
    case RadioVariant.LEGACYBOXED:
      return <RadioLegacyBoxed ref={ref} {...radioProps} />;
    default:
      return <RadioBase ref={ref} {...radioProps} />;
  }
}

export default forwardRef(Radio);
