import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from 'components/latest-core';

type HierarchicalOptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incomingProps: any;
  optionLabel: string;
  optionHierarchicalSubLabel: string;
};

const styles = makeStyles(() => ({
  text: {
    color: 'inherit',
  },
}));

const HierarchicalOption = ({
  incomingProps,
  optionLabel,
  optionHierarchicalSubLabel,
}: HierarchicalOptionProps) => {
  const classes = styles();
  return (
    <div {...incomingProps}>
      <Grid container item direction="column" className={classes.text}>
        <Typography variant="body2" fb className={classes.text}>
          {optionLabel}
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          {optionHierarchicalSubLabel}
        </Typography>
      </Grid>
    </div>
  );
};

export default HierarchicalOption;
