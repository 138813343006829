import {
  FormElement,
  ControlledCalendar,
  ControlledCheckbox,
} from 'components/latest-core';
import { BookingDateProps } from '../data/types/Schema';

export const BookingDate = ({
  bookingStartDate,
  bookingNoEndDate,
  LOS_STRINGS,
  control,
}: BookingDateProps) => {
  return (
    <div className=" bg-color-light  my-3 border-primary">
      <FormElement title={LOS_STRINGS.ADVANCE_SETTINGS.BOOKING_START_DATE}>
        <ControlledCalendar
          name="bookingStartDate"
          control={control}
          variant="single"
          type="input"
          inputClasses="!w-[250px]"
        />
      </FormElement>

      <div className="mt-4">
        <ControlledCheckbox
          variant="thin"
          name="bookingNoEndDate"
          control={control}
          disabled={!bookingStartDate}
          className="!mr-1"
        >
          {LOS_STRINGS.ADVANCE_SETTINGS.HAS_BOOKING_END_DATE}
        </ControlledCheckbox>
      </div>
      {bookingNoEndDate && (
        <FormElement
          title={LOS_STRINGS.ADVANCE_SETTINGS.BOOKING_END_DATE}
          className="mt-2"
        >
          <ControlledCalendar
            name="bookingEndDate"
            control={control}
            disabledBefore={bookingStartDate}
            inputClasses="!w-[250px]"
            variant="single"
            type="input"
          />
        </FormElement>
      )}
    </div>
  );
};
