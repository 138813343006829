import { Dispatch, SetStateAction } from 'react';
import {
  AutoCompleteProps,
  GetFilteredResults,
  OptionLabel,
} from '../Dropdown.types';

type HandleSelectAllHook = (
  getFilteredResults: GetFilteredResults,
  handleClearOptions: () => void,
  inputValue: string,
  options: AutoCompleteProps['options'],
  optionLabel: OptionLabel,
  valueField: string,
  tags: AutoCompleteProps['options'],
  setTags: Dispatch<SetStateAction<AutoCompleteProps['options']>>,
) => (
  isSelected: boolean,
  onChangeCallback: (tags: AutoCompleteProps['options']) => void,
) => void;

const useHandleSelectAll: HandleSelectAllHook = (
  getFilteredResults,
  handleClearOptions,
  inputValue,
  options,
  optionLabel,
  valueField,
  tags,
  setTags,
) => {
  return (isSelected, onChangeCallback) => {
    if (isSelected) {
      const optionsLocal = [
        ...getFilteredResults(inputValue, options, optionLabel),
        ...tags,
      ];
      const uniqueTags = [
        ...new Map(
          optionsLocal.map((item: AutoCompleteProps['options']) => [
            item[valueField],
            item,
          ]),
        ).values(),
      ];
      setTags([...uniqueTags]);
      onChangeCallback([...uniqueTags]);
    } else {
      handleClearOptions();
    }
  };
};

export default useHandleSelectAll;
