import { useCallback, useContext } from 'react';
import {
  AttributeErrors,
  LeafCategory,
  ServiceNode,
  ServiceValidationInput,
} from '../data/types/Schema';
import {
  validateInclusions,
  verifyLosInclusions,
} from '../data/api/packagesApiClient';
import { OMNITURE_CATEGORIES, pushToOmniture } from '../../../utilities/gtm';
import { PACKAGE_OMN_PAGE_NAME, PACKAGE_TYPE } from '../packageConstants';
import {
  useCurrentHotel,
  useLangStrings,
} from '../../../utilities/CustomHooks';
import { createServiceInput } from '../data/selectors/templateResponseSelector';
import { get } from 'react-hook-form';
import { showMessage } from '../../../utilities/Utils';
import { PackagesLosContext } from '../PackagesLosContext';
import { PackagesContext } from '../PackagesContext';

function addInclusionAndUpdatePriority(
  servicelist: ServiceValidationInput[],
  newService: ServiceValidationInput,
): ServiceValidationInput[] {
  const { priority: newServicePriority } = newService;
  const sortedServiceList = [...servicelist].sort(
    (a, b) => a.priority - b.priority,
  );
  // Check if the new priority already exists in the list
  const existingIndex = sortedServiceList.findIndex(
    obj => obj.priority === newServicePriority,
  );
  if (existingIndex === -1) {
    // If the new priority does not exist, simply add the new object
    sortedServiceList.push(newService);
  } else {
    // If the new priority exists, increment consecutive priorities
    sortedServiceList.splice(existingIndex, 0, newService);
    let _idx = existingIndex + 1;
    while (_idx < sortedServiceList.length) {
      if (
        sortedServiceList[_idx].priority ===
        sortedServiceList[_idx - 1].priority
      ) {
        sortedServiceList[_idx].priority = sortedServiceList[_idx].priority + 1;
      }
      _idx++;
    }
  }
  return sortedServiceList;
}

export function usePackageActions(contextName: string) {
  const losContext = useContext(PackagesLosContext);
  const regularContext = useContext(PackagesContext);

  const contextValue =
    contextName === PACKAGE_TYPE.LOS ? losContext : regularContext;
  const {
    updateServiceResponse,
    serviceResponse,
    inclusionForDelete,
    setInclusionForDelete,
    inclusionForEdit,
    setInclusionForEdit,
    inclusionLookupByIdMap,
  } = contextValue;
  const [hotelId, currentHotel] = useCurrentHotel();

  const [, commonStr] = useLangStrings<'Packages'>('Packages');

  const performValidation = async (formState, template) => {
    const isLosPackage = contextName === PACKAGE_TYPE.LOS;
    let reqBody;
    const services = serviceResponse?.servicesResponse?.services || [];
    const isUpdateMode = services.some(
      serviceIncl => serviceIncl.leafCategory.serviceId === template.serviceId,
    );
    const existingServices = services.map(createServiceInput);
    if (isUpdateMode) {
      if (isLosPackage) {
        reqBody = {
          hotelId: currentHotel.mmt_id,
          type: 'los',
          benefits: existingServices.map(item => {
            if (item.leafTemplateId !== inclusionForEdit.leafTemplateId) {
              return item;
            }
            return {
              ...item,
              selectedAttributes: formState,
            };
          }),
        };
      } else {
        reqBody = {
          ingoHotelId: hotelId,
          inclusions: existingServices.map(item => {
            if (item.leafTemplateId !== inclusionForEdit.leafTemplateId) {
              return item;
            }
            return {
              ...item,
              selectedAttributes: formState,
            };
          }),
        };
      }
    } else {
      const newService: ServiceValidationInput = {
        leafTemplateId: template.serviceId,
        subCategoryId: template.subCategoryId,
        selectedAttributes: formState,
        status: 1,
        priority: template.priorityOrder,
      };
      const inclusionList = addInclusionAndUpdatePriority(
        existingServices,
        newService,
      );
      if (isLosPackage) {
        reqBody = {
          hotelId: currentHotel.mmt_id,
          type: 'los',
          benefits: inclusionList,
        };
      } else {
        reqBody = {
          ingoHotelId: hotelId,
          inclusions: inclusionList,
        };
      }
    }

    const responsePayload = isLosPackage
      ? await verifyLosInclusions(reqBody)
      : await validateInclusions(reqBody);
    if (!responsePayload.success) {
      throw new Error('Validation API failed');
    }
    const { errorMappings } = responsePayload?.validatePackageResponse || {};
    const hasErrors = Object.keys(errorMappings || {}).length > 0;
    if (hasErrors) {
      return {
        success: false,
        errors: errorMappings as AttributeErrors,
      };
    }
    if (isLosPackage && 'packageId' in serviceResponse) {
      updateServiceResponse({
        ...responsePayload,
        packageId: serviceResponse.packageId,
      });
    } else {
      updateServiceResponse(responsePayload);
    }
    setInclusionForEdit(null);

    const selectedTemplate = template;
    const mode = isUpdateMode ? 'update' : 'add';
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      pageName: PACKAGE_OMN_PAGE_NAME,
      loadedComponents: `${selectedTemplate.leafCategoryName} | ${selectedTemplate.subCategoryName} | ${mode}`,
      cta: {
        name: 'package_inclusion_addition',
        componentName: 'package_creation_step_two',
      },
    });
    return {
      success: true,
    };
  };

  const onNewInclusionClick = useCallback((leafCategory: LeafCategory) => {
    //TODO complete this omni event
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      pageName: PACKAGE_OMN_PAGE_NAME,
      loadedComponents: `${leafCategory.leafCategoryName} | ${leafCategory.subCategoryName}`,
      cta: {
        name: 'package_inclusion_selection',
        componentName: 'package_creation_step_two',
      },
    });

    setInclusionForEdit({
      priority: -1,
      status: 1,
      leafTemplateId: leafCategory.serviceId,
      subCategoryId: leafCategory.subCategoryId,
      selectedAttributes: {},
    });
  }, []);

  const onInclusionDeleteClick = useCallback(
    (leafCategory: LeafCategory) => {
      const inclusionForDeletion =
        serviceResponse.servicesResponse.services.find(
          value => value.leafCategory.serviceId === leafCategory.serviceId,
        );

      if (!inclusionForDeletion) {
        // TODO Log this to sentry
        return;
      }
      setInclusionForDelete(inclusionForDeletion);
      pushToOmniture({
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        pageName: PACKAGE_OMN_PAGE_NAME,
        loadedComponents: `${leafCategory.leafCategoryName} | delete`,
        cta: {
          name: 'package_inclusion_edit_real_estate_area',
          componentName: 'package_creation_step_two',
        },
      });
    },
    [serviceResponse, setInclusionForDelete],
  );

  const cancelDelete = useCallback(async () => {
    setInclusionForDelete(null);
  }, []);

  const confirmDelete = useCallback(async () => {
    const isLosPackage = contextName === PACKAGE_TYPE.LOS;
    setInclusionForDelete(null);
    const inclusionsToKeep = serviceResponse.servicesResponse.services
      .filter(
        serviceIncl =>
          serviceIncl.leafCategory.serviceId !==
          inclusionForDelete.leafCategory.serviceId,
      )
      .map(createServiceInput);
    const response =
      contextName === PACKAGE_TYPE.LOS
        ? await verifyLosInclusions({
          hotelId: currentHotel.mmt_id,
          type: 'los',
          benefits: inclusionsToKeep,
        })
        : await validateInclusions({
          ingoHotelId: hotelId,
          inclusions: inclusionsToKeep,
        });
    if (response.success) {
      if (isLosPackage && 'packageId' in serviceResponse) {
        updateServiceResponse({
          ...response,
          packageId: serviceResponse.packageId,
        });
      } else {
        updateServiceResponse(response);
      }
    } else {
      const message =
        get(response, 'error.errors[0].message', null) ||
        commonStr.MESSAGES.SOMETHING_WENT_WRONG;

      showMessage({
        show: true,
        message,
        type: 'error',
      });
    }
  }, [serviceResponse, setInclusionForDelete, inclusionForDelete]);

  const onEditClicked = useCallback(
    (item: ServiceNode) => {
      setInclusionForEdit(createServiceInput(item));
      const leafCategory = inclusionLookupByIdMap[item.leafCategory.serviceId];
      pushToOmniture({
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        pageName: PACKAGE_OMN_PAGE_NAME,
        // {{inclusion_name}} | {{edit/delete}}
        loadedComponents: `${leafCategory.leafCategoryName} | edit`,
        cta: {
          name: 'package_inclusion_edit_real_estate_area',
          componentName: 'package_creation_step_two',
        },
      });
    },
    [setInclusionForEdit, inclusionLookupByIdMap],
  );

  return {
    performValidation,
    onNewInclusionClick,
    onInclusionDeleteClick,
    confirmDelete,
    cancelDelete,
    onEditClicked,
    inclusionForDelete,
  };
}
