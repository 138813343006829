import { Typography } from '../../../components/latest-core';
import React, { RefCallback, useEffect, useMemo } from 'react';
import {
  AttributeData as Attribute,
  AttributeErrors,
  AttributeNode,
  LeafCategory,
} from '../data/types/Schema';
import {
  getAttributesKeyIdMap,
  groupAttributesByLabel,
  isHalfWidthType,
} from '../data/selectors/templateResponseSelector';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { AttributeFieldProps } from './inclusion-form-fields/AttributeFieldProps';
import { BooleanSelect } from './inclusion-form-fields/BooleanSelect';
import { FreeFormTextOrNumber } from './inclusion-form-fields/FreeFormTextOrNumber';
import { TimeSelectComponent } from './inclusion-form-fields/TimeSelectComponent';
import { SelectComponent } from './inclusion-form-fields/SelectComponent';
import { ServicePriceField } from './inclusion-form-fields/ServicePriceField';
import { ValueTypeField } from './inclusion-form-fields/ValueTypeField';
import { OMNITURE_CATEGORIES, pushToOmniture } from '../../../utilities/gtm';
import { PACKAGE_OMN_PAGE_NAME } from '../packageConstants';
import { UnSupportedRenderType } from './inclusion-form-fields/UnSupportedRenderType';
import Sentry from '../../../utilities/Sentry';
import { isNullOrUndefined, mapValues } from '../../../utilities/Utils';

export type FormActionResult =
  | {
    type: 'valid';
    formState: Record<string, unknown>;
    template: LeafCategory;
  }
  | {
    type: 'invalid';
  };

export interface InclusionsFormProps {
  template: LeafCategory;
  selectedAttributes: Record<string, unknown>;
  saveTriggerRef: RefCallback<() => Promise<FormActionResult>>;
  setErrorRef: RefCallback<(errors: AttributeErrors) => void>;
}

function formFactory({
  attr,
  defaultValues,
  attributesKeyIdMap,
  template,
}: {
  attr: Attribute;
  defaultValues: Record<string, unknown>;
  attributesKeyIdMap: Record<string, number>;
  template: LeafCategory;
}) {
  const { renderType } = attr;
  let baseProps: AttributeFieldProps = {
    attr,
    attributesKeyIdMap,
    defaultValue: defaultValues[attr.attributeId] as number | string,
  };
  if (attr.settingKey === 'value_type') {
    return <ValueTypeField {...baseProps} />;
  }
  if (template.isMandatoryForPackage && attr.settingKey === 'avail_subject') {
    baseProps = {
      ...baseProps,
      readonly: true,
      defaultValue: 0,
    };
  }
  if (attr.settingKey === 'default_price') {
    const valueTypeAttrId = attributesKeyIdMap.value_type;
    const serviceModeAttrId = attributesKeyIdMap.service_mode;
    const defaultValue = defaultValues[valueTypeAttrId] as AttributeNode;
    return (
      <ServicePriceField
        {...baseProps}
        defaultValueTypeValue={defaultValue}
        defaultServiceModeValue={
          defaultValues[serviceModeAttrId] as AttributeNode
        }
      />
    );
  }
  switch (renderType) {
    case 'boolean_select':
      return <BooleanSelect {...baseProps} />;
    case 'number':
    case 'text':
    case 'text_area':
    case 'special_alpha_numeric':
    case 'number_range':
      // @ts-ignore
      return <FreeFormTextOrNumber {...baseProps} />;
    case 'time_select':
      return <TimeSelectComponent {...baseProps} />;
    case 'single_select':
    case 'multi_select':
    case 'number_select':
      return (
        <SelectComponent
          {...baseProps}
          isMulti={renderType === 'multi_select'}
        />
      );
    default: {
      return <UnSupportedRenderType attr={attr} leaf={template} />;
    }
  }
}

function ErrorsScroller({ fieldOrder }: { fieldOrder: number[] }) {
  const {
    formState: { errors, submitCount },
  } = useFormContext();
  useEffect(() => {
    const keys = Object.keys(errors);
    if (!keys.length) {
      return;
    }
    const firstErrorField = fieldOrder.find(value => !!errors[value]);
    const selectors = `[data-attr-field-id='${firstErrorField}']`;
    try {
      const matchingElements = document.querySelectorAll(selectors);
      if (matchingElements.length === 0) {
        return;
      }
      const [field] = matchingElements;
      field.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    } catch (e) {
      Sentry.logError(new Error('Error while auto-scroll to error'));
    }
  }, [errors, submitCount]);
  return null;
}

export function InclusionsForm(props: InclusionsFormProps) {
  const { template, selectedAttributes, saveTriggerRef, setErrorRef } = props;
  const attributesGroups = useMemo(
    () => groupAttributesByLabel(template),
    [template],
  );
  const attributesKeyIdMap = useMemo(
    () => getAttributesKeyIdMap(template),
    [template],
  );
  const formMethods = useForm({
    defaultValues: {},
    criteriaMode: 'all',
    mode: 'onSubmit',
  });

  useEffect(() => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.PAGE_VIEW,
      pageType: 'popup_edit_inclusion_details',
      pageName: PACKAGE_OMN_PAGE_NAME,
    });
  }, []);

  useEffect(() => {
    saveTriggerRef(() => {
      return new Promise(resolve => {
        try {
          //eslint-disable-next-line
          formMethods.handleSubmit(
            (formState: Record<string, unknown>) => {
              const finalFormState = mapValues(
                formState,
                (item: { value: unknown }) => {
                  if (typeof item === 'object') {
                    if (isNullOrUndefined(item)) {
                      return item;
                    }
                    if (Array.isArray(item)) {
                      return item.map(e => e.value);
                    }
                    return item.value;
                  }
                  return item;
                },
              );
              resolve({
                type: 'valid',
                formState: finalFormState,
                template,
              });
            },
            () => {
              resolve({
                type: 'invalid',
              });
            },
          )();
        } catch (e) {
          console.error('hadnleSubmit error', e);
        }
      });
    });
  }, []);

  useEffect(() => {
    setErrorRef(errors => {
      formMethods.clearErrors();
      Object.values(errors).forEach(errorValue => {
        // @ts-ignore
        formMethods.setError(`${errorValue.attributeId}`, {
          type: 'validate',
          message: errorValue.LongDescription,
        });
      });
    });
  }, [formMethods]);
  const fieldOrder = useMemo(
    () =>
      attributesGroups
        .map(group =>
          group.attributesGroup.map(attrs =>
            attrs.map(attr => attr.attributeId),
          ),
        )
        .flat(10),
    [attributesGroups],
  );
  return (
    <FormProvider {...formMethods}>
      <form>
        <div className={'flex flex-col divide-y gap-1'}>
          {attributesGroups.map(value => (
            <div className={'flex flex-col'}>
              <Typography variant={'subHeadingMedium'} className={'py-1'}>
                {value.label}
              </Typography>
              <div className={'flex flex-col'}>
                {value.attributesGroup.map(attrGroup => (
                  <div className={'flex flex-row gap-2'}>
                    {attrGroup.map(attr =>
                      formFactory({
                        attr,
                        defaultValues: selectedAttributes,
                        attributesKeyIdMap,
                        template,
                      }),
                    )}
                    {attrGroup.length === 1 &&
                      isHalfWidthType(attrGroup[0]) && (
                        <div className={'flex flex-1'}></div>
                    )}
                  </div>
                ))}
              </div>
              <ErrorsScroller fieldOrder={fieldOrder} />
            </div>
          ))}
        </div>
      </form>
    </FormProvider>
  );
}
