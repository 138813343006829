import MuiCircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Children, useLayoutEffect, useRef, useState } from 'react';
import { isNullOrUndefined } from 'utilities/Utils';

type IMakeStyleCommonProps = {
  trailColor?: string;
  strokeColor?: string;
  isStatic?: boolean;
};

type IMakeStyleProps = IMakeStyleCommonProps & {
  progressSize: number;
};

type ICircularProgressWithChildren = {
  children?: never;
  size: number;
};
type ICircularProgressChildren = {
  children: React.ReactNode;
  size?: number;
};

type ICircularProgress = IMakeStyleCommonProps &
(ICircularProgressChildren | ICircularProgressWithChildren) & {
  thickness?: number;
  value?: number;
  contentPadding?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: props => props.progressSize,
    height: props => props.progressSize,
  },
  trail: {
    position: 'absolute',
    color: (props: IMakeStyleProps) =>
      props.trailColor ?? theme.palette.grey[200],
  },
  stroke: {
    position: 'absolute',
    color: (props: IMakeStyleProps) =>
      props.strokeColor ?? theme.palette.primary.main,
    animationDuration: (props: IMakeStyleProps) => !props.isStatic && '550ms',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

function CircularProgress(props: ICircularProgress) {
  const {
    children,
    trailColor,
    strokeColor,
    thickness = 2,
    size = 0,
    value = 100,
    isStatic = false,
    contentPadding = 0,
  } = props;
  const [progressSize, setProgressSize] = useState<number>(
    () => size + contentPadding,
  );
  const contentRef = useRef<HTMLDivElement | null>(null);

  const classes = useStyles({
    progressSize,
    trailColor,
    strokeColor,
    isStatic,
  });
  const mainVariant = isStatic ? 'determinate' : 'indeterminate';

  useLayoutEffect(() => {
    if (
      size === 0 &&
      Children.count(children) > 0 &&
      !isNullOrUndefined(contentRef.current)
    ) {
      const { offsetHeight: height, offsetWidth: width } = contentRef.current;
      const contentSize = height > width ? height : width;
      setProgressSize(() => contentSize + contentPadding);
    }
  }, [children, contentRef.current]);

  return (
    <div className={classes.root}>
      <MuiCircularProgress
        variant="determinate"
        className={classes.trail}
        size={progressSize}
        thickness={thickness}
        value={100}
      />
      <MuiCircularProgress
        variant={mainVariant}
        disableShrink
        className={classes.stroke}
        classes={{ circle: classes.circle }}
        size={progressSize}
        value={value}
        thickness={thickness}
      />
      <div className={classes.content} ref={contentRef}>
        {children}
      </div>
    </div>
  );
}

export default CircularProgress;
