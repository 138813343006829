import axios, { apiWrapper } from 'base/APIClient';
import { ONBOARDING, PROPERTY, FOOTER } from 'base/Url';

export const createCancellation = data => {
  const { CREATE_CANCELLATION } = ONBOARDING;
  return apiWrapper(axios.post(CREATE_CANCELLATION, data, { useLoader: true }));
};

export const deleteCancellation = (hotelCode, formData) => {
  const { DELETE_CANCELLATION } = ONBOARDING;
  const url = DELETE_CANCELLATION(hotelCode);
  return apiWrapper(axios.post(url, formData, { useLoader: true }));
};

export const fetchDocument = (id, useLoader = false) => {
  const { BANK_DETAILS } = PROPERTY;
  const url = BANK_DETAILS.FETCH_DOCUMENT(id);
  return apiWrapper(
    axios.get(url, { useLoader, responseType: 'blob' }).then(res => res),
  );
};

export const getBDMDetails = (hotelCode, useLoader = false) => {
  const { GET_FOOTER_DETAILS } = FOOTER;
  const url = GET_FOOTER_DETAILS(hotelCode);
  return apiWrapper(axios.get(url, { useLoader }));
};

export const updateRoomDetails = (data, id) => {
  const { POST_SUB_ROOM, UPDATE_SUB_ROOM } = ONBOARDING;
  if (id) {
    const url = UPDATE_SUB_ROOM(id);
    return apiWrapper(axios.put(url, data));
  }
  const { hotel_id: hotelId, ...others } = data;
  return apiWrapper(
    axios.post(
      POST_SUB_ROOM,
      { hotel_id: hotelId, rooms_detail: [others] },
      { useLoader: true },
    ),
  );
};

export type BdmDetailsType = {
  contractbdo?: { name?: string; number?: string; email?: string };
};
