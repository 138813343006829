import { FormElement } from 'components/new-core';
import { Switch, Calendar, useCalendar } from 'components/latest-core';

import './DatesForm.scss';

const DatesForm = props => {
  const {
    title,
    switchVal,
    onSwitchChange,
    onStartDateChange,
    onEndDateChange,
    startDate,
    endDate,
  } = props;
  const startDateProps = useCalendar({
    variant: 'single',
    initialDate: startDate,
    callbackAfterDateSelection: onStartDateChange,
  });
  const endEndProps = useCalendar({
    variant: 'single',
    initialDate: endDate,
    callbackAfterDateSelection: onEndDateChange,
  });

  return (
    <div className="dates-form-wrapper">
      <div className="dates-title-wrapper">
        <h4>{title}</h4>
        <span>
          <label>No End Date</label>
          <Switch
            checked={switchVal}
            onChange={checked => onSwitchChange(checked)}
            name="default"
          ></Switch>
        </span>
      </div>
      <div className="flex">
        <FormElement label="Start Date">
          <Calendar type="input" {...startDateProps} />
        </FormElement>
        {!switchVal && (
          <FormElement label="End Date">
            <Calendar type="input" {...endEndProps} />
          </FormElement>
        )}
      </div>
    </div>
  );
};

export default DatesForm;
