import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import UnsupportedBrowser from './special-screens/UnsupportedBrowser';

import { redirections, routes } from './routes';
import { AppContext } from '../utilities/Context';
import ErrorBoundary from './ErrorBoundary';
import MediaUploadProgress from './MediaUploadProgress';
import BulkUploadMain from './bulk-upload/BulkUploadMain';

import {
  isSupportedBrowser,
  replaceUrl,
  showGlobalLoader,
  showMessage,
} from 'utilities/Utils';

import { User } from '../interfaces/AppContextInterface';
import {
  IChainData,
  IDayUsePricePercentages,
  IHotel,
} from './hotel/interfaces';
import { ISTRINGS } from 'interfaces/Strings';
import { userProfileTypeMap } from './../constants';
import { UserProfileType } from './hotel/constants';
import HotelMain from './hotel/HotelMain';
import SENTRY from 'utilities/Sentry';
import Loader from 'components/Loader';
import WrappedLoader from './WrappedLoader';

interface AppProps {
  hotelList: IHotel[];
  chainData: IChainData;
  isStaff: boolean;
  user: User;
  dayUsePricePercentages: IDayUsePricePercentages;
  langStrings: ISTRINGS;
  lang: string;
  setLang: React.Dispatch<React.SetStateAction<string>>;
  isLoggedIn: boolean;
  isBdManager: boolean;
}

const GroupPropertyMain = React.lazy(
  () => import('../group-property/GroupPropertyMain'),
);

const staffPaths = [
  '/onboarding.*',
  '/manage.*',
  '/my-property',
  '/homepage.*',
];
const nonStaffPaths = [
  '/',
  '/my-property',
  '/onboarding.*',
  '/manage.*',
  '/groupchat.*',
];

const getValidGroupPaths = (paths: string[]) => {
  const regexString = `^(${paths.join('|')})$`;

  return new RegExp(regexString);
};

export default function App(props: AppProps) {
  const {
    hotelList,
    user,
    isStaff,
    chainData,
    dayUsePricePercentages,
    langStrings,
    setLang,
    lang,
    isBdManager,
  } = props;

  const { pathname, search } = useLocation();

  const [showUnsupported, setShowUnsupported] = useState(!isSupportedBrowser);

  useEffect(() => {
    window.addEventListener('unhandledrejection', function (event) {
      SENTRY.logFatalException(event.reason);
      event.preventDefault();
    });
  }, []);

  const showGroup = !isStaff && user?.profile.id !== 162424;
  const normalizedPathname = pathname.replace(/\/$/, '');

  const isGroupRoute = showGroup && normalizedPathname === '';
  useEffect(() => {
    window.addEventListener('unhandledrejection', function (event) {
      SENTRY.logFatalException(event.reason);
      event.preventDefault();
    });
  }, []);

  const groupPropertyPaths = getValidGroupPaths(
    !showGroup ? staffPaths : nonStaffPaths,
  );

  const shouldShowAddHotel =
    isStaff === false &&
    hotelList.filter(item => !item.is_onboarding).length === 0;
  const userProfileType: UserProfileType =
    userProfileTypeMap[user?.profile.user_legal_entity_type];

  const childRoutes = [];
  childRoutes.push(
    <Route
      key="group-property-qcFlow"
      path={routes.qcFlow.base}
      component={GroupPropertyMain}
    />,
  );
  // hidding as per product request

  childRoutes.push(
    <Route
      key="group-property-dlFlow"
      path={routes.duplicateListing.base}
      component={GroupPropertyMain}
    />,
  );
  childRoutes.push(
    <Route
      key="group-property-dlcreate"
      path={routes.duplicateListing.create}
      component={GroupPropertyMain}
    />,
  );
  if (shouldShowAddHotel) {
    childRoutes.push(
      <Route
        key="group-property"
        path={['/onboarding.*', '/my-property', '/']}
        exact
        component={GroupPropertyMain}
      />,
      <Route
        key="group-property"
        path={routes.onboarding.base}
        component={GroupPropertyMain}
      />,
    );
    childRoutes.push(
      <Redirect
        key="onboarding-redirection"
        to={routes.onboarding.myProperty}
      />,
    );
  } else {
    childRoutes.push(
      <Route
        key="group-property"
        path={groupPropertyPaths}
        exact
        component={GroupPropertyMain}
      />,
    );
    childRoutes.push(
      <Route
        key="hotel"
        render={({ location }) => {
          const { search: _search, pathname: _pathname } = location;
          const params = queryString.parse(_search);

          if (params.hotel_id) {
            localStorage.setItem('last_hotelcode', `${params.hotel_id}`);
            delete params.hotel_id;

            let newSearch = `?${queryString.stringify(params, {
              skipEmptyString: true,
              skipNull: true,
            })}`;
            if (newSearch === '?') {
              newSearch = '';
            }
            replaceUrl({ path: _pathname, query: newSearch });
          }

          return (
            <HotelMain
              chainData={chainData}
              hotelList={hotelList.filter(item => !item.is_onboarding)}
            />
          );
        }}
      />,
    );
  }

  const hideUnsupported = useCallback(() => setShowUnsupported(false), []);

  return (
    <AppContext.Provider
      value={{
        user,
        isStaff,
        showGroup,
        showGlobalLoader,
        showMessage,
        lang,
        setLang,
        strings: langStrings,
        hotelList,
        dayUsePricePercentages,
        userProfileType,
        isBdManager,
      }}
    >
      <ErrorBoundary>
        {showUnsupported ? (
          <UnsupportedBrowser acknowledge={hideUnsupported} />
        ) : (
          <Suspense fallback={<Loader className="global-loader-overlay" />}>
            <>
              {/* eslint-disable-next-line */}
              {!isGroupRoute && redirections[normalizedPathname] != null && (
                <Redirect to={redirections[normalizedPathname] + search} />
              )}
              <WrappedLoader />
              {/* add this search above  */}
              <MediaUploadProgress />
              <BulkUploadMain />
              <Switch>{childRoutes}</Switch>
            </>
          </Suspense>
        )}
      </ErrorBoundary>
    </AppContext.Provider>
  );
}
