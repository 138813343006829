import React from 'react';
import { ButtonGroup as MuiButtonGroup, alpha, PropTypes } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type ButtonGroupProps = Pick<
React.ComponentProps<typeof MuiButtonGroup>,
'style' | 'size' | 'fullWidth' | 'disabled'
> & {
  children: React.ReactNode;
  className?: string;
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary' | 'white';
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  groupedContainedPrimary: {
    '&.Mui-disabled': {
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
      color: 'white',
    },
  },
  groupedContainedSecondary: {
    '&.Mui-disabled': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
      color: 'white',
    },
  },
  groupedOutlinedWhite: {
    color: theme.palette.grey[900],
    '&:hover': {
      borderColor: theme.palette.action.disabledBackground,
    },
    '&.Mui-disabled': {
      color: alpha(theme.palette.grey[900], 0.5),
    },
  },
  groupedOutlinedPrimary: {
    '&.Mui-disabled': {
      color: alpha(theme.palette.primary.main, 0.5),
      borderColor: theme.palette.action.disabledBackground,
    },
  },
  groupedOutlinedSecondary: {
    '&.Mui-disabled': {
      color: alpha(theme.palette.secondary.main, 0.5),
      borderColor: theme.palette.action.disabledBackground,
    },
  },
}));

function ButtonGroup(props: ButtonGroupProps) {
  const {
    children,
    style,
    className = '',
    variant = 'outlined',
    color = 'primary',
    size = 'medium',
    fullWidth = false,
    disabled = false,
  } = props;

  const {
    root,
    groupedContainedPrimary,
    groupedContainedSecondary,
    groupedOutlinedPrimary,
    groupedOutlinedSecondary,
    groupedOutlinedWhite,
  } = useStyles();

  const commonProps = {
    style,
    classes: {
      root: `${className} ${root}`,
      groupedContainedPrimary,
      groupedContainedSecondary,
      groupedOutlinedPrimary,
      groupedOutlinedSecondary,
      groupedOutlinedWhite,
    },
    variant,
    color: color as Exclude<PropTypes.Color, 'default'>,
    size,
    disabled,
    fullWidth,
  };

  return <MuiButtonGroup {...commonProps}>{children}</MuiButtonGroup>;
}

export default ButtonGroup;
