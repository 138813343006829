import React, { useEffect, useMemo, useRef, useState } from 'react';
import { extractNumberInput } from 'utilities/Utils';
import { Typography, Button, Input } from 'components/latest-core';

import './OTPInput.scss';
type ValueType = string | number;

export type OTPInputProps<T extends ValueType> = {
  value: T;
  onChange: (value: T) => void;
  maxLength: number;
  data?: string | number;
  type?: 'email' | 'number';
  onChangeNumber?: () => void;
  verifyOTPHandler?: () => void;
  resendOTPHandler?: () => Promise<unknown>;
  error?: boolean;
  disabled?: boolean;
};

// End of type defination for OtpInput.tsx

const KEY_CODE_MAP = {
  LEFT: 37,
  RIGHT: 39,
  BACK: 8,
  DELETE: 46,
};

const useOtpInput = (value, onChange, maxLength = 4) => {
  const otpValue = extractNumberInput({
    target: { value },
  } as React.ChangeEvent<HTMLInputElement>).toString();

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const refList = [
    inputRef1,
    inputRef2,
    inputRef3,
    inputRef4,
    inputRef5,
    inputRef6,
  ];

  const inputList = [...new Array(maxLength)].map((_, index) => ({
    value: otpValue[index] || '',
    ref: refList[index],
  }));

  const otpLength = otpValue.length;

  const updateRefFocus = (index, forwardMove = true) => {
    let refIndex;
    if (forwardMove) {
      refIndex = index === maxLength - 1 ? maxLength - 1 : index + 1;
    } else {
      refIndex = index === 0 ? 0 : index - 1;
    }
    refList[refIndex].current.focus();
  };

  const onOTPKeyDown = ($event, refIndex) => {
    // backspace or delete
    const key = $event.keyCode || $event.charCode;
    if (key === KEY_CODE_MAP.BACK || key === KEY_CODE_MAP.DELETE) {
      if (refIndex <= otpLength) {
        const updatedOTP =
          otpValue.substr(0, refIndex) + otpValue.substr(refIndex + 1);
        onChange(updatedOTP);
      }
      updateRefFocus(refIndex, false);
    } else if (key === KEY_CODE_MAP.LEFT) {
      updateRefFocus(refIndex, false);
    } else if (key === KEY_CODE_MAP.RIGHT) {
      updateRefFocus(refIndex);
    }
  };

  const onOTPChange: (newValue: ValueType, refIndex: number) => void = (
    newValue,
    refIndex,
  ) => {
    // Inserting
    if (newValue === '' || otpLength === maxLength) {
      return;
    }
    const inputValue = newValue.toString().replace(otpValue[refIndex], '');
    const updatedOTP =
      otpValue.substring(0, refIndex) +
      inputValue +
      otpValue.substring(refIndex);
    onChange(updatedOTP);
    updateRefFocus(refIndex);
  };

  return { inputList, onOTPChange, onOTPKeyDown };
};

export default function OTPInput<T extends ValueType = string>(
  props: OTPInputProps<T>,
) {
  const {
    value,
    onChange,
    maxLength = 4,
    data,
    type = 'number',
    onChangeNumber = null,
    verifyOTPHandler = null,
    resendOTPHandler = null,
    error = false,
    disabled = false,
  } = props;
  const { inputList, onOTPChange, onOTPKeyDown } = useOtpInput(
    value,
    onChange,
    maxLength,
  );

  const [resendOTPEnabled, setResendOTPEnabled] = useState(false);
  const [seconds, setSeconds] = useState(10);

  const valueLength = useMemo(
    () =>
      extractNumberInput({
        target: { value },
      } as unknown as React.ChangeEvent<HTMLInputElement>).toString().length,
    [value],
  );

  const onResendOTPclick = () => {
    resendOTPHandler()
      .then(() => {
        setResendOTPEnabled(false);
        setSeconds(10);
      })
      .catch(err => {
        console.error('resendOTPHandler', err);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
        setResendOTPEnabled(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div className="otp-wrapper-latest-core">
      <div className="flex flex-col items-start">
        {data && (
          <div>
            Enter the {maxLength}-digit OTP sent to you at {data}.
          </div>
        )}
        {onChangeNumber && (
          <Button
            onClick={onChangeNumber}
            variant="text"
            className="mt-1"
            size="small"
          >
            {`Change ${type}`}
          </Button>
        )}
      </div>
      <div className="otp-input-wrapper-latest-core mt-4 mb-4">
        {inputList.map((input, index) => (
          <Input
            type="number"
            key={index}
            name={`otp-input-${index}`}
            className="otp-input"
            value={parseInt(input.value)}
            ref={input.ref}
            error={error}
            onChange={val => onOTPChange(val, index)}
            onKeyDown={val => onOTPKeyDown(val, index)}
            disabled={disabled}
            autoComplete="off"
          />
        ))}
        {verifyOTPHandler && (
          <Button
            onClick={verifyOTPHandler}
            variant="text"
            className="ml-8 mb-4"
            disabled={valueLength !== maxLength}
          >
            {'Verify'}
          </Button>
        )}
      </div>
      {error && (
        <Typography className="text-error-default -mb-[2]" variant="subtitle1">
          Invalid OTP
        </Typography>
      )}
      {resendOTPHandler && (
        <Button
          onClick={onResendOTPclick}
          variant="text"
          className="!normal-case"
          size="small"
          disabled={!resendOTPEnabled}
        >
          {'Resend OTP'} {seconds > 0 ? `in ${seconds} seconds` : ''}
        </Button>
      )}
    </div>
  );
}
