import { forwardRef } from 'react';
import ModalBase from './ModalBase';
import type { ModalPropTypes } from '../Modal.types';

function ModalConfirm(props: ModalPropTypes, ref?: React.Ref<HTMLDivElement>) {
  const { header = null, footer = null } = props;
  const customHeader = header
    ? {
      ...header,
      hideCross: true,
    }
    : header;
  const customFooter = footer
    ? {
      ...footer,
      hideFooterDivider: true,
    }
    : footer;
  return (
    <ModalBase
      ref={ref}
      {...props}
      footer={customFooter}
      header={customHeader}
    />
  );
}

export default forwardRef(ModalConfirm);
