import SLTouchPoints from 'modules/sponsored-listing/components/Persuasion/SLTouchPoints';
import React, { useEffect, useState } from 'react';
import { wrappedStrings } from '../../utilities/CustomHooks';
import OpportunityCentreDashboard from '../opportunity-center/OpportunityCentreDashboard';
import { DashBoardSaleCarousel } from './components/DashboardSaleCarousel';
import PerformanceHeader from './components/PerformanceHeader';
import {
  ContentScoreSection,
  InstayGuestIssuesSection,
  PendingRefundRequestsNew,
  PriorityBookingsSection,
  QualityScoreSection,
} from './sections';
import AnalyticsRedirectCard from './sections/AnalyticsRedirectCard';
import { HostWebMigration } from './sections/HostWebMigration';
import ManageCompetitors from './sections/ManageCompetitors';
import PerformanceMetrices from './sections/PerformanceMetrices';
import VDICard from './sections/VDICard';
import BankDetailsPersuasionSection from './sections/persuasions/BankDetailsPersuasionSection';
import { pushEventToGtm } from './utils/utils';
import type { DashboardProps } from './Dashboard.types';

const sections = {
  newBookings: 'new_bookings',
  newReviews: 'new_reviews',
  contentScore: 'content_score',
  roomNightsPerformance: 'performance',
  vdi: 'vdi',
  instayIssues: 'instay_guest_issues',
  refundRequests: 'pending_refund_requests',
  unverifiedUser: 'unverified_user',
  bankDetails: 'bank_details_persuasion',
  hostWebMigration: 'host_transition_persuasion',
  SLTouchPoints: 'sponsored_listing_persuasion',
};

const totalSections = Object.keys(sections).length;

const Dashboard = (props: DashboardProps) => {
  const { hotel } = props;
  const [successfullyLoadedLabels, setSuccessfullyLoadedLabels] = useState([]);
  const [counter, setCounter] = useState(totalSections);

  useEffect(() => {
    setSuccessfullyLoadedLabels([]);
    setCounter(totalSections);
  }, [hotel.hotelId]);

  function onSectionLoadUpdate(isSuccess: boolean, sectionLabel: string) {
    const updatedLabels = successfullyLoadedLabels;
    if (isSuccess) {
      updatedLabels.push(sectionLabel);
      setSuccessfullyLoadedLabels(updatedLabels);
    }

    const updatedCounter = counter - 1;
    setCounter(updatedCounter);
    if (updatedCounter === 0) {
      const label = updatedLabels.join('|');
      pushEventToGtm('dashboard', label, {}, 'web_page_load');
    }
  }

  return (
    <div className="sr-container">
      <div className="sr-flex-row posRel dashboard-container">
        <div id="main-content">
          <DashBoardSaleCarousel />
          <PerformanceMetrices>
            <PerformanceHeader />
            <AnalyticsRedirectCard />
          </PerformanceMetrices>
          <ManageCompetitors />
          {/** Switching off this feature as part of extranet cleanup */}
          {/* {
              <UserVerificationPersuasion
                gtmLabel={sections.unverifiedUser}
                onSectionLoadUpdate={onSectionLoadUpdate}
              />
              } */}
          <InstayGuestIssuesSection
            hotel={hotel}
            gtmLabel={sections.instayIssues}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          <PendingRefundRequestsNew
            hotel={hotel}
            gtmLabel={sections.refundRequests}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          <PriorityBookingsSection
            hotelId={hotel.hotelId}
            gtmLabel={sections.newBookings}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          {/** Switching off this feature as part of extranet cleanup */}
          {/* <NewReviewsSection
              hotel={hotel}
              gtmLabel={sections.newReviews}
              onSectionLoadUpdate={onSectionLoadUpdate}
            /> */}
        </div>
        <div id="side-content">
          <OpportunityCentreDashboard />
          <QualityScoreSection />
          <HostWebMigration
            gtmLabel={sections.hostWebMigration}
            hotel={hotel}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          <BankDetailsPersuasionSection
            gtmLabel={sections.bankDetails}
            hotel={hotel}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          <SLTouchPoints
            pageType="HOME"
            gtmLabel={sections.SLTouchPoints}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          <ContentScoreSection
            key={hotel}
            gtmLabel={sections.contentScore}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          <VDICard
            hotel={hotel}
            gtmLabel={sections.vdi}
            onSectionLoadUpdate={onSectionLoadUpdate}
          />
          {/** Switching off this feature as part of extranet cleanup */}
          {/* <PerformanceSection
              hotel={hotel}
              gtmLabel={sections.roomNightsPerformance}
              width={360}
              onSectionLoadUpdate={onSectionLoadUpdate}
            /> */}
        </div>
      </div>
    </div>
  );
};
export default wrappedStrings(Dashboard, 'Dashboard');
