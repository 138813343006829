import { classNames } from 'utilities/Utils';
import { useMemo } from 'react';
import { Typography } from 'components/latest-core';
import { getCustomRanges } from '../Calendar.utils';
import { isDateSame } from 'utilities/DateUtils';
import { InvalidDates, UseVariantCalendarReturnProp } from '../Calendar.types';

export type RangePickerMenuOption =
  | 'today'
  | 'yesterday'
  | 'this-week'
  | 'last-week'
  | 'this-month'
  | 'last-month'
  | 'this-year'
  | 'last-year'
  | 'all-time';

type RangePickerMenuProps = {
  calendarProps: UseVariantCalendarReturnProp<'range'>;
  optionsToShow: RangePickerMenuOption[];
  invalidDates: InvalidDates;
  selectAndNavigateDate: ({
    selectedDays,
    calendarProps,
  }: {
    selectedDays: { from: Date; to: Date };
    calendarProps: UseVariantCalendarReturnProp<'range'>;
  }) => void;
};

export const RangePickerMenu = (props: RangePickerMenuProps) => {
  const {
    calendarProps,
    optionsToShow = [
      'today',
      'yesterday',
      'this-week',
      'last-week',
      'this-month',
      'last-month',
      'this-year',
      'last-year',
      'all-time',
    ],
    invalidDates: [disabledDays, ...blockedDays],
    selectAndNavigateDate,
  } = props;
  const { selectedDays } = calendarProps;
  const menuOptions = useMemo(() => {
    const options = getCustomRanges();
    return optionsToShow.map(option => {
      const range = options[option].get();
      const { from, to } = range;
      const isFromDisabled =
        from > disabledDays.after ||
        from < disabledDays.before ||
        blockedDays.includes(from);
      const isToDisabled = to
        ? to > disabledDays.after ||
          to < disabledDays.before ||
          blockedDays.includes(to)
        : false;
      const isActive =
        selectedDays.from && selectedDays.to && from && to
          ? isDateSame(selectedDays.from, from) &&
            isDateSame(selectedDays.to, to)
          : false;
      return {
        label: options[option].label,
        range,
        key: option,
        disabled: isFromDisabled || isToDisabled,
        active: isActive,
      };
    });
  }, [optionsToShow, selectedDays]);

  return (
    <div className=" px-4 py-3 border-solid border-[1px] border-y-0 border-l-0 border-color-divider">
      {menuOptions.map(option => (
        <div
          key={option.key}
          className={classNames(
            'rounded-md pl-4 pr-7 py-3 cursor-pointer hover:bg-color-primaryLighter',
            option.disabled && 'bg-color-light pointer-events-none',
            option.active && 'bg-color-primaryLighter',
          )}
          onClick={() => {
            if (!option.disabled) {
              selectAndNavigateDate({
                selectedDays: option.range,
                calendarProps,
              });
            }
          }}
        >
          <Typography
            variant="subtitle1"
            themeColor={option.active ? 'primary.main' : 'common.black'}
            fb
          >
            {option.label}
          </Typography>
        </div>
      ))}
    </div>
  );
};
