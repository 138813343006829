import { forwardRef } from 'react';
import { OutlinedInput, InputAdornment } from '@mui/material';
import * as CommonIcons from 'assets/common';
import {
  classNames,
  extractNumberInput,
  getDataPropsFromRest,
} from 'utilities/Utils';

import type { InputProps } from '../types/input.type';
import { useStyles } from '../styles/input.styles';

const InputComp = (props: InputProps, ref?: React.Ref<HTMLDivElement>) => {
  const {
    type = 'text',
    readValueOnChange = x => x,
    placeholder = '',
    disabled = false,
    value = '',
    onChange = x => x,
    onBlur,
    disableClear = true,
    leftElement,
    rightElement,
    error = false,
    format,
    onClick,
    readOnly = false,
    style,
    orientation = 'left',
    size = 'medium',
    name = '',
    autoFocus = false,
    min = 0, //For type number
    max, //For type number,
    charLimit,
    decimalPoints = 2,
    prefix = '',
    suffix = '',
    className = '',
    onFocusCapture,
    convertToUppercase = false,
    onKeyDown,
    autoComplete,
    elementClassNames = '',
    ...rest
  } = props;
  const classes = useStyles({ orientation });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: targetName } = event.target;
    if (type === 'text' || type === 'password') {
      let val = event.target.value;

      if (convertToUppercase) {
        val = event.target.value.toUpperCase();
      }
      // @ts-ignore
      readValueOnChange(val);
      // @ts-ignore
      onChange(val, targetName);
    } else if (type === 'number') {
      const numberInput = extractNumberInput(event, decimalPoints);
      // @ts-ignore
      onChange(numberInput, targetName);
      // @ts-ignore
      readValueOnChange(numberInput);
    }
  };

  const handleOnWheel = e => {
    // to disable changing values on scroll when input type is number
    if (type === 'number' && e.target instanceof HTMLElement) {
      e.target.blur();
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent the input of the dash character
    if (type === 'number' && event.key === '-') {
      event.preventDefault();
    }
  };

  const dataTestIdProps = getDataPropsFromRest(rest);

  return (
    <OutlinedInput
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      name={name}
      type={type}
      inputRef={ref}
      className={classNames(
        classes.root,
        size === 'small' ? classes.small : '',
        className,
      )}
      onWheel={handleOnWheel}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}
      onBlur={onBlur}
      onFocusCapture={onFocusCapture}
      onClick={onClick}
      value={format ? format(value as string) : value}
      endAdornment={
        rightElement ? (
          <InputAdornment position="end">
            <div className="pr-3">{rightElement}</div>
            {suffix && <div className={classes.prefixSuffix}>{suffix}</div>}
          </InputAdornment>
        ) : !disableClear ? (
          <InputAdornment position="end">
            <CommonIcons.Close
              className={classes.endCloseIcon}
              // @ts-ignore
              onClick={() => (onChange ? onChange('', name) : null)}
            />
            {suffix && <div className={classes.prefixSuffix}>{suffix}</div>}
          </InputAdornment>
        ) : suffix ? (
          <InputAdornment position="end">
            {suffix && <div className={classes.prefixSuffix}>{suffix}</div>}
          </InputAdornment>
        ) : null
      }
      startAdornment={
        (leftElement || prefix) && (
          <InputAdornment position="start">
            {prefix && <div className={classes.prefixSuffix}>{prefix}</div>}
            {leftElement && (
              <div className={`${!prefix ? 'pl-3' : ''} ${elementClassNames}`}>
                {leftElement}
              </div>
            )}
          </InputAdornment>
        )
      }
      inputProps={{
        min,
        max,
        maxLength: charLimit,
        ...dataTestIdProps,
        className: classes.input,
        style: {
          textAlign: orientation,
        },
      }}
      error={error}
      readOnly={readOnly}
      style={style}
      classes={{
        adornedStart: classes.adornedStart,
        adornedEnd: classes.adornedEnd,
      }}
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'black',
        },
      }}
      onKeyDown={onKeyDown}
      onKeyPress={handleKeyPress}
    />
  );
};

const Input = forwardRef(InputComp);
export { Input };
