import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import {
  LErrorClose,
  ErrorMsgIcon,
  LInfoClose,
  LInfoIcon,
  LNotificationIcon,
  LSuccessClose,
  LSuccessIcon,
  LWarningClose,
  LWarningIcon,
} from 'assets/common';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import { classNames } from 'utilities/Utils';

const getIconColorCodesMapping = {
  success: {
    icon: <LSuccessIcon />,
    crossIcon: <LSuccessClose />,
  },
  info: {
    icon: <LInfoIcon />,
    crossIcon: <LInfoClose />,
  },
  warning: {
    icon: <LWarningIcon />,
    crossIcon: <LWarningClose />,
  },
  error: {
    icon: <ErrorMsgIcon />,
    crossIcon: <LErrorClose />,
  },
};
const getIconColorCodesMappingForNotification = {
  true: {
    icon: <LNotificationIcon />,
    crossIcon: <LWarningClose />,
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& [class*="MuiAlert-icon"]': {
      marginRight: '10px',
      padding: 0,
    },
    '& [class*="MuiPaper-root"]': {
      padding: '4px 6px',
      borderRadius: 'unset',
    },
    '& [class*="MuiAlert-root"]': {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 6px',
      '& [class*="MuiBox-root"]': {
        'line-height': '16px',
      },
    },
    '& [class*="MuiAlert-message"]': {
      flex: '1',
      padding: '0',
    },
    '& [class*="MuiAlert-standardError"]': {
      border: '1px solid #E57373',
      backgroundColor: '#FFEBEE !important',
      '& [class*="MuiButton-containedPrimary"]': {
        backgroundColor: '#C62828 !important',
      },
    },
    '& [class*="MuiAlert-standardWarning"]': {
      backgroundColor: '#FFF8EE !important',
      border: '1px solid #FADCAC',
      '& [class*="MuiButton-containedPrimary"]': {
        backgroundColor: '#F2A526 !important',
      },
    },
    '& [class*="MuiAlert-standardInfo"]': {
      backgroundColor: '#EFF1FF !important',
      border: '1px solid #C2C7EC',
      '& [class*="MuiButton-containedPrimary"]': {
        backgroundColor: '#3543BF !important',
      },
    },
    '& [class*="MuiAlert-standardSuccess"]': {
      backgroundColor: '#E8FFF0 !important',
      border: '1px solid #7CE3C0',
      '& [class*="MuiButton-containedPrimary"]': {
        backgroundColor: '#2E7D32 !important',
      },
    },
    '& [class*="MuiAlert-standardSuccess"][class*="no-broder"]': {
      '& [class*="MuiTypography-root"]': {
        color: '#2E7D32 !important',
        fontSize: '14px',
      },
    },
    '& [class*="no-broder"]': {
      backgroundColor: 'unset !important',
      border: 'none !important',
    },
  },
  textPrimary: {
    '& [class*="MuiButton-textPrimary"]': {
      textDecoration: 'underline',
    },
  },
  bgCTAColor: {
    backgroundColor: '',
  },
  linkColor: {
    color: '#0B61D9',
  },
}));

export type InlineMsgAlertsProps = {
  onClose?:
  | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
  | undefined;
  severity: 'success' | 'info' | 'warning' | 'error';
  variant?: 'filled';
  message: React.ReactNode;
  infoMessage?: React.ReactNode;
  action?: {
    type: 'success' | 'info' | 'warning' | 'error';
    onClick: () => void;
  };
  cta?: {
    buttonText: string | React.ReactNode;
    onClick: () => void;
    className?: string;
    size?: 'small' | 'medium' | 'large';
    endIcon?: React.ElementType;
  };
  link?: React.ReactNode;
  onClick?:
  | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
  | undefined;
  isNotification?: boolean;
  linkButtonSize?: 'small' | 'medium' | 'large';
  rootClassName?: string;
  inerClasses?: string;
  inerActionClasses?: string;
  linkClasses?: string;
  icon?: React.ReactNode;
};

function InlineMsgAlerts(props: InlineMsgAlertsProps) {
  const {
    severity,
    message,
    variant,
    infoMessage,
    link = null,
    cta = null,
    onClick,
    action = null,
    isNotification = false,
    linkButtonSize = 'small',
    rootClassName = '',
    inerClasses = '',
    inerActionClasses = '',
    linkClasses = '',
    icon = null,
  } = props;
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${rootClassName}`}>
      <Alert
        variant={variant}
        severity={severity}
        icon={
          icon
            ? icon
            : isNotification
              ? getIconColorCodesMappingForNotification[`${isNotification}`].icon
              : getIconColorCodesMapping[`${severity}`].icon
        }
        className={`${inerClasses} w-auto `}
      >
        <Box
          className={`d-flex justify-between items-center text-container ${inerActionClasses}`}
        >
          <Typography
            variant="subtitle1"
            themeColor="gray.main"
            className="flex-1"
          >
            {message}
          </Typography>

          <Box className="d-flex justify-between items-center">
            {infoMessage && (
              <Typography variant="subtitle1" themeColor="text.link">
                {infoMessage}
              </Typography>
            )}

            {link && (
              <Box
                className={classNames(classes.textPrimary, 'ml-2', linkClasses)}
              >
                <Button
                  variant="text"
                  className={classes.linkColor}
                  onClick={onClick}
                  size={linkButtonSize}
                >
                  <Typography
                    variant="subtitle1"
                    themeColor="text.link"
                    className="w-max"
                  >
                    {link}
                  </Typography>
                </Button>
              </Box>
            )}
            {cta && (
              <Button
                className={`ml-2 ${cta.className}`}
                size={cta.size || 'small'}
                onClick={cta.onClick}
                IconCompRight={cta.endIcon}
              >
                {cta.buttonText}
              </Button>
            )}
            {action && (
              <Box className="padL14" onClick={action.onClick}>
                {getIconColorCodesMapping[`${severity}`].crossIcon}
              </Box>
            )}
          </Box>
        </Box>
      </Alert>
    </div>
  );
}

export default InlineMsgAlerts;
