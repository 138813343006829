import type { Palette } from '@mui/material';
import type { TypographyOptions } from '@mui/material/styles/createTypography';

export const getTypographyVariant = (palette: Palette): TypographyOptions => {
  const commonTypography = {
    fontStyle: 'normal',
    color: palette.text.primary,
  };

  return {
    fontFamily: 'Roboto',

    // Heading H1 - Bold 28px
    h1: {
      ...commonTypography,
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '42px',
    },

    // Heading H2 - Medium 24px
    h2: {
      ...commonTypography,
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
    },

    // Heading H3 - Medium 18px
    h3: {
      ...commonTypography,
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '27px',
    },

    // Sub heading text - regular 16PX
    subHeadingRegular: {
      ...commonTypography,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
    },

    // Sub heading text - Medium 16PX
    subHeadingMedium: {
      ...commonTypography,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },

    // Body text - Regular 14px
    body1: {
      ...commonTypography,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },

    // Body text - Medium 14PX
    body2: {
      ...commonTypography,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },

    // Body link - regular 14PX
    link: {
      ...commonTypography,
      fontSize: '14px',
      lineHeight: '20px',
      textDecoration: 'underline',
      color: palette.text.link,
      cursor: 'pointer',
    },

    // Button text - Bold 14px
    buttonText: {
      ...commonTypography,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.01em',
      textTransform: 'uppercase',
    },

    // Section heading - Medium 12px
    sectionHeading: {
      ...commonTypography,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.02em',
      textTransform: 'uppercase',
    },

    // Subtext - Regular 12px
    subtitle1: {
      ...commonTypography,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.02em',
    },

    // Subtext - Regular 10px
    subtitle2: {
      ...commonTypography,
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: 400,
      letterSpacing: '0.02em',
    },

    // Subtext - Medium 12px
    subtextMedium: {
      ...commonTypography,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.02em',
      textTransform: 'uppercase',
    },

    // Legends - Regular 10px
    legends: {
      ...commonTypography,
      fontSize: '10px',
      lineHeight: '14px',
      letterSpacing: '0.02em',
    },

    // Shout value - Bold 32px
    shoutValue: {
      ...commonTypography,
      fontSize: '32px',
      lineHeight: '42px',
      letterSpacing: '0.02em',
    },
  };
};
