import { Typography } from 'components/latest-core';
import { useLangStrings } from 'utilities/CustomHooks';
import { getFormattedDate, getFutureDate } from 'utilities/DateUtils';
import { useEffect } from 'react';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { navigateTo } from 'utilities/Utils';
import { dateMonthYear } from '../../../constants';

type PerformanceCardProps = {
  title: string;
  shoutOut: {
    prefix?: string;
    val: string;
    isInfo: boolean;
  };
  last7Days: string;
  to: string;
  fetchCardData: (id: string) => void;
};

const PerformanceCard = ({
  title,
  shoutOut,
  last7Days,
  fetchCardData,
  to,
}: PerformanceCardProps) => {
  const [STRINGS, COMMON_STRINGS] = useLangStrings<'Dashboard'>('Dashboard');

  const onViewMoreClicked = to => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'view_more_details',
        type: 'click',
        componentName: `performance_overview|${title}`,
      },
      loadedComponents: `dashboard|${title}_card`,
    });
    navigateTo(to);
  };

  const onRetryClick = () => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'retry',
        type: 'click',
        componentName: `performance_overview|${title}`,
        misc_id_3: 'last_7_Days',
      },
    });
    fetchCardData(title);
  };

  useEffect(() => {
    if (last7Days === 'NA%') {
      pushToOmniture({
        event: OMNITURE_CATEGORIES.CTA_LOAD,
        cta: {
          name: 'retry',
          type: 'load',
          componentName: `performance_overview|${title}`,
          misc_id_3: 'last_7_Days',
        },
      });
    }
  }, [last7Days]);
  return (
    <>
      <div className="flex mb-2 items-center">
        <Typography variant="body2" className="mr-2">
          {title}
        </Typography>
        {shoutOut.isInfo && (
          <Typography variant="sectionHeading" className="!normal-case">
            {`(${COMMON_STRINGS.LABELS.LAST_UPDATED.replace(
              '__time__',
              getFormattedDate(getFutureDate(new Date(), -1), dateMonthYear),
            )})`}
          </Typography>
        )}
      </div>

      <div className="flex items-center mb-1">
        {shoutOut?.prefix && (
          <Typography
            variant="body2"
            className="!text-common-light relative top-[3px]"
          >
            {shoutOut.prefix}&nbsp;
          </Typography>
        )}
        <Typography variant="h2" className="mr-2">
          {shoutOut.val}
        </Typography>
      </div>
      {last7Days !== 'NA%' ? (
        <Typography
          variant="sectionHeading"
          className="text-text-primary mb-[10px]"
        >
          {STRINGS.SUMMARY_CARD.LAST_7_DAYS.replace(
            '__value__',
            ` : ${last7Days}`,
          )}
        </Typography>
      ) : (
        <div className="flex mb-[10px]">
          <Typography variant="sectionHeading" className="mr-1 !normal-case">
            {COMMON_STRINGS.MESSAGES.SOME_DATA_POINTS_NOT_LOADING}
          </Typography>
          <Typography
            variant="sectionHeading"
            className="capitalize text-primary-default cursor-pointer"
            onClick={onRetryClick}
          >
            {COMMON_STRINGS.MESSAGES.RETRY.replaceAll('!', '')}
          </Typography>
        </div>
      )}

      {/* Dont remove it, it will be taken live with milestone 2 */}
      <Typography
        onClick={() => onViewMoreClicked(to)}
        fb
        isLink
        themeColor="primary.main"
      >
        {COMMON_STRINGS.BUTTONS.VIEW_MORE}
      </Typography>
    </>
  );
};

export default PerformanceCard;
