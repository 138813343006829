import { routes } from 'app/routes';
import { RTBIcons } from 'assets/common';
import { Button, Modal } from 'components/latest-core';
import { useContext, useEffect } from 'react';
import { HotelContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import { navigateTo } from 'utilities/Utils';
import { pushToOmniture } from 'utilities/gtm';
import { LOGOUT_COOKIES } from '../reseller.constants';
import { UserProfileViewType } from 'app/hotel/constants';
import {
  RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
  RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE,
} from '../../../constants';

type SuccessPopupProps = {
  accountType: 'new' | 'existing';
};

const SuccessPopup = (props: SuccessPopupProps) => {
  const { accountType } = props;

  const { updateHotelDetail, setUserProfileView } = useContext(HotelContext);

  const [STRINGS] = useLangStrings<'Reseller'>('Reseller');

  const handleGoToHotelTravelClick = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'account_switch_new',
        componentName: 'hoteltravel_onboarding',
      },
    });
    document.cookie = LOGOUT_COOKIES;
  };

  const handleNavigation = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'account_switch_resume',
        componentName: 'hoteltravel_onboarding',
      },
    });
    sessionStorage.setItem(
      RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_KEY,
      RESELLER_ONBOARDING_REDIRECTED_FROM_STORAGE_VALUE,
    );
    setUserProfileView(UserProfileViewType.HOTEL_TRAVEL);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateHotelDetail();
    navigateTo(routes.bookings.BookingsListBase);
  };

  useEffect(() => {
    let timer;
    if (accountType === 'existing') {
      timer = setTimeout(handleNavigation, 5000);
    }

    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal isOpen>
      <div className="flex flex-col items-center justify-center">
        <RTBIcons.GreenTickIconComp height={128} width={128} />
        <div className="font-[500] text-[18px] ">
          {STRINGS.SUCCESS_POPUP.MESSAGE}
        </div>
        {accountType === 'new' ? (
          <>
            <Button onClick={handleNavigation} className="mt-6">
              {STRINGS.CTA_TEXT.CONTINUE_WITH_LOGGED_IN}
            </Button>
            <div className="font-[500] text-[16px] my-4">OR</div>
            <Button variant={'outlined'} onClick={handleGoToHotelTravelClick}>
              <a href="/logout/">{STRINGS.CTA_TEXT.GO_TO_HOTEL_TRAVEL}</a>
            </Button>
          </>
        ) : (
          <div className="mt-2 text-[16px] text-center mb-10">
            {STRINGS.SUCCESS_POPUP.EXISTING_ACCOUNT_MESSAGE}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SuccessPopup;
