import { Input, Radio } from 'components/latest-core';
import { FormElement } from 'components/new-core';
import { useEffect, useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  EarlyBirdOpts,
  LMDOptions,
  LMDSuggestions,
  LOSOpts,
  LosSuggestions,
  bundleOptions,
  defaultAlluserDiscount,
  defaultLoggedInDiscount,
  earlyBirdSuggestions,
  getOfferTypeOptions,
  newPromotionTypes,
  offerTypes,
} from '../Promotions.constants';
import { DiscountInput, PromotionWindowWrapper } from '../components';

const suggestionsTypeMap = {
  [newPromotionTypes.LOS]: LosSuggestions,
  [newPromotionTypes.EBD]: earlyBirdSuggestions,
  [newPromotionTypes.LMD]: LMDSuggestions,
  [newPromotionTypes.DAY]: [],
};

const ConfigureOffer = props => {
  const {
    promotionType,
    defaultDiscount,
    currency,
    isFreeNightCustomOffer,
    isEditFlowOnly,
    configureOfferState: cFS,
    isBundled,
  } = props;
  const [STRINGS] = useLangStrings('Promotions');
  const NSTRINGS = STRINGS.CREATE_SECTIONS.CONFIGURE_OFFER;
  const showFNDUI =
    promotionType === newPromotionTypes.FND || isFreeNightCustomOffer;
  const isDateOrCustomDeal =
    promotionType === newPromotionTypes.DATE ||
    promotionType === newPromotionTypes.CUSTOM;
  const isOfferTypePercent = cFS.offerType === offerTypes.percentage;

  const freeNightDealUI = (
    <FormElement label={NSTRINGS.FN_LABEL} style={{ width: 'auto' }} isRequired>
      <span>
        {NSTRINGS.FN_DESC[0]}{' '}
        <Input
          type="number"
          className="marginLR5 max-w-[55px]"
          value={cFS.bookNights}
          onChange={value => cFS.setBookNights(value)}
        />{' '}
        {NSTRINGS.FN_DESC[1]} {NSTRINGS.FN_DESC[2]}{' '}
        <Input
          type="number"
          className="marginLR5 max-w-[55px]"
          value={cFS.freeNights}
          onChange={value => cFS.setFreeNights(value)}
        />{' '}
        {NSTRINGS.FN_DESC[3]}
      </span>
    </FormElement>
  );

  return (
    <tr>
      <td>{NSTRINGS.NAME}</td>
      <td>
        {showFNDUI ? (
          freeNightDealUI
        ) : (
          <>
            <FormElement
              label={NSTRINGS.LABEL1}
              isRequired
              className="width100"
            >
              <Radio
                value={cFS.offerType}
                options={getOfferTypeOptions(isEditFlowOnly)}
                disabled={isEditFlowOnly}
                onChange={cFS.setOfferType}
                variant="legacy-boxed"
                inline
              />
            </FormElement>
            {isDateOrCustomDeal ? (
              <DiscountInput
                size={'large'}
                currency={currency}
                isOfferTypePercent={isOfferTypePercent}
                cFS={cFS}
                offer={cFS.offerList[0]}
                isBundled={isBundled}
              />
            ) : (
              <PromotionWindowWrapper
                promotionType={promotionType}
                defaultDiscount={defaultDiscount}
                currency={currency}
                isEditFlowOnly={isEditFlowOnly}
                configureOfferState={cFS}
                suggestionsTypeMap={suggestionsTypeMap}
                isBundled={isBundled}
              />
            )}
          </>
        )}
      </td>
    </tr>
  );
};

/**Configure Offer State Constants */
const defaultBookNights = 2;
const defaultFreeNights = 1;

const getOffer = (type, suggestionObj, dis) => {
  let bookWithinDays = null;
  let checkInWeekDayList = [];
  if (type === newPromotionTypes.LMD) {
    bookWithinDays = suggestionObj
      ? suggestionObj.suggestionDays
      : LMDOptions[3];
  }
  if (type === newPromotionTypes.EBD) {
    bookWithinDays = suggestionObj
      ? suggestionObj.suggestionDays
      : EarlyBirdOpts[0];
  }
  if (type === newPromotionTypes.LOS) {
    bookWithinDays = suggestionObj ? suggestionObj.suggestionDays : LOSOpts[1];
  }
  if (type === newPromotionTypes.DAY) {
    checkInWeekDayList = [0, 1, 2, 3, 4, 5, 6];
  }
  return {
    allPercentDiscount: suggestionObj
      ? suggestionObj.allDis
      : defaultAlluserDiscount,
    allFixedDiscount: 0,
    loggedInPercentDiscount: dis ? dis : defaultLoggedInDiscount,
    loggedInFixedDiscount: 0,
    bookWithinDays,
    checkInWeekDayList,
  };
};

export function useCFOfferState(
  type,
  dis,
  promotionData,
  allSuggestions = false,
) {
  const [offerType, setOfferType] = useState(
    promotionData?.offerType ?? offerTypes.percentage,
  );

  const [bookNights, setBookNights] = useState(
    promotionData?.bookNights ?? defaultBookNights,
  );
  const [freeNights, setFreeNights] = useState(
    promotionData?.freeNights ?? defaultFreeNights,
  );
  const [offerList, setOfferList] = useState(
    promotionData?.offerList ?? [getOffer(type, null, dis)],
  );

  function getAllSuggestions() {
    if (!type) {
      return [];
    }
    const mappedSuggestions = suggestionsTypeMap[type].map(item => {
      return getOffer(type, item, dis);
    });
    return mappedSuggestions;
  }

  const updateOfferList = () => {
    setOfferList(
      promotionData?.offerList ??
        (allSuggestions ? getAllSuggestions() : [getOffer(type, null, dis)]),
    );
  };
  const setAllPercentDiscount = (value, index = 0) => {
    setOfferList(pre => {
      const updated = [...pre];
      updated[index] = {
        ...updated[index],
        allPercentDiscount: value,
      };
      return updated;
    });
  };

  const setAllFixedDiscount = (value, index = 0) => {
    setOfferList(pre => {
      const updated = [...pre];
      updated[index] = {
        ...updated[index],
        allFixedDiscount: value,
      };
      return updated;
    });
  };

  const setLoggedInPercentDiscount = (value, index = 0) => {
    setOfferList(pre => {
      const updated = [...pre];
      updated[index] = {
        ...updated[index],
        loggedInPercentDiscount: value,
      };
      return updated;
    });
  };

  const setLoggedInFixedDiscount = (value, index = 0) => {
    setOfferList(pre => {
      const updated = [...pre];
      updated[index] = {
        ...updated[index],
        loggedInFixedDiscount: value,
      };
      return updated;
    });
  };

  const setBookWithinDays = (value, index = 0) => {
    setOfferList(pre => {
      const updated = [...pre];
      updated[index] = {
        ...updated[index],
        bookWithinDays: value,
      };
      return updated;
    });
  };

  const setCheckInWeekDayList = (value, index = 0) => {
    setOfferList(pre => {
      const updated = [...pre];
      updated[index] = {
        ...updated[index],
        checkInWeekDayList: value,
      };
      return updated;
    });
  };

  const addNewWindow = (
    promotionType,
    defaultDiscount,
    suggestionObj = null,
  ) => {
    const offer = getOffer(promotionType, suggestionObj, defaultDiscount);

    setOfferList(pre => [...pre, offer]);
  };

  useEffect(() => {
    updateOfferList();
  }, [type]);

  return {
    offerType,
    setOfferType,
    bookNights,
    setBookNights,
    freeNights,
    setFreeNights,
    offerList,
    setOfferList,
    setAllPercentDiscount,
    setAllFixedDiscount,
    setLoggedInPercentDiscount,
    setLoggedInFixedDiscount,
    addNewWindow,
    setBookWithinDays,
    setCheckInWeekDayList,
    updateOfferList,
  };
}

function parseFND(state) {
  return {
    night: state.freeNights,
    minLos: Number(state.bookNights),
  };
}

export function parseDate(state, isBundled = false) {
  const isOfferTypePercent = state.offerType === offerTypes.percentage;
  const offer = state.offerList?.[0] ?? state;
  if (!offer) {
    return null;
  }

  let offerValueList = [
    {
      channel: 'all',
      isActive: true,
      offerBasis: 'discount',
      offerType: state.offerType,
      offerValue: isOfferTypePercent
        ? offer.allPercentDiscount
        : offer.allFixedDiscount,
      segment: isBundled ? bundleOptions[1].value : bundleOptions[0].value,
    },
  ];
  if (!isBundled) {
    const loggedInUserObj = {
      channel: 'all',
      isActive: true,
      offerBasis: 'discount',
      offerType: state.offerType,
      offerValue: isOfferTypePercent
        ? offer.allPercentDiscount + offer.loggedInPercentDiscount
        : offer.allFixedDiscount + offer.loggedInFixedDiscount,
      segment: 'loggedin',
    };
    offerValueList = [...offerValueList, loggedInUserObj];
  }
  return {
    offerValueList: offerValueList,
  };
}

export function parseOfferList(offer, promotionType, isBundled = false) {
  const extraParams: {
    earlyBirdMax?: number;
    earlyBirdMin?: number;
    minLos?: number;
    checkinWeekDay?: string[];
  } = {};

  switch (promotionType) {
    case newPromotionTypes.LMD:
      extraParams.earlyBirdMax = offer.bookWithinDays.value;
      break;
    case newPromotionTypes.EBD:
      extraParams.earlyBirdMin = offer.bookWithinDays.value;
      break;
    case newPromotionTypes.LOS:
      extraParams.minLos = offer.bookWithinDays?.value;
      break;
    case newPromotionTypes.DAY:
      extraParams.checkinWeekDay = offer.checkInWeekDayList.map(item =>
        String(item),
      );
      break;
    default:
  }
  return {
    ...extraParams,
    ...parseDate(offer, isBundled),
  };
}

export function getPayloadFrCF(CFstate, promotionType) {
  switch (promotionType) {
    case newPromotionTypes.FND:
      return parseFND(CFstate);
    case newPromotionTypes.DATE:
    case newPromotionTypes.CUSTOM:
      return parseDate(CFstate, false);
    default:
      return {};
  }
}

export default ConfigureOffer;
