import { makeStyles } from '@mui/styles';

import { type StyleProps } from './Carousel.types';

export const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
    },
    slider: (props: StyleProps) => ({
      width: props.multiView ? 'auto' : 'inherit',
      overflow: 'hidden',
    }),
    slides: (props: StyleProps) => ({
      width: props.multiView ? 'auto' : 'inherit',
      display: 'flex',
      overflow: 'hidden',
      gap: '8px',
    }),
    slide: {
      width: 'inherit',
      flexShrink: 0,
    },
    indicatorContainer: {
      width: '100%',
      margin: 'auto',
      display: 'flex',
      gap: '8px',
      paddingTop: '8px',
      paddingBottom: '16px',
      justifyContent: 'center',
    },
    indicator: {
      width: '40px',
      height: '4px',
      borderRadius: '4px',
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.light,
    },
    activeIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    disabledIndicator: {
      pointerEvents: 'none',
    },
    leftButton: {
      zIndex: 1,
    },
    rightButton: {
      zIndex: 1,
    },
  };
});
