import { Checkbox, ListItemText } from '@mui/material';
import { ActiveMixCheckbox } from 'assets/common';
import { isNullOrUndefined } from 'utilities/Utils';

type SelectAllProps = {
  values?: unknown[];
  options: unknown[];
  onSelectAll: (selectedItems: unknown[]) => void;
};

const SelectAll = ({
  values = [],
  options = [],
  onSelectAll,
}: SelectAllProps) => {
  const valueLength = (Array.isArray(values) && values.length) || 0;
  const isSelectAll = valueLength === options.length;

  const shouldShowCheckbox =
    isSelectAll || isNullOrUndefined(values) || valueLength === 0;
  const handleSelectAll = () => {
    if (isSelectAll || valueLength > 0) {
      onSelectAll([]);
    } else {
      onSelectAll(options);
    }
  };
  return (
    <div onClick={handleSelectAll} className="select-all-wrapper">
      {shouldShowCheckbox && (
        <Checkbox checked={isSelectAll} className="ml-0 p-0" />
      )}
      {!shouldShowCheckbox && <ActiveMixCheckbox />}
      <ListItemText
        primary={
          valueLength === 0
            ? 'Select All'
            : `${
              Array.isArray(values)
                ? isSelectAll
                  ? 'All'
                  : values.length
                : '0'
            } selected`
        }
        className="pl-2 text-text-primary"
        {...(valueLength ? { 'aria-selected': 'true' } : {})}
      />
    </div>
  );
};

export default SelectAll;
