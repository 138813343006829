import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { isNullOrUndefined, navigateTo } from 'utilities/Utils';
import { Popover, Tag, Typography } from 'components/latest-core';
import { TabsInfo } from '../../interfaces/HotelContextInterface';
import { routes } from '../routes';
import { legacyPushToGTM, GTM_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { NestedArrow } from 'assets/modules/hotelHeader';
import * as NavigationIcons from 'assets/modules/navigation';
import {
  GUEST_CHAT_COUNT_EVENTS_NAME,
  GuestChatCountEvent,
} from 'utilities/EventBus';
import { UserProfileViewType } from 'app/hotel/constants';
import { useLangStrings } from 'utilities/CustomHooks';
import { SaleHanldingImage } from './components/SaleHanldingImage';

import './LeftNav.scss';
import 'components/latest-core/Scrollbar/Scrollbar.scss';

interface LeftNavProps {
  tabs: TabsInfo[];
  counts: Object;
  isUserBlocked: boolean;
  disabledControls: boolean;
  additionalChildren?: ReactNode;
  isNewLinkageEnabled?: boolean;
  showSwitchToGoMMTOption?: boolean;
  setUserProfileView?: React.Dispatch<
  React.SetStateAction<UserProfileViewType>
  >;
  isResellerView?: boolean;
}

export const EVENT_LABEL_OPTIONS = {
  DROPDOWN_OPEN: 'dropdown_open',
  PAGE_OPEN: 'page_open',
};

export default function LeftNav(props: LeftNavProps) {
  const {
    tabs,
    counts,
    isUserBlocked,
    disabledControls,
    additionalChildren,
    isNewLinkageEnabled = false,
    showSwitchToGoMMTOption = false,
    setUserProfileView = null,
    isResellerView = false,
  } = props;
  const { pathname } = useLocation();
  const updatedTabs = useMemo(() => {
    let finalTabs = tabs;
    if (isUserBlocked) {
      finalTabs = finalTabs.sort(
        (a, b) => Number(a.verificationBlocker) - Number(b.verificationBlocker),
      );
    }
    return finalTabs;
  }, [tabs, isUserBlocked]);
  const [STRINGS, COMMON_STRINGS] = useLangStrings<'Navigation'>('Navigation');
  const findActiveDropdown = () => {
    let defaultExpand = '';
    updatedTabs.forEach(tab => {
      if (tab.children) {
        const childMatch = tab.children.some(item =>
          pathname.includes(item.route),
        );
        if (childMatch) {
          defaultExpand = tab.label;
        }
      }
    });
    return defaultExpand;
  };

  const [activeDropDown, setActiveDropDown] = useState(findActiveDropdown);
  const [slTooltipData] = useState(null);
  const [isSLOpen, setSLOpen] = useState(true);
  const [guestChatCount, setGuestChatCount] = useState({});
  const slPopupRef = useRef();

  const routeCounts = useMemo(
    () => ({
      ...counts,
      ...guestChatCount,
    }),
    [counts, guestChatCount],
  );

  const updateGuestChatCount = useCallback((count: number) => {
    setGuestChatCount(prev => ({
      ...prev,
      [routes.guestchat.base]: count,
    }));
  }, []);

  const clickHandler = (tab, e) => {
    if (disabledControls) {
      return e.preventDefault();
    }
    setActiveDropDown('');

    const count = routeCounts[tab.route] ? routeCounts[tab.route] : 0;
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.WEB_LEFTDRAWER,
      eventAction: tab.gtmAction,
      omnitureData: {
        event: 'ctaClick',
        name: `${GTM_CATEGORIES.WEB_LEFTDRAWER}_${count}`,
      },
      eventLabel: `${count}_${EVENT_LABEL_OPTIONS.PAGE_OPEN}`,
    });
  };

  const updateDropDownVisible = (tab, parentCount) => {
    if (disabledControls) return;

    let tabLabel = tab.label;

    if (activeDropDown === tabLabel) {
      tabLabel = '';
    } else {
      const subOptions = tab.children.map(eachTab => eachTab.label).join('|');
      legacyPushToGTM({
        eventCategory: GTM_CATEGORIES.WEB_LEFTDRAWER,
        eventAction: tab.gtmAction,
        omnitureData: {
          event: 'ctaClick',
          name: `${GTM_CATEGORIES.WEB_LEFTDRAWER}_${parentCount}`,
          cta: {
            type: 'click',
            leftMenuOptions: '',
            menuBadge: tab.showNew ? `${tab.label}- new` : '',
            leftMenuSubOptions: subOptions,
          },
        },
        eventLabel: `${parentCount}_${EVENT_LABEL_OPTIONS.DROPDOWN_OPEN}`,
      });
    }
    if (tab.permissionGroup === 'ParityInsight') {
      navigateTo(tab.route);
    }
    setActiveDropDown(tabLabel);
  };

  const handleSwitchToGoMMTProfile = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'web_leftdrawer',
        componentName: 'toggle_account',
      },
    });
    if (setUserProfileView) setUserProfileView(UserProfileViewType.INGO);
  };

  useEffect(() => {
    GuestChatCountEvent.on(
      GUEST_CHAT_COUNT_EVENTS_NAME.property_chat_count,
      updateGuestChatCount,
    );

    return () => {
      GuestChatCountEvent.off(
        GUEST_CHAT_COUNT_EVENTS_NAME.property_chat_count,
        updateGuestChatCount,
      );
    };
  }, []);

  useLayoutEffect(() => {
    if (disabledControls) return;
    setActiveDropDown(findActiveDropdown);
  }, [pathname]);

  const sponsoredListingTooltip = () => {
    if (isNullOrUndefined(slTooltipData)) {
      return null;
    }
    const header = slTooltipData?.header;
    const tooltip = slTooltipData?.body?.content;

    return (
      <Popover
        anchorEl={slPopupRef.current}
        open={isSLOpen}
        onClose={() => setSLOpen(false)}
        className="sl-stage-popup"
        anchorVertical="top"
        anchorHorizontal="right"
        transformHorizontal="left"
      >
        <p className="font14 fmed">
          {header}
          <span className="sr-label sr-label-success marginL3">New</span>
        </p>
        <ul className="font12">
          {tooltip && (
            <>
              <li style={{ listStyleType: 'disc' }} className="marginB20">
                {tooltip.k1}
              </li>
              <li style={{ listStyleType: 'disc' }}>{tooltip.k2}</li>
            </>
          )}
        </ul>
      </Popover>
    );
  };

  const isSLRoute = route => route === routes.sponsoredListing.base;

  return (
    <nav
      className={`header-navigation-container sr-light-scrollbar ${
        disabledControls ? 'disabled' : ''
      }`}
    >
      {updatedTabs.map(tab => {
        const isBlocked = isUserBlocked && tab.verificationBlocker;
        if (tab.children) {
          const isDropDownActive = activeDropDown === tab.label;
          let parentCount = 0;
          if (
            tab.children.find(
              innerTab => innerTab.route === routes.inventory.linkedRates,
            ) &&
            !isNewLinkageEnabled
          ) {
            tab.showNew = false;
          } else if (isResellerView && tab.label === 'Rates & Inventory') {
            tab.showNew = false;
          }
          tab.children.forEach(innerTab => {
            if (innerTab.label === 'Sync Property Calendar') {
              tab.showNew = true;
            }
            if (routeCounts[innerTab.route]) {
              parentCount += routeCounts[innerTab.route];
            }
          });
          if (tab.children.length === 0) {
            return null;
          }
          const commonParentRoute = tab.commonParentRoute;
          const navFragment = innerTab => (
            <>
              <div className="flex-centralized">
                <NestedArrow />
                <div className="ml-1">
                  <span className="child-nav-label">{innerTab.label} </span>
                  {innerTab.subLabel && (
                    <Typography variant="subtitle1" themeColor="text.secondary">
                      {innerTab.subLabel}
                    </Typography>
                  )}
                </div>
              </div>
              {innerTab.showNew && <div className="green-dot ml-2"></div>}
              <div className="flex-centralized">
                {!isNullOrUndefined(routeCounts[innerTab.route]) &&
                routeCounts[innerTab.route] !== 0 ? (
                  <span className="sr-nav-badge flex-centralized">
                    {routeCounts[innerTab.route]}
                  </span>
                  ) : null}
                {isBlocked && <img src={NavigationIcons.SecurityIcon} alt="" />}
              </div>
            </>
          );

          return (
            <React.Fragment key={tab.label}>
              <div
                className={`nav-entry ${isBlocked ? 'blocked-tab' : ''} ${
                  isDropDownActive ? 'active' : ''
                }`}
                onClick={() => updateDropDownVisible(tab, parentCount)}
              >
                <div className="flex-centralized">
                  <tab.imgComponent className="icon" />
                  <span className="nav-label">{tab.label}</span>
                </div>
                {tab.showNew && (
                  <Tag
                    label={COMMON_STRINGS.LABELS.NEW_BADGE}
                    variant="outlined"
                    size="small"
                    className="ml-2"
                    color="success"
                  />
                )}

                <div className="flex-centralized">
                  {parentCount !== 0 ? (
                    <span className="sr-nav-badge flex-centralized">
                      {parentCount}
                    </span>
                  ) : null}
                  <KeyboardArrowDownIcon
                    className={`drop-down-icon ${
                      isDropDownActive && 'arrow-up'
                    }`}
                  />
                  {isBlocked && (
                    <img src={NavigationIcons.SecurityIcon} alt="" />
                  )}
                </div>
              </div>
              {isDropDownActive &&
                tab.children.map(innerTab => {
                  if (
                    innerTab?.route === routes.inventory.linkedRates &&
                    !isNewLinkageEnabled
                  ) {
                    return null;
                  }
                  if (commonParentRoute) {
                    const isRouteSelected = pathname.includes(innerTab.route);
                    const navigateToTab = e => {
                      clickHandler(innerTab, e);
                      navigateTo(innerTab.route);
                    };
                    return (
                      <div
                        key={innerTab.route}
                        className={`nav-entry small ${
                          isBlocked ? 'blocked-tab' : ''
                        } ${isRouteSelected ? 'active' : ''}`}
                        onClick={navigateToTab}
                      >
                        {navFragment(innerTab)}
                      </div>
                    );
                  }
                  if (innerTab?.route === routes.parity.inventory) {
                    return (
                      <NavLink
                        key={innerTab.route}
                        className={
                          'nav-entry small blocked-tab pointer-events-none !cursor-not-allowed'
                        }
                        to={innerTab.route}
                        disabled
                      >
                        {navFragment(innerTab)}
                      </NavLink>
                    );
                  }
                  return (
                    <NavLink
                      key={innerTab.route}
                      className={`nav-entry small ${
                        isBlocked ? 'blocked-tab' : ''
                      }`}
                      to={innerTab.route}
                      onClick={e => clickHandler(innerTab, e)}
                    >
                      {navFragment(innerTab)}
                    </NavLink>
                  );
                })}
            </React.Fragment>
          );
        }
        return (
          <Fragment key={tab.route}>
            <NavLink
              ref={isSLRoute(tab.route) ? slPopupRef : null}
              key={tab.route}
              className={`nav-entry ${isBlocked ? 'blocked-tab' : ''}`}
              to={tab.route}
              onClick={e => clickHandler(tab, e)}
              exact={tab.route === '/'}
            >
              <div className="flex-centralized">
                <tab.imgComponent className="icon" />

                <span className="nav-label">{tab.label}</span>
              </div>
              <div className="flex-centralized">
                {tab.showNew && (
                  <Tag
                    label={COMMON_STRINGS.LABELS.NEW_BADGE}
                    variant="outlined"
                    size="small"
                    className="ml-2"
                    color="success"
                  />
                )}

                {!isNullOrUndefined(routeCounts[tab.route]) &&
                routeCounts[tab.route] ? (
                  <span className="sr-nav-badge flex-centralized">
                    {routeCounts[tab.route]}
                  </span>
                  ) : null}
                {isBlocked && <img src={NavigationIcons.SecurityIcon} alt="" />}
              </div>
            </NavLink>
            {isSLRoute(tab.route) && sponsoredListingTooltip()}
          </Fragment>
        );
      })}
      <SaleHanldingImage />
      {showSwitchToGoMMTOption && (
        <div className="flex flex-col  justify-center mx-3 mb-2">
          <div className=" p-2 bg-[#EDF6FF] border border-solid border-[#B2DCFF] rounded-[4px] text-[12px]">
            {STRINGS.LEFT_NAV.SWITCH_INFO}
          </div>
          <div
            className="text-[#3543BF] underline mt-1 cursor-pointer "
            onClick={handleSwitchToGoMMTProfile}
          >
            {STRINGS.LEFT_NAV.SWITCH_TEXT}
          </div>
        </div>
      )}
      {additionalChildren}
    </nav>
  );
}
