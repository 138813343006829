import { Appstore, PlayStore } from 'assets/modules/login';
import { APP_STORE, PLAY_STORE } from '../login.constant';
import { pushToOmniture } from 'utilities/gtm';

const AppDownloadButtons = ({ userName, compName = '' }) => {
  const ctaType = compName === 'registration' ? 'bottom' : 'top';

  const onAppClick = (type, ctaName) => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: ctaName,
        type: 'click',
      },
      loadedComponents: `${userName}`,
      pageName: 'landing_page',
    });

    window.open(type);
  };

  return (
    <>
      <img
        src={Appstore}
        alt="Appstore"
        className="mr-3 cursor-pointer"
        onClick={() => onAppClick(APP_STORE, `${ctaType}_download_ios_app`)}
      />
      <img
        src={PlayStore}
        className="cursor-pointer"
        alt="PlayStore"
        onClick={() =>
          onAppClick(PLAY_STORE, `${ctaType}_download_android_app`)
        }
      />
    </>
  );
};

export default AppDownloadButtons;
