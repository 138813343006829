import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Drawer, Typography, Divider } from 'components/latest-core';
import * as CommonIcons from 'assets/common';
import InvoicesUploadProgress from './InvoicesUploadProgress';
import UploadStatusDrawer from './UploadStatusDrawer';
import { uploadGSTNInvoiceFiles } from 'shared_logic/bookings';
import {
  acceptedInvoicesToUpload,
  bulkUploadTabsConfig,
} from 'shared_logic/bookings/constants';
import {
  InvoicesUploadEvent,
  INVOICES_UPLOAD_EVENT_NAME,
} from 'utilities/EventBus';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  copyTextToClipboard,
  isNullOrUndefined,
  showToast,
} from 'utilities/Utils';
import { GST_INVOICE_STATUS_MAP } from '../../constants';
import * as BookingsIcons from 'assets/modules/bookings';
import {
  BookingDataType,
  GSTNDrawerFields,
} from 'modules/bookings-revamp/bookings.types';
import { pushToOmniture } from 'utilities/gtm';
import { invoiceUploadViewTypes } from 'modules/bookings-revamp/bookings.constants';

const checkIfError = (file, bookingIds) => {
  const errorType = [];
  const fileName = file.name.split('.')[0];
  if (file.size / (1024 * 1024) > 10) {
    errorType.push('size_error');
  }
  if (
    bookingIds.length > 1 &&
    bookingIds.findIndex(
      x => x.bookingId === fileName || x.parentVendorBookingId === fileName,
    ) === -1
  ) {
    errorType.push('unrecognised_bookings');
  }
  if (!acceptedInvoicesToUpload.includes(file.type)) {
    errorType.push('wrong_format');
  }
  return errorType;
};

const DragNDropToUpload = props => {
  const {
    onUploadChange,
    inputRef,
    onFileDrop,
    bookingIds = [],
    STRINGS,
  } = props;

  const handleDragOver = event => {
    event.preventDefault();
    return false;
  };

  const handleInputClick = () => {
    inputRef.current.click();
  };

  return (
    <div
      className="w-full h-[112px] border-[1px] border-[#D2D1D1] border-dashed  flex justify-center rounded-md bg-color-light"
      draggable
      onDrop={onFileDrop}
      onDragOver={handleDragOver}
      onClick={handleInputClick}
    >
      <div className="flex justify-center items-center">
        <CommonIcons.Upload className="mr-4" />
        <div className="flex flex-col justify-center items-center">
          <Typography variant="subHeadingMedium" themeColor="gray.light">
            {STRINGS.INVOICE_DETAILS.DRAG_DROP}
          </Typography>
          <Typography className="mt-1" variant="body2" themeColor="gray.light">
            Or{' '}
            <Button variant="text" className="mr-1" size="small">
              {STRINGS.INVOICE_DETAILS.CLICK_HERE}
            </Button>
            {STRINGS.INVOICE_DETAILS.TO_UPLOAD}
          </Typography>
        </div>
      </div>
      <input
        className="hidden"
        ref={inputRef}
        multiple={bookingIds.length > 1}
        type="file"
        onChange={onUploadChange}
        accept={acceptedInvoicesToUpload.join(',')}
      />
    </div>
  );
};

export type ViewType = 'upload' | 'statuses' | 'progress';

export default function BulkUploadMain() {
  const [STRINGS, COMMON_STRINGS] =
    useLangStrings<'BookingsRevamp'>('BookingsRevamp');
  const [fileObjectData, setFileObjectData] = useState(null);
  const [view, setView] = useState<ViewType>(null);
  const bookingIdsRef = useRef([]);
  const hotelcodeRef = useRef(null);
  const rejectReasonsRef = useRef(null);
  const gtmLabelRef = useRef(null);
  const inputRef = useRef(null);
  const gstInfoRef: React.MutableRefObject<
  BookingDataType['gstInfo'] & GSTNDrawerFields
  > = useRef(null);
  const bookingIds = bookingIdsRef.current;
  const isBulkUpload = bookingIds.length > 1;
  const gstInfo = gstInfoRef.current;
  const isGSTNinfo = gstInfo?.isGSTNinfo ?? false;
  const isReupload = gstInfo?.isReupload ?? false;

  const title =
    isGSTNinfo && isReupload
      ? STRINGS.INVOICE_DETAILS.INVOICE_STATUS
      : isGSTNinfo
        ? STRINGS.INVOICE_DETAILS.GSTN_INFO
        : isReupload
          ? STRINGS.PERSUASIONS.REJECTED_INVOICES.REUPLOAD_BUTTON_TEXT
          : isBulkUpload
            ? STRINGS.INVOICE_DETAILS.BULK_UPLOAD_INVOICES
            : STRINGS.BOOKING_LIST_CARD_LABELS.UPLOAD_INVOICE;

  const {
    allData = null,
    successData = null,
    failedData = null,
    moderationData = null,
    totalCount = 0,
    isAllUploadComplete,
  } = useMemo(() => {
    if (!fileObjectData) return {};
    const allFileKeys = Object.keys(fileObjectData);

    const { success, failure, moderation } = allFileKeys.reduce(
      (past, curr) => {
        if (fileObjectData[curr].status === 'success') {
          return { ...past, success: [...past.success, curr] };
        } else if (fileObjectData[curr].status === 'error') {
          return { ...past, failure: [...past.failure, curr] };
        } else if (
          fileObjectData[curr].status === GST_INVOICE_STATUS_MAP.IN_MODERATION
        ) {
          return { ...past, moderation: [...past.moderation, curr] };
        }
        return past;
      },
      { success: [], failure: [], moderation: [] },
    );

    const isPending =
      allFileKeys.filter(
        fileName =>
          fileObjectData[fileName].externalError?.length === 0 &&
          fileObjectData[fileName].status === 'pending',
      ).length > 0;

    return {
      allData: fileObjectData,
      totalCount: allFileKeys.length,
      successData: success,
      failedData: failure,
      isAllUploadComplete: !isPending,
      moderationData: moderation,
    };
  }, [fileObjectData]);

  const allTabs = useMemo(() => {
    return bulkUploadTabsConfig(
      allData?.length,
      successData?.length,
      failedData?.length,
      moderationData?.length,
    );
  }, [allData, successData, failedData, moderationData]);

  function CopyJSX({ heading, value }) {
    const [isCopied, setIsCopied] = useState(false);
    const copyRef = useRef(null);

    const handleCopyClick = () => {
      pushToOmniture({
        event: 'ctaClick',
        cta: {
          name: `copy_${heading.split(' ').join('_').toLowerCase()}`,
          type: 'click',
          componentName: 'bookings_upload_invoice',
          bookingId: bookingIds[0].parentVendorBookingId,
        },
      });
      copyTextToClipboard(copyRef);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    };

    return (
      <>
        <div className="flex items-center">
          <Typography variant="sectionHeading" className="mr-2">
            {heading}
          </Typography>
          <Typography
            variant="subtitle2"
            className="cursor-pointer"
            themeColor="primary.dark"
            onClick={handleCopyClick}
          >
            {isCopied ? STRINGS.COPIED : COMMON_STRINGS.BUTTONS.COPY}
          </Typography>
        </div>
        <div ref={copyRef}>
          <Typography variant="body2" className="mb-4">
            {value}
          </Typography>
        </div>
      </>
    );
  }

  const uploadFiles = tempFileData => {
    const filterValidFiles = Object.keys(tempFileData).filter(
      fileName => tempFileData[fileName].externalError.length === 0,
    );
    filterValidFiles.forEach(fileName => {
      const requestConfig = {
        onUploadProgress: progressEvent => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setTimeout(() => {
            setFileObjectData(old => ({
              ...old,
              [fileName]: { ...(old?.[fileName] ?? {}), progress },
            }));
          }, 0);
        },
      };
      const extractedFileName = fileName.split('.')[0];
      const extractedBooking =
        bookingIds.length === 1
          ? bookingIds[0]
          : bookingIds.find(
            x =>
              x.bookingId === extractedFileName ||
                x.parentVendorBookingId === extractedFileName,
          );
      uploadGSTNInvoiceFiles(
        tempFileData[fileName].file,
        hotelcodeRef.current,
        extractedBooking.isMultiRoom
          ? extractedBooking.parentVendorBookingId
          : extractedBooking.bookingId,
        extractedBooking.isMultiRoom,
        STRINGS,
        requestConfig,
        true,
        gtmLabelRef.current,
      )
        .then(
          (res: {
            invoiceStatus: string;
            rejectReasons: string;
            errorMsg: string;
          }) => {
            const { invoiceStatus, rejectReasons, errorMsg } = res;
            if (invoiceStatus === 'verified') {
              setFileObjectData(old => ({
                ...old,
                [fileName]: { ...(old?.[fileName] ?? {}), status: 'success' },
              }));
            } else if (invoiceStatus === GST_INVOICE_STATUS_MAP.SCRUTINY) {
              setFileObjectData(old => ({
                ...old,
                [fileName]: {
                  ...(old?.[fileName] ?? {}),
                  status: 'warning',
                  message: errorMsg,
                },
              }));
            } else if (invoiceStatus === GST_INVOICE_STATUS_MAP.REJECTED) {
              setFileObjectData(old => ({
                ...old,
                [fileName]: {
                  ...(old?.[fileName] ?? {}),
                  status: 'error',
                  message: rejectReasons,
                },
              }));
            } else if (invoiceStatus === GST_INVOICE_STATUS_MAP.IN_MODERATION) {
              setFileObjectData(old => ({
                ...old,
                [fileName]: {
                  ...(old?.[fileName] ?? {}),
                  status: GST_INVOICE_STATUS_MAP.IN_MODERATION,
                  message: 'Invoice Under Verification',
                },
              }));
            }
          },
        )
        .catch(err => {
          setFileObjectData(old => ({
            ...old,
            [fileName]: {
              ...(old?.[fileName] ?? {}),
              status: 'error',
              message: err.errorMsg,
            },
          }));
        });
    });
  };

  const validateData = data => {
    const temp = {};

    [...data].forEach(file => {
      temp[file.name] = {
        file,
        name: file.name,
        status: 'pending',
        progress: 0,
        externalError: checkIfError(file, bookingIds),
      };
    });
    setFileObjectData(temp);
    setView('statuses');
    uploadFiles(temp);
  };

  const handleRejectedInvoice = gstnUrl => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'rejected_invoice',
        type: 'click',
        componentName: 'bookings_rejected_invoice',
        bookingId: bookingIds[0].parentVendorBookingId,
      },
    });
    window.open(gstnUrl, '_blank');
  };

  const handleUploadChangeHandler = e => {
    const data = e.target.files;
    if (data.length === 0) return;
    validateData(data);
  };

  const handleFileDrop = e => {
    const data = e.dataTransfer.files;
    validateData(data);
  };

  const onShowFileUploaderDrawer = ({
    bookingIds: bookingids = [], // mix of both parent & confirm ids
    hotelcode,
    gstInfo: gstinfo,
    errorMessage = null,
    gtmLabel = '',
  }) => {
    hotelcodeRef.current = hotelcode;
    bookingIdsRef.current = bookingids;
    rejectReasonsRef.current = errorMessage;
    gstInfoRef.current = gstinfo;
    gtmLabelRef.current = gtmLabel;
    setView('upload');
  };

  const hideUploaderDrawer = () => {
    if (isNullOrUndefined(fileObjectData)) {
      InvoicesUploadEvent.emit(INVOICES_UPLOAD_EVENT_NAME.all_upload_complete, {
        externalError: true,
        failureData: [],
        successData: [],
        moderationData: [],
      });
      setView(null);
    } else {
      setView('progress');
    }
  };

  useEffect(() => {
    if (isAllUploadComplete) {
      InvoicesUploadEvent.emit(INVOICES_UPLOAD_EVENT_NAME.all_upload_complete, {
        failureData: failedData.map(val => ({
          bookingId:
            bookingIds.length === 1
              ? bookingIds[0].parentVendorBookingId // In Bulk Upload SingleCase filename check is not mandatory. So exact parentId is passed to remove from list.
              : val.split('.')[0], //mix of both parent & child ids
          message: fileObjectData[val].message,
        })),
        successData:
          bookingIds.length === 1 && successData.length > 0
            ? [bookingIds[0].parentVendorBookingId]
            : successData.map(val => val.split('.')[0]),
        moderationData: moderationData.map(val => ({
          bookingId:
            bookingIds.length === 1
              ? bookingIds[0].parentVendorBookingId // In Bulk Upload SingleCase filename check is not mandatory. So exact parentId is passed to remove from list.
              : val.split('.')[0], //mix of both parent & child ids
          message: fileObjectData[val].message,
        })),
        externalError:
          Object.keys(fileObjectData).length > 0 &&
          failedData.length + successData.length + moderationData.length === 0,
      });
      if (successData?.length === totalCount) {
        const message = 'All invoices uploaded successfully.';
        showToast(message, 'success');
      } else if (successData?.length > 0) {
        const message = `${successData?.length} invoice(s) uploaded successfully. `;
        showToast(message, 'success');
      }
    }
  }, [isAllUploadComplete]);

  useEffect(() => {
    pushToOmniture({
      event: 'pageView',
      pageType: 'popup_invoice_upload',
      loadedComponents: isReupload ? 'rejected_invoice' : 'first_time',
    });

    InvoicesUploadEvent.on(
      INVOICES_UPLOAD_EVENT_NAME.show_files_uploader_drawer,
      onShowFileUploaderDrawer,
    );

    return () =>
      InvoicesUploadEvent.off(
        INVOICES_UPLOAD_EVENT_NAME.show_files_uploader_drawer,
        onShowFileUploaderDrawer,
      );
  }, []);

  const showRejectedInvoiceButton =
    gstInfo?.gstInvoiceUrl && gstInfo?.gstInvoiceUrl !== '';

  return (
    <div>
      {view === invoiceUploadViewTypes.UPLOAD && (
        <Drawer
          isOpen
          header={{
            title: title,
            subTitle: `${
              !isBulkUpload
                ? `${STRINGS.OTHER_BOOKING_DETAILS.BOOKING_ID} : ${bookingIds[0].parentVendorBookingId}`
                : ''
            }`,
          }}
          onClose={hideUploaderDrawer}
          preventBackDropClick
        >
          {bookingIds.length === 1 && rejectReasonsRef.current && (
            <div className="w-full border-[1px] border-solid border-[#D2D1D1] rounded-md p-3 mb-4">
              <div className="flex justify-between mb-2">
                <Typography variant="body2" themeColor="error.main">
                  {STRINGS.INVOICE_DETAILS.REJECTION_REASONS}
                </Typography>
                {showRejectedInvoiceButton && (
                  <Button
                    variant="text"
                    onClick={() =>
                      handleRejectedInvoice(gstInfo?.gstInvoiceUrl)
                    }
                  >
                    {STRINGS.INVOICE_DETAILS.VIEW_REJECTED_INVOICE}
                  </Button>
                )}
              </div>
              <div className="">
                {Array.isArray(rejectReasonsRef.current) ? (
                  rejectReasonsRef.current.map(text => (
                    <div className="flex items-center mb-1">
                      <img
                        src={BookingsIcons.ErrorAlertIcon}
                        alt="alert"
                        className="mr-2"
                      />
                      <Typography variant="subtitle1" themeColor="error.main">
                        {text}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center mb-1">
                    <img
                      src={BookingsIcons.ErrorAlertIcon}
                      alt="alert"
                      className="mr-2"
                    />
                    <Typography variant="subtitle1" themeColor="gray.main">
                      {rejectReasonsRef.current}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
          {isBulkUpload && (
            <>
              <Typography
                variant="subHeadingRegular"
                themeColor="text.primary"
                fb
              >
                {STRINGS.PLEASE_ENSURE}{' '}
              </Typography>
              <ul>
                {Object.keys(STRINGS.INVOICE_DETAILS.UPLOAD_RULES).map(RULE => (
                  <div className="flex items-center">
                    <span className="mr-1 mb-2 font-bold">.</span>
                    <span className="text-size-14">
                      {STRINGS.INVOICE_DETAILS.UPLOAD_RULES[RULE]}{' '}
                    </span>
                  </div>
                ))}
              </ul>
            </>
          )}
          {!isBulkUpload && (
            <div className="w-full border-[1px] border-solid border-[#D2D1D1] rounded-md">
              <div className="w-full bg-color-light py-2 px-3 rounded-t-md">
                <div className="">{STRINGS.INVOICE_DETAILS.INVOICE_FOR}</div>
              </div>
              <Divider />
              <div className="p-3">
                <div className="flex items-center mb-1">
                  <div className="flex-1 flex items-center">
                    <div className="flex flex-col">
                      <CopyJSX
                        heading={STRINGS.BOOKING_LIST_CARD_LABELS.GUEST_NAME}
                        value={gstInfo?.guestName}
                      />
                    </div>
                  </div>
                  <div className="flex-1 flex items-center">
                    <div className="flex flex-col">
                      <CopyJSX
                        heading={
                          STRINGS.BOOKING_LIST_CARD_LABELS.INVOICE_AMOUNT
                        }
                        value={`${gstInfo?.currency} ${gstInfo?.invoiceAmount}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <div className="flex flex-col">
                    <CopyJSX
                      heading={STRINGS.BOOKING_LIST_CARD_LABELS.COMPANY_NAME}
                      value={gstInfo?.gstCompanyName}
                    />
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <div className="flex flex-col">
                    <CopyJSX
                      heading={STRINGS.BOOKING_LIST_CARD_LABELS.COMPANY_ADDRESS}
                      value={gstInfo?.gstCompanyAddress}
                    />
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <div className="flex items-center flex-1">
                    <div className="flex flex-col">
                      <CopyJSX
                        heading={STRINGS.BOOKING_LIST_CARD_LABELS.COMPANY_GSTN}
                        value={gstInfo?.gstNo}
                      />
                    </div>
                  </div>
                  <div className="flex items-center flex-1">
                    <div className="flex flex-col">
                      <CopyJSX
                        heading={STRINGS.BOOKING_LIST_CARD_LABELS.HOTEL_GSTN}
                        value={gstInfo?.hotelGSTN}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-4">
            <DragNDropToUpload
              inputRef={inputRef}
              onUploadChange={handleUploadChangeHandler}
              onFileDrop={handleFileDrop}
              bookingIds={bookingIds}
              STRINGS={STRINGS}
            />
          </div>
          {!isBulkUpload && (
            <ul>
              {Object.keys(STRINGS.INVOICE_DETAILS.UPLOAD_RULES)
                .slice(1)
                .map(key => {
                  return (
                    <div className="flex">
                      <span className="mr-1 font-bold text-size-18">.</span>
                      <span className="text-size-10 flex items-center pt-[10px]">
                        {STRINGS.INVOICE_DETAILS.UPLOAD_RULES[key]}
                      </span>
                    </div>
                  );
                })}
            </ul>
          )}
        </Drawer>
      )}
      {fileObjectData && view === invoiceUploadViewTypes.STATUSES && (
        <UploadStatusDrawer
          allTabs={allTabs}
          fileObjectData={fileObjectData}
          setFileObjectData={setFileObjectData}
          isAllUploadComplete={isAllUploadComplete}
          totalFilesCount={totalCount}
          successData={successData}
          failedData={failedData}
          moderationData={moderationData}
          setView={setView}
        />
      )}
      {view === invoiceUploadViewTypes.PROGRESS && (
        <InvoicesUploadProgress
          fileObjectData={fileObjectData}
          successData={successData}
          failedData={failedData}
          moderationData={moderationData}
          totalFilesCount={totalCount}
          isAllUploadComplete={isAllUploadComplete}
          setView={setView}
          setFileObjectData={setFileObjectData}
        />
      )}
    </div>
  );
}
