import { Button } from 'components/new-core';
import { useContext } from 'react';

import { routes } from 'app/routes';
import { HotelContext } from 'utilities/Context';
import { navigateTo, GAFormatting } from 'utilities/Utils';
import './QualityScoreSection.scss';
import { tierMap } from 'modules/quality-score/constants';
import QualityScoreDisplay from './QualityScoreDisplay';

import { legacyPushToGTM, GTM_CATEGORIES } from 'utilities/gtm';

const QualityScoreSection = () => {
  const { qualityScoreState } = useContext(HotelContext);
  const { tier, qsPopup, is_qs_active, tasksPending, score } =
    qualityScoreState;

  if (!is_qs_active) {
    return null;
  }

  const { header, levelInfo, ctaText } = qsPopup;

  const bgImg = tierMap[tier].bgImg;

  const navigateToQS = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigateTo(routes.qualityScore.base);
    const { qsPopup } = qualityScoreState;
    const { ctaText } = qsPopup;
    legacyPushToGTM({
      eventCategory: GTM_CATEGORIES.HEADER,
      eventAction: 'quality_score_popup',
      eventLabel: GAFormatting(ctaText),
    });
  };

  return (
    <div className="marginB20 quality-score-card">
      <div
        className="quality-score-rating-container"
        style={{ backgroundImage: bgImg }}
      >
        <div>
          <div className="fw500 font21">{tier.toUpperCase()} LEVEL </div>
        </div>
        <QualityScoreDisplay score={score} tier={tier} />
      </div>
      <div className="marginT20">
        <div className="header">{header}</div>
        <div className="level-info">{levelInfo}</div>
        {tasksPending > 0 && (
          <Button noPadding variant={'text'} onClick={navigateToQS}>
            {ctaText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default QualityScoreSection;
