import {
  CategorizedTemplates,
  LeafCategory,
  ServiceNode,
} from '../data/types/Schema';
import { Button, Chip, Input, Typography } from 'components/latest-core';
import * as CommonIcons from '../../../assets/common';
import { SearchNotFound } from '../../../assets/common';
import {
  Collapse,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import React, { useMemo, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/styles';
import QuestionTooltip from '../../../components/common/QuestionTooltip';
import { useLangStrings } from '../../../utilities/CustomHooks';
import { Packages } from '../../../interfaces/Strings';
import {
  PackageGuideTooltip,
  useGuideTooltipConfig,
} from '../../../shared_logic/package-inlcusions/components/PackageGuideTooltip';
import { theme } from 'components/mui-theme';

type TemplatesTableProps = {
  templateCategories: CategorizedTemplates[];
  optionalTemplates: LeafCategory[];
  selectedInclusionsMap: Record<number, ServiceNode[]>;
  onInclusionClick: (arg: LeafCategory) => void;
  onInclusionDeleteClick: (arg: LeafCategory) => void;
  isLosPackageFlow?: boolean;
};

function TemplateChips({
  templates,
  selectedInclusionsMap,
  onInclusionClick,
  onInclusionDelete,
}: {
  selectedInclusionsMap: Record<number, ServiceNode[]>;
  templates: LeafCategory[];
  onInclusionClick: (arg: LeafCategory) => void;
  onInclusionDelete: (arg: LeafCategory) => void;
}) {
  return (
    <div className={'flex flex-wrap flex-row gap-2'}>
      {templates.map(template => {
        const inclusions = selectedInclusionsMap[template.serviceId];
        const count = Array.isArray(inclusions) ? inclusions.length : 0;
        const isSelected = count > 0;
        return (
          <Chip
            key={template.leafCategoryName}
            selected={isSelected}
            onClick={() => {
              if (isSelected) {
                return;
              }
              onInclusionClick(template);
            }}
            onDelete={
              !isSelected
                ? undefined
                : () => {
                  onInclusionDelete(template);
                }
            }
            label={template.leafCategoryName}
            badge={
              count > 0 && (
                <span
                  className={
                    'hidden text-size-12 rounded-full bg-color-blue text-common-white px-[5px] h-4 content-center'
                  }
                >
                  {count}
                </span>
              )
            }
          />
        );
      })}
    </div>
  );
}

const StyledTableCell = styled(TableCell)({
  borderBottomColor: theme.palette.divider,
});
const StyledTableHeadCell = styled(TableCell)({
  backgroundColor: theme.palette.backgrounds.light,
  padding: '4px 8px',
  fontSize: 12,
});

export const getFilteredTemplateCategories = (
  searchText: string,
  templateCategories: CategorizedTemplates[],
  optionalTemplates: LeafCategory[],
): {
  templateCategories: CategorizedTemplates[];
  optionalTemplates: LeafCategory[];
} => {
  if (searchText === '') {
    return { templateCategories, optionalTemplates };
  }
  let updatedTemplateCategories = [...templateCategories];
  searchText = searchText.toLowerCase();
  const filterTemplate = (template: LeafCategory) =>
    template.leafCategoryName.toLowerCase().indexOf(searchText) > -1;
  updatedTemplateCategories = (updatedTemplateCategories ?? [])
    .map(({ categoryName, templates }) => {
      const filteredTemplates = templates.filter(filterTemplate);
      return { categoryName, templates: filteredTemplates };
    })
    .filter(value => value.templates.length > 0);
  const filteredOptionalTemplates = optionalTemplates.filter(filterTemplate);
  return {
    templateCategories: updatedTemplateCategories,
    optionalTemplates: filteredOptionalTemplates,
  };
};

function EmptyFilteredView({
  search,
  pkgStr,
}: {
  search: string;
  pkgStr: Packages;
}) {
  return (
    <div
      className={
        'flex flex-col justify-center items-center gap-4 min-h-[250px]'
      }
    >
      <SearchNotFound />
      <span
        dangerouslySetInnerHTML={{
          __html: pkgStr.LABELS.SEARCH_NOT_FOUND.replace(
            '{search_term}',
            search,
          ),
        }}
      ></span>
    </div>
  );
}

export function TemplatesTable(props: TemplatesTableProps) {
  const {
    templateCategories,
    optionalTemplates,
    selectedInclusionsMap,
    onInclusionClick,
    onInclusionDeleteClick,
    isLosPackageFlow,
  } = props;
  const [pkgStr] = useLangStrings<'Packages'>('Packages');

  const [searchText, setSearchText] = useState<string>('');
  const [expandOptionalTemplates, setExpandOptionalTemplates] = useState(true);
  const { showTooltipGuide, onDismissClick } =
    useGuideTooltipConfig('pkg_man_incl_guide');
  const filteredTemplates = useMemo(
    () =>
      getFilteredTemplateCategories(
        searchText,
        templateCategories,
        optionalTemplates,
      ),
    [searchText, templateCategories, optionalTemplates],
  );
  return (
    <>
      <div className={'flex flex-row items-center  justify-between mb-2'}>
        {isLosPackageFlow ? (
          <div className="flex flex-col">
            <div className="flex items-center">
              <Typography variant="h3">
                {pkgStr.LABELS.ADD_MANDATORY}
              </Typography>
              {!showTooltipGuide && (
                <QuestionTooltip
                  size={16}
                  bgBlack={false}
                  titleWithContent={true}
                  header={pkgStr.LABELS.MANDATORY_INCLUSION}
                  content={pkgStr.LABELS.WHAT_IS_MANDATORY_INCL}
                  placement={'top'}
                  className="ml-2"
                />
              )}
            </div>
            <Typography variant="subtitle1" className="!text-text-secondary">
              {pkgStr.LABELS.ADD_MIN}
            </Typography>
          </div>
        ) : (
          <Typography variant={'body2'} className={'w-1/2 text-text-primary'}>
            {pkgStr.LABELS.SELECT_INCLUSIONS}
          </Typography>
        )}
        <Input
          className={isLosPackageFlow ? '!w-[300px]' : 'w-1/2'}
          type="text"
          placeholder={pkgStr.LABELS.SEARCH_INCL_PLACEHOLDER}
          value={searchText}
          charLimit={25}
          onChange={val => setSearchText(val)}
          leftElement={<CommonIcons.Search />}
          disableClear={false}
          rightElement={
            searchText ? undefined : (
              <Button variant={'contained'} size={'small'}>
                {pkgStr.LABELS.SEARCH_INCL_CTA}
              </Button>
            )
          }
        />
      </div>
      {filteredTemplates.templateCategories.length === 0 &&
        filteredTemplates.optionalTemplates.length === 0 && (
          <EmptyFilteredView search={searchText} pkgStr={pkgStr} />
      )}
      {filteredTemplates.templateCategories.length > 0 && (
        <MuiTable border={0}>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>
                {pkgStr.LABELS.CATEGORIES}
              </StyledTableHeadCell>
              <StyledTableHeadCell>
                <span className={'flex flex-row items-center gap-2'}>
                  <span data-tour-id={'mandatory_inclusion'}>
                    {pkgStr.LABELS.INCLUSIONS}
                  </span>
                  {!showTooltipGuide && (
                    <QuestionTooltip
                      size={16}
                      bgBlack={false}
                      titleWithContent={true}
                      header={pkgStr.LABELS.MANDATORY_INCLUSION}
                      content={pkgStr.LABELS.WHAT_IS_MANDATORY_INCL}
                      placement={'top'}
                    />
                  )}
                  {showTooltipGuide && (
                    <PackageGuideTooltip
                      onCtaClick={onDismissClick}
                      body={pkgStr.LABELS.WHAT_IS_MANDATORY_INCL}
                      title={pkgStr.LABELS.MANDATORY_INCLUSION}
                    />
                  )}
                </span>
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTemplates.templateCategories.map(rowData => (
              <TableRow>
                <StyledTableCell>{rowData.categoryName}</StyledTableCell>
                <StyledTableCell>
                  <TemplateChips
                    templates={rowData.templates}
                    selectedInclusionsMap={selectedInclusionsMap}
                    onInclusionClick={onInclusionClick}
                    onInclusionDelete={onInclusionDeleteClick}
                  />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      )}
      {filteredTemplates.optionalTemplates.length > 0 && (
        <div className={'mt-2 mb-4'}>
          <div
            className={'flex flex-row justify-between flex-1'}
            onClick={() => {
              setExpandOptionalTemplates(prevState => !prevState);
            }}
          >
            <Typography variant={'h3'}>
              {pkgStr.LABELS.OPTIONAL_INCLUSIONS}
            </Typography>
            <IconButton aria-label="expand Optional Inclusions " size="small">
              {expandOptionalTemplates ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </div>
          <Collapse
            className={'pt-4'}
            in={expandOptionalTemplates}
            timeout="auto"
            unmountOnExit
          >
            <TemplateChips
              templates={filteredTemplates.optionalTemplates}
              selectedInclusionsMap={selectedInclusionsMap}
              onInclusionClick={onInclusionClick}
              onInclusionDelete={onInclusionDeleteClick}
            />
          </Collapse>
        </div>
      )}
    </>
  );
}
