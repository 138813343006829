import React, { useCallback, useContext, useState } from 'react';
import { PackagesContext } from '../PackagesContext';
import { useLangStrings } from '../../../utilities/CustomHooks';
import { DetailsCard } from '../../../components/common';
import CircularProgressBar from '../../../components/latest-core/ProgressBar/CircularProgressBar';
import { InlineMsgAlerts, Typography } from '../../../components/latest-core';
import {
  Check,
  Delete,
  DragIndicator,
  EditOutlined,
} from '../../../assets/common';
import SortableDragDrop from '../../../components/common/DragAndDrop/SortableDragDrop';
import QuestionTooltip from '../../../components/common/QuestionTooltip';
import WarningIcon from '@mui/icons-material/Warning';
import { Packages } from '../../../interfaces/Strings';
import {
  PackageType,
  ServiceNode,
  ValidatePackageResponse,
} from '../data/types/Schema';
import { usePackageActions } from './usePackageActions';
import { isNullOrUndefined } from 'utilities/Utils';
import { PackagesLosContext } from '../PackagesLosContext';
import { PACKAGE_TYPE } from '../packageConstants';

export type SidePanelProps = {
  currentStep: number;
};

function DateSettingsCallout({ pkgStr }: { pkgStr: Packages }) {
  const { serviceResponse } = useContext(PackagesContext);
  if ((serviceResponse?.servicesResponse?.services?.length || 0) === 0) {
    return null;
  }
  return (
    <span
      className={
        'flex flex-row gap-2 bg-color-warningLight bg-opacity-20 p-3 borRadius4 border-color-warningDefault border-[1px] border-solid'
      }
    >
      <WarningIcon className={'mr-2'} fontSize={'small'} color={'warning'} />
      <Typography variant={'subtitle1'}>
        {pkgStr.LABELS.INCLUSIONS_DATES_CALLOUT}
      </Typography>
    </span>
  );
}

function Progress({ max, value }: { value: number; max: number }) {
  const progress = Math.min(100, (value / max) * 100);
  if (progress === 100) {
    return (
      <div className={'w-[24px] h-[24px]'}>
        <Check className={'w-[24px] h-[24px] scale-125'} />
      </div>
    );
  }
  return (
    <div className={'w-[24px] h-[24px]'}>
      <CircularProgressBar
        sx={{ position: 'absolute' }}
        color={'info'}
        variant={'determinate'}
        value={100}
        size={24}
        thickness={4}
      />
      <div className={'w-[24px] h-[24px] leading-[24px] posAbs text-center'}>
        {value}
      </div>
      {progress < 100 && (
        <CircularProgressBar
          color={'secondary'}
          variant={'determinate'}
          value={progress}
          size={24}
          thickness={4}
        />
      )}
      {progress === 100 && <Check className="h-[24px] w-[24px] ml-2" />}
    </div>
  );
}

function ValidationStatus({
  contextName = 'inclusions',
  criteria,
  pkgStr,
}: {
  contextName: PackageType;
  criteria: ValidatePackageResponse;
  pkgStr: Packages;
}) {
  return (
    <div
      className={
        'accent-primary-light flex flex-col gap-2 bg-color-primary p-3 borRadius4'
      }
    >
      <Typography variant={'body1'} fb>
        {pkgStr.LABELS.CRITERIA_FOR_ADD}
      </Typography>
      <div className={'flex flex-row gap-2'}>
        <Progress
          max={criteria.minimumInclusions}
          value={criteria.matchingInclusions}
        />
        <span>
          {contextName === 'los'
            ? pkgStr.LABELS.MIN_CRITERIA_LOS_INCLUSIONS.replace(
              '{min_incl_required}',
              `${criteria.minimumInclusions}`,
            )
            : pkgStr.LABELS.MIN_CRITERIA_INCLUSIONS.replace(
              '{min_incl_required}',
              `${criteria.minimumInclusions}`,
            )}
          {contextName !== 'los' && (
            <QuestionTooltip
              bgBlack={false}
              titleWithContent={true}
              header={pkgStr.LABELS.MANDATORY_INCLUSION}
              content={pkgStr.LABELS.WHAT_IS_MANDATORY_INCL}
              placement={'top'}
            />
          )}
        </span>
      </div>
      {criteria.minimumCategories && (
        <div className={'flex flex-row gap-2'}>
          <Progress
            max={criteria.minimumCategories}
            value={criteria.matchingCategories}
          />
          {pkgStr.LABELS.MIN_CRITERIA_CATEGORIES.replace(
            '{min_sub_cat_required}',
            `${criteria.minimumCategories}`,
          )}
        </div>
      )}
    </div>
  );
}

function DescriptionText({ text = '' }: { text: string }) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const mayNeedExpand = (text || '').length >= 40;
  return (
    <div className={'flex flex-col items-start'}>
      <Typography
        variant={'subtitle1'}
        className={`text-start max-w-[150px] break-words ${
          expanded ? '' : 'two-lines'
        }`}
      >
        {text}
      </Typography>
      {mayNeedExpand && (
        <span
          className={'text-text-link cursor-pointer font-bold'}
          onClick={() => setExpanded(prevVal => !prevVal)}
        >
          {expanded ? 'show less' : 'show more'}
        </span>
      )}
    </div>
  );
}

function InclusionList({
  inclusions,
  pkgStr,
  contextName = 'inclusions',
  totalNights,
}: {
  inclusions: Array<ServiceNode>;
  pkgStr: Packages;
  contextName?: string;
  totalNights?: number;
}) {
  const losContext = useContext(PackagesLosContext);
  const regularContext = useContext(PackagesContext);
  const contextValue =
    contextName === PACKAGE_TYPE.LOS ? losContext : regularContext;
  const { updateServiceResponse, serviceResponse, inclusionLookupByIdMap } =
    contextValue;
  const { onInclusionDeleteClick, onEditClicked } =
    usePackageActions(contextName);

  const onDeleteClicked = useCallback(
    (item: ServiceNode) => {
      const leafCategory = inclusionLookupByIdMap[item.leafCategory.serviceId];
      onInclusionDeleteClick(leafCategory);
    },
    [inclusionLookupByIdMap, onInclusionDeleteClick],
  );
  const GetRenderItemView = useCallback(
    ({ item, index }: { item: ServiceNode; index: number }) => (
      <div
        className={
          'gap-2 flex flex-row items-start justify-items-start px-2 py-2 border-t-0 border-r-0 border-l-0 border-b-[1px] border-solid border-color-divider'
        }
      >
        <DragIndicator className={'my-[4px] min-w-[12px] min-h-[12px]'} />
        <span>{index + 1}</span>
        <div className={'flex flex-col flex-1 px-3 justify-items-start'}>
          <Typography
            // title={item.metadata.name}
            variant={'subtitle1'}
            className={'text-start'}
            fb
          >
            {item.metadata.name}
          </Typography>
          <DescriptionText text={item.metadata.description} />
        </div>
        <div className={'flex flex-row gap-4 py-[2px]'}>
          <Delete
            className={'stroke-primary-default cursor-pointer'}
            onClick={() => onDeleteClicked(item)}
          />
          <EditOutlined
            className={'stroke-primary-default cursor-pointer'}
            onClick={() => onEditClicked(item)}
          />
        </div>
      </div>
    ),
    [onEditClicked, serviceResponse],
  );

  if (isNullOrUndefined(serviceResponse) || inclusions.length === 0) {
    return contextName === 'los' ? (
      <Typography>{pkgStr.LABELS.NO_INCLUSIONS}</Typography>
    ) : null;
  }
  return (
    <div>
      <Typography variant={'subtitle1'} className={'pb-5'}>
        {pkgStr.LABELS.DRAG_INCLUSION}
      </Typography>
      <div
        className={
          'h-6 bg-color-light text-common-light text-size-12 px-2 flex align-center'
        }
      >
        {pkgStr.LABELS.INCLUSIONS}
      </div>
      <div>
        <SortableDragDrop
          className={
            contextName === PACKAGE_TYPE.LOS
              ? '[&>:last-child>div]:!border-b-0'
              : ''
          }
          parentKey="ratePlanId"
          data={inclusions}
          callback={(...args) => {
            updateServiceResponse(prevState => ({
              ...prevState,
              servicesResponse: {
                ...prevState.servicesResponse,
                services: (args[0] as ServiceNode[]).map((service, index) => ({
                  ...service,
                  priorityOrder: index + 1,
                })),
              },
            }));
          }}
          dragComponent={GetRenderItemView}
          id="id"
        />
      </div>
      {contextName === PACKAGE_TYPE.INCLUSIONS && (
        <div
          className={
            'accent-primary-light flex flex-col gap-2 bg-color-primary p-3 borRadius4 mt-4'
          }
        >
          {pkgStr.LABELS.MANAGE_PACKAGE_INSTRUCTION_WITHOUT_REDIRECTION_TEXT}
        </div>
      )}
      {contextName === PACKAGE_TYPE.LOS && (
        <InlineMsgAlerts
          severity="info"
          message={pkgStr.LOS.APPLICABLE_ON.replace(
            '__placeholder__',
            totalNights.toString(),
          )}
          inerClasses="!rounded"
        />
      )}
    </div>
  );
}

export function PackagesCart({
  pkgStr,
  contextName = PACKAGE_TYPE.INCLUSIONS,
}: {
  pkgStr: Packages;
  contextName?: PackageType;
}) {
  const losContext = useContext(PackagesLosContext);
  const regularContext = useContext(PackagesContext);

  const contextValue =
    contextName === PACKAGE_TYPE.LOS ? losContext : regularContext;

  const { serviceResponse } = contextValue;
  if (!serviceResponse) {
    return null;
  }
  let minStay;
  if ('minStay' in contextValue) {
    minStay = contextValue.minStay;
  }

  const serviceNodes = serviceResponse?.servicesResponse?.services || [];
  const addedInclCount = serviceNodes.length;

  const validationOptions = {
    pkgStr,
    criteria: serviceResponse.validatePackageResponse,
  };
  return (
    <DetailsCard
      title={`${
        contextName === PACKAGE_TYPE.LOS
          ? pkgStr.LOS.LONG_STAY_INCLUSION
          : pkgStr.LABELS.PACKAGES_INCLUSIONS
      } (${addedInclCount} added)`}
      variant="without-star"
    >
      <div className={'flex flex-col gap-3 min-w-[200px]'}>
        {validationOptions.criteria && (
          <ValidationStatus {...validationOptions} contextName={contextName} />
        )}
        <InclusionList
          pkgStr={pkgStr}
          inclusions={serviceNodes}
          contextName={contextName}
          totalNights={minStay}
        />
      </div>
    </DetailsCard>
  );
}

export function SidePanel({ currentStep }: SidePanelProps) {
  const [pkgStr] = useLangStrings<'Packages'>('Packages');
  const { serviceResponse } = useContext(PackagesContext);
  if (currentStep === 0) {
    return (
      <DetailsCard
        title={pkgStr.LABELS.PACKAGES_DETAILS}
        content={pkgStr.LABELS.SELECT_A_RATE_PLAN}
        variant="without-star"
      />
    );
  }
  if (currentStep === 1) {
    const services = serviceResponse?.servicesResponse?.services || [];
    const hasDateCondition = services.some(value => !!value.condition);
    return (
      <div className={'flex flex-col'}>
        <PackagesCart pkgStr={pkgStr} />
        {hasDateCondition && <DateSettingsCallout pkgStr={pkgStr} />}
      </div>
    );
  }
  throw new Error(
    `IllegalArgumentException. currentStep should be wither 1 or 0, but ${currentStep} was passed`,
  );
}
