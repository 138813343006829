import MuiChip, { ChipProps } from '@mui/material/Chip';
import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getDataPropsFromRest } from 'utilities/Utils';
import React from 'react';

type TagPropsType = {
  label: React.ReactNode;
  size: 'small' | 'medium';
  color: 'default' | 'success' | 'warning' | 'error' | 'info' | 'successmedium';
  variant: 'filled' | 'outlined';
  className: string;
  icon?: React.ReactElement;
  showOutline?: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    border: 'none',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    height: '20px',
    background: theme.palette.backgrounds.white,

    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.text.secondary,
        theme.palette.action.hoverOpacity,
      ),
    },
    '& [class*="MuiChip-label"].not(class*|="success").not(class*|="warning").not(class*|="error")':
      {
        color: `${theme.palette.backgrounds.white}!important`,
      },
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.text.primary} !important`,
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
  sizeSmall: {
    height: '20px',
    fontSize: '10px',
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
  },
  labelSmall: {
    padding: '0 6px',
  },
  success: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.success.dark} !important`,
    },
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.success.lighter,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  warning: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.warning.main} !important`,
    },
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.warning.lighter,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  error: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.error.main} !important`,
    },
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.error.lighter,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  outlined: {
    border: '1px solid',
  },
  outlineddefault: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.text.primary} !important`,
    },
    border: `1px solid ${theme.palette.text.primary}`,
    background: theme.palette.backgrounds.light,
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.text.secondary,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  outlinedinfo: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.text.primary} !important`,
    },
    border: `1px solid ${theme.palette.backgrounds.divider}`,
    background: theme.palette.backgrounds.dark,
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.text.secondary,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  outlinedsuccess: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.success.dark} !important`,
    },
    border: `1px solid ${theme.palette.success.dark}`,
    background: theme.palette.success.lighter,
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.success.lighter,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  outlinedsuccessmedium: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.text.primary} !important`,
    },
    border: '1px solid #81C784',
    background: theme.palette.success.lighter,
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.success.main,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  outlinedwarning: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.warning.main} !important`,
    },
    border: `1px solid ${theme.palette.warning.main}`,
    background: theme.palette.warning.lighter,
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.warning.lighter,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  outlinederror: {
    '& [class*="MuiChip-label"]': {
      color: `${theme.palette.error.dark} !important`,
    },
    border: `1px solid ${theme.palette.error.dark}`,
    background: theme.palette.error.lighter,
    '$clickable&:hover, $clickable&:focus, $deletable&:focus': {
      backgroundColor: alpha(
        theme.palette.error.lighter,
        theme.palette.action.hoverOpacity,
      ),
    },
  },
  nooutline: {
    border: 'none',
    '& [class*="MuiChip-label"]': {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
}));

function Tag(props: Partial<TagPropsType>) {
  const {
    label,
    icon,
    size = 'medium',
    color = 'default',
    variant = 'filled',
    showOutline = true,
    className = '',
    ...rest
  } = props;

  const classes = useStyles();

  const _color: ChipProps['color'] = 'default';

  const rootCls = `${className}  ${
    classes[(variant === 'outlined' ? 'outlined' : '') + color]
  } ${classes.root} ${!showOutline && classes.nooutline}`;

  const dataTestIdProps = getDataPropsFromRest(rest);

  return (
    <MuiChip
      classes={{
        root: rootCls,

        sizeSmall: classes.sizeSmall,
        labelSmall: classes.labelSmall,
        outlined: classes.outlined,
      }}
      label={label}
      size={size}
      icon={icon}
      color={_color}
      variant={variant}
      {...dataTestIdProps}
    />
  );
}
export { Tag };
