import { Typography } from 'components/latest-core';
import { LInfoIcon } from 'assets/common';
import { getFormattedDate } from 'utilities/DateUtils';
import { SelectedDatesProps } from '../data/types/Schema';

export const SelectedDatesInfo = ({
  showAdvanceSettings,
  bookingStartDate,
  PERFORMANCE_STRINGS,
  LOS_STRINGS,
  promotionBasedOn,
  bookingEndDate,
  checkinStartDate,
  checkinEndDate,
}: SelectedDatesProps) => {
  const getSelectedDatesText = () => {
    let text = PERFORMANCE_STRINGS.PROMO_PREFIX;
    let bookingInfoText = '';
    let stayInfoText = '';
    bookingInfoText +=
      bookingStartDate &&
      ` ${PERFORMANCE_STRINGS.BOOKING_DATES_PREFIX} ${getFormattedDate(
        bookingStartDate,
      )}`;
    bookingInfoText += bookingEndDate
      ? ` ${PERFORMANCE_STRINGS.TO} ${getFormattedDate(bookingEndDate)}`
      : ` ${LOS_STRINGS.ONWARDS}`;

    if (checkinStartDate) {
      stayInfoText += ` ${
        PERFORMANCE_STRINGS.STAY_DATES_PREFIX
      } ${getFormattedDate(checkinStartDate)}`;
    }

    if (checkinEndDate) {
      stayInfoText += ` ${PERFORMANCE_STRINGS.TO} ${getFormattedDate(
        checkinEndDate,
      )}`;
    } else if (checkinStartDate && !checkinEndDate) {
      stayInfoText += ` ${LOS_STRINGS.ONWARDS}`;
    }

    text +=
      promotionBasedOn === 'booking'
        ? bookingInfoText
        : `${stayInfoText} ${PERFORMANCE_STRINGS.WITH} ${bookingInfoText}`;
    return text;
  };

  return showAdvanceSettings && bookingStartDate ? (
    <div className="border border-solid border-[#C2C7EC] bg-[#EFF1FF] flex mt-2">
      <div className="flex p-1 items-center">
        <LInfoIcon className="mr-2" width={20} />
        <Typography variant="subtitle1">{getSelectedDatesText()}</Typography>
      </div>
    </div>
  ) : null;
};
