import React from 'react';
import { Tooltip } from '../index';
import makeStyles from '@mui/styles/makeStyles';
import { HelpOutline } from '@mui/icons-material';
import { isNullOrUndefined } from 'utilities/Utils';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import './FormElement.scss';

type FormElementProps = {
  label?: string;
  rightElem?: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  containerClass?: string;
  labelClassName?: string;
  errorMsg?: string | FieldError | Merge<FieldError, FieldErrorsImpl<unknown>>;
  tooltip?: React.ReactNode;
  tooltipBgBlack?: boolean;
  isMultipleElem?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  secondaryLabel?: string;
  fixedErrorSpan?: boolean;
};

type FormElementStylesProps = {
  fixedErrorSpan?: boolean;
};

const useStyles = makeStyles(theme => ({
  error: {
    marginTop: (props: FormElementStylesProps) =>
      props.fixedErrorSpan ? null : '2px',
    position: (props: FormElementStylesProps) =>
      props.fixedErrorSpan ? 'absolute' : 'static',
    bottom: (props: FormElementStylesProps) =>
      props.fixedErrorSpan ? '-26px' : null,
    width: (props: FormElementStylesProps) =>
      props.fixedErrorSpan ? '100%' : null,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '12px',
    '&:empty': {
      padding: '0',
      visibility: 'hidden',
    },
  },
}));

function FormElement(props: FormElementProps) {
  const {
    label = '',
    rightElem,
    children,
    style,
    className = '',
    containerClass = '',
    labelClassName = '',
    errorMsg = '',
    tooltip = null,
    tooltipBgBlack = false,
    isMultipleElem = false,
    isRequired = false,
    secondaryLabel = '',
    disabled = false,
    fixedErrorSpan = false,
  } = props;

  const { error } = useStyles({ fixedErrorSpan });

  const rootCls = `sr-form-element ${isMultipleElem ? 'multiple' : ''} ${
    fixedErrorSpan ? 'posRel' : ''
  } ${className}`;

  const secondaryLabelCls = 'secondary-label-class';
  const classesLabel = [labelClassName];
  if (isRequired) {
    classesLabel.push('required-cls');
  }
  if (disabled) {
    classesLabel.push('disabled-cls');
  }

  return (
    <div className={rootCls} style={style}>
      <label>
        <span className={classesLabel.join(' ')}>{label}</span>
        {!isNullOrUndefined(tooltip) && (
          <Tooltip
            content={tooltip}
            bgBlack={tooltipBgBlack}
            placement="bottom"
          >
            <HelpOutline />
          </Tooltip>
        )}
        <div className="right-area">{rightElem}</div>
      </label>
      {secondaryLabel && (
        <label className={secondaryLabelCls}>{secondaryLabel}</label>
      )}
      <div className={`sr-elem-container ${containerClass}`}>{children}</div>
      <span className={error}>{errorMsg as string}</span>
    </div>
  );
}

export default FormElement;
