import { inventoryMaxFutureDays } from '../../constants';
import { getFormattedTime, getFutureDate } from 'utilities/DateUtils';

export const PLACEHOLDER = '__placeholder__';
export const LOCALSTORAGE_KEY = 'day-use-onboarding';
export const MAX_FUTURE_DATE = getFutureDate(
  new Date(),
  inventoryMaxFutureDays,
);

// please follow FormFields type from dayUseRooms.types.ts
export const formFields = {
  room_details: {
    room_type: 'room_type.room_type',
    room_name: 'room_details.room_name',
    room_description: 'room_details.room_description',
    bed_type: 'room_details.bed_type',
    maximum_guest_occupancy: 'room_details.maximum_guest_occupancy',
    amenities: 'room_details.amenities',
  },
  pricing_and_availability: {
    // settings fields
    restrictions: {
      min_cutoff: 'pricing_and_availability.restrictions.min_cutoff',
      max_cutoff: 'pricing_and_availability.restrictions.max_cutoff',
    },
    inventory_cut_off: {
      fullday_inventory_cutoff_toggle:
        'pricing_and_availability.inventory_cut_off.fullday_inventory_cutoff_toggle',
      fullday_inventory_cutoff_value:
        'pricing_and_availability.inventory_cut_off.fullday_inventory_cutoff_value',
    },
    rateplan_9: {
      is_active: 'pricing_and_availability.rateplan_9.is_active',
      price: 'pricing_and_availability.rateplan_9.price',
    },
    // onboarding fields
    availability_period: 'pricing_and_availability.availability_period',
    hourly_rates: {
      hours_3: 'pricing_and_availability.hourly_rates.hours_3',
      hours_6: 'pricing_and_availability.hourly_rates.hours_6',
      hours_9: 'pricing_and_availability.hourly_rates.hours_9',
    },
    inventory: 'pricing_and_availability.inventory',
    // common fields
    tax_inclusive: 'pricing_and_availability.tax_inclusive',
    availability_window: {
      first_check_in:
        'pricing_and_availability.availability_window.first_check_in',
      last_check_out:
        'pricing_and_availability.availability_window.last_check_out',
    },
    allow_for_multibooking: 'pricing_and_availability.allow_for_multibooking',
    housekeeping_duration: 'pricing_and_availability.housekeeping_duration',
  },
  cancellation_policy: 'cancellation_policy',
  couple_friendly_policy: 'couple_friendly_policy',
  couple_friendly_policy_id: 'couple_friendly_policy_id',
} as const;

export const checkInOptions = [...new Array(14)].map((_, index) => {
  const time = String(index + 5);
  return {
    label: getFormattedTime(time),
    value: `${time.padStart(2, '0')}:00`,
  };
});

export const checkOutOptions = [...new Array(14)].map((_, index) => {
  const time = index + 11 === 24 ? '0' : String(index + 11);
  const formattedTime = getFormattedTime(time);
  return {
    label: formattedTime === '12:00 AM' ? '12:00 Midnight' : formattedTime,
    value: `${time.padStart(2, '0')}:00`,
  };
});

export const housekeepingOptions = [
  {
    label: '1 Hour',
    value: 60,
  },
  {
    label: '2 Hours',
    value: 120,
  },
] as const;

export const daysHoursOptions = ['days', 'hours'];
export const beforeAfterOptions = ['before', 'after'];

export const cancellationPolicies = {
  free_cancellation: [
    {
      chargestype: 'percent',
      chargesvalue: 0,
      startday: 0,
      endday: 365,
    },
    {
      chargestype: 'percent',
      chargesvalue: 100,
      startday: 0,
      endday: 0,
      time: '0AM',
    },
    {
      chargestype: 'percent',
      chargesvalue: 100,
      startday: -1,
      endday: 0,
    },
  ],
  non_refundable: [
    {
      chargestype: 'percent',
      chargesvalue: 100,
      startday: -1,
      endday: 365,
    },
  ],
};

export const SLOT_DURATION = {
  hours_3: 3 * 60,
  hours_6: 6 * 60,
  hours_9: 9 * 60,
};

export const rpFormKeys = {
  hour3Key: '3HR',
  hour6Key: '6HR',
  hour9Key: '9HR',
};

export const SLOT_DURATION_HOURLY_RP_MAP = {
  '180': 'RP3',
  '360': 'RP6',
  '540': 'RP9',
};

export const SLOT_DURATION_HOURLY_PERCENT_MAP = {
  '180': '3HR',
  '360': '6HR',
  '540': '9HR',
};

export const HOTEL_POLICY_CATEGORYID = 3;
export const HOTEL_POLICY_HOURLY_CFP_TEMPLATE_ID = 4; //CFP: Couple Friendly Policy
export const HOURLY_POLICY_CATEGORYID = 25;
export const HOURLY_POLICY_CATEGORYID_CFP_TEMPLATE_ID = 178;
