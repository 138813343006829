import {
  FieldPath,
  FieldValues,
  PathValue,
  useController,
} from 'react-hook-form';
import { ControlledInputProps } from '../types/input.type';
import { isNullOrUndefined } from 'utilities/Utils';
import { Input } from './InputBase';

export const ControlledInput = <
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
    props: ControlledInputProps<T, TName>,
  ) => {
  const {
    name,
    control,
    defaultValue,
    isRequired,
    customValidator,
    pattern,
    patternErrorMessage = null,
    valueAsNumber,
    setValueAs,
    type = 'text',
    convertToUppercase = false,
    ...additional
  } = props;

  const validations: Record<string, unknown> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };

  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }
  if (typeof valueAsNumber !== 'undefined') {
    validations.valueAsNumber = valueAsNumber;
  }
  if (typeof setValueAs !== 'undefined') {
    validations.setValueAs = setValueAs;
  }

  if (!isNullOrUndefined(pattern)) {
    validations.pattern = {
      value: pattern,
      message: patternErrorMessage || 'Please enter info in correct format',
    };
  }

  const { min, max } = additional;
  if (!isNullOrUndefined(min)) {
    validations.min = {
      value: min,
      message: `The value cannot be lesser than ${min}`,
    };
  }
  if (!isNullOrUndefined(max)) {
    validations.max = {
      value: max,
      message: `The value cannot be greater than ${max}`,
    };
  }

  const {
    field: { ref, value, onChange, onBlur },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });

  if (type === 'number') {
    return (
      <Input
        type="number"
        ref={ref}
        value={value as number}
        onChange={onChange}
        onBlur={onBlur}
        {...additional}
      />
    );
  }
  return (
    <Input
      ref={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      convertToUppercase={convertToUppercase}
      {...additional}
    />
  );
};
