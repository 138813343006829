import { addToDate } from 'utilities/DateUtils';

export const getCustomDate = () => {
  const now = new Date();
  return {
    clear: {
      label: 'Clear',
      get: () => {
        return null;
      },
    },
    today: {
      label: 'Today',
      get: () => {
        return now;
      },
    },
  };
};

export const getCustomDates = () => {
  const now = new Date();
  return {
    clear: {
      label: 'Clear',
      get: () => {
        return [];
      },
    },
    today: {
      label: 'Today',
      get: () => {
        return [now];
      },
    },
  };
};

export const getCustomRanges = () => {
  const now = new Date();
  const currentDay = now.getDay();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();
  return {
    clear: {
      label: 'Clear',
      get: () => {
        return { from: null, to: null };
      },
    },
    today: {
      label: 'Today',
      get: () => {
        return { from: now, to: now };
      },
    },
    yesterday: {
      label: 'Yesterday',
      get: () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return { from: yesterday, to: yesterday };
      },
    },
    'next-7-days': {
      label: 'This Week',
      get: (date = now) => {
        const endOfWeek = addToDate(6, 'd', date);
        return { from: date, to: endOfWeek };
      },
    },
    'prev-7-days': {
      label: 'This Week',
      get: (date = now) => {
        const endOfWeek = addToDate(6, 'd', date);
        return { from: date, to: endOfWeek };
      },
    },
    'this-week': {
      label: 'This Week',
      get: () => {
        const startOfWeek = new Date(
          currentYear,
          currentMonth,
          currentDate - currentDay,
        );
        const endOfWeek = new Date(
          currentYear,
          currentMonth,
          currentDate + (6 - currentDay),
        );
        return { from: startOfWeek, to: endOfWeek };
      },
    },
    'last-week': {
      label: 'Last Week',
      get: () => {
        const startOfLastWeek = new Date(
          currentYear,
          currentMonth,
          currentDate - currentDay - 7,
        );
        const endOfLastWeek = new Date(
          currentYear,
          currentMonth,
          currentDate - currentDay - 1,
        );
        return { from: startOfLastWeek, to: endOfLastWeek };
      },
    },
    'this-month': {
      label: 'This Month',
      get: () => {
        const startOfMonth = new Date(currentYear, currentMonth, 1); // First day of the month
        const endOfMonth = new Date(currentYear, currentMonth + 1, 0); // Last day of the month
        return { from: startOfMonth, to: endOfMonth };
      },
    },

    'last-month': {
      label: 'Last Month',
      get: () => {
        const lastMonth = currentMonth - 1;
        const startOfLastMonth = new Date(currentYear, lastMonth, 1); // First day of the month
        const endOfLastMonth = new Date(currentYear, lastMonth + 1, 0); // Last day of the month
        return { from: startOfLastMonth, to: endOfLastMonth };
      },
    },
    'this-year': {
      label: 'This Year',
      get: () => {
        const startOfYear = new Date(currentYear, 0, 1); // January 1st
        const endOfYear = new Date(currentYear, 11, 31); // December 31st
        return { from: startOfYear, to: endOfYear };
      },
    },
    'last-year': {
      label: 'Last Year',
      get: () => {
        const lastYear = currentYear - 1;
        const startOfLastYear = new Date(lastYear, 0, 1); // January 1st
        const endOfLastYear = new Date(lastYear, 11, 31); // December 31st
        return { from: startOfLastYear, to: endOfLastYear };
      },
    },
    'all-time': {
      label: 'All Time',
      get: () => {
        const startOfCentury = new Date(2000, 0, 1); // January 1st
        const endOfCentury = new Date(2099, 11, 31); // December 31st
        return { from: startOfCentury, to: endOfCentury };
      },
    },
  };
};
