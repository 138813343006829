/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { PROPERTY_CHAIN_IDS, SOURCE_PARTNER } from '../../constants';
import { routes } from '../routes';

//Icons
import { TabIcons } from 'assets/modules/hotelHeader';
import {
  CampaignEntityAgreement,
  CampaignManagerIds,
} from 'modules/campaign-manager/Campaigns.types';
import { CAMPGAIN_MANAGER_KEY } from 'shared_logic/constants';
import { Rights } from '../../interfaces/AppContextInterface';
import {
  ClearTaxInfo,
  CurrentHotel,
  TabsInfo,
} from '../../interfaces/HotelContextInterface';
import { getPropertyTabsArray } from '../../modules/property/PropertyTabs';
import { ITabsToHide } from './interfaces';

//Modules
//removed lazy loading for this page as it is the first to be loaded
import DashboardMain from '../../modules/dashboard/DashboardMain';
import withLoaderWrapper from './withLoaderWrapper';
import { ISTRINGS } from '../../interfaces/Strings';
import { isNullOrUndefined } from 'utilities/Utils';

const AnalyticsMain = React.lazy(
  () => import('../../modules/analytics/AnalyticsMain'),
);
export const InventoryMain = React.lazy(
  () => import('../../modules/inventory/InventoryMain'),
);
const CompetitiveAnalysisMain = React.lazy(
  () => import('../../modules/competitive-analysis/CompetitiveAnalysisMainNew'),
);
const PnCMain = React.lazy(
  () => import('../../modules/promotions-and-coupons/PnCMain'),
);
const SponsoredListingMain = React.lazy(
  () => import('../../modules/sponsored-listing/SponsoredListingMain'),
);
const PerformanceBoosterMain = React.lazy(
  () => import('../../modules/performanceBooster/PerformanceBoosterMain'),
);
export const GuestChatMain = React.lazy(
  () => import('../../modules/guest-chat/GuestChatMain'),
);
const PropertyMain = React.lazy(
  () => import('../../modules/property/PropertyMain'),
);
const ListingsMain = React.lazy(
  () => import('../../modules/host-web/modules/listing/ListingMain'),
);
export const BookingsRevampMain = React.lazy(
  () => import('../../modules/bookings-revamp/BookingsMain'),
);
export const BookingsRevampL2Main = React.lazy(
  () => import('../../modules/bookings-revamp/L2/BookingDetailsMain'),
);
const ReviewsMain = React.lazy(
  () => import('../../modules/reviews/ReviewsMain'),
);
// Commenting for hiding purpose as extranet cleanup task
// const InstayIssuesMain = React.lazy(
//   () => import('../../modules/instay-issues/InstayIssuesMain'),
// );
const AddonsMain = React.lazy(() => import('../../modules/addons/AddonsMain'));
const PLBMain = React.lazy(() => import('../../modules/plb/PLBMain'));
const ReportsMain = React.lazy(
  () => import('../../modules/reports/ReportsMain'),
);
// Commenting for hiding purpose as extranet cleanup task
// const InvoicingMain = React.lazy(
//   () => import('../../modules/invoicing/InvoicesMain'),
// );
const RateRecommendation = React.lazy(
  () => import('../../modules/rateRecommendation/RateRecommendation'),
);
const PaymentDashboardMain = React.lazy(
  () => import('../../modules/payments/PaymentsMain'),
);
const DayUseRoomsMain = React.lazy(
  () => import('../../modules/day-use-rooms/DayUseRoomsMain'),
);
const GroupRatesMain = React.lazy(
  () => import('../../modules/group-rates/GroupRatesMain'),
);
const CampaignMain = React.lazy(
  () => import('../../modules/campaign-manager/CampaignManager'),
);
const ActionDetail = React.lazy(
  () => import('../../modules/opportunity-center/ActionDetail'),
);

const MyImpactMain = React.lazy(
  () => import('../../modules/my-impact/MyImpactMain'),
);
const BlackGoTribeMain = React.lazy(
  () => import('../../modules/black-goTribe/BlackGoTribeMain'),
);
const PrebuyLedgerMain = React.lazy(
  () => import('../../modules/prebuy-ledger/PrebuyLedgerMain'),
);

export const resellerOnlyTabs = [routes.prebuyLedger.base];

export const resellerTabs = [
  routes.inventory.all,
  routes.inventory.low,
  routes.bookings.BookingsListBase,
  ...resellerOnlyTabs,
];

const campaignMetadata = {
  [CAMPGAIN_MANAGER_KEY.GOSTAYS.id]: {
    label: CAMPGAIN_MANAGER_KEY.GOSTAYS.label,
    gtmAction: 'goStays',
    route: routes.businessBooster.campaign.goStay,
  },
  [CAMPGAIN_MANAGER_KEY.GOMMT_ADVANTAGE.id]: {
    label: CAMPGAIN_MANAGER_KEY.GOMMT_ADVANTAGE.label,
    gtmAction: 'selectProgramme',
    route: routes.businessBooster.campaign.selectProgramme,
  },
  [CAMPGAIN_MANAGER_KEY.DSD.id]: {
    label: CAMPGAIN_MANAGER_KEY.DSD.label,
    gtmAction: 'DailyStealDealMain',
    route: routes.businessBooster.campaign.dailyStealDeal.base,
  },
};

const CampaignRoute = (
  id: CampaignManagerIds,
  isAccepted: boolean,
): TabsInfo => {
  return {
    ...campaignMetadata[id],
    permissionGroup: 'Offers',
    component: withLoaderWrapper(CampaignMain),
    verificationBlocker: false,
    showNew: !isAccepted,
  };
};

export const reportsRoute: TabsInfo = {
  label: 'Reports',
  gtmAction: 'reports',
  route: routes.reports.base,
  component: withLoaderWrapper(ReportsMain),
  permissionGroup: 'Analytics',
  imgComponent: TabIcons.ReviewsImg,
  verificationBlocker: true,
};

const getModifiedPropertyTabsArray = ({
  currentHotel,
  commonStr,
  showNewContentScore,
}: {
  currentHotel: CurrentHotel;
  commonStr: ISTRINGS['Common'];
  showNewContentScore: boolean;
}): TabsInfo[] =>
  getPropertyTabsArray({
    currentHotel,
    commonStr,
    showNewContentScore,
  }).map(item => ({
    label: item.label,
    gtmAction: item.gtmAction,
    permissionGroup: 'Property',
    route: item.route,
    verificationBlocker: true,
    showNew: item?.showNew ?? false,
  }));

const listingsRoute = {
  label: 'Property Information',
  gtmAction: 'listings',
  imgComponent: TabIcons.PropImg,
  permissionGroup: 'Property',
  route: routes.listing.base,
  component: withLoaderWrapper(ListingsMain),
  verificationBlocker: true,
  showNew: true,
};

const MyImpactRoute = {
  label: 'MyImpact',
  gtmAction: 'MyImpact',
  route: routes.myImpactHomeStay.base,
  component: MyImpactMain,
  verificationBlocker: true,
  showNew: true,
};

/*
permissionGroup are stored in backend database hence DO not change these strings.
These need not necessarily be unique.
*/
// Change to method so will get everytime diff reference
const getAllTabs = ({
  currentHotel,
  commonStr,
  showNewContentScore,
}: {
  currentHotel: CurrentHotel;
  commonStr: ISTRINGS['Common'];
  showNewContentScore: boolean;
}): TabsInfo[] => {
  const modifiedPropertyTabsArray = getModifiedPropertyTabsArray({
    currentHotel,
    commonStr,
    showNewContentScore,
  });
  return [
    {
      label: 'Dashboard',
      gtmAction: 'dashboard',
      permissionGroup: 'Dashboard',
      route: routes.dashboard.base,
      component: DashboardMain,
      imgComponent: TabIcons.DashBoardImg,
      verificationBlocker: true,
    },
    {
      label: 'Bookings',
      imgComponent: TabIcons.BookingImg,
      verificationBlocker: false,
      gtmAction: 'bookings',
      commonParentRoute: true,
      component: withLoaderWrapper(BookingsRevampMain),
      children: [
        {
          label: 'Bookings List',
          gtmAction: 'bookings-bookings_list',
          permissionGroup: 'Bookings',
          route: routes.bookings.BookingsListBase,
          verificationBlocker: false,
        },
        {
          label: 'Old Synxis Bookings',
          gtmAction: 'bookings-old_synxis_bookings',
          permissionGroup: 'Bookings',
          route: routes.bookings.oldSynxisListBase,
          verificationBlocker: false,
        },
        {
          label: 'Old Derby Bookings',
          gtmAction: 'bookings-old_derby_bookings',
          permissionGroup: 'Bookings',
          route: routes.bookings.oldDerbyListBase,
          verificationBlocker: false,
        },
        {
          label: 'Payments',
          gtmAction: 'bookings-payments',
          permissionGroup: 'Bookings',
          route: routes.bookings.paymentsListBase,
          verificationBlocker: false,
        },
        // Commenting for hiding purpose as extranet cleanup task
        // {
        //   label: 'Adjustments',
        //   gtmAction: 'bookings-adjustments',
        //   permissionGroup: 'Bookings',
        //   route: routes.bookings.adjustmentsListBase,
        //   verificationBlocker: false,
        // },
      ],
    },
    {
      label: 'Rates & Inventory',
      imgComponent: TabIcons.InventoryImg,
      verificationBlocker: false,
      gtmAction: 'rates_inventory',
      commonParentRoute: true,
      showNew: true,
      component: withLoaderWrapper(InventoryMain),
      children: [
        {
          label: 'Manage Rates & Inventory',
          gtmAction: 'rates_inventory-calendar_view',
          permissionGroup: 'Rates & Inventory',
          route: routes.inventory.all,
          verificationBlocker: false,
        },
        {
          label: 'Linked Rates',
          gtmAction: 'rates_inventory-linked_rates',
          permissionGroup: 'Rates & Inventory',
          route: routes.inventory.linkedRates,
          verificationBlocker: false,
          showNew: true,
        },
        {
          label: 'Sync Property Calendar',
          gtmAction: 'rates_inventory-sync-calendar',
          permissionGroup: 'Rates & Inventory',
          route: routes.inventory.calSync,
          verificationBlocker: false,
          showNew: true,
        },
        {
          label: 'Default Rates & Inventory',
          gtmAction: 'rates_inventory-default_rates_inventory',
          permissionGroup: 'Rates & Inventory',
          route: routes.inventory.default,
          verificationBlocker: false,
        },
      ],
    },
    {
      label: 'Grow Your Business',
      gtmAction: 'booster',
      imgComponent: TabIcons.NewOfferImg,
      verificationBlocker: false,
      showNew: true,
      children: [
        {
          label: 'Promotions & Coupons',
          gtmAction: 'promotions-and-coupons',
          permissionGroup: 'Offers',
          route: routes.businessBooster.pnc.base,
          component: withLoaderWrapper(PnCMain),
          verificationBlocker: false,
          showNew: false,
        },
        {
          label: 'MMTBLACK / goTribe',
          subLabel: 'Loyalty Programme',
          gtmAction: 'black-goTribe',
          permissionGroup: 'Offers',
          route: routes.businessBooster.blackGoTribe.base,
          component: withLoaderWrapper(BlackGoTribeMain),
          verificationBlocker: false,
          showNew: true,
        },
        {
          label: 'PLB & VDI',
          gtmAction: 'boost_performance',
          permissionGroup: 'Offers',
          route: routes.businessBooster.performance,
          component: withLoaderWrapper(PerformanceBoosterMain),
          verificationBlocker: true,
        },
      ],
    },
    {
      label: 'Pre-buy Ledger',
      gtmAction: 'prebuy_ledger',
      route: routes.prebuyLedger.base,
      component: withLoaderWrapper(PrebuyLedgerMain),
      imgComponent: TabIcons.PrebuyLedgerImg,
      verificationBlocker: true,
      showNew: true,
    },
    {
      label: 'Payments',
      gtmAction: 'payment',
      permissionGroup: 'Payments',
      route: routes.payments.base,
      component: withLoaderWrapper(PaymentDashboardMain),
      imgComponent: TabIcons.PaymentsImg,
      verificationBlocker: false,
    },
    {
      label: 'New Offerings',
      imgComponent: TabIcons.NewOfferImg,
      verificationBlocker: false,
      gtmAction: 'new_offering',
      showNew: false,
      children: [
        {
          label: 'Group Rates',
          gtmAction: 'group_rates',
          permissionGroup: 'Rates & Inventory',
          route: routes.groupBookings.base,
          component: withLoaderWrapper(GroupRatesMain),
          verificationBlocker: true,
          showNew: false,
        },
        {
          label: 'Hourly Stays',
          gtmAction: 'hourly_rates',
          permissionGroup: 'Rates & Inventory',
          route: routes.dayUse.base,
          component: withLoaderWrapper(DayUseRoomsMain),
          verificationBlocker: true,
          showNew: false,
        },
      ],
    },
    {
      label: 'Market Analysis',
      gtmAction: 'competitive_analysis',
      permissionGroup: 'Rates & Inventory',
      route: routes.competitiveAnalysis.base,
      component: withLoaderWrapper(CompetitiveAnalysisMain),
      imgComponent: TabIcons.CompetitiveAnalysisImg,
      verificationBlocker: true,
    },
    {
      label: 'Sponsored Listing',
      gtmAction: 'sponsered_listing',
      permissionGroup: 'Rates & Inventory',
      route: routes.sponsoredListing.base,
      component: withLoaderWrapper(SponsoredListingMain),
      imgComponent: TabIcons.SponsporedListingImg,
      verificationBlocker: true,
      showNew: false,
    },
    {
      label: 'Property Information',
      gtmAction: 'property',
      type: 'Property',
      imgComponent: TabIcons.PropImg,
      verificationBlocker: true,
      commonParentRoute: true,
      component: withLoaderWrapper(PropertyMain),
      children: modifiedPropertyTabsArray,
      showNew:
        currentHotel.is_instagram_enabled ||
        modifiedPropertyTabsArray.some(
          tab => tab?.showNew && tab.label !== 'Property Vibe',
        ),
    },
    {
      label: 'Ratings & Reviews',
      gtmAction: 'reviews-ratings_and_reviews',
      permissionGroup: 'Content',
      route: routes.reviews.ratings,
      component: withLoaderWrapper(ReviewsMain),
      imgComponent: TabIcons.ReviewsImg,
      verificationBlocker: true,
    },
    {
      label: 'Analytics',
      gtmAction: 'analytics',
      permissionGroup: 'Analytics',
      route: routes.analytics.base,
      component: withLoaderWrapper(AnalyticsMain),
      imgComponent: TabIcons.AnalyticsImg,
      verificationBlocker: true,
      showNew: true,
    },
    {
      label: 'Guest Chat',
      imgComponent: TabIcons.GuestChatImg,
      gtmAction: 'guest_chat',
      verificationBlocker: true,
      children: [
        {
          label: 'Guest Messages',
          gtmAction: 'guest_chat-ingochat',
          route: routes.guestchat.base,
          permissionGroup: 'Messages',
          component: withLoaderWrapper(GuestChatMain),
          verificationBlocker: true,
        },
        // Commenting for hiding purpose as extranet cleanup task
        // {
        //   label: 'Instay Guest Issues',
        //   gtmAction: 'guest_chat-instay_issues',
        //   route: routes.instay.base,
        //   permissionGroup: 'Messages',
        //   component: withLoaderWrapper(InstayIssuesMain),
        //   verificationBlocker: true,
        // },
      ],
    },
    {
      label: 'More',
      gtmAction: 'more',
      imgComponent: TabIcons.MoreImage,
      verificationBlocker: true,
      showNew:
        currentHotel.is_sustainability_enabled && currentHotel.showListings,
      children: [
        {
          label: 'Margin Offers',
          gtmAction: 'more-plb_margin_offers',
          route: routes.plb.base,
          component: withLoaderWrapper(PLBMain),
          permissionGroup: 'Offers',
          imgComponent: TabIcons.PlbImg,
          verificationBlocker: true,
        },
        reportsRoute,
        {
          label: 'Add Ons',
          gtmAction: 'more-add_ons',
          route: routes.addons.base,
          component: withLoaderWrapper(AddonsMain),
          permissionGroup: 'Rates & Inventory',
          imgComponent: TabIcons.ReviewsImg,
          verificationBlocker: true,
        },
        // Commenting the code as part of extranet cleanup task
        // {
        //   label: 'Tax Invoicing',
        //   gtmAction: 'more-tax_invoicing',
        //   route: routes.invoicing.base,
        //   component: withLoaderWrapper(InvoicingMain),
        //   imgComponent: TabIcons.InvoiceImg,
        //   permissionGroup: 'Bookings',
        //   verificationBlocker: true,
        // },
        {
          label: 'Rate Recommendation',
          gtmAction: 'more-tax_invoicing',
          route: routes.rateRecommendation.base,
          component: withLoaderWrapper(RateRecommendation),
          permissionGroup: 'Analytics',
          imgComponent: TabIcons.InvoiceImg,
          verificationBlocker: true,
        },
        {
          label: 'Action center',
          gtmAction: 'OpportunityCenterDeatil',
          route: routes.opportunityCenter.actionDetail,
          component: withLoaderWrapper(ActionDetail),
          verificationBlocker: true,
        },
      ],
    },
  ];
};

export const filterTabs = (
  tabs: TabsInfo[],
  tabsToHide: ITabsToHide,
): TabsInfo[] => {
  return tabs.reduce((acc, item) => {
    if (item.children) {
      const filteredChild = filterTabs(item.children, tabsToHide);
      const temp = { ...item, children: filteredChild };
      acc.push(temp);
    } else if (tabsToHide[item.route] !== true) {
      acc.push(item);
    }
    return acc;
  }, []);
};

const READ_WRITE = 'Read-Write';

function checkUserHasPermission(tab: TabsInfo, userRights) {
  let blockedTabs = [];
  if (userRights) {
    blockedTabs = userRights.blocked;
  }

  return !(
    tab.permissionGroup in blockedTabs &&
    blockedTabs[tab.permissionGroup] === READ_WRITE
  );
}

const getTabsToHideBasedOnPermission = (tabs: TabsInfo[], userRights) => {
  let tabsToHide: ITabsToHide = {};
  tabs.forEach(tab => {
    if (tab.children) {
      const childrenTabsToHide = getTabsToHideBasedOnPermission(
        tab.children,
        userRights,
      );
      tabsToHide = { ...tabsToHide, ...childrenTabsToHide };
    } else if (!checkUserHasPermission(tab, userRights)) {
      tabsToHide[tab.route] = true;
    }
  });
  return tabsToHide;
};

const filterResellerOnlyTabs = (tabs: TabsInfo[], tabsToHide, campaign) => {
  tabs.forEach(tab => {
    if (tab.children) {
      filterResellerOnlyTabs(tab.children, tabsToHide, campaign);
    } else if (!resellerTabs.includes(tab.route)) {
      tabsToHide[tab.route] = true;
    } else if (
      tab.route === routes.businessBooster.campaign.dailyStealDeal.base &&
      !campaign.is_dsd_activated
    ) {
      // hide DSD tab in Reseller View if DSD is not active
      tabsToHide[tab.route] = true;
    }
  });
};

export function decideTabs(options: {
  currHotel: CurrentHotel;
  clearTaxInfo: Partial<ClearTaxInfo>;
  userRights: Rights;
  campaignEntity: CampaignEntityAgreement;
  externalCalSyncData;
  isResellerView: boolean;
  commonStr: ISTRINGS['Common'];
  showNewContentScore: boolean;
}) {
  const {
    currHotel,
    clearTaxInfo,
    userRights = null,
    campaignEntity,
    externalCalSyncData,
    isResellerView,
    commonStr,
    showNewContentScore,
  } = options;

  const {
    showListings,
    isDomHotel,
    isactive,
    is_direct_connect: isDirectConnect,
    chain_id: chainId,
    is_chat_enabled: isChatEnabled,
    is_price_recommendation_enabled: isPriceRecommendationEnabled,
    is_compt_analysis_enabled: isComptAnalysisEnabled,
    pricing_model: pricingModel,
    source_partner: sourcePartner,
    hideSponsoredListing,
    is_eligible_for_group_booking: isEligibleForGroupBooking,
    dayuse_booking_eligible: dayuseBookingEligible,
    isHomeStay,
    is_new_linkage_enabled: isNewLinkageEnabled,
    is_instagram_enabled: isInstagramEnabled,
    is_sustainability_enabled: isSustainabilityEnabled,
    vendor,
  } = currHotel;

  const { calSyncEnabledRoomList, calSyncNotEnabledRoomList } =
    externalCalSyncData ?? {};

  const toHideExternalCalSyncForMultiRooms =
    isHomeStay &&
    (calSyncEnabledRoomList?.length > 0 ||
      calSyncNotEnabledRoomList?.length > 0);

  const showPaymentDashboardTab =
    isactive && isDomHotel && !isDirectConnect && !PROPERTY_CHAIN_IDS[chainId];

  const isSynxis = sourcePartner === SOURCE_PARTNER.SYNXIS;
  const isDerby = sourcePartner === SOURCE_PARTNER.DERBY;

  const isPartnerProperty = isSynxis || isDerby;

  const updatedTabs = getAllTabs({
    currentHotel: currHotel,
    commonStr,
    showNewContentScore,
  });

  if (showListings) {
    updatedTabs.forEach((tab, index) => {
      //Replace /property with /listings
      if (tab?.type === 'Property') {
        updatedTabs.splice(index, 1, listingsRoute);
      } else if (tab.label === 'More' && isSustainabilityEnabled) {
        tab.children.push(MyImpactRoute);
      }
    });
  }

  const growYourBusinessIndex = updatedTabs.findIndex(
    item => item.label === 'Grow Your Business',
  );

  const growYourBusinessTab = updatedTabs[growYourBusinessIndex];
  for (const key in CAMPGAIN_MANAGER_KEY) {
    const { id } = CAMPGAIN_MANAGER_KEY[key];
    const campaignData = campaignEntity[id as CampaignManagerIds];
    if (campaignData && campaignMetadata[id]) {
      growYourBusinessTab.children.push(
        CampaignRoute(id, campaignData.isAccepted),
      );
    }
  }

  const tabsToHide = getTabsToHideBasedOnPermission(updatedTabs, userRights);
  tabsToHide[routes.invoicing.base] = clearTaxInfo.is_eligible !== true;
  tabsToHide[routes.guestchat.base] = !isChatEnabled;
  tabsToHide[routes.rateRecommendation.base] = !isPriceRecommendationEnabled;
  tabsToHide[routes.competitiveAnalysis.base] = !isComptAnalysisEnabled;
  //Hide for pricing model 3 & Partner(SynXis) hotels
  tabsToHide[routes.inventory.default] =
    pricingModel === 3 || isPartnerProperty;
  //Hide for Partner(SynXis) hotels
  tabsToHide[routes.inventory.low] = isPartnerProperty;
  //Hide sponsored listing
  tabsToHide[routes.sponsoredListing.base] = hideSponsoredListing;
  //Bookings
  tabsToHide[routes.bookings.adjustmentsListBase] = isDirectConnect;
  tabsToHide[routes.bookings.oldSynxisListBase] =
    vendor === SOURCE_PARTNER.INGO ||
    vendor === SOURCE_PARTNER.DERBY ||
    isNullOrUndefined(vendor);
  tabsToHide[routes.bookings.oldDerbyListBase] =
    isNullOrUndefined(vendor) || sourcePartner !== SOURCE_PARTNER.DERBY;
  tabsToHide[routes.bookings.paymentsListBase] = showPaymentDashboardTab;
  tabsToHide[routes.payments.base] = !showPaymentDashboardTab;

  //Group Booking
  tabsToHide[routes.groupBookings.base] =
    isNewLinkageEnabled || !isEligibleForGroupBooking;

  tabsToHide[routes.dayUse.base] =
    isNewLinkageEnabled || !dayuseBookingEligible;
  tabsToHide[routes.property.vibe] = !isInstagramEnabled;

  tabsToHide[routes.myImpactHomeStay.base] = !isSustainabilityEnabled;
  tabsToHide[routes.myImpactHotel.base] = !isSustainabilityEnabled;

  tabsToHide[routes.inventory.calSync] = !toHideExternalCalSyncForMultiRooms;
  tabsToHide[routes.prebuyLedger.base] = !isResellerView;

  tabsToHide[routes.property.newContentScore] = !showNewContentScore;

  // hiding tabs for reseller only user
  if (isResellerView) {
    filterResellerOnlyTabs(
      updatedTabs,
      tabsToHide,
      campaignEntity[CAMPGAIN_MANAGER_KEY.DSD.id],
    );
  }

  return filterTabs(updatedTabs, tabsToHide);
}
