import { CalendarBase } from './variants/CalendarBase';
import { CalendarInput } from './variants/CalendarInput';
import { BlackoutCalendarInput } from './variants/BlackoutCalendarInput';
import { CalendarProps } from './Calendar.types';
import { CalendarBasic } from './variants/CalendarBasic';

export const Calendar = (props: CalendarProps) => {
  switch (props.type) {
    case 'basic':
      return <CalendarBase {...props} />;
    case 'input':
      return <CalendarInput {...props} />;
    case 'blackout':
      return <BlackoutCalendarInput {...props} />;
    case 'default':
      return <CalendarBasic {...props} />;
  }
};
