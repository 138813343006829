import React from 'react';
import ContainerShimmer from './ContainerShimmer';
import { HeaderShimmer } from './HeaderShimmer';
import { LeftSectionShimmer } from './LeftSectionShimmer';

const GlobalShimmer = () => {
  return (
    <div className={'w-full flex flex-col'}>
      <HeaderShimmer />
      <div className="nav-container flex flex-row gap-3 m-3 flex-1">
        <LeftSectionShimmer />
        <ContainerShimmer hasLeftNav />
      </div>
    </div>
  );
};

export default GlobalShimmer;
