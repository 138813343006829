import { type Ref, forwardRef, useCallback } from 'react';
import { DropdownBase } from './DropdownBase';
import { type DropdownHierarchicalProps } from '../DropdownMain.types';
import HierarchicalOption from '../Components/HierarchicalOption';
import { OptionLabel } from '../__LegacyDropdown__/Dropdown.types';
import { createFilterOptions } from '@mui/material';

const Dropdown = (props: DropdownHierarchicalProps, ref: Ref<unknown>) => {
  const { hierarchicalLabelField, labelField, options, ...rest } = props;
  const filter = createFilterOptions();
  const optionLabel: OptionLabel = useCallback(
    item => (typeof item === 'string' ? item : item?.[labelField]),
    [labelField],
  );

  const filterOptionsInterval = (_, state) => {
    state.inputValue = '';

    const res = filter(options as [], state) as unknown[];
    return res;
  };

  const renderOption = (incomingProps, option) => (
    <HierarchicalOption
      incomingProps={incomingProps}
      optionLabel={optionLabel(option)}
      optionHierarchicalSubLabel={option[hierarchicalLabelField]}
    />
  );

  return (
    <DropdownBase
      {...rest}
      options={options}
      renderOption={renderOption}
      filterOptions={filterOptionsInterval}
      labelField={labelField}
      ref={ref}
      inputDisabled={true}
      MenuProps={{
        PaperProps: {
          elevation: 0,
        },
      }}
    />
  );
};

const DropdownHierarchical = forwardRef(Dropdown);
export default DropdownHierarchical;
