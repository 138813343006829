import { useContext } from 'react';

import { Button, FormElement, Tag } from 'components/latest-core';
import { HotelContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import { PLACEHOLDER } from '../reseller.constants';

type AgreementSectionProps = {
  viewAgreementButtonError: string;
  handleViewAgreementClick: () => void;
  isAgreementAccepted: boolean;
};

const AgreementSection = (props: AgreementSectionProps) => {
  const {
    viewAgreementButtonError,
    handleViewAgreementClick,
    isAgreementAccepted,
  } = props;

  const [STRINGS] = useLangStrings<'Reseller'>('Reseller');

  const {
    currentHotel: { hotelname: hotelName },
  } = useContext(HotelContext);

  return (
    <div className="p-4 bg-[#F8F8F8] text-[14px]">
      <div>
        {STRINGS.AGREEMENT_SECTION.AGREEMENT_DESCRIPTION.replace(
          PLACEHOLDER,
          hotelName,
        )}
      </div>

      <div className="mt-8">
        {STRINGS.AGREEMENT_SECTION.AGREEMENT_TEXT_1}
        <ul className="my-0 list-disc pl-4">
          {STRINGS.AGREEMENT_SECTION.AGREEMENT_TEXT_LIST.map(text => (
            <li>{text}</li>
          ))}
        </ul>
      </div>
      <div className="flex flex-row items-center mt-4">
        <FormElement errorMsg={viewAgreementButtonError}>
          <Button variant="outlined" onClick={handleViewAgreementClick}>
            {STRINGS.CTA_TEXT.VIEW_AGREEMENT}
          </Button>
        </FormElement>
        {isAgreementAccepted && (
          <Tag
            label={STRINGS.AGREEMENT_SECTION.ACCEPTED}
            color={'success'}
            variant={'outlined'}
            className="ml-2 mb-1 border-none"
          />
        )}
      </div>
    </div>
  );
};

export default AgreementSection;
