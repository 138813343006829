import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import * as GroupBookings from 'assets/modules/group-bookings';
import Stepper from 'components/latest-core/Stepper/Stepper2.0';
import SetupAccount from './components/SetupAccount';
import AgreementSection from './components/AgreementSection';
import { getDefaultValuesInitialized } from './reseller.utils';
import {
  acceptResellerAgreement,
  activateLegalEntity,
  createResellerOnlyUser,
  markAdminUsersAsReseller,
} from './ResellerGraphClient';
import { AppContext, HotelContext } from 'utilities/Context';
import { FaqCardsLatest } from 'components/common';
import BDMDetailsCard from './components/BDMDetailsCard';
import { getFooterDetails } from './ResllerAPIClient';
import { useLangStrings, useOpenDocumentLink } from 'utilities/CustomHooks';
import { getValue, goBack } from 'utilities/Utils';
import { Button } from 'components/latest-core';
import KnowMorePopup from './components/KnowMorePopup';
import SuccessPopup from './components/SuccessPopup';
import { pushToOmniture } from 'utilities/gtm';
import { ResellerIntroVideo } from 'modules/dashboard/components/ResellerOnboardingPopup';
import { SETUP_ACCOUNT_FORM_FIELDS } from './reseller.constants';
import { ResellerOnboardingFormType } from './Reseller.types';
import { BdmDetailsType } from 'shared_logic/SharedAPIClient';

export type ExistingAccountsType = {
  first_name: string;
  last_name: string;
  countrycode: string;
  mobile: string;
  country_id: number;
  email: string;
  id: number;
};

const StepperSideContent = props => {
  const { contactDetails } = props;

  const [STRINGS] = useLangStrings<'Reseller'>('Reseller');

  return (
    <>
      <FaqCardsLatest
        openFirst={false}
        title={STRINGS.RESELLER_ONBOARDING.FAQ_TITLE}
        faqs={STRINGS.RESELLER_ONBOARDING.FAQS}
      />
      <BDMDetailsCard contactDetails={contactDetails} />
    </>
  );
};

const ResellerOnboarding = () => {
  const { user } = useContext(AppContext);
  const {
    currentHotel: { hotelcode: hotelCode },
    resellerAgreement,
  } = useContext(HotelContext);

  const [STRINGS, COMMON] = useLangStrings<'Reseller'>('Reseller');

  const [isAgreementAccepted, setIsAgreementAccepted] = useState(
    resellerAgreement?.status === 'accepted' ?? false,
  );
  const [activeStep, setActiveStep] = useState(0);
  const [isAgreemenetViewedOnce, setIsAgreemenetViewedOnce] = useState(false);
  const [viewAgreementButtonError, setViewAgreementButtonError] = useState('');
  const [contactDetails, setContactDetails] = useState(null);
  const [knowMorePopup, setKnowMorePopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState<{
    open: boolean;
    accountType: 'new' | 'existing';
  }>({ open: false, accountType: 'existing' });
  const [showGuidePopup, setShowGuidePopup] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [existingAccounts, setExistingAccounts] = useState<
  ExistingAccountsType[]
  >([]);
  const [emailVerifiedError, setEmailVerifiedError] = useState('');

  const [openDocument] = useOpenDocumentLink(true);

  const formMethods = useForm<ResellerOnboardingFormType>({
    mode: 'onChange',
    defaultValues: getDefaultValuesInitialized(),
    shouldUnregister: false,
  });

  const { handleSubmit, setError } = formMethods;

  const getContactDetails = () => {
    getFooterDetails(hotelCode)
      .then((resp: BdmDetailsType) => {
        setContactDetails(resp?.contractbdo);
      })
      .catch(error => {
        console.error('get footer details', error);
      });
  };

  const onHandleActivateTravelBizFailure = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'error|activate_travelbiz_error',
        componentName: 'hoteltravel_onboarding',
      },
      loadedComponents: 'Form Error',
    });
  };

  const handleActivateTravelBiz = data => {
    const { accountType } = data;

    if (accountType === 'new') {
      if (!isMobileVerified) {
        //@ts-ignore
        setError(SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.name, {
          type: 'custom',
          message: STRINGS.RESELLER_ONBOARDING.MOBILE_ERROR,
        });
        //@ts-ignore
        setError(SETUP_ACCOUNT_FORM_FIELDS.EMAIL_ADDRESS.name, {
          type: 'custom',
          message: STRINGS.RESELLER_ONBOARDING.EMAIL_ERROR_2,
        });

        pushToOmniture({
          event: 'ctaClick',
          cta: {
            name: 'error|verify_mobile_to_proceed_error',
            componentName: 'hoteltravel_onboarding',
          },
          loadedComponents: STRINGS.RESELLER_ONBOARDING.MOBILE_ERROR,
        });

        return;
      }
      if (!isEmailVerified) {
        setEmailVerifiedError(STRINGS.RESELLER_ONBOARDING.EMAIL_ERROR);

        pushToOmniture({
          event: 'ctaClick',
          cta: {
            name: 'error|verify_email_to_proceed_error',
            componentName: 'hoteltravel_onboarding',
          },
          loadedComponents: STRINGS.RESELLER_ONBOARDING.EMAIL_ERROR,
        });

        return;
      }
    }

    let promise;
    if (accountType === 'new') {
      // make call for reseller only user
      promise = createResellerOnlyUser(hotelCode, data);
    } else {
      promise = Promise.resolve();
    }

    promise.then(() => {
      const markAdminUsersAsResellerPromise =
        markAdminUsersAsReseller(hotelCode);
      const activateLegalEntityPromise = activateLegalEntity(hotelCode);

      Promise.all([markAdminUsersAsResellerPromise, activateLegalEntityPromise])
        .then(() => {
          setShowSuccessPopup({ open: true, accountType });

          pushToOmniture({
            event: 'ctaClick',
            cta: {
              name: 'activate_hotel_travel',
              componentName: 'hoteltravel_onboarding',
            },
            miscId3: accountType,
          });
        })
        .catch(error => {
          console.error('Mark admin users', error);
        });
    });
  };

  const handleViewAgreementClick = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'view_agreement',
        componentName: 'hoteltravel_onboarding',
      },
    });

    if (!isAgreemenetViewedOnce) {
      setIsAgreemenetViewedOnce(true);
      setViewAgreementButtonError('');
    }
    const url = resellerAgreement.sample_url;
    openDocument(url);
  };

  const handleKnowMoreClick = () => {
    setKnowMorePopup(true);

    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'i_want_to_know_more',
        componentName: 'hoteltravel_onboarding',
      },
    });
  };

  const handleGuidePopupClick = () => {
    setShowGuidePopup(true);

    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'video_help',
        componentName: 'hoteltravel_onboarding',
      },
    });
  };

  const handleGuidePopupClose = () => {
    setShowGuidePopup(false);
  };

  const navigateToStep = (step: number) => {
    setActiveStep(step);
  };

  const handleSave = section => {
    if (section === 'AgreementSection') {
      if (isAgreementAccepted) {
        pushToOmniture({
          event: 'ctaClick',
          cta: {
            name: 'next',
            componentName: 'hoteltravel_onboarding',
          },
        });

        return Promise.resolve(true);
      }

      // If agreemenet is not viewed at least once, throw error.
      if (!isAgreemenetViewedOnce) {
        setViewAgreementButtonError(
          STRINGS.RESELLER_ONBOARDING.STEPPER.AGREEMENT_VIEW_ERROR,
        );

        pushToOmniture({
          event: 'ctaClick',
          cta: {
            name: 'error|agreement_not_accepted_error',
            componentName: 'hoteltravel_onboarding',
          },
          loadedComponents:
            STRINGS.RESELLER_ONBOARDING.STEPPER.AGREEMENT_VIEW_ERROR,
        });

        return Promise.resolve(false);
      }

      // if agreeement status is pending, make api call, otherwise, just increase the step

      if (!isAgreementAccepted) {
        // make a local state here to maintain that he has accepted the agreement.

        pushToOmniture({
          event: 'ctaClick',
          cta: {
            name: 'accept',
            componentName: 'hoteltravel_onboarding',
          },
        });

        return acceptResellerAgreement(hotelCode, user).then(data => {
          const success = getValue(
            //@ts-ignore
            data,
            'rawEndpoint.acceptAgreementList.modelType1.success',
          );
          if (success) {
            setIsAgreementAccepted(true);
            return Promise.resolve(true);
          }
          return Promise.resolve(false);
        });
      }

      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  };

  const steps = [
    {
      title: STRINGS.RESELLER_ONBOARDING.STEPPER.STEP_1_TITLE,
      subtitle: '',
      summary:
        isAgreementAccepted && STRINGS.RESELLER_ONBOARDING.STEPPER.ACCEPTED,
      content: (
        <AgreementSection
          viewAgreementButtonError={viewAgreementButtonError}
          handleViewAgreementClick={handleViewAgreementClick}
          isAgreementAccepted={isAgreementAccepted}
        />
      ),
      sideContent: <StepperSideContent contactDetails={contactDetails} />,
      makeApiCall: true,
      section: 'AgreementSection',
      secondaryCTA: !isAgreementAccepted ? (
        <Button
          variant="outlined"
          onClick={handleKnowMoreClick}
          className="ml-6"
        >
          {STRINGS.CTA_TEXT.I_WANT_TO_KNOW_MORE}
        </Button>
      ) : null,
    },
    {
      title: STRINGS.RESELLER_ONBOARDING.STEPPER.STEP_2_TITLE,
      content: (
        <SetupAccount
          isMobileVerified={isMobileVerified}
          setIsMobileVerified={setIsMobileVerified}
          isEmailVerified={isEmailVerified}
          setIsEmailVerified={setIsEmailVerified}
          existingAccounts={existingAccounts}
          setExistingAccounts={setExistingAccounts}
          emailVerifiedError={emailVerifiedError}
          setEmailVerifiedError={setEmailVerifiedError}
        />
      ),
      sideContent: <StepperSideContent contactDetails={contactDetails} />,
      makeApiCall: false,
      section: 'SetupAccountSection',
    },
  ];

  const handleNextPromise = nextStep => {
    const stepObj = steps[nextStep - 1];

    if (stepObj.makeApiCall) {
      return handleSave(stepObj.section);
    }

    return true;
  };

  const onCancel = () => {
    goBack();

    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'cancel',
        componentName: 'hoteltravel_onboarding',
      },
    });
  };

  useEffect(() => {
    getContactDetails();

    pushToOmniture({
      event: 'pageView',
    });
  }, []);

  useEffect(() => {
    if (resellerAgreement) {
      setIsAgreementAccepted(resellerAgreement.status === 'accepted');
      setActiveStep(resellerAgreement.status === 'accepted' ? 1 : 0);
    }
  }, [resellerAgreement]);

  return (
    <FormProvider {...formMethods}>
      <div className="mt-4">
        <Stepper
          title={STRINGS.RESELLER_ONBOARDING.STEPPER.TRAVELBIZ_ONBOARDING}
          guidePopup={
            <div
              onClick={handleGuidePopupClick}
              className="border-solid border-[1px] border-[#EFEFEF] p-[10px] bg-[#FFFFFF] rounded-[4px] mb-4 flex justify-center items-center cursor-pointer"
            >
              <div className="font-medium text-[12px]">
                <img alt="" src={GroupBookings.ShareSvg} className="marginR6" />
                {STRINGS.RESELLER_ONBOARDING.STEPPER.VIDEO_TEXT}
              </div>
            </div>
          }
          activeStep={activeStep}
          setActiveStep={navigateToStep}
          steps={steps}
          positiveText={
            <>{STRINGS.RESELLER_ONBOARDING.STEPPER.ACTIVATE_TRAVERLBIZ}</>
          }
          onCancel={onCancel}
          NextButtonText={
            isAgreementAccepted ? COMMON.BUTTONS.NEXT : STRINGS.CTA_TEXT.ACCEPT
          }
          onNextClick={handleNextPromise}
          onSubmit={handleSubmit(
            handleActivateTravelBiz,
            onHandleActivateTravelBizFailure,
          )}
          editButtonText={COMMON.BUTTONS.VIEW}
          negativeText={COMMON.BUTTONS.EXIT}
        />
        {knowMorePopup && <KnowMorePopup setKnowMorePopup={setKnowMorePopup} />}
        {showSuccessPopup.open && (
          <SuccessPopup accountType={showSuccessPopup.accountType} />
        )}
        {showGuidePopup && (
          <ResellerIntroVideo
            onClose={handleGuidePopupClose}
            onConfirm={handleGuidePopupClose}
            isComingFromOnboarding={false}
          />
        )}
      </div>
    </FormProvider>
  );
};

export default ResellerOnboarding;
