import { OptionType } from '../Dropdown.types';

type HandleClearOptionsHook = (
  inputValue: string,
  tags: unknown[],
  setTags: (tags: unknown[]) => void,
  tagOptions: readonly unknown[],
  valueField: string,
) => () => void;

const useHandleClearOptions: HandleClearOptionsHook = (
  inputValue,
  tags,
  setTags,
  tagOptions,
  valueField,
) => {
  return () => {
    let updatedTags = [];
    if (inputValue.length) {
      updatedTags = tags.filter((tag: OptionType) => {
        return !tagOptions.find((tagOption: OptionType) => {
          return tag[valueField] === tagOption[valueField];
        });
      });
    }
    setTags([...updatedTags]);
  };
};

export default useHandleClearOptions;
