import { useContext, useEffect, useState } from 'react';
import {
  Divider,
  Button,
  ControlledInput,
  ControlledPasswordInput,
  ControlledPhoneInput,
  ControlledRadio,
  FormElement,
  OTPInput,
} from 'components/latest-core';
import { useFormContext } from 'react-hook-form';

import { SETUP_ACCOUNT_FORM_FIELDS } from '../reseller.constants';
import {
  checkIfEmailOrMobileExists,
  getExistingAccounts,
} from '../ResellerGraphClient';
import { RTBIcons } from 'assets/common';
import { sendOTP, verifyOTP } from '../ResllerAPIClient';
import ExistingAccountsSideDrawer from './ExistingAccountsSideDrawer';
import { HotelContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import { pushToOmniture } from 'utilities/gtm';
import { emailRegex, passwordRegex } from 'utilities/allRegex';
import { ExistingAccountsType } from '../ResellerOnboarding';

type SetupAccountProps = {
  isMobileVerified: boolean;
  setIsMobileVerified: React.Dispatch<React.SetStateAction<boolean>>;
  isEmailVerified: boolean;
  setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>;
  existingAccounts: ExistingAccountsType[];
  setExistingAccounts: React.Dispatch<
  React.SetStateAction<ExistingAccountsType[]>
  >;
  emailVerifiedError: string;
  setEmailVerifiedError: React.Dispatch<React.SetStateAction<string>>;
};

const VerifiedTag = () => {
  return (
    <div className="flex items-center justify-center">
      <RTBIcons.GreenTickIconComp height={24} width={24} className="mr-2" />{' '}
      <span className="text-[#2E7D32]">{'Verified'}</span>
    </div>
  );
};

const SetupAccount = (props: SetupAccountProps) => {
  const {
    isMobileVerified,
    setIsMobileVerified,
    isEmailVerified,
    setIsEmailVerified,
    existingAccounts,
    setExistingAccounts,
    emailVerifiedError,
    setEmailVerifiedError,
  } = props;

  const {
    currentHotel: { hotelcode: hotelCode },
  } = useContext(HotelContext);

  const [STRINGS] = useLangStrings<'Reseller'>('Reseller');

  const {
    control,
    watch,
    setError,
    clearErrors,

    formState: { errors },
  } = useFormContext();

  const [mobileFieldView, setMobileFieldView] = useState<'mobile' | 'otp'>(
    'mobile',
  );
  const [mobileOTP, setMobileOTP] = useState('');

  const [emailFieldView, setEmailFieldView] = useState<'email' | 'otp'>(
    'email',
  );
  const [emailOTP, setEmailOTP] = useState('');

  const [showExistingAccountsDrawer, setShowExistingAccountsDrawer] =
    useState(false);

  const [accountTypeVal, mobileVal, email] = watch([
    'accountType',
    'mobile',
    'email',
  ]);

  const handleExistingAccountsSideDrawer = () => {
    if (existingAccounts.length === 0) {
      getExistingAccounts(hotelCode)
        .then(res => {
          //@ts-ignore
          setExistingAccounts(res);
        })
        .catch(error => {
          console.error('Get existing accounts', error);
        });
    }

    setShowExistingAccountsDrawer(true);
  };

  const setupAccountOptions = [
    {
      label: (
        <span className="font-medium">
          {STRINGS.SETUP_ACCOUNT.CONTINUE_WITH_YOUR_EXISTING_ACCOUNT}
        </span>
      ),
      value: 'existing',
      description: (
        <div>
          {STRINGS.SETUP_ACCOUNT.IF_YOU_SELECT_THIS_OPTION_ALL}
          <span
            className="text-[#0B61D9] cursor-pointer"
            onClick={handleExistingAccountsSideDrawer}
          >
            {STRINGS.SETUP_ACCOUNT.EXISTING_ACCOUNTS}
          </span>{' '}
          {
            STRINGS.SETUP_ACCOUNT
              .CAN_BE_USED_TO_VIEW_DETAILS_FOR_BOTH_MAKEMYTRIP_TRAVELBIZ
          }
        </div>
      ),
    },
    {
      label: (
        <span className="font-medium">
          {STRINGS.SETUP_ACCOUNT.CREATE_A_NEW_ACCOUNT_FOR_TRAVELBIZ}
        </span>
      ),
      value: 'new',
      description:
        STRINGS.SETUP_ACCOUNT.CREATE_A_NEW_ACCOUNT_FOR_TRAVELBIZ_DESCTIPTION,
    },
  ];

  const handleVerifyMobileNumber = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'verify_mobile',
        componentName: 'hoteltravel_onboarding',
      },
    });

    checkIfEmailOrMobileExists(`${mobileVal.phone}`)
      .then(res => {
        // @ts-ignore
        const { responseCode, message } = res;

        if (responseCode !== 2) {
          setError(SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.name, {
            type: 'custom',
            message,
          });

          pushToOmniture({
            event: 'ctaClick',
            cta: {
              name: 'error|user_mobile_already_exists_error',
              componentName: 'hoteltravel_onboarding',
            },
            loadedComponents: message,
          });

          return;
        }

        sendOTP(mobileVal.phone, 'mobile', mobileVal.country)
          .then(() => setMobileFieldView('otp'))
          .catch(error => {
            console.error('Send otp', error);
          });
      })
      .catch(error => {
        console.error('check if email or mobile exists', error);
      });
  };

  const handleVerifyEmail = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'verify_email',
        componentName: 'hoteltravel_onboarding',
      },
    });

    checkIfEmailOrMobileExists(email)
      .then(res => {
        // @ts-ignore

        const { responseCode, message } = res;

        if (responseCode !== 2) {
          setError(SETUP_ACCOUNT_FORM_FIELDS.EMAIL_ADDRESS.name, {
            type: 'custom',
            message,
          });

          pushToOmniture({
            event: 'ctaClick',
            cta: {
              name: 'error|user_email_already_exists_error',
              componentName: 'hoteltravel_onboarding',
            },
            loadedComponents: message,
          });

          return;
        }

        sendOTP(email, 'email')
          .then(() => setEmailFieldView('otp'))
          .catch(error => {
            console.error('Send otp', error);
          });
      })
      .catch(error => {
        console.error('check if email or mobile exists', error);
      });
  };

  const verifyOTPHandler = (otp, contactType, contactAddress) => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: `verify_${contactType}_otp`,
        componentName: 'hoteltravel_onboarding',
      },
    });

    verifyOTP(otp, contactAddress)
      .then(res => {
        // @ts-ignore
        const { success, message } = res;

        if (success) {
          switch (contactType) {
            case 'mobile': {
              setIsMobileVerified(true);
              setMobileFieldView('mobile');
              clearErrors([
                SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.name,
                SETUP_ACCOUNT_FORM_FIELDS.EMAIL_ADDRESS.name,
              ]);
              break;
            }
            case 'email': {
              setIsEmailVerified(true);
              setEmailVerifiedError('');
              setEmailFieldView('email');
              break;
            }
            default:
              break;
          }
        } else {
          pushToOmniture({
            event: 'ctaClick',
            cta: {
              name: `error|${contactAddress}_incorrect_otp`,
              componentName: 'hoteltravel_onboarding',
            },
            loadedComponents: message,
          });

          if (contactType === 'mobile') setMobileOTP('----');
          else setEmailOTP('------');
        }
      })
      .catch(error => {
        console.error('verify otp', error);
      });
  };

  const handleNumberChange = () => {
    setMobileOTP('----');
    setMobileFieldView('mobile');

    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'change_mobile',
        componentName: 'hoteltravel_onboarding',
      },
    });
  };

  const handleEmailChange = () => {
    setEmailOTP('------');
    setEmailFieldView('email');

    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'change_email',
        componentName: 'hoteltravel_onboarding',
      },
    });
  };

  const resendMobileOTPHandler = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'resend_mobile_otp',
        componentName: 'hoteltravel_onboarding',
      },
    });

    return sendOTP(mobileVal.phone, 'mobile', mobileVal.country);
  };

  const resendEmailOTPHandler = () => {
    pushToOmniture({
      event: 'ctaClick',
      cta: {
        name: 'resend_email_otp',
        componentName: 'hoteltravel_onboarding',
      },
    });

    return sendOTP(email, 'email');
  };

  const disableVerifyMobileButton = () => {
    return (
      (errors[SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.name] &&
        errors[SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.name]?.type !==
          'custom') ||
      mobileVal.phone === ''
    );
  };

  useEffect(() => {
    if (accountTypeVal === 'existing') {
      clearErrors();
    }
  }, [accountTypeVal]);

  return (
    <div>
      <div className="text-[16px] font-medium">
        {STRINGS.SETUP_ACCOUNT.RADIO_BUTTON_QUESTION}
      </div>

      <div>
        <ControlledRadio
          options={setupAccountOptions}
          control={control}
          name={SETUP_ACCOUNT_FORM_FIELDS.ACCOUNT_TYPE.name}
          customClasses={{
            groupRoot: 'mt-4 !gap-4',
          }}
        />
      </div>

      {accountTypeVal === 'new' && (
        <div className="mt-4 p-4 bg-[#F8F8F8]">
          <div className="font-medium text-[16px]">
            {STRINGS.SETUP_ACCOUNT.ENTER_YOUR_NEW_ACCOUNT_DETAILS}
          </div>
          <div className="text-[14px] mt-1 text-[#787878]">
            {STRINGS.SETUP_ACCOUNT.ENTER_YOUR_NEW_ACCOUNT_DETAILS_DESCTIPTION}
          </div>
          <div className="flex flex-row  mt-6 w-[340px]">
            <FormElement
              title={SETUP_ACCOUNT_FORM_FIELDS.FIRST_NAME.label}
              errorMsg={errors[
                SETUP_ACCOUNT_FORM_FIELDS.FIRST_NAME.name
              ]?.message?.toString()}
            >
              <ControlledInput
                control={control}
                name={SETUP_ACCOUNT_FORM_FIELDS.FIRST_NAME.name}
                isRequired={SETUP_ACCOUNT_FORM_FIELDS.FIRST_NAME.isRequired}
              />
            </FormElement>

            <FormElement
              title={SETUP_ACCOUNT_FORM_FIELDS.LAST_NAME.label}
              className="pl-4"
              errorMsg={errors[
                SETUP_ACCOUNT_FORM_FIELDS.LAST_NAME.name
              ]?.message?.toString()}
            >
              <ControlledInput
                control={control}
                name={SETUP_ACCOUNT_FORM_FIELDS.LAST_NAME.name}
                isRequired={SETUP_ACCOUNT_FORM_FIELDS.FIRST_NAME.isRequired}
              />
            </FormElement>
          </div>

          <Divider className="my-4" />

          {mobileFieldView === 'mobile' ? (
            <div className="flex flex-row  mt-6 ">
              <FormElement
                title={SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.label}
                className="w-[340px]"
                errorMsg={errors[
                  SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.name
                ]?.message?.toString()}
              >
                <ControlledPhoneInput
                  control={control}
                  name={SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.name}
                  isRequired={
                    SETUP_ACCOUNT_FORM_FIELDS.MOBILE_NUMBER.isRequired
                  }
                  rightElement={isMobileVerified && <VerifiedTag />}
                  disabled={isMobileVerified}
                  disableDropdown={true}
                />
              </FormElement>

              {!isMobileVerified && (
                <Button
                  variant="text"
                  className="mt-6 ml-8"
                  disabled={disableVerifyMobileButton()}
                  onClick={handleVerifyMobileNumber}
                >
                  {STRINGS.CTA_TEXT.VERIFY_MOBILE_NUMBER}
                </Button>
              )}
            </div>
          ) : (
            <div className="mt-6">
              <OTPInput
                value={mobileOTP}
                onChange={val => setMobileOTP(val)}
                maxLength={4}
                data={`${mobileVal.country} ${mobileVal.phone}`}
                type="number"
                onChangeNumber={handleNumberChange}
                resendOTPHandler={resendMobileOTPHandler}
                verifyOTPHandler={() => {
                  verifyOTPHandler(mobileOTP, 'mobile', mobileVal.phone);
                }}
              />
            </div>
          )}

          <Divider className="my-4" />

          {emailFieldView === 'email' ? (
            <div className="flex flex-row  mt-6 ">
              <FormElement
                title={SETUP_ACCOUNT_FORM_FIELDS.EMAIL_ADDRESS.label}
                className="w-[340px]"
                errorMsg={
                  errors[
                    SETUP_ACCOUNT_FORM_FIELDS.EMAIL_ADDRESS.name
                  ]?.message?.toString() || emailVerifiedError
                }
              >
                <ControlledInput
                  control={control}
                  name={SETUP_ACCOUNT_FORM_FIELDS.EMAIL_ADDRESS.name}
                  isRequired={isMobileVerified}
                  disabled={!isMobileVerified || isEmailVerified}
                  placeholder={STRINGS.SETUP_ACCOUNT.ENTER_YOUR_EMAIL_ID}
                  rightElement={isEmailVerified && <VerifiedTag />}
                  pattern={emailRegex}
                />
              </FormElement>

              {!isEmailVerified && (
                <Button
                  variant="text"
                  className="mt-6 ml-8"
                  disabled={
                    !!errors[SETUP_ACCOUNT_FORM_FIELDS.EMAIL_ADDRESS.name]
                      ?.message ||
                    mobileVal.phone === '' ||
                    !isMobileVerified
                  }
                  onClick={handleVerifyEmail}
                >
                  {STRINGS.CTA_TEXT.VERIFY_EMAIl}
                </Button>
              )}
            </div>
          ) : (
            <div className="mt-6">
              <OTPInput
                value={emailOTP}
                onChange={value => setEmailOTP(value)}
                maxLength={4}
                data={email}
                type="email"
                onChangeNumber={handleEmailChange}
                resendOTPHandler={resendEmailOTPHandler}
                verifyOTPHandler={() => {
                  verifyOTPHandler(emailOTP, 'email', email);
                }}
              />
            </div>
          )}

          {isMobileVerified && isEmailVerified && (
            <FormElement
              title={SETUP_ACCOUNT_FORM_FIELDS.PASSWORD.label}
              className="w-[340px] mt-4"
              errorMsg={errors[
                SETUP_ACCOUNT_FORM_FIELDS.PASSWORD.name
              ]?.message?.toString()}
              hintText={
                !errors[
                  SETUP_ACCOUNT_FORM_FIELDS.PASSWORD.name
                ]?.message?.toString() &&
                STRINGS.SETUP_ACCOUNT.PATTERN_ERROR_MESSAGE
              }
            >
              <ControlledPasswordInput
                control={control}
                name={SETUP_ACCOUNT_FORM_FIELDS.PASSWORD.name}
                isRequired={SETUP_ACCOUNT_FORM_FIELDS.PASSWORD.isRequired}
                placeholder={'Password'}
                pattern={passwordRegex}
                patternErrorMessage={
                  STRINGS.SETUP_ACCOUNT.PATTERN_ERROR_MESSAGE
                }
              />
            </FormElement>
          )}
        </div>
      )}
      {showExistingAccountsDrawer && (
        <ExistingAccountsSideDrawer
          isOpen={showExistingAccountsDrawer}
          existingAccounts={existingAccounts}
          onClose={() => setShowExistingAccountsDrawer(false)}
        />
      )}
    </div>
  );
};

export default SetupAccount;
