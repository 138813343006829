import {
  AttributeData as Attribute,
  LeafCategory,
} from '../../data/types/Schema';
import { useEffect } from 'react';
import SENTRY from '../../../../utilities/Sentry';

export function UnSupportedRenderType({
  attr,
  leaf,
}: {
  attr: Attribute;
  leaf: LeafCategory;
}) {
  useEffect(() => {
    SENTRY.logError(
      new Error(
        `Unsupported renderType. renderType = ${attr.renderType}, id = ${attr.attributeId}, leafId = ${leaf.serviceId}`,
      ),
    );
  }, [attr]);
  return null;
}
