import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import * as CommonIcons from 'assets/common';
import {
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import { getDataPropsFromRest, isNullOrUndefined } from 'utilities/Utils';
import { Typography } from 'components/latest-core';
import { ControlledMultiLineInputProps, MultiLineInputProps } from './types';
import { useTextAreaStyles } from './styles';

function MultiLineInputComp(
  props: MultiLineInputProps,
  ref: React.Ref<HTMLElement>,
) {
  const {
    value,
    onChange,
    rowsMin = 4,
    rowsMax = rowsMin,
    name,
    placeholder = '',
    fullWidth = true,
    disabled = false,
    errorMessage = '',
    className = '',
    characterLimit = 30,
    hideHelpText = false,
    autoFocus = false,
    ...rest
  } = props;

  const {
    helperTextError,
    errContainer,
    errorIcon,
    helperTextCharCount,
    smallPadding,
  } = useTextAreaStyles();

  const dataTestIdProps = getDataPropsFromRest(rest);

  const inputChangeHandler = evt => {
    const { name: _name, value: _value } = evt.target;
    return onChange(_value, _name);
  };

  const hasError = errorMessage !== '';
  let helperText = null;
  if (hasError) {
    helperText = (
      <div className={errContainer}>
        <CommonIcons.Error className={errorIcon} />
        <Typography variant="subtitle1" themeColor="error.main">
          {errorMessage}
        </Typography>
      </div>
    );
  } else if (!hideHelpText) {
    helperText = `${value.length} of  ${characterLimit}`;
  }

  return (
    <TextField
      autoFocus={autoFocus}
      value={value}
      onChange={inputChangeHandler}
      inputRef={ref}
      multiline={true}
      minRows={rowsMin}
      maxRows={rowsMax}
      name={name}
      fullWidth={fullWidth}
      disabled={disabled}
      error={hasError}
      classes={{ root: `${smallPadding} ${className}` }}
      placeholder={placeholder}
      inputProps={{ maxlength: characterLimit }}
      variant="outlined"
      helperText={helperText}
      FormHelperTextProps={{
        className: hasError
          ? helperTextError
          : `${helperTextCharCount} ${
            value.length === characterLimit ? 'text-warning-default' : ''
          }`,
      }}
      {...dataTestIdProps}
    />
  );
}

const MultiLineInput = forwardRef(MultiLineInputComp);

const ControlledMultiLineInput = <
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
    props: ControlledMultiLineInputProps<T, TName>,
  ) => {
  const {
    name,
    control,
    defaultValue = '',
    isRequired = false,
    maxLength,
    minLength,
    customValidator,
    ...rest
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };
  if (!isNullOrUndefined(maxLength)) {
    validations.maxLength = {
      value: maxLength,
      message: `The value cannot be longer than ${maxLength} characters`,
    };
  }
  if (!isNullOrUndefined(minLength)) {
    validations.minLength = {
      value: minLength,
      message: `The value cannot be shorter than ${minLength} characters`,
    };
  }
  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const {
    field: { ref, value, onChange },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });

  return (
    <MultiLineInput
      ref={ref}
      value={value}
      onChange={onChange}
      characterLimit={maxLength ?? 30}
      {...rest}
    />
  );
};

export { MultiLineInput, ControlledMultiLineInput };
