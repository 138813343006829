import { saleHanldingLink } from 'modules/sale-handling/utils';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { HotelContext } from 'utilities/Context';

export const SaleHanldingImage = () => {
  const { saleCampaigns, isResellerView } = useContext(HotelContext) || {};

  if (isResellerView || !saleCampaigns) {
    return null;
  }

  const {
    campaignName,
    campaignId,
    images: { campaignShortInlet },
  } = saleCampaigns[0];

  return (
    <div className="p-3">
      <Link to={saleHanldingLink(campaignId)}>
        <img width="100%" src={campaignShortInlet} alt={campaignName} />
      </Link>
    </div>
  );
};
