import { useForm } from 'react-hook-form';
import { useContext, useMemo } from 'react';
import {
  Button,
  ControlledInput,
  FormElement,
  Typography,
} from 'components/latest-core';
import {
  emailRegex,
  pwdWithAtleastOneAlpaNumericAndSplCharRegex,
  registerUserFullNameRegex,
} from 'utilities/allRegex';
import {
  getParsedErrorMessageFromAPI,
  navigateTo,
  showMessage,
} from 'utilities/Utils';
import AppDownloadButtons from './AppDownloadButtons';
import { registerUser } from '../LoginAPIClient';

import { routes } from 'app/routes';
import { AuthContext } from 'utilities/Context';
import { PASSWORD_MIN_CHAR_LENGTH } from '../login.constant';
import { pushToCtaOmnitureCommon } from '../utils';

const Registration = ({ userName }) => {
  const {
    langStringsData: {
      langStrings: {
        Login: { LOGIN: STRINGS },
        Common: { MESSAGES: COMMON },
      },
    },
  } = useContext(AuthContext);

  const defaultValues = useMemo(() => {
    return {
      fullName: '',
      email: '',
      createPassword: '',
      confirmPassword: '',
    };
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues,
  });

  const handleFormSubmit = values => {
    const paylaod = {
      fullName: values.fullName,
      password: values.confirmPassword,
      email: values.email,
    };

    registerUser(paylaod)
      .then(resp => {
        // @ts-ignore
        const data = resp?.response;
        if (data.success) {
          pushToCtaOmnitureCommon({
            ctaName: 'Sign_Up',
            componentName: 'Landing_Page|Sign_Up',
            pageName: 'landing_page',
          });
          navigateTo(routes.login.registerConfirm);
        } else {
          pushToCtaOmnitureCommon({
            ctaName: 'Sign_Up',
            componentName: 'Landing_Page|Sign_Up',
            pageName: 'landing_page',
            errMsg:
              getParsedErrorMessageFromAPI(data) ?? COMMON.SOMETHING_WENT_WRONG,
          });
          showMessage({
            type: 'error',
            show: true,
            commonMessage: COMMON.SOMETHING_WENT_WRONG,
            message: data,
          });
        }
      })
      .catch(err => {
        pushToCtaOmnitureCommon({
          ctaName: 'Sign_Up',
          componentName: 'Landing_Page|Sign_Up',
          pageName: 'landing_page',
          errMsg:
            getParsedErrorMessageFromAPI(err) ?? COMMON.SOMETHING_WENT_WRONG,
        });
        showMessage({
          type: 'error',
          show: true,
          commonMessage: COMMON.SOMETHING_WENT_WRONG,
          message: err,
        });
      });
  };

  const commonPasswordValdiation = (value, fieldName = '') => {
    if (value?.length < PASSWORD_MIN_CHAR_LENGTH)
      return STRINGS.INVALID_PASS_MESS;

    if (
      fieldName !== 'oldPassword' &&
      !pwdWithAtleastOneAlpaNumericAndSplCharRegex.test(value)
    )
      return STRINGS.PASSWORD_VALIDATION;

    return false;
  };

  const validatePassowrd = () => {
    const createPassword = getValues('createPassword');

    const errorStr = commonPasswordValdiation(createPassword);

    if (errorStr) {
      return errorStr;
    }

    return true;
  };

  const validateConfirmPassowrd = () => {
    const [createPassword, confirmPassword] = getValues([
      'createPassword',
      'confirmPassword',
    ]);

    const errorStr = commonPasswordValdiation(confirmPassword);

    if (errorStr) {
      return errorStr;
    }

    if (createPassword !== confirmPassword) return STRINGS.PASSWORD_MISMATCH;

    return true;
  };

  const onItemClick = (url, eventDetails) => {
    pushToCtaOmnitureCommon(eventDetails);
    window.open(url);
  };

  return (
    <div className="text-center">
      <div className="mt-[70px] text-[32px] text-[#2c3e50] font-medium mb-[30px]">
        {STRINGS.SIGN_UP}
      </div>
      <div className="w-[360px] mx-auto">
        <FormElement errorMsg={errors.fullName?.message}>
          <ControlledInput
            className="mt-4"
            control={control}
            name="fullName"
            isRequired
            placeholder="Your Full Name"
            pattern={registerUserFullNameRegex}
          />{' '}
        </FormElement>

        <FormElement errorMsg={errors.email?.message}>
          <ControlledInput
            className="mt-4 [&>*:first-child]:py-0"
            control={control}
            name="email"
            isRequired
            placeholder="Your Email Id"
            pattern={emailRegex}
          />{' '}
        </FormElement>
        <Typography className="text-[15px] text-[#2c3e50] text-start">
          {STRINGS.EMAIL_TYPE}
        </Typography>
        <FormElement errorMsg={errors.createPassword?.message}>
          <ControlledInput
            className="mt-4"
            control={control}
            name="createPassword"
            isRequired
            placeholder="Create Password"
            type="password"
            customValidator={validatePassowrd}
          />{' '}
        </FormElement>
        <FormElement errorMsg={errors.confirmPassword?.message}>
          <ControlledInput
            className="mt-4"
            control={control}
            name="confirmPassword"
            isRequired
            placeholder="Confirm Password"
            type="password"
            customValidator={validateConfirmPassowrd}
          />{' '}
        </FormElement>
        <Button
          fullWidth
          onClick={handleSubmit(handleFormSubmit)}
          className="!bg-[#f39c12] hover:bg-[#c87f0a] !mt-4 !leading-[26px]"
        >
          {STRINGS.REGISTER_NOW}
        </Button>
      </div>
      <div className="flex justify-around w-[750px] mx-auto mt-[15px]">
        {STRINGS.CONTACT_US.map(item => (
          <Button
            key={item.TITLE}
            className="!text-[#18bc9c] !text-[15px] !px-[15px] !py-[10px] hover:border-none !border-none hover:!text-[#18bc9c] hover:!bg-[#ecf0f1] !font-medium"
            variant="outlined"
            onClick={() => onItemClick(item.URL, item.eventDetails)}
          >
            {item.TITLE}
          </Button>
        ))}
      </div>
      <AppDownloadButtons userName={userName} compName="registration" />
      <div className="text-[11px] text-[#7d7d7d] !my-2">
        <span>
          {STRINGS.ORG_GROUP} {new Date().getFullYear()}
        </span>

        <a
          className="text-[#18bc9c] text-size-[11px] hover:text-[#18bc9c] hover:underline  pl-1 cursor-pointer"
          href="https://in.goibibo.com/static/html/privacy_policy.html"
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => {
            pushToCtaOmnitureCommon({
              ctaName: 'privacy_tnc_click',
              componentName: 'Landing_Page|privacy_tnc_click',
              pageName: 'landing_page',
            });
          }}
        >
          {STRINGS.TERMS_CONDITIONS}
        </a>
      </div>
    </div>
  );
};

export default Registration;
