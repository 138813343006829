import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '60px',
    height: '32px',
    padding: '4px 8px 4px 8px',
    gap: '4px',
    userSelect: 'none',
    background: theme.palette.backgrounds.white,
    border: `1px solid ${theme.palette.backgrounds.divider}`,
    fontStyle: 'normal',
    color: theme.palette.common.black,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    '& [class*="MuiChip-deleteIcon"]': {
      color: 'inherit',
      width: '16px',
      height: '16px',
      cursor: 'pointer',
      margin: '0px',
    },
    '&.Mui-disabled': {
      // background: theme.palette.backgrounds.light,
      // border: `1px solid ${theme.palette.backgrounds.divider}`,
      '& [class*="MuiChip-deleteIcon"]': {
        color: 'inherit',
        width: '16px',
        height: '16px',
        cursor: 'pointer',
        margin: '0px',
      },
    },
  },
  label: {
    padding: '0px',
  },
  sizeSmall: {
    height: '24px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.02em',
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.lighter,
    '& [class*="MuiChip-deleteIcon"]': {
      color: theme.palette.primary.main,
      width: '16px',
      height: '16px',
      cursor: 'pointer',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04) !important',
      '& [class*="MuiChip-deleteIcon"]': {
        color: theme.palette.primary.main,
        width: '16px',
        height: '16px',
        cursor: 'pointer',
      },
    },
  },
}));
