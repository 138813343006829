import { Button, Typography } from 'components/latest-core';
import { useLangStrings } from 'utilities/CustomHooks';
import { ViewType } from './BulkUploadMain';
import * as BookingsIcons from 'assets/modules/bookings';
import FileUploadStatusComp from './FileUploadStatusComp';
import { invoiceUploadViewTypes } from 'modules/bookings-revamp/bookings.constants';

type FileObject = {
  file: File;
  name: string;
  status: string;
  progress: number;
  externalError: string[];
  message: string | string[];
};

type CompProps = {
  setView: React.Dispatch<React.SetStateAction<ViewType>>;
  fileObjectData: FileObject[];
  successData: string[];
  failedData: string[];
  moderationData: string[];
  totalFilesCount: number;
  isAllUploadComplete: boolean;
  setFileObjectData: (data: FileObject[]) => void;
};

export default function InvoicesUploadProgress(props: CompProps) {
  const {
    fileObjectData,
    successData,
    failedData,
    moderationData,
    totalFilesCount,
    isAllUploadComplete,
    setFileObjectData,
    setView,
  } = props;

  const [STRINGS, COMMON_STRINGS] =
    useLangStrings<'BookingsRevamp'>('BookingsRevamp');

  const showProgressDetails = () => {
    setView(invoiceUploadViewTypes.STATUSES as ViewType);
  };

  const handleClear = () => {
    setFileObjectData(null);
    setView(null);
  };

  if (totalFilesCount === 0) {
    return null;
  }

  const completedCount =
    successData.length + failedData.length + moderationData.length;

  return (
    <>
      <div className="w-[350px] bg-color-white rounded-md shadow-box-1 absolute right-[110px] bottom-4 z-10 p-3 overflow-auto max-h-[200px]">
        <div className="mb-3 flex flex-col space-between">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <div className="flex items-center">
                {completedCount !== totalFilesCount ? (
                  <Typography variant="body2">
                    {STRINGS.INVOICE_DETAILS.UPLOAD_POPUP_MESSAGES.UPLOAD_PROCESSING.replace(
                      '__placeholder__',
                      `${completedCount + 1}`,
                    ).replace('__placeholder__1', `${totalFilesCount}`)}
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    {isAllUploadComplete &&
                    completedCount === 0 &&
                    totalFilesCount > 0
                      ? STRINGS.INVOICE_DETAILS.UPLOAD_POPUP_MESSAGES
                        .BOOKINGS_UNRECOGNIZED
                      : STRINGS.INVOICE_DETAILS.UPLOAD_POPUP_MESSAGES
                        .UPLOAD_COMPLETE}
                  </Typography>
                )}
                <Button
                  variant="text"
                  onClick={showProgressDetails}
                  className="ml-2"
                >
                  {COMMON_STRINGS.BUTTONS.VIEW_BTN_TEXT}
                </Button>
              </div>
              {failedData?.length > 0 && (
                <Typography
                  variant="subtitle1"
                  themeColor="error.main"
                  className="mt-1"
                >
                  {STRINGS.INVOICE_DETAILS.UPLOAD_POPUP_MESSAGES.UPLOAD_FAILED.replace(
                    '__placeholder__',
                    `${failedData?.length}`,
                  )}
                </Typography>
              )}
            </div>
            <div className="flex items-center">
              <img
                src={BookingsIcons.SearchCloseIcon}
                alt=""
                className="ml-2 cursor-pointer"
                onClick={handleClear}
              />
            </div>
          </div>
          <div>
            {Object.keys(fileObjectData).map(fileName => (
              <div className="mt-4">
                <FileUploadStatusComp
                  key={fileObjectData[fileName]}
                  fileObj={fileObjectData[fileName]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
