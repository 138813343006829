export const analyticsDocumentQuery = `
query AnalyticDocument($rawEndpointRequestData: RawEndpointsRequestData) {
	rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) {
	  analyticsDocuments {
		modelType1 {
		  data
		  message
		  status
		}
	  }
	}
  }  
`;

export const analyticsHistogramQuery = `
	query AnalyticsHistogram($rawEndpointRequestData: RawEndpointsRequestData){
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData){
      analyticsHistogram{
        modelType1 {
          data
          message
          status
        }
      }
    }
  }
`;
