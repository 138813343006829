import { Box, BoxProps } from '@mui/material';
import { getColor, type PaletteColorsType } from 'components/mui-theme';

type ThemeBGColor = {
  bgcolor?: PaletteColorsType;
  customBgColor?: never;
};
type CustomBGColor = {
  customBgColor?: string;
  bgcolor?: never;
};
type FlexProps = Omit<BoxProps, 'bgcolor'> & {
  children?: React.ReactNode;
} & (ThemeBGColor | CustomBGColor);

const FlexContainer = (props: FlexProps) => {
  const { children, customBgColor, bgcolor, ...rest } = props;
  const backgroundColor = customBgColor ? customBgColor : getColor(bgcolor);
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={'100%'}
      bgcolor={backgroundColor}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default FlexContainer;
