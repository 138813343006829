import React from 'react';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  alpha,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import {
  classNames,
  getDataPropsFromRest,
  isNullOrUndefined,
} from 'utilities/Utils';

// type defination for ToggleButtons
type ToggleButtonGroupProps = Pick<
React.ComponentProps<typeof MuiToggleButtonGroup>,
'ref' | 'value' | 'onBlur'
> & {
  onChange?: (newValue: unknown, name: string) => void;
  name?: string;
  displayAsYN?: boolean;
  showAsToggle?: boolean;
  lowercase?: boolean;
};

type Props = {
  btnClass?: string;
  containerClass?: string;
  enforceSelected?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  noBorder?: boolean;
  ungrouped?: unknown;
  roundedChipBtnClass?: string;
  disableYes?: boolean;
  disableNo?: boolean;
  size?: 'small' | 'medium' | 'large';
  disableRipple?: boolean;
  isMultiple?: boolean;
  showIndividualButtons?: boolean;
  variant?: 'chip' | 'normal' | 'radio-chip' | 'rounded-chip' | 'nav-toggle';
  noGap?: boolean;
};

type CommonProps = Props & {
  data: {
    value: string | number | boolean;
    content: React.ReactNode;
    tag?: React.ReactNode;
    disabled?: boolean;
  }[];
  ynToggle?: false;
  ungrouped?: boolean;
};

type YNButtonProps = Props & {
  ynToggle: true;
  ungrouped?: boolean;
  data?: never;
};

type ToggleButtonProps = (CommonProps | YNButtonProps) & ToggleButtonGroupProps;

type ControlledToggleButtonProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = (CommonProps | YNButtonProps) & {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: string | number;
  isRequired?: boolean;
  customValidator?: RegisterOptions<T, TName>['validate'];
  setValueAs?: RegisterOptions['setValueAs'];
  valueAsNumber?: RegisterOptions['valueAsNumber'];
  readValueOnChange?: (val: string | number) => void;
};
// end of type defination for ToggleButtons
type ToggleButtonStyleProps = {
  ungrouped?: boolean;
  fullWidth?: boolean;
  noBorder?: boolean;
  disabled?: boolean;
  noGap?: boolean;
};
const useStyles = makeStyles(theme => ({
  root: (props: ToggleButtonStyleProps) => ({
    height: '32px',
    border: '1px solid #D2D1D1',
    // TODO: Need to relook on this later
    // backgroundColor: '#EFEFEF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '4px',
    textAlign: 'center',
    width: props.fullWidth ? '100%' : 'unset',
  }),
  button: (props: ToggleButtonStyleProps) => ({
    width: props.fullWidth ? '100%' : 'unset',
    height: '22px',
    fontWeight: 400,
    '&.MuiToggleButton-sizeLarge': {
      height: '34px',
      fontSize: '14px',
    },
    '&.Mui-selected': {
      boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
      border: props.noBorder
        ? `1px solid ${theme.palette.primary.main} !important`
        : 'none',
      fontWeight: 500,
    },
    color: theme.palette.text.primary,
  }),
  disabled: {
    opacity: '0.5',
  },
  shadow: {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .14)',
  },
  ynToggleContainer: {
    display: 'flex',
    gap: '1px',
    padding: '2px !important',
    height: '30px !important',
    '& button.Mui-selected[value*=yes]': {
      background: theme.palette.primary.main,
      color: theme.palette.backgrounds.white,
      borderRightColor: theme.palette.primary.main,
      borderBlockColor: theme.palette.primary.main,
    },
  },
  ynToggle: {
    width: '50px !important',
    borderRadius: '4px !important',
    // border: 'none'
  },
  unGrouped: {
    border: 'none',
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: '8px',
    },
    '& button[class*="MuiToggleButtonGroup-groupedHorizontal"]:not(:first-child)':
      {
        borderLeftColor: `${theme.palette.divider}`,
        marginLeft: 0,
      },
  },
  btnNo: {
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: '6px',
    color: `${theme.palette.primary.main}`,
  },
  borderNone: {
    border: 'none !important',
  },
  displayAsYN: {
    width: 'unset !important',
  },
  ynToggleBtn: {
    border: '1px solid #DEDEDE !important',
    height: '28px',
    background: theme.palette.common.white,
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.backgrounds.white,
      '&.Mui-selected:hover': {
        background: theme.palette.primary.main,
      },
    },
  },
  chip: {
    borderRadius: '60px !important',
    height: '36px !important',
    padding: '1px !important',
    width: '96px !important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  chipToggle: {
    borderRadius: '60px !important',
    height: '32px !important',
  },
  chipNo: {
    backgroundColor: '#787878 !important',
    color: '#fff !important',
  },
  radioChip: ({ disabled }: ToggleButtonStyleProps) => ({
    padding: '0px !important',
    height: '32px !important',
    display: 'flex',
    gap: '8px',
    width: 'fit-content',
    border: 'none',
    '& > *': {
      textTransform: 'none',
      width: 'auto !important',
      border: `1px solid ${theme.palette.backgrounds.divider} !important`,
      borderRadius: '60px !important',
      backgroundColor: `${
        disabled ? theme.palette.backgrounds.light : theme.palette.common.white
      } !important`,
      color: `${theme.palette.text.primary} !important`,
      fontWeight: '400',
      '&  .circle-yn': {
        height: '14px',
        width: '14px',
        boxSizing: 'border-box',
        border: `1px solid ${disabled ? '#D2D1D1' : '#787878'} !important`,
        marginRight: '7px',
        borderRadius: '100%',
      },
    },
    '& > .Mui-selected': {
      border: `1px solid ${
        disabled
          ? theme.palette.backgrounds.divider
          : theme.palette.primary.main
      } !important`,
      backgroundColor: `${
        disabled
          ? theme.palette.backgrounds.light
          : theme.palette.backgrounds.primary
      } !important`,
      '&  .circle-yn': {
        strokeWidth: '4px',
        border: `4px solid ${
          disabled
            ? theme.palette.backgrounds.divider
            : theme.palette.primary.main
        }  !important`,
      },
    },
    '& > .Mui-disabled': {
      opacity: 0.5,
    },
  }),
  roundedChip: ({ noGap }: { noGap: boolean }) => ({
    padding: '1px !important',
    height: '36px !important',
    display: 'flex',
    gap: noGap ? '0px' : '10px',
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '60px',
    background: `${theme.palette.backgrounds.white} !important`,
    '& > *': {
      textTransform: 'none',
      width: 'auto !important',
      color: `${theme.palette.text.primary} !important`,
      fontWeight: '400',
      border: 'none !important',
      borderRadius: '50px !important',
      padding: '6px 10px',
    },
    '& > .Mui-selected': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.backgrounds.white} !important`,
    },
  }),
  navToggleBtn: {
    position: 'relative',
    padding: '0 39px 0 40px',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: alpha(theme.palette.action.active, 0.05),
    },
    '&.MuiToggleButton-root': {
      fontSize: '12px ',
      height: '34px',
      borderRadius: '4px !important',
    },
    '&.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.leagecyNav,
      '&:hover': {
        backgroundColor: theme.palette.common.leagecyNav,
      },
    },
    '&.Mui-selected::before': {
      content: '""',
      border: '5px solid transparent',
      left: '50%',
      bottom: '-10px',
      position: 'absolute',
      borderTopColor: theme.palette.common.leagecyNav,
    },
  },
  addSpace: {
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    borderRadius: '4px !important',
    marginRight: '8px !important',
    height: '34px !important',
    fontSize: '12px !important',
    marginLeft: '0px !important',
    '&:last-child': {
      marginRight: '0px !important',
    },
  },
  individualButtons: {
    borderColor: `${theme.palette.primary.main} !important`,
    fontWeight: '500 !important',
    '&.MuiToggleButton-root': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.backgrounds.white,
      '&.Mui-selected:hover': {
        background: theme.palette.primary.main,
      },
    },
  },
}));
const YNButton = (props: ToggleButtonProps) => {
  const {
    value,
    enforceSelected = true,
    ungrouped = false,
    disabled,
    disableYes = false,
    disableNo = false,
    onChange,
    onBlur,
    name,
    variant = 'normal',
    ...rest
  } = props;

  const handleChange = (newValue: unknown) => {
    if (!isNullOrUndefined(newValue)) onChange(newValue, name);
  };

  const classes = useStyles({ ungrouped, disabled });
  const dataTestIdProps = getDataPropsFromRest(rest);
  return (
    <MuiToggleButtonGroup
      value={value}
      exclusive
      onChange={(_, newValue) =>
        enforceSelected ? handleChange(newValue) : onChange(newValue, name)
      }
      onBlur={onBlur}
      className={classNames(
        variant !== 'radio-chip' && classes.root,
        ungrouped && classes.unGrouped,
        variant === 'normal' && ungrouped && classes.unGrouped,
        variant === 'chip' && classes.chip,
        variant === 'radio-chip' && classes.radioChip,
        classes.ynToggleContainer,
      )}
      {...dataTestIdProps}
    >
      <MuiToggleButton
        value={'no'}
        className={classNames(
          value === 'no' && classes.btnNo,
          !ungrouped && value === 'yes' && classes.borderNone,
          classes.ynToggle,
          variant === 'chip' && classes.chipToggle,
          variant === 'chip' && value === 'no' && classes.chipNo,
        )}
        disabled={disabled || disableNo}
      >
        {variant === 'radio-chip' && <div className="circle-yn"></div>} No
      </MuiToggleButton>

      <MuiToggleButton
        value={'yes'}
        className={classNames(
          !ungrouped && value === 'no' && classes.borderNone,
          classes.ynToggle,
          variant === 'chip' && classes.chipToggle,
        )}
        disabled={disabled || disableYes}
      >
        {variant === 'radio-chip' && <div className="circle-yn"></div>}Yes
      </MuiToggleButton>
    </MuiToggleButtonGroup>
  );
};

const ToggleButtonComp = (
  props: ToggleButtonProps,
  ref: React.Ref<unknown>,
) => {
  const {
    data,
    onChange,
    value,
    btnClass,
    disabled,
    onBlur,
    enforceSelected = true,
    ynToggle = false,
    ungrouped = false,
    name = '',
    displayAsYN = false,
    fullWidth = false,
    showAsToggle = true,
    variant = 'normal',
    lowercase = false,
    noBorder = false,
    roundedChipBtnClass = '',
    size = 'medium',
    disableRipple = false,
    isMultiple = false,
    showIndividualButtons = false,
    containerClass = '',
    noGap = false,
    ...rest
  } = props;

  const classes = useStyles({ fullWidth, noBorder, disabled, noGap });

  const handleChange = (newValue: unknown) => {
    if (!isNullOrUndefined(newValue)) onChange(newValue, name);
  };
  const dataTestIdProps = getDataPropsFromRest(rest);

  return ynToggle ? (
    <YNButton {...props} />
  ) : (
    <MuiToggleButtonGroup
      ref={ref}
      value={value}
      exclusive={!isMultiple}
      onChange={(_, newValue) =>
        enforceSelected ? handleChange(newValue) : onChange(newValue, name)
      }
      onBlur={onBlur}
      className={classNames(
        containerClass,
        variant === 'rounded-chip'
          ? classes.roundedChip
          : variant === 'radio-chip'
            ? classes.radioChip
            : (!showAsToggle && classes.root,
            ungrouped && classes.unGrouped,
            displayAsYN && classes.ynToggleContainer),
        variant === 'nav-toggle' && classes.shadow,
      )}
      size={size}
      {...dataTestIdProps}
    >
      {data.map((item, index) => (
        <MuiToggleButton
          key={`toggleButton_${index}`}
          value={item.value}
          className={
            variant === 'nav-toggle'
              ? classNames(classes.navToggleBtn)
              : variant !== 'radio-chip' &&
                variant !== 'rounded-chip' &&
                showAsToggle
                ? classNames(
                  disabled && classes.disabled,
                  btnClass,
                  classes.button,
                  displayAsYN && classes.ynToggle,
                  displayAsYN && classes.displayAsYN,
                  item.value !== value && classes.borderNone,
                  showAsToggle && classes.ynToggleBtn,
                  lowercase && 'lowercase',
                  (isMultiple || showIndividualButtons) && classes.addSpace,
                  showIndividualButtons && classes.individualButtons,
                )
                : roundedChipBtnClass
          }
          disabled={disabled || item.disabled}
          disableRipple={disableRipple}
        >
          {variant === 'radio-chip' && <div className="circle-yn"></div>}
          {item.content}
          {item.tag}
        </MuiToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};
const ToggleButton = React.forwardRef(ToggleButtonComp);

const ControlledToggleButton = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
    props: ControlledToggleButtonProps<T, TName>,
  ) => {
  const {
    name,
    control,
    defaultValue,
    isRequired = false,
    customValidator,
    variant = 'normal',
    setValueAs,
    valueAsNumber,
    readValueOnChange,
    ...rest
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
    setValueAs,
    valueAsNumber,
    validate: customValidator ?? undefined,
  };

  const {
    field: { ref, value, onChange, onBlur },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });

  const internalOnChange = val => {
    onChange(val);
    readValueOnChange?.(val);
  };

  return (
    <ToggleButton
      ref={ref}
      value={value}
      onChange={internalOnChange}
      onBlur={onBlur}
      variant={variant}
      {...rest}
    />
  );
};

export { ToggleButton, ControlledToggleButton };
