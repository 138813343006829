import { isNullOrUndefined } from 'utilities/Utils';

export function parseNestedNumberValue(value: unknown): number | null {
  if (typeof value === 'number') {
    return value;
  }
  if (
    typeof value === 'object' &&
    !isNullOrUndefined(value) &&
    'value' in value
  ) {
    return (value as Record<string, number>).value as number;
  }
  return null;
}
