import { Divider } from '@mui/material';
import {
  ControlledInput,
  FormElement,
  Typography,
} from 'components/latest-core';
import { useFormContext, useWatch } from 'react-hook-form';
import { LOS_PACKAGE_LENGTH_LIMIT } from '../packageConstants';
import { ISTRINGS } from 'interfaces/Strings';

const PackageName = (str: { strings: ISTRINGS['Packages'] }) => {
  const {
    strings: { LOS: STRINGS },
  } = str;
  const { control, getValues } = useFormContext();

  const { minStayDuration, customStayDuration } = getValues();
  const minDuration =
    Number(minStayDuration) === 0
      ? Number(customStayDuration.value)
      : Number(minStayDuration);

  const val = useWatch();
  return (
    <div>
      <Typography variant="h3">{STRINGS.PACKAGE_NAME}</Typography>
      <Divider className="-mx-4 mt-1" />
      <div className="mt-3 mr-9">
        <FormElement>
          <ControlledInput
            name="packageName"
            control={control}
            placeholder={STRINGS.PACKAGE_NAME}
            isRequired
            charLimit={LOS_PACKAGE_LENGTH_LIMIT}
            defaultValue={STRINGS.LONG_STAY_BENEFIT}
            suffix={STRINGS.LOS_PACKAGE.replace(
              '__placeholder__',
              minDuration.toString(),
            )}
          />
        </FormElement>
        <Typography variant="subtitle1" className="mt-1">{`${
          val?.packageName?.length || 0
        }/${LOS_PACKAGE_LENGTH_LIMIT} ${STRINGS.CHARACTERS}`}</Typography>
      </div>
    </div>
  );
};

export default PackageName;
