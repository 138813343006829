import { AttributeFieldProps } from './AttributeFieldProps';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import { ControlledInput } from '../../../../components/latest-core';

export function ValueTypeField(props: AttributeFieldProps) {
  const { control } = useFormContext();
  return (
    <ControlledInput
      defaultValue={props.defaultValue as string}
      control={control}
      name={`${props.attr.attributeId}`}
      className={'hidden'}
    />
  );
}
