import React from 'react';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * Future notes:
 * * If a footer is needed with Action buttons, look at AccordionActions
 */

type LegacyAccordionProps = {
  children: React.ReactNode;
  title: React.ReactNode;
  isOpen?: boolean;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
  subTitle?: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightElem?: React.ReactNode;
  noPadding?: boolean;
  renderExpanded?: boolean;
  defaultOpen?: boolean;
  className?: string;
  headerCls?: string;
  contentCls?: string;
  hideToggle?: boolean;
};

const useStyles = makeStyles(theme => ({
  accRootCls: {
    marginBottom: '16px',
    transition: 'none',
    borderRadius: '4px',
    border: '1px solid #e7e7e7',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .2)',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      backgroundColor: '#eeeeee',
    },
    '&.Mui-expanded': {
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, .16)',
    },
    '&:before': {
      display: 'none',
    },
  },
  headerRootCls: {
    backgroundColor: theme.palette.common.white,
    height: '60px',
    '&.Mui-expanded': {
      minHeight: '60px',
      backgroundColor: theme.palette.grey[200],
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '& > .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
  },
  textGroupCls: {
    margin: '0 auto 0 4px',
    '&:first-child': {
      marginLeft: 0,
    },
    '& > h3': {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#0b0b0b',
      margin: '0',
    },
    '& > .subtitle': {
      marginTop: '4px',
      fontSize: '0.75rem',
      color: alpha(theme.palette.common.black, 0.7),
      //As long as it is material icon, it is sized properly
      '& .MuiSvgIcon-root': {
        fontSize: '0.875rem',
      },
    },
  },
  expandIconCls: {
    backgroundColor: alpha(theme.palette.grey[900], 0.1),
    color: theme.palette.primary.main,
    height: '8px',
    width: '8px',
    margin: '0 0 0 2px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[900], 0.1),
    },
  },
  dBlock: {
    display: 'block',
  },
  zeroPadding: {
    padding: 0,
  },
  dNone: {
    display: 'none',
  },
}));

function LegacyAccordion(props: LegacyAccordionProps) {
  const {
    children,
    title,
    isOpen, //If not set, can work independantly
    onChange, //If not set, can work independantly
    subTitle,
    leftIcon,
    rightElem = null,
    noPadding = false,
    //This is required to render only headers when not expanded
    //By default all accordion contents will not be rendered
    renderExpanded = false,
    defaultOpen = false,
    className = '',
    headerCls = '',
    contentCls = '',
    hideToggle = false,
  } = props;
  const {
    accRootCls,
    headerRootCls,
    expandIconCls,
    textGroupCls,
    dBlock,
    zeroPadding,
    dNone,
  } = useStyles();

  const contentClassName = noPadding
    ? `${dBlock} ${contentCls} ${zeroPadding}`
    : `${dBlock} ${contentCls}`;

  const slideTransitionProps = {
    mountOnEnter: !renderExpanded,
    unmountOnExit: !renderExpanded,
    timeout: { enter: 225, exit: 195 },
  };

  return (
    <MuiAccordion
      classes={{
        root: `${accRootCls} ${className} ${headerCls}`,
      }}
      expanded={isOpen}
      defaultExpanded={defaultOpen}
      onChange={onChange}
      TransitionProps={slideTransitionProps}
    >
      <AccordionSummary
        sx={{
          root: `${headerRootCls} ${headerCls}`,
          expandIcon: `${expandIconCls} ${hideToggle && dNone}`,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        {leftIcon}
        <span className={textGroupCls}>
          <h3>{title}</h3>
          <span className="subtitle">{subTitle}</span>
        </span>
        {rightElem}
      </AccordionSummary>
      <AccordionDetails classes={{ root: contentClassName }}>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
}

export default LegacyAccordion;
