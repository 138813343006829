import { getFormattedDate } from 'utilities/DateUtils';
import { Typography, Button } from 'components/latest-core';
import { GI, MMT } from 'modules/dashboard/images';
import { type PriorityBookingRowType } from 'modules/dashboard/Dashboard.types';
import { capitalizeFirst } from 'utilities/Utils';
import WithTooltipTypography from 'components/common/WithTooltipEllipsis';

export const PriorityBookingRow = (props: PriorityBookingRowType) => {
  const { bookingData, onReject, onConfirm, STRINGS, COMMON_STRINGS } = props;
  const {
    bookingId,
    vendorId,
    checkin,
    checkout,
    user: { name: customerName },
    totalOccupancy,
    roomNights,
    mealPlanValue,
    noOfRooms,
    roomName,
  } = bookingData;
  const vendorImage = vendorId === 'MakeMyTrip' ? MMT : GI;
  const checkinFormatted = getFormattedDate(checkin);
  const checkoutFormatted = getFormattedDate(checkout);
  const customerNameFormatted = capitalizeFirst(customerName);
  return (
    <div className="flex items-start justify-between">
      <img src={vendorImage} alt={vendorId} className="w-[70px] mt-8" />
      <div className="basis-1/2 mt-3">
        <WithTooltipTypography
          className="mb-2  w-[200px] lg:w-[400px]"
          toolTipContent={customerNameFormatted}
        >
          {customerNameFormatted}
        </WithTooltipTypography>
        <Typography variant="subHeadingRegular" className="mb-2">
          {checkinFormatted} - {checkoutFormatted}
        </Typography>
        <Typography className="opacity-70 mb-2">
          {noOfRooms} {roomName} - {mealPlanValue} , {totalOccupancy}{' '}
          {COMMON_STRINGS.LABELS.GUEST} • {roomNights}{' '}
          {COMMON_STRINGS.LABELS.NIGHTS}
        </Typography>
        <Typography className="opacity-70 mb-2">
          {STRINGS.NEW_BOOKINGS.BOOKING_ID}: {bookingId}
        </Typography>
      </div>
      <div className="flex gap-3 mt-8 ">
        <Button
          variant="text"
          onClick={() => onReject(bookingData)}
          className="text-error-default hover:!text-error-dark"
        >
          {COMMON_STRINGS.BUTTONS.REJECT}
        </Button>
        <Button onClick={() => onConfirm(bookingData)}>
          {COMMON_STRINGS.BUTTONS.CONFIRM}
        </Button>
      </div>
    </div>
  );
};
