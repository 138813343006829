import { Radio, Checkbox, Chip as MuiChip } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { classNames } from 'utilities/Utils';
import { useStyles } from './Chip.styles';
import { ChipPropsType } from './Chip.types';

function getPrefix(
  prefix: string,
  icon: React.ReactNode,
  selected: boolean,
  disabled: boolean,
) {
  switch (prefix) {
    case 'checkbox':
      return (
        <Checkbox checked={selected} disabled={disabled} className="p-0" />
      );
    case 'radio':
      return <Radio checked={selected} disabled={disabled} className="p-0" />;
    default:
      return icon;
  }
}

export const Chip = (props: ChipPropsType) => {
  const {
    label,
    className = '',
    size = 'medium',
    prefix,
    icon,
    disabled = false,
    selected = false,
    onDelete,
    onClick,
    badge,
    tag,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <MuiChip
      classes={{
        root: classNames(classes.root, className),
        label: classes.label,
        sizeSmall: classes.sizeSmall,
        colorSuccess: classes.active,
      }}
      label={
        <div className={'flex items-center gap-1'}>
          {getPrefix(prefix, icon, selected, disabled)}
          {label}
          {badge}
          {tag}
        </div>
      }
      deleteIcon={<ClearRoundedIcon />}
      onDelete={onDelete}
      size={size}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      color={selected ? 'success' : 'default'}
      {...rest}
    />
  );
};
