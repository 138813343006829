import * as CommonIcons from 'assets/common';

type IconVariantOptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  incomingProps: any;
  optionLabel: string;
};

const IconVariantOption = ({
  optionLabel,
  incomingProps,
}: IconVariantOptionProps) => {
  return (
    <div {...incomingProps}>
      <CommonIcons.NewSearch style={{ marginRight: '10px' }} />
      {optionLabel}
    </div>
  );
};

export default IconVariantOption;
