import React, { useState } from 'react';

import { Modal, LegacyMultiLineInput } from 'components/latest-core';
import { Button } from '../../../components/new-core';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { showMessage, navigateTo } from 'utilities/Utils';
import { useLangStrings } from '../../../utilities/CustomHooks';
import { routes } from '../../../app/routes';

import { legacyPushToGTM } from 'utilities/gtm';

const THRESHOLD = 500;

export default function InstayIssue(props) {
  const { issue, updateInstayIssues, hotelCode, fetchData, id, isStaff } =
    props;
  const [isOpen, setIsOpen] = useState(false);
  const [resolution, setResolution] = useState('');

  const [STRINGS] = useLangStrings('InstayIssues');

  const updateStatus = (complaint_id, status, description = null) => {
    updateInstayIssues(hotelCode, complaint_id, status, description).then(
      () => {
        fetchData();
        if (status === '1') {
          legacyPushToGTM({
            eventCategory: 'web_dashboard',
            eventAction: 'instay_issues',
            label: 'mark_as_resolved_success',
            booking_id: issue.bookingId,
            functionality: issue.overDue ? 'overdue' : 'acknowledged',
            misc_id: issue.complaintId,
          });
          setIsOpen(false);
          showMessage({
            type: 'success',
            show: true,
            message: STRINGS.ISSUE_RESOLVED_SUCCESS,
          });
        } else {
          showMessage({
            type: 'success',
            show: true,
            message: STRINGS.ISSUE_ACKNOWLEDGED_SUCCESS,
          });
        }
      },
    );
  };

  const handleIssueClick = () => {
    navigateTo(`${routes.instay.base}?id=${id}`);
  };

  const updateResolution = ({ value }) => {
    if (value.length > THRESHOLD) {
      return;
    }
    setResolution(value);
  };

  const handleAcknowledge = () => {
    legacyPushToGTM({
      eventCategory: 'web_dashboard',
      eventAction: 'instay_issues',
      label: 'acknowledge',
      booking_id: issue.bookingId,
      functionality: issue.overDue ? 'overdue' : 'unacknowledged',
      misc_id: issue.complaintId,
    });
    updateStatus(issue.complaintId, '10');
  };

  const handleMarkAsResolved = () => {
    legacyPushToGTM({
      eventCategory: 'web_dashboard',
      eventAction: 'instay_issues',
      label: 'mark_as_resolved',
      booking_id: issue.bookingId,
      functionality: issue.overDue ? 'overdue' : 'acknowledged',
      misc_id: issue.complaintId,
    });
    setIsOpen(!isOpen);
  };

  return (
    <div className="sr-panel panel-default">
      <div className="sr-panel-heading instay-header small">
        <span>
          {issue.customerName}
          {issue.isPremium && (
            <span className="premium-user">{STRINGS.PREMIUM}</span>
          )}
        </span>
        <div className="booking-info">
          <span className="checked-in">
            {`${STRINGS.CHECKED_IN} `} {issue.checkedIn}
          </span>
          <span className="report-time">
            {`${STRINGS.REPORTED} `} {issue.reportedAt}
          </span>
        </div>
      </div>

      <div className="sr-panel-body instay-body no-pad">
        <div className="issue">
          {issue.media.length > 0 && (
            <div className="img-area">
              <img
                src={issue.media[0].url}
                className="issue-img"
                alt="issueImage"
              ></img>
              {issue.media.length > 1 && (
                <span className="img-count">{`+${issue.media.length}`}</span>
              )}
            </div>
          )}
          <div className="issue-info" onClick={handleIssueClick}>
            <span className="issue-text">{STRINGS.ISSUE}</span>
            <span className="sub-category">
              {issue.issueSubCat[0]}{' '}
              {issue.issueSubCat.length > 1 && (
                <span className="more">
                  {`+${issue.issueSubCat.length - 1} ${STRINGS.MORE}`}
                </span>
              )}
            </span>
            <span className="category">
              <span className="category-text">{`${STRINGS.CATEGORY}:`}</span>{' '}
              {issue.categoriesText}
            </span>
          </div>
          <div className="issue-action">
            {issue.status === 10 ? (
              <Button
                size="small"
                color="secondary"
                onClick={handleMarkAsResolved}
                disabled={isStaff}
              >
                {STRINGS.MARK_AS_RESOLVED}
              </Button>
            ) : (
              <Button
                size="small"
                onClick={handleAcknowledge}
                disabled={isStaff}
              >
                {STRINGS.ACKNOWLEDGE_ISSUE}
              </Button>
            )}

            <div className="response-status">
              {issue.overDue ? (
                <>
                  <ErrorOutlineIcon
                    fontSize="small"
                    style={{ marginRight: '5px' }}
                    color="error"
                  />{' '}
                  <span className="delayed">{STRINGS.RESOLUTION_OVERDUE}</span>
                </>
              ) : (
                <>
                  <QueryBuilderIcon
                    fontSize="small"
                    style={{ marginRight: '5px' }}
                  />{' '}
                  {`${STRINGS.RESPONSE_EXPECTED_IN}:`}
                  <span className={`timer ${issue.threshold}`}>
                    {`${issue.timeRemaining} `}
                    {STRINGS.MINUTES}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <Modal
          isOpen
          size="custom"
          header={{
            title: STRINGS.ABLE_TO_RESOLVE,
            hideCross: true,
          }}
          footer={{
            primaryBtn: {
              onClick: () => updateStatus(issue.complaintId, '1', resolution),
            },
            secondaryBtn: {
              onClick: () => setIsOpen(false),
            },
          }}
          customClasses={{
            children: 'w-[400px] !pb-4',
          }}
        >
          <div className="issue-modal-body">
            <div className="issue-modal-text">
              <span>{`${STRINGS.CUSTOMER_COMPLAINED_ABOUT}: `}</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                {issue.issueSubCat.map(sub => {
                  return <span>{`- ${sub}`}</span>;
                })}
              </div>
            </div>
            <div className="text-area">
              <LegacyMultiLineInput
                placeholder={STRINGS.RESOLUTION_PLACEHOLDER}
                value={resolution}
                onChange={updateResolution}
              />
              <span>
                {resolution.length}/{THRESHOLD}
              </span>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
