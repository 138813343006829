import axios, { wrappedAxiosCall } from 'base/APIClient';
import { doQuery } from 'base/GraphQLClient';
import { APP_MAIN, PROMOTIONS } from 'base/Url';
import { getFormattedTime, groupDates } from 'utilities/DateUtils';
import { showMessage } from 'utilities/Utils';
import {
  analyticsDocumentQuery,
  analyticsHistogramQuery,
} from './PromotionQuery';
import {
  bundleOptions,
  DefaultCheckInWeekDay,
  defaultEarlyBirdMax,
  defaultEndDateString,
  newPromotionTypes,
  offerTypes,
} from './Promotions.constants';
import { apiDateFormat } from '../PnC.constants';
import {
  getPayloadFrAdvSettings,
  getPayloadFrCF,
  getPayloadFrDates,
  getPayloadFrRestrictions,
  parseOfferList,
} from './creation-sections';
import type { PromotionPayloadType } from './promotion.types';
import { getBlackoutDates } from './Promotions.utils';

export const updateLoggedInConfigValue = (hotelCode, data) => {
  const url = APP_MAIN.V2_HOTEL(hotelCode);
  return wrappedAxiosCall({ method: 'put', url, data });
};

// Todo: After new flow is activated on all promotions in FUTURE. Need to remove it.
export const createPromotions = data => {
  const url = PROMOTIONS.CREATE_MULTIPLE_HOTEL_OFFERS;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { useLoader: true })
      .then(({ data: dataArr }) => {
        dataArr = dataArr?.[0] ?? {};
        if (!dataArr.success) {
          showMessage({
            type: 'error',
            show: true,
            message:
              dataArr || Object.values(dataArr?.message || {})?.join('\n'),
          });
        } else {
          resolve({});
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

// Todo: After new flow is activated on all promotions in FUTURE. Need to remove it.
export const modifyPromotions = data => {
  const url = PROMOTIONS.UPDATE_HOTEL_OFFER;
  return wrappedAxiosCall({ method: 'post', url, data });
};

type CommonParamsType = {
  applyOnLinkedRP: boolean;
  checkinWeekDay: string[];
  offerCondition: string;
  offerCategory;
  offerValueList: {
    isActive: boolean;
    offerBasis: string;
    channel: string;
    offerType: string;
    offerValue: number;
    segment: string;
  }[];
  isActive: boolean;
  earlyBirdMax?: number;
};

//isExisting -> modify || duplicate [isEditFlow]
//isModify : false -> duplicate ; true -> modify only [isEditFlowOnly]
export function getPromotionPayload(
  isModify,
  isFNDDeal,
  hotelCode,
  promotionData,
  allStatesData,
) {
  const { promotionType, offerCode, parentGroupId } = promotionData;
  const isBundled = allStatesData.applyPromotion === bundleOptions[1].value;
  const freeNightOfferList = [
    {
      isActive: true,
      offerBasis: 'discount',
      channel: 'all',
      offerType: offerTypes.percentage,
      offerValue: 100,
      segment: bundleOptions[isBundled ? 1 : 0].value,
    },
  ];

  const commonParams: CommonParamsType = {
    applyOnLinkedRP: true,
    checkinWeekDay: DefaultCheckInWeekDay,
    offerCondition: 'all',
    offerCategory: promotionType,
    offerValueList: freeNightOfferList,
    isActive: true,
  };
  if (promotionType === newPromotionTypes.EBD) {
    commonParams.earlyBirdMax = defaultEarlyBirdMax;
  }
  let request: Partial<PromotionPayloadType> = {
    ...commonParams,
    offerName: allStatesData.promotionName,
    ...getPayloadFrCF(allStatesData, promotionType),
    ...getPayloadFrDates(allStatesData),
    ...getPayloadFrAdvSettings(allStatesData, hotelCode, isFNDDeal),
  };
  if (promotionType === newPromotionTypes.CUSTOM) {
    request = {
      ...request,
      ...getPayloadFrRestrictions(allStatesData),
    };
  }
  if (isModify) {
    request.offerCode = offerCode;
    request.parentGroupId = parentGroupId;
  }
  const requestArr = [];
  const offerType = allStatesData.offerType;
  const offerList = allStatesData.offerList;
  offerList?.forEach(offer => {
    request = {
      ...request,
      ...parseOfferList({ ...offer, offerType }, promotionType, isBundled),
    };
    if (isFNDDeal) {
      request.offerValueList = freeNightOfferList;
    }
    requestArr.push(request);
  });
  return requestArr;
}

export const getEbdNLmdPromotionPayload = (
  hotelCode,
  promotionType,
  formValues,
  isEditFlow,
) => {
  const {
    bookablePeriod,
    discountForNonLogin,
    extraDiscountForLogin,
    promotionName,
    checkinStartDate,
    checkinEndDate,
    bookingStartDate,
    bookingEndDate,
    blackoutDate,
    allRoomAndRp,
    selectedRoomAndRp,
    checkinNoEndDate,
    bookingNoEndDate,
    promotionBasedOn,

    //for modify
    offerCode,
    parentGroupId,
  } = formValues;

  const payload: Partial<PromotionPayloadType> = {
    applyOnLinkedRP: true,
    checkinWeekDay: DefaultCheckInWeekDay,
    offerCondition: 'all',
    offerCategory: promotionType,
    offerValueList: [
      {
        channel: 'all',
        isActive: true,
        offerBasis: 'discount',
        offerType: 'percentage',
        offerValue: discountForNonLogin,
        segment: 'b2c',
      },
      {
        channel: 'all',
        isActive: true,
        offerBasis: 'discount',
        offerType: 'percentage',
        offerValue: discountForNonLogin + extraDiscountForLogin,
        segment: 'loggedin',
      },
    ],
    isActive: true,
    nonRefundable: false,
    pahApplicable: true,
    offerName: promotionName?.trim(),
    checkinDateStart: getFormattedTime(checkinStartDate, apiDateFormat),
    bookingDateStart: getFormattedTime(bookingStartDate, apiDateFormat),
    checkoutDateEnd:
      checkinNoEndDate && checkinEndDate
        ? getFormattedTime(checkinEndDate, apiDateFormat)
        : defaultEndDateString,
    bookingDateEnd:
      bookingNoEndDate && bookingEndDate
        ? getFormattedTime(bookingEndDate, apiDateFormat)
        : `${defaultEndDateString}`,
    earlyBirdMin: promotionType === 'earlybird' ? +bookablePeriod : 0,
    earlyBirdMax: promotionType === 'earlybird' ? 550 : +bookablePeriod,
    checkinBlackoutDates: [],
    relatedToList: [],
  };

  if (promotionType === 'earlybird' && promotionBasedOn === 'checkin') {
    payload.bookingDateStart = getFormattedTime(
      checkinStartDate,
      apiDateFormat,
    );
    payload.bookingDateEnd = `${defaultEndDateString}`;
  }

  if (
    promotionType === 'lastminute' &&
    promotionBasedOn !== 'booking&checkin'
  ) {
    payload.checkinDateStart = getFormattedTime(
      bookingStartDate,
      apiDateFormat,
    );
    payload.checkoutDateEnd = defaultEndDateString;
  }

  if (blackoutDate) {
    const allDates = groupDates(blackoutDate, true);
    payload.checkinBlackoutDates = getBlackoutDates({ allDates });
  }
  if (allRoomAndRp) {
    payload.relatedToList = [
      {
        relatedTo: 'hotel',
        relatedCode: hotelCode,
      },
    ];
  } else {
    Object.keys(selectedRoomAndRp).forEach(room => {
      const tempList = [];
      const currentRoomRpObj = selectedRoomAndRp[room];
      let isRoomChecked = true;
      Object.keys(currentRoomRpObj).forEach(rp => {
        if (currentRoomRpObj[rp]) {
          tempList.push({
            relatedTo: 'ratePlan',
            relatedCode: rp,
          });
        } else {
          isRoomChecked = false;
        }
      });

      if (isRoomChecked) {
        payload.relatedToList = [
          ...payload.relatedToList,
          {
            relatedTo: 'room',
            relatedCode: room,
          },
        ];
      } else {
        payload.relatedToList = [...payload.relatedToList, ...tempList];
      }
    });
  }

  if (isEditFlow) {
    payload.offerCode = offerCode;
    payload.parentGroupId = parentGroupId;
  }

  return payload;
};

export const getAnalyticsPromotion = (
  promotionType,
  locusCityCode,
  hotelCityId,
) => {
  const payload = {
    rawEndpointRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'Promotions',
          include: {
            locusCityCode: locusCityCode,
            'hotel.ncityId': hotelCityId,
            offerType: 'percentage',
            'offerConditionData.offerCategory': promotionType,
          },
          KPI: [
            {
              offerValue: 'avg',
            },
          ],
        },
      },
    },
  };

  return new Promise(resolve => {
    doQuery(analyticsHistogramQuery, payload, { useLoader: false })
      .then(resp => {
        const response =
          resp.data.rawEndpoint.analyticsHistogram.modelType1.data;
        resolve(response);
      })
      .catch(error => {
        console.error('analyticsHistogramQuery', error);
      });
  });
};

export const getSimilarPromotion = (hotelCode, promotionType) => {
  const payload = {
    rawEndpointRequestData: {
      analyticsDocuments: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'Promotions',
          include: {
            'offerConditionData.bookingDateEnd': {
              gte: `${defaultEndDateString} 00:00:00`,
            },
            'offerConditionData.offerCategory': promotionType,
            hotelCode: hotelCode,
          },
          sort: {
            createdOn: 'desc',
          },
          fields: ['offerConditionData.offerName'],
          offset: 0,
          size: 1,
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    doQuery(analyticsDocumentQuery, payload, { useLoader: false })
      .then(resp => {
        const response =
          resp.data.rawEndpoint.analyticsDocuments.modelType1.data;
        if (response.doc_count > 0) {
          resolve(response.docs[0]);
        } else {
          reject(null);
        }
      })
      .catch(error => {
        console.error('analyticsDocumentQuery', error);
      });
  });
};
