import { useController } from 'react-hook-form';
import { Calendar } from './Calendar';
import { useCalendar } from './Calendar.hooks';
import { ControlledCalendarProps } from './Calendar.types';
import { useEffect } from 'react';

export const ControlledCalendar = (props: ControlledCalendarProps) => {
  const {
    type,
    customValidator,
    isRequired = false,
    name,
    control,
    defaultValue,
    variant,
    hookProps = {},
    ...rest
  } = props;
  const validations = {
    validate: inputValue => {
      if (customValidator) {
        return customValidator(inputValue);
      } else if (isRequired) {
        if (
          !(
            (variant === 'single' && inputValue) ||
            (variant === 'multiple' && inputValue.length > 0) ||
            (variant === 'range' && inputValue.from && inputValue.to)
          )
        )
          return 'This field is required';
      }
      return true;
    },
  };

  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const convertToDate = defaultDates => {
    switch (variant) {
      case 'single':
        return defaultDates ? new Date(defaultDates) : null;
      case 'multiple':
        return (defaultDates || []).map(date => new Date(date));
      case 'range':
        return {
          from: defaultDates?.from ? new Date(defaultDates.from) : null,
          to: defaultDates?.to ? new Date(defaultDates.to) : null,
        };
    }
  };

  const {
    field: { onChange, value },
  } = useController({
    name,
    rules: validations,
    control,
    defaultValue: convertToDate(defaultValue),
  });

  const calendarProps = useCalendar({
    variant,
    ...hookProps,
    initialDate: convertToDate(value),
    callbackAfterDateSelection: onChange,
    defaultNull: true,
  });

  useEffect(() => {
    if (value) {
      calendarProps.calendarProps.setSelectedDays(convertToDate(value));
    }
  }, [value]);

  //@ts-ignore
  return <Calendar type={type} {...rest} {...calendarProps} />;
};
