import { Skeleton } from '@mui/material';
import React from 'react';

export function HeaderShimmer() {
  return (
    <div
      id={'global-shimmer'}
      className="bg-color-white p-2 flex w-full items-center justify-between h-[72px]"
    >
      <div className="flex items-center w-full">
        <div>
          {[...new Array(3).keys()].map((_, index) => (
            <Skeleton
              key={index}
              height={5}
              width={36}
              variant="rectangular"
              className="rounded mt-1"
            />
          ))}
        </div>

        <Skeleton
          height={44}
          width={44}
          variant="rectangular"
          className="rounded-3xl ml-4"
        />
        <div className="ml-4">
          <Skeleton
            height={16}
            width={190}
            variant="rectangular"
            className="w-full rounded"
          />
          <Skeleton
            height={16}
            width={246}
            variant="rectangular"
            className="rounded mt-2"
          />
        </div>
        <Skeleton
          height={36}
          variant="rectangular"
          className="w-1/3 rounded ml-2"
        />
      </div>
      <div className="flex w-full items-center justify-end">
        <Skeleton
          height={44}
          width={44}
          variant="rectangular"
          className="rounded"
        />
        <Skeleton
          height={44}
          width={44}
          variant="rectangular"
          className="rounded-3xl ml-4"
        />
      </div>
    </div>
  );
}
