import { routes } from 'app/routes';
import {
  BlackOfferNewOldMap,
  CouponMetadataInterface,
  CUG_CATEGORY_TYPE,
  CugMetadataInterface,
  CugOfferCategoryValueInterface,
  DealDataInterface,
  PROMOTION_CATEGORY_TYPE,
  PromotionMetadataInterface,
  DEAL_STATUS,
  DEAL_TYPE_LABEL,
  OfferValueInterface,
  OFFER_VALUE_SEGMENT_TYPE,
  PromotionOfferCategoryValueInterface,
} from 'interfaces/PncContextInterface';
import { Common, PerformanceBooster, Promotions } from 'interfaces/Strings';
import CONSTANTS, {
  getGenericCUGDrawer,
} from 'modules/performanceBooster/PerformanceBooster.constants';
import { saleHanldingLink } from 'modules/sale-handling/utils';
import queryString from 'query-string';
import {
  dateInit,
  getFormattedDate,
  onlyDateComparison,
} from 'utilities/DateUtils';
import {
  getFormattedString,
  getPluralOrSingularForm,
  navigateTo,
  routeHistoryRef,
  showMessage,
  updateNestedJsonKeyToNewValue,
} from 'utilities/Utils';
import { pushToOmniture } from 'utilities/gtm';
import {
  CTAS,
  DRAWER_MAPPING,
  SEGMENT_MAPPING_CUG,
  couponPayloadKeyMap,
  createIcon,
  ctaDesc,
  cugDisplayName,
  dateAPIFormat,
  dealTypeDesc,
  mmtSelectSegments,
  onlyMmtBlackSegments,
  segmentDesc,
  segmentOrder,
  NO_END_DATE,
} from './PnC.constants';
import {
  AllCugStatus,
  CreationSubTabsType,
  EachCugStatus,
  CtaData,
  OfferValueType,
} from './PnCTypes';
import {
  defaultPromotionName,
  newPromotionTypes,
  offerTypes,
} from './Promotions/Promotions.constants';
import { DRAWER_TYPE } from './performanceBooster/BoosterDrawerController';
import { DefaultFormValue } from './Promotions/promotion.types';
import { getFinalPromotionPayload } from './Promotions/Promotions.utils';
import { createUpdatePromotion } from './Promotions/PromotionsGraphClient';

const { FALLBACK_DATE: fallback } = CONSTANTS;

export const generateFormattedData = (data: DealDataInterface) => {
  const offerValueList = (data?.offerValueDataList || [])?.map(offer => {
    return {
      offer_value: offer?.offerValue,
      offer_type: offer?.offerType,
      isactive: offer?.isActive,
      segment: offer?.segment,
    };
  });
  return {
    ...data,
    createdon: data?.createdOn,
    modifiedon: data?.modifiedOn,
    checkinblackout: data?.checkinblackoutdates || [],
    offer_value_list: offerValueList,
    offercode: data?.offerCode,
    offer_value: offerValueList?.[0]?.offer_value,
  };
};

export const createOfferValuePayload = (
  discountValues: number | number[],
  cugName: string,
) => {
  if (Array.isArray(discountValues) && discountValues.length > 0) {
    let runningSum = 0;
    const allTierActual = discountValues.map(num => (runningSum += num));
    return allTierActual.map((eachTierData, index) => ({
      isActive: 1,
      offer_value: eachTierData,
      segment: SEGMENT_MAPPING_CUG[cugName][index],
    }));
  }
  const valueList = [];
  const dataObj = {
    isActive: 1,
    offer_value: discountValues,
    segment: SEGMENT_MAPPING_CUG[cugName],
  };
  if (
    cugName === CUG_CATEGORY_TYPE.mmtSelect ||
    cugName === CUG_CATEGORY_TYPE.mmtBlack
  ) {
    for (let i = 0; i < 3; i++) {
      const newObj = {
        ...dataObj,
        segment: SEGMENT_MAPPING_CUG[cugName][i],
        offer_value:
          typeof discountValues === 'number' && discountValues + 5 * i,
      };
      valueList.push(newObj);
    }
  } else {
    valueList.push(dataObj);
  }

  return valueList;
};

export const generateCugStatus = (cugData: {
  [key in CUG_CATEGORY_TYPE]?: CugOfferCategoryValueInterface;
}) => {
  const cugStatusMetaData = {};
  Object.keys(cugData)
    .filter(eachCug => !cugData[eachCug]?.isCreated)
    .forEach(eachCug => {
      const defaultOfferList = createOfferValuePayload(
        cugData[eachCug].recommendedValue,
        eachCug,
      );
      const { monthlyBlackoutLimit, annualBlackoutLimit } = cugData[eachCug];
      cugStatusMetaData[eachCug] = {
        isActive: true,
        isCustomised: false,
        bookingdatestart: getFormattedDate(new Date(), dateAPIFormat),
        bookingdateend: getFormattedDate(NO_END_DATE, dateAPIFormat),
        checkinblackout: [],
        checkindatestart: getFormattedDate(new Date(), dateAPIFormat),
        checkoutdateend: getFormattedDate(NO_END_DATE, dateAPIFormat),
        offer_value_list: defaultOfferList,
        segment: DRAWER_MAPPING[eachCug],
        monthly_blackout_limit: monthlyBlackoutLimit ?? 0,
        annual_blackout_limit: annualBlackoutLimit ?? 0,
      };
    });
  return cugStatusMetaData;
};

export function transformBlackoutDates(dates: string) {
  if (!dates) {
    return '';
  }
  const datePairs = dates.split(',');
  const result = [];
  for (const pair of datePairs) {
    const [startDate, endDate] = pair.split(':');
    result.push([startDate, endDate]);
  }
  return result;
}

export function apiFormatBlackoutDates(datePairs: string[][]) {
  const formattedDates = [];
  for (const pair of datePairs) {
    const [startDate, endDate] = pair;
    const formattedDate = `${startDate},${endDate}`;
    formattedDates.push(formattedDate);
  }
  return formattedDates;
}

export const transformPayload = (
  STRINGS: PerformanceBooster,
  data,
  isActive = true,
) => {
  const formattedPayload = {};

  for (const inputKey in couponPayloadKeyMap) {
    const outputKey = couponPayloadKeyMap[inputKey];
    if (data.hasOwnProperty(inputKey)) {
      if (outputKey === STRINGS.CHECKINBLACKOUTDATES && data[inputKey]) {
        formattedPayload[outputKey] = apiFormatBlackoutDates(data[inputKey]);
      } else {
        formattedPayload[outputKey] = { value: data[inputKey] };
      }
    }
  }

  formattedPayload[STRINGS.ISACTIVE] = {
    value: isActive,
  };
  return formattedPayload;
};

const isCugSegmentPresent = (cugStatus: AllCugStatus, type: string) => {
  return (
    cugStatus.hasOwnProperty(CUG_CATEGORY_TYPE[type]) &&
    cugStatus[CUG_CATEGORY_TYPE[type]].isActive &&
    !cugStatus[CUG_CATEGORY_TYPE[type]].isCustomised
  );
};

export const checkIfAllCustomOrDisabled = ({
  cugStatus,
  countOf,
  isBlackout = false,
}: {
  cugStatus: AllCugStatus;
  countOf: 'both' | 'disabled' | 'customised';
  isBlackout?: boolean;
}): boolean => {
  const totalBoosters = Object.keys(cugStatus).length;

  const customisedBoosters = Object.values(cugStatus).filter(
    (item: EachCugStatus) => item.isCustomised,
  ).length;

  const disabledBoosters = Object.values(cugStatus).filter(
    (item: EachCugStatus) => !item.isActive,
  ).length;

  const isBlackPresent = isCugSegmentPresent(
    cugStatus,
    CUG_CATEGORY_TYPE.mmtBlack,
  );
  const isSelectPresent = isCugSegmentPresent(
    cugStatus,
    CUG_CATEGORY_TYPE.mmtSelect,
  );

  if (countOf === 'disabled') {
    return disabledBoosters === totalBoosters;
  } else if (countOf === 'customised') {
    return customisedBoosters === totalBoosters;
  }

  if ((isBlackPresent || isSelectPresent) && !isBlackout) {
    return !(
      totalBoosters -
      (customisedBoosters + disabledBoosters) -
      (Number(isBlackPresent) + Number(isSelectPresent))
    );
  }

  return customisedBoosters + disabledBoosters === totalBoosters;
};

export const mapValue = (
  value: string,
  reverseMap = false,
  obj1 = DRAWER_MAPPING,
  obj2 = SEGMENT_MAPPING_CUG,
) => {
  if (reverseMap) {
    const key = Object.keys(obj1).find(k => obj1[k] === value);
    if (key && obj2.hasOwnProperty(key)) {
      return Array.isArray(obj2[key]) ? obj2[key][0] : obj2[key];
    }
  }
  const key = Object.keys(obj2).find(
    k => obj2[k] === value || obj2[k][0] === value,
  );

  if (key && obj1.hasOwnProperty(key)) {
    return obj1[key];
  }

  return null;
};

export const pushPageViewEventToOmniture = (
  pageTye: string,
  loadedValues?: string,
) => {
  pushToOmniture({
    event: 'pageView',
    pageType: pageTye,
    loadedComponents: loadedValues,
  });
};

const processCouponData = data => {
  const {
    offercode: offerCode,
    offer_value: offerValue,
    checkindatestart: checkIn = null,
    checkoutdateend: checkOut = null,
    bookingdatestart: bookingStart = null,
    bookingdateend: bookingEnd = null,
    checkinblackout: blackOut = [],
  } = data;

  const res = {
    offerValue,
    offerCode,
    checkIn: dateInit(checkIn),
    checkOut: dateInit(checkOut, fallback),
    bookingStart: dateInit(bookingStart),
    bookingEnd: dateInit(bookingEnd, fallback),
    blackOut,
  };

  return res;
};

const processMMTBlackSelectData = data => {
  const {
    offercode: offerCode,
    offer_value_list: offerValueList = [],
    checkinblackout: blackOut = [],
  } = data;
  const res = { offerCode, offerValueList, blackOut };
  return res;
};

const processCorporateData = data => {
  const {
    offercode: offerCode,
    offer_value_list: offerValueList,
    checkindatestart: checkIn = null,
    checkoutdateend: checkOut = null,
    bookingdatestart: bookingStart = null,
    bookingdateend: bookingEnd = null,
    checkinblackout: blackOut = [],
    offercategory: offerCategory = '',
    earlybirdmin: earlyBirdMin = null,
    earlybirdmax: earlyBirdMax = null,
  } = data;
  const offerValue =
    offerValueList?.[0]?.segment === OFFER_VALUE_SEGMENT_TYPE.Affiliate
      ? offerValueList?.[1]?.offer_value || 0
      : offerValueList?.[0]?.offer_value;
  const res = {
    offerValue,
    offerCode,
    checkIn: dateInit(checkIn),
    checkOut: dateInit(checkOut, fallback),
    bookingStart: dateInit(bookingStart),
    bookingEnd: dateInit(bookingEnd, fallback),
    blackOut,
    offerCategory,
    earlyBirdMin,
    earlyBirdMax,
  };
  return res;
};

const processGenericCUG = (STRINGS, data) => {
  const {
    segment,
    offercode: offerCode,
    offer_value_list: offerValueList,
    checkindatestart: checkIn = null,
    checkoutdateend: checkOut = null,
    bookingdatestart: bookingStart = null,
    bookingdateend: bookingEnd = null,
    checkinblackout: blackOut = [],
  } = data;
  const segmentContent = getGenericCUGDrawer(STRINGS, segment);
  if (segmentContent) {
    const offerValue = offerValueList?.[0]?.offer_value;
    const res = {
      segment,
      offerCode,
      offerValue,
      checkIn: dateInit(checkIn),
      checkOut: dateInit(checkOut, fallback),
      bookingStart: dateInit(bookingStart),
      bookingEnd: dateInit(bookingEnd, fallback),
      blackOut,
      drawerText: segmentContent,
    };
    return res;
  }
  showMessage({
    show: true,
    message: STRINGS.ALERT_MESSAGE.COMING_SOON,
    type: 'success',
  });
  return null;
};

export const getProcessedData = (STRINGS, type, data) => {
  if (data === undefined || null) {
    return null;
  }
  switch (type) {
    case DRAWER_TYPE.COUPON:
      return processCouponData(data);
    case DRAWER_TYPE.CORPORATE:
      return processCorporateData(data);
    case DRAWER_TYPE.MMT_BLACK:
      return processMMTBlackSelectData(data);
    case DRAWER_TYPE.MMT_SELECT:
      return processMMTBlackSelectData(data);
    default:
      return processGenericCUG(STRINGS, data);
  }
};

const calculateDiscount = (
  offerValueSegment: OFFER_VALUE_SEGMENT_TYPE,
  offerValue: number,
  discountList: OfferValueInterface[],
  STRINGS: Promotions,
) => {
  // Find the offer in discountList that matches the offerValueSegmentType
  const matchingOffer = discountList?.find(
    offer => offer?.segment === offerValueSegment,
  );
  const baseDiscountString = getFormattedString(
    STRINGS.DEALS.ALL_DISCOUNT_PERCENT,
    {
      discount: offerValue - (matchingOffer?.offerValue || 0),
    },
  );
  if (matchingOffer) {
    return getFormattedString(
      `${STRINGS.DEALS.ALL_DISCOUNT_PERCENT} + ${baseDiscountString}`,
      {
        discount: matchingOffer?.offerValue || 0,
      },
    );
  }
  return baseDiscountString;
};

const getLoggedInOfferDesc = (
  offerValue: number,
  discountList: OfferValueInterface[],
) => {
  return `${
    offerValue -
    (discountList?.find(
      offer => offer?.segment === OFFER_VALUE_SEGMENT_TYPE.b2c,
    )?.offerValue || 0)
  }`;
};

const getOfferDescription = (
  offer: OfferValueInterface,
  STRINGS: Promotions,
  discountList: OfferValueInterface[],
): string => {
  const { offerType, offerValue, segment } = offer;
  if (offerValue >= 1) {
    if (offerType === offerTypes.percentage) {
      return getFormattedString(
        {
          [OFFER_VALUE_SEGMENT_TYPE.loggedin]:
            STRINGS.DEALS.LOGGEDIN_DISCOUNT_PERCENT,
          [OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT1]: calculateDiscount(
            OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT,
            offerValue,
            discountList,
            STRINGS,
          ),
          [OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT2]: calculateDiscount(
            OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT1,
            offerValue,
            discountList,
            STRINGS,
          ),
        }[segment] || STRINGS.DEALS.ALL_DISCOUNT_PERCENT,
        {
          discount:
            segment === OFFER_VALUE_SEGMENT_TYPE.loggedin
              ? getLoggedInOfferDesc(offerValue, discountList)
              : offerValue,
        },
      );
    }
    return getFormattedString(
      segment === OFFER_VALUE_SEGMENT_TYPE.loggedin
        ? STRINGS.DEALS.LOGGEDIN_DISCOUNT_FLAT
        : STRINGS.DEALS.ALL_DISCOUNT_FLAT,
      {
        discount:
          segment === OFFER_VALUE_SEGMENT_TYPE.loggedin
            ? getLoggedInOfferDesc(offerValue, discountList)
            : offerValue,
      },
    );
  }

  return '';
};

const generateOfferValueList = (
  discountList: OfferValueInterface[],
  propmotionType: PROMOTION_CATEGORY_TYPE,
  STRINGS: Promotions,
  minNights?: number,
  night?: string,
): OfferValueType[] => {
  const offerValueList: OfferValueType[] = [];
  discountList
    ?.filter(val => val?.isActive && val?.offerValue)
    .forEach(offer => {
      if (propmotionType === PROMOTION_CATEGORY_TYPE.fnd || night) {
        const stayNights = getPluralOrSingularForm('night', minNights);
        const payNights = getPluralOrSingularForm(
          'night',
          minNights - parseInt(night),
        );
        offerValueList.push({
          offerDesc: getFormattedString(STRINGS.FN_DESC, {
            x: stayNights,
            y: payNights,
          }),
          offerValue: payNights,
        });
      } else {
        offerValueList.push({
          offerDesc: getOfferDescription(offer, STRINGS, discountList),
          offerValue: offer.offerValue || 0,
          segment: offer.segment,
        });
      }
    });
  return offerValueList;
};

export const sortOfferValueList = (list = []) => {
  return list.sort(
    (a, b) =>
      (segmentOrder[a.segment] ?? 100) - (segmentOrder[b.segment] ?? 100),
  );
};

const getDiscountValue = (
  orderedList: OfferValueType[],
  isPercent: boolean,
  propmotionType: string,
  STRINGS: Promotions,
  COMMON: Common,
  isMmtBlack: boolean,
  isMmtSelect: boolean,
  isBundled: boolean,
  baseCurrency: string,
) => {
  if (orderedList.length === 0) return '';

  const baseValue = orderedList[0]?.offerDesc || '';
  let discountValue = baseValue;

  if (
    orderedList[0]?.segment === OFFER_VALUE_SEGMENT_TYPE.loggedin &&
    !(propmotionType === PROMOTION_CATEGORY_TYPE.fnd)
  ) {
    discountValue = `${getFormattedString(
      isPercent
        ? STRINGS.DEALS.ALL_DISCOUNT_PERCENT
        : STRINGS.DEALS.ALL_DISCOUNT_FLAT,
      {
        currency: baseCurrency === COMMON.CURRENCY.INR ? '₹' : baseCurrency,
        discount: 0,
      },
    )} ${discountValue}`;
  }

  if (!isPercent && !(propmotionType === PROMOTION_CATEGORY_TYPE.fnd)) {
    discountValue = getFormattedString(
      `${STRINGS.DEALS.FLAT} ${discountValue}`,
      {
        currency: baseCurrency === COMMON.CURRENCY.INR ? '₹' : baseCurrency,
      },
    );
  }
  if (isMmtBlack || isMmtSelect) {
    const asterics = orderedList.length !== 0 ? '*' : '';
    return discountValue + asterics;
  }

  if (isBundled) {
    return `${discountValue} ${STRINGS.DEALS.BUNDLED}`;
  }

  if (orderedList.length === 2) {
    return getFormattedString(
      `${discountValue} ${orderedList[1]?.offerDesc || ''}`,
      {
        currency: baseCurrency === COMMON.CURRENCY.INR ? '₹' : baseCurrency,
      },
    );
  }

  return discountValue;
};

const buildDiscountDescriptionForMMT = (
  orderedList: OfferValueType[],
  STRINGS: Promotions,
) => {
  let desc = STRINGS.DEALS.DISCOUNT_OFFERED;

  orderedList.forEach((offer, index) => {
    const segmentDescription = Object.keys(segmentDesc)?.includes(
      offer?.segment,
    )
      ? segmentDesc[offer?.segment]
      : offer?.segment;

    const offerDescription = orderedList[index]?.offerDesc;
    const asterics = index === 0 ? '*' : '';
    const comma = index < orderedList.length - 1 ? ',' : '';
    desc += ` ${segmentDescription}: ${offerDescription}${asterics}${comma} `;
  });

  return desc === STRINGS.DEALS.DISCOUNT_OFFERED ? '' : desc;
};

const getDiscountDescription = (
  discountValue: string,
  orderedList: OfferValueType[],
  isMmtBlack: boolean,
  isMmtSelect: boolean,
  STRINGS: Promotions,
) => {
  if (!discountValue) return '';

  if (isMmtBlack || isMmtSelect) {
    return buildDiscountDescriptionForMMT(orderedList, STRINGS);
  }

  return '';
};

export const getDiscount = (
  STRINGS: Promotions,
  COMMON: Common,
  discountList: OfferValueInterface[],
  propmotionType: PROMOTION_CATEGORY_TYPE,
  baseCurrency: string,
  minNights?: number,
  night?: string,
) => {
  const isBundled = !!discountList?.find(
    offer => offer?.segment === OFFER_VALUE_SEGMENT_TYPE.bundled,
  );
  const isMmtBlack = !!discountList?.find(offer =>
    onlyMmtBlackSegments.includes(offer?.segment),
  );
  const isMmtSelect = !!discountList?.find(offer =>
    mmtSelectSegments.includes(offer?.segment),
  );
  const isPercent = !!discountList?.find(
    offer => offer?.offerType === offerTypes.percentage,
  );

  const offerValueList = generateOfferValueList(
    discountList,
    propmotionType,
    STRINGS,
    minNights,
    night,
  );

  const orderedDiscountList = sortOfferValueList(offerValueList);
  const discountValue = getDiscountValue(
    orderedDiscountList,
    isPercent,
    propmotionType,
    STRINGS,
    COMMON,
    isMmtBlack,
    isMmtSelect,
    isBundled,
    baseCurrency,
  );
  const discountDesc = getDiscountDescription(
    discountValue,
    orderedDiscountList,
    isMmtBlack,
    isMmtSelect,
    STRINGS,
  );
  return { discountValue, discountDesc };
};

export const getDisplayDate = (
  STRINGS: Promotions,
  startDate: string,
  endDate: string,
  isNoEndDate: boolean,
) => {
  return `${startDate} ${
    isNoEndDate
      ? STRINGS.PROMOTION_REVAMP.MSGS.ONWARDS
      : `${STRINGS.PROMOTION_REVAMP.MSGS.TO} ${endDate}`
  }`;
};

export function sortOfferCategoriesByPriority(
  metadata: PromotionMetadataInterface & CugMetadataInterface,
  defaultCount: number,
  defaultRecommendedValue: boolean,
  isDomHotel = false,
) {
  const categoryArray = Object.entries(metadata?.offerCategories || {});

  const sortedCategoryArr = categoryArray
    .sort(
      (a: object, b: object) => (a[1]?.priority || 0) - (b[1]?.priority || 0),
    )
    .filter(offer => !isDomHotel || offer[0] !== 'geography');

  let totalActiveCount = 0;
  let totalExpiredCount = 0;
  let totalInactiveCount = 0;
  let totalCreatedCount = 0;
  const sortedOfferCategories = {};

  sortedCategoryArr.forEach(
    ([key, value]: [
      string,
      CugOfferCategoryValueInterface & PromotionOfferCategoryValueInterface,
    ]) => {
      value.key = key;
      value.icon = Object.keys(createIcon).includes(key)
        ? createIcon[key]
        : createIcon.promotion;
      value.activeCount =
        value?.activeCount && value.activeCount > 0
          ? value.activeCount
          : defaultCount;
      value.expiredCount =
        value?.expiredCount && value.expiredCount > 0
          ? value.expiredCount
          : defaultCount;
      value.inactiveCount =
        value?.inactiveCount && value.inactiveCount > 0
          ? value.inactiveCount
          : defaultCount;
      value.isRecommended = value?.isRecommended || defaultRecommendedValue;
      totalActiveCount += value.activeCount;
      totalExpiredCount += value.expiredCount;
      totalInactiveCount += value.inactiveCount;
      totalCreatedCount += value?.isCreated ? 1 : 0;
      sortedOfferCategories[key] = value;
    },
  );
  metadata.activeCount = totalActiveCount;
  metadata.expiredCount = totalExpiredCount;
  metadata.inactiveCount = totalInactiveCount;
  metadata.createdCount = totalCreatedCount;
  metadata.offerCategories = sortedOfferCategories;
  return metadata;
}

export const getIsNewUser = (
  promotionMetadata: PromotionMetadataInterface,
  cugMetadata: CugMetadataInterface,
  couponMetadata: CouponMetadataInterface,
) => {
  const count =
    promotionMetadata.activeCount +
    promotionMetadata.expiredCount +
    promotionMetadata.inactiveCount +
    cugMetadata.activeCount +
    cugMetadata.expiredCount +
    cugMetadata.inactiveCount +
    cugMetadata.createdCount +
    couponMetadata.activeCount +
    couponMetadata.expiredCount +
    couponMetadata.inactiveCount;
  return !count;
};

export const getCtaList = (
  COMMON: Common,
  status: DEAL_STATUS,
  expiringIn: number,
  type: DEAL_TYPE_LABEL,
  isCampaign: boolean = false,
  isMmtBlack = false,
): CtaData[] => {
  if (isCampaign) {
    const updateButton: CtaData = {
      key: CTAS.MODIFY,
      label: ctaDesc[`${CTAS.MODIFY}`],
      btnLabel: COMMON.BUTTONS.MODIFY, //only modify for campaign
      type,
      onClick: () => {},
      disable: false,
    };

    const withdrawBtn: CtaData = {
      key: CTAS.DEACTIVATE,
      label: ctaDesc[`${CTAS.WITHDRAW}`], //different label for de-activation
      type,
      onClick: () => {},
      disable: false,
      btnLabel: COMMON.BUTTONS.WITHDRAW,
    };
    return [updateButton, withdrawBtn];
  }

  const ctaKey = expiringIn && expiringIn < 7 ? CTAS.EXTEND : CTAS.MODIFY;

  const ctaBtnLabel =
    expiringIn && expiringIn < 7
      ? COMMON.BUTTONS.EXTEND
      : COMMON.BUTTONS.MODIFY;
  const updateButton: CtaData = {
    key: ctaKey,
    label: ctaDesc[`${ctaKey}`],
    btnLabel: ctaBtnLabel,
    type,
    onClick: () => {},
    disable: false,
  };

  if (status === DEAL_STATUS?.ACTIVE) {
    if (isMmtBlack) {
      return [
        updateButton,
        {
          key: CTAS.BLACK_WITHDRAW,
          label: ctaDesc[`${CTAS.BLACK_WITHDRAW}`],
          type,
          onClick: () => {},
          disable: false,
          btnLabel: COMMON.BUTTONS.WITHDRAW,
        },
      ];
    }
    const commonCtas = [
      updateButton,
      {
        key: CTAS.DEACTIVATE,
        label: ctaDesc[`${CTAS.DEACTIVATE}`],
        type,
        onClick: () => {},
        disable: false,
      },
    ];

    if (type === DEAL_TYPE_LABEL.promotion || type === DEAL_TYPE_LABEL.coupon) {
      return [
        ...commonCtas,
        {
          key: CTAS.DUPLICATE,
          label: ctaDesc[`${CTAS.DUPLICATE}`],
          type,
          onClick: () => {},
          disable: false,
        },
      ];
    }
    return commonCtas;
  }
  if (type === DEAL_TYPE_LABEL.promotion) {
    return [
      {
        key: CTAS.REACTIVATE,
        label: ctaDesc[`${CTAS.REACTIVATE}`],
        btnLabel: ctaDesc[`${CTAS.REACTIVATE}`],
        type,
        onClick: () => {},
        disable: false,
      },
    ];
  }
  if (status === DEAL_STATUS.EXPIRED) {
    return [
      {
        key: CTAS.OFFER_AGAIN,
        label: ctaDesc[`${CTAS.REACTIVATE}`],
        btnLabel: ctaDesc[`${CTAS.REACTIVATE}`],
        type,
        onClick: () => {},
        disable: false,
      },
    ];
  }
  return [
    {
      key: CTAS.REACTIVATE,
      label: ctaDesc[`${CTAS.REACTIVATE}`],
      btnLabel: ctaDesc[`${CTAS.REACTIVATE}`],
      type,
      onClick: () => {},
      disable: false,
    },
  ];
};

export const getDealName = (
  STRINGS: Promotions,
  BOOSTERSTRINGS: PerformanceBooster,
  dealName: string,
  dealType: string,
  segment: string,
  discountValue: string,
  isResellerView: boolean,
  isCampaign: boolean = false,
) => {
  //if campaign return dealName
  if (isCampaign) {
    return dealName ?? STRINGS.DEALS.SALE_CAMPAIGN;
  }

  if (isResellerView && dealType === DEAL_TYPE_LABEL.cug) {
    if (segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK) {
      return STRINGS.DEALS.HCBLACK;
    } else if (segment === OFFER_VALUE_SEGMENT_TYPE.corporate) {
      return STRINGS.DEALS.HCBIZ;
    }
  }

  if (dealName) {
    return dealName;
  }

  if (dealType === DEAL_TYPE_LABEL.cug) {
    return cugDisplayName[segment] || dealTypeDesc[`${dealType}`];
  }

  if (dealType === DEAL_TYPE_LABEL.coupon) {
    return `${BOOSTERSTRINGS.COUPON}${
      discountValue ? `-${discountValue}` : ''
    }`;
  }

  return STRINGS.DEALS.DEAL;
};

const modifyPromotion = (
  id: string,
  parentId: string,
  isDuplicate = false,
  type = null,
) => {
  const editType = isDuplicate ? CTAS.DUPLICATE : '';

  const params = {
    id,
    parentId,
    editType,
    type: [newPromotionTypes.FND, newPromotionTypes.LOS].includes(type)
      ? type
      : null,
  };

  return routeHistoryRef.curr.push({
    pathname: routes.businessBooster.pnc.modify,
    search: `?${queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    })}`,
  });
};

const handleDeactivateClick = (
  mmtId: string | number,
  offerCode: string,
  parentGroupId: string,
  removeOfferAndUpdateDeals: () => void,
) => {
  const payload = {
    isActive: false,
    offerCode: offerCode,
    parentGroupId: parentGroupId,
  };
  const finalPayload = getFinalPromotionPayload({
    payloadArr: [payload],
  });
  return createUpdatePromotion({
    mmtId,
    payLoad: finalPayload[0],
    showGlobalLoader: true,
  })
    .then(() => {
      showMessage({
        show: true,
        message: 'Offer Deactivated Successfully',
        type: 'success',
      });
      removeOfferAndUpdateDeals();
    })
    .catch(() => {
      showMessage({
        show: true,
        message: 'Failed to Deactivate Offer',
        type: 'error',
      });
    });
};

export const getAllCtaContent = (
  STRINGS: Promotions,
  COMMON: Common,
  BOOSTERSTRINGS: PerformanceBooster,
  dealData: DealDataInterface,
  showCUGDrawer: (data, isEditFlow: boolean, isofferAgain?: boolean) => void,
  showCouponDrawer: (data, isOfferAgain?: boolean) => void,
  isDomHotel: boolean,
  isResellerView: boolean,
  status: DEAL_STATUS,
  expiringIn: number,
  type: DEAL_TYPE_LABEL,
  dealName: string,
  segment: OFFER_VALUE_SEGMENT_TYPE,
  discountValue: string,
  setUpdateStatus: (data: unknown) => void,
) => {
  const { campaignId = null } = dealData?.campaignDetails ?? {};
  const isCampaign = !!campaignId;
  const isMmtBlack = segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK;
  const ctaList = getCtaList(
    COMMON,
    status,
    expiringIn,
    type,
    isCampaign,
    isMmtBlack,
  );

  ctaList.forEach((cta: CtaData) => {
    let onClickHandler: () => void;

    const campaignIsExpired = Boolean(
      (status === DEAL_STATUS.IN_ACTIVE || status === DEAL_STATUS.EXPIRED) &&
        isCampaign,
    );

    const campaignBookingEnded = Boolean(
      onlyDateComparison(new Date(), new Date(dealData?.bookingdateend)) > 0,
    );

    cta.disable =
      isResellerView ||
      (isDomHotel && dealData.segment === OFFER_VALUE_SEGMENT_TYPE.GEOGRAPHY) ||
      !dealData.showModification ||
      campaignIsExpired ||
      campaignBookingEnded;

    cta.dealName = getDealName(
      STRINGS,
      BOOSTERSTRINGS,
      dealName,
      type,
      segment,
      discountValue,
      isResellerView,
      isCampaign,
    );
    switch (cta.type) {
      case DEAL_TYPE_LABEL.cug:
        switch (cta.key) {
          case CTAS.MODIFY:
          case CTAS.EXTEND:
            if (campaignId) {
              onClickHandler = () =>
                navigateTo(saleHanldingLink(campaignId, dealData.offerCode));
              break;
            }
            if (segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK) {
              onClickHandler = () =>
                navigateTo(routes.businessBooster.blackGoTribe.enroll);
              break;
            }
            onClickHandler = () => {
              showCUGDrawer(dealData, true);
            };
            break;
          case CTAS.DEACTIVATE:
            onClickHandler = () => {
              setUpdateStatus({
                isActive: false,
                data: dealData,
                isCoupon: false,
                isCampaign,
              });
            };
            break;
          case CTAS.OFFER_AGAIN:
            if (!campaignId && segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK) {
              onClickHandler = () =>
                navigateTo(routes.businessBooster.blackGoTribe.enroll);
              break;
            }
            onClickHandler = () => {
              showCUGDrawer(dealData, true, true);
            };
            break;
          case CTAS.REACTIVATE:
            if (!campaignId && segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK) {
              onClickHandler = () =>
                navigateTo(routes.businessBooster.blackGoTribe.enroll);
              break;
            }
            onClickHandler = () => {
              setUpdateStatus({
                isActive: true,
                data: dealData,
                isCoupon: false,
              });
            };
            break;
          case CTAS.BLACK_WITHDRAW:
            onClickHandler = () => {
              const offers = [
                {
                  offerCode: dealData.offerCode,
                  isActive: false,
                  offerValues: dealData.offerValueDataList
                    .filter(item =>
                      [
                        OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1,
                        OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2,
                      ].includes(item.segment),
                    )
                    .map(offerValueData => {
                      return {
                        isActive: false,
                        segment: BlackOfferNewOldMap[offerValueData.segment],
                        discountValue: offerValueData.offerValue,
                        chargeType: offerValueData.offerType,
                      };
                    }),
                },
              ];

              const payload = {
                offers,
                services: updateNestedJsonKeyToNewValue(
                  dealData.blackServicesOffered,
                  'isActive',
                  false,
                ),
              };
              setUpdateStatus({
                data: dealData,
                isActive: false,
                isCoupon: false,
                isCampaign: false,
                isMmtBlack: true,
                mmtBlackWithdrawPayload: payload,
              });
            };
            break;
          default:
            onClickHandler = () => {};
            break;
        }
        break;

      case DEAL_TYPE_LABEL.coupon:
        switch (cta.key) {
          case CTAS.MODIFY:
          case CTAS.EXTEND:
            if (campaignId) {
              onClickHandler = () =>
                navigateTo(saleHanldingLink(campaignId, dealData.offerCode));
              break;
            }
            onClickHandler = () => {
              showCouponDrawer(dealData);
            };
            break;
          case CTAS.DEACTIVATE:
            onClickHandler = () => {
              setUpdateStatus({
                isActive: false,
                data: dealData,
                isCoupon: true,
                isCampaign,
              });
            };
            break;
          case CTAS.DUPLICATE:
            onClickHandler = () => {
              showCouponDrawer({});
            };
            break;
          case CTAS.OFFER_AGAIN:
            onClickHandler = () => {
              showCouponDrawer(dealData, true);
            };
            break;
          case CTAS.REACTIVATE:
            onClickHandler = () => {
              setUpdateStatus({
                isActive: true,
                data: dealData,
                isCoupon: true,
              });
            };
            break;
          default:
            onClickHandler = () => {};
            break;
        }
        break;

      default:
        switch (cta.key) {
          case CTAS.MODIFY:
          case CTAS.EXTEND:
            onClickHandler = () => {
              modifyPromotion(
                dealData.offerCode,
                dealData.parentGroupId || '',
                false,
                dealData?.offerCategory,
              );
            };
            break;
          case CTAS.DEACTIVATE:
            onClickHandler = () => {
              setUpdateStatus({
                data: dealData,
                isPromotion: true,
                deactivateHandler: handleDeactivateClick,
              });
            };
            break;
          case CTAS.DUPLICATE:
            onClickHandler = () => {
              modifyPromotion(
                dealData.offerCode,
                dealData.parentGroupId || '',
                true,
                dealData?.offerCategory,
              );
            };
            break;
          case CTAS.REACTIVATE:
            onClickHandler = () => {
              modifyPromotion(
                dealData.offerCode,
                dealData.parentGroupId || '',
                true,
                dealData?.offerCategory,
              );
            };
            break;
          default:
            onClickHandler = () => {};
            break;
        }
        break;
    }

    cta.onClick = (isMenuItemClick = true) => {
      if (isMenuItemClick) {
        pushToOmniture({
          event: 'ctaClick',
          cta: {
            name: cta.label,
            componentName: 'active_promotion',
            type: 'click',
          },
          loadedComponents: isCampaign ? cta?.dealName : '',
        });
      }
      onClickHandler();
    };
  });
  return ctaList;
};

export const formatDataForOmniture = (data: AllCugStatus) => {
  const values = [];
  Object.keys(data).forEach(cug => {
    if (!data[cug].isActive) {
      values.push(`${cug}:{DIS}`);
    } else if (data[cug].isCustomised) {
      values.push(`${cug}:{C}`);
    } else {
      values.push(`${cug}:{D}`);
    }
  });
  return values.join('|');
};

export const getRoundedOffValue = (value: number): number =>
  parseFloat(value.toFixed(1)); //rounds of a value to 1 decimal place

const isMMTBlackSegment = (offerValueList: OfferValueInterface[]) => {
  return !!offerValueList?.find(offer =>
    onlyMmtBlackSegments.includes(offer?.segment),
  );
};

export const createFinalOfferForCampaign = (
  dealType: DEAL_TYPE_LABEL,
  offerValueDataList: OfferValueInterface[],
) => {
  let finalOffer = null;
  let isMmtBlack = false;
  switch (dealType) {
    case DEAL_TYPE_LABEL.coupon:
      finalOffer = {
        COUPON: offerValueDataList[0].offerValue,
      };
      break;
    case DEAL_TYPE_LABEL.cug:
      isMmtBlack = isMMTBlackSegment(offerValueDataList);
      if (isMmtBlack && !!offerValueDataList.length) {
        finalOffer = {
          MMT_BLACK1: offerValueDataList.find(
            offer => offer.segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK1,
          )?.offerValue,
          MMT_BLACK2: offerValueDataList.find(
            offer => offer.segment === OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK2,
          )?.offerValue,
        };
      } else if (
        offerValueDataList[0].segment === OFFER_VALUE_SEGMENT_TYPE.MOBILE
      ) {
        finalOffer = {
          MOBILE: offerValueDataList[0].offerValue,
        };
      }
      break;
  }
  return finalOffer;
};

export const overrideData = (allStatesData, additionalData) => {
  const formattedData = {
    ...allStatesData,
    ...additionalData,
  };
  return formattedData;
};

export const getDefaultPromotionName = ({
  promotionType,
  discountForNonLogin = 0,
  extraDiscountForLogin = 0,
  bookablePeriod = '',
  stayNights = 0,
  freeNights = 0,
  minStayDuration,
}: Partial<DefaultFormValue> & {
  promotionType: CreationSubTabsType;
}) => {
  let promotionName = '';
  switch (promotionType) {
    case newPromotionTypes.LOS:
      promotionName = `${defaultPromotionName.LOS}${
        discountForNonLogin + extraDiscountForLogin
      }%-${minStayDuration} Days`;
      break;
    case newPromotionTypes.FND:
      promotionName = `${defaultPromotionName.FND}${stayNights || ''}on${
        freeNights || ''
      }`;
      break;
    case newPromotionTypes.EBD:
      promotionName = `${defaultPromotionName.EBD}-${
        discountForNonLogin + extraDiscountForLogin
      }%-${bookablePeriod} Days`;
      break;
    case newPromotionTypes.LMD:
      promotionName = `${defaultPromotionName.LMD}-${
        discountForNonLogin + extraDiscountForLogin
      }%-${bookablePeriod} Days`;
      break;
    default:
      return promotionName;
  }
  return promotionName;
};
