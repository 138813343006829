import queryString from 'query-string';
import Bowser from 'bowser';

import { getFormattedDate } from './DateUtils';
import pushToGTM, { GTM_CATEGORIES } from './gtm';
import { AppEvents } from '../utilities/EventBus';
import {
  PropertiesListingDetails,
  TabsInfo,
} from 'interfaces/HotelContextInterface';
import { ObjectKeyPaths } from './Utils.types';
import { ToastProps } from 'interfaces/AppContextInterface';
import browsers from '../browsers.json';
import { CombinedError } from '@urql/core';
import { GenericValue } from 'interfaces/types';
import { API_TOKEN_KEY, appDocAccessCookieKey } from '../constants';
import type { RouterProps } from 'react-router-dom';
import { type AxiosResponse } from 'axios';
import DOMPurify from 'dompurify';

/**
 * Moving app top level utils to here
 * This is needed since Vite JS hot reloading is not working
 * Read more here: https://github.com/vitejs/vite/discussions/4577#discussioncomment-1161007
 */
const browser = Bowser.getParser(window.navigator.userAgent);
export const isSupportedBrowser = browser.satisfies({
  chrome: `>=${browsers.chrome}`,
  edge: `>=${browsers.edge}`,
  firefox: `>=${browsers.firefox}`,
  safari: `>=${browsers.safari}`,
});

type History = RouterProps['history'];
//This variable holds the history object reference
export const routeHistoryRef: { curr: History | null } = { curr: null };
//This variable holds the counter of calls in progress
//Keeping here since Loader effect might not run but by then showGlobalLoader is already called
export const callCounterRef = { curr: 0 };
export const shimmerCounterRef = { curr: 0 };
export const isNullOrUndefined = value =>
  value === null || typeof value === 'undefined';

export function navigateTo(path, state = null, search = '') {
  if (isNullOrUndefined(state)) {
    routeHistoryRef.curr.push(path);
  } else {
    routeHistoryRef.curr.push({
      pathname: path,
      state,
      search: search,
    });
  }
}

export function replaceUrl({ path, query }) {
  routeHistoryRef.curr.replace({
    pathname: path,
    search: query,
  });
}

export const parseTime = (time: string) => {
  if (isNullOrUndefined(time)) return { hour: '', minute: '' };
  const [hour, minute] = time.split(':');
  return {
    hour: parseInt(hour),
    minute: parseInt(minute),
  };
};

export function goForward() {
  routeHistoryRef.curr.go(1);
}

export function goBack() {
  routeHistoryRef.curr.go(-1);
}

export const showGlobalShimmer = value => {
  if ((!value && shimmerCounterRef.curr > 0) || value) {
    shimmerCounterRef.curr = value
      ? shimmerCounterRef.curr + 1
      : shimmerCounterRef.curr - 1;
  }
  AppEvents.emit('showGlobalShimmer', value);
};

export const showGlobalLoader = value => {
  //call counter should not go below zero

  if ((!value && callCounterRef.curr > 0) || value) {
    callCounterRef.curr = value
      ? callCounterRef.curr + 1
      : callCounterRef.curr - 1;
  }

  AppEvents.emit('showGlobalLoader', value);
};
export const getGraphQLErrors = error => {
  let message = '';
  const graphQLError =
    error?.graphQLErrors?.[0] || error?.error?.graphQLErrors?.[0];
  if (graphQLError) {
    if (graphQLError.message) {
      message = graphQLError.message;
    }
    const extensions = graphQLError.extensions;
    if (extensions) {
      if (extensions.message) {
        message = extensions.message as string;
      }
      if (extensions.error) {
        message = extensions.error as string;
      }
      if (extensions?.errors?.[0]?.message) {
        message = extensions.errors[0].message;
      }
    }
  }
  return message;
};
export const getParsedErrorMessageFromGraphql = (error: CombinedError) => {
  let message = 'Something went wrong!';
  const graphQLError = getGraphQLErrors(error);
  if (graphQLError) {
    message = graphQLError;
  }

  return message;
};

export type APIError = {
  message?: string;
  data?: {
    message?: string;
    errors?: { message: string }[];
    error?: {
      api_error?: {
        message?: string;
      };
    };
  };
  response?: {
    data?: {
      message?: string;
      errors?: { message: string }[];
      error?: {
        api_error?: {
          message?: string;
        };
      };
    };
  };
  errors?: { message: string }[];
  errorDetail?: {
    displayMessage?: string;
  };
  error?: {
    api_error?: {
      message?: string;
    };
  };
};

export const getParsedErrorMessageFromAPI = (error: APIError): string => {
  let message = '';

  if (
    error?.message ||
    error?.data?.message ||
    error?.response?.data?.message
  ) {
    message =
      error?.data?.message || error?.response?.data?.message || error?.message;
  }
  if (
    (Array.isArray(error?.errors) && error.errors.length) ||
    (Array.isArray(error?.response?.data?.errors) &&
      error.response.data.errors.length)
  ) {
    const errors = error?.errors || error?.response?.data?.errors;
    message = errors?.map(item => item.message).join('\n');
  }

  if (error?.errorDetail?.displayMessage) {
    message = error?.errorDetail?.displayMessage;
  }

  if (
    error?.error?.api_error?.message ||
    error?.response?.data?.error?.api_error?.message
  ) {
    message =
      error?.error?.api_error?.message ||
      error?.response?.data?.error?.api_error?.message;
  }

  return message;
};

export const getErrorMessage = error => {
  let message = error; //props.message || props.commonMessage;
  if (Array.isArray(error)) {
    const firstErrorMessage = error?.find(item => typeof item === 'string');
    if (firstErrorMessage) message = firstErrorMessage;
  }
  const apiError = getParsedErrorMessageFromAPI(error);

  if (apiError) {
    message = apiError;
  }

  const graphQLError = getGraphQLErrors(error);
  if (graphQLError) {
    message = graphQLError;
  }
  // @ts-ignore
  if (message?.errors?.length) {
    // @ts-ignore
    message = message?.errors?.map(item => item.message).join('\n');
  }

  if (typeof message !== 'string') {
    message = message?.message || error?.error;
  }

  return message;
};

export const showMessage = (props: ToastProps) => {
  const { persistUntilClear = false, type = 'success', show = true } = props;

  const errormessage = getErrorMessage(props.message);

  const message = errormessage || props.commonMessage;

  AppEvents.emit('showToast', {
    show,
    message,
    type,
    persistUntilClear,
    title: props.title,
  });
};

export function ignoreFailure(promise: Promise<unknown>) {
  return promise.then(
    res => Promise.resolve(res),
    err => Promise.resolve(err.response?.data ?? {}),
  );
}

export function sentenceJoin(arr: string[]): string {
  if (arr.length === 1) {
    return arr[0];
  }
  return `${arr.slice(0, -1).join(', ')} & ${arr[arr.length - 1]}`;
}

export const fixedFloatValue = (val: string, decimalPts: number): string => {
  return parseFloat(val).toFixed(decimalPts);
};

export const INTLFormat = (nStr: number | bigint, currency: string): string => {
  if (currency === 'INR') {
    return new Intl.NumberFormat('en-IN').format(nStr);
  }
  return new Intl.NumberFormat('en-US').format(nStr);
};

export function appendCDN(path: string): string {
  return `https://gos3.ibcdn.com/${path}`;
}

/**
 * This function will provide complete functionality
 * to extract the value from numeric input.
 * Set `min` & `max` attrs on input and that will also be considered.
 *
 * Pass the `onChange` or `onBlur` event to this function,
 * it will return the parsed numeric value.
 * @param {native event} evt
 */
export function extractNumberInput(
  evt: React.ChangeEvent<HTMLInputElement>,
  decimalPoints: number = 0,
) {
  const min = parseInt(evt.target.min, 10) || 0;
  const max = parseInt(evt.target.max, 10) || null;
  let newVal: string | number = evt.target.value;
  newVal =
    min >= 0
      ? newVal.replace(/[^0-9.]/g, '')
      : newVal.replace(/[^-?0-9.]/g, '');

  const isDotAtEnd = /\.$/.test(newVal);
  const isNegative = /^-/.test(newVal);

  if (newVal.trim() === '') {
    return '';
  }

  if (isNegative && (newVal === '-' || Number(newVal) === 0)) {
    return newVal;
  }

  newVal = Number(newVal);
  if (Number.isNaN(newVal)) {
    newVal = '';
    return newVal;
  }
  if (decimalPoints > 0) {
    newVal =
      Math.round(newVal * Math.pow(10, decimalPoints)) /
      Math.pow(10, decimalPoints);
  } else {
    newVal = parseInt(`${newVal}`, 10);
  }
  if (newVal < min) {
    newVal = min;
  } else if (!isNullOrUndefined(max) && newVal > max) {
    newVal = max;
  }
  if (decimalPoints > 0 && isDotAtEnd) {
    newVal = `${newVal}.`;
  }
  return newVal;
}

function formatDecimal(noOfPoints: number) {
  const powValue = Math.pow(10, noOfPoints);
  return function (number: number) {
    return Math.round(number * powValue) / powValue;
  };
}

export const formatDecimal2 = formatDecimal(2);

export function capitalizeFirst(string: string) {
  if (isNullOrUndefined(string) || string === '') return '';
  return string[0].toUpperCase() + string.substring(1);
}

export function capitalizeString(string: string) {
  if (string === '') return '';
  return string
    .split(' ')
    .map(substr => capitalizeFirst(substr))
    .join(' ');
}

export function arrayToObject<T>(arr: Array<T>, key: string) {
  return arr?.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});
}

export function GAFormatting(text: string) {
  return text
    .replace(/[^\w\s|]/g, '')
    .replace(/\s/g, '_')
    .toLowerCase();
}

export function formatNumberByLocale(
  numberString: number,
  locale: string = 'en-IN',
) {
  return numberString.toLocaleString(locale);
}

export function getImageDimensions(file) {
  return new Promise(resolve => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = function () {
      resolve({ naturalWidth: img.width, naturalHeight: img.height });
    };
  });
}

export function convertBytesTo(bytes: number, unit = 'MB') {
  switch (unit) {
    case 'KB':
      return bytes / 1024;
    case 'MB':
      // (1024 * 1024)
      return bytes / 1048576;
    case 'GB':
      // (1024 * 1024 * 1024)
      return bytes / 1073741824;

    default:
      return bytes;
  }
}

export function getFileSize(size: number) {
  const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
  let i = 0;
  let bytes = size;
  while (bytes > 900 && i < fSExt.length) {
    bytes /= 1024;
    i++;
  }
  const unit = fSExt[i];
  return `${Math.round(convertBytesTo(size, unit))} ${unit}`;
}

const imageThresholds = { lower: 0, upper: 30 };
const videoThresholds = { lower: 4, upper: 60 };

export const checkInvalidSizeRangeOfFile = (
  file,
  isPhoto,
  defaultUnit = 'MB',
) => {
  const sizeLimit = isPhoto ? imageThresholds : videoThresholds;
  const fileSize = convertBytesTo(file.size, defaultUnit);
  return fileSize < sizeLimit.lower || fileSize > sizeLimit.upper;
};

/**
 *
 * @param {string} pathname
 * @param {string} search
 * @param {string[]|string} queryParams
 * @returns {string|object}
 */
export function clearQueryParams(
  pathname: string,
  search: string,
  queryParams: string[] | string,
  onlyRead: boolean = false,
) {
  const params = queryString.parse(search);
  let toReturn;
  if (typeof queryParams === 'string') {
    toReturn = params[queryParams];
    //cleanup from the URL
    delete params[queryParams];
  } else {
    toReturn = {};
    Object.assign(toReturn, params);
    queryParams.forEach(param => {
      delete params[param];
    });
  }

  let newSearch = '';
  if (Object.keys(params).length > 0) {
    newSearch = `?${queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    })}`;
  }
  if (!onlyRead) {
    replaceUrl({ path: pathname, query: newSearch });
  }

  return toReturn;
}

type Primitives = string | boolean | number | undefined;

export function appendQueryParam({
  search,
  queryParams,
}: {
  search: string;
  queryParams: Record<string, Primitives>;
}): string {
  const existingQueryParams = queryString.parse(search);
  return queryString.stringify({
    ...existingQueryParams,
    ...queryParams,
  });
}

export function allSettled(promises: Promise<unknown>[]) {
  const wrappedPromises = promises.map(p =>
    Promise.resolve(p).then(
      val => ({ status: 'fulfilled', value: val }),
      err => ({ status: 'rejected', reason: err }),
    ),
  );
  return Promise.all(wrappedPromises);
}
interface NavigatorIE extends Navigator {
  msSaveBlob?: (blob: Blob, fileName: string) => void;
}

function initiateDownload(blob: Blob, fileName: string) {
  const navigatorIE = navigator as NavigatorIE;
  if (navigatorIE.msSaveBlob) {
    // IE 10+
    navigatorIE.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      showMessage({
        show: true,
        message: 'You\'re browser doesn\'t support downloading!',
        type: 'error',
      });
    }
  }
}

/**
 * @description
 * Here we need can download by passing axios response.
 * File should have header of 'content-disposition'
 * @param { axiosResponse } response
 */
function blobToFile(
  response: AxiosResponse,
  fileExtn: string,
  blobType: string,
) {
  const filename = response.headers['content-disposition'].replace(
    /^.*filename=/,
    '',
  );
  const fileData = response.data;
  const blob = new Blob([fileData], { type: blobType });
  const fileName = `${filename}${fileExtn}`;
  initiateDownload(blob, fileName);
}

//Extension expected to come from BE
export const downloadFile = (response: AxiosResponse) =>
  blobToFile(response, '', 'application/octet-stream');
export const downloadCSV = (response: AxiosResponse) =>
  blobToFile(response, '.csv', 'text/csv;charset=utf-8;');

export function getPluralOrSingularForm(string: string, count: number = 1) {
  return count > 1 ? ` ${count} ${string}s` : `${count} ${string}`;
}

/**
 * This function returns a function that can be used with
 * React.memo
 * @param {string[]} keysList
 */
export function getComparator(keysList: string[]) {
  return function (prevProps: object, nextProps: object) {
    return keysList.every(key => prevProps[key] === nextProps[key]);
  };
}

export function findValueByPath(obj: object, path: string) {
  return path.split('.').reduce((p, c) => p?.[c], obj);
}

/**
 *
 * Input ->  {'a.b.c':1}
 * Output -> {a:{b:{c: 1}}}
 */
export function unflatten(
  data: Record<string, unknown>,
): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  for (const i in data) {
    const keys = i.split('.');
    keys.reduce(function (
      acc: Record<string, unknown>,
      key: string,
      index: number,
    ) {
      if (acc[key]) {
        return acc[key] as Record<string, unknown>;
      }
      if (keys.length - 1 === index) {
        acc[key] = data[i];
      } else {
        acc[key] = {};
      }

      return acc[key] as Record<string, unknown>;
    },
    result);
  }
  return result;
}

export function removeExtraSpaces(str: string): string | void {
  if (!str) {
    return;
  }
  return str.trim().replace(/\s+/g, ' ');
}

// Need to implemented using DOM API instead of jQuery

export function scrollToElement(elementToScrollTo: string) {
  document
    .querySelector(elementToScrollTo)
    .scrollIntoView({ behavior: 'smooth' });
}

export function showStaffUserError() {
  showMessage({
    type: 'error',
    show: true,
    message: 'Staff user cannot perform this operation',
  });
}

export function showToast(
  message: string,
  type: 'success' | 'error' | 'warning',
) {
  showMessage({
    show: true,
    message,
    type,
  });
}

export function getURLWithParams(
  url: string,
  params: Record<string, string | number>,
): string {
  const urlParam = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (!isNullOrUndefined(params[key])) {
      urlParam.append(key, `${params[key]}`);
    }
  });
  return `${url}?${urlParam.toString()}`;
}

export const deepFlatten = arr =>
  [].concat(...arr.map(v => (Array.isArray(v) ? deepFlatten(v) : v)));

export const getUrlFromFileObj = file => {
  return URL.createObjectURL(file);
};

export function sortIgnoringCase(array: string[]) {
  const sortedArray = array.sort(function (a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
}

export const findOptionsObj = (options, value: string | number) => {
  //its not possible to remove any here because it is a generic function which accepts different type of options
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return options?.find((item: any) => item.value === value);
};

export const getAmountWithCurrency = (
  amount: number,
  baseCurrency = 'INR',
  fixed: number = 2,
) => {
  const locale = baseCurrency === 'INR' ? 'en-IN' : 'en-US';
  if (!amount && amount !== 0) return '--';
  const localisedAmount = Number(amount.toFixed(fixed)).toLocaleString(locale);
  const currency = baseCurrency === 'INR' ? '₹' : baseCurrency;
  return `${currency} ${localisedAmount}`;
};

export const getDataPropsFromRest = rest => {
  if (isNullOrUndefined(rest)) return {};
  return Object.keys(rest).reduce((acc, item) => {
    if (item.startsWith('data-')) {
      acc[item] = rest[item];
    }
    return acc;
  }, {});
};

export const roundOff = (number: number, decimalPoints: number) => {
  const multiplier = Math.pow(10, decimalPoints);
  //Explaination: https://stackoverflow.com/a/11832950
  return Math.round((number + Number.EPSILON) * multiplier) / multiplier;
};

export function isObject(val: GenericValue): boolean {
  if (isNullOrUndefined(val)) return false;
  return typeof val === 'object' && !Array.isArray(val);
}

type Fn = (...args: Function[]) => string;
type ClassConditions = { [className: string]: boolean };
type ClassNames = (...args: (string | Fn | ClassConditions)[]) => string;

export const classNames: ClassNames = (...args): string =>
  args
    .map(x => {
      if (typeof x === 'function') {
        return x();
      } else if (isObject(x)) {
        return Object.entries(x)
          .filter(([, condition]) => condition)
          .map(([className]) => className)
          .join(' ');
      }
      return x;
    })
    .filter(x => x)
    .join(' ');

export const trimString = val =>
  typeof val === 'string'
    ? val.trim()
    : typeof val === 'number'
      ? // added type number because isEmpty will return true for number type
      val.toString()
      : val;

export const isEmpty = val =>
  isNullOrUndefined(val) ||
  !(Object.keys(trimString(val)) || trimString(val)).length;

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const throttle = <T extends unknown[]>(
  fn: (...args: T) => void,
  wait: number = 300,
) => {
  let inThrottle: boolean;
  let lastFn: ReturnType<typeof setTimeout>;
  let lastTime: number;
  return (...args: T) => {
    if (!inThrottle) {
      fn(...args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn(...args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const camelCaseToWords = (string, seperator = '-') =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, seperator)
    .toLowerCase();

export const compactObject = (val, omitValue = null) =>
  Object.keys(val).reduce(
    // eslint-disable-next-line
    (acc, key) => (val[key] != omitValue ? { ...acc, [key]: val[key] } : acc),
    {},
  );

export const compactArray = (arr = [], omitValue = null) =>
  arr.filter(val => (omitValue ? val !== omitValue : !!val));

export const createCommonParentRoute = (
  parent: TabsInfo,
  childrenArr: TabsInfo[],
) => {
  const routesRegex = new RegExp(
    `(${childrenArr.map(item => item.route).join('|')})`,
  );
  return {
    route: routesRegex,
    component: parent.component,
  };
};

export const previewOnMmt = normalizedCurrHotel => {
  const url = `https://www.makemytrip.com/hotels/hotel-details/?checkin=date_7&checkout=date_8&city=abc&country=ab&roomStayQualifier=2e0e&hotelId=${normalizedCurrHotel.mmt_id}&locusId=abc&locusType=city&currency=${normalizedCurrHotel.base_currency}&source=INGO`;
  pushToGTM(GTM_CATEGORIES.HEADER, 'preview_on_makemytrip', url);
  window.open(url);
};

export const previewOnGoibibo = normalizedCurrHotel => {
  const nameString = normalizedCurrHotel.hotelname.replace(/ /g, '-');
  const cityString = normalizedCurrHotel.city.replace(/ /g, '-');
  const today = new Date();
  const tomorrow = new Date();
  today.setDate(today.getDate() + 7);
  tomorrow.setDate(tomorrow.getDate() + 8);
  const queryParams = {
    ci: getFormattedDate(today, 'YYYYMMDD'),
    co: getFormattedDate(tomorrow, 'YYYYMMDD'),
  };
  const url = `https://www.goibibo.com/hotels/${nameString}-hotel-in-${cityString}-${
    normalizedCurrHotel.voyagerid
  }/?hquery=${encodeURIComponent(JSON.stringify(queryParams))}`;
  pushToGTM(GTM_CATEGORIES.HEADER, 'preview_on_goibibo', url);
  window.open(url);
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// enabling any because we are not sure about the type of the object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValue = <T extends object = any>(
  from: T,
  selector: ObjectKeyPaths<T> | string,
  //enable any because we are not sure about the type of the default value it can be of any structure and can contain any number of chaining
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any = undefined,
) =>
    (selector as string)
    // eslint-disable-next-line
    ?.replace(/\[([^\[\]]*)\]/g, '.$1.')
      .split('.')
      .filter(t => t !== '')
      .reduce((prev, cur) => prev && prev[cur], from) ?? defaultValue;

// We cannot predict the type of the object so we are using any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pick = <T extends object = any, K extends keyof T = any>(
  obj: T,
  arr: K[],
): Pick<T, K> =>
    arr.reduce(
    // eslint-disable-next-line
    (acc, curr) => (curr in obj && ((acc as any)[curr] = obj[curr]), acc),
      {},

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any;

export const omit = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> => {
  const result = { ...obj };
  keys.forEach(key => {
    delete result[key];
  });
  return result as Omit<T, K>;
};

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}_` : '';
    if (
      typeof obj[k] === 'object' &&
      !isNullOrUndefined(obj[k]) &&
      Object.keys(obj[k]).length > 0
    )
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

export const toTitleCase = (str: string) => {
  if (!str) return '';
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export const toPixel = (x: number) => `${x}px`;

export const pushEventToGtm = (
  action: string,
  label: string,
  // enabling any because we are not sure about the type of the object that customData can have
  // eslint-disable-next-line @typescript-eslint/default-param-last, @typescript-eslint/no-explicit-any
  customData: Record<string, any> = {},
  category: string,
): void => {
  pushToGTM(category, action, label, customData || {});
};

export function copyTextToClipboard(copyRef) {
  copyRef.current.contentEditable = true;
  const range = document.createRange();
  range.selectNodeContents(copyRef.current);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand('copy');
  copyRef.current.contentEditable = false;
}

export const getDatasource = data => {
  if (data?.hasOwnProperty('isHomeStayFlow')) {
    const { isHomeStayFlow, isHostWeb } = data;
    return isHomeStayFlow && isHostWeb ? 'host_web_view' : 'ingo_legacy';
  }
  return null;
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const currencyDataFormaterWithFixedDecimals = (
  number,
  currency,
  fixed = 1,
  minValue = 10000,
  singleFixed = 3,
) => {
  if (currency === 'INR') {
    if (number > 10000000) {
      return {
        value: parseFloat((number / 10000000).toFixed(fixed)).toString(),
        suffix: 'Cr',
      };
    } else if (number > 99999) {
      return {
        value: parseFloat((number / 100000).toFixed(fixed)).toString(),
        suffix: 'L',
      };
    } else if (number > minValue && minValue >= 1000) {
      return {
        value: parseFloat((number / 1000).toFixed(fixed)).toString(),
        suffix: 'K',
      };
    }
    return {
      value: parseFloat(parseFloat(number).toFixed(singleFixed)).toString(),
      suffix: '',
    };
  }
  if (number > 1000000000000) {
    return {
      value: parseFloat((number / 1000000000000).toFixed(fixed)).toString(),
      suffix: 'Tn',
    };
  } else if (number > 1000000000) {
    return {
      value: parseFloat((number / 1000000000).toFixed(fixed)).toString(),
      suffix: 'Bn',
    };
  } else if (number > 1000000) {
    return {
      value: parseFloat((number / 1000000).toFixed(fixed)).toString(),
      suffix: 'M',
    };
  } else if (number > minValue && minValue >= 1000) {
    return {
      value: parseFloat((number / 1000).toFixed(fixed)).toString(),
      suffix: 'K',
    };
  }
  return {
    value: parseFloat(parseFloat(number).toFixed(singleFixed)).toString(),
    suffix: '',
  };
};

export const generatePrefixForCurrencies = currency =>
  currency === 'INR' ? '₹' : currency;

const generateRandomHexChar = () => {
  const charsToBeUsed = '0123456789abcdefABCDEF';
  const randomIndex = Math.floor(Math.random() * charsToBeUsed.length);
  return charsToBeUsed.charAt(randomIndex);
};

export const generateCorrelationKey = () => {
  const blockSizes = [8, 4, 4, 4, 12];
  const correlationKey = blockSizes.map(sectionLength => {
    let section = '';
    for (let i = 0; i < sectionLength; i++) {
      section += generateRandomHexChar();
    }
    return section;
  });
  return correlationKey.join('-');
};

export const time24hrTo12Hr = (time: string) => {
  if (!time) return '';
  const [hours, minutes] = time.split(':');
  let period = 'am';
  let hours12hr = parseInt(hours);

  if (hours12hr === 0) {
    hours12hr = 12;
  } else if (hours12hr >= 12) {
    period = 'pm';
    if (hours12hr > 12) {
      hours12hr -= 12;
    }
  }

  const time12hr = `${hours12hr
    .toString()
    .padStart(2, '0')}:${minutes} ${period}`;

  if (hours12hr === 12 && minutes === '00' && period === 'pm') {
    return `${time12hr} (noon)`;
  }
  return time12hr;
};

// argument should be in 24hr format from 00:00:00
// return - a array of object {label,value}, label is the time in 12 hr format and value is corresponding value to that label in 24 hr format
export const generateTimeRange = (
  startTime24hr: string,
  endTime24hr: string,
) => {
  const timeArray = [];
  let currentTime = startTime24hr;

  while (currentTime <= endTime24hr) {
    let [hours, minutes] = currentTime.split(':');
    let period = 'am';
    let hours12hr = parseInt(hours);

    if (hours12hr === 0) {
      hours12hr = 12;
    } else if (hours12hr >= 12) {
      period = 'pm';
      if (hours12hr > 12) {
        hours12hr -= 12;
      }
    }

    const time12hr = `${hours12hr
      .toString()
      .padStart(2, '0')}:${minutes} ${period}`;

    if (hours12hr === 12 && minutes === '00' && period === 'pm') {
      timeArray.push({ label: `${time12hr} (noon)`, value: currentTime });
    } else {
      timeArray.push({ label: time12hr, value: currentTime });
    }

    if (minutes === '00') minutes = '30';
    else {
      minutes = '00';
      hours = (1 + parseInt(hours)).toString().padStart(2, '0');
    }
    currentTime = `${hours}:${minutes}:00`;
  }

  return timeArray;
};

export const getAPIToken = () => {
  const token = (localStorage.getItem(API_TOKEN_KEY) || '').replace(/"/g, '');

  return token;
};

// util function to set and expire a cookie
export const setCookie = (name: string, value: string, days: number = null) => {
  let expires = null;
  let cookie = `${name}=${value};domain=.${window.location.hostname};path=/;`;

  if (!isNullOrUndefined(days)) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()}`;
  }

  if (expires) {
    cookie += expires;
  }
  document.cookie = cookie;
};

export const broadcastLoginInfoToOtherTabs = type => {
  const channel = new BroadcastChannel('tokenChange');
  channel.postMessage({
    action: type,
    currentTab: window.TAB,
  });
};

export const removeAPIToken = (broadcastToOtherTabs = true) => {
  localStorage.removeItem(API_TOKEN_KEY);
  setCookie(appDocAccessCookieKey, '', 0);
  localStorage.removeItem('last_hotelcode');
  if (broadcastToOtherTabs) {
    broadcastLoginInfoToOtherTabs('logout');
  }
};

// Generic functioncannot determine the type of K so using any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K,
): Record<K, T[]> {
  return list.reduce((acc, item) => {
    const key = getKey(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as Record<K, T[]>);
}

export function mapValues<T, U>(
  obj: Record<string, T>,
  mapper: (value: T, key: string, obj: Record<string, T>) => U,
): Record<string, U> {
  const result: Record<string, U> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = mapper(obj[key], key, obj);
    }
  }
  return result;
}

export const getFormattedString = (
  template: string,
  replacements: { [key: string]: string | number },
) => {
  let result = template;
  for (const key in replacements) {
    const replaceKey = new RegExp(`__${key}__`, 'g');
    result = result.replace(replaceKey, `${replacements[key]}`);
  }
  return result;
};

export const noop = () => {};

export function updateNestedJsonKeyToNewValue(
  inputObj: object,
  key: string,
  newValue: unknown,
) {
  if (Array.isArray(inputObj)) {
    return inputObj.map(item =>
      updateNestedJsonKeyToNewValue(item, key, newValue),
    );
  } else if (typeof inputObj === 'object' && inputObj !== null) {
    const updatedObj = { ...inputObj };
    for (const currentKey in updatedObj) {
      updatedObj[currentKey] = updateNestedJsonKeyToNewValue(
        updatedObj[currentKey],
        key,
        newValue,
      );
    }
    if (key in updatedObj) {
      updatedObj[key] = newValue;
    }
    return updatedObj;
  }
  return inputObj;
}

export const cleanInputObjWithEmptyKeys = (obj: object): object | undefined => {
  for (const key in obj) {
    const value = obj[key];
    if (
      isNullOrUndefined(value) ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    ) {
      delete obj[key];
    } else if (isObject(value)) {
      cleanInputObjWithEmptyKeys(value);
      if (Object.keys(value).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export function numberToWord(num: number) {
  const words = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
  ];
  return words[num] || num.toString();
}

export function insertUniqueNumberIntoSortedArray(
  arr: number[],
  newValue: number,
) {
  const index = arr.findIndex(element => element >= newValue);
  if (index < 0 || arr[index] !== newValue) {
    arr.splice(index < 0 ? arr.length : index, 0, newValue);
  }
  return arr;
}

export const getUniqueValues = (numbers: number[]) => [...new Set(numbers)];

export function findLastIndex(array, predicate) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return i;
    }
  }
  return -1;
}
export const interpolateString = (
  template: string,
  values: Record<string, string>,
): string => {
  return template.replace(
    /{{(.*?)}}/g,
    (match, key) => values[key.trim()] || match,
  );
};

export const processInternalCalSyncData = (data: PropertiesListingDetails) => {
  if (data && isObject(data) && Object.keys(data).length) {
    return Object.entries(data).reduce((acc, [type, itemData]) => {
      if (itemData?.length > 0) {
        acc.push({ type, data: itemData });
      }
      return acc;
    }, []);
  }
  return [];
};

export const isAllRateplanSelected = roomRpsList => {
  const rooms = Object.keys(roomRpsList);
  const rateplans = rooms.reduce((acc, cur) => {
    return {
      ...acc,
      ...roomRpsList[cur],
    };
  }, {});

  const rpValues = Object.keys(rateplans)
    .filter(key => key !== 'roomLevel' && key !== 'isRoomChecked')
    .map(key => rateplans[key]);
  const isChecked = !rpValues.includes(false);
  const totalRp = rpValues.length;
  const selectedRp = rpValues.filter(Boolean).length;

  return {
    isChecked,
    totalRp,
    selectedRp,
  };
};

export const createBlobUrlFromHtml = (htmlContent: string): string => {
  if (typeof htmlContent !== 'string' || !htmlContent.trim()) {
    return null;
  }

  const blob = new Blob([htmlContent], { type: 'text/html' });
  return URL.createObjectURL(blob);
};

export const isFunction = (fn: unknown): boolean =>
  fn && {}.toString.call(fn) === '[object Function]';

// Function will sanitize the url:
// Input - 'http://www.some.site/page.html?default=<script>alert(document.cookie)</script>'
// Output - 'http://www.some.site/page.html?default='
export const sanitizeImageURL = (src: string): string => {
  if (!src) return '';
  return DOMPurify.sanitize(src);
};
