import { sources } from './reviews.constants';

const SOURCE_DEPENDED_QUERIES = {
  [sources.MMT]: `mmt
    {
        replied
        notReplied
        repliedpercentage
        totalReviews
        rejected
        all_pagecount
        replynotrequired
        replied_question_percentage
        rejected_count
        imageReviews
        photoreviews_count
        under_moderation
        replynow_count
        totalcount
        replied_count     
        reply_not_required_count     
        replynow_pagecount
        moderation_count
        replied_pagecount
        photoreviews_pagecount
        totalBrandPendingReplies
    }`,
  [sources.GI]: `gi
    {
        totalBrandPendingReplies
        totalReplyPending
        totalInTripReviews
        hotel_qna_pagecount
        totalNewMbClaimedReviews
        hotel_qna_count
        nonbooking_pagecount
        questions_count
        totalReviewsAgainstBooking
        citylocality_qna_pagecount
        replied_count
        new_mbg_review_pagecount
        new_instay_review_pagecount
        totalMbClaimedReviews
        attended_instay_review_pagecount
        totalQuestionsNotAnsweredByHotelier
        booking_count
        replied_question_percentage
        totalHotelQuestions
        not_answered_count
        totalNewInTripReviews
        answers_count
        replied_pagecount
        totalValidReviews
        repliedpercentage
        questions_pagecount
        nonbooking_count
        totalCityQuestions
        totalReviews
        totalReplyPendingAgainstBooking
        totalAnswers
        citylocality_qna_count
        totalAttendedInTripReviews
        attended_instay_review_count
        totalQuestionsAnsweredByHotelier
        photoreviews_pagecount
        all_pagecount
        not_answered_pagecount
        photoreviews_count
        totalQuestions
        all_mbg_review_pagecount
        totalReviewsWithPhotos
        new_instay_review_count
        replynow_count
        totalcount
        unresolved_instay_review_count
        unresolved_instay_review_pagecount
        replynow_pagecount
        booking_pagecount
    }`,
};

export const GET_UCG_COUNT_AND_REVIEWS = (
  source: typeof sources[keyof typeof sources],
) => `query getUGCCount(
    $getUGCCountRawEndpointsRequestData: GenericRequest,
    $getUGCReviewsRawEndpointsRequestData: RawEndpointsRequestData,
    $getShortLinkDataRawEndpointRequestData: RawEndpointsRequestData) 
{ 
    ugcCount: 
        getUGCCount(endpointRequestData: 
            $getUGCCountRawEndpointsRequestData)
            {
                success
                results
                {
                    totalReplyPending
                    ${SOURCE_DEPENDED_QUERIES[source]}
                }
            }
    ugcReviews: 
        rawEndpoint(rawEndpointRequestData: $getUGCReviewsRawEndpointsRequestData) 
        {
            getUGCReviews
            {
                modelType1
                {
                    move_first
                    previous
                    next
                    success
                    results
                } 
            }
        }
    shortLinkData: 
        rawEndpoint(rawEndpointRequestData: $getShortLinkDataRawEndpointRequestData)
        {
            getShortLinkData 
            {
                hotelId
                shortLinkType
                channelManager
                errorCode
                {
                    httpStatusCode
                    errorCode
                    errorMessage
                    displayMessage
                }
                    __typename
            }
        __typename
        }
}`;

export const GET_HEADER_COUNTS_QUERY = (
  source: typeof sources[keyof typeof sources],
) => `
  query getUGCCount($getUGCCountRawEndpointsRequestData: GenericRequest) {
    ugcCount: getUGCCount(
      endpointRequestData: $getUGCCountRawEndpointsRequestData
    ) {
      success
      results {
        totalReplyPending
        ${SOURCE_DEPENDED_QUERIES[source]}
      }
    }
  }
`;
