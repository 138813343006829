import { doQuery } from '../../../base/GraphQLClient';
import {
  getFormattedDate,
  getStartOfMonth,
  getEndOfMonth,
} from 'utilities/DateUtils';
import { apiDateFormat } from '../../../constants';
import { analyticsQuery } from './AnalyticsGraphQueries';
import * as AC from '../constants/Analytics';
import {
  getTransactionOverviewVariables,
  parseTransactionOverviewResponse,
  getRoomNightsSectionVariables,
  parseRoomNightsSectionResponse,
  getAvgSellingPriceVariables,
  parseAvgSellingPriceResponse,
  getHotelVisitVariables,
  getConversionRateVariables,
  parseDemandOverviewRes,
  getVisibilitySectionVariables,
  getRoomNightsMonthWiseTrendVariables,
  getRoomNightsMonthWiseTotalVariables,
  getRoomNightsLsYrDataVariables,
  parseRoomNightsLsYrDataResponse,
  getRoomNightsLOSTrendVariables,
  getCancelledBookingsVariable,
  parseCancelledBookingsLastYrResponse,
  parseCancelledBookingsResponse,
  getRoomNightsMealPlanVariables,
  getRoomNightsPaxVariables,
  getConversionRateTrendVariables,
  parseRoomNightsMonthWiseResponse,
  parseVisibilitySectionResponse,
  getRoomNightsLOSAverageVariables,
} from './AnalyticsVariables';
import { getValue } from 'utilities/Utils';

const successStatusCodes = [200, 404];

const getRoomNightsLsYrData = (hotelCode, queryParams) => {
  const query = analyticsQuery;

  const variables = getRoomNightsLsYrDataVariables(queryParams, hotelCode);

  return doQuery(query, variables, { useLoader: false });
};

const getBookingCancelledData = (hotelCode, queryParams) => {
  const query = analyticsQuery;

  const variable = getCancelledBookingsVariable(queryParams, hotelCode);

  return doQuery(query, variable, { useLoader: false });
};

const getModifiedBookingsData = (compareWith, data) => {
  const modifiedData = [];
  let totalHotelBooking;
  let totalCompHotelBooking;

  if (compareWith === 'Last Year') {
    totalCompHotelBooking =
      data.prevYrData?.meta?.hotel?.bookings_cancelled || 0;
    totalHotelBooking = data.curYrData?.meta?.hotel?.bookings_cancelled || 0;
  } else {
    totalHotelBooking = data.meta?.hotel?.bookings_cancelled || 0;
    totalCompHotelBooking = data.meta?.comp?.bookings_cancelled || 0;
  }

  for (let i = 0; i < AC.BOOKING_DAYS.length; i++) {
    const day = AC.BOOKING_DAYS[i];
    let hotelBooking = {};
    let compBooking = {};

    if (compareWith === 'Last Year') {
      compBooking = data.prevYrData?.bookings?.[day] || {};
      hotelBooking = data.curYrData?.bookings?.[day] || {};
    } else {
      hotelBooking = data.hotel[day] || {};
      compBooking = data.comp?.[day] || {};
    }

    hotelBooking.percent = Math.floor(
      ((hotelBooking.cancelled || 0) / (totalHotelBooking || 1)) * 100,
    );
    if (compBooking) {
      compBooking.percent = Math.floor(
        ((compBooking.cancelled || 0) / (totalCompHotelBooking || 1)) * 100,
      );
    }

    modifiedData.push({
      day: day,
      ourHotelBooking: { ...hotelBooking },
      compHotelBooking: compBooking ? { ...compBooking } : {},
    });
  }

  return modifiedData;
};

const getModifiedData = (data, hotelId, brand, viewType) => {
  const ourHotel = data.hotel[hotelId][brand[0]].room_nights;
  const compHotel = data.comp.data[brand[0]].room_nights;
  const metaData = data.meta;
  const modifiedData = [];

  for (const interval in ourHotel) {
    const compPortion = Math.floor(
      (compHotel[interval].value / metaData.comp.room_nights_total) * 100,
    );
    const ourHotelPortion = Math.floor(
      (ourHotel[interval].value / metaData.hotel.room_nights_total) * 100,
    );

    modifiedData.push({
      interval: getFormattedDate(
        interval,
        viewType === 'day' ? 'Do-MMM' : 'MMM-YYYY',
      ),
      ourHotel: { ...ourHotel[interval], portion: ourHotelPortion },
      compData: { ...compHotel[interval], portion: compPortion },
    });
  }

  return modifiedData;
};

const getExtractedData = (hotelId, brand, resp, dataType = 'bookings') => {
  let errMessage = null;
  const valAccessor = dataType === 'hotelData' ? 'room_nights' : dataType;

  const modifiedData = resp.reduce(
    (finalData, currentItem, index) => {
      const temp = currentItem;
      if (temp && temp.status === 'success' && temp.data) {
        const hotelBookingsData = temp.data;
        const data =
          hotelBookingsData.hotel[hotelId][brand]?.[valAccessor] || {};
        const type = index === 0 ? 'curYrData' : 'prevYrData';

        finalData[type][dataType] = data;
        finalData[type].meta = hotelBookingsData.meta;
      } else {
        errMessage = temp.message;
      }
      return finalData;
    },
    {
      prevYrData: { [dataType]: {}, meta: {} },
      curYrData: { [dataType]: {}, meta: {} },
    },
  );

  return { errMessage, modifiedData };
};

export const getLastYRData = (hotelId, queryParams) => {
  const allData = [];

  allData.push(getRoomNightsLsYrData(hotelId, queryParams));

  queryParams.start_date = getFormattedDate(
    getStartOfMonth().subtract(11, 'months').subtract(1, 'year'),
    apiDateFormat,
  );
  queryParams.end_date = getFormattedDate(
    getEndOfMonth().subtract(1, 'year'),
    apiDateFormat,
  );

  allData.push(getRoomNightsLsYrData(hotelId, queryParams));

  return Promise.all(allData).then(res =>
    parseRoomNightsLsYrDataResponse(res, queryParams, hotelId, 'hotelData'),
  );
};

export const getRoomNightsMonthWiseData = (hotelCode, queryParams, brand) => {
  const query = analyticsQuery;

  const roomNightsMonthWiseTrendPromise = new Promise((resolve, reject) => {
    const variables = getRoomNightsMonthWiseTrendVariables(
      queryParams,
      hotelCode,
    );

    doQuery(query, variables)
      .then(res => resolve(res))
      .catch(reject);
  });

  const roomNightsMonthWiseTotalPromise = new Promise((resolve, reject) => {
    const variables = getRoomNightsMonthWiseTotalVariables(
      queryParams,
      hotelCode,
    );

    doQuery(query, variables)
      .then(res => resolve(res))
      .catch(reject);
  });

  return Promise.all([
    roomNightsMonthWiseTrendPromise,
    roomNightsMonthWiseTotalPromise,
  ]).then(response => {
    const [roomNightMonthWiseTrendResponse, roomNightsMonthWiseTotalResponse] =
      response;

    const trendData =
      getValue(
        roomNightMonthWiseTrendResponse,
        'data.rawEndpoint.analyticsHistogram.modelType1.data',
      ) || {};
    const totalData =
      getValue(
        roomNightsMonthWiseTotalResponse,
        'data.rawEndpoint.analyticsHistogram.modelType1.data',
      ) || {};

    const hotelData = parseRoomNightsMonthWiseResponse(
      trendData,
      totalData,
      queryParams,
      hotelCode,
    );

    let errorMess = null;

    if (!response.status) {
      errorMess = response.message;
    }

    const modifiedData = getModifiedData(
      hotelData,
      hotelCode,
      brand,
      queryParams.view,
    );

    return { errorMess, hotelData, modifiedData };
  });
};

export const getBookingsData = (hotelId, brand, compareWith, queryParams) => {
  if (compareWith === 'Competitor') {
    queryParams.comp_data = true;
  } else if (compareWith === 'City') {
    queryParams.comp_data = true;
    queryParams.comparison = 'city';
  } else if (compareWith === 'Last Year') {
    queryParams.comp_data = false;

    const promise = getBookingCancelledData(hotelId, queryParams);

    return promise.then(resp => {
      const data =
        getValue(resp, 'data.rawEndpoint.analyticsHistogram.modelType1') || {};

      const lastYearData = parseCancelledBookingsLastYrResponse(
        data,
        queryParams,
        hotelId,
        'PREVIOUS_YEAR',
      );
      const currYearData = parseCancelledBookingsLastYrResponse(
        data,
        queryParams,
        hotelId,
        'CURRENT_YEAR',
      );

      const finalData = [currYearData, lastYearData];

      const { errMessage, modifiedData: reStructuredData } = getExtractedData(
        hotelId,
        brand,
        finalData,
      );
      const modifiedData = getModifiedBookingsData(
        compareWith,
        reStructuredData,
      );
      return { errMessage, reStructuredData, modifiedData };
    });
  }

  return getBookingCancelledData(hotelId, queryParams).then(resp => {
    const temp = getValue(
      resp,
      'data.rawEndpoint.analyticsHistogram.modelType1',
    ) || { data: {}, status: '' };
    let bookingsData = null;

    if (temp.status !== 'success') {
      return { errMessage: temp.message, bookingsData: null, modifiedData: [] };
    }
    const respData = parseCancelledBookingsResponse(
      temp.data,
      queryParams,
      hotelId,
    );
    bookingsData = {
      comp: respData?.comp?.data?.[brand[0]]?.bookings,
      hotel: respData?.hotel?.[hotelId]?.[brand[0]]?.bookings,
      meta: respData.meta,
    };
    const modifiedData = getModifiedBookingsData(compareWith, bookingsData);
    return { errMessage: null, bookingsData, modifiedData };
  });
};

export const getRoomNightsLOSData = (hotelCode, queryParams) => {
  const query = analyticsQuery;

  const roomNightsLOSTrendPromise = new Promise((resolve, reject) => {
    const variables = getRoomNightsLOSTrendVariables(queryParams, hotelCode);

    doQuery(query, variables)
      .then(res => resolve(res))
      .catch(reject);
  });

  const roomNightsLOSAvergaePromsie = new Promise((resolve, reject) => {
    const variables = getRoomNightsLOSAverageVariables(queryParams, hotelCode);

    doQuery(query, variables)
      .then(res => resolve(res))
      .catch(reject);
  });

  return Promise.all([roomNightsLOSTrendPromise, roomNightsLOSAvergaePromsie]);
};

export const getRoomNightsMealData = (hotelId, queryParams) => {
  const query = analyticsQuery;

  const variable = getRoomNightsMealPlanVariables(queryParams, hotelId);

  return doQuery(query, variable, { useLoader: false });
};

export const getRoomPaxData = (hotelId, queryParams) => {
  const query = analyticsQuery;

  const variable = getRoomNightsPaxVariables(queryParams, hotelId);

  return doQuery(query, variable, { useLoader: false });
};

export const getTransactionOverview = (request, hotelId) => {
  const roomNightsAndNetAmountRequest = {
    ...request,
    property: ['room_nights', 'net_amount'],
  };

  const roomNightsAndNetAmountOverview = new Promise((resolve, reject) => {
    const variables = getTransactionOverviewVariables(
      roomNightsAndNetAmountRequest,
      hotelId,
    );

    doQuery(analyticsQuery, variables)
      .then(res => {
        const response = getValue(
          res,
          'data.rawEndpoint.analyticsHistogram.modelType1',
        ) || { data: {}, status: '' };

        resolve(response);
      })
      .catch(reject);
  });

  const averageSellPriceRequest = { ...request, property: ['sell_amount'] };

  const averageSellPriceOverview = new Promise((resolve, reject) => {
    const variables = getTransactionOverviewVariables(
      averageSellPriceRequest,
      hotelId,
    );

    doQuery(analyticsQuery, variables)
      .then(res => {
        const response = getValue(
          res,
          'data.rawEndpoint.analyticsHistogram.modelType1',
        ) || { data: {}, status: '' };

        resolve(response);
      })
      .catch(reject);
  });

  return Promise.all([
    roomNightsAndNetAmountOverview,
    averageSellPriceOverview,
  ]).then(response => {
    const [roomNightsAndNetAmountResponse, averageSellPriceResponse] = response;

    const data = parseTransactionOverviewResponse(
      roomNightsAndNetAmountResponse.data,
      averageSellPriceResponse.data,
      roomNightsAndNetAmountRequest,
      averageSellPriceRequest,
      hotelId,
    );

    return {
      data: {
        data,
        status:
          roomNightsAndNetAmountResponse.status === 'success' &&
          averageSellPriceResponse.status === 'success'
            ? 'success'
            : 'failure',
      },
    };
  });
};

export const getRoomNightsSection = (request, hotelId, config = {}) => {
  return new Promise((resolve, reject) => {
    const variables = getRoomNightsSectionVariables(request, hotelId);

    doQuery(analyticsQuery, variables, config)
      .then(res => {
        const data = parseRoomNightsSectionResponse(
          getValue(
            res,
            'data.rawEndpoint.analyticsHistogram.modelType1.data',
          ) || {},
          request,
          hotelId,
        );
        resolve({
          data: {
            data,
            status:
              getValue(
                res,
                'data.rawEndpoint.analyticsHistogram.modelType1.status',
              ) || '',
          },
        });
      })
      .catch(reject);
  });
};

export const getAvgSellingPrice = (request, hotelId) => {
  return new Promise((resolve, reject) => {
    const variables = getAvgSellingPriceVariables(request, hotelId);

    doQuery(analyticsQuery, variables)
      .then(res => {
        const data = parseAvgSellingPriceResponse(
          getValue(
            res,
            'data.rawEndpoint.analyticsHistogram.modelType1.data',
          ) || {},
          request,
          hotelId,
        );

        resolve({
          data: {
            data,
            status:
              getValue(
                res,
                'data.rawEndpoint.analyticsHistogram.modelType1.status',
              ) || '',
          },
        });
      })
      .catch(reject);
  });
};

export const getDemandOverview = (request, hotelId) => {
  return new Promise((resolve, reject) => {
    const hotelVisitsVariables = getHotelVisitVariables(request, hotelId);
    const converstionRateVariables = getConversionRateVariables(
      request,
      hotelId,
    );

    const hotelVisitsCall = doQuery(analyticsQuery, hotelVisitsVariables);
    const conversionRateCall = doQuery(
      analyticsQuery,
      converstionRateVariables,
    );

    Promise.all([hotelVisitsCall, conversionRateCall])
      .then(res => {
        const [hotelVisitsRes, conversionRatesRes] = res;
        const data = parseDemandOverviewRes(
          getValue(
            hotelVisitsRes,
            'data.rawEndpoint.analyticsHistogram.modelType1.data',
          ) || {},
          getValue(
            conversionRatesRes,
            'data.rawEndpoint.analyticsHistogram.modelType1.data',
          ) || {},
          request,
          hotelId,
        );
        resolve({
          data: {
            data,
            status:
              !successStatusCodes.includes(
                getValue(
                  hotelVisitsRes,
                  'data.rawEndpoint.analyticsHistogram.modelType1.status_code',
                ),
              ) ||
              !successStatusCodes.includes(
                getValue(
                  conversionRatesRes,
                  'data.rawEndpoint.analyticsHistogram.modelType1.status_code',
                ),
              )
                ? 'failure'
                : 'success',
          },
        });
      })
      .catch(reject);
  });
};

export const getVisibilitySection = (request, hotelId) => {
  return new Promise((resolve, reject) => {
    const variables = getVisibilitySectionVariables(request, hotelId);
    doQuery(analyticsQuery, variables)
      .then(res => {
        const data = parseVisibilitySectionResponse(
          getValue(res, 'data.rawEndpoint.analyticsHistogram.modelType1.data'),
          request,
          hotelId,
        );
        resolve({
          data: {
            data,
            status: successStatusCodes.includes(
              getValue(
                res,
                'data.rawEndpoint.analyticsHistogram.modelType1.status_code',
              ),
            )
              ? 'success'
              : 'failure',
          },
        });
      })
      .catch(reject);
  });
};

export const getConversionRateTrend = (request, hotelId) => {
  return new Promise((resolve, reject) => {
    const variables = getConversionRateTrendVariables(request, hotelId);

    doQuery(analyticsQuery, variables)
      .then(res => {
        const data = parseVisibilitySectionResponse(
          getValue(res, 'data.rawEndpoint.analyticsHistogram.modelType1.data'),
          request,
          hotelId,
        );
        resolve({
          data: {
            data,
            status: getValue(
              res,
              'data.rawEndpoint.analyticsHistogram.modelType1.status',
            ),
          },
        });
      })
      .catch(reject);
  });
};
