import { useContext } from 'react';
import { AuthContext } from 'utilities/Context';
import { NewUserPasswordResetHeader } from './NewUserPassworReset';
import { Button, Typography } from 'components/latest-core';
import { navigateTo } from 'utilities/Utils';
import { routes } from 'app/routes';

const NewUserPasswordResetSuccess = () => {
  const {
    langStringsData: { langStrings },
    logOutUser,
    isLoggedIn,
  } = useContext(AuthContext);

  const { Common: COMMON } = langStrings;

  return (
    <NewUserPasswordResetHeader>
      <Typography
        variant="buttonText"
        className="pt-[25px] !text-[#090909] mb-[10px] !normal-case"
      >
        {COMMON.MESSAGES.PWD_CREATE_SUCCESS}
      </Typography>

      <Typography
        variant="subtitle1"
        className="!text-[#090909] opacity-50 mb-[10px]"
      >
        {COMMON.MESSAGES.LOGIN_WITH_PWD}
      </Typography>
      <Button
        variant="text"
        className="hover:underline mb-[10px]"
        onClick={() =>
          isLoggedIn ? logOutUser() : navigateTo(routes.login.base)
        }
      >
        {COMMON.BUTTONS.LOGIN_HERE}
      </Button>
    </NewUserPasswordResetHeader>
  );
};

export default NewUserPasswordResetSuccess;
