export const RadioVariant = {
  DEFAULT: 'default',
  LEGACYBOXED: 'legacy-boxed',
  CARD: 'card',
  CHIP: 'chip',
} as const;

export const RadioSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const;

export const RadioColor = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DEFAULT: 'default',
} as const;
