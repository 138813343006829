import React from 'react';
import { RightSectionShimmer } from './RightSectionShimmer';
import { MainSectionShimmer } from './MainSectionShimmer';

type ContainerShimmerProps = {
  hasLeftNav?: boolean;
};

const ContainerShimmer = (props: ContainerShimmerProps) => {
  const { hasLeftNav = true } = props;
  return (
    <div className={'flex justify-center flex-1'}>
      <div
        id={'container-shimmer'}
        className="mt-4 w-full flex flex-1 flex-row gap-4 justify-center max-w-screen-lg justify-self-center"
      >
        <MainSectionShimmer />
        {hasLeftNav && <RightSectionShimmer />}
      </div>
    </div>
  );
};

export default ContainerShimmer;
