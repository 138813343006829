import { forwardRef, type Ref } from 'react';
import { DropdownBase } from './DropdownBase';
import { type DropdownMainProps } from '../DropdownMain.types';
import { useInlinedDropdownBaseStyles } from '../Dropdown.styles';
import { classNames } from 'utilities/Utils';

const Dropdown = (
  props: Omit<
  DropdownMainProps,
  //classname is removed from inline dropdown as it was messing up the styles in the dropdown
  // use classes instead
  'disableClearable' | 'className'
  > & {
    noOutline?: boolean;
    variant?: 'inlineDropdown';
    className?: string;
  },
  ref: Ref<unknown>,
) => {
  const styles = useInlinedDropdownBaseStyles();
  const {
    classes,
    placeholder = 'Select a value',
    noOutline,
    className,
  } = props;
  return (
    <DropdownBase
      ref={ref}
      classes={{
        root: styles.root,
        endAdornment: styles.endAdornment,
        ...classes,
      }}
      className={classNames(noOutline && styles.noOutline, className)}
      disableClearable
      placeholder={placeholder}
      {...props}
    />
  );
};
const DropdownInline = forwardRef(Dropdown);
export default DropdownInline;
