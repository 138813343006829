import React from 'react';
import { SingleRadioPropsType } from '../Radio.types';
import RadioLabel from './RadioLabel';
import { getSelectedClassName, resolveClasses } from '../Radio.utils';
import { classNames } from 'utilities/Utils';

const SingleRadio = (props: SingleRadioPropsType) => {
  const {
    option,
    customClasses,
    classes,
    isSelected = false,
    isDisabled = false,
  } = props;
  const { radio: radioContainer = '', radioContent: radioClasses = {} } =
    customClasses || {};
  const addDisableClass = isDisabled ? 'radio-disabled' : '';
  return (
    <span
      className={classNames(
        addDisableClass,
        classes.radioContainer,
        resolveClasses(radioContainer, isSelected),
      )}
    >
      <span
        className={classNames(
          addDisableClass,
          getSelectedClassName(isSelected, classes.icon, classes.checkedIcon),
        )}
      />
      <RadioLabel
        option={option}
        radioClasses={resolveClasses(radioClasses, isSelected)}
        classes={classes}
      />
    </span>
  );
};

export default SingleRadio;
