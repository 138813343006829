import {
  forwardRef,
  useCallback,
  // useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { ChevronDownUnfilled } from 'assets/common';
import { ListSubheader, MenuItem, Select } from '@mui/material';
import CustomSearchInput from '../Components/RevampVersion/CustomSearchInput';
import useOptions from '../__LegacyDropdown__/Hooks/useOptions';
import { type DropdownSearchableProps } from '../DropdownMain.types';
import {
  classNames,
  getDataPropsFromRest,
  isNullOrUndefined,
} from 'utilities/Utils';
import { useDropdownSearchBaseStyles } from '../Dropdown.styles';
import useElementWidth from '../Hooks/useSelectWidth';

const Dropdown = (props: DropdownSearchableProps, ref) => {
  const {
    options,
    labelField = 'label',
    value,
    onChange,
    placeholder = 'Select',
    searchPlaceholder,
    renderValue,
    renderSelectAll,
    renderOptions,
    isMultiple,
    error,
    disabled,
    startAdornment,
    valueField = '',
    renderMaxTagError,
    customFooter,
    // isMenuTop = false,
    menuHeight = '48vh',
    wrapperClassNames = '',
    renderOption,
    ...rest
  } = props;
  const selectRef = useRef(null);
  const optionLabel = useCallback(
    item => (typeof item === 'string' ? item : item?.[labelField]),
    [labelField],
  );
  const valueLabel = useCallback(
    item => (typeof item === 'string' ? item : item?.[valueField]),
    [valueField],
  );

  const [displayedOptions, clearSearchText, TextFieldComponent] = useOptions(
    options,
    optionLabel,
  );

  const selectWidth = useElementWidth(selectRef);

  const classes = useDropdownSearchBaseStyles({
    isMultiple,
    selectWidth,
    menuHeight,
  });

  const isNoOptions = displayedOptions?.length === 0;

  const allOptionsMap = useMemo(() => {
    return options.reduce((acc, option) => {
      acc[valueField ? valueLabel(option) : optionLabel(option)] = option;
      return acc;
    }, {});
  }, [options]);

  const allValues = useMemo(() => {
    if (isNullOrUndefined(value)) {
      if (isMultiple) {
        return [];
      }
      return '';
    }
    return Array.isArray(value)
      ? value.map(val => (valueField ? valueLabel(val) : optionLabel(val)))
      : valueField
        ? valueLabel(value)
        : optionLabel(value);
  }, [value]);

  const handleOnChange = selectedValueRecieved => {
    if (isMultiple && Array.isArray(selectedValueRecieved)) {
      const selectedOptions = selectedValueRecieved.map(
        val => allOptionsMap[val],
      );
      onChange(selectedOptions);
    } else {
      onChange(allOptionsMap[selectedValueRecieved as string]);
    }
  };

  const dataTestIdProps = getDataPropsFromRest(rest);

  return (
    <div
      className={classNames(
        'relative w-full',
        disabled ? 'cursor-not-allowed bg-color-light' : 'bg-color-white',
        wrapperClassNames,
      )}
      ref={selectRef}
    >
      {(isMultiple && Array.isArray(value) && !value?.length) ||
      (!isMultiple && !value) ? (
        <p className={classes.placeholder}>{placeholder}</p>
        ) : null}
      <Select
        {...rest}
        variant="outlined"
        value={allValues}
        onChange={event => {
          const selectedValue: unknown | unknown[] = event.target.value;
          handleOnChange(selectedValue);
        }}
        multiple={isMultiple}
        MenuProps={{
          classes: {
            paper: classes.paper,
            list: classes.menuItem,
          },
          autoFocus: false,
          anchorEl: selectRef?.current,
          anchorReference: 'anchorEl',
        }}
        ref={ref}
        IconComponent={iconProps => (
          <ChevronDownUnfilled {...iconProps} color="#404040" />
        )}
        classes={{
          icon: classes.endAdornment,
          root: classes.selectRoot,
        }}
        autoComplete="off"
        onKeyDown={e => e.stopPropagation()}
        onKeyUpCapture={e => e.stopPropagation()}
        disabled={disabled}
        error={error}
        {...(renderValue
          ? {
            renderValue: selected => renderValue(selected, allOptionsMap),
          }
          : {})}
        onClose={clearSearchText}
        {...dataTestIdProps}
      >
        <CustomSearchInput
          placeholder={searchPlaceholder}
          size="small"
          isNoOptions={isNoOptions}
          isMultiple={isMultiple}
          startAdornment={startAdornment}
          TextFieldComponent={TextFieldComponent}
        >
          {isNoOptions ? null : renderSelectAll}
        </CustomSearchInput>
        {typeof renderOptions === 'function'
          ? renderOptions(
            displayedOptions,
            valueField ? valueLabel : optionLabel,
          )
          : !isNoOptions &&
            displayedOptions.map((option, index) => {
              if (typeof renderOption === 'function') {
                return (
                  <MenuItem
                    key={valueField ? valueLabel(option) : optionLabel(option)}
                    disableRipple
                    disableTouchRipple
                    disableGutters
                    value={
                      valueField ? valueLabel(option) : optionLabel(option)
                    }
                  >
                    {renderOption(
                      {
                        value: valueField
                          ? valueLabel(option)
                          : optionLabel(option),
                      },
                      option,
                      {
                        selected: allValues,
                        inputValue: optionLabel(option),
                        labelField,
                      },
                    )}
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  value={valueField ? valueLabel(option) : optionLabel(option)}
                  key={index}
                  disableRipple
                  disableTouchRipple
                  disableGutters
                >
                  {optionLabel(option)}
                </MenuItem>
              );
            })}
        {customFooter ? (
          <ListSubheader className="mt-2">{customFooter}</ListSubheader>
        ) : null}
      </Select>
      {renderMaxTagError ? renderMaxTagError : null}
    </div>
  );
};

const DropdownSearchBase = forwardRef(Dropdown);
export default DropdownSearchBase;
