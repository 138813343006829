import React, { forwardRef } from 'react';
import MuiIconButton from '@mui/material/IconButton';
import { getDataPropsFromRest } from 'utilities/Utils';
import { theme } from 'components/mui-theme';

import './IconButton.scss';

type IconButtonProps = {
  IconComp: React.ElementType;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  className?: string;
  variant?:
  | 'default'
  | 'contained-white'
  | 'contained-black'
  | 'contained-primary';
  color?: 'primary' | 'secondary' | 'default';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  id?: string;
  [key: string]: unknown; // used for data-* property only
};

function IconButtonComp(props: IconButtonProps, ref) {
  const {
    onClick,
    style = {},
    className = '',
    variant = 'default',
    color = 'primary',
    size = 'medium',
    disabled = false,
    id,
    IconComp,
    ...rest
  } = props;

  const dataProps = getDataPropsFromRest(rest);

  const icon = <IconComp fontSize={size !== 'medium' ? size : 'default'} />;
  const baseClass = `${className} ${size} ${variant}`;

  if (variant !== 'default') {
    style.boxShadow = theme.shadows[1];
  }

  return (
    <MuiIconButton
      id={id}
      ref={ref}
      style={style}
      classes={{ root: baseClass }}
      color={color}
      disabled={disabled}
      onClick={onClick}
      {...dataProps}
    >
      {icon}
    </MuiIconButton>
  );
}

const IconButton = forwardRef(IconButtonComp);

export default IconButton;
