import { useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import {
  UseSingleCalendarProps,
  UseSingleCalendarReturnProps,
} from '../Calendar.types';

export const useSingleCalendar = (
  props: UseSingleCalendarProps,
): UseSingleCalendarReturnProps => {
  const today = new Date();
  const {
    variant,
    initialDate,
    callbackAfterDateSelection,
    defaultNull = false,
  } = props;
  const [selectedDays, setSelectedDays] = useState<Date | null>(
    initialDate ? initialDate : defaultNull ? null : today,
  );
  const [month, setMonth] = useState<Date>();
  const handleDayClick = (day: Date, { disabled = false }: DayModifiers, _) => {
    if (disabled) return;
    setSelectedDays(day);
    callbackAfterDateSelection?.(day);
  };
  return {
    variant,
    selectedDays,
    setSelectedDays,
    handleDayClick,
    month,
    setMonth,
    initialMonth: selectedDays ?? today,
  };
};
