import { USER } from 'base/Url';
import axios from '../../base/APIClient';
import { apiWrapper } from 'base/APIClient';

const registerUser = paylaod => {
  const url = USER.REGISTRATION;

  return apiWrapper(axios.post(url, paylaod));
};

const loginUser = payload => {
  const url = USER.LOGIN;

  return apiWrapper(axios.post(url, payload));
};

const initiatePasswordReset = payload => {
  const url = USER.PASSWORD_RESET;

  return apiWrapper(axios.post(url, payload));
};

const onConfirmPasswordReset = (payload, isAlreadyLoggedIn) => {
  const url = isAlreadyLoggedIn
    ? USER.CHANGE_PASSWORD
    : USER.CONFIRM_PASSWORD_RESET;

  return apiWrapper(axios.post(url, payload));
};

const activateUserAccount = payload => {
  const url = USER.ACTIVATE_ACCOUNT;

  return apiWrapper(axios.post(url, payload));
};

const logoutUser = () => {
  const url = USER.LOGOUT;

  return apiWrapper(
    axios.get(url, {
      useLoader: true,
    }),
  );
};

export {
  registerUser,
  loginUser,
  initiatePasswordReset,
  onConfirmPasswordReset,
  activateUserAccount,
  logoutUser,
};
