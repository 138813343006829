import React, { forwardRef } from 'react';
import { default as MuiButton } from '@mui/material/Button';
import { alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataAttribute } from 'interfaces/types';
import { getDataPropsFromRest } from 'utilities/Utils';

import './Button.scss';

// Type defination for Button.tsx

type ButtonProps = DataAttribute &
Pick<
React.ComponentProps<typeof MuiButton>,
| 'children'
| 'onClick'
| 'variant'
| 'size'
| 'fullWidth'
| 'disabled'
| 'type'
> & {
  style?: React.CSSProperties;
  className?: string;
  IconComp?: React.ElementType;
  moveIconToEnd?: boolean;
  noPadding?: boolean;
  color?:
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // used for data-* property only,
  iconClass?: string;
};

// End of type defination for Button.tsx

const useStyles = makeStyles(theme => ({
  containedPrimary: {
    '&.Mui-disabled': {
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
      color: 'white',
    },
  },
  containedSecondary: {
    '&.Mui-disabled': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
      color: 'white',
    },
  },
  outlinedPrimary: {
    '&.Mui-disabled': {
      color: alpha(theme.palette.primary.main, 0.5),
    },
  },
  outlinedSecondary: {
    '&.Mui-disabled': {
      color: alpha(theme.palette.secondary.main, 0.5),
    },
  },
  textPrimary: {
    '&.Mui-disabled': {
      color: alpha(theme.palette.primary.main, 0.5),
    },
  },
  textSecondary: {
    '&.Mui-disabled': {
      color: alpha(theme.palette.secondary.main, 0.5),
    },
  },
}));

function ButtonComp(props: ButtonProps, ref?: React.Ref<HTMLButtonElement>) {
  const {
    children,
    onClick,
    style,
    className = '',
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    fullWidth = false,
    disabled = false,
    type = 'button',
    IconComp,
    moveIconToEnd = false,
    noPadding,
    iconClass = '',
    ...rest
  } = props;

  const dataProps = getDataPropsFromRest(rest);

  const {
    containedPrimary,
    containedSecondary,
    outlinedPrimary,
    outlinedSecondary,
    textPrimary,
    textSecondary,
  } = useStyles();

  let startIcon, endIcon;
  // eslint-disable-next-line
  if (IconComp != null) {
    const iconProps: { fontSize?: string } = {};
    if (size !== 'medium') {
      iconProps.fontSize = size;
    }
    const icon = <IconComp {...iconProps} className={iconClass} />;
    if (moveIconToEnd) {
      endIcon = icon;
    } else {
      startIcon = icon;
    }
  }

  const rootCls =
    variant === 'text' && noPadding ? `zero-padding ${className}` : className;

  return (
    <MuiButton
      variant={variant}
      style={style}
      ref={ref}
      classes={{
        root: rootCls,
        containedPrimary,
        containedSecondary,
        outlinedPrimary,
        outlinedSecondary,
        textPrimary,
        textSecondary,
      }}
      color={color}
      size={size}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      disableElevation
      {...dataProps}
    >
      {children}
    </MuiButton>
  );
}

const Button = forwardRef(ButtonComp);

export default Button;
