import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    '& [class*="MuiCircularProgress-colorPrimary"]': {
      color: '#fff',
    },
    '& [class*="MuiCircularProgress-colorSecondary"]': {
      color: '#3543BF',
    },
    '& [class*="MuiCircularProgress-colorInfo"]': {
      color: '#C2C7EC',
    },
    '& [class*="MuiCircularProgress-colorSuccess"]': {
      color: '#2e7d32',
    },
  },
}));

export type CircularProgressBarProps = {
  color?: CircularProgressProps['color'];
  size?: CircularProgressProps['size'];
  value?: CircularProgressProps['value'];
  max?: number;
  sx?: CircularProgressProps['sx'];
  variant?: CircularProgressProps['variant'];
  thickness?: CircularProgressProps['thickness'];
  children?: React.ReactNode; // Allow children to be passed
};

function CircularProgressBar(props: CircularProgressBarProps) {
  const { color, size, variant, value, thickness, sx, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        color={color}
        size={size}
        variant={variant}
        value={value}
        thickness={thickness}
        sx={sx}
      />
      {children}
    </div>
  );
}

export default CircularProgressBar;
