export const bulkUploadTabsConfig = (
  allCount,
  successCount,
  failureCount,
  wipCount,
) => [
  {
    label: 'All',
    value: 'all',
    count: allCount,
  },
  {
    label: 'Success',
    value: 'success',
    count: successCount,
  },
  {
    label: 'Under Verification',
    value: 'in_wip',
    count: wipCount,
  },
  {
    label: 'Failed',
    value: 'failed',
    count: failureCount,
  },
];

export const acceptedInvoicesToUpload = [
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
];
