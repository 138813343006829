import { goibiboIcon, makeMyTripIcon } from 'assets/common';

export const RESULTS_PER_PAGE = 20;
export const ACCEPTED_TYPES =
  '.jpeg, .jpg, .tiff, .png, .webp, .img, .bmp, .jif, .tif';

export const getVendorTabsConfig = STRINGS => {
  return [
    {
      label: STRINGS.LABELS.GOIBIBO,
      value: 'goibibo',
      imgUrl: goibiboIcon,
    },
    {
      label: STRINGS.LABELS.MAKEMYTRIP,
      value: 'makemytrip',
      imgUrl: makeMyTripIcon,
    },
  ];
};

export const getGIReviewTabs = STRINGS => {
  return [
    {
      label: STRINGS.ALL_REVIEWS,
      value: 'all',
      count: null,
    },
    {
      label: STRINGS.NOT_REPLIED,
      value: 'replynow',
      count: null,
    },
    {
      label: STRINGS.REPLIED,
      value: 'replied',
      count: null,
    },
    {
      label: STRINGS.BOOKING_REVIEWS,
      value: 'booking',
      count: null,
    },
    {
      label: STRINGS.NON_BOOKING_REVIEWS,
      value: 'nonbooking',
      count: null,
    },
    {
      label: STRINGS.PHOTO_REVIEWS,
      value: 'photoreviews',
      count: null,
    },
  ];
};

export const getMMTReviewTabs = STRINGS => {
  return [
    {
      label: STRINGS.ALL_REVIEWS,
      value: 'all',
      count: null,
    },
    {
      label: STRINGS.NOT_REPLIED,
      value: 'replynow',
      count: null,
    },
    {
      label: STRINGS.REPLIED,
      value: 'replied',
      count: null,
    },
    {
      label: STRINGS.PHOTO_REVIEWS,
      value: 'photoreviews',
      count: null,
    },
  ];
};

export const getQnATabs = STRINGS => {
  return [
    {
      label: STRINGS.ALL_QUESTIONS,
      value: 'qna',
    },
    {
      label: STRINGS.HOTEL_QUESTIONS,
      value: 'hotel',
    },
    {
      label: STRINGS.CITY_LOCALITY_QUESTIONS,
      value: 'citylocality',
    },
    {
      label: STRINGS.UNANSWERED_QUESTIONS,
      value: 'unanswered',
    },
  ];
};

export const ActiveTabLabel = {
  insights: 'insights_tab',
  reviews: 'ratings_reviews_tab',
};

export const sources = {
  MMT: 'MMT',
  GI: 'GI',
} as const;

export const subtabs = {
  replynow: 'replynow',
  replied: 'replied',
  booking: 'booking',
  nonbooking: 'nonbooking',
  photoreviews: 'photoreviews',
} as const;

export const vendorTabs = {
  makemytrip: 'makemytrip',
  goibibo: 'goibibo',
};
