import { Tooltip as MuiTooltip } from '@mui/material';
import { Tooltip, Typography } from 'components/latest-core';
import { VariantType } from 'components/latest-core/Typography/Typography';
import { useEffect, useRef, useState } from 'react';
import { PaletteColorsType } from '../mui-theme';

export type ToolTipPlacementType = Pick<
React.ComponentProps<typeof MuiTooltip>,
'placement'
>['placement'];

interface IProps {
  children: React.ReactNode;
  toolTipContent?: React.ReactNode;
  className?: string;
  fb?: boolean;
  bgBlack?: boolean;
  placement?: ToolTipPlacementType;
  typographyVariant?: VariantType;
  themeColor?: PaletteColorsType;
  onClick?: () => void;
  showAboveModal?: boolean;
}

const WithTooltipTypography = (props: IProps) => {
  const {
    children,
    toolTipContent = null,
    className = '',
    fb = false,
    bgBlack = false,
    typographyVariant = 'body1',
    placement = 'top',
    themeColor = 'text.primary',
    onClick = () => {},
    showAboveModal = false,
  } = props;

  const ref = useRef<HTMLParagraphElement>(null);
  const [isOverflow, setisOverflow] = useState(false);

  //this is to show tooltip only on elemnts where content is overflowing
  useEffect(() => {
    if (ref?.current) {
      const _isOverflow = ref.current.scrollWidth > ref.current.clientWidth;
      setisOverflow(_isOverflow);
    }
  }, [ref?.current?.scrollWidth]);

  const renderContent = () => {
    return isOverflow ? (
      toolTipContent ? (
        <Typography fb={fb} themeColor={themeColor} variant={typographyVariant}>
          {toolTipContent}
        </Typography>
      ) : (
        children
      )
    ) : (
      ''
    );
  };
  return (
    <Tooltip
      showAboveModal={showAboveModal}
      placement={placement as ToolTipPlacementType}
      content={renderContent()}
      bgBlack={bgBlack}
    >
      <Typography
        className={`overflow-hidden whitespace-nowrap ${
          isOverflow ? 'text-ellipsis' : ''
        }  ${className}`}
        ref={ref}
        fb={fb}
        themeColor={themeColor}
        variant={typographyVariant}
        onClick={onClick}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default WithTooltipTypography;
