import makeStyles from '@mui/styles/makeStyles';
import { DropdownMainProps } from './DropdownMain.types';

const useDropdownBaseStyles = makeStyles(theme => ({
  autoCompleteRoot: {
    width: '100%',
  },
  root: (props: DropdownMainProps) => ({
    width: '100%',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    backgroundColor: props.disabled
      ? `${theme.palette.backgrounds.light || '#F8F8F8'}`
      : `${theme.palette.common.white || '#fff'}`,

    // for active border color
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.text.primary} !important`,
      border: '1px solid',
    },

    '& > div:first-child': {
      padding: '1px 1px 1px 8px',
      backgroundColor: 'inherit',

      '& input': {
        color: `${theme.palette.text.primary || '#404040'} !important`,
        fontSize: '16px',
      },
      '& .Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26) !important',
        fontSize: '16px',
      },
    },
    '& .Mui-disabled:disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      cursor: 'not-allowed !important',
    },

    //not faded classes
    '& .Mui-disabled': {
      color: `${theme.palette.text.primary || '#404040'}`, // Keep the text color
      '-webkit-text-fill-color': `${theme.palette.text.primary || '#404040'}`, // For some browsers
      opacity: 1, // Prevent fading
      cursor: props.disabled ? 'not-allowed' : 'pointer', // add pointer effect for normal dropdown case
      caretColor: 'transparent', // hide the caret color
    },
  }),
  paper: {
    backgroundColor: `${theme.palette.common.white || '#FFF'}`,
    padding: '0px 4px 4px 4px',
    boxShadow: '0px 1px 5px rgba(1, 16, 34, 0.1)',
    borderRadius: '4px',
    border: '1px solid #D2D1D1',
    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: `${theme.palette.backgrounds.primary || '#EFF1FF'}`,
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: 'transparent',
      color: `${theme.palette.primary.main || '#3543BF'}`,
    },
    '& li[class^="MuiAutocomplete-option"]': {
      fontSize: '14px',
      fontFamily: 'Roboto',
      lineHeight: '20px',
      padding: '8px 12px',
      marginBottom: '2px',
      backgroundColor: `${theme.palette.common.white || '#FFF'}`,
    },
    '& div[class*="MuiAutocomplete-groupLabel"]': {
      fontSize: '12px !important',
    },
    '& span[class*="MuiCheckbox-root"]': () => ({
      backgroundColor: `${theme.palette.common.white || '#FFF'}`,
    }),
    '& li[data-focus="true"]': {
      backgroundColor: `${theme.palette.backgrounds.primary || '#EFF1FF'}`,
      '& *': {
        color: `${theme.palette.primary.main || '#3543BF'}`,
      },
      '& svg': {
        backgroundColor: 'transparent',
      },
      '& span[class*="MuiCheckbox-root"]': {
        backgroundColor: () =>
          `${theme.palette.backgrounds.light || '#F8F8F8'}`,
        '&.Mui-checked': {
          backgroundColor: `${theme.palette.backgrounds.primary || '#EFEFEF'}`,
        },
      },
    },
    '& li[aria-selected="true"]': {
      fontWeight: 500,
      color: `${theme.palette.primary.main || '#3543BF'}!important`,
      '& *': {
        color: `${theme.palette.primary.main || '#3543BF'}!important`,
      },
    },
  },
  endAdornment: {
    top: 'auto',
  },
  buttonVariant: {
    color: `${theme.palette.backgrounds.white} !important`,
    '& .MuiInputBase-root': {
      width: '100% !important',
      background: '#3543BF !important',
      '& .MuiInputBase-input': {
        color: `${theme.palette.backgrounds.white} !important`,
        '-webkit-text-fill-color': `${theme.palette.backgrounds.white} !important`,
        fontWeight: '600 !important',
        fontSize: '16px !important',
        borderRight: `1px solid ${theme.palette.backgrounds.white}`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  noBorder: {
    '& fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
      border: 'none',
    },
    '& .Mui-disabled': {
      color: `${theme.palette.primary.main} !important`, // Keep the text color
      '-webkit-text-fill-color': `${
        theme.palette.primary.main || '#3543BF'
      } !important`, // For some browsers
      opacity: 1, // Prevent fading
    },
    '& input': {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: '500',
      padding: '4px 0px 4px !important',
    },
  },
  small: {
    width: '182px',
  },
}));

const useInlinedDropdownBaseStyles = makeStyles(() => ({
  autoCompleteRoot: {
    minWidth: '118px',
    width: '100%',
  },
  endAdornment: {
    top: 'auto',
    right: '4px !important',
    pointerEvents: 'none',
  },
  root: () => ({
    width: '100%',
    minWidth: '118px',
    '& > div:first-child': {
      // to reduce the end endormnent padding
      '& input': {
        fontSize: '14px',
        lineHeight: '20px',
        padding: '2px 4px !important',
        textAlign: 'center',
      },
      '& .Mui-disabled': {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  }),
  //for no outline variant
  noOutline: {
    '& fieldset[class*="MuiOutlinedInput-notchedOutline"]': {
      border: 'none',
    },
    '& input': {
      padding: '2px 4px 2px 2px !important',
    },
  },
}));

const useMultiSelectDropdownBaseStyles = makeStyles(theme => ({
  checkbox: {
    marginRight: '12.5px',
    backgroundColor: `${theme.palette.backgrounds.light || '#F8F8F8'}`,
    color: `${theme.palette.primary.main || '#3543BF'}`,
    padding: '0px',
  },
}));

type StyleProps = {
  isMultiple?: boolean;
  selectWidth?: number;
  menuHeight: string;
};

const useDropdownSearchBaseStyles = makeStyles(theme => ({
  selectRoot: {
    position: 'relative',
    width: '100%',
    height: '40px',
    overflow: 'hidden',

    // for active border color
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.text.primary} !important`,
      border: '1px solid',
    },

    //for disabled
    '& .Mui-disabled': {
      cursor: 'not-allowed !important',
      color: `${theme.palette.text.primary}`,
      '-webkit-text-fill-color': `${theme.palette.text.primary}`,
    },
  },
  //dropdown paper styles
  paper: (props: StyleProps) => ({
    maxHeight: props.menuHeight,
    maxWidth: `${props.selectWidth}px`,
    boxShadow: 'none',
    borderRadius: '0px 0px 4px 4px',
    border: '1px solid #D2D1D1',
    //to remove open and close animation
    transition: 'none !important',
    '& .MuiList-root': {
      paddingTop: '0',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    //select all wrapper styles
    '& .MuiListSubheader-root': {
      paddingLeft: '4px',
    },
    //list item uncurved border
    '& .MuiButtonBase-root': {
      borderRadius: '0',
    },
  }),
  endAdornment: {
    top: 'auto',
    color: `${theme.palette.text.primary} !important`,
  },
  // placeholder styles
  placeholder: {
    position: 'absolute',
    top: '50%',
    left: '12px',
    transform: 'translateY(-50%)',
    color: theme.palette.text.disabled,
    fontSize: '16px',
    lineHeight: '20px',
  },
  menuItem: (props: StyleProps) => ({
    //list items font and hover styles
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      lineHeight: '20px',
      padding: props?.isMultiple ? '8px 12px 8px 20px' : '8px 12px',
      marginLeft: '4px',
      whiteSpace: 'normal',
      '&:hover': {
        backgroundColor: `${theme.palette.backgrounds.primary} !important`,
      },
    },
    //select all text and hover styles
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: `${theme.palette.backgrounds.primary} !important`,
      },
    },

    // selected items styles
    '& [aria-selected="true"]': {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: 'transparent',
    },

    //checkbox styles
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },

    //select all wrapper styles
    '& .select-all-wrapper': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '14px',
      lineHeight: '20px',
      margin: '0 4px',
      padding: '0 12px 0 8px',
      '&:hover': {
        backgroundColor: `${theme.palette.backgrounds.primary} !important`,
      },
    },
  }),
}));

export {
  useInlinedDropdownBaseStyles,
  useMultiSelectDropdownBaseStyles,
  useDropdownSearchBaseStyles,
  useDropdownBaseStyles,
};
