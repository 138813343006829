import { OutlinedInput } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as CommonIcons from 'assets/common';
import {
  classNames,
  getDataPropsFromRest,
  isNullOrUndefined,
  noop,
} from 'utilities/Utils';
import React, { ReactNode, useEffect } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import { integerRegex } from 'utilities/allRegex';
import { GenericFunction } from 'interfaces/types';
import { Button } from 'components/latest-core';

export enum COUNTER_ACTION {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  CHANGE = 'change',
}

type CounterProps = {
  value?: number;
  changeCounter: (value: number, name: string, type?: COUNTER_ACTION) => void;
  maxLimit?: number;
  minLimit?: number;
  name?: string;
  allowEmptyString?: boolean;
  startAdornment?: ReactNode | null;
  endAdornment?: ReactNode | null;
  className?: string;
  disabled?: boolean;
  showBinIconOnLowerLimit?: boolean;
  binIconClickHandlerCb?: GenericFunction;
};

type Disabled = {
  next: boolean;
  previous: boolean;
};

type ControlledCounterProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = Omit<CounterProps, 'changeCounter'> & {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: number;
  isRequired?: boolean;
  customValidator?: RegisterOptions<T, TName>['validate'];
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '120px',
    height: '40px',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& [class*="MuiInputBase-root"]': {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: 'none',
      height: '40px',
    },
    '& input[class*="MuiOutlinedInput-input"]': {
      padding: '4px',
      fontSize: '16px',
      maxWidth: '48px',
      textAlign: 'center',
    },
    '& fieldset': {
      border: 'none',
      maxWidth: '48px',
      textAlign: 'center',
      overFlow: 'hidden',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      '&::-webkit-inner-spin-button,::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  actions: {
    cursor: 'pointer',
  },
  disabled: {
    opacity: '0.3',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '0px 8px',
    width: '40px',
    height: '40px',
  },
}));

const getDisabledState = (props: CounterProps): Disabled => {
  const { value = 0, maxLimit = Infinity, minLimit = 0, disabled } = props;
  return {
    next: disabled || value >= maxLimit,
    previous: disabled || value <= minLimit,
  };
};

const CountersComp = (props: CounterProps, ref?: React.Ref<unknown>) => {
  const {
    value = 0,
    changeCounter,
    maxLimit = Infinity,
    minLimit = 0,
    startAdornment = null,
    endAdornment = null,
    name = '',
    allowEmptyString = false,
    className = '',
    disabled = false,
    showBinIconOnLowerLimit = false,
    binIconClickHandlerCb = noop,
    ...rest
  } = props;
  const [isActionBtnPairDisabled, setActionBtnPairDisabled] =
    React.useState<Disabled>(getDisabledState(props));

  const dataTestIdProps = getDataPropsFromRest(rest);

  useEffect(() => {
    setActionBtnPairDisabled(getDisabledState(props));
  }, [value, maxLimit, minLimit, disabled]);

  const handleChange = (_value, type?: COUNTER_ACTION) => {
    let overrideValue;
    // allow only integers
    if (!integerRegex.test(_value)) {
      return;
    }
    if (allowEmptyString) {
      overrideValue = _value >= maxLimit ? maxLimit : null;
    } else {
      overrideValue =
        _value >= maxLimit ? maxLimit : _value <= minLimit ? minLimit : null;
    }
    if (!isNullOrUndefined(overrideValue))
      changeCounter(overrideValue, name, type);
    else changeCounter(_value, name, type);
  };

  const formatValue = _value => String(_value).padStart(2, '0');

  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)} {...dataTestIdProps}>
      {showBinIconOnLowerLimit && value === minLimit ? (
        <Button
          className={classes.button}
          variant="text"
          onClick={binIconClickHandlerCb}
          disabled={disabled}
        >
          <CommonIcons.Delete
            width={14}
            height={14}
            className={classNames(
              classes.actions,
              disabled && classes.disabled,
            )}
          />
        </Button>
      ) : (
        <Button
          className={classes.button}
          variant="text"
          disabled={isActionBtnPairDisabled.previous}
          onClick={() =>
            handleChange(Number(value) - 1, COUNTER_ACTION.DECREASE)
          }
        >
          <CommonIcons.Substract
            width={14}
            height={14}
            className={classNames(
              classes.actions,
              isActionBtnPairDisabled.previous && classes.disabled,
            )}
          />
        </Button>
      )}
      <OutlinedInput
        inputRef={ref}
        value={
          allowEmptyString
            ? value
            : formatValue(value > minLimit ? value : minLimit)
        }
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        onChange={e => {
          handleChange(
            e.target.value ? Number(e.target.value) : '',
            COUNTER_ACTION.CHANGE,
          );
        }}
        disabled={disabled}
        className="rounded-0"
      />
      <Button
        className={classes.button}
        variant="text"
        disabled={isActionBtnPairDisabled.next}
        onClick={() => handleChange(Number(value) + 1, COUNTER_ACTION.INCREASE)}
      >
        <CommonIcons.Addition
          width={14}
          height={14}
          className={classNames(
            classes.actions,
            isActionBtnPairDisabled.next && classes.disabled,
          )}
        />
      </Button>
    </div>
  );
};

const Counters = React.forwardRef(CountersComp);

const ControlledCounter = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(
    props: ControlledCounterProps<T, TName>,
  ) => {
  const {
    name,
    control,
    defaultValue = 0,
    isRequired = false,
    customValidator,
    ...rest
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };

  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const { minLimit, maxLimit } = rest;
  if (!isNullOrUndefined(minLimit)) {
    validations.min = {
      value: minLimit,
      message: `The value cannot be lesser than ${minLimit}`,
    };
  }
  if (!isNullOrUndefined(maxLimit)) {
    validations.max = {
      value: maxLimit,
      message: `The value cannot be greater than ${maxLimit}`,
    };
  }

  const {
    field: { ref, value, onChange },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });

  return (
    <Counters ref={ref} value={value} changeCounter={onChange} {...rest} />
  );
};

export { ControlledCounter, Counters };
