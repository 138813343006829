import { makeStyles } from '@mui/styles';
import { ModalSize } from './Modal.constant';
import { ModalSizeType } from './Modal.types';

type ModalStylesPropTypes = {
  size: ModalSizeType;
  hideFooterDivider: boolean;
  hideHeaderDivider: boolean;
  showScrollbar: boolean;
};

const getModalContainerWidth = (size: ModalSizeType) => {
  switch (size) {
    case ModalSize.SMALL:
      return '380px';
    case ModalSize.MEDIUM:
      return '500px';
    case ModalSize.LARGE:
      return '768px';
    default:
      return '100%';
  }
};

const getModalContainerHeight = (size: ModalSizeType) => {
  switch (size) {
    case ModalSize.SMALL:
      return '400px';
    case ModalSize.MEDIUM:
      return '600px';
    case ModalSize.LARGE:
      return '800px';
    default:
      return '100%';
  }
};

export const useModalStyles = makeStyles(theme => ({
  root: {
    zIndex: '1200',
  },
  paper: {
    maxWidth: '100%',
  },
  backdrop: {
    backgroundColor: 'theme.palette.backgrounds.overlay',
  },
  modalContainer: (props: ModalStylesPropTypes) => ({
    display: 'flex',
    flexDirection: 'column',
    width: getModalContainerWidth(props.size),
    maxHeight: getModalContainerHeight(props.size),
    overflow: 'hidden',
  }),
  headerContainer: (props: ModalStylesPropTypes) => ({
    position: 'sticky',
    top: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    padding: '16px',
    borderBottom: !props.hideHeaderDivider
      ? `1px solid ${theme.palette.divider}`
      : 'none',
    backgroundColor: theme.palette.background.paper,
  }),
  headerChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  headerTitleContainer: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },
  headerSubTitleContainer: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },
  headerCrossIconContainer: {
    cursor: 'pointer',
  },
  modalChidrenContainer: (props: ModalStylesPropTypes) => ({
    padding: '16px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    '&::-webkit-scrollbar': props.showScrollbar
      ? {
        width: '12px',
        backgroundColor: theme.palette.backgrounds.light,
      }
      : null,
    '&::-webkit-scrollbar-thumb': props.showScrollbar
      ? {
        backgroundColor: theme.palette.backgrounds.divider,
      }
      : null,
  }),
  footerContainer: (props: ModalStylesPropTypes) => ({
    position: 'sticky',
    bottom: '0',
    right: '0',
    display: 'flex',
    gap: '16px',
    justifyContent: 'end',
    padding: '10px',
    borderTop: !props.hideFooterDivider
      ? `1px solid ${theme.palette.divider}`
      : '',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
  }),
  footerErrorMsgContainer: {
    color: theme.palette.error.dark,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    marginRight: 'auto',
    wordWrap: 'break-word',
    paddingLeft: '6px',
  },
  footerPrimaryBtnContainer: {
    height: '40px',
    padding: '8px 16px !important',
    flexShrink: 0,
  },
  footerSecondaryBtnContainer: {
    height: '40px',
    padding: '8px 16px !important',
    flexShrink: 0,
  },
}));
