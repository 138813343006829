import { InfoQuestionIcon } from 'assets/common';
import { Tooltip, Typography } from 'components/latest-core';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import { isNullOrUndefined } from 'utilities/Utils';

type CardType = {
  id: string;
  shoutOut: string;
  trend: string;
  last7Days: string;
  to: string;
  isLoading: boolean;
  isError: boolean;
  boost: boolean;
  loaded_at: string;
};
type PerformanceHeaderProps = {
  performanceData?: CardType[];
};

const PerformanceHeader = ({ performanceData }: PerformanceHeaderProps) => {
  const [STRINGS] = useLangStrings<'Dashboard'>('Dashboard');

  const [forceUpdate, setForceForceUpdate] = useState<string>('now');
  const loadedAt = useMemo(() => {
    return Object.values(performanceData).find(
      el => !isNullOrUndefined(el.isLoading) && !el.isError,
    )?.loaded_at;
  }, [performanceData]);

  useEffect(() => {
    const timeId = setInterval(() => {
      setForceForceUpdate(`${moment(loadedAt).fromNow()}`);
    }, 10000);
    return () => {
      clearInterval(timeId);
    };
  }, [loadedAt]);

  return (
    <>
      <div className={`d-flex items-center ${!loadedAt ? 'mb-4' : ''}`}>
        <Typography variant="h1" className="mb-1 mr-2">
          {STRINGS.PERFORMANCE_HEADER.HEADER}
        </Typography>
        <Tooltip
          content={STRINGS.PERFORMANCE_HEADER.TOOLTIP_INFO}
          placement="bottom"
          bgBlack
        >
          <img src={InfoQuestionIcon} alt="help Icon" className="w-6 h-6" />
        </Tooltip>
      </div>

      {loadedAt && (
        <Typography
          variant="sectionHeading"
          className="mb-6 !leading-[0.24px] !normal-case block"
        >
          {`${STRINGS.PERFORMANCE_HEADER.LAST_UPDATED} ${forceUpdate}`}
        </Typography>
      )}
    </>
  );
};

export default PerformanceHeader;
