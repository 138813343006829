import { FormElement, Input } from 'components/latest-core/';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { HotelContext } from 'utilities/Context';
import { useLangStrings } from 'utilities/CustomHooks';
import {
  defaultPromotionName,
  newPromotionTypes,
  offerTypes,
} from '../Promotions.constants';
import { isNullOrUndefined } from 'utilities/Utils';

const getPromotionName = (
  promotionData,
  configureOfferState,
  base_currency,
) => {
  let displayLabel = '';
  const { promotionType } = promotionData;
  const { offerList, bookNights, freeNights, offerType } = configureOfferState;

  const fixedDiscount = `${offerList[0].allFixedDiscount}`;
  const percentDiscount = `${offerList[0].allPercentDiscount}%`;
  switch (promotionType) {
    case newPromotionTypes.DATE:
      displayLabel =
        offerType === offerTypes.fixed
          ? defaultPromotionName.DATE + fixedDiscount + base_currency
          : defaultPromotionName.DATE + percentDiscount;
      break;

    case newPromotionTypes.DAY:
      displayLabel =
        offerType === offerTypes.fixed
          ? defaultPromotionName.DAY + fixedDiscount + base_currency
          : defaultPromotionName.DAY + percentDiscount;

      break;

    case newPromotionTypes.LMD:
      if (!isNullOrUndefined(offerList[0].bookWithinDays)) {
        displayLabel =
          offerType === offerTypes.fixed
            ? `${defaultPromotionName.LMD + fixedDiscount + base_currency}-${
              offerList[0].bookWithinDays?.value
            }days`
            : `${defaultPromotionName.LMD + percentDiscount}-${
              offerList[0].bookWithinDays?.value
            }days`;
      }
      break;

    case newPromotionTypes.EBD:
      if (!isNullOrUndefined(offerList[0].bookWithinDays)) {
        displayLabel =
          offerType === offerTypes.fixed
            ? `${defaultPromotionName.EBD + fixedDiscount + base_currency}-${
              offerList[0].bookWithinDays?.value
            }days`
            : `${defaultPromotionName.EBD + percentDiscount}-${
              offerList[0].bookWithinDays?.value
            }days`;
      }

      break;

    case newPromotionTypes.LOS:
      if (!isNullOrUndefined(offerList[0].bookWithinDays)) {
        displayLabel =
          offerType === offerTypes.fixed
            ? `${defaultPromotionName.LOS + fixedDiscount + base_currency}-${
              offerList[0].bookWithinDays?.value
            }days`
            : `${defaultPromotionName.LOS + percentDiscount}-${
              offerList[0].bookWithinDays?.value
            }days`;
      }
      break;

    case newPromotionTypes.FND:
      displayLabel = `${defaultPromotionName.FND}${bookNights}on${freeNights}`;
      break;

    default:
      displayLabel = '';
      break;
  }

  return displayLabel;
};
const PromotionName = props => {
  const { promotionNameState: pNS } = props;
  const [STRINGS] = useLangStrings('Promotions');

  return (
    <tr>
      <td>{STRINGS.CREATE_SECTIONS.PNAME}</td>
      <td>
        <FormElement title={STRINGS.CREATE_SECTIONS.PNAME} isRequired>
          <Input
            value={pNS.promotionName}
            onChange={pNS.changeHandler}
            type="text"
            className="!w-[250px]"
          />
        </FormElement>
      </td>
    </tr>
  );
};

export function usePromotionName(
  promotionData,
  configureOfferState,
  isEditFlow,
  showTemplates = false,
) {
  const {
    currentHotel: { base_currency: baseCurreny },
  } = useContext(HotelContext);

  const promotionDefaultName = getPromotionName(
    promotionData,
    configureOfferState,
    baseCurreny,
  );

  const [promotionName, setPromotionName] = useState(
    promotionData?.name ?? promotionDefaultName,
  );
  const isPromotionNameEdited = useRef(!!promotionData.name);

  const changeHandler = value => {
    isPromotionNameEdited.current = true;
    setPromotionName(value);
  };

  useEffect(() => {
    if (showTemplates && !isEditFlow) {
      isPromotionNameEdited.current = false;
      setPromotionName(promotionDefaultName);
    }
  }, [showTemplates]);
  useEffect(() => {
    const hasUpdated = isPromotionNameEdited.current;
    if (!hasUpdated && !isEditFlow) {
      setPromotionName(promotionDefaultName);
    }
  }, [promotionDefaultName]);

  return {
    promotionName,
    changeHandler,
  };
}

export default React.memo(PromotionName, (prevProps, nextProps) => {
  return (
    prevProps.promotionNameState.promotionName ===
    nextProps.promotionNameState.promotionName
  );
});
