import { roomRateMapObjects } from 'app/hotel/HotelAPIClient';
import { CurrentHotel } from 'interfaces/HotelContextInterface';
import { ISTRINGS } from 'interfaces/Strings';
import queryString from 'query-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FieldValues,
  useFormContext,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  fetchContentScorePersuausion,
  fetchIPUserEligibility,
} from 'shared_logic/SharedGraphClient';
import { fetchDocument } from 'shared_logic/SharedAPIClient';
import { processInternalCalSyncData, showMessage } from 'utilities/Utils';
import { AppContext, HotelContext } from './Context';

export function useClickOutside(
  ref: React.MutableRefObject<HTMLElement>,
  isVisible: boolean,
  onHide: (v?: boolean, e?: Event) => void,
) {
  const eventHandler = useCallback(
    e => {
      if (ref.current && !ref.current.contains(e.target)) {
        onHide(false, e);
      }
    },
    [onHide, ref],
  );
  useEffect(() => {
    if (isVisible) {
      document.addEventListener('click', eventHandler);
    }
    return () => {
      document.removeEventListener('click', eventHandler);
    };
  }, [isVisible, eventHandler]);
}

export function useToggleState(
  init?: boolean,
): [isTrue: boolean, onClick: (v: boolean) => void] {
  const [isTrue, setIsTrue] = useState(init);
  const onClick = val => {
    if (isTrue === val) {
      setIsTrue(isTrue);
    } else {
      setIsTrue(val);
    }
  };

  return [isTrue, onClick];
}

//FUTURE NOTE: Update Storybook mocks if any changes
export function useFlagState(
  initialVal: boolean,
): [flag: boolean, set: () => void, unset: () => void, toggle: () => void] {
  const [flag, setFlag] = useState(initialVal);
  const set = useCallback(() => {
    setFlag(true);
  }, []);
  const unset = useCallback(() => {
    setFlag(false);
  }, []);
  const toggle = useCallback(() => {
    setFlag(c => !c);
  }, []);
  return [flag, set, unset, toggle];
}

export function useLangStrings<T extends keyof ISTRINGS>(
  moduleName: T,
): [ISTRINGS[T], ISTRINGS['Common']] {
  const { strings } = useContext(AppContext);
  return [strings[moduleName] as ISTRINGS[T], strings.Common];
}

export function useCurrentHotel(): [
  hotelcode: string,
  currentHotel: CurrentHotel,
] {
  const { currentHotel } = useContext(HotelContext);
  return [currentHotel?.hotelcode, currentHotel];
}

export const useCombinedRoomRateplan = (combiningGrp = false) => {
  const {
    currentHotel: { hotelcode },
    roomRatePlan,
  } = useContext(HotelContext);

  const {
    hourlyStaysRateplanDetails,
    hourlyStaysRoomDetails,
    roomDetails: nonHourlyStaysRoomDetails,
    rateplanDetails: nonHourlyStaysRateplanDetails,
    grpRateplans,
  } = roomRatePlan;

  const { roomDetails, rateplanDetails, roomRateMapObj } = useMemo(() => {
    const _roomDetails = [
      ...hourlyStaysRoomDetails,
      ...nonHourlyStaysRoomDetails,
    ];
    const _rateplanDetails = {
      ...hourlyStaysRateplanDetails,
      ...nonHourlyStaysRateplanDetails,
    };
    if (combiningGrp) {
      Object.keys(_rateplanDetails).forEach(roomKey => {
        const roomRateplans = _rateplanDetails[roomKey] ?? [];
        if (grpRateplans[roomKey]) {
          _rateplanDetails[roomKey] = [
            ...roomRateplans,
            ...grpRateplans[roomKey],
          ];
        }
      });
    }

    const _roomRateMapObj = roomRateMapObjects(_roomDetails, _rateplanDetails);
    return {
      roomDetails: _roomDetails,
      rateplanDetails: _rateplanDetails,
      roomRateMapObj: _roomRateMapObj,
    };
  }, [hotelcode, roomRatePlan]);

  return {
    hourlyStaysRateplanDetails,
    hourlyStaysRoomDetails,
    roomDetails,
    rateplanDetails,
    grpRateplans,
    ...roomRateMapObj,
  };
};

export function useHotelChange(
  fn: (hotelCode?: string) => void,
  extraDeps: React.DependencyList = [],
) {
  const {
    currentHotel: { hotelcode },
  } = useContext(HotelContext);

  useEffect(fn.bind(null, hotelcode), [hotelcode, ...extraDeps]);
}

export function useAccordion(defaultCode: string | number) {
  const [accordion, setAccordion] = useState(defaultCode);

  const toggleAccordion = (code: string | number) => {
    if (accordion === code) {
      setAccordion(null);
    } else {
      setAccordion(code);
    }
  };

  return [accordion, toggleAccordion, setAccordion];
}

export function useMountSkipEffect(cb: Function, deps = []) {
  const isMountDone = useRef(false);

  useEffect(() => {
    if (isMountDone.current) {
      cb();
    } else {
      isMountDone.current = true;
    }
  }, deps);
}

export function wrappedStrings(
  Component: React.ComponentClass | React.FunctionComponent,
  moduleName: keyof ISTRINGS,
) {
  const StringsWrappedComponent = function (props) {
    const [STRINGS, COMMON_STRINGS] = useLangStrings(moduleName);
    return (
      <Component {...props} STRINGS={STRINGS} COMMON_STRINGS={COMMON_STRINGS} />
    );
  };
  return StringsWrappedComponent;
}

export function useIntersectionObserver(
  targetCls,
  callback,
  deps = [],
  options = null,
) {
  useEffect(() => {
    const elem = document.querySelectorAll(`.${targetCls}`);
    const observer = new IntersectionObserver(callback, options);
    if (elem.length) {
      elem.forEach(el => {
        observer.observe(el);
      });
    }
    return () => {
      elem.forEach(el => {
        observer.unobserve(el);
      });
    };
  }, [...deps]);
}
// copied from https://usehooks.com/useLocalStorage/
export function useStateWithLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      const initialVal = item ? JSON.parse(item) : initialValue;
      if (!item) {
        window.localStorage.setItem(key, JSON.stringify(initialVal));
      }
      return initialVal;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  };

  const removeValue = () => {
    setStoredValue(null);
    window.localStorage.removeItem(key);
  };
  return [storedValue, setValue, removeValue] as const;
}

export const useResizeObserver = (
  element: React.RefObject<HTMLDivElement>,
  useSizeObserver: boolean,
) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (element?.current && useSizeObserver && window.ResizeObserver) {
      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          setSize({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        });
      });
      resizeObserver.observe(element.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, [element]);

  return size;
};

export function useInterval(callback: () => void, delay: number, deps = []) {
  useEffect(() => {
    const id = setInterval(callback, delay);
    return () => clearInterval(id);
  }, [delay, ...deps]);
}

export const useScrollAndFocusToCurrentElement = (
  element: React.MutableRefObject<HTMLElement>,
) => {
  const startScrollAndFocus = () => {
    if (element?.current) {
      element?.current?.focus();
      element.current?.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };
  return [startScrollAndFocus];
};

export const useOpenDocumentLink = (useLoader = false) => {
  const [COMMON] = useLangStrings<'Common'>('Common');
  const openDocument = fileURL => {
    fetchDocument(fileURL, useLoader)
      .then((res: Blob | MediaSource) => {
        try {
          const windowUrl = window.URL || window.webkitURL;
          const urlLink = windowUrl.createObjectURL(res);
          window.open(urlLink, '_blank');
          windowUrl.revokeObjectURL(urlLink);
        } catch (e) {
          showMessage({
            show: true,
            message: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
            type: 'error',
          });
        }
      })
      .catch(error => {
        console.error('Fetch Document', error);
      });
  };
  return [openDocument];
};
export const useIntersectionObserverRef = (cb?: () => void) => {
  const containerRef = useRef(null);
  const callbackFunction = entries => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      if (cb) {
        cb();
      }
    }
  };

  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 1.0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  return [containerRef];
};

export function useDebounce<T>(value: T, delay: number = 500) {
  const currentTimer = useRef<ReturnType<typeof setTimeout>>();
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (currentTimer.current) {
      clearTimeout(currentTimer.current);
    }
    currentTimer.current = setTimeout(() => {
      setVal(value);
    }, delay);
  }, [value]);

  return val;
}

// this return the current value of field stored in formContext
export const useWatchWrapper = name => {
  const { getValues } = useFormContext();
  const fieldValue = useWatch({ name });
  return fieldValue ?? getValues(name);
};

export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => queryString.parse(search), [search]);
}

export const useTriggersOnScroll = (
  element: React.MutableRefObject<HTMLElement>,
  conditions: Array<{
    height: number;
    callbackScrolled: () => void;
    callbackNotScrolled?: () => void;
  }>,
) => {
  const triggerOnScroll = (e: Event) => {
    const target = e.target as HTMLElement;
    conditions.forEach(condition => {
      if (target.scrollTop > condition.height) {
        condition.callbackScrolled();
      } else if (condition.callbackNotScrolled) {
        condition.callbackNotScrolled();
      }
    });
  };

  useEffect(() => {
    if (!element.current) return () => {};
    element?.current?.addEventListener('scroll', triggerOnScroll);
    return () =>
      element?.current?.removeEventListener('scroll', triggerOnScroll);
  }, [element, conditions]); // Add conditions to dependency array if they're dynamic
};

export const useIPUserEligibility = () => {
  const { user } = useContext(AppContext);
  const [isIpsUserEligible, setIsIpsUserEligible] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user && user.profile && user.profile.id) {
          const iPUserInfoData = await fetchIPUserEligibility(
            user.profile.id.toString(),
          );
          setIsIpsUserEligible(iPUserInfoData?.isIpsUserEligible);
        }
      } catch (error) {
        console.error('Error fetching IPS user eligibility:', error);
      }
    };

    fetchData(); // eslint-disable-line
  }, [user]);

  return isIpsUserEligible;
};

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// To get paired Property
export function usePairedProperty(
  internalCalSyncData,
  hotelcode,
  pairedPropertyHotelCode = false,
) {
  const pairedProperty = useMemo(() => {
    const parentDetails = processInternalCalSyncData(
      internalCalSyncData?.fetchPropertyListingData?.parentPropertyListing,
    );

    const childDetails = processInternalCalSyncData(
      internalCalSyncData?.fetchPropertyListingData?.childPropertyListing,
    );
    const parentHotelId = parentDetails?.[0]?.data[0]?.ingoHotelId;
    if (parentHotelId) {
      if (hotelcode === parentHotelId) {
        if (pairedPropertyHotelCode) {
          return childDetails[0]?.data[0]?.ingoHotelId;
        }
        return childDetails[0]?.data[0]?.hotelName;
      } else if (pairedPropertyHotelCode) {
        return parentHotelId;
      }
      return parentDetails[0]?.data[0]?.hotelName;
    }
  }, [internalCalSyncData, hotelcode]);

  return pairedProperty;
}
export const useContentScorePersuasion = (
  actionCategory,
  contentLevel,
  isCSRedirected,
  showNewContentScore,
) => {
  const { currentHotel: { mmt_id = '', showListings = false } = {} } =
    useContext(HotelContext) || {};
  const [contentScorePersuasion, setContentScorePersuasion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isCSRedirected && (showNewContentScore || showListings)) {
          const cSUserInfoData = await fetchContentScorePersuausion(
            actionCategory,
            contentLevel,
            mmt_id,
          );
          setContentScorePersuasion(cSUserInfoData);
        }
      } catch (error) {
        console.error(
          'Error fetching Content Score unresolved persuasion:',
          error,
        );
      }
    };

    fetchData(); // eslint-disable-line
  }, [
    actionCategory,
    isCSRedirected,
    mmt_id,
    showNewContentScore,
    contentLevel,
    showListings,
  ]);

  return contentScorePersuasion;
};

export const useWatchEffect = (
  name: string,
  callback: Function,
  watchFunc?: UseFormWatch<FieldValues>,
) => {
  const { watch } = useFormContext() || { watch: watchFunc } || {
    watch: _ => {},
  };
  const value = watch(name);
  useEffect(() => callback(value), [value]);
};
