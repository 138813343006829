import * as PromotionsIcons from 'assets/modules/promotions';
import { Button, FormElement } from 'components/new-core';
import { useLangStrings } from 'utilities/CustomHooks';
import { dayOptions } from '../../Promotions.constants';
import { DiscountInput } from '../index';
import { Dropdown, ToggleButton } from 'components/latest-core';

import './PromotionWindow.scss';

const PromotionWindow = props => {
  const {
    defaultValFrWindow,
    updateLoggedInVal,
    condition = {},
    currency,
    windowNum,
    offer,
    handleRemoveWindow,
    isDayDeal,
    isEditFlowOnly,
    isOfferTypePercent,
    index,
    cFS,
    isBundled,
  } = props;
  const [STRINGS] = useLangStrings('Promotions');
  const NSTRINGS = STRINGS.CREATE_SECTIONS.CONFIGURE_OFFER;

  return (
    <div className="promotions-promotion-window-wrapper marginB25">
      <div className="window-heading-wrapper">
        <div className="flex">
          <img src={PromotionsIcons.PromoWindow} alt="promotion-window-icon" />
          <h4 className="font14 padL5">{`${NSTRINGS.PROMO_WINDOW} ${windowNum}`}</h4>
        </div>
        {!isEditFlowOnly && (
          <Button
            variant="text"
            className="font12"
            onClick={() => handleRemoveWindow(windowNum - 1)}
            disabled={isEditFlowOnly}
            style={{ position: 'relative', right: '-15px' }}
          >
            {STRINGS.BTNS.REMOVE}
          </Button>
        )}
      </div>
      <span className="padTB5 flex marginB15 promotion-discount-wrapper">
        <DiscountInput
          offer={offer}
          currency={currency}
          isOfferTypePercent={isOfferTypePercent}
          showDefaulValCheckBox={true}
          isDefaultValChecked={windowNum === defaultValFrWindow}
          onDefaultValChange={val => {
            updateLoggedInVal(val, windowNum);
          }}
          index={index}
          cFS={cFS}
          isBundled={isBundled}
        />
        {isDayDeal ? (
          <FormElement
            label={NSTRINGS.SELECT_DAYS_LABEL_1}
            tooltip={NSTRINGS.SELECT_DAYS_LABEL_2}
            isRequired
          >
            <ToggleButton
              data={dayOptions}
              value={offer.checkInWeekDayList}
              onChange={val => {
                cFS.setCheckInWeekDayList(val, index);
              }}
              isMultiple
              disableRipple
            />
          </FormElement>
        ) : (
          <FormElement label={condition.title} className="marginR0" isRequired>
            <Dropdown
              options={condition.options ?? []}
              value={offer.bookWithinDays}
              onChange={val => {
                cFS.setBookWithinDays(val, index);
              }}
              disableClearable
              variant="default"
            />
          </FormElement>
        )}
      </span>
    </div>
  );
};

export default PromotionWindow;
