import React from 'react';
import { Box, Tooltip as MuiTooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '../Typography/Typography';

import './Tooltip.scss';

type TooltipProps = Pick<
React.ComponentProps<typeof MuiTooltip>,
'placement'
> & {
  content: React.ReactNode;
  children: JSX.Element;
  className?: string;
  bgBlack?: boolean;
  bgBlue?: boolean;
  bgPrimary?: boolean;
  open?: boolean;
  titleWithContent?: boolean;
  header?: string;
  overBottom?: boolean;
  onClose?: (event: React.SyntheticEvent) => void;
  showAboveModal?: boolean;
  tooltipCls?: string;
};

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#404040 !important',
  },
  arrow1: {
    '&:before': {
      border: '1px solid #D2D1D1',
    },
    color: '#FFFFFF',
  },
  arrowBlue: {
    color: '#3543bf !important',
  },

  tooltipCls: {
    color: '#404040',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    fontSize: '12px',
    padding: '12px',
    whiteSpace: 'pre-line',
    border: '1px solid #EFEFEF',
  },
  tooltipCls1: {
    color: '#fff !important',
    backgroundColor: '#404040 !important',
    borderRadius: '2px !important',
    fontSize: '14px !important',
    padding: '6px 8px !important',
    whiteSpace: 'pre-line',
  },
  tooltipBlueCls: {
    backgroundColor: '#3543bf !important',
  },
  overBottomCls: {
    zIndex: 1,
  },
  headerBg: {
    backgroundColor: '#EFEFEF',
  },
}));

export default function Tooltip(props: TooltipProps) {
  const {
    content,
    children,
    placement = 'right',
    bgBlack,
    bgBlue,
    bgPrimary,
    open,
    titleWithContent = false,
    header,
    overBottom = false,
    onClose,
    showAboveModal = false,
    className,
    tooltipCls: _tooltipCls,
  } = props;
  const classes = useStylesBootstrap();
  const tooltipClass = {};
  if (overBottom) {
    //@ts-ignore
    tooltipClass.popperInteractive = classes.overBottomCls;
  }
  if (bgBlue) {
    //@ts-ignore
    tooltipClass.arrow = classes.arrowBlue;
    //@ts-ignore
    tooltipClass.tooltip = `${classes.tooltipCls1} ${classes.tooltipBlueCls}`;
  }
  if (_tooltipCls) {
    //@ts-ignore
    tooltipClass.tooltip += ` ${_tooltipCls || ''}`;
  }
  const renderTooltipContent = ({
    header: tooltipHeader,
    content: tooltipContent,
  }) => {
    return (
      <Box>
        <Box
          style={{
            marginTop: '-12px',
            marginLeft: '-12px',
            marginRight: '-12px',
          }}
          className={`${classes.headerBg} padTB8 padLR12`}
        >
          <Typography variant="subtitle1" fb>
            {tooltipHeader}
          </Typography>
        </Box>
        <Box className="padT12">{tooltipContent}</Box>
      </Box>
    );
  };

  const extraProps = { ...(typeof open === 'boolean' && { open }) };

  return (
    <MuiTooltip
      title={
        titleWithContent && !bgBlack && !bgPrimary && !bgBlue
          ? renderTooltipContent({ header, content })
          : content ?? ''
      }
      placement={placement}
      onClose={onClose}
      classes={
        !bgBlack
          ? {
            tooltip: `${classes.tooltipCls} ${className}`,
            arrow: classes.arrow1,
            ...tooltipClass,
          }
          : {
            tooltip: `${classes.tooltipCls1} ${className}`,
            arrow: classes.arrow,
            ...tooltipClass,
          }
      }
      arrow
      {...extraProps}
      PopperProps={showAboveModal ? { style: { zIndex: 200000000 } } : {}}
    >
      {children}
    </MuiTooltip>
  );
}
