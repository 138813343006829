export const CHAT_UNREAD_COUNT = `
query UnreadChatDetails($rawEndpointRequestData: RawEndpointsRequestData) { 
    rawEndpoint(rawEndpointRequestData: $rawEndpointRequestData) { 
        unreadChatDetails { 
            modelType1 { 
                success 
                total_unread_message 
                last_unread_chat 
                error
            } 
        }
    }
}
`;
