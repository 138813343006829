import { AttributeData } from '../../data/types/Schema';
import { useFormContext } from 'react-hook-form';
import { FormElement } from '../../../../components/latest-core';
import React from 'react';

export type FieldContainerProps = {
  children: React.ReactNode;
  inline?: boolean;
};

export function FieldContainer(
  props: FieldContainerProps & { attr: AttributeData },
) {
  const { attr, inline } = props;
  const {
    formState: { errors },
  } = useFormContext();
  const errorMsg = errors && errors[attr.attributeId]?.message?.toString();
  const cls = inline ? '!flex-row space-between' : 'flex-col w-full';
  const titleCls = inline ? 'items-center self-center' : '';
  const baseErrorCls = 'incl-attr-field-error';
  const errorCls = inline ? `${baseErrorCls} w-full` : `${baseErrorCls}`;
  const key = `${attr.attributeId}`;
  return (
    <FormElement
      key={key}
      data-attr-field-id={key}
      isRequired={attr.renderType === 'sub_number' ? true : attr.required}
      title={attr.label as string}
      errorMsg={errorMsg}
      titleClass={titleCls}
      errorClass={errorCls}
      className={`flex flex-1 ${cls} py-3 flex-wrap`}
    >
      {props.children}
    </FormElement>
  );
}
