import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import { getDataPropsFromRest, noop } from 'utilities/Utils';
import Typography from '../Typography/Typography';
import { CheckboxSelected } from 'assets/latest_core';

type CommonProps = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  size?: 'small' | 'medium';
  color?: 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  subLabel?: string;
  count?: number;
  type?: string;
  marginBottomNone?: boolean;
  indeterminate?: boolean;
  variant?: 'normal' | 'thin';
  marginRight?: boolean;
};

type CheckboxProps = CommonProps & {
  name: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  value: string | number | unknown;
};

const useStyles = makeStyles(theme => ({
  root: (props: {
    subLabel?: string;
    marginBottomNone?: boolean;
    isThin: boolean;
  }) => ({
    alignItems: props.subLabel ? 'flex-start' : 'center',
    marginLeft: 0,
    marginBottom: props.marginBottomNone && 0,
    '& .MuiButtonBase-root': {
      width: '14px',
      height: '14px',
      paddingTop: props.subLabel ? '9px' : '6px',
      '& .MuiSvgIcon-root': {
        fontSize: '20px',
        marginLeft: '4px',
      },
    },
    '& span[class*=MuiTypography-root]': {
      fontSize: '14px',
      lineHeight: '20px',
      marginLeft: '4px',
      color: theme.palette.text.primary,
    },
    '& span[class*=MuiCheckbox-indeterminate]': {
      color: theme.palette.primary.main,
    },
    '& .Mui-disabled': {
      backgroundColor: props.isThin ? '' : theme.palette.backgrounds.dark,
    },
    '& .Mui-disabled.Mui-checked': {
      backgroundColor: 'unset !important',
    },
    '& .MuiTypography-root.Mui-disabled': {
      color: theme.palette.text.primary,
      backgroundColor: 'unset !important',
    },
  }),
}));

function CheckboxComp(props: Partial<CheckboxProps>, ref) {
  const {
    name,
    value,
    color = 'primary',
    onChange = noop,
    checked,
    disabled = false,
    size = 'medium',
    children,
    className,
    subLabel,
    count,
    type,
    marginBottomNone = false,
    indeterminate = true,
    variant = 'normal',
    marginRight = true,
    ...rest
  } = props;

  const handleChange = e => {
    onChange(e.target.checked);
  };

  const dataTestIdProps = getDataPropsFromRest(rest);
  const classes = useStyles({
    subLabel,
    marginBottomNone,
    isThin: variant === 'thin',
  });
  const extraProps = type === 'indeterminate' ? { indeterminate } : {};
  if (variant === 'thin') {
    // @ts-ignore
    extraProps.icon = (
      <span
        className={`h-[14px] flex-shrink-0 w-[14px] rounded-sm border-solid border flex ${
          disabled ? 'border-divider bg-color-light' : 'border-text-secondary'
        } cursor-pointer -m-[6px]`}
      />
    );
    // @ts-ignore
    extraProps.checkedIcon = (
      <CheckboxSelected
        className={`-m-[6px] flex-shrink-0 ${
          disabled ? ' fill-common-divider' : ''
        }`}
      />
    );
  }

  const defaultMuiComp = (
    <MuiCheckbox
      ref={ref}
      style={{
        width: '14px',
        height: '14px',
        marginRight: marginRight ? '11px' : '0px',
        marginLeft: '2px',
      }}
      className={className}
      name={name}
      value={value}
      checked={checked}
      color={color}
      disabled={disabled}
      size={size}
      onChange={handleChange}
      {...dataTestIdProps}
      {...extraProps}
    />
  );

  if (children) {
    let label = children;

    if (subLabel) {
      label = (
        <>
          <Typography
            variant="body1"
            themeColor="text.primary"
            className="capitalize"
          >
            {children}{' '}
            {count > 0 && (
              <Typography
                fb
                variant="subtitle1"
                themeBg="primary.main"
                themeColor="common.white"
                className="rounded-lg text-center inline px-1 py-[1px]"
              >
                <span>{count > 99 ? '99+' : count}</span>
              </Typography>
            )}
          </Typography>
          <Typography variant="sectionHeading" className="capitalize">
            {subLabel}
          </Typography>
        </>
      );
    }
    return (
      <FormControlLabel
        control={defaultMuiComp}
        label={label}
        classes={{ root: classes.root }}
      />
    );
  }

  return defaultMuiComp;
}

const Checkbox = forwardRef(CheckboxComp);

type ControlledCheckboxProps<
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = CommonProps & {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: boolean;
  isRequired?: boolean;
  customValidator?: RegisterOptions<T, TName>['validate'];
};

function ControlledCheckbox<
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: ControlledCheckboxProps<T, TName>) {
  const {
    name,
    control,
    defaultValue = false,
    isRequired = false,
    customValidator,
    ...rest
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };
  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const {
    field: { ref, value, onChange },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });

  return (
    <Checkbox
      ref={ref}
      checked={value}
      onChange={_value => onChange(_value)}
      {...rest}
    />
  );
}

export { ControlledCheckbox };

export { Checkbox };
