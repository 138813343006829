import React, { forwardRef, useContext, useEffect } from 'react';
import {
  useController,
  Control,
  Validate,
  RegisterOptions,
  FieldValues,
  FieldPath,
  PathValue,
} from 'react-hook-form';

import { Input } from '../../index';
import { HotelContext } from '../../../../utilities/Context';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import makeStyles from '@mui/styles/makeStyles';

type CommonProps = {
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  disableToggle?: boolean;
  minFixed?: number;
  minPercentage?: number;
  maxFixed?: number;
  maxPercentage?: number;
  className?: string;
};

type InputWithToggleProps = CommonProps & {
  value: number;
  mode: string;
  name?: string;
  onChange: (mode: string, value: number | string, name: string) => void;
};

type ControlledInputWithToggleProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = CommonProps & {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: {
    value: number | string;
    mode: string;
  };
  isRequired?: boolean;
  customValidator?: Validate<PathValue<T, TName>, T>;
};

const PERCENT = 'percent';
const FIXED = 'fixed';

const options = [
  { content: '%', value: PERCENT },
  { content: '₹', value: FIXED },
];

const useStyles = makeStyles(theme => ({
  root: {
    height: '26px',
    '& button:hover': {
      background: `${theme.palette.primary.main} !important`,
      color: theme.palette.backgrounds.white,
      opacity: 0.4,
    },
    '& .Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.backgrounds.white,
    },
  },
}));

const CustomToggle = props => {
  const { data, mode, onChange } = props;

  const { root } = useStyles();

  return (
    <ToggleButtonGroup
      size="small"
      exclusive
      value={mode}
      onChange={(e, newData) => onChange(newData)}
      classes={{
        root,
      }}
    >
      {data.map(item => {
        return <ToggleButton value={item.value}>{item.content}</ToggleButton>;
      })}
    </ToggleButtonGroup>
  );
};

function InputWithToggleComp(
  props: InputWithToggleProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const {
    value,
    mode,
    onChange,
    //Optional
    name,
    placeholder,
    onBlur,
    disabled = false,
    disableToggle = false,
    error = false,
    minFixed = 0,
    minPercentage = 0,
    maxFixed,
    maxPercentage = 100,
    className = '',
  } = props;

  const {
    currentHotel: { base_currency: baseCurrency },
  } = useContext(HotelContext);

  const maxValue = mode === FIXED ? maxFixed : maxPercentage;
  const minValue = mode === FIXED ? minFixed : minPercentage;

  const changeHandler = (_value, _name) => {
    onChange(mode, _value, _name);
  };

  const changeMode = newMode => {
    onChange(newMode, '', name);
  };

  useEffect(() => {
    if (value > maxValue) {
      onChange(mode, maxValue, name);
    } else if (value < minValue) {
      onChange(mode, minValue, name);
    }
  }, [mode]);

  // Left section only if mode is fixed then only have currency type.
  const currency = baseCurrency === 'INR' ? '₹' : baseCurrency;
  const leftElem = mode === FIXED ? currency : '%';

  const rightElem = (
    <CustomToggle
      data={options}
      onChange={changeMode}
      mode={mode}
      disabled={disableToggle}
    />
  );

  return (
    <Input
      ref={ref}
      value={value}
      onChange={changeHandler}
      type="number"
      leftElement={leftElem}
      rightElement={rightElem}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      disabled={disabled}
      error={error}
      min={minValue}
      max={maxValue}
      className={className}
    />
  );
}

const InputWithToggle = forwardRef(InputWithToggleComp);

function ControlledInputWithToggle<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: ControlledInputWithToggleProps<T, TName>) {
  const {
    name,
    control,
    defaultValue,
    isRequired = false,
    customValidator,
    ...rest
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    validate: (inputValue, formValues) => {
      if (isRequired && inputValue?.value === '') {
        return 'This field is required';
      }
      if (typeof customValidator === 'function') {
        return customValidator(inputValue, formValues);
      }
      return true;
    },
  };

  const {
    field: { ref, value: data, onChange },
  } = useController<T, TName>({
    name,
    control,
    defaultValue: defaultValue as PathValue<T, TName>,
    rules: validations,
  });

  const onChangeHandler = (mode, value) => onChange({ mode, value });

  return (
    <InputWithToggle
      ref={ref}
      value={data.value}
      mode={data.mode}
      onChange={onChangeHandler}
      {...rest}
    />
  );
}

export { ControlledInputWithToggle };

export default InputWithToggle;
