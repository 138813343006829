import {
  ControlledInput,
  ControlledMultiLineInput,
} from '../../../../components/latest-core';
import { AttributeFieldProps } from './AttributeFieldProps';
import { useFormContext } from 'react-hook-form';
import { FieldContainer } from './FieldContainer';
import React from 'react';
import { parseNestedNumberValue } from './packageFormUtils';

export type ControlledInputProps = Parameters<typeof ControlledInput>[0];

export function FreeFormTextOrNumber(
  props: AttributeFieldProps & Partial<ControlledInputProps>,
) {
  const { control } = useFormContext();
  const { attr, ...inputProps } = props;
  const { customValidator, ...restInputProps } = inputProps;
  let { defaultValue = null } = props;
  const isMinMaxSpecified =
    inputProps.max !== undefined && inputProps.min !== undefined;
  let inputValidationProps: {};
  const renderType = attr.renderType;
  const isTextArea = renderType === 'text_area';
  if (isTextArea) {
    // renderType = 'text'
  }
  if (isMinMaxSpecified) {
    inputValidationProps = {};
  } else if (renderType === 'number' || renderType === 'number_select') {
    inputValidationProps = {
      max: parseNestedNumberValue(attr.maxValue) || undefined,
      min: parseNestedNumberValue(attr.minValue) || undefined,
    };
  } else {
    inputValidationProps = {
      maxLength: parseNestedNumberValue(attr.maxValue) || undefined,
      minLength: parseNestedNumberValue(attr.minValue) || undefined,
    };
  }
  const Cmp = isTextArea ? ControlledMultiLineInput : ControlledInput;
  if (isTextArea) {
    defaultValue = defaultValue || ''; // text area default can't be empty string
  }
  return (
    <FieldContainer attr={attr}>
      <Cmp
        {...restInputProps}
        type={renderType as 'text' | 'number'}
        name={`${attr.attributeId}`}
        control={control}
        {...inputValidationProps}
        disabled={false}
        valueAsNumber={renderType === 'number' || undefined}
        // @ts-ignore
        defaultValue={defaultValue || ''}
        isRequired={attr.required}
        className={''}
        // @ts-ignore
        customValidator={customValidator}
      />
    </FieldContainer>
  );
}
