import { Radio } from 'components/latest-core';
import { FormElement } from 'components/new-core/';
import React, { useState } from 'react';
import { useLangStrings } from 'utilities/CustomHooks';

const toOfferOptions = [
  { label: 'Discount', value: 'discount' },
  { label: 'Free Nights', value: 'freeNights' },
];
const CustomOfferType = props => {
  const { toOfferState, isEditFlowOnly } = props;
  const [STRINGS] = useLangStrings('Promotions');
  const NSTRINGS = STRINGS.CREATE_SECTIONS.OFFER_TYPE;

  return (
    <tr>
      <td>{NSTRINGS.NAME}</td>
      <td>
        <FormElement label={NSTRINGS.LABEL} isRequired>
          <Radio
            variant="legacy-boxed"
            value={toOfferState.toOffer}
            options={toOfferOptions}
            onChange={toOfferState.setToOffer}
            disabled={isEditFlowOnly}
            inline
          />
        </FormElement>
      </td>
    </tr>
  );
};

export function useCustomOfferType(promotionData) {
  const [toOffer, setToOffer] = useState(promotionData?.toOffer ?? 'discount');

  return {
    toOffer,
    setToOffer,
  };
}

export default React.memo(CustomOfferType, (prevProps, nextProps) => {
  return prevProps.toOfferState.toOffer === nextProps.toOfferState.toOffer;
});
