import { RoomRateplanSelectable, useRoomRpSelectable } from 'components/common';
import { Button, FormElement } from 'components/new-core/';
import { Dropdown, Radio, Calendar, useCalendar } from 'components/latest-core';
import { useState } from 'react';
import { useFlagState, useLangStrings } from 'utilities/CustomHooks';
import { groupDates } from 'utilities/DateUtils';
import {
  bundleOptions,
  nightOptions,
  yesNoOpts,
} from '../Promotions.constants';
import { getBlackoutDates } from '../Promotions.utils';

const AdvanceSettings = props => {
  const { isFNDDeal, isEditFlowOnly, advSettingsState: advSS } = props;
  const [STRINGS, CSTRINGS] = useLangStrings('Promotions');
  const NSTRINGS = STRINGS.CREATE_SECTIONS.ADV_SETNGS;

  const getAdvancedUI = (
    <>
      <FormElement className="width100" label={NSTRINGS.LABEL1}>
        <Radio
          options={yesNoOpts}
          name="showBlackOutDates"
          value={advSS.showBlackOutDates ? 'yes' : 'no'}
          onChange={val => advSS.setShowBlackOutDates(val === 'yes')}
          inline
        />
      </FormElement>
      {advSS.showBlackOutDates && (
        <FormElement label={NSTRINGS.LABEL2} className="width100">
          <Calendar
            type="blackout"
            inputClasses="!w-[250px]"
            {...advSS.blackoutCalendarProps}
          />
        </FormElement>
      )}
      <FormElement className="width100" label={NSTRINGS.LABEL3}>
        <Radio
          options={yesNoOpts}
          name="nonRefundable"
          value={advSS.nonRefundable ? 'yes' : 'no'}
          onChange={val => advSS.setNonRefundable(val === 'yes')}
          inline
        />
      </FormElement>
      <FormElement className="width100" label={NSTRINGS.LABEL4}>
        <Radio
          options={yesNoOpts}
          name="pahApplicable"
          value={advSS.pahApplicable ? 'yes' : 'no'}
          onChange={val => advSS.setPAHApplicable(val === 'yes')}
          inline
        />
      </FormElement>
      {isFNDDeal && (
        <FormElement label={NSTRINGS.LABEL5}>
          <Dropdown
            value={advSS.offerCondition}
            options={nightOptions}
            onChange={advSS.setOfferCondition}
            disableClearable
            variant="default"
          />
        </FormElement>
      )}
      <FormElement
        className="width100"
        label={NSTRINGS.LABEL6}
        tooltip={NSTRINGS.LABEL6_TT}
      >
        <Radio
          options={yesNoOpts}
          name="applicableForAllRoomsAndRps"
          value={advSS.applicableForAllRoomsAndRps ? 'yes' : 'no'}
          onChange={val => advSS.setApplicableForAllRoomsAndRps(val === 'yes')}
          disabled={isEditFlowOnly}
          inline
        />
      </FormElement>{' '}
      <FormElement label={NSTRINGS.LABEL7}>
        <Radio
          options={bundleOptions}
          name="applyPromotion"
          value={advSS.applyPromotion}
          onChange={val => advSS.setApplyPromotion(val)}
          inline
        />
      </FormElement>
      {!advSS.applicableForAllRoomsAndRps && (
        <RoomRateplanSelectable
          roomRpsList={advSS.roomRpsList}
          setRoomRpsList={advSS.setRoomRpsList}
          activeRooms={advSS.activeRooms}
          activeRateplans={advSS.activeRateplans}
          disabled={isEditFlowOnly}
        />
      )}
    </>
  );

  return (
    <tr>
      <td>{NSTRINGS.NAME}</td>
      <td>
        {!advSS.showSettings && (
          <Button
            variant="text"
            onClick={advSS.setShowSettings}
            style={{ position: 'relative', left: '-16px' }}
          >
            + {CSTRINGS.BUTTONS.SHOW_SETTINGS}
          </Button>
        )}
        {advSS.showSettings && (
          <>
            {getAdvancedUI}
            <Button
              variant="text"
              onClick={advSS.setShowSettings}
              style={{ position: 'relative', left: '-16px' }}
            >
              — {CSTRINGS.BUTTONS.HIDE_SETTINGS}
            </Button>
          </>
        )}
      </td>
    </tr>
  );
};

export function useAdvanceSettingsState(promotionData) {
  const [showSettings, , , setShowSettings] = useFlagState(false);
  const [showBlackOutDates, setShowBlackOutDates] = useState(
    promotionData?.showBlackOutDates ?? false,
  );
  const [checkInBlackOutDatesArr, setCheckInBlackOutDatesArr] = useState(
    promotionData?.checkInBlackOutDatesArr ?? [],
  );
  const [nonRefundable, setNonRefundable] = useState(
    promotionData?.nonRefundable ?? false,
  );
  const [pahApplicable, setPAHApplicable] = useState(
    promotionData?.pahApplicable ?? true,
  );
  const [applicableForAllRoomsAndRps, setApplicableForAllRoomsAndRps] =
    useState(promotionData?.applicableForAllRoomsAndRps ?? true);
  const [applyPromotion, setApplyPromotion] = useState(
    promotionData?.isBundled ? bundleOptions[1].value : bundleOptions[0].value,
  );
  const [offerCondition, setOfferCondition] = useState(
    promotionData?.offerCondition ?? nightOptions[0],
  );
  const blackoutCalendarProps = useCalendar({
    variant: 'multiple',
    initialDate: checkInBlackOutDatesArr,
    callbackAfterDateSelection: setCheckInBlackOutDatesArr,
  });

  const { roomRpsList, setRoomRpsList, activeRooms, activeRateplans } =
    useRoomRpSelectable(
      promotionData?.selectedRoomRpList ?? {
        rooms: {},
        rateplans: {},
      },
    );

  return {
    showSettings,
    setShowSettings,
    showBlackOutDates,
    setShowBlackOutDates,
    blackoutCalendarProps,
    nonRefundable,
    setNonRefundable,
    pahApplicable,
    setPAHApplicable,
    applicableForAllRoomsAndRps,
    setApplicableForAllRoomsAndRps,
    applyPromotion,
    setApplyPromotion,
    roomRpsList,
    setRoomRpsList,
    activeRooms,
    activeRateplans,
    offerCondition,
    setOfferCondition,
    checkInBlackOutDatesArr,
  };
}

export function getPayloadFrAdvSettings(advState, hotelCode, isFNDDeal) {
  const payload: {
    checkinBlackoutDates: string[];
    nonRefundable: boolean;
    pahApplicable: boolean;
    relatedToList: { relatedTo: string; relatedCode: string }[];
    offerCondition?: string;
  } = {
    checkinBlackoutDates: [],
    nonRefundable: advState.nonRefundable,
    pahApplicable: advState.pahApplicable,
    relatedToList: [],
  };

  if (isFNDDeal) {
    payload.offerCondition = advState.offerCondition.value;
  }

  if (advState.showBlackOutDates) {
    const allDates = groupDates(advState.checkInBlackOutDatesArr, true);
    payload.checkinBlackoutDates = getBlackoutDates({ allDates });
  }
  if (advState.applicableForAllRoomsAndRps) {
    payload.relatedToList = [
      {
        relatedTo: 'hotel',
        relatedCode: hotelCode,
      },
    ];
  } else {
    Object.keys(advState.roomRpsList).forEach(room => {
      const tempList = [];
      const currentRoomRpObj = advState.roomRpsList[room];
      const isRoomChecked = advState.roomRpsList[room]?.isRoomChecked;

      if (isRoomChecked) {
        payload.relatedToList = [
          ...payload.relatedToList,
          {
            relatedTo: 'room',
            relatedCode: room,
          },
        ];
      } else {
        Object.keys(currentRoomRpObj).forEach(rp => {
          if (currentRoomRpObj[rp] === true) {
            tempList.push({
              relatedTo: 'ratePlan',
              relatedCode: rp,
            });
          }
        });
        payload.relatedToList = [...payload.relatedToList, ...tempList];
      }
    });
  }
  return payload;
}

export default AdvanceSettings;
