import { DayPicker } from 'react-day-picker';
import { CustomCaption } from '../components/CustomCaption';
import { CustomDay } from '../components/CustomDay';
import { CalendarBasicProps } from '../Calendar.types';

import 'react-day-picker/dist/style.css';

const MIN_DATE = new Date('2000-01-01');
const MAX_DATE = new Date('2099-12-31');

export const CalendarBasic = (props: CalendarBasicProps) => {
  const {
    handleDayClick = () => {},
    initialMonth = new Date(),
    modifiers,
    disabled = [],
    numberOfMonths = 1,
    month,
    setMonth,
    modifiersStyles,
    fromYear = MIN_DATE,
    toYear = MAX_DATE,
    selected,
    renderDay: RenderDay = CustomDay,
    renderCaption: RenderCaption = CustomCaption,
    className = '',
    captionLabel,
    disableNavigation = false,
    styles = {
      root: {
        margin: '0px',
      },
      caption_start: {
        borderRight: '1px solid #E0E0E0',
      },
      month: {
        margin: '0px',
        padding: '20px 24px',
        gap: '12px',
      },
      cell: {
        paddingTop: '4px',
        border: 'none',
      },
      head_cell: {
        border: 'none',
        textTransform: 'none',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  } = props;

  const customComponents: { Caption?; Day? } = {};
  if (RenderCaption)
    customComponents.Caption = captionProps => (
      <RenderCaption
        month={month}
        setMonth={setMonth}
        fromYear={fromYear}
        toYear={toYear}
        captionLabel={captionLabel}
        {...captionProps}
      />
    );
  if (RenderDay) customComponents.Day = dayProps => <RenderDay {...dayProps} />;

  return (
    <DayPicker
      data-test-id={props['data-test-id']}
      fromYear={fromYear.getFullYear()}
      toYear={toYear.getFullYear()}
      numberOfMonths={numberOfMonths}
      mode="default"
      onDayClick={handleDayClick}
      disableNavigation={disableNavigation}
      month={month}
      defaultMonth={initialMonth}
      disabled={disabled}
      selected={selected}
      onMonthChange={setMonth}
      weekStartsOn={1}
      modifiers={{
        weekStart: { dayOfWeek: [1] },
        weekEnd: { dayOfWeek: [0] },
        ...modifiers,
      }}
      today={new Date()}
      components={customComponents}
      className={`${className} select-none`}
      classNames={{}}
      styles={styles}
      modifiersClassNames={{
        day: 'day',
        weekStart: 'week_start relative',
        weekEnd: 'week_end relative',
        disabled: 'disabled',
        blocked: 'blocked',
        rangeStart: 'range_start',
        rangeEnd: 'range_end',
        rangeMiddle: 'range_middle',
      }}
      modifiersStyles={modifiersStyles}
    />
  );
};
