import React, { Suspense } from 'react';
import {
  DefaultError,
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorScreen from 'app/special-screens/ErrorScreen';
import ContainerShimmer from '../app/shimmers/ContainerShimmer';
import sentry from '../utilities/Sentry';

class PageQueryError extends Error {
  constructor(query: Query<unknown, unknown, unknown>, cause: Error) {
    super(`Error while loading the page. queryHash=${query.queryHash}`, {
      cause,
    });
    this.name = 'PageQueryError';
  }
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error: DefaultError, query: Query<unknown, unknown, unknown>) {
      sentry.logFatalException(new PageQueryError(query, error));
    },
  }),

  defaultOptions: {
    queries: {
      /**
       * refetchOnWindowFocus = false -> don't refetch on window refocus...
       * ... because this can override local derived state in some cases
       */
      refetchOnWindowFocus: false,
      /**
       *  this can overwrite some state when offline -> online. Better to do things explicitly
       *  */
      refetchOnReconnect: false,
      throwOnError: true, // ErrorBoundary shall catch
      gcTime: 0, // 0 => clear cache immediately after unMount
      staleTime: Infinity,
    },
  },
});

export function reactQueryProviderHOC<T>(
  ComponentToWrap: React.ComponentType<T>,
) {
  return (props: T) => (
    <Suspense fallback={<div />}>
      <QueryClientProvider client={queryClient}>
        <ComponentToWrap {...props} />
      </QueryClientProvider>
    </Suspense>
  );
}

type ErrorScreenProps = {
  error: Error;
  reset: () => void;
};

type WrapperOptions<T> = {
  renderLoader?: (props: T) => React.ReactElement;
  renderError?: (props: ErrorScreenProps) => React.ReactElement;
};

export function suspenseWrapper<T>(
  Component: React.ComponentType<T>,
  options?: WrapperOptions<T>,
): React.ComponentType<T> {
  const {
    renderError = ({ reset }) => <ErrorScreen fetchFailed retryFn={reset} />,
    renderLoader = () => <ContainerShimmer />,
  } = options || {};
  return (props: T) => (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary, error }) =>
            // @ts-ignore
            renderError({ reset: resetErrorBoundary, error })
          }
        >
          <Suspense fallback={renderLoader(props)}>
            <Component {...props} />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
