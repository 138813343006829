import { Button } from 'components/latest-core';
import {
  getCustomDate,
  getCustomDates,
  getCustomRanges,
} from '../Calendar.utils';
import { isDateSame } from 'utilities/DateUtils';

export const Footer = props => {
  const {
    primaryCta,
    secondaryCta,
    hideCalendar,
    calendarProps,
    selectAndNavigateDate,
    isMultiCalendar,
  } = props;
  const { variant } = calendarProps;
  const footerOptions = {
    ...(variant === 'single'
      ? getCustomDate()
      : variant === 'multiple'
        ? getCustomDates()
        : getCustomRanges()),
    apply: { label: 'Apply', onClick: hideCalendar },
  };
  const onClickCta = (ctaType: string) => {
    if (ctaType === 'apply') {
      footerOptions[ctaType].onClick();
    } else {
      const selectedDays = footerOptions[ctaType].get();
      selectAndNavigateDate({
        selectedDays,
        calendarProps,
      });
    }
  };
  return (
    <div className="border-solid border-[1px] border-x-0 border-b-0 border-color-divider p-4">
      <div className="flex gap-3 justify-end">
        {primaryCta && (
          <Button
            onClick={() => onClickCta(primaryCta)}
            variant="text"
            className={isMultiCalendar ? 'w-[100px]' : 'flex-1'}
            disabled={
              variant === 'single' && primaryCta === 'today'
                ? isDateSame(calendarProps.selectedDays, new Date())
                : false
            }
          >
            {footerOptions[primaryCta].label}
          </Button>
        )}
        {secondaryCta && (
          <Button
            onClick={() => onClickCta(secondaryCta)}
            className={isMultiCalendar ? 'w-[100px]' : 'flex-1'}
            disabled={
              variant === 'single' && secondaryCta === 'today'
                ? isDateSame(calendarProps.selectedDays, new Date())
                : false
            }
          >
            {footerOptions[secondaryCta].label}
          </Button>
        )}
      </div>
    </div>
  );
};
