import { doMutation } from '../../base/GraphQLClient';

export const UPDATE_ACTIVITY_TRACKER_QUERY = `mutation ActivityTrackerUpdate($rawEndpointRequestData: RawEndpointsRequestData) { 
  rawEndpoint(endpointRequestData: $rawEndpointRequestData) {
    activityTrackerUpdate { 
      modelType1 { 
        success
        message
        errors {
          field
          message
        }
        __typename
      }
      __typename
    }
    __typename
  }
}`;

export async function updateActivityTracker({
  userId,
  hotelId,
}: {
  userId: string;
  hotelId: string;
}) {
  const { data } = await doMutation(
    UPDATE_ACTIVITY_TRACKER_QUERY,
    {
      rawEndpointRequestData: {
        activityTrackerUpdate: {
          modelType: 'modelType1',
          urlParams: {
            activityId: '3',
          },
          body: {
            user_id: userId,
            hotel_code: hotelId,
          },
        },
      },
    },
    { useLoader: false },
  );
  return data;
}
