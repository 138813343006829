import { wrappedAxiosCall } from 'base/APIClient';
import { doMutation, doQuery } from 'base/GraphQLClient';
import { PROMOTIONS } from 'base/Url';
import {
  AllPncDataInterface,
  CUG_CATEGORY_TYPE_BLACK,
  CUG_CATEGORY_TYPE_EXCLUSIVE_BLACK,
  CugMetadataInterface,
  FilterDataInterface,
  ICugBlackCategoryServiceData,
  PaginationInterface,
  PncMetaDataInterface,
  PNC_TAB,
  PromotionMetadataInterface,
  SortDealInterface,
  DEAL_STATUS,
  DEAL_TYPE_LABEL,
  OFFER_VALUE_SEGMENT_TYPE,
} from 'interfaces/PncContextInterface';
import { Common, Promotions } from 'interfaces/Strings';
import {
  MODIFY_SALE_QUERY,
  generateModifySaleVariables,
} from 'modules/sale-handling/SaleHanldingGraphQueries';
import {
  participationStatus,
  participationStatusNumMap,
} from 'modules/sale-handling/SaleHandling.constants';
import { PostPayload } from 'modules/sale-handling/SaleHandling.types';
import {
  addToDate,
  getFormattedDate,
  getFutureDate,
  onlyDateComparison,
  unGroupDates,
} from 'utilities/DateUtils';
import {
  generateCorrelationKey,
  getValue,
  isNullOrUndefined,
} from 'utilities/Utils';
import {
  FILTER_KEYS,
  apiDateFormat,
  dateAPIFormat,
  listingDateFormat,
  minDuration,
  mmtSelectSegments,
  noEndDate,
  onlyMmtBlackSegments,
} from './PnC.constants';
import {
  CREATE_UPDATE_COUPON,
  CREATE_UPDATE_CUG_MUTATION,
  GET_DEALS_LIST_QUERY,
  GET_DEALS_META_DATA_QUERY,
} from './PnCGraphQueries';
import {
  EarlyBirdOpts,
  LMDOptions,
  LOSOpts,
  defaultEarlyBirdMax,
  defaultEarlyBirdMin,
  defaultEndDateString,
  defaultMaxLosValue,
  defaultMinLosValue,
  newPromotionTypes,
  nightOptions,
  offerTypes,
} from './Promotions/Promotions.constants';
import {
  getIsNewUser,
  sortOfferCategoriesByPriority,
  sortOfferValueList,
  transformBlackoutDates,
} from './utils';

const commonParams = {
  vendor: 'all',
  platform: 'extranet',
};

const commonGETDetailQueryParams = {
  ...commonParams,
  projection: 'detail',
};

function parseDataFrCreate(data) {
  let offerType,
    allPercentDiscount = 0,
    allFixedDiscount = 0,
    loggedInPercentDiscount = 0,
    loggedInFixedDiscount = 0,
    bookWithinDays,
    toOffer;
  let isBundled = false;

  const checkInWeekDayList = data.check_in_weekday.map(item => Number(item));
  toOffer = 'discount';
  data.offer_value_list.forEach(offer => {
    offerType = offer.offer_type; // This is assuming offertype will be same for all objects inside offer_value_list
    const isOfferTypePercent = offerType === offerTypes.percentage;

    if (offer.segment === 'bundled') {
      isBundled = true;
    }
    if (offer.segment === 'b2c' || offer.segment === 'bundled') {
      if (isOfferTypePercent) {
        allPercentDiscount = offer.offer_value;
        allFixedDiscount = 0;
      } else {
        allPercentDiscount = 0;
        allFixedDiscount = offer.offer_value;
      }
    }
    if (offer.segment === 'loggedin') {
      if (isOfferTypePercent) {
        loggedInPercentDiscount = offer.offer_value - allPercentDiscount;
        loggedInFixedDiscount = 0;
      } else {
        loggedInPercentDiscount = 0;
        loggedInFixedDiscount = offer.offer_value - allFixedDiscount;
      }
    }
  });

  const dealType = data.category;
  let earlyBirdMin = data.earlybirdmin,
    earlyBirdMax = data.earlybirdmax,
    maxLos = data.max_los,
    minLos = data.min_los;
  const losOpts = LOSOpts;

  if (!isNullOrUndefined(earlyBirdMin)) {
    earlyBirdMin = EarlyBirdOpts.find(item => item.value === earlyBirdMin);
  }
  if (!isNullOrUndefined(earlyBirdMax)) {
    earlyBirdMax = LMDOptions.find(item => item.value === earlyBirdMax);
  }
  if (!isNullOrUndefined(maxLos)) {
    maxLos = losOpts.find(item => item.value === maxLos);
  }
  if (!isNullOrUndefined(minLos)) {
    minLos = losOpts.find(item => item.value === minLos);
  }

  if (dealType === newPromotionTypes.EBD) {
    bookWithinDays = earlyBirdMin;
  }
  if (dealType === newPromotionTypes.LMD) {
    bookWithinDays = earlyBirdMax;
  }
  if (dealType === newPromotionTypes.LOS) {
    bookWithinDays = minLos;
  }
  if (dealType === newPromotionTypes.CUSTOM && data.night) {
    toOffer = 'freeNights';
  }

  let checkInBlackOutDatesArr = [];
  if (data.checkin_blackout_dates) {
    const parsedBlackoutDates = data.checkin_blackout_dates
      ?.split(',')
      ?.map(entry => entry.split(':'));
    checkInBlackOutDatesArr = unGroupDates(parsedBlackoutDates);
  }
  const isRelatedListHotel =
    data.related_to_list?.[0].related_to === 'hotel' ?? false;
  const hasNoStayEndDate =
    getFormattedDate(new Date(data.checkindateend), dateAPIFormat) ===
    defaultEndDateString;
  const hasNoBkgEndDate =
    getFormattedDate(new Date(data.booking_date_end), dateAPIFormat) ===
    defaultEndDateString;
  let stayEndDate = new Date(data.checkindateend);
  let bkgEndDate = new Date(data.booking_date_end);

  if (hasNoStayEndDate) {
    stayEndDate = addToDate(6, 'months');
  }

  if (hasNoBkgEndDate) {
    bkgEndDate = addToDate(6, 'months');
  }

  const selectedRoomRpList = {
    rooms: {},
    rateplans: {},
  };
  data.related_to_list.forEach(item => {
    if (item.related_to === 'room') {
      selectedRoomRpList.rooms[item.related_code] = true;
    }
    if (item.related_to === 'rateplan') {
      selectedRoomRpList.rateplans[item.related_code] = true;
    }
  });

  const toReturn = {
    // name section
    name: data.name,
    parentGroupId: data.parent_group_id,
    // to offer section
    toOffer,
    // dates section
    applicableOnlyForStayDates: !data.checkindatestart,
    stayStartDate: new Date(data.checkindatestart),
    stayEndDate,
    hasNoStayEndDate,
    bkgStartDate: new Date(data.booking_date_start),
    bkgEndDate,
    hasNoBkgEndDate,
    showEditDates: true,
    //restrictions section
    hasMaxLOS: data.max_los !== defaultMaxLosValue,
    maxLos,
    hasMinLOS: data.min_los !== defaultMinLosValue,
    minLos,
    hasEBMin: data.earlybirdmin !== defaultEarlyBirdMin,
    EBMin: earlyBirdMin,
    hasEBMax: data.earlybirdmax !== defaultEarlyBirdMax,
    EBMax: earlyBirdMax,
    applicableOnSelectedDays: data.check_in_weekday.length !== 7,
    selectedDays: checkInWeekDayList,
    // advance settings section
    showBlackOutDates: !!data.checkin_blackout_dates,
    checkInBlackOutDatesArr,
    nonRefundable: data.nonrefundable,
    pahApplicable: data.pah,
    applicableForAllRoomsAndRps: isRelatedListHotel,
    selectedRoomRpList: selectedRoomRpList,
    offerCondition: nightOptions.find(item => data.condition === item.value),
    // configure offer section
    offerType,
    isBundled,
    offerList: [
      {
        allPercentDiscount,
        allFixedDiscount,
        loggedInPercentDiscount,
        loggedInFixedDiscount,
        checkInWeekDayList,
        bookWithinDays,
      },
    ],
    bookNights: data.min_los ? Number(data.min_los) - Number(data.night) : null,
    freeNights: Number(data.night),
    // other keys
    promotionType: dealType,
    isEditData: true,
    offerCode: data.offercode || '',
    is_active: !!data.is_active,
  };

  return toReturn;
}

const transformBlackInclusionBenefitsData = (
  blackInclusionBenefitData: Record<
  CUG_CATEGORY_TYPE_BLACK,
  ICugBlackCategoryServiceData[]
  >,
): Record<string, unknown> => {
  const transformedData = {};
  for (const key in blackInclusionBenefitData) {
    const segmentItem = blackInclusionBenefitData[key] || [];
    for (const segmentServiceItem of segmentItem) {
      const { id, isActive, leafCategoryKey, selectedAttributes } =
        segmentServiceItem ?? {};
      if (leafCategoryKey && !transformedData[leafCategoryKey]) {
        transformedData[leafCategoryKey] = {
          black1: [],
          black2: [],
        };
      }
      transformedData[leafCategoryKey][key]?.push({
        id,
        isActive,
        selectedAttributes,
      });
    }
  }
  return transformedData;
};

export const getPromotionsDetails = (hotelCode, offerCode, parentGroupId) => {
  const params = {
    ...commonGETDetailQueryParams,
    hotelcode: hotelCode,
    offer_code: offerCode,
    parent_group_id: parentGroupId,
  };
  const url = PROMOTIONS.GET_HOTEL_OFFERS;
  return wrappedAxiosCall({ method: 'get', url, params }).then(
    parseDataFrCreate,
  );
};

export function parseDeals(
  res: AllPncDataInterface,
  blackInclusionData: Record<
  CUG_CATEGORY_TYPE_BLACK,
  ICugBlackCategoryServiceData[]
  >,
) {
  const activeLeafCategoryNames = Object.values(
    blackInclusionData || {},
  ).flatMap(items =>
    items.filter(item => item?.isActive).map(item => item.leafCategoryName),
  );
  const uniqueActiveLeafCategoryNames = [...new Set(activeLeafCategoryNames)];
  const blackBenefitOffered = Array.from(uniqueActiveLeafCategoryNames)
    .filter(Boolean)
    .join(', ');

  const pncList = res?.offerDataList?.map(deal => {
    const {
      dealName,
      dealType,
      offerValueDataList,
      offerPerformanceData,
      expiringIn,
      modifiedOn,
      createdOn,
      night,
      minNights,
      offerCode,
      offerCategory,
      isActive,
      checkindatestart,
      checkoutdateend,
      checkinblackoutdates,
      bookingdatestart,
      bookingdateend,
      showModification,
      status,
      earlyBirdMin,
      earlyBirdMax,
      is_editable: isEditable,
      parentGroupId,
      campaignDetails,
    } = deal;

    const type =
      dealType !== DEAL_TYPE_LABEL.coupon && dealType !== DEAL_TYPE_LABEL.cug
        ? DEAL_TYPE_LABEL.promotion
        : dealType;
    const deactivatedOn =
      status === DEAL_STATUS.EXPIRED
        ? getFormattedDate(bookingdateend, listingDateFormat)
        : status === DEAL_STATUS.IN_ACTIVE
          ? getFormattedDate(modifiedOn, listingDateFormat)
          : '-';

    const isMmtBlack = !!offerValueDataList?.find(offer =>
      onlyMmtBlackSegments.includes(offer?.segment),
    );
    const isMmtSelect = !!offerValueDataList?.find(offer =>
      mmtSelectSegments.includes(offer?.segment),
    );
    let lockDate = '';
    const lockDateTill = getFutureDate(checkindatestart, minDuration);
    if (
      status === DEAL_STATUS.ACTIVE &&
      minDuration &&
      onlyDateComparison(new Date(), lockDateTill) <= 0 &&
      (isMmtBlack || isMmtSelect)
    ) {
      lockDate = getFormattedDate(lockDateTill);
    }
    const segment =
      type === DEAL_TYPE_LABEL.cug
        ? isMmtBlack
          ? OFFER_VALUE_SEGMENT_TYPE.MMT_BLACK
          : isMmtSelect
            ? OFFER_VALUE_SEGMENT_TYPE.MMT_SELCT
            : offerValueDataList?.[0]?.segment ===
            OFFER_VALUE_SEGMENT_TYPE.Affiliate
              ? OFFER_VALUE_SEGMENT_TYPE[offerValueDataList?.[1]?.segment || '']
              : OFFER_VALUE_SEGMENT_TYPE[offerValueDataList?.[0]?.segment || '']
        : '';

    return {
      offerCode,
      offerCategory,
      dealName,
      dealType: type,
      status,
      isActive,
      expiringIn,
      offerPerformanceData,
      offerValueDataList: sortOfferValueList(offerValueDataList),
      checkindatestart,
      checkoutdateend,
      checkinblackoutdates: transformBlackoutDates(checkinblackoutdates),
      bookingdatestart,
      bookingdateend,
      isCheckinNoEndDate: new Date(checkoutdateend) >= noEndDate,
      isBookingNoEndDate: new Date(bookingdateend) >= noEndDate,
      lockedInDate: campaignDetails ? null : lockDate, //override on campaign
      modifiedOn,
      createdOn,
      deactivatedOn: deactivatedOn,
      minNights,
      night,
      showModification: campaignDetails ? true : showModification, //override on campaign
      isMmtBlack,
      isMmtSelect,
      segment,
      earlyBirdMin: earlyBirdMin || null,
      earlyBirdMax: earlyBirdMax || null,
      is_editable: isEditable || true,
      parentGroupId: parentGroupId || '',
      campaignDetails,
      blackBenefitOffered:
        isNullOrUndefined(campaignDetails) && isMmtBlack
          ? blackBenefitOffered
          : undefined,
      blackServicesOffered: isMmtBlack
        ? transformBlackInclusionBenefitsData(blackInclusionData)
        : undefined,
    };
  });
  return pncList ? { ...res, offerDataList: pncList } : res;
}

function formatFilterData(filterData: FilterDataInterface) {
  const data = { ...(filterData || {}) };

  if (FILTER_KEYS.BookingDateStart in data) {
    data.bookingStartDate = getFormattedDate(
      data.bookingStartDate,
      apiDateFormat,
    );
  }

  if (FILTER_KEYS.BookingDateEnd in data) {
    data.bookingEndDate = getFormattedDate(data.bookingEndDate, apiDateFormat);
  }

  if (FILTER_KEYS.CheckInDateStart in data) {
    data.checkinDateStart = getFormattedDate(
      data.checkinDateStart,
      apiDateFormat,
    );
  }

  if (FILTER_KEYS.CheckOutDateEnd in data) {
    data.checkoutDateEnd = getFormattedDate(
      data.checkoutDateEnd,
      apiDateFormat,
    );
  }

  if (FILTER_KEYS.OfferCodeList in data) {
    const offerList = data.offerCodeList;
    data.offerCodeList = offerList?.map(offer => offer?.value);
  }
  return data;
}
export const getDealsList = (
  mmtId: string | number,
  COMMON: Common,
  isResellerView: boolean,
  activeDealsTab: PNC_TAB,
  pagination: PaginationInterface,
  sortDealsBy: SortDealInterface,
  filterData: FilterDataInterface,
  showPerformanceData: boolean,
) => {
  const variables = {
    endpointRequestData: {
      fetchServices: {
        fetchServicesRequest: {
          body: {
            ingoHotelId: '',
            hotelId: mmtId,
            rateplancode: '',
            serviceType: 'inclusion',
            segment: ['black1', 'black2'],
            channelManager: '',
            searchRequest: {
              filters: [
                {
                  field: 'EntityType',
                  value: 'hotel',
                },
              ],
            },
            status: ['active'],
          },
        },
      },
    },
    rawEndpointRequestData: {
      rpcGetOffer: {
        modelType: 'modelType1',
        body: {
          mmtId,
          correlationKey: generateCorrelationKey(),
          offSet: (pagination.pageNo - 1) * pagination.size,
          count: pagination.size,
          orderBy: sortDealsBy?.label || '',
          sortOrder: sortDealsBy?.order || '',
          statusList:
            activeDealsTab === PNC_TAB.active
              ? [DEAL_STATUS.ACTIVE]
              : [DEAL_STATUS.EXPIRED, DEAL_STATUS.IN_ACTIVE],
          showPerformanceData: showPerformanceData ?? true,
          ...(isResellerView && { isHotelTravel: true }),
          ...(formatFilterData(filterData) || {}),
        },
      },
      rpcGetHotelDetailForMMTIdV2: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          request_id: generateCorrelationKey(),
          mmt_id: mmtId,
          filterFields: ['blackEnrolledOn'],
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    return doQuery(GET_DEALS_LIST_QUERY, variables, {
      headers: { country: 'in', language: 'en', platform: 'Desktop' },
      useLoader: false,
    })
      .then(res => {
        const data = getValue(res, 'data.rawEndpoint.rpcGetOffer');
        const blackInclusionData = getValue(res, 'data.fetchServices.data');

        if (data?.success) {
          const pncData = data?.data;
          resolve(parseDeals(pncData, blackInclusionData));
        } else {
          reject({
            error: data?.message || COMMON.MESSAGES.SOMETHING_WENT_WRONG,
          });
        }
      })
      .catch(data => {
        reject({
          error: data?.message || COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        });
      });
  });
};

function parseDealsMetaData(
  dealsMetaData: PncMetaDataInterface,
  STRINGS: Promotions,
  isDomHotel: boolean,
) {
  const defaultCount = 0;
  const defaultRecommendedValue = false;

  const updateMetadata = (
    metadata: PromotionMetadataInterface & CugMetadataInterface,
    title: string,
    subtitle: string,
    description: string,
    isRecommended = defaultRecommendedValue,
  ) => {
    metadata.title = title;
    metadata.subTitle = subtitle;
    metadata.description = description;
    metadata.isRecommended = metadata?.metaData?.isRecommended || isRecommended;
    return sortOfferCategoriesByPriority(
      metadata,
      defaultCount,
      defaultRecommendedValue,
      isDomHotel,
    );
  };

  dealsMetaData.PROMOTIONS = updateMetadata(
    dealsMetaData?.PROMOTIONS,
    STRINGS.PROMOTION_REVAMP.PROMOTIONS,
    STRINGS.DEALS.PROMOTIONS_SUBTITLE,
    STRINGS.DEALS.PROMOTIONS_DESC,
  );

  dealsMetaData.CUG = updateMetadata(
    dealsMetaData?.CUG,
    STRINGS.DEALS.CUG_TITLE,
    STRINGS.DEALS.CUG_SUBTITLE,
    STRINGS.DEALS.CUG_DESC,
  );

  const couponMetadata = dealsMetaData?.COUPONS;
  const customised = couponMetadata?.offerCategories?.customised;

  dealsMetaData.COUPONS = {
    ...couponMetadata,
    ...customised,
    title: customised?.title || STRINGS.DEALS.COUPONS_TITLE,
    subTitle: STRINGS.DEALS.COUPONS_SUBTITLE,
    description: customised?.description || STRINGS.DEALS.COUPONS_DESC,
    activeCount: customised?.activeCount || defaultCount,
    expiredCount: customised?.expiredCount || defaultCount,
    inactiveCount: customised?.inactiveCount || defaultCount,
    isRecommended: customised?.isRecommended || defaultRecommendedValue,
  };

  dealsMetaData.isNewUser = getIsNewUser(
    dealsMetaData.PROMOTIONS,
    dealsMetaData.CUG,
    dealsMetaData.COUPONS,
  );

  dealsMetaData.baseOfferValue =
    dealsMetaData.PROMOTIONS?.offerCategories?.date?.maxBaseOfferValue || 0;

  dealsMetaData.affiliates = [];

  return dealsMetaData;
}

export const getDealsMetaData = (
  mmtId: string | number,
  STRINGS: Promotions,
  COMMON: Common,
  isDomHotel: boolean,
  getOffers: number,
  hotelcode: string,
  nextMonday: string,
  nextMondayAfterThreeWeeks: string,
): Promise<{
  parseDealsMetaData;
  netAvailableAndBookedInventory: number;
  bookedInventoryShare: number;
  compsetBookingsOnCug;
}> => {
  const today = getFormattedDate(new Date(), apiDateFormat);
  const sixMonthsFromToday = getFormattedDate(
    getFutureDate(new Date(), -6, 'months'),
    apiDateFormat,
  );
  const variables = {
    rawEndpointRequestData: {
      rpcGetOfferMetadata: {
        modelType: 'modelType1',
        body: {
          mmtId,
          offerBasisList: ['PROMOTIONS', 'CUG', 'COUPONS'],
          getOffers: getOffers,
          correlationKey: generateCorrelationKey(),
          segments: Object.values(CUG_CATEGORY_TYPE_EXCLUSIVE_BLACK),
        },
      },
    },
    NetAvailableAndBookedInventoryRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          pipeline: 'inventory',
          include: {
            idate: {
              gte: getFormattedDate(today, apiDateFormat),
              lte: getFormattedDate(today, apiDateFormat),
            },
            'hotel.hotelCode': [hotelcode],
            'room.active': true,
          },
          KPI: [
            {
              netAvailableAndBookedInventory: 'custom',
            },
          ],
          group_by: ['hotel.hotelCode'],
        },
      },
    },
    BookedInventoryShareRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          KPI: [
            {
              bookedInventoryShare: 'custom',
            },
          ],
          include: {
            idate: {
              gte: nextMonday,
              lte: nextMondayAfterThreeWeeks,
            },
            'hotel.hotelCode': [hotelcode],
          },
          pipeline: 'inventory',
        },
      },
    },
    CompsetBookingsOnCugRequestData: {
      analyticsHistogram: {
        modelType: 'modelType1',
        endPointId: 1,
        body: {
          KPI: [
            {
              bookingId: 'value_count',
            },
          ],
          include: {
            confirmStatusValue: ['confirmed'],
            bookingDate: {
              gte: `${sixMonthsFromToday} 00:00:00`, //getting 6 months previous date
              lte: `${today} 00:00:00`,
            },
            hotelId: hotelcode,
          },
          exclude: {
            'cug.id': null,
          },
          pipeline: 'transactions',
          comparison: ['compset'],
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    return doQuery(GET_DEALS_META_DATA_QUERY, variables, {
      headers: { country: 'in', language: 'en', platform: 'Desktop' },
      useLoader: false,
    })
      .then(res => {
        const data = getValue(res, 'data.grpc_rpc.rpcGetOfferMetadata');

        // promotions meta data visible on the cards and CUG screen
        const netAvailableAndBookedInventory = getValue(
          res,
          'data.netAvailableAndBookedInventory.analyticsHistogram.modelType1.data',
        );
        // const bookedInventoryShare = getValue(
        //   res,
        //   'data.bookedInventoryShare.analyticsHistogram.modelType1.data.bookedInventoryShare.value'
        // );
        const compsetBookingsOnCug = getValue(
          res,
          'data.compsetBookingsOnCug.analyticsHistogram.modelType1.data',
        );
        //
        if (data?.success) {
          const dealsMetaData = data?.data;

          resolve({
            parseDealsMetaData: parseDealsMetaData(
              dealsMetaData,
              STRINGS,
              isDomHotel,
            ),
            netAvailableAndBookedInventory: Object.keys(
              netAvailableAndBookedInventory,
            ).length
              ? netAvailableAndBookedInventory['hotel.hotelCode'][hotelcode]
                ?.netAvailable?.value
              : null,
            bookedInventoryShare: null, //not to be shown -> by product
            compsetBookingsOnCug,
          });
        } else {
          reject({
            error: data?.message || COMMON.MESSAGES.SOMETHING_WENT_WRONG,
          });
        }
      })
      .catch(data => {
        reject({
          error: data?.message || COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        });
      });
  });
};

export const createUpdateCug = (
  data,
  mmtId: string | number,
  showGlobalLoader = true,
) => {
  const variables = {
    rawEndpointRequestData: {
      rpcUpdateCug: {
        modelType: 'modelType1',
        body: {
          mmtId: mmtId,
          correlationKey: generateCorrelationKey(),
          offerData: data,
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    doMutation(CREATE_UPDATE_CUG_MUTATION, variables, {
      useLoader: showGlobalLoader,
      headers: { country: 'in', language: 'en', platform: 'Desktop' },
    })
      .then(res => {
        const rpcUpdateCugData = getValue(res, 'data.rawEndpoint.rpcUpdateCug');
        if (rpcUpdateCugData?.success) {
          resolve(rpcUpdateCugData);
        } else {
          reject(rpcUpdateCugData);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const createUpdateCoupon = (data, mmtId: string | number) => {
  const variables = {
    rawEndpointRequestData: {
      rpcUpdateCoupon: {
        modelType: 'modelType1',
        body: {
          mmtId: mmtId,
          correlationKey: generateCorrelationKey(),
          offerData: [data],
        },
      },
    },
  };

  return new Promise((resolve, reject) => {
    doMutation(CREATE_UPDATE_COUPON, variables, {
      headers: { country: 'in', language: 'en', platform: 'Desktop' },
    })
      .then(res => {
        const rpcUpdateCouponData = getValue(
          res,
          'data.rawEndpoint.rpcUpdateCoupon',
        );
        if (rpcUpdateCouponData?.success) {
          resolve(rpcUpdateCouponData);
        } else {
          reject(rpcUpdateCouponData);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const removeCampaign = ({
  hotelId,
  campaignId,
  offerCode,
  finalDiscount,
}: PostPayload) => {
  const payload: PostPayload = {
    hotelId,
    campaignId,
    offerCode,
    participationStatus:
      participationStatusNumMap[participationStatus.terminated],
    finalDiscount,
  };

  return new Promise((resolve, reject) => {
    doQuery(MODIFY_SALE_QUERY, generateModifySaleVariables(payload), {
      headers: {
        country: 'in',
        language: 'en',
        platform: 'Desktop',
        source: 'ingo_web',
      },
    })
      .then(response => {
        const error = response?.errors;
        const internalError =
          response?.data?.rawEndpoint?.rpcModifyCampaignMappingDetails
            ?.errorDetail;
        if (
          (internalError && internalError?.errorMessage?.length) ||
          (error && error[0]?.message?.length)
        ) {
          const errMsg = internalError?.errorMessage ?? error?.message;
          reject(errMsg);
        } else {
          resolve(response);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
};
