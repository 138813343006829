import { isNullOrUndefined } from 'utilities/Utils';
import { enigmaBase } from './APIClient';

/**
 * @private
 * @description
 * This is an global API collection, where we have have API endpoints. Which are shared access various categories.
 */
const globalAPI = {
  ANALYTICS_DOC: hotelCode => `/api/v1/analytics/${hotelCode}/doc/`,
  ANALYTICS_HISTOGRAM_V2: 'api/v2/analytics/histogram',
  REVIEWS: hotelCode => `/api/v2/reviews/${hotelCode}/`,
  VCC_PASSWORD: hotelCode => `/api/v2/vcc/${hotelCode}/voucher-password`,
  INSTAY_ISSUES: hotelCode => `/api/v2/reviews/${hotelCode}/instay/`,
  FETCH_BOOKINGS: '/api/v1/booking/fetch-bookings/',
  HOUSE_RULES_CATEGORIES: '/api/v2/get-policy-category/',
  POLICIES_V2: '/api/v2/hotel-policy-v2/',
  SPACES: '/api/v2/spaces/',
  HOTEL_METADATA: hotelCode => `/api/v2/hotel/${hotelCode}/metadata/`,
};

const USER = {
  REGISTRATION: `${enigmaBase}/api/v1/account/register`,
  LOGIN: `${enigmaBase}/api/v1/account/login`,
  PASSWORD_RESET: `${enigmaBase}/api/v1/account/reset`,
  CONFIRM_PASSWORD_RESET: `${enigmaBase}/api/v1/account/confirmReset`,
  CHANGE_PASSWORD: `${enigmaBase}/api/v1/account/changePassword`,
  ACTIVATE_ACCOUNT: `${enigmaBase}/api/v1/account/complete`,
  LOGOUT: `${enigmaBase}/api/v1/account/logout`,
};

const APP_MAIN = {
  INITIAL_DATA: '/extranet/api/initial-data',
  SANDESH_LOGIN: '/api/v1/sandesh/',
  LOGIN: 'api/v2/users/info/',
  STRINGS: lang => {
    const baseURL = import.meta.env.BASE_URL;
    return `${baseURL}strings/${lang}/strings.${window.STRINGS_HASH}.json`;
  },
  HOTEL_DETAIL: hotelCode => globalAPI.HOTEL_METADATA(hotelCode),
  V2_HOTEL: hotelCode => `/api/v2/hotel/${hotelCode}/`,
  GET_GENERAL_DATA: '/api/v2/common/get-general-data/',
  RATEPLAN_REORDER: 'api/v1/service/reorder',
  GET_MISC_DETAILS: hotelCode =>
    `/extranet/api/getmiscdetails/?hotelcode=${hotelCode}`,
  CT_DETAILS: hotelCode =>
    `/extranet/api/get-hotel-invoicing-data?hotelcode=${hotelCode}`,
  GST_CT_DETAILS: hotelCode =>
    `/extranet/api/cleartax/openDashboard/?hotelcode=${hotelCode}`,
  ENROLL_TAX_INVOICING: '/extranet/api/enroll-hotelier/',
  ENROLL_CLEAR_TAX_GST: '/api/v1/cleartax/register_user',
  UNENROLL_TAX_INVOICING: '/extranet/api/unenroll-hotelier/',
  UNENROLL_GST_CLEARTAX: '/api/v1/cleartax/unregister_user',
  GENERAL_DETAILS: '/extranet/api/property/getallgeneraldetails/',
  FETCH_ALL_PERSUASIONS: hotel => globalAPI.ANALYTICS_DOC(hotel),
  FETCH_INSTAY_ISSUES_COUNT: hotelCode => globalAPI.INSTAY_ISSUES(hotelCode),
  GET_HOTEL_INFO: '/api/v1/hotel/get-hotel-info/',
  UPDATE_HOTEL_INFO: '/api/v1/hotel/update-hotel-info/',
  GST: hotelCode => `/api/v1/hotel/${hotelCode}/gst/`,
  UPDATE_TAN_NUMBER: '/api/v1/hotels/update-tan/',
  DECIDE_TAN_POPUP: hotelCode =>
    `/api/v1/hotels/decide_tan_popup/?hotelcode=${hotelCode}`,
  ACCEPT_FCL_TNC: hotelCode => `/api/v1/hotel/${hotelCode}/accept-agreement/`,
  DECIDE_FCL_POPUP: hotelCode =>
    `/api/v1/hotel/${hotelCode}/decide-agreement-popup/`,
};

const PROMOTIONS = {
  GET_HOTEL_OFFERS: '/api/v2/hoteloffer/',
  CREATE_MULTIPLE_HOTEL_OFFERS: '/api/v1/hoteloffer/create-multiple-offers/',
  UPDATE_HOTEL_OFFER: '/api/v2/hoteloffer/update-offer/',
  GET_COUNTS: '/api/v2/hoteloffer/counts/',
  GET_RECOMMENDATIONS: hotelCode =>
    `/api/v1/analytics/recommendations/promotion/${hotelCode}/`,
};

const INVENTORY = {
  HOTEL_INVENTORY: '/api/v1/hotel-inventory/',
  HOTEL_RATES: '/api/v1/hotel-rates/',
  GET_DOCUMENT_TREND: hotel => globalAPI.ANALYTICS_DOC(hotel),
  LINK_UNLINK: '/api/v1/inventory/linkrateplan/',
  CREATE_RATE_PLAN: '/api/v2/rateplan/',
  UPDATE_RATE_PLAN: rateplanCode => `/api/v2/rateplan/${rateplanCode}/`,
  AUTO_UPDATE_RATES: '/api/v1/static-copy-ari/',
};

const ANALYTICS = {
  GET_RATINGS: hotel => `/api/v2/ratings/${hotel}/`,
  GET_REVIEWS: hotel => globalAPI.REVIEWS(hotel),
};

const REVIEWS = {
  GET_REVIEWS: hotelCode => globalAPI.REVIEWS(hotelCode),
  GET_REVIEWS_COUNTS: hotelCode => `${globalAPI.REVIEWS(hotelCode)}counts/`,
  POST_REVIEW_REPLY_GI: reviewId => `/api/v1/bookingreview/${reviewId}/reply/`,
  POST_REVIEW_REPLY_MMT: reviewId =>
    `/api/v2/reviews/${encodeURIComponent(reviewId)}/reply/`,
  GET_QUESTIONS: voyagerId => `/api/v1/questions/${voyagerId}/`,
  POST_QUESTION_REPLY_GI: questionId =>
    `/api/v1/questions/${questionId}/reply/`,
  UPLOAD_PHOTOS: () => '/extranet/manageproperty/images/upload/',
  DOWNLOAD_RATINGS_PDF: `${enigmaBase}/api/v1/generate-shortlink-pdf`,
};

const DASHBOARD = {
  GET_DOCUMENT_TREND: hotel => globalAPI.ANALYTICS_DOC(hotel),
  GET_TODAYS_CHECKINS: hotel => `/api/v1/analytics/${hotel}/todays-checkins/`,
  GET_SELLABILITY_SHARE: globalAPI.ANALYTICS_HISTOGRAM_V2,
  GET_TODAYS_AVG_ROOM_RENT: globalAPI.ANALYTICS_HISTOGRAM_V2,

  // API in use
  GET_UNANSWERED_REVIEWS: hotel => globalAPI.REVIEWS(hotel),
  BOOKING_STATUS_API_WITH_WEBHOOK: bookingId =>
    `/api/v1/booking/${bookingId}/hotel-booking-status/`,

  UPDATE_PERSUASIONS: hotelCode =>
    `/api/v1/analytics/persuasions/${hotelCode}/update/`,
  GET_HOTEL: hotel => `/api/v1/hotel/${hotel}/`,
  DOWNLOAD_APP_LINK: '/api/v1/sandesh-transaction/',
  FETCH_BOOKINGS: globalAPI.FETCH_BOOKINGS,
  GET_COUNTRIES_LIST: '/api/v2/countries/data/',
  GET_BANK_PERSUASION: hotelCode =>
    `/api/v2/bank_details/${hotelCode}/bank_detail/`,
};

const USERMANAGEMENT = {
  UM_WRAPPER_FOR_OTP: '/api/v2/verifyuser/',
  UM_FETCH_USERS: '/api/v2/users/',
  UM_ADD_USER: '/api/v2/users/',
  UM_UPDATE_USER: userid => `/api/v2/users/${userid}/`,
  UM_RESEND_EMAIL_VERIFICATION: `${enigmaBase}/api/v1/account/resendVerificationEmail`,
  UM_DEACTIVATE_USER: userid => `/api/v2/users/${userid}/deactivate/`,
  UM_EXISTING_USER: '/api/v2/existingcheck/',
  IM_SEND_OTP: '/api/v1/instant_messenger/otp/',
  IM_VERIFY_OTP: '/api/v1/instant_messenger/',
  IM_WHATSAPP_SUBSCRIBE: '/api/v1/instant_messenger/',
  RESET_PASSWORD: '/api/v2/resetpassword/',
  HOSTS_LIST: '/api/v2/hostlist/',
  UPLOAD_PROFILE_PIC: userid => `/api/v2/hostlist/${userid}/upload/`,
  PREFERENCES: '/api/v2/preferences/',
  VERIFY_EXISTING_USER: `${enigmaBase}/api/v1/manage-account/verifyExistingUser`,
  VERIFY_USER_AND_CREATE_PWD: `${enigmaBase}/api/v1/manage-account/verifyNewUser`,
};

const ADDONS = {
  FETCH_HOTEL_ADDONS: hotelId => `/api/v1/add_on/?hotelcode=${hotelId}`,
  MANAGE_ADDON: addonId => `/api/v1/add_on/${addonId}/`,
  SEND_ADDON_FOR_MODERATION: addonId =>
    `/api/v1/add_on/${addonId}/send-addons-for-moderation/`,
  GET_ADDON_GENERAL_DATA: '/api/v1/add_on/general-data/',
  CREATE_ADDON: '/api/v1/add_on/',
};

const ONBOARDING = {
  AGREEMENT_INFO_ENDPOINT: hotelCode => `/api/v2/enroll/hotel/${hotelCode}/`,
  GET_PROPERTY_IMAGES: (hotelcode, roomcode) => {
    if (roomcode) {
      return `/api/v2/imagedetail/${hotelcode}/?roomcode=${roomcode}`;
    }
    return `/api/v2/imagedetail/${hotelcode}/`;
  },
  CREATE_ROOM: '/api/v2/enroll/room/',
  UPDATE_SUB_ROOM: roomId => `/api/v2/enroll/room/${roomId}/`,
  CREATE_CANCELLATION: '/api/v2/cancellation_rule/',
  DELETE_CANCELLATION: hotelCode =>
    `/api/v2/cancellation_rule/delete/?hotelcode=${hotelCode}`,
  POST_SUB_ROOM: '/api/v2/enroll/room/',
  // Basic info tab
  BASIC_INFO: {
    RESEND_FCL: id => `/api/v2/enroll/info/${id}/regenerate-link/`,
    HOST_INFO_END_POINT: '/api/v2/host/',
  },

  // Location tab
  LOCATION: {
    GET_CITY_CODE_ENDPOINT: city => `/api/v2/cities/?city=${city}`,
    GET_LOCALITY_LIST_ENDPOINT: city => `/api/v2/locality/${city}/`,
    CREATE_HOTEL_ENDPOINT: '/api/v2/enroll/hotel/', //endpoint
    TNC_INFO_ENDPOINT: '/api/v2/hotel-agreement/',
  },

  // FINANCE tab
  FINANCE: {
    DOCUMENT_ENDPOINT: '/api/v2/document/',
    EMAIL_COMMUNICATION_ENDPOINT: '/api/v2/enroll_communication/',
    VALIDATE_FINANCE_DETAILS_ENDPOINT: '/api/v2/validate_finance/',
    AADHAR_OTP_GENERATE: '/api/v2/idcheck/generate-otp/',
    AADHAR_OTP_SUBMIT: '/api/v2/idcheck/submit-otp/',
    VALIDATE_OCR: '/api/v2/idcheck/validate-ocr/',
    GET_OWNERSHIP_DOCUMENT: `${enigmaBase}/api/v1/get-documents`,
  },
};

const PROPERTY = {
  AMENITIES_V2_MAPPING: '/api/v2/amenities-v2-mapping/',
  GET_LOCUS_DETAILS: (lat, lng) =>
    `/api/v2/fetch-location-data/?latitude=${lat}&longitude=${lng}`,
  FETCH_CONTENT_SCORE_TREND: hotelCode =>
    `/api/v2/hotel/content-score/${hotelCode}/`,

  FETCH_MEDIA: '/api/v2/media/fetch-media/',
  MEDIA: mediaId => `/api/v2/media/${mediaId}/`,

  // Video section
  VIDEOS: {
    VIDEO_BASE: id =>
      isNullOrUndefined(id) ? '/api/v2/videos/' : `/api/v2/videos/${id}/`,
    VIDEO_DETAILS: hotelCode => `/api/v2/videodetail/${hotelCode}/`,
  },

  // Settings Tab
  SETTINGS: {
    UPDATE_VCC_PASSWORD: hotelCode => globalAPI.VCC_PASSWORD(hotelCode),
    VCC_OTP: hotelCode => `${globalAPI.VCC_PASSWORD(hotelCode)}/otp`,
    RESET_VCC_PASSWORD: hotelCode =>
      `${globalAPI.VCC_PASSWORD(hotelCode)}/reset`,
    PARTNERS_INFO: '/api/v1/client/',
    ACTIVATE_DEACTIVATE_PARTNERS: '/api/v1/client/activate-deactivate-client/',
    DELETE_PARTNERS: '/api/v1/client/delete-client/',
    PAH_PRE_PAY_RULE: '/api/v1/pre_pay_rule/',
    PAH_BLACKOUT_DATES: '/api/v1/pay_at_hotel_blackout/',
    UPDATE_BLACKOUT_DATES:
      '/api/v1/pay_at_hotel_blackout/manage_pah_blackouts/',
    PAH_INVENTORY_LIMIT: '/api/v1/room/get-pah-inventory-limit/',
    UPDATE_PAH_INVENTORY_LIMIT: '/api/v1/room/update-pah-inventory-limit/',
    PAH_CC_PASSWORD: '/api/v1/hotel_user/cc-password/',
    GET_OFFLINE_BOOKING_URL: '/api/v1/offline-bookings/',
    UPDATE_OFFLINE_BOOKING_URL: '/api/v1/offline-bookings/update/',
  },

  HOW_TO_REACH: {
    ROUTES_CONFIG: '/api/v2/hotel-routes/config/',
    ROUTES: hotelCode => `/api/v2/hotel-routes/${hotelCode}/`,
    POST_ROUTES: '/api/v2/hotel-routes/',
  },

  GET_MEDIA_TAGS: '/api/v2/media-tags/',

  RULES_AND_POLICIES: {
    CATEGORIES: globalAPI.HOUSE_RULES_CATEGORIES,
    RULES: globalAPI.POLICIES_V2,
    PUT_ROUTE: globalAPI.POLICIES_V2,
    POLICIES_SEARCH: '/api/v2/policy-search/',
    GRACE_CANCEL: '/api/v2/cancellation_rule/',
    CREATE_CANCELLATION_V3: '/api/v3/cancellation_policy/',
    DELETE_CANCELLATION_V3: '/api/v3/cancellation_policy/delete/',
    REGISTRATION_DETAILS_POST: '/api/v2/documents/',
  },
  //Room tab
  ROOM: {
    BASE_ROOM: '/api/v1/room/',
    SINGLE_ROOM: roomCode => `/api/v1/room/${roomCode}/`,
    BASE_RP: '/api/v2/rateplan/',
    SINGLE_RP: rpCode => `/api/v2/rateplan/${rpCode}/`,
  },
  BANK_DETAILS: {
    GET_BANK_DETAILS: hotelCode =>
      `/api/v1/bank_details/${hotelCode}/bank_detail/`,
    GET_BANK_LIST: hotelCode => `/api/v1/bank_details/${hotelCode}/banks`,
    GET_CURRENCY_CODES: hotelCode =>
      `/api/v1/bank_details/${hotelCode}/currency`,
    GET_DOCUMENT: hotelCode => `/api/v1/bank_details/${hotelCode}/doc`,
    GET_SERVICE_CHARGE: hotelCode =>
      `/api/v1/gst/?formname=gsttaxinfo&hotelcode=${hotelCode}&platform=extranet&page_size=100`,
    UPDATE_SERVICE_CHARGE: gstID => `/api/v1/gst/${gstID}/`,
    GET_SERVICE_TAX: hotelCode =>
      `/api/v1/tax/?formname=taxinfo&hotelcode=${hotelCode}&platform=extranet&page_size=100`,
    UPDATE_SERVICE_TAX: taxCode => `/api/v1/tax/${taxCode}/`,
    CREATE_SERVICE_TAX: () => '/api/v1/tax/',
    UPDATE_BANK_DETAILS: hotelCode =>
      `/api/v1/bank_details/${hotelCode}/records`,
    ADHAR_VERIFICATION: hotelCode =>
      `/api/v1/bank_details/${hotelCode}/adhar_verify`,
    GET_CURRENCIES: hotelCode => `/api/v1/bank_details/${hotelCode}/currency`,
    TOGGLE_PAYMENT: hotelCode =>
      `/api/v1/bank_details/${hotelCode}/payment_status`,
    TRIGGER_ACCESS_CODE_OTP: () => '/api/v1/bank_details/otp/generate',
    RESET_ACCESS_CODE: () => '/api/v1/bank_details/accesscode/reset',
    VALIDATE_OTP: () => '/api/v1/bank_details/otp/validate',
    VALIDATE_ACCESS_CODE: () => '/api/v1/bank_details/accesscode/validate',
    GET_HISTORY: hotelCode => `/api/v1/bank_details/${hotelCode}/history`,
    GET_DOCUMENTS: hotelCode => `/api/v2/document/?hotelcode=${hotelCode}`,
    GET_CITY_AGREEMENTS: cityId =>
      `${ONBOARDING.LOCATION.TNC_INFO_ENDPOINT}?cityId=${cityId}`,
    ACCEPT_AGREEMENTS: () => '/api/v2/hotel-agreement/',
    ACCEPT_FCL_TC: hotelCode => `/api/v1/hotel/${hotelCode}/accept-agreement/`,
    NOC_AGREEMENTS: hotelCode => `/api/v2/enroll/hotel/${hotelCode}/`,
    UPLOAD_DOCUMENT: hotelCode => `/api/v2/document/?hotelcode=${hotelCode}`,
    UPDATE_DOCUMENT: id => `/api/v2/document/${id}/`,
    FETCH_DOCUMENT: documentId =>
      `${enigmaBase}/api/v3/fetch-document/?documentId=${documentId}`,
    GET_QC_REASONS: hotelCode => `/api/v2/hotelstate/${hotelCode}/`,
    PUSH_TO_STATE_MACHINE: hotelCode =>
      `/api/v2/hotelstate/${hotelCode}/push-to-state-machine/`,
    PUSH_FOR_FINANCE_APPROVAL: hotelCode =>
      `/api/v1/accounts/${hotelCode}/push-for-bank-approval/`,
  },
  FOOD_SERVICE: {
    CATEGORIES: globalAPI.HOUSE_RULES_CATEGORIES,
    FOOD_CONTENT: globalAPI.POLICIES_V2,
    SPACES: globalAPI.SPACES,
    SPACE_BY_ID: spaceId => `${globalAPI.SPACES}/${spaceId}/`,
    SPACE_TEMPLATE: '/api/v2/policy/space-policy-templates/',
    SPACE_POLICY: '/api/v2/space-policy-v2/',
  },
};

const COUPON_DISCOUNT = {
  GET_COUPONS: (hotelCode, status) =>
    `/api/v1/discount-coupons/?hotelcode=${hotelCode}&status=${status}`,
  GET_COUPON_STATS: hotelCode =>
    `/api/v1/hoteloffer/coupon-stats/?hotelcode=${hotelCode}`,
  CREATE_COUPON: '/api/v1/discount-coupons/',
  UPDATE_COUPON_STATUS: '/api/v2/hoteloffer/offer-status/',
  EDIT_COUPON: (offerCode, hotelCode) =>
    `/api/v1/discount-coupons/${offerCode}/?hotelcode=${hotelCode}`,
};

const PERFORMANCE_BOOSTER = {
  COUPONS: '/api/v1/discount-coupons/',
  GET_VDI: '/api/v1/vdi/',
  CUG_OFFERS: '/api/v2/hotelcugoffer/',
  CUG_META_DATA: '/api/v2/hotelcugoffer/cug_meta_data/',
  UPDATE_BOOSTER_STATUS: '/api/v2/hoteloffer/offer-status/',
  EDIT_COUPON: offerCode => `/api/v1/discount-coupons/${offerCode}/`,
  EDIT_CUG_OFFER: offerCode => `/api/v2/hotelcugoffer/${offerCode}/`,
  UPDATE_VDI: '/api/v1/vdi/update-vdi/',
};

const FOOTER = {
  GET_FOOTER_DETAILS: hotelCode => `/api/v2/hotel/${hotelCode}/staff/`,
};

const VDI = {
  GET_VDIS: (hotelCode, status) =>
    `/api/v1/vdi/?hotelcode=${hotelCode}&status=${status}`,
  SUBMIT_VDIS: '/api/v1/vdi/update-vdi/',
  FETCH_VDI_TO_SHOW_CARD: hotelCode =>
    `/api/v1/vdi/show-vdi/?hotelcode=${hotelCode}`,
};

const GUEST_CHAT = {
  AUTH: hotelCode => `/api/v2/guest-chat/helper/${hotelCode}/auth/`,
  COUNTS: hotelCode => `/api/v2/guest-chat/helper/${hotelCode}/message-count/`,
  GET_GUEST_BLOCK_STATUS: customerUUID =>
    `/api/v2/guest-chat/user/${customerUUID}/block-status/`,
  BLOCK_GUEST: customerUUID => `/api/v2/guest-chat/user/${customerUUID}/block/`,
  CLOSE_SESSION: '/api/v2/guest-chat/session/close/',
  FETCH_POLICIES: hotelCode =>
    `/api/v2/guest-chat/helper/${hotelCode}/policies/`,
  GET_BROADCAST_CATEGORIES: '/api/v2/guest-chat/helper/broadcast-categories/',
  GET_BOOKINGS_FOR_SELECTED_BROADCAST_CATEGORY: hotelCode =>
    `/api/v2/guest-chat/helper/${hotelCode}/broadcast-bookings/`,
  BROADCAST_TO_BKGS: hotelCode =>
    `/api/v2/guest-chat/helper/${hotelCode}/broadcast/`,
  SEND_REQUEST_REVIEW_LINK: hotelCode =>
    `/api/v2/guest-chat/helper/${hotelCode}/request-review/`,
  // provisionalBookingId is an Interim booking ID
  GET_PROVISIONAL_BOOKING_DETAILS: provisionalBookingId =>
    `/api/v2/rtb_booking/rtb-detail/?probookingid=${provisionalBookingId}`,
  APPROVE_OR_REJECT_RTB: '/api/hotels/rtb_action/',
  CREATE_PREAPPROVED_INVITE: '/api/v1/initiatetobook/',
  WITHDRAW_PREAPPROVED_INVITE: '/api/v1/initiatetobook/withdraw/',
};

const BOOKING_REVAMP = {
  VIEW_MMT_VOUCHER: (bookingId, hotelCode) =>
    `/hotels/oldmmthotelvoucher/${bookingId}/?hotelcode=${hotelCode}`,
  VIEW_GI_VOUCHER: (bookingId, hotelCode) =>
    `/hotels/hotelvoucher/${bookingId}/?hotelcode=${hotelCode}`,
  UPLOAD_INVOICE: '/api/v1/gstn-invoice/upload-deed/',
  DOWNLOAD_WAVIER_DOCUMENT: '/api/v1/booking/download_waiver_document/',
  CHECK_CC_PASSWORD: '/api/v1/hotel_user/check-cc-password/',
  VALIDATE_VCC_PASSWORD: hotelCode =>
    `${globalAPI.VCC_PASSWORD(hotelCode)}/validate`,
  VCC_PASSWORD_VALIDATION_SUCCESS: bookingId =>
    `/api/v1/payment/${bookingId}/voucher/`,
  CUSTOMER_INVOICE_URL: (bookingId, hotelcode) =>
    `/hotels/hotel_tax_invoice/?reporttype=hotel-tax&bookingId=${bookingId}&hid=${hotelcode}`,
  DOWNLOAD_BOOKINGS: '/api/v1/booking/download-bookings/',
  GSTN_KNOW_MORE: 'https://partners.go-mmt.com/support/solutions/81000097994',
  REQUEST_REVIEW: uid => `/api/v1/bookingreview/${uid}/requestreview/`,
};

const BOOKING = {
  GET_DCB_BOOKING: '/api/v2/booking/direct-connect-bookings/',
  GET_NORMAL_BOOKINGS: '/api/v1/booking/fetch-bookings/',
  GET_REVIEW_STATUS: '/api/v1/bookingreview/status/',
  GET_CANCEL_BOOKINGS: '/api/v1/cancellation/fetch-cancellations/',
  GET_PAYMENTS_BOOKINGS: '/api/v1/payment/fetch-transactions/',
  GET_ADJUSTMENT_DATA: '/api/v1/payment/fetch-adjustments/',
  FETCH_MO_DETAILS_DCB: bookingId =>
    `/api/v1/payment/mo-payment/${bookingId}/detail-dc/`,
  FETCH_MO_DETAILS: bookingId =>
    `/api/v1/payment/mo-payment/${bookingId}/detail/`,
  UPDATE_BOOKING_STATUS: bookingId =>
    `/api/v1/booking/${bookingId}/hotel-booking-status/`,
  FETCH_CUSTOMER_DETAILS: bookingId =>
    `/api/v1/booking/${bookingId}/fetch-customer-details/`,
  FETCH_SPECIFIC_BOOKING_BY_ID: bookingId =>
    `/api/v1/booking/${bookingId}/fetch-hotel-booking-voucher/`,
  FETCH_MULTI_BOOKING_BY_ID: bookingId =>
    `/api/v1/multibooking/${bookingId}/fetch-multi-booking-voucher/`,
  MAIL_DCB_VOUCHER: '/api/v1/booking/send-oldmmt-booking-voucher/',
  MAIL_PAYMT_VOUCHER: paymentId =>
    `/api/v1/payment/${paymentId}/resend-payment-voucher/`,
  FETCH_PAYMT_VOUCEHR: paymentId =>
    `/api/v1/payment/${paymentId}/fetch-payment-voucher/`,
  FETCH_RELATED_BOOKING_CANCELLATION: paymentId =>
    `/api/v1/payment/${paymentId}/fetch-related-bookings-cancellations/`,
  FETCH_DCB_VCC: '/api/v2/vcc/fetch',
  UPDATE_DCB_STATUS: bookingId =>
    `/api/v2/booking/direct-connect-booking-status/${bookingId}/`,
  FETCH_MO_PAYMT: hotelcode =>
    `/api/v1/payment/mo-hotel-payment/${hotelcode}/summary/`,
  FETCH_MO_BOOKING: hotelcode =>
    `/api/v1/payment/mo-hotel-payment/${hotelcode}/detail/`,
  VCC_REALIZATION: '/api/v2/vcc/get_realization',
  CHECK_CC_PASSWORD: '/api/v1/hotel_user/check-cc-password/',
  ON_GSTN_INVOICE_UPLOAD: '/api/v1/gstn-invoice/upload-deed/',
  INITIAVE_WAIVER: '/api/v1/booking/initiate_waiver/',
  GET_WAIVER_DETAIL: (bid, isMultiRoom) =>
    `/api/v1/booking/waiver-details/?booking_id=${bid}&is_multiroom=${isMultiRoom}`,
  VALIDATE_VCC_PASSWORD: hotelCode =>
    `${globalAPI.VCC_PASSWORD(hotelCode)}/validate`,
  GET_VOUCHER_EMAILS: hotelcode =>
    `/api/v1/booking/${hotelcode}/fetch-booking-voucher/`,
  SEND_VOUCHER_EMAILS: (hotelcode, vendorBookingId) =>
    `/api/v1/booking/send-oldmmt-booking-voucher/?hotelcode=${hotelcode}&bookingid=${vendorBookingId}`,
  RESEND_VOUCHER_EMAILS: bookingId =>
    `/api/v1/booking/${bookingId}/resend-booking-voucher/`,
  DOWNLOAD_WAVIER_DOCUMENT: '/api/v1/booking/download_waiver_document/',
  PAYMENT_VOUCHER: paymentId => `/api/v1/payment/${paymentId}/voucher/`,
  DOWNLOAD_BOOKINGS: '/api/v1/booking/download-bookings/',
  CUSTOMER_INVOICE_URL: (bookingId, hotelcode) =>
    `/hotels/hotel_tax_invoice/?reporttype=hotel-tax&bookingId=${bookingId}&hid=${hotelcode}`,
  VIEW_MMT_VOUCHER: (bookingId, hotelcode) =>
    `/hotels/oldmmthotelvoucher/${bookingId}/?hotelcode=${hotelcode}`,
  DOWNLOAD_CANCELLATION: '/api/v1/cancellation/download-cancellations/',
  DOWNLOAD_MOPAYMENT_DETAIL: hotelcode =>
    `/api/v1/payment/mo-hotel-payment/${hotelcode}/download-payment-detail/`,
  DOWNLOAD_MOPAYMENT_DETAIL_V2: hotelcode =>
    `/api/v1/payment/mo-hotel-payment/${hotelcode}/download-payment-detail-v2/`,
  DOWNLOAD_MOPAYMENT_SUMMARY: hotelcode =>
    `/api/v1/payment/mo-hotel-payment/${hotelcode}/download-payment-summary/`,
  DOWNLOAD_TRANSACTION: '/api/v1/payment/download-transactions/',
  VIEW_PAYMENT_VOUCHER: paymentid => `/api/v1/payment/${paymentid}/voucher/`,
  VIEW_BOOKING_VOUCHER: (bookingId, hotelcode) =>
    `/hotels/hotelvoucher/${bookingId}/?hotelcode=${hotelcode}`,
  VCC_PASSWORD_VALIDATION_SUCCESS: bookingId =>
    `/api/v1/payment/${bookingId}/voucher/`,
};

const RATE_RECOMMENDATION = {
  RECOMMENDATION_CONFIG: hotelCode =>
    `/api/v1/analytics/recommendations/price/${hotelCode}/conf/`,
};

const QS = {
  state: hotelCode => `/api/v1/hotel/quality-score/${hotelCode}/`,
  summary: hotelCode => `/api/v1/hotel/quality-score/${hotelCode}/summary/`,
  tasks: hotelCode => `/api/v1/hotel/quality-score/${hotelCode}/tasks/`,
  benefits: hotelCode => `/api/v1/hotel/quality-score/${hotelCode}/benefits/`,
  updateTask: hotelCode =>
    `/api/v1/hotel/quality-score/${hotelCode}/update-task/`,
  tutorial: hotelCode => `/api/v1/hotel/quality-score/${hotelCode}/tutorial/`,
  qstrend: hotelCode => `/api/v1/hotel/quality-score/${hotelCode}/trend/`,
};
const SERVICES = {
  GET_SERVICE_TEMPLATE: type => `/api/v2/service-template/?type=${type}`,
  GET_ELIBIGLE_RATEPLANS: '/api/v2/eligible-rateplan/',
  SERVICE: '/api/v2/service/',
  SINGLE_SERVICE: id => `/api/v2/service/${id}/`,
  GET_RECOMMENDATIONS: hotelCode =>
    `/api/v2/service-suggestion/${hotelCode}/hotel/`,
  BULK_CREATE: '/api/v2/service/multi-create/',
};

const INSTAY = {
  GET_INSTAY_ISSUES: hotelCode => globalAPI.INSTAY_ISSUES(hotelCode),
  UPDATE_INSTAY_ISSUES: hotelCode =>
    `/api/v2/reviews/${hotelCode}/update-instay/`,
};

const REPORT = {
  // TODO : if in dev env enigma giving error replace with prod url
  FETCH_INVENTORY: hotelCode => `/api/v1/reports/inventory/${hotelCode}/`,
  FETCH_RATES: hotelCode => `/api/v1/reports/rates/${hotelCode}/`,
  FETCH_OFFERS: hotelCode => `/api/v1/reports/offers/${hotelCode}/`,
  FETCH_BOOKINGS: hotelCode => `/api/v1/reports/booking/${hotelCode}/`,
  FETCH_INVOICES: hotelCode => `/api/v1/reports/invoice/${hotelCode}/`,
  FETCH_PLB: hotelCode => `/api/v1/payment/${hotelCode}/get_plb_rules`,
  FETCH_VDI: hotelCode => `/api/v1/payment/${hotelCode}/get_vdi_rules`,
  GET_FRN_SUMMARY: hotelCode => `/api/v1/payment/${hotelCode}/get_frn_summary`,
  GET_ADT_SUMMARY: hotelCode => `/api/v1/payment/${hotelCode}/get_adt_summary`,
  GET_ADT_DETAILS: hotelCode => `/api/v1/payment/${hotelCode}/get_adt_detail`,
  // Reports API
  SINGLE_COMBINED_REPORT: hotelCode =>
    `/api/v1/payment/report/combined/${hotelCode}`,
  FRN_SINGLE_REPORT: hotelCode => `/api/v1/payment/report/${hotelCode}/frn`,
  ACRN_SINGLE_REPORT: hotelCode => `/api/v1/payment/report/${hotelCode}/acrn`,
  VDI_SINGLE_REPORT: hotelCode => `/api/v1/payment/report/${hotelCode}/vdi`,
  MIB_SINGLE_REPORT: hotelCode => `/api/v1/payment/report/${hotelCode}/mib`,
  MULTIPLE_REPORT: hotelCode => `/api/v1/payment/report/multi/${hotelCode}`,
  DOWNLOAD_REPORTS: '/api/v1/reports/downloadreport/',
  FETCH_GST_CLEARTAX_DATA: '/api/v1/cleartax/fetch_posted_invoices',
};

const PLB = {
  FETCH_MARGIN_OFFERS: '/api/v1/hoteloffer/',
  FETCH_PLB_ROOMS: '/extranet/api/inventory/plbroomlist/',
  CREATE_PLB_RULE: '/extranet/api/plb/submitplb/',
};
const ADTECH_REPORT = {
  ADTECH_REPORT_DOWNLOAD: `${enigmaBase}/v1/adtech/report/`,
  ADTECH_INVOICE_DOWNLOAD: `${enigmaBase}/v1/adtech/invoice/`,
};

const HOST_WEB_MIGRATION = {
  PREVIEW: hotelCode => `/api/migration-hostweb/migrate/${hotelCode}/`,
  TRIGGER: '/api/migration-hostweb/migrate/',
  METADATA: hotelCode => globalAPI.HOTEL_METADATA(hotelCode),
};

const PREBUY_LEDGER = {
  DOWNLOAD_PREBUY_ORDERS_EXCEL: `${enigmaBase}/api/v1/get-prebuyorders-excel`,
};

export {
  APP_MAIN,
  PROMOTIONS,
  INVENTORY,
  ANALYTICS,
  DASHBOARD,
  USERMANAGEMENT,
  ADDONS,
  PROPERTY,
  COUPON_DISCOUNT,
  PERFORMANCE_BOOSTER,
  FOOTER,
  VDI,
  ONBOARDING,
  GUEST_CHAT,
  BOOKING,
  BOOKING_REVAMP,
  RATE_RECOMMENDATION,
  QS,
  SERVICES,
  INSTAY,
  REPORT,
  PLB,
  ADTECH_REPORT,
  HOST_WEB_MIGRATION,
  REVIEWS,
  USER,
  PREBUY_LEDGER,
};
