import { forwardRef } from 'react';
import {
  ClassNameMap,
  FormControlLabel,
  Switch as SwitchMui,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';

type SwitchProps = {
  label?: string | React.ReactNode | React.ReactNode[];
  name?: string;
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  size?: 'small' | 'medium';
  type?: string;
  disabled?: boolean;
  labelPosition?: 'start' | 'end' | 'top' | 'bottom';
};

type Classes = ClassNameMap<
'root' | 'thumb' | 'checked' | 'track' | 'switchBase' | 'focusVisible'
>;

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiButtonBase-root': {
      padding: '7px',
      left: '-2px',
    },
    '&.MuiSwitch-sizeSmall': {
      '& .MuiButtonBase-root': {
        padding: '2px',
      },
    },
  },
  thumb: {
    position: 'relative',
    top: '2px',
  },
  uncheckedRoot: {
    '& .MuiButtonBase-root': {
      padding: '7px',
    },
    '&.MuiSwitch-sizeSmall': {
      '& .MuiButtonBase-root': {
        padding: '2px',
      },
    },
  },
  defaultRoot: {
    '& .MuiButtonBase-root': {
      top: '-2px',
    },
  },
}));

function SwitchComp(props: SwitchProps, ref) {
  const {
    label = '',
    name = '',
    checked,
    onChange,
    size = 'medium',
    type,
    disabled = false,
    labelPosition = 'end', // default value for label position
  } = props;

  const { root, thumb, uncheckedRoot, defaultRoot } = useStyles();

  const iconSize = size === 'medium' ? 'large' : 'medium';

  let icon = null;
  let iconType = 'checkedIcon';

  if (type === 'close') {
    icon = <CancelIcon fontSize={iconSize} />;
  } else if (type === 'check') {
    icon = <CheckCircleIcon fontSize={iconSize} />;
  }

  if (!checked && icon) {
    icon = <CancelIcon fontSize={iconSize} shapeRendering="rect" />;
    iconType = 'icon';
  }

  const customIcon = icon ? { [iconType]: icon } : {};

  const styles = icon
    ? { root: checked ? root : uncheckedRoot }
    : { root: defaultRoot };

  const switchComp = (
    <SwitchMui
      ref={ref}
      checked={checked}
      onChange={event => onChange(event.target.checked)}
      color="primary"
      name={name}
      size={size}
      disabled={disabled}
      classes={{ ...styles, thumb }}
      {...customIcon}
    />
  );

  return (
    <FormControlLabel
      control={switchComp}
      label={label}
      labelPlacement={labelPosition}
    />
  );
}

const Switch = forwardRef(SwitchComp);

type ControlledSwitchProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = {
  name: TName;
  control: Control<T, unknown>;
  defaultValue?: string;
  isRequired?: boolean;
  customValidator?: RegisterOptions<T, TName>['validate'];
  disabled?: boolean;
  classes?: Classes;
};

function ControlledSwitch<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: ControlledSwitchProps<T, TName>) {
  const {
    name,
    control,
    defaultValue = null,
    isRequired = false,
    customValidator,
    ...additional
  } = props;

  const validations: Partial<RegisterOptions<T, TName>> = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };
  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  const {
    field: { onChange, value, ref },
  } = useController<T, TName>({
    name,
    rules: validations,
    defaultValue: defaultValue as PathValue<T, TName>,
    control,
  });

  return (
    <Switch
      ref={ref}
      checked={value}
      onChange={newValue => onChange(newValue)}
      {...additional}
    />
  );
}

export { ControlledSwitch };

export { Switch };
