/** eslint-disable */
import { useMediaQuery } from '@mui/material';
import {
  memo,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLangStrings } from 'utilities/CustomHooks';
import * as NavigationIcons from '../../assets/modules/navigation';
import { SCREEN_WIDTH_BREAKPOINT } from '../../constants';
import User from '../hotel/Users/User';

import { UserProfileType, UserProfileViewType } from 'app/hotel/constants';
import { routes } from 'app/routes';
import {
  ToggleButton,
  Tooltip,
  Typography,
  Divider,
} from 'components/latest-core';
import { AppContext } from 'utilities/Context';
import { navigateTo } from 'utilities/Utils';
import {
  GTM_CATEGORIES,
  legacyPushToGTM,
  OMNITURE_CATEGORIES,
  pushToOmniture,
} from 'utilities/gtm';
import { fetchChatUnreadCount } from './ChatDiscoveryGraphClient';
import CounterCircle from './CounterCircle';
import { ChatAnimationIcon, ZeroChat } from 'assets/modules/app';

import './NavigationContainer.scss';
type NavigationContainerProps = {
  children?: ReactNode;
  leftNav?: ReactNode;
  headerLeftComponent?: ReactNode;
  headerMiddleComponent?: ReactNode;
  showTopLevelUserProfileSwitcher?: boolean;
  userProfileView?: string;
  setUserProfileView?: React.Dispatch<
  React.SetStateAction<UserProfileViewType>
  >;
  hotelCode?: string;
  isChatEnabled?: boolean;
  pendingPrebuyOrdersCount?: number;
};

const unReadMsgDataInitialState = {
  lastMsg: null,
  count: 0,
};

const getProfileOptions = ({ STRINGS, pendingPrebuyOrdersCount }) => [
  {
    content: STRINGS.NAVIGATION_CONTAINER.INGO_MMT,
    value: UserProfileViewType.INGO,
  },
  {
    content: (
      <div className="flex items-center gap-1">
        {STRINGS.NAVIGATION_CONTAINER.HOTEL_CLOUD}
        <CounterCircle count={pendingPrebuyOrdersCount} />
      </div>
    ),
    value: UserProfileViewType.HOTEL_TRAVEL,
  },
];

const NavigationContainer = (props: NavigationContainerProps) => {
  const {
    children,
    headerLeftComponent,
    headerMiddleComponent,
    leftNav,
    userProfileView,
    setUserProfileView,
    showTopLevelUserProfileSwitcher = false,
    hotelCode,
    isChatEnabled,
    pendingPrebuyOrdersCount,
  } = props;

  const { userProfileType } = useContext(AppContext);
  const [STRINGS, COMMON] = useLangStrings<'Navigation'>('Navigation');

  const isBigScreen = useMediaQuery(
    `(min-width:${SCREEN_WIDTH_BREAKPOINT}px)`,
    { noSsr: true },
  );
  const [showLeftNav, setshowLeftNav] = useState(isBigScreen);
  const [showMenuMessage, setShowMenuMessage] = useState(!isBigScreen);
  const [menuIconHover, setMenuIconHover] = useState(false);
  const [chatUnreadMsgData, setChatUnreadMsgData] = useState(
    unReadMsgDataInitialState,
  );

  const trackChatIconEvent = useCallback(
    (eventType: 'load' | 'hover_load' | 'click') => {
      const cta = {
        name: 'guest_chat_icon',
        componentName: 'property_header|top_right_icon_1',
        type: eventType,
      };
      const { count, lastMsg } = chatUnreadMsgData;
      switch (eventType) {
        case 'load':
          pushToOmniture({
            event: OMNITURE_CATEGORIES.CTA_LOAD,
            cta,
            loadedComponents: `unread_chats:${count}`,
          });

          break;
        case 'hover_load':
          pushToOmniture({
            event: OMNITURE_CATEGORIES.CTA_LOAD,
            cta,
            loadedComponents: `unread_chats:${count}|hover_prompt_text:${lastMsg}`,
          });
          break;
        case 'click':
          pushToOmniture({
            event: OMNITURE_CATEGORIES.CTA_CLICK,
            cta,
            loadedComponents: `unread_chats:${count}`,
          });

          break;
      }
    },
    [chatUnreadMsgData],
  );

  const chatIconEventHandlers = useMemo(() => {
    const onMouseEnter: MouseEventHandler<HTMLElement> = () => {
      trackChatIconEvent('hover_load');
    };
    const onClick = () => {
      trackChatIconEvent('click');
      navigateTo(routes.guestchat.all);
    };
    return {
      onClick,
      onMouseEnter,
    };
  }, [trackChatIconEvent]);

  useEffect(() => {
    const chatDataLoaded = chatUnreadMsgData !== unReadMsgDataInitialState;
    if (!isChatEnabled || !chatDataLoaded) {
      return;
    }
    trackChatIconEvent('load');
  }, [isChatEnabled, chatUnreadMsgData]);

  const onLeftNavIconClick = () => {
    if (window.innerWidth >= SCREEN_WIDTH_BREAKPOINT) {
      let eventAction = '';
      if (showLeftNav === true) {
        eventAction = 'hide';
      } else eventAction = 'show';

      legacyPushToGTM({
        eventCategory: GTM_CATEGORIES.WEB_LEFTDRAWER,
        eventAction: eventAction,
      });

      setshowLeftNav(prev => !prev);
    }
  };

  const LeftNavIcon = useMemo(() => {
    let showToolTip, toolTipContent;

    if (showLeftNav) {
      showToolTip = false;
      toolTipContent = '';
    } else if (isBigScreen) {
      toolTipContent = STRINGS.NAVIGATION_CONTAINER.CLICK_TO_LOCK_SIDEBAR;
      showToolTip = menuIconHover;
    } else {
      toolTipContent = STRINGS.NAVIGATION_CONTAINER.CLICK_TO_SEE_MENU_ITEMS;
      showToolTip = showMenuMessage;
    }

    const menuIcon = (
      <Tooltip content={toolTipContent} bgBlack open={showToolTip}>
        <div>
          <img
            src={NavigationIcons.HamburgerIcon}
            width={24}
            height={24}
            alt=""
            className={`${showLeftNav ? 'show' : 'nav-hide-on-hover'}`}
          />
          <img
            src={NavigationIcons.RightDoubleArrowIcon}
            width={24}
            height={24}
            alt=""
            className={`${showLeftNav ? 'hide' : 'nav-show-on-hover'}`}
          />
        </div>
      </Tooltip>
    );

    return (
      <div className="marginLR12 cur-pntr" onClick={() => onLeftNavIconClick()}>
        {menuIcon}
      </div>
    );
  }, [showLeftNav, showMenuMessage, menuIconHover]);

  const handleUserProfileToggle = val => {
    pushToOmniture({
      event: 'toggle',
      cta: {
        name: val,
        componentName: 'toggle_account',
      },
    });

    setUserProfileView(val);
  };

  const renderHotelTravelInfo = () => {
    switch (userProfileType) {
      case UserProfileType.BOTH:
        return (
          <div>
            <ToggleButton
              roundedChipBtnClass="xmd:text-size-8 lg:text-size-12 xmd:w-[50px]"
              data={getProfileOptions({ STRINGS, pendingPrebuyOrdersCount })}
              value={userProfileView}
              variant="rounded-chip"
              onChange={val => handleUserProfileToggle(val)}
              noGap
            />
          </div>
        );
      case UserProfileType.HOTEL_TRAVEL:
        return <NavigationIcons.HotelTravelIcon className="mr-2" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (showMenuMessage) {
      setTimeout(() => {
        setShowMenuMessage(false);
      }, 8000);
    }
  }, []);

  useEffect(() => {
    if (showLeftNav !== isBigScreen) {
      setshowLeftNav(isBigScreen);
    }
  }, [isBigScreen]);

  useEffect(() => {
    if (hotelCode) {
      fetchChatUnreadCount(hotelCode, { showLoader: false })
        .then(response => {
          const { lastUnreadChatMsg, totalUnreadMsg } = response;
          setChatUnreadMsgData({
            lastMsg: lastUnreadChatMsg,
            count: totalUnreadMsg,
          });
        })
        .catch(error => {
          console.error('fetchChatUnreadCount', error);
        });
    }
  }, [hotelCode]);

  useEffect(() => {
    if (menuIconHover) setShowMenuMessage(false);
  }, [menuIconHover]);

  const tooltipContent = chatUnreadMsgData?.lastMsg ? (
    <div className="tooltipChatWrapper">{chatUnreadMsgData.lastMsg}</div>
  ) : null;
  return (
    <div className="navigation-container">
      <div className="navigation-header">
        <div className="left-nav-icon-container">
          <div className="hover:bg-color-primary hover:rounded-md  flex flex-col items-center justify-center my-1 slg:mx-4 md:ml-4 hover:w-fit hover:h-[50px]">
            <div
              onMouseEnter={() => setMenuIconHover(true)}
              onMouseLeave={() => setMenuIconHover(false)}
            >
              {LeftNavIcon}
            </div>
            <div>
              <Typography variant="legends" themeColor="text.primary">
                {COMMON.LABELS.MENU}
              </Typography>
            </div>
          </div>
          {!showLeftNav && (
            <div className="nav-show-on-hover left-nav-hover">
              <div className="left-nav-container sr-flex1">{leftNav}</div>
            </div>
          )}
        </div>

        <div className="sr-flex1 w-[100%]">
          <div className="sr-flex-rowblock py-[10px] pr-[10px]">
            {headerLeftComponent}
            <div className="sr-flex1">{headerMiddleComponent}</div>
            <div className="flex flex-row items-center lg:gap-5 xmd:gap-2">
              {showTopLevelUserProfileSwitcher && renderHotelTravelInfo()}
              {isChatEnabled && (
                <div className="flex lg:gap-5 xmd:gap-3 items-center">
                  <div
                    className="relative cursor-pointer"
                    {...chatIconEventHandlers}
                  >
                    <Tooltip
                      className="chatUnreadWrapper"
                      content={tooltipContent}
                      bgBlack
                      placement="bottom"
                    >
                      <div className="flex items-center flex-col hover:bg-color-primary hover:rounded-md h-[52px] py-1 px-2">
                        <img
                          src={
                            chatUnreadMsgData?.count === 0
                              ? ZeroChat
                              : ChatAnimationIcon
                          }
                          alt="chat icon"
                          width="40px"
                          height="40px"
                        />
                        {chatUnreadMsgData.count > 0 && (
                          <div className="absolute right-[8px] top-[0px]">
                            <CounterCircle count={chatUnreadMsgData.count} />
                          </div>
                        )}

                        <Typography
                          variant="legends"
                          themeColor="text.primary"
                          className="absolute bottom-[0px]"
                        >
                          {COMMON.LABELS.CHAT}
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                  <Divider orientation="vertical" className="h-[40px]" />
                </div>
              )}
              <User />
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-body">
        {showLeftNav && (
          <div className="sr-flex-col">
            <div
              className="hide-block cur-pntr"
              onClick={() => onLeftNavIconClick()}
            >
              <img
                src={NavigationIcons.LeftDoubleArrowIcon}
                alt=""
                className="marginL12 marginR16"
              />
              <div className="text-gray-light7 font13">
                {STRINGS.NAVIGATION_CONTAINER.HIDE}
              </div>
            </div>
            <div className="sr-flex1 oa">{leftNav}</div>
          </div>
        )}
        <div className="sr-flex1 oa">{children}</div>
      </div>
    </div>
  );
};

export default memo(NavigationContainer);
/** eslint-disable */
