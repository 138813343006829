import { forwardRef } from 'react';
import type { OptionProps, RadioVariantsPropsType } from '../Radio.types';
import RadioBase from './RadioBase';
import { classNames } from 'utilities/Utils';

function RadioLegacyBoxed(
  props: RadioVariantsPropsType,
  ref?: React.Ref<HTMLDivElement>,
) {
  const { classes } = props;
  const { legacyBoxedLabel, legacyCheckedBoxedLabel } = classes;
  const customRadioIcon = (option: OptionProps) => {
    return {
      icon: (
        <span className={classNames(legacyBoxedLabel)}>{option.label}</span>
      ),
      checkedIcon: (
        <span className={classNames(legacyBoxedLabel, legacyCheckedBoxedLabel)}>
          {option.label}
        </span>
      ),
    };
  };
  return <RadioBase ref={ref} customRadioIcon={customRadioIcon} {...props} />;
}

export default forwardRef(RadioLegacyBoxed);
