import { ChevronDown, ChevronUp } from 'assets/common';
import { Popover, Typography } from 'components/latest-core';
import { useState, useRef } from 'react';
import { classNames } from 'utilities/Utils';

type MenuOption = {
  value?: string;
  label: string;
};

type MenuProps = {
  selectedEl: (selectedOption: MenuOption) => React.ReactNode;
  options: MenuOption[];
  value?: MenuOption;
  callback?: Function;
  customStyles?: {
    popover?: string;
  };
  'data-test-id'?: string;
};

export const Menu = (props: MenuProps) => {
  const { selectedEl, options, value, callback, customStyles = {} } = props;
  const menuRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div data-test-id={props['data-test-id']}>
      <div
        className="flex flex-row cursor-pointer"
        ref={menuRef}
        onClick={() => {
          setIsMenuOpen(s => !s);
        }}
      >
        {selectedEl(value)}
        <div className=" rounded-r-[4px] h-[20px] px-[1px]">
          {isMenuOpen ? (
            <ChevronUp width={20} height={20} />
          ) : (
            <ChevronDown width={20} height={20} />
          )}
        </div>
      </div>
      {isMenuOpen && (
        <Popover
          className={classNames(
            'h-[200px] rounded p-1 border border-solid border-color-divider mt-2 scrollbar-none shadow-[0_0_10px_5px_rgba(0,0,0,0.1)]',
            customStyles.popover,
          )}
          anchorEl={menuRef.current}
          open={true}
          onClose={() => {
            setIsMenuOpen(false);
          }}
          anchorVertical="bottom"
          anchorHorizontal="left"
          transformVertical="top"
          transformHorizontal="left"
        >
          {options.map(option => (
            <div
              className={classNames(
                'flex flex-col py-2 px-3 cursor-pointer hover:bg-[#EFF1FF]',
              )}
              onClick={() => callback(option)}
              ref={ref => {
                if (ref && value.label === option.label) ref.scrollIntoView();
              }}
            >
              <Typography
                variant="body1"
                themeColor={
                  value.label === option.label ? 'primary.main' : undefined
                }
                fb={value.label === option.label ? true : undefined}
              >
                {option.label}
              </Typography>
            </div>
          ))}
        </Popover>
      )}
    </div>
  );
};
