import { Popper, PopperProps } from '@mui/material';
import { isNullOrUndefined } from 'utilities/Utils';

type CustomPopperProps = {
  popupAnchor?: React.RefObject<HTMLElement>;
  autoCompleteRef?: React.RefObject<HTMLElement>;
  popperWidth?: string;
  increaseZIndex?: boolean;
} & PopperProps;

const CustomPopper = ({
  popupAnchor,
  autoCompleteRef,
  popperWidth,
  increaseZIndex,
  ...popperProps
}: CustomPopperProps) => {
  const elem = !isNullOrUndefined(popupAnchor)
    ? popupAnchor.current
    : autoCompleteRef?.current;
  return (
    <Popper
      {...popperProps}
      style={{
        width: popperWidth ? popperWidth : elem?.clientWidth,
        ...(increaseZIndex ? { zIndex: 9999999999 } : { zIndex: 1300 }),
      }}
      anchorEl={elem}
    />
  );
};

export default CustomPopper;
