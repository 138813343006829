//Top level routes
const myProperty = '/my-property';
const OnboardingBase = '/onboarding';
const QcFlowBase = '/qc-flow';
const DuplicateListingBase = '/duplicate-listing';
const ChainBase = '/chain';
const UMBase = '/manage';
const SLBase = '/sponsored-listing';
const LoginBase = '/login';

//meet and beat
const ParityBase = '/homepage';
const SaleHandlingBase = '/sale-campaign';
//Hotel specific routes
const DashboardBase = '/dashboard';
const BookingsBase = '/bookings';
const InventoryBase = '/inventory';
const calSyncBase = `${InventoryBase}/cal-sync`;
const PnCBase = '/promotions-coupons';
const BlackGoTribeBase = '/black-goTribe';
const DealsBase = '/deals';
const PromotionsBase = '/newPromotions';
const PerformanceBase = '/performance';
const DailyStealDealBase = '/daily-steal-deal';
const QualityScoreBase = '/quality-score';
const ReviewsBase = '/reviews';
const AnalyticsBase = '/analytics';
const GuestChatBase = '/guestchat';
const GroupChatBase = '/groupchat';
const InstayIssuesBase = '/instay-issues';
const ReportsBase = '/reports';
const PLBBase = '/more';
const More = '/promotions';
const AddonsBase = '/addons';
const InvoicingBase = '/invoicing';
const RateRecommendationBase = '/rate-recommendation';
const DemandHeatMapBase = '/demand-heat-map';
const groupBookingsBase = '/group-rates';
const campaignBase = '/campaign';
const resellerbase = '/reseller';
const compsetBase = '/compset-management';
const packagesBase = '/packages';
const prebuyLedgerBase = '/prebuy-ledger';
//Multiple nesting in property
const PropertyBase = '/property';
const myImpactHotelBase = `${PropertyBase}/my-impact`;
const myImpactHomeStayBase = '/my-impact';
const PoliciesBase = `${PropertyBase}/rules-policies`;
const InclusionsBase = `${PropertyBase}/inclusions`;
const SettingsBase = `${PropertyBase}/settings`;
const BasicInfoBase = `${PropertyBase}/basic-info`;
const AmenitiesBase = `${PropertyBase}/amenities`;
const RatingsAndReviewsBase = `${ReviewsBase}/ratings`;
const propertyMediaBase = `${PropertyBase}/media`;
//Multiple nesting in Listing
const ListingBase = '/listing';
const mediaBase = `${ListingBase}/media`;
//Multiple nesting in Bookings
const BookingsListBase = `${BookingsBase}/bookingslist`;
const oldSynxisListBase = `${BookingsBase}/synxis`;
const oldDerbyListBase = `${BookingsBase}/derby`;
const cancellationsListBase = `${BookingsBase}/cancellationslist`;
const paymentsListBase = `${BookingsBase}/paymentslist`;
const adjustmentsListBase = `${BookingsBase}/adjustmentslist`;
const OnboardingPhotosAndVideosBase = `${OnboardingBase}/photos-and-videos`;
const QCPhotosAndVideosBase = `${QcFlowBase}/photos-and-videos`;
const DuplicateListingPhotosAndVideosBase = `${DuplicateListingBase}/photos-and-videos`;
//Routes where auto redirection shouldn't happen in case of hotelcode search
export const noRedirectRoutes = /^\/(property|reports)/;

export enum MY_IMPACT {
  EDIT = '/edit',
  THANK_YOU = '/thankYou',
  CREATE = '/create',
  BASE = '/',
}

export const routes = {
  login: {
    base: LoginBase,
    resetPassword: '/reset-password',
    resetPasswordConfirm: '/reset-password/confirm',
    registerConfirm: '/register/confirm',
    activateComplete: '/activate/complete',
  },
  onboarding: {
    myProperty: myProperty,
    base: OnboardingBase,
    basicInfo: `${OnboardingBase}/basic-info`,
    location: `${OnboardingBase}/location`,
    amenities: `${OnboardingBase}/amenities`,
    rooms: `${OnboardingBase}/rooms`,
    roomDetails: `${OnboardingBase}/room-details`,
    photosAndVideos: {
      base: OnboardingPhotosAndVideosBase,
      all: `${OnboardingPhotosAndVideosBase}/all`,
      upload: `${OnboardingPhotosAndVideosBase}/upload`,
      edit: `${OnboardingPhotosAndVideosBase}/edit`,
      tag: `${OnboardingPhotosAndVideosBase}/tag`,
      tagged: `${OnboardingPhotosAndVideosBase}/tagged-media`,
      asignToRoomsOrSpaces: `${OnboardingPhotosAndVideosBase}/assign`,
      pending: `${OnboardingPhotosAndVideosBase}/pending`,
      rejected: `${OnboardingPhotosAndVideosBase}/rejected`,
      unassigned: `${OnboardingPhotosAndVideosBase}/unassigned`,
    },
    policies: `${OnboardingBase}/policies`,
    finance: `${OnboardingBase}/finance`,
    thankyou: `${OnboardingBase}/thankyou`,
    roomsAndSpaces: `${OnboardingBase}/rooms-and-spaces`,
  },
  qcFlow: {
    base: QcFlowBase,
    basicInfo: `${QcFlowBase}/basic-info`,
    location: `${QcFlowBase}/location`,
    amenities: `${QcFlowBase}/amenities`,
    rooms: `${QcFlowBase}/rooms`,
    roomDetails: `${QcFlowBase}/room-details`,
    photosAndVideos: {
      base: QCPhotosAndVideosBase,
      all: `${QCPhotosAndVideosBase}/all`,
      upload: `${QCPhotosAndVideosBase}/upload`,
      edit: `${QCPhotosAndVideosBase}/edit`,
      tag: `${QCPhotosAndVideosBase}/tag`,
      tagged: `${QCPhotosAndVideosBase}/tagged`,
      asignToRoomsOrSpaces: `${QCPhotosAndVideosBase}/assign`,
      pending: `${QCPhotosAndVideosBase}/pending`,
      rejected: `${QCPhotosAndVideosBase}/rejected`,
      unassigned: `${QCPhotosAndVideosBase}/unassigned`,
    },
    policies: `${QcFlowBase}/policies`,
    finance: `${QcFlowBase}/finance`,
    thankyou: `${QcFlowBase}/thankyou`,
    roomsAndSpaces: `${QcFlowBase}/rooms-and-spaces`,
  },
  duplicateListing: {
    base: DuplicateListingBase,
    create: '/duplicate-listing-create',
    basicInfo: `${DuplicateListingBase}/basic-info`,
    location: `${DuplicateListingBase}/location`,
    amenities: `${DuplicateListingBase}/amenities`,
    rooms: `${DuplicateListingBase}/rooms`,
    roomDetails: `${DuplicateListingBase}/room-details`,

    policies: `${DuplicateListingBase}/policies`,
    finance: `${DuplicateListingBase}/finance`,
    roomsAndSpaces: `${DuplicateListingBase}/rooms-and-spaces`,
    summary: `${DuplicateListingBase}/summary`,
    photosAndVideos: {
      base: DuplicateListingPhotosAndVideosBase,
      all: `${DuplicateListingPhotosAndVideosBase}/all`,
      upload: `${DuplicateListingPhotosAndVideosBase}/upload`,
      edit: `${DuplicateListingPhotosAndVideosBase}/edit`,
      tag: `${DuplicateListingPhotosAndVideosBase}/tag`,
      tagged: `${DuplicateListingPhotosAndVideosBase}/tagged-media`,
      asignToRoomsOrSpaces: `${DuplicateListingPhotosAndVideosBase}/assign`,
      pending: `${DuplicateListingPhotosAndVideosBase}/pending`,
      rejected: `${DuplicateListingPhotosAndVideosBase}/rejected`,
      unassigned: `${DuplicateListingPhotosAndVideosBase}/unassigned`,
    },
  },
  chain: {
    base: ChainBase,
    dashboard: `${ChainBase}/dashboard`,
  },
  userManagement: {
    base: UMBase,
    addUser: `${UMBase}/add-user`,
    profile: `${UMBase}/profile`,
    team: `${UMBase}/team`,
    newUserPasswordReset: '/manage-account/password/newuser',
    verifyExistingUser: '/manage-account/verify',
    newUserPasswordResetSucces: '/manage-account/password/newuser/success',
  },
  dashboard: {
    base: DashboardBase,
  },
  bookings: {
    base: '/bookings',
    BookingsListBase,
    oldSynxisListBase,
    oldDerbyListBase,
    cancellationsListBase,
    paymentsListBase,
    adjustmentsListBase,
    new: `${BookingsListBase}/new`,
    bookingDetailL2: `${BookingsListBase}/booking-detail`,
    persuasions: {
      pendingBookings: `${BookingsListBase}/pending-bookings`,
      pendingInvoices: `${BookingsListBase}/pending-invoices`,
      rejectedInvoices: `${BookingsListBase}/rejected-invoices`,
      refundRequests: `${BookingsListBase}/refund-requests`,
      noshowCheckins: `${BookingsListBase}/checkin-noshow-bookings`,
    },
    refunds: `${BookingsListBase}/refunds`,
    upcoming: `${BookingsListBase}/up`,
    past: `${BookingsListBase}/past`,
    cancelled: `${BookingsListBase}/cancelled`,
    all: `${BookingsListBase}/all`,
    payathotel: `${BookingsListBase}/pah`,
    corporate: `${BookingsListBase}/corporate`,
    filter: `${BookingsListBase}/filtered`,
    allCancellations: `${cancellationsListBase}/all`,
    cancelFilter: `${cancellationsListBase}/filtered`,
    allTransactions: `${paymentsListBase}/all`,
    paymentsFilter: `${paymentsListBase}/filtered`,
    allAdjustments: `${adjustmentsListBase}/all`,
    adjustFilter: `${adjustmentsListBase}/filtered`,
    allSynxis: `${oldSynxisListBase}/all`,
    synxisFilter: `${oldSynxisListBase}/filtered`,
    allDerby: `${oldDerbyListBase}/all`,
    derbyFilter: `${oldDerbyListBase}/filtered`,
  },
  businessBooster: {
    campaign: {
      base: campaignBase,
      goStay: `${campaignBase}/gostay`,
      selectProgramme: `${campaignBase}/select`,
      dailyStealDeal: {
        base: DailyStealDealBase,
        edit: `${DailyStealDealBase}/edit`,
        create: `${DailyStealDealBase}/create`,
        summary: `${DailyStealDealBase}/summary`,
      },
    },
    pnc: {
      base: PnCBase,
      create: `${PnCBase}/create`,
      basic: `${PnCBase}/create/basic`,
      lastMinute: `${PnCBase}/create/last-minute`,
      earlyBird: `${PnCBase}/create/early-bird`,
      dayBased: `${PnCBase}/create/day-based`,
      freeNight: `${PnCBase}/create/free-night`,
      longStay: `${PnCBase}/create/long-stay`,
      custom: `${PnCBase}/create/custom`,
      modify: `${PnCBase}/modify`,
      active: `${PnCBase}/active`,
      expired: `${PnCBase}/expired`,
      performanceBooster: `${PnCBase}/performance-booster`,
    },
    performance: PerformanceBase,
    blackGoTribe: {
      base: BlackGoTribeBase,
      enroll: `${BlackGoTribeBase}/enroll`,
    },
  },
  groupBookings: {
    base: groupBookingsBase,
    room: `${groupBookingsBase}/room`,
    mealsAndRateplan: `${groupBookingsBase}/rates`,
    cancellationPolicy: `${groupBookingsBase}/cancellation-policy`,
    coordinatorDetails: `${groupBookingsBase}/coordinator-details`,
  },
  inventory: {
    base: InventoryBase,
    all: `${InventoryBase}/all`,
    low: `${InventoryBase}/low`,
    default: `${InventoryBase}/default`,
    calSync: `${calSyncBase}`,
    ics: `${calSyncBase}/ics`,
    ecs: `${calSyncBase}/ecs`,
    logs: `${InventoryBase}/logs`,
    bulkBase: `${InventoryBase}/bulk`,
    bulkInv: `${InventoryBase}/bulk/inventory`,
    bulkRate: `${InventoryBase}/bulk/rate`,
    bulkRestriction: `${InventoryBase}/bulk/restriction`,
    offeredRates: `${InventoryBase}/offered-rates`,
    linkedRates: `${InventoryBase}/linked-rates`,
    linkedRatesMealPrice: `${InventoryBase}/linked-rates/meal-price`,
    linkedRatesLinkages: `${InventoryBase}/linked-rates/linkages`,
  },
  payments: {
    base: '/payments',
  },
  competitiveAnalysis: {
    base: '/market-analysis',
  },
  sponsoredListing: {
    base: SLBase,
    createSL: `${SLBase}/create`,
    paymentStatus: `${SLBase}/payment-status`,
    salesBoosterCampaign: `${SLBase}/campaign-info`,
    slCampaignDetails: `${SLBase}/campaign-details`,
  },
  property: {
    base: PropertyBase,
    amenities: AmenitiesBase,
    functionalService: `${AmenitiesBase}/functional-services`,
    foodService: `${AmenitiesBase}/food-services`,
    rooms: `${PropertyBase}/rooms`,
    basicInfoBase: BasicInfoBase,
    propertyDetails: `${BasicInfoBase}/details`,
    contact: `${BasicInfoBase}/contact`,
    directions: `${BasicInfoBase}/directions`,
    inclusionsBase: InclusionsBase,
    allActiveInclusions: `${InclusionsBase}/all/active`,
    allInactiveInclusions: `${InclusionsBase}/all/deactivated`,
    mmtBlackInclusions: `${InclusionsBase}/mmt-black`,
    mmtSelectInclusions: `${InclusionsBase}/mmt-select`,
    photosAndVideos: {
      base: propertyMediaBase,
      spaces: `${propertyMediaBase}/spaces-details`,
      unassigned: `${propertyMediaBase}/unassigned`,
      upload: `${propertyMediaBase}/upload`,
      edit: `${propertyMediaBase}/edit`,
      tag: `${propertyMediaBase}/tag`,
      tagged: `${propertyMediaBase}/tagged-media`,
      all: `${propertyMediaBase}/all`,
      pending: `${propertyMediaBase}/pending`,
      rejected: `${propertyMediaBase}/rejected`,
    },
    vibe: `${PropertyBase}/vibe`,
    policiesBase: PoliciesBase,
    cancellation: `${PoliciesBase}/cancellation`,
    propertyRules: `${PoliciesBase}/property-rules`,
    otherPolicies: `${PoliciesBase}/other`,
    notices: `${PoliciesBase}/notices`,
    contentScore: `${PropertyBase}/content-score`,
    newContentScore: `${PropertyBase}/new-content-score`,
    settings: `${PropertyBase}/settings`,
    detailedContacts: `${PropertyBase}/detailed-contacts`,
    settingsBase: SettingsBase,
    reservation: `${SettingsBase}/reservation`,
    vcc: `${SettingsBase}/vcc`,
    directBooking: `${SettingsBase}/direct-booking`,
    channelManager: `${SettingsBase}/channel-manager`,
    pah: `${SettingsBase}/pah`,
    corporate: `${SettingsBase}/corporate-settings`,
    finance: `${PropertyBase}/financial-details`,
    childAgePolicy: `${PoliciesBase}/child-age-policy`,
    postBookingPolicy: `${PoliciesBase}/post-booking-policy`,
    registerationDetails: `${PoliciesBase}/registration-details`,
  },
  //Host web only
  listing: {
    base: ListingBase,
    spaces: `${ListingBase}/spaces`,
    caretaker: `${ListingBase}/caretaker`,
    amenities: `${ListingBase}/amenities`,
    propertyVibe: `${ListingBase}/property-vibe`,
    newContentScore: `${ListingBase}/new-content-score`,
    photosAndVideos: {
      base: mediaBase,
      unassigned: `${mediaBase}/unassigned`,
      spaces: `${mediaBase}/spaces-details`,
      upload: `${mediaBase}/upload`,
      edit: `${mediaBase}/edit`,
      tag: `${mediaBase}/tag`,
      tagged: `${mediaBase}/tagged-media`,
      all: `${mediaBase}/all`,
      pending: `${mediaBase}/pending`,
      rejected: `${mediaBase}/rejected`,
    },
  },
  qualityScore: {
    base: QualityScoreBase,
  },
  reviews: {
    base: ReviewsBase,
    qna: `${ReviewsBase}/qna`,
    ratings: RatingsAndReviewsBase,
    ratingsDetails: `${RatingsAndReviewsBase}/details`,
    insights: `${RatingsAndReviewsBase}/insights`,
  },
  analytics: {
    base: AnalyticsBase,
    roomNights: `${AnalyticsBase}/room-nights`,
    revenue: `${AnalyticsBase}/revenue`,
    asp: `${AnalyticsBase}/asp`,
    propertyVisits: `${AnalyticsBase}/property-visits`,
    consversion: `${AnalyticsBase}/conversion`,
  },
  guestchat: {
    base: GuestChatBase,
    all: `${GuestChatBase}/messages/all`,
    starred: `${GuestChatBase}/messages/starred`,
    unreplied: `${GuestChatBase}/messages/unreplied`,
    unread: `${GuestChatBase}/messages/unread`,
    bookingRequest: `${GuestChatBase}/messages/booking-requests`,
    templates: `${GuestChatBase}/templates`,
    archived: `${GuestChatBase}/messages/archived-chats`,
  },
  groupchat: {
    base: GroupChatBase,
    all: `${GroupChatBase}/messages/all`,
    starred: `${GroupChatBase}/messages/starred`,
    unreplied: `${GroupChatBase}/messages/unreplied`,
    unread: `${GroupChatBase}/messages/unread`,
    bookingRequest: `${GroupChatBase}/messages/booking-requests`,
    templates: `${GroupChatBase}/templates`,
    archived: `${GroupChatBase}/messages/archived-chats`,
  },
  parity: {
    base: ParityBase,
    price: `${ParityBase}/price`,
    inventory: `${ParityBase}/inventory`,
    //BD View of the hotel
    competitiveAnalysisByHotel: `${ParityBase}/price/hotel-details`,
  },
  instay: {
    base: InstayIssuesBase,
  },
  reports: {
    base: ReportsBase,
    // logs: ReportsBase + '/logs',
    inventory: `${ReportsBase}/inventory`,
    rates: `${ReportsBase}/rates`,
    offers: `${ReportsBase}/offers`,
    bookings: `${ReportsBase}/bookings`,
    plb: `${ReportsBase}/plb-records`,
    vdi: `${ReportsBase}/vdi-records`,
    invoices: `${ReportsBase}/invoices`,
    advancePayment: `${ReportsBase}/advance-payment`,
  },
  plb: {
    base: PLBBase,
    marginOffers: `${PLBBase}/margin-offers`,
    active: `${PLBBase}/margin-offers/active`,
    inactive: `${PLBBase}/margin-offers/inactive`,
    createPlb: `${PLBBase}/create-plb`,
  },
  addons: {
    base: AddonsBase,
  },
  opportunityCenter: {
    actionDetail: '/action-detail',
  },
  invoicing: {
    base: InvoicingBase,
  },
  rateRecommendation: {
    base: RateRecommendationBase,
  },
  demandHeatMap: {
    base: DemandHeatMapBase,
  },
  dayUse: {
    base: '/hourly-bookings',
  },
  reseller: {
    base: resellerbase,
  },
  compset: {
    base: compsetBase,
  },
  myImpactHotel: {
    base: myImpactHotelBase,
    edit: myImpactHotelBase + MY_IMPACT.EDIT,
    thankYou: myImpactHotelBase + MY_IMPACT.THANK_YOU,
    create: myImpactHotelBase + MY_IMPACT.CREATE,
  },
  myImpactHomeStay: {
    base: myImpactHomeStayBase,
    edit: myImpactHomeStayBase + MY_IMPACT.EDIT,
    create: myImpactHomeStayBase + MY_IMPACT.CREATE,
    thankYou: myImpactHomeStayBase + MY_IMPACT.THANK_YOU,
  },
  sale_handling: {
    base: SaleHandlingBase,
  },
  packages: {
    base: packagesBase,
    manage: `${packagesBase}/:packageId/manage`,
    edit: `${packagesBase}/:packageId/edit`,
    losPackage: '/los-package',
  },
  prebuyLedger: {
    base: prebuyLedgerBase,
  },
};
//This will maintain our old routes
const deprecatedRoutes = {
  competitiveAnalysis: {
    base: '/competitive-analysis',
  },
  cancellation: `${routes.property.base}/cancel-rules`,
  policy: `${routes.property.base}/policy`,
  notices: `${routes.property.base}/notices`,
  pah: `${routes.property.base}/pah`,
  channelManager: `${routes.property.base}/channel-manager`,
  corporate: `${routes.property.base}/corporate-settings`,
  basicInfo: `${routes.property.base}/basic-info`,
  contactInfo: `${routes.property.base}/contact`,
  directions: `${routes.property.base}/property-directions`,
  invOptim: `${routes.inventory.base}/optimization`,
  userManagement: '/manage-profile',
  taxes: `${PropertyBase}/taxes`,
  bankDetails: `${PropertyBase}/bank-details`,
  documents: `${PropertyBase}/documents`,
  gstDetails: `${PropertyBase}/gst-details`,
  gstTaxes: `${PropertyBase}/gst-taxes`,
  bookings: routes.bookings.base,
  chatprebooking: `${GuestChatBase}/messages/prebooking`,
  chatpostbooking: `${GuestChatBase}/messages/postbooking`,
  chatarchived: `${GuestChatBase}/messages/archived`,
  reportsLogs: `${ReportsBase}/logs`,
  reportsCancellationPolicy: `${ReportsBase}/cancellation-policy`,
  reportsUserActivity: `${ReportsBase}/user-activity`,
  clearTax: '/cleartax_dashboard',
  contentScore: `${AnalyticsBase}/content-score`,
  ratings: `${AnalyticsBase}/ratings-and-reviews`,
  promotions: PromotionsBase,
  promotionsActive: `${PromotionsBase}/active`,
  promotionsCreate: `${PromotionsBase}/create`,
  promotionsExpired: `${PromotionsBase}/expired-deactivated`,
  promotionsExpiringSoon: `${PromotionsBase}/expiring-soon`,
  promotionsNew: `${PromotionsBase}/new`,
  plb: {
    base: More,
    marginOffers: `${More}/margin-offers`,
    active: `${More}/margin-offers/active`,
    inactive: `${More}/margin-offers/inactive`,
    createPlb: `${More}/create-plb`,
  },
  deals: {
    base: DealsBase,
    create: `${DealsBase}/create`,
    basic: `${DealsBase}/create/basic`,
    lastMinute: `${DealsBase}/create/last-minute`,
    earlyBird: `${DealsBase}/create/early-bird`,
    dayBased: `${DealsBase}/create/day-based`,
    freeNight: `${DealsBase}/create/free-night`,
    longStay: `${DealsBase}/create/long-stay`,
    custom: `${DealsBase}/create/custom`,
    modify: `${DealsBase}/modify`,
    active: `${DealsBase}/active`,
    expired: `${DealsBase}/expired`,
    performanceBooster: `${DealsBase}/performance-booster`,
  },
  onboardingPhotos: `${OnboardingBase}/photos`,
  qcFlowPhotos: `${QcFlowBase}/photos`,
  property: {
    photos: `${PropertyBase}/photos`,
    videos: `${PropertyBase}/videos`,
  },
};
export const redirections = {
  '': DashboardBase,
  [routes.chain.base]: DashboardBase,
  [deprecatedRoutes.competitiveAnalysis.base]: routes.competitiveAnalysis.base,
  [routes.chain.dashboard]: DashboardBase,
  [routes.inventory.base]: routes.inventory.all,
  [routes.inventory.bulkBase]: routes.inventory.bulkInv,
  [routes.analytics.base]: routes.analytics.roomNights,
  [routes.property.base]: routes.property.propertyDetails,
  [deprecatedRoutes.cancellation]: routes.property.cancellation,
  [deprecatedRoutes.policy]: routes.property.propertyRules,
  [deprecatedRoutes.notices]: routes.property.notices,
  [routes.property.inclusionsBase]: routes.property.allActiveInclusions,
  [routes.property.policiesBase]: routes.property.cancellation,
  [routes.property.settingsBase]: routes.property.channelManager,
  [routes.reviews.base]: routes.reviews.ratings,
  [routes.guestchat.base]: routes.guestchat.all,
  [routes.groupchat.base]: routes.groupchat.all,
  [routes.reports.base]: routes.reports.inventory,
  [routes.plb.marginOffers]: routes.plb.active,
  [deprecatedRoutes.promotions]: routes.businessBooster.pnc.base,
  [deprecatedRoutes.promotionsActive]: routes.businessBooster.pnc.base,
  [deprecatedRoutes.promotionsCreate]: routes.businessBooster.pnc.base,
  [deprecatedRoutes.promotionsExpired]: routes.businessBooster.pnc.base,
  [deprecatedRoutes.promotionsExpiringSoon]: routes.businessBooster.pnc.base,
  [deprecatedRoutes.promotionsNew]: routes.businessBooster.pnc.base,
  [routes.businessBooster.pnc.base]: routes.businessBooster.pnc.create,
  [deprecatedRoutes.reportsLogs]: routes.reports.inventory,
  [deprecatedRoutes.reportsCancellationPolicy]: routes.reports.inventory,
  [deprecatedRoutes.reportsUserActivity]: routes.reports.inventory,
  [deprecatedRoutes.pah]: routes.property.pah,
  [deprecatedRoutes.channelManager]: routes.property.channelManager,
  [deprecatedRoutes.corporate]: routes.property.corporate,
  [deprecatedRoutes.basicInfo]: routes.property.propertyDetails,
  [deprecatedRoutes.contactInfo]: routes.property.contact,
  [deprecatedRoutes.directions]: routes.property.directions,
  [deprecatedRoutes.invOptim]: routes.inventory.low,
  [deprecatedRoutes.userManagement]: routes.userManagement.team,
  [deprecatedRoutes.bookings]: routes.bookings.new,
  [deprecatedRoutes.taxes]: routes.property.finance,
  [deprecatedRoutes.bankDetails]: routes.property.finance,
  [deprecatedRoutes.gstDetails]: routes.property.finance,
  [deprecatedRoutes.gstTaxes]: routes.property.finance,
  [deprecatedRoutes.documents]: routes.property.finance,
  [deprecatedRoutes.chatarchived]: routes.guestchat.all,
  [deprecatedRoutes.chatpostbooking]: routes.guestchat.all,
  [deprecatedRoutes.chatprebooking]: routes.guestchat.all,
  [deprecatedRoutes.clearTax]: routes.invoicing.base,
  [deprecatedRoutes.contentScore]: routes.property.contentScore,
  [deprecatedRoutes.ratings]: routes.reviews.ratings,
  [deprecatedRoutes.plb.active]: routes.plb.active,
  [deprecatedRoutes.plb.createPlb]: routes.plb.createPlb,
  [deprecatedRoutes.plb.marginOffers]: routes.plb.marginOffers,
  [deprecatedRoutes.plb.inactive]: routes.plb.inactive,
  [deprecatedRoutes.plb.base]: routes.plb.base,
  [deprecatedRoutes.deals.active]: routes.businessBooster.pnc.active,
  [deprecatedRoutes.deals.base]: routes.businessBooster.pnc.base,
  [deprecatedRoutes.deals.basic]: routes.businessBooster.pnc.basic,
  [deprecatedRoutes.deals.create]: routes.businessBooster.pnc.create,
  [deprecatedRoutes.deals.custom]: routes.businessBooster.pnc.custom,
  [deprecatedRoutes.deals.dayBased]: routes.businessBooster.pnc.dayBased,
  [deprecatedRoutes.deals.earlyBird]: routes.businessBooster.pnc.earlyBird,
  [deprecatedRoutes.deals.expired]: routes.businessBooster.pnc.expired,
  [deprecatedRoutes.deals.freeNight]: routes.businessBooster.pnc.freeNight,
  [deprecatedRoutes.deals.lastMinute]: routes.businessBooster.pnc.lastMinute,
  [deprecatedRoutes.deals.longStay]: routes.businessBooster.pnc.longStay,
  [deprecatedRoutes.deals.modify]: routes.businessBooster.pnc.modify,
  [deprecatedRoutes.deals.performanceBooster]:
    routes.businessBooster.pnc.performanceBooster,
  [deprecatedRoutes.onboardingPhotos]: routes.onboarding.photosAndVideos.base,
  [deprecatedRoutes.qcFlowPhotos]: routes.qcFlow.photosAndVideos.base,
  [deprecatedRoutes.property.photos]: routes.property.photosAndVideos.base,
  [deprecatedRoutes.property.videos]: routes.property.photosAndVideos.base,
};
