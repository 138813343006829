import { AttributeFieldProps } from './AttributeFieldProps';
import { useFormContext } from 'react-hook-form';
import { ControlledDropdown } from '../../../../components/latest-core';
import { FieldContainer } from './FieldContainer';
import React, { useCallback, useMemo } from 'react';
import { parseNestedNumberValue } from './packageFormUtils';
import { isNullOrUndefined } from 'utilities/Utils';

export function SelectComponent(
  props: AttributeFieldProps & { isMulti: boolean },
) {
  const { control } = useFormContext();
  const { attr, isMulti, defaultValue } = props;
  const options = useMemo(() => {
    if (attr.renderType === 'number_select') {
      const { maxValue: maxValueNested, minValue: minValueNested } = attr;
      const maxValue = parseNestedNumberValue(maxValueNested);
      const minValue = parseNestedNumberValue(minValueNested);
      const numberOptions = [];
      for (let i = minValue; i <= maxValue; i++) {
        numberOptions.push({ label: `${i}`, value: i });
      }
      return numberOptions;
    }
    return (attr.attributeList || []).map(value => ({
      value: value.id,
      label: value.label,
    }));
  }, [attr]);

  type Option = {
    value: string | number;
    label: string;
  };

  let defaultOption: Option | Option[] | undefined;
  if (Array.isArray(defaultValue)) {
    const values = new Set(defaultValue);
    defaultOption = options.filter(item => values.has(item.value));
  } else if (defaultValue) {
    defaultOption = options.find(item => defaultValue === item.value);
  }
  const required = attr.required;
  const validate = useCallback(
    async (data: unknown) => {
      if (!required) {
        return null;
      }
      if (isMulti) {
        if (!Array.isArray(data) || data.length === 0) {
          return 'This field is required';
        }
      }
      if (isNullOrUndefined(data)) {
        return 'This field is required';
      }
      return null;
    },
    [isMulti, required],
  );
  const restProps: {
    disableClear?: boolean;
  } = {};
  if (!isMulti) {
    restProps.disableClear = required;
  }
  return (
    <FieldContainer attr={attr}>
      {/*@ts-ignore*/}
      <ControlledDropdown
        name={`${attr.attributeId}`}
        control={control}
        options={options}
        variant={isMulti ? 'multiSelect' : 'default'}
        disabled={false}
        customValidator={validate}
        defaultValue={defaultOption}
        isRequired={required}
        valueField="value"
        {...restProps}
      />
    </FieldContainer>
  );
}
