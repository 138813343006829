import { AttributeFieldProps } from './AttributeFieldProps';
import { useFormContext, useWatch } from 'react-hook-form';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FreeFormTextOrNumber } from './FreeFormTextOrNumber';
import { ToggleButton } from '../../../../components/latest-core';

type ServiceModeFieldProps = AttributeFieldProps & {
  defaultValueTypeValue: unknown;
  defaultServiceModeValue: unknown;
};

function intVal(numOrString: unknown): number | undefined {
  if (typeof numOrString === 'string') {
    return parseInt(numOrString, 10);
  }
  if (typeof numOrString === 'number') {
    return numOrString;
  }
  return undefined;
}

export function ServicePriceField(props: ServiceModeFieldProps) {
  const { attr: _attr, attributesKeyIdMap } = props;
  const attr = useMemo(
    () => ({
      ..._attr,
      required: true,
    }),
    [_attr],
  );
  const { setValue, setFocus } = useFormContext();
  const [initialValue, setInitialValue] = useState(props.defaultValue);
  const { defaultValueTypeValue, defaultServiceModeValue } = props;
  const valueTypeAttrId = attributesKeyIdMap.value_type;
  const serviceModeAttrId = attributesKeyIdMap.service_mode;
  const serviceModeVal = intVal(
    useWatch({
      name: `${serviceModeAttrId}`,
      defaultValue: defaultServiceModeValue,
    }),
  );
  const serviceModeValRef = useRef(serviceModeVal);

  const [selectedValueType, setSelectedValueType] = useState<
  'percentage' | 'fixed'
  >((defaultValueTypeValue || 0) === 0 ? 'percentage' : 'fixed');
  const selectedValueTypeRef = useRef(selectedValueType);

  const fieldName = `${attr.attributeId}`;
  const onValueTypeToggle = useCallback(() => {
    setInitialValue(null);
    setValue(fieldName, undefined);
    setSelectedValueType(prev =>
      prev === 'percentage' ? 'fixed' : 'percentage',
    );
  }, []);

  useEffect(() => {
    if (
      selectedValueTypeRef.current === selectedValueType &&
      serviceModeValRef.current === serviceModeVal
    ) {
      return;
    }
    const valueTypeFieldName = `${valueTypeAttrId}`;
    if (serviceModeVal === 0) {
      setValue(valueTypeFieldName, undefined);
    } else {
      setValue(valueTypeFieldName, selectedValueType === 'percentage' ? 0 : 1);
      selectedValueTypeRef.current = selectedValueType;
    }
    setValue(fieldName, undefined);
    setFocus(fieldName, { shouldSelect: true });
  }, [selectedValueType, serviceModeVal, attr]);

  if (serviceModeVal !== 1) {
    return null;
  }
  const minMax =
    selectedValueType === 'percentage'
      ? {
        min: 0,
        max: 90,
      }
      : ({
        max: attr.maxValue || undefined,
        min: attr.minValue || undefined,
      } as { min: number; max: number });
  return (
    <FreeFormTextOrNumber
      placeholder={'Enter Discount'}
      attr={attr}
      key={selectedValueType}
      attributesKeyIdMap={attributesKeyIdMap}
      {...minMax}
      defaultValue={initialValue as string | number}
      rightElement={
        <ToggleButton
          btnClass={'!h-[24px] !w-[26px]'}
          data={[
            { value: 'fixed', content: '₹' },
            { value: 'percentage', content: '%' },
          ]}
          variant={'normal'}
          onChange={onValueTypeToggle}
          value={selectedValueType}
        />
      }
    />
  );
}
