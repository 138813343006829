import { useMemo, useState } from 'react';
import { ChevronDown } from 'assets/common';
import { classNames } from 'utilities/Utils';
import { Divider, Button, Typography } from 'components/latest-core';
import { useLangStrings } from 'utilities/CustomHooks';

import './FaqCardsLatest.scss';
type ANSWER = {
  ICON?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  TEXT: string;
};

export type FAQs = {
  QUESTION: string;
  ANSWER?: string;
  ANSWERS?: ANSWER[];
  NODE_ELEMENT?: React.ReactNode;
  onLinkClick?: () => void;
  linkText?: string;
};

type FaqCardsLatestProps = {
  title: string;
  faqs: FAQs[];
  showHide?: boolean;
  openFirst?: boolean;
  handleClickFaq?: (id: number, isOpen: boolean) => void;
  itemClassName?: string;
};

const FaqCardsLatest = (props: FaqCardsLatestProps) => {
  const {
    title,
    faqs,
    showHide = false,
    openFirst = true,
    handleClickFaq = null,
    itemClassName = '',
  } = props;
  const [COMMON_STRINGS] = useLangStrings<'Common'>('Common');
  const [faqsList, setFaqsList] = useState(() =>
    faqs.map((faq, idx) => ({
      ...faq,
      id: idx,
      isOpen: openFirst && idx === 0,
    })),
  );

  const handleClick = (id: number, isOpen: boolean) => {
    setFaqsList(prev =>
      prev.map(faq =>
        faq.id === id ? { ...faq, isOpen: !isOpen } : { ...faq, isOpen: false },
      ),
    );
    if (handleClickFaq) handleClickFaq(id, isOpen);
  };
  const isHide = useMemo(() => !faqsList.every(faq => !faq.isOpen), [faqsList]);

  return (
    <div
      data-test-id="faq-card"
      className="border border-solid border-divider bg-color-white faq-cards-latest rounded-b"
    >
      <div className="px-3 py-[10px] font-[500] min-h-[48px] flex justify-between items-center">
        <Typography
          variant="body2"
          className="flex justify-center items-center"
        >
          {title}
        </Typography>
        {showHide && isHide && (
          <Button
            variant="text"
            size="small"
            onClick={() => handleClick(-1, false)}
          >
            <span className="font-semibold">{COMMON_STRINGS.BUTTONS.HIDE}</span>
          </Button>
        )}
      </div>
      <Divider />
      {faqsList.map((faq, index, array) => {
        return (
          <div key={index + faq.id}>
            <div
              className={`px-3 py-[14px] flex flex-row justify-between cursor-pointer ${
                faq.isOpen ? 'bg-color-primary' : ''
              }`}
              onClick={() => handleClick(faq.id, faq.isOpen)}
            >
              <Typography
                variant="body1"
                themeColor="text.link"
                className="flex justify-center items-center"
              >
                {faq.QUESTION}
              </Typography>
              <ChevronDown
                fill="#404040"
                className={classNames(faq.isOpen && 'up', 'chevron-icon')}
              />
            </div>
            {faq.isOpen && faq.ANSWER && (
              <Typography variant="body1" className="flex justify-center p-3">
                <p
                  dangerouslySetInnerHTML={{ __html: faq.ANSWER }}
                  className="mb-0"
                />
              </Typography>
            )}
            {faq.isOpen && !!faq.NODE_ELEMENT && faq?.NODE_ELEMENT}
            {faq.isOpen && faq.ANSWERS && (
              <div className="flex flex-col p-1 align-center">
                {faq.ANSWERS.map(({ ICON, TEXT }) => {
                  return (
                    <div
                      className={classNames(
                        'flex flex-row p-2 align-center',
                        itemClassName,
                      )}
                    >
                      {!!ICON && (
                        <div>
                          <ICON fontSize="24px" className="mr-2" />
                        </div>
                      )}
                      {!!TEXT && (
                        <Typography
                          variant="body1"
                          className="flex justify-center"
                        >
                          <p
                            dangerouslySetInnerHTML={{ __html: TEXT }}
                            className="mb-0"
                          />
                        </Typography>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {faq.isOpen && faq.linkText && (
              <div className="px-3 pb-[10px]" onClick={faq.onLinkClick}>
                <Typography
                  variant="body1"
                  themeColor="text.link"
                  className="underline cursor-pointer"
                >
                  {faq.linkText}
                </Typography>
              </div>
            )}
            {index !== array.length - 1 && <Divider />}
          </div>
        );
      })}
    </div>
  );
};

export default FaqCardsLatest;
