import { classNames } from 'utilities/Utils';
import SortableList from './SortableList';
import { useEffect, useState } from 'react';

import './SortableDragDrop.scss';

export default function SortableDragDrop({
  dragComponent,
  data = [],
  id,
  callback,
  parentKey,
  className = '',
}) {
  const [items, setItems] = useState([...data]);
  useEffect(() => {
    setItems(data);
  }, [data]);
  return (
    <div className={classNames('SortableDragDrop', className)}>
      <SortableList
        callback={(...args) => callback(...args, setItems)}
        items={items}
        setItems={setItems}
        component={dragComponent}
        id={id}
        parentKey={parentKey}
      />
    </div>
  );
}
