import React, { useState } from 'react';

import './CircularCountDown.scss';

function CircularCountDown() {
  const [countdown, setCountdown] = useState(5);

  setInterval(function () {
    setCountdown(countdown <= 0 ? countdown : countdown - 1);
    if (countdown < 0) {
      clearInterval(countdown);
    }
  }, 1000);

  return (
    <div className="countdown-wrapper">
      <div id="countdown">
        <div id="countdown-number" className="red">
          {countdown}
        </div>
        <svg>
          <circle r="10" cx="20" cy="20"></circle>
        </svg>
      </div>
    </div>
  );
}

export default CircularCountDown;
