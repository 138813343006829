import { useState } from 'react';
import { Button, Drawer, Tabs, Typography } from 'components/latest-core';
import { ViewType } from './BulkUploadMain';
import { useLangStrings } from 'utilities/CustomHooks';
import FileUploadStatusComp from './FileUploadStatusComp';

type CompProps = {
  setView: React.Dispatch<React.SetStateAction<ViewType>>;
  setFileObjectData: Function;
  successData?: string[];
  moderationData?: string[];
  failedData?: string[];
  allTabs: { label: string; value: string }[];
  totalFilesCount: number;
  [any: string]: unknown | unknown[];
};

export default function UploadStatusDrawer(props: CompProps) {
  const {
    fileObjectData,
    setFileObjectData,
    isAllUploadComplete,
    totalFilesCount,
    allTabs,
    failedData,
    successData,
    moderationData,
    setView,
  } = props;

  const [STRINGS, COMMON_STRINGS] =
    useLangStrings<'BookingsRevamp'>('BookingsRevamp');

  const [activeTab, setActiveTab] = useState('all');

  const clearAll = () => {
    setFileObjectData(null);
    setView(null);
  };

  const onTabClick = tab => {
    setActiveTab(tab);
  };

  const onHide = () => {
    if (totalFilesCount > 0) {
      setView('progress');
    } else {
      setView(null);
    }
  };

  const onAddMoreFilesHandler = () => {
    setView('upload');
  };

  return (
    <Drawer
      isOpen
      onClose={onHide}
      header={{
        title: 'Upload Invoices',
      }}
      footer={{
        primaryBtn: {
          onClick: onHide,
          text: COMMON_STRINGS.BUTTONS.HIDE,
        },
        secondaryBtn: {
          onClick: onAddMoreFilesHandler,
          text: STRINGS.INVOICE_DETAILS.ADD_MORE_FILES,
          disabled: !isAllUploadComplete,
        },
      }}
      customClasses={{
        children: '!p-0 !overflow-hidden',
      }}
    >
      <div className="flex flex-col overflow-hidden h-full">
        <div className="flex flex-col sticky top-0 right-0">
          <div className="p-2 bg-color-dark mb-[14px]">
            {isAllUploadComplete ? (
              <div className="flex justify-between items-center ml-3 mr-4">
                <div>{STRINGS.INVOICE_DETAILS.UPLOAD_COMPLETE}</div>
                <Button variant="text" onClick={clearAll}>
                  {COMMON_STRINGS.BUTTONS.CLEAR_ALL}
                </Button>
              </div>
            ) : (
              <div className="ml-3">{STRINGS.INVOICE_DETAILS.UPLOADING}...</div>
            )}
          </div>
          <Tabs
            tabs={allTabs}
            activeTab={activeTab}
            onChange={onTabClick}
            borderBtmLine
          />
        </div>
        <div className="p-4 overflow-x-hidden overflow-y-scroll h-[calc(100%-80px)]">
          {activeTab === 'in_wip' && (
            <div className="py-2 px-3 bg-color-warning border-[1px] border-solid  border-[#F2a526] rounded-md shadow-box-2 mb-2">
              <Typography variant="subtitle1">
                {STRINGS.INVOICE_DETAILS.BULK_UNDER_VERIFICATION_TEXT}
              </Typography>
            </div>
          )}
          <div className={activeTab === 'in_wip' ? 'mt-4' : 'mt-0'}>
            {activeTab === 'all' &&
              Object.keys(fileObjectData).map(fileName => (
                <FileUploadStatusComp fileObj={fileObjectData[fileName]} />
              ))}
            {activeTab === 'success' &&
              successData.length > 0 &&
              successData.map(fileName => (
                <FileUploadStatusComp fileObj={fileObjectData[fileName]} />
              ))}
            {activeTab === 'in_wip' &&
              moderationData.length > 0 &&
              moderationData.map(fileName => (
                <FileUploadStatusComp fileObj={fileObjectData[fileName]} />
              ))}
            {activeTab === 'failed' &&
              failedData.length > 0 &&
              failedData.map(fileName => (
                <FileUploadStatusComp fileObj={fileObjectData[fileName]} />
              ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
