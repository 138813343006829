import { Skeleton } from '@mui/material';

function OpportunityCenterShimmer() {
  return (
    <div className="h-[450px] w-full rounded-lg overflow-hidden bg-color-white flex flex-col gap-5 p-4 mb-4">
      <Skeleton
        variant="rectangular"
        width="100%"
        height="15%"
        className="rounded"
      />
      <div className="flex flex-col gap-3 h-full">
        <Skeleton
          variant="rectangular"
          width="100%"
          height="5%"
          className="rounded"
        />
        {Array(3)
          .fill('')
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height="30%"
              className="rounded"
            />
          ))}
      </div>
    </div>
  );
}

export default OpportunityCenterShimmer;
