import React, { useContext, useState, useEffect } from 'react';
import { useLangStrings } from '../../utilities/CustomHooks';

import { AppContext } from '../../utilities/Context';
import {
  generateUmOTPWrapper,
  validateUmOTPWrapper,
} from 'shared_logic/user-management/APIClient';
import { showMessage } from 'utilities/Utils';
import { pushToOmniture } from 'utilities/gtm';
import {
  OTPInput,
  PhoneInput,
  usePhoneInput,
  Button,
} from 'components/latest-core';

import './VerificationBlockerMain.scss';

export default function VerificationBlockerMain() {
  const appState = useContext(AppContext);
  const { user } = appState;
  const [currentBlocker, setCurrentBlocker] = useState(
    user.profile.email_verified ? 'mobile' : 'email',
  );
  const [timeLeft, setTimeLeft] = useState(60);
  const [isOTPFlow, setIsOTPFlow] = useState(false);
  const [otp, setOtp] = useState('');
  const [STRINGS, COMMON] = useLangStrings('VerificationBlocker');

  const phoneState = usePhoneInput(
    user.profile.mobile,
    user.profile.countrycode !== '' ? user.profile.countrycode : '+91',
  );

  const handleNumberChange = () => {
    setIsOTPFlow(false);
    setOtp('');
  };

  const sendOTP = () => {
    const responseObj = {
      event: 'ctaResponse',
      cta: {
        name: 'send_otp',
        type: 'click',
        componentName: 'verify_mobile',
        apiResponseCode: 'success',
      },
      pageName: 'dashboard',
      errorInfo: {},
    };
    let promise;
    if (currentBlocker === 'email') {
      promise = generateUmOTPWrapper(user.profile.email, 'email');
    } else if (currentBlocker === 'mobile') {
      promise = generateUmOTPWrapper(
        phoneState.phone.toString(),
        'mobile',
        false,
        phoneState?.country?.value ?? '+91',
      );
    }
    promise
      .then(res => {
        if (res.success) {
          showMessage({
            show: true,
            message: STRINGS.OTP_SENT_SUCCESSFULLY,
            type: 'success',
          });
          pushToOmniture(responseObj);
          setTimeLeft(60);
          setIsOTPFlow(true);
        } else {
          showMessage({
            show: true,
            message: res,
            commonMessage: STRINGS.ERROR_IN_OTP_SEND,
            type: 'error',
          });
        }
      })
      .catch(res => {
        const erromsg =
          res?.response?.data?.message ||
          res?.message ||
          STRINGS.ERROR_IN_OTP_SEND;
        showMessage({
          show: true,
          message: res,
          commonMessage: STRINGS.ERROR_IN_OTP_SEND,
          type: 'error',
        });
        responseObj.cta.apiResponseCode = 'failure';
        responseObj.errorInfo = {
          errorCode: res.status,
          errorType: res.statusText,
          errorDescription: erromsg,
        };
        pushToOmniture(responseObj);
      });
  };

  const handleOTPSubmit = () => {
    let promise;
    let successMessage;
    if (currentBlocker === 'email') {
      promise = validateUmOTPWrapper(otp, {
        user_email: user.profile.email,
        contact_address: user.profile.email,
      });
      successMessage = `${STRINGS.EMAIL_ID} ${STRINGS.VERIFICATION_SUCCESSFUL}`;
    } else if (currentBlocker === 'mobile') {
      promise = validateUmOTPWrapper(otp, {
        contact_address: phoneState.phone,
      });
      successMessage = `${STRINGS.MOBILE_NUMBER} ${STRINGS.VERIFICATION_SUCCESSFUL}`;
    }
    promise
      .then(res => {
        if (res.success) {
          showMessage({
            show: true,
            message: successMessage,
            type: 'success',
          });
          setTimeout(() => {
            setTimeLeft(60);
          }, 200);
          setIsOTPFlow(false);
          setOtp('');
          if (currentBlocker === 'email') {
            user.profile.email_verified = true;
            setCurrentBlocker('mobile');
          } else {
            user.profile.mobile_verified = true;
            window.location.reload();
          }
        } else {
          setOtp('');
          showMessage({
            show: true,
            message: STRINGS.INCORRECT_OTP,
            type: 'error',
          });
        }
      })
      .catch(() => {
        showMessage({
          show: true,
          message: STRINGS.ERROR_IN_VERIFYING,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    if (isOTPFlow && timeLeft > 0) {
      setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }
  });

  return (
    <div className="full-size-centered">
      <div className="verify-card">
        <p className="blocker-title">
          {currentBlocker === 'email'
            ? STRINGS.EMAIL_TITLE
            : STRINGS.MOBILE_TITLE}
        </p>
        {isOTPFlow ? (
          <div>
            <span className="sr-flex-row">
              <span>{STRINGS.OTP_SENT}</span>
              <span style={{ fontWeight: '500', marginLeft: '10px' }}>
                {currentBlocker === 'email'
                  ? user.profile.email
                  : `${phoneState.country.value}${phoneState.phone}`}
              </span>
            </span>
            <div className="sr-flex-col marginT15">
              <span className="marginB5" style={{ fontWeight: 500 }}>
                {STRINGS.ENTER_OTP}
              </span>
              <div className="sr-flex-row">
                <OTPInput
                  className="blocker-otp-input"
                  value={otp}
                  onChange={setOtp}
                />
                <Button
                  disabled={otp.length < 4}
                  onClick={handleOTPSubmit}
                  className="blocker-otp-verify"
                >
                  {STRINGS.SUBMIT}
                </Button>
              </div>
            </div>
            <div className="marginT25 resend-blocker-otp ">
              {timeLeft > 0 ? (
                <span className="resend-timer marginR5">
                  {`${timeLeft} ${COMMON.LABELS.SECONDS.toUpperCase()}`}
                </span>
              ) : (
                <span onClick={sendOTP} className="sr-cursor marginR5">
                  {STRINGS.RESEND_OTP}
                </span>
              )}

              {currentBlocker === 'mobile' && (
                <span
                  onClick={handleNumberChange}
                  className="sr-cursor resend-divide"
                >
                  {STRINGS.CHANGE_MOBILE_NUMBER}
                </span>
              )}
            </div>
          </div>
        ) : (
          <>
            <p>
              {STRINGS.DESC[0]}
              <span style={{ fontWeight: '500' }}>{STRINGS.DESC[1]}</span>
              {STRINGS.DESC[2]}
              <span style={{ fontWeight: '500' }}>{STRINGS.DESC[3]}</span>
              {STRINGS.DESC[4]}
            </p>
            {currentBlocker === 'mobile' ? (
              <>
                <p style={{ fontWeight: '500' }}>{STRINGS.MOBILE_NUMBER}</p>
                <div className="sr-flex-row h-10">
                  <PhoneInput
                    className="blocker-phone flex-1"
                    {...phoneState}
                  />
                  <Button onClick={sendOTP}>{STRINGS.VERIFY_NOW}</Button>
                </div>
              </>
            ) : (
              <div className="sr-flex-row sr-align-center">
                <span
                  style={{
                    fontWeight: 300,
                    fontSize: 'small',
                  }}
                >
                  {STRINGS.EMAIL_ID} : {user.profile.email}
                </span>
                <Button className="blocker-cta" onClick={sendOTP}>
                  {STRINGS.VERIFY_NOW}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
