import React, { useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { autoSuggestParse } from '../SearchInput/SearchInput';
import { DEFAULT_LOCATION_COORDS } from '../../../constants';
import {
  MAP_DEFAULT_RADIUS,
  NEARBY_PLACES_CONSTANTS,
  POINT_OF_INTEREST,
} from './Dropdown.constants';
const GSuggest = { current: null };
const GPlaces = { current: null };

type NearByInfoType = {
  currentLocation?: {
    lat: number;
    lng: number;
  };
  radius?: number;
};

type NearByType = {
  type: 'airport' | 'train_station' | 'bus_station';
  lat: number;
  lng: number;
  radius?: number;
  keyword?: string;
};

type SelectedPlaceType = {
  placeId: string | number;
};

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const typeMap = {
  airport: NEARBY_PLACES_CONSTANTS.AIRPORT,
  rail: NEARBY_PLACES_CONSTANTS.TRAIN_STATION,
  bus: NEARBY_PLACES_CONSTANTS.BUS_STATION,
};

function ensureSuggestService() {
  if (window.google) {
    if (!GSuggest.current) {
      GSuggest.current = new window.google.maps.places.AutocompleteService();
    }
  }
  return !!GSuggest.current;
}

function parseSuggestions(data) {
  if (!data) {
    return [];
  }

  return data.map(item => {
    const { id, description, place_id, structured_formatting } = item;
    return {
      id,
      structured_formatting,
      place: description,
      placeId: place_id,
    };
  });
}

function parseNearByOptions(data) {
  return data.map(item => {
    const { id, name, vicinity, place_id, geometry } = item;
    return {
      id,
      name,
      vicinity,
      geometry,
      placeId: place_id,
      structured_formatting: {
        main_text: name,
        secondary_text: vicinity,
      },
    };
  });
}

function parseNearByPlace(data, type) {
  let filteredData = data;
  //Product requirement only for airport type
  if (type === NEARBY_PLACES_CONSTANTS.AIRPORT) {
    filteredData = data.filter(item => {
      return (
        JSON.stringify([
          NEARBY_PLACES_CONSTANTS.AIRPORT,
          NEARBY_PLACES_CONSTANTS.ESTABLISHMENT,
          POINT_OF_INTEREST,
        ]) === JSON.stringify(item.types.sort())
      );
    });
  }

  if (type === NEARBY_PLACES_CONSTANTS.RAIL) {
    filteredData = data.filter(item => {
      return (
        JSON.stringify([
          NEARBY_PLACES_CONSTANTS.ESTABLISHMENT,
          NEARBY_PLACES_CONSTANTS.TRAIN_STATION,
          NEARBY_PLACES_CONSTANTS.TRANSIT_STATION,
          POINT_OF_INTEREST,
        ]) === JSON.stringify(item.types.sort())
      );
    });
  }

  const modifiedData = filteredData.map(place => {
    const { id, name, place_id: placeId, geometry } = place;
    return {
      id,
      name,
      placeId,
      lat: geometry.location.lat(),
      lng: geometry.location.lng(),
    };
  });

  return modifiedData;
}

function ensurePlacesService() {
  if (window.google) {
    if (!GPlaces.current) {
      GPlaces.current = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
  }
  return !!GPlaces.current;
}

export function useGoogleLocation(nearByInfo?: NearByInfoType) {
  const classes = useStyles();
  const [value, onChange] = useState(null);

  function fetchOptions(inputVal): Promise<unknown> {
    const valid = ensureSuggestService();
    if (!valid) {
      return Promise.resolve([]);
    }
    return new Promise(resolve => {
      GSuggest.current.getPlacePredictions({ input: inputVal }, res =>
        resolve(parseSuggestions(res)),
      );
    });
  }

  function fetchNearByOptions(inputVal): Promise<unknown> {
    const {
      currentLocation: {
        lat = DEFAULT_LOCATION_COORDS.LATITUDE,
        lng = DEFAULT_LOCATION_COORDS.LONGITUDE,
      },
      radius = MAP_DEFAULT_RADIUS,
    } = nearByInfo;

    const valid = ensurePlacesService();
    if (!valid) {
      return Promise.resolve([]);
    }

    const requestObj = {
      radius,
      location: new window.google.maps.LatLng(lat, lng),
      keyword: inputVal,
    };

    return new Promise(resolve =>
      GPlaces.current.nearbySearch(requestObj, res =>
        resolve(parseNearByOptions(res)),
      ),
    );
  }

  function renderOption(props, option, state) {
    const { inputValue } = state;
    const { main_text, secondary_text } = option.structured_formatting;
    const parts = autoSuggestParse(main_text, inputValue);
    return (
      <div {...props}>
        <Grid container alignItems="center">
          <Grid>
            <LocationOnIcon className={classes.icon} />
          </Grid>
          <Grid xs>
            {parts.map((part, index) => (
              <span
                key={index}
                className={`${
                  part.highlight ? 'font-bold' : 'font-normal'
                } whitespace-pre`}
              >
                {part.text}
              </span>
            ))}
            {secondary_text && (
              <Typography variant="body2" color="textSecondary">
                {secondary_text}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  return {
    value,
    onChange,
    fetchOptions: nearByInfo ? fetchNearByOptions : fetchOptions,
    renderOption,
    labelField: 'place',
  };
}

function parsePlace(data) {
  const { formatted_address, geometry, id, place_id } = data ?? {};
  return {
    id,
    placeId: place_id,
    place: formatted_address,
    lat: geometry?.location?.lat(),
    lng: geometry?.location?.lng(),
  };
}

//This input has to be the value set in the above hook.
export function getPlaceDetails(selectedPlace: SelectedPlaceType) {
  const valid = ensurePlacesService();
  if (!valid) {
    return Promise.resolve(null);
  }
  return new Promise(resolve => {
    GPlaces.current.getDetails({ placeId: selectedPlace.placeId }, res =>
      resolve(parsePlace(res)),
    );
  });
}

export function fetchNearByPlaces(nearByInfo: NearByType) {
  const valid = ensurePlacesService();
  if (!valid) {
    return Promise.resolve(null);
  }
  const { lat, lng, type, keyword, radius = MAP_DEFAULT_RADIUS } = nearByInfo;
  const location = new window.google.maps.LatLng(lat, lng);
  const requestObj = {
    location,
    radius,
    type: typeMap[type],
    keyword,
  };

  return new Promise(resolve => {
    GPlaces.current.nearbySearch(requestObj, res =>
      resolve(parseNearByPlace(res, typeMap[type])),
    );
  });
}
