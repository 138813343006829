import { Typography, Checkbox } from 'components/latest-core';
import { RoomRateplanSelectable as RoomRateplanSelectableBase } from 'components/common';
import { Divider } from '@mui/material';
import { PropsForRoomRatePlanSelectable } from '../data/types/Schema';

export const RoomRatePlanSelectable = ({
  selectAll,
  clickOnSelectAll,
  roomRpSelect,
  LOS_STRINGS,
}: PropsForRoomRatePlanSelectable) => {
  return (
    <div className="mt-4 rounded p-4 bg-color-light border-[1px] border-solid border-primary border-color-divider">
      <Typography variant="subHeadingMedium" className="mb-4">
        {LOS_STRINGS.ROOM_RATE_PLAN_APPL}
      </Typography>
      <Checkbox
        checked={selectAll.isChecked}
        onChange={clickOnSelectAll}
        variant="thin"
      >
        <Typography>
          {LOS_STRINGS.BENEFIT_APPL_TO_ALL}
          <Typography
            themeColor="text.secondary"
            variant="subtitle1"
            inline
          >{` (${selectAll.selectedRp}/${selectAll.totalRp})`}</Typography>
        </Typography>
      </Checkbox>
      {selectAll.selectedRp !== selectAll.totalRp && (
        <>
          <Divider className="mt-4" />
          <div className="rounded p-4 bg-color-light">
            <RoomRateplanSelectableBase {...roomRpSelect} isNewUI />
          </div>
        </>
      )}
    </div>
  );
};
