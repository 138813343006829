import { createContext, SetStateAction } from 'react';
import {
  FetchLosServiceResponse,
  LeafCategory,
  ServiceNode,
  ServiceValidationInput,
} from './data/types/Schema';
import { MIN_STAY_VAL } from './packageConstants';

export type PackagesLosContextType = {
  minStay: number | string;
  inclusionLookupByIdMap: Record<number, LeafCategory> | null;
  setInclusionLookupByIdMap: (
    action: SetStateAction<Record<number, LeafCategory>>,
  ) => void;
  inclusionForDelete: ServiceNode | null;
  setInclusionForDelete(arg: ServiceNode | null): void;
  inclusionForEdit: ServiceValidationInput | null;
  setInclusionForEdit(arg: ServiceValidationInput | null): void;
  serviceResponse?: FetchLosServiceResponse | null;
  updateServiceResponse: (
    action: SetStateAction<FetchLosServiceResponse>,
  ) => void;
};

export const PackagesLosContext = createContext<PackagesLosContextType>({
  minStay: MIN_STAY_VAL,
  inclusionLookupByIdMap: null,
  setInclusionLookupByIdMap: () => {},
  serviceResponse: null,
  inclusionForEdit: null,
  setInclusionForEdit() {},
  inclusionForDelete: null,
  setInclusionForDelete() {},
  updateServiceResponse: () => {},
});
