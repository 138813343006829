import { makeStyles } from '@mui/styles';

type DrawerStylesPropTypes = {
  hasLeftChildren: boolean;
};

export const useDrawerStyles = makeStyles(theme => ({
  root: {
    zIndex: '1200',
  },
  backdrop: (props: DrawerStylesPropTypes) => ({
    display: props.hasLeftChildren ? 'none' : 'flex',
    backgroundColor: 'theme.palette.backgrounds.overlay',
  }),
  leftChildContainer: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: 'calc(100vw - 424px)',
    height: '100vh',
    backgroundColor: 'rgb(95,95,95,0.4)',
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '424px',
    overflow: 'hidden',
    height: '100vh',
  },
  headerContainer: {
    position: 'sticky',
    top: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  headerChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  headerTitleContainer: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },
  headerSubTitleContainer: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },
  headerCrossIconContainer: {
    cursor: 'pointer',
  },
  drawerChidrenContainer: {
    padding: '16px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
  },
  footerContainer: {
    position: 'sticky',
    bottom: '0',
    right: '0',
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  footerPrimaryBtnContainer: {
    width: '100%',
    height: '40px',
    padding: '8px 16px !important',
  },
  footerSecondaryBtnContainer: {
    width: '100%',
    height: '40px',
    padding: '8px 16px !important',
  },
}));
