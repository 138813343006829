import React, { forwardRef, useState } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';

import { Input } from './InputBase';
import { isNullOrUndefined } from 'utilities/Utils';
import Button from 'components/latest-core/Button/Button';

type PasswordInputProps = {
  value: string;
  onChange: (value: string, name: string) => void;
  name?: string;
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium';
  leftElement?: React.ReactNode;
};

function PasswordInputComp(props: PasswordInputProps, ref) {
  const {
    value,
    onChange,
    //Optional
    name,
    placeholder = 'Type here...',
    onBlur,
    disabled = false,
    error = false,
    size = 'medium',
    leftElement,
  } = props;

  const [type, setType] = useState<'text' | 'password'>('password');
  const toggleType = () =>
    setType(prev => (prev === 'password' ? 'text' : 'password'));

  const passwordInputRight = (
    <Button
      variant="text"
      onClick={toggleType}
      className="!w-9"
      disabled={disabled}
    >
      <span className="text-[12px]">
        {type === 'password' ? 'SHOW' : 'HIDE'}
      </span>
    </Button>
  );

  return (
    <Input
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      error={error}
      size={size}
      placeholder={placeholder}
      leftElement={leftElement}
      rightElement={passwordInputRight}
      ref={ref}
    />
  );
}

const PasswordInput = forwardRef(PasswordInputComp);

const ControlledPasswordInput = props => {
  const {
    name,
    control,
    defaultValue,
    isRequired,
    customValidator,
    pattern,
    patternErrorMessage = null,
    ...additional
  } = props;

  const validations: RegisterOptions = {
    required: {
      value: isRequired,
      message: 'This field is required',
    },
  };

  if (typeof customValidator === 'function') {
    validations.validate = customValidator;
  }

  if (!isNullOrUndefined(pattern)) {
    validations.pattern = {
      value: pattern,
      message: patternErrorMessage || 'Please enter info in correct format',
    };
  }

  const { min, max } = additional;
  if (!isNullOrUndefined(min)) {
    validations.min = {
      value: min,
      message: `The value cannot be lesser than ${min}`,
    };
  }
  if (!isNullOrUndefined(max)) {
    validations.max = {
      value: max,
      message: `The value cannot be greater than ${max}`,
    };
  }

  const {
    field: { ref, value, onChange, onBlur },
  } = useController({
    name,
    control,
    defaultValue,
    rules: validations,
  });

  return (
    <PasswordInput
      ref={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...additional}
    />
  );
};

export { PasswordInput, ControlledPasswordInput };
