import { UseSuspenseQueryOptions } from '@tanstack/react-query/build/modern';
import { Room } from '../types/Rooms';
import {
  fetchInclusions,
  FetchInclusionsOptions,
  fetchLosPackageDetails,
  fetchLosPkgServiceTemplate,
  fetchRoomsAndRatePlans,
  fetchTemplates,
} from '../api/packagesApiClient';
import { FetchServiceResponse } from '../types/Schema';

export function roomsAndRatePlansQueryOptions({
  hotelId,
  editMode,
}: {
  hotelId: string;
  editMode: boolean;
}): UseSuspenseQueryOptions<Room[]> {
  return {
    queryFn: () => fetchRoomsAndRatePlans({ hotelId, editMode }),
    queryKey: [
      `property:${hotelId}`,
      { op: 'Pkg_FetchRoomsWithRatePlans', editMode },
    ],
  };
}

export function templatesQueryOptions() {
  return {
    queryFn: () => fetchTemplates(),
    queryKey: ['property', { op: 'Pkg_Templates' }],
    staleTime: Infinity,
    gcTime: Infinity, // This data doesn't change
  };
}

export const fetchServicesQueryKeys = {
  summaryPageKey(params: Omit<FetchInclusionsOptions, 'source'>) {
    return [
      `property:id_${params.hotelId}:RP_${params.ratePlanId}`,
      { op: 'Pkg_Summary' },
    ];
  },
  inclusionsPageKey(params: Omit<FetchInclusionsOptions, 'source'>) {
    return [
      `property:id_${params.hotelId}:RP_${params.ratePlanId}`,
      { op: 'Pkg_Inclusions' },
    ];
  },
};
export function fetchServicesQueryKey(params: FetchInclusionsOptions) {
  return [`property:RP_${params.ratePlanId}`, { op: 'Pkg_Inclusions' }];
}

export function fetchServicesQueryOptions(
  params: FetchInclusionsOptions,
): UseSuspenseQueryOptions<FetchServiceResponse> {
  return {
    queryFn: () => fetchInclusions(params),
    initialData: params.cachedResponse,
    queryKey:
      params.source === 'inclusions'
        ? fetchServicesQueryKeys.inclusionsPageKey(params)
        : fetchServicesQueryKeys.summaryPageKey(params),
  };
}

export type FetchServicesErrors =
  | { type: 'unknown' }
  | { type: 'package_deactivated'; message: string }
  | { type: 'hotel_mismatch'; message: string };

export function getFetchServicesApiErrors(
  resp: FetchServiceResponse,
): FetchServicesErrors {
  const { error } = resp;
  if (Array.isArray(error?.errors)) {
    const hotelMismatchErr = error.errors.find(
      value => value.errorCode === 'CONTENT_PACKAGE_037',
    );
    if (hotelMismatchErr) {
      return {
        type: 'hotel_mismatch',
        message: hotelMismatchErr.message,
      };
    }
    const hotelDeactivatedErr = error.errors.find(
      value => value.errorCode === 'CONTENT_PACKAGE_038', //TODO check this with BE
    );
    if (hotelDeactivatedErr) {
      return {
        type: 'package_deactivated',
        message: hotelDeactivatedErr.message,
      };
    }
  }
  return { type: 'unknown' };
}

export const fetchLosPkgServiceTemplateQueryOptions = (hotelId: string) => ({
  queryKey: [`property:id_${hotelId}`, { op: 'Los_Pkg_Templates' }],
  queryFn: async () => {
    return fetchLosPkgServiceTemplate(hotelId);
  },
  staleTime: Infinity,
  gcTime: Infinity, // This data doesn't change
});

export const fetchLosPackageDetailsQueryOptions = ({
  hotelCode,
  mmtId,
  packageId,
  clearPackageId,
}) => ({
  queryKey: [`property:pkgId_${packageId}`, { op: 'Los_Packages' }],
  queryFn: () =>
    fetchLosPackageDetails({ hotelCode, mmtId, packageId, clearPackageId }),
});
