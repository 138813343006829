import { useState, useEffect, useCallback } from 'react';

/**
 * A hook to find the current width of an element using a ref.
 * @param {React.RefObject<HTMLElement>} ref - The ref of the element you want to measure.
 * @returns {number} The width of the element.
 */
function useElementWidth(ref) {
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);

  return width;
}

export default useElementWidth;
