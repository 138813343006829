import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
  },
  headerText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  divider: {
    flex: '1 1 0%',
  },
}));
